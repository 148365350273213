<ngx-loading [show]="loading"></ngx-loading>
<div id="submission-level-tolerances" class="wrapper">
  <div class="row">
    <div class="col-12">
      <h1>{{title}}</h1>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <alert type="info" [dismissible]="false">
        <span>{{infoMessage}}</span>
      </alert>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <app-messages></app-messages>
    </div>
  </div>

  <div *ngIf="activeTolerance && rowData.length > 0" class="row">
    <div class="col-12" id="div:dateCreated">
      <b>Date Created: </b>{{activeTolerance.dateCreated | date: 'short'}} <br>
    </div>
  </div>

  <div *ngIf="activeTolerance && rowData.length > 0" class="row mb-3">
    <div class="col-12" id="div:userCreated">
      <b>User Created: </b>{{activeTolerance.userCreated}} <br>
    </div>
  </div>

  <div *ngIf="activeTolerance" class="row mb-3">
    <div class="col-12">
      <form name="toleranceForm" #toleranceForm="ngForm">
        <div class="row mb-3">
          <div class="col-sm-6">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">Failure Percentage</span>
              </div>
              <input id="input:percent" title="Failure Percentage" type="text" name="tolerance_pct" #tolerance_pct="ngModel" class="form-control" [(ngModel)]="activeTolerance.percentage" required [pattern]=percentPattern>
              <div class="input-group-append" [hidden]="tolerance_pct.valid || tolerance_pct.pristine">
                <span class="input-group-text text-danger"><small>Not a valid percent</small></span>
              </div>
              <div class="input-group-append hidden-xs">
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="input-group margin-top-20">
              <div class="input-group-prepend">
                <span class="input-group-text">Failure Count</span>
              </div>
              <input id="input:count" title="Failure Count" type="text" name="tolerance_cnt" #tolerance_cnt="ngModel" class="form-control" [(ngModel)]="activeTolerance.count" required [pattern]="countPattern">
              <div class="input-group-append" [hidden]="tolerance_cnt.valid || tolerance_cnt.pristine">
                <span class="input-group-text text-danger"><small>Not a valid max value</small></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button id="btn:update" type="button" class="btn btn-primary" (click)="updateTolerance()" [disabled]="!toleranceForm.valid || toleranceForm.pristine"><i class="fa fa-refresh"></i> Update</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <hr/>

  <div>

    <div *ngIf="activeTolerance && toleranceHistory.length > 0 && rowData.length > 0 && columnDefs.length > 0" class="row mb-3">
      <div class="col-12">
        <ag-grid-angular
          style="width: 100%; height: 400px;"
          #agGrid
          id="tolerancesGrid"
          class="ag-theme-balham"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="rowData"
          [context]="context"
          [frameworkComponents]="frameworkComponents"
          (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
  </div>
</div>
