/*
 * Copyright 2018 National Association of Insurance Commissioners
 */

import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FilingSchemaComponent} from './filing-schema.component';

const filingSchemaRoutes: Routes = [
  {path: 'filingSchema', component: FilingSchemaComponent}
];

@NgModule({
  imports: [
    RouterModule.forChild(filingSchemaRoutes)
  ],
  exports: [RouterModule]
})
export class FilingSchemaRoutingModule { }
