import {Component, OnDestroy, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';
import {SubmissionKeys} from '../model/submission-keys';
import {User} from '../model/user';
import {UserService} from '../service/user.service';
import {FileSubmission} from '../model/file-submission';
import {Schema} from '../model/schema.enum';
import {FileService} from './service/file.service';
import {SubmissionKeysService} from './service/submission-keys.service';
import {FileSubmissionState} from './model/file-submission-state.enum';
import {MessageService} from '../messages/service/message.service';
import {AlertMessage} from '../messages/model/alert-message';
import {UnCamelCasePipe} from '../pipes/un-camel-case.pipe';
import {StatusRendererComponent} from './cell-renderer/status-renderer/status-renderer.component';
import {DateRendererComponent} from '../cell-renderer/date-renderer/date-renderer.component';
import {NumberRendererComponent} from '../cell-renderer/number-renderer/number-renderer.component';
import {ExceptionsRendererComponent} from './cell-renderer/exceptions-renderer/exceptions-renderer.component';
import {Router} from '@angular/router';
import {
  FileValidationMaintenanceService
} from '../file-validation-maintenance/services/file-validation-maintenance.service';
import {ValidationSchema} from '../file-validation-maintenance/model/validation-schema';
import {FileUpload} from './model/file-upload';
import {UserAssignmentService} from '../user-assignment/services/user-assignment.service';
import {AssignmentValueResources} from '../user-assignment/model/user-assignment';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-file-dashboard',
  templateUrl: './file-dashboard.component.html',
  styleUrls: ['./file-dashboard.component.css']
})
export class FileDashboardComponent implements OnInit, OnDestroy {
  user: User;
  title: string;
  isFileUploading = false;
  uploadPercentage: number;
  uploadingFileName: string;
  fileSubmissions: FileSubmission[];
  submissionKeys: SubmissionKeys;
  searchComplete = false;
  loading = false;
  scrollBarHorizontal = (window.innerWidth < 1200);
  subscriptions: Array<Subscription> = [];
  alive: boolean;
  interval = 30000;
  firstLoad = false;

  columnDefs = [];
  rowData = [];
  context: any;
  frameworkComponents: any;
  defaultColDef: any;
  paginationPageSize = 10;

  private gridApi;
  private gridColumnApi;
  validationSchema: ValidationSchema;
  assignmentKeyFieldName: string;
  fileUpload: FileUpload;
  assignmentValues: AssignmentValueResources;
  fileSubmissionsForAssignmentKeys: FileSubmission[] = [];

  constructor(
    private userService: UserService,
    private fileService: FileService,
    private submissionKeysService: SubmissionKeysService,
    public messageService: MessageService,
    public unCamelCase: UnCamelCasePipe,
    private router: Router,
    private schemaMaintenanceService: FileValidationMaintenanceService,
    private userAssignmentService: UserAssignmentService
  ) {
    this.alive = true;
    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 12000);
    };
  }

  fileUploadedEventListner(event) {
    this.createHandler();
  }

  ngOnInit() {
    this.context = {componentParent: this};
    this.frameworkComponents = {
      statusRenderer: StatusRendererComponent,
      dateRenderer: DateRendererComponent,
      numberRenderer: NumberRendererComponent,
      exceptionsRenderer: ExceptionsRendererComponent
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this.user = this.userService.getStoredUser();
    this.uploadPercentage = 0;
    this.getSubmissionKeysForUser();
    this.fileSubmissions = [];
    this.setTitle();
    this.checkIfUserAssignmentsAsigned();
  }

  setTitle(): void {
    const datacallGroupArray = this.user.dataCallGroups;
    const datacallGroup = datacallGroupArray.find(dcg => dcg.groupName.toLocaleLowerCase() === this.user.selectedDatacallGroup.toLocaleLowerCase());
    const datacalls = datacallGroup.dataCalls;
    const datacall = datacalls.find(dc => dc.dataCallName.toLocaleLowerCase() === this.user.currentSchema.toLocaleLowerCase());
    let displayName: string = datacall['dataCallDisplayName'];
    if (!displayName || displayName.trim().length < 1) {
      displayName = this.user.currentSchema.toLocaleUpperCase();
    }
    this.title = this.user.currentSchema.toUpperCase() === Schema.PBR ? 'Company Experience Data File Upload' : `${displayName} File Dashboard`;
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  createHandler() {
    this.loading = true;
    this.fileUpload = this.fileService.getFileUplaod();
    this.fileService.createHandler(this.user, this.fileUpload)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        res => {
          this.messageService.clear();
          this.messageService.add(new AlertMessage(`${this.fileUpload.originalFileName} has been uploaded successfully`, 'success'));
          this.getFileSubmissions();
        }, error => {
          this.messageService.add(new AlertMessage(`${this.fileUpload.originalFileName} has failed to upload`, 'error'));
        }
      );
  }

  checkIfUserAssignmentsAsigned() {
    if (this.userAssignmentService.getShowuserAssignmentsPrimaryLink()) {
      this.getAssignmentValuesForUser();
    } else {
      this.getFileSubmissions();
    }
  }

  getAssignmentValuesForUser(): void {
    this.loading = true;
    const subscription = this.userAssignmentService.getAssignmentValuesForUser(this.user.userId, this.user.currentSchema)
      .subscribe(
        (assignmentValues: AssignmentValueResources) => {
          this.assignmentValues = assignmentValues;
          if (this.assignmentValues.assignmentValues.length !== 0) {
            this.getFileSubmissionsFromAssignementKeys(this.assignmentValues);
          }
        }
      );
    this.subscriptions.push(subscription);
  }

  getFileSubmissionsFromAssignementKeys(assignmentValues) {
    this.loading = true;
    const subscription = this.fileService.getFileSubmissions(assignmentValues, this.user.currentSchema)
      .subscribe(
        (fileSubmissionsForAssignmentKeys: FileSubmission[]) => {
          this.fileSubmissionsForAssignmentKeys = fileSubmissionsForAssignmentKeys;
          this.getFileSubmissions();
        }
      );
    this.subscriptions.push(subscription);
  }

  getFileSubmissions(): void {
    this.loading = true;
    this.fileService.getFileSubmissionForUser(this.user)
      .subscribe(
        (fileSubmissions: FileSubmission[]) => {
          this.fileSubmissions = fileSubmissions;
          this.setData();
          this.getAssignmentKeyNameFromDatcallFieldDefinitions();
          this.loading = false;
          this.searchComplete = true;
        }
      );
  }

  setData() {
    let fileSubmissions = this.fileSubmissions.map(c => c.id);
    this.fileSubmissions = this.fileSubmissions.concat(this.fileSubmissionsForAssignmentKeys.filter(({id}) => !fileSubmissions.includes(id)));
    this.fileSubmissions.sort((a, b) => new Date(b.modified).getTime() - new Date(a.modified).getTime());
    this.rowData = this.fileService.getFileDashboardData(this.fileSubmissions);
    if (this.rowData.length > 0) {
      this.columnDefs = this.getColumnsDef(this.rowData[0]);
      if (this.gridColumnApi) {
        const allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(column => {
          allColumnIds.push(column.colId);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds);
      }
    }
  }

  getSubmissionKeysForUser(): void {
    this.loading = true;
    const subscription = this.submissionKeysService.getKeys(this.user)
      .subscribe(
        submissionKeys => {
          this.submissionKeys = submissionKeys;
          this.fileService.setSubmissionKeys(this.submissionKeys);
        }
      );
    this.subscriptions.push(subscription);
  }
  
  getAssignmentKeyNameFromDatcallFieldDefinitions() {
    this.loading = true;
    this.schemaMaintenanceService.getCurrentValidationSchemaByType(this.user.currentSchema)
      .subscribe(
        validationSchema => {
          this.validationSchema = validationSchema;
          let key: Array<string> = _.keys(this.validationSchema.properties);
          let properties: any[] = [];
          key.forEach(x => {
            properties.push(this.validationSchema.properties[x]);
          });
          properties.forEach(x => {
            if (x.isAssignmentsKey) {
              this.assignmentKeyFieldName = x.name;
            }
          });
          this.schemaMaintenanceService.setAssignmentKeyFieldName(this.assignmentKeyFieldName);
          this.searchComplete = true;
          this.loading = false;
        }
      );
  }

  getColumnsDef(row: any): any[] {
    const columnsDef: any[] = [];
    const keys = Object.keys(row);
    keys.forEach(key => {
      const headerName = this.unCamelCase.transform(key);
      if (key === 'status') {
        columnsDef.push({headerName: headerName, field: key, cellRenderer: 'statusRenderer', autoHeight: true});
      } else if (key === 'modified' || key === 'created') {
        columnsDef.push({headerName: headerName, field: key, cellRenderer: 'dateRenderer'});
      } else if (key === 'numberOfRecords') {
        columnsDef.push({headerName: headerName, field: key, cellRenderer: 'numberRenderer'});
      } else if (key === 'exceptions') {
        columnsDef.push({headerName: headerName, field: key, cellRenderer: 'exceptionsRenderer'});
      } else if (key === 'rejectionReasons') {
        columnsDef.push({headerName: headerName, field: key, hide: true});
      } else {
        columnsDef.push({headerName: headerName, field: key});
      }
    });
    return columnsDef;
  }

  onColumnResized(event) {
    if (event.finished) {
      this.gridApi.resetRowHeights();
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  getRowValues(row: any): any[] {
    return Object.keys(row).map(key => row[key]);
  }

  submitFileSubmission(row: any) {
    const fileSubmission = this.fileService.findFileSubmission(this.fileSubmissions, row);
    const subscription = this.fileService.updateFileSubmissionState(this.user, fileSubmission.id, FileSubmissionState.filePendingNaicReview)
      .subscribe(
        next => {
          this.messageService.add(new AlertMessage('File is pending NAIC review', 'success'));
          window.scroll(0, 0);
          this.getFileSubmissions();
        });
    this.subscriptions.push(subscription);
  }

  removeFileSubmission(row: any) {
    const fileSubmission = this.fileService.findFileSubmission(this.fileSubmissions, row);
    const subscription = this.fileService.updateFileSubmissionState(this.user, fileSubmission.id, FileSubmissionState.senderRejectedFile)
      .subscribe(
        next => {
          this.messageService.add(new AlertMessage('File was removed', 'success'));
          window.scroll(0, 0);
          this.getFileSubmissions();
        }
      );
    this.subscriptions.push(subscription);
  }

  isWaitingForUserApproval(status: string): boolean {
    return status === FileSubmissionState.pendingUserSubmission;
  }

  getLinkInfo(row: any) {
    return this.fileService.getLinkInfo(this.fileSubmissions, row);
  }

  isStaticColumn(header: any): boolean {
    return FileService.isStaticColumn(header);
  }

  getRowClass(row) {
    return {
      'rerun-warning': row.status === FileSubmissionState.pendingUserSubmission,
      'rerun-danger': row.status === 'SYSTEM_REJECTED_FILE'
    };
  }

  goToFileSubmissionErrors(data: any) {
    this.router.navigate(['/fileSubmissionErrors', this.getLinkInfo(data)]);
  }
}
