import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class SchemaSelectionService {

  public selectedSchemaAfterDisclaimerAcceptance: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public selectedSchemaBeforeDisclaimerAcceptance: BehaviorSubject<string> = new BehaviorSubject<string>('');

}
