import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import * as _ from 'lodash';
import {BsModalService} from 'ngx-bootstrap/modal';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subscription, Subject} from 'rxjs';
import {FileSubmissionState} from '../file-dashboard/model/file-submission-state.enum';
import {SubmissionKeysService} from '../file-dashboard/service/submission-keys.service';
import {FileSubmission} from '../model/file-submission';
import {SubmissionKey} from '../model/submission-key';
import {SubmissionKeys} from '../model/submission-keys';
import {User} from '../model/user';
import {UserService} from '../service/user.service';
import {CredibleComponent} from './credible/credible.component';
import {NotesComponent} from './notes/notes.component';
import {FileAdminService} from './service/file-admin.service';
import {SubmissionAuditComponent} from './submission-audit/submission-audit.component';
import {UpdateStatusComponent} from './update-status/update-status.component';
import {MessageService} from '../messages/service/message.service';
import {AlertMessage} from '../messages/model/alert-message';
import {DateRendererComponent} from '../cell-renderer/date-renderer/date-renderer.component';
import {NumberRendererComponent} from '../cell-renderer/number-renderer/number-renderer.component';
import {takeUntil} from 'rxjs/operators';
import {UnCamelCasePipe} from '../pipes/un-camel-case.pipe';
import {
  FormFormatExceptionsRendererComponent
} from './cell-renderer/form-format-exceptions-renderer/form-format-exceptions-renderer.component';
import {
  CrossFieldExceptionsRendererComponent
} from './cell-renderer/cross-field-exceptions-renderer/cross-field-exceptions-renderer.component';
import {FileNameRendererComponent} from './cell-renderer/file-name-renderer/file-name-renderer.component';
import {Router} from '@angular/router';
import {NotesRendererComponent} from './cell-renderer/notes-renderer/notes-renderer.component';
import {AuditTrailRendererComponent} from './cell-renderer/audit-trail-renderer/audit-trail-renderer.component';
import {UpdateStatusRendererComponent} from './cell-renderer/update-status-renderer/update-status-renderer.component';
import {ReloadRendererComponent} from './cell-renderer/reload-renderer/reload-renderer.component';
import {CredibleRendererComponent} from './cell-renderer/credible-renderer/credible-renderer.component';
import {
  StatusRendererForAdminComponent
} from './cell-renderer/status-renderer-for-admin/status-renderer-for-admin.component';
import {
  FileValidationMaintenanceService
} from '../file-validation-maintenance/services/file-validation-maintenance.service';
import {ValidationSchema} from '../file-validation-maintenance/model/validation-schema';
import { FileLevelValidationsRendererComponent } from './cell-renderer/file-level-validations-renderer/file-level-validations-renderer.component';

@Component({
  selector: 'app-file-admin',
  templateUrl: './file-admin.component.html'
})
export class FileAdminComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  title: string;
  user: User;
  fileSubmissionKeys: SubmissionKeys;
  fileList: FileSubmission[];
  messageText: string;
  error = false;
  searched = false;
  confirmReloadRef: BsModalRef;
  fileSubmissionForReload: FileSubmission;
  modalSubscriptions: Array<Subscription> = [];
  notesRef: BsModalRef;
  credibleRef: BsModalRef;
  auditRef: BsModalRef;
  updateStatusRef: BsModalRef;
  loading = false;
  scrollBarHorizontal = (window.innerWidth < 1200);
  columnDefs = [];
  rowData = [];
  defaultColDef: any;
  context: any;
  frameworkComponents: any;
  gridApi: any;
  paginationPageSize = 10;
  validationSchema: ValidationSchema;
  assignmentKeyFieldName: any;

  public static isSenderRejectedFile(file: FileSubmission): boolean {
    return file.fileSubmissionState === FileSubmissionState.senderRejectedFile;
  }

  public static isNaicRejectsFileResubmit(file: FileSubmission): boolean {
    return file.fileSubmissionState === FileSubmissionState.naicRejectsFileResubmit;
  }

  public static isSystemRejected(file: FileSubmission): boolean {
    return file.fileSubmissionState === FileSubmissionState.systemRejectedFile;
  }

  public static isAdminRejectsFileResubmit(file: FileSubmission): boolean {
    return file.fileSubmissionState === FileSubmissionState.adminRejectsFileResubmit;
  }

  constructor(
    private userService: UserService,
    private submissionKeysService: SubmissionKeysService,
    private fileAdminService: FileAdminService,
    private modalService: BsModalService,
    private messageService: MessageService,
    private unCamelCase: UnCamelCasePipe,
    private router: Router,
    private schemaMaintenanceService: FileValidationMaintenanceService
  ) {
    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 12000);
    };
  }

  ngOnInit() {
    this.context = {componentParent: this};
    this.frameworkComponents = {
      dateRenderer: DateRendererComponent,
      numberRenderer: NumberRendererComponent,
      formFormatExceptionsRenderer: FormFormatExceptionsRendererComponent,
      crossFieldExceptionsRenderer: CrossFieldExceptionsRendererComponent,
      fileNameRenderer: FileNameRendererComponent,
      notesRenderer: NotesRendererComponent,
      auditTrailRenderer: AuditTrailRendererComponent,
      reloadRenderer: ReloadRendererComponent,
      updateStatusRenderer: UpdateStatusRendererComponent,
      credibleRenderer: CredibleRendererComponent,
      statusRendererForAdmin: StatusRendererForAdminComponent,
      fileLevelValidationsRenderer: FileLevelValidationsRendererComponent
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this.user = this.userService.getStoredUser();
    this.title = `${this.user.currentSchema.toUpperCase()} File Administration`;
    this.getSubmissionKeysForUser();
    if (this.fileAdminService.getStoredSubmissionKeys()) {
      this.fileSubmissionKeys = this.fileAdminService.getStoredSubmissionKeys();
      this.getFileList();
    }
    this.getAssignmentKeyNameFromDatcallFieldDefinitions();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.messageService.clear();
  }

  getSubmissionKeysForUser() {
    this.loading = true;
    this.submissionKeysService.getKeys(this.user)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        fileSubmissionKeys => {
          this.error = false;
          this.fileSubmissionKeys = fileSubmissionKeys;
          this.fileAdminService.setSubmissionKeys(this.fileSubmissionKeys);
        }
      );
  }

  getAssignmentKeyNameFromDatcallFieldDefinitions() {
    this.loading = true;
    this.schemaMaintenanceService.getCurrentValidationSchemaByType(this.user.currentSchema)
      .subscribe(
        validationSchema => {
          this.validationSchema = validationSchema;
          let key: Array<string> = _.keys(this.validationSchema.properties);
          let properties: any[] = [];
          key.forEach(x => {
            properties.push(this.validationSchema.properties[x]);
          });
          properties.forEach(x => {
            if (x.isAssignmentsKey) {
              this.assignmentKeyFieldName = x.name;
            }
          });
          this.schemaMaintenanceService.setAssignmentKeyFieldName(this.assignmentKeyFieldName);
          this.loading = false;
        }
      );
  }

  getFileList() {
    this.loading = true;
    this.fileAdminService.setStoredSubmissionKeys(this.fileSubmissionKeys);
    this.fileAdminService.getFileList(this.user.currentSchema, this.fileSubmissionKeys)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (fileList: FileSubmission[]) => {
          this.loading = false;
          this.error = false;
          this.searched = true;
          this.fileList = _.sortBy(fileList, 'created').reverse();
          this.rowData = this.fileAdminService.getFileAdminData(this.fileList);
          this.columnDefs = this.getColumnDef(this.rowData[0]);
        }
      );
  }

  getColumnDef(row: any): any[] {
    const columnsDef: any[] = [];
    if (row) {
      const keys = Object.keys(row);
      keys.forEach(key => {
        const headerName = this.unCamelCase.transform(key);
        switch (headerName) {
          case 'File': 
          columnsDef.push({ headerName: headerName, field: key, cellRenderer: 'fileNameRenderer'});
            break;
          case 'Form Format Exceptions':
            columnsDef.push({headerName: headerName, field: key, cellRenderer: 'formFormatExceptionsRenderer'});
            break;
          case 'Cross Field Exceptions':
            columnsDef.push({headerName: headerName, field: key, cellRenderer: 'crossFieldExceptionsRenderer'});
            break;
          case 'File Level Validations':
              columnsDef.push({headerName: headerName, field: key, cellRenderer: 'fileLevelValidationsRenderer'});
            break;
          case 'Number Of Records':
            columnsDef.push({headerName: headerName, field: key, cellRenderer: 'numberRenderer'});
            break;
          case 'Date':
            columnsDef.push({headerName: headerName, field: key, cellRenderer: 'dateRenderer'});
            break;
          case 'Notes':
            columnsDef.push({headerName: headerName, field: key, cellRenderer: 'notesRenderer', autoHeight: true});
            break;
          case 'Audit Trail':
            columnsDef.push({headerName: headerName, field: key, cellRenderer: 'auditTrailRenderer', autoHeight: true});
            break;
          case 'Reload':
            columnsDef.push({headerName: headerName, field: key, cellRenderer: 'reloadRenderer', autoHeight: true});
            break;
          case 'Update Status':
            columnsDef.push({
              headerName: headerName,
              field: key,
              cellRenderer: 'updateStatusRenderer',
              autoHeight: true
            });
            break;
          case 'Credible':
            columnsDef.push({headerName: headerName, field: key, cellRenderer: 'credibleRenderer', autoHeight: true});
            break;
          case 'Status':
            columnsDef.push({
              headerName: headerName,
              field: key,
              cellRenderer: 'statusRendererForAdmin',
              autoHeight: true
            });
            break;
          case 'Rejection Reasons':
            columnsDef.push({headerName: headerName, field: key, hide: true});
            break;
          default:
            columnsDef.push({headerName: headerName, field: key});
            break;
        }
      });
    }
    return columnsDef;
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;

    this.sizeColumnsToFit();
  }

  sizeColumnsToFit(): void {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  reload(fileSubmission: FileSubmission, template: TemplateRef<any>): void {
    this.fileSubmissionForReload = fileSubmission;
    this.confirmReloadRef = this.modalService.show(template, {class: 'modal-lg'});
  }

  confirmReload(): void {
    this.doReload(this.fileSubmissionForReload);
  }

  doReload(fileSubmission: FileSubmission) {
    this.fileAdminService.doReload(fileSubmission)
      .subscribe(
        () => {
          this.error = false;
          this.messageText = 'The request for reload was accepted by the server and should be processed shortly';
          this.messageService.add(new AlertMessage(this.messageText, 'success'));
        }
      );
  }

  getFileFromS3(s3fileName: string) {
    this.loading = true;
    this.fileAdminService.getPreSignedUrlfromS3(this.user, s3fileName).subscribe(response => {
      this.loading = false;
      const presignedUrl = response.downloadURL;
      this.handleS3Download(presignedUrl);
    });
  }

  handleS3Download(presignedUrl: string) {
    let hiddenElement = document.createElement('a');
    hiddenElement.href = presignedUrl;
    hiddenElement.target = '_blank';
    hiddenElement.click();
    hiddenElement.remove();
  }

  openNotes(fileSubmission: FileSubmission): void {
    const initialState = {
      fileSubmission: fileSubmission
    };
    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        this.error = this.notesRef.content.error;
        this.messageText = this.notesRef.content.messageText;
        if (this.messageText !== undefined) {
          this.messageService.add(new AlertMessage(this.messageText, this.error ? 'warning' : 'success'));
        }
        this.unsubscribeModal();
        this.getFileList();
      }));

    this.notesRef = this.modalService.show(NotesComponent, {initialState: initialState, class: 'modal-lg'});
  }

  openCredible(fileSubmission: FileSubmission): void {
    const initialState = {
      fileSubmission: fileSubmission
    };
    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        this.error = this.credibleRef.content.error;
        this.messageText = this.credibleRef.content.messageText;
        if (this.messageText !== undefined) {
          this.messageService.add(new AlertMessage(this.messageText, this.error ? 'warning' : 'success'));
        }
        this.unsubscribeModal();
        this.getFileList();
      }));

    this.credibleRef = this.modalService.show(CredibleComponent, {initialState: initialState, class: 'modal-lg'});
  }

  openSubmissionAudit(fileSubmission: FileSubmission): void {
    const initialState = {
      fileSubmission: fileSubmission
    };
    this.auditRef = this.modalService.show(SubmissionAuditComponent, {initialState: initialState, class: 'modal-lg'});
  }

  openUpdateStatus(fileSubmission: FileSubmission): void {
    const initialState = {
      fileSubmission: fileSubmission
    };
    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        this.unsubscribeModal();
        this.getFileList();
      }));

    this.updateStatusRef = this.modalService.show(UpdateStatusComponent, {
      initialState: initialState,
      class: 'modal-lg'
    });
  }

  showUpdateStatus(file: FileSubmission): boolean {
    return !FileAdminComponent.isSenderRejectedFile(file) &&
      !FileAdminComponent.isNaicRejectsFileResubmit(file) &&
      !FileAdminComponent.isAdminRejectsFileResubmit(file) &&
      !FileAdminComponent.isSystemRejected(file);
  }

  showCredible(file: FileSubmission): boolean {
    return this.userService.canViewCredible(this.user) &&
      (this.isReasonabilityCheckComplete(file) ||
        this.isCredible(file) ||
        this.isNotCredible(file) ||
        this.isPartiallyCredible(file));
  }


  isReasonabilityCheckComplete(file: FileSubmission): boolean {
    return file.fileSubmissionState === FileSubmissionState.reasonabilityCheckComplete;
  }

  isCredible(file: FileSubmission): boolean {
    return file.fileSubmissionState === FileSubmissionState.credible;
  }

  isNotCredible(file: FileSubmission): boolean {
    return file.fileSubmissionState === FileSubmissionState.notCredible;
  }

  isPartiallyCredible(file: FileSubmission): boolean {
    return file.fileSubmissionState === FileSubmissionState.partiallyCredible;
  }

  canViewCredible(): boolean {
    return this.userService.canViewCredible(this.user);
  }

  sortSubmissionKeysByName(submissionKeys: SubmissionKey[]): SubmissionKey[] {
    return _.sortBy(submissionKeys, ['name']);
  }

  private findFileSubmission(row: any): FileSubmission {
    const search = {};
    if (row.originalFileName) {
      search['originalFileName'] = row.originalFileName;
    }
    if (row.date) {
      search['created'] = row.date;
    }
    if (row.numberOfRecords) {
      search['totalDocuments'] = row.numberOfRecords;
    }
    if (row.status) {
      search['fileSubmissionState'] = row.status;
    }
    if (row.formFormatExceptions) {
      search['formFormatErrorCount'] = row.formFormatExceptions;
    }
    if (row.crossFieldExceptions) {
      search['crossFieldErrorCount'] = row.crossFieldExceptions;
    }
    return _.find(this.fileList, search);
  }

  getLinkInfo(row: any, validationCategory: string): any {
    const file = this.findFileSubmission(row);
    return {documentId: file.id, originalFileName: file.originalFileName, validationCategory: validationCategory};
  }

  isStaticColumn(header: string): boolean {
    return FileAdminService.isStaticAdminColumn(header);
  }

  getFlexGrow(name: string): number {
    if (name === 'adminAction') {
      return 3;
    }
    if (name === 'file') {
      return 2;
    }
    return 1;
  }

  getHeaderClass(name: string): string {
    const centeredHeaders = ['formFormatExceptions', 'crossFieldExceptions', 'numberOfRecords', 'adminAction'];
    if (_.indexOf(centeredHeaders, name) > -1) {
      return 'font-weight-bold text-center';
    }
    return 'font-weight-bold';
  }

  getCellClass(name: string): string {
    const centeredHeaders = ['formFormatExceptions', 'crossFieldExceptions', 'numberOfRecords', 'adminAction'];
    if (_.indexOf(centeredHeaders, name) > -1) {
      return 'text-center';
    }
    return '';
  }

  getColumnMode(): string {
    return this.scrollBarHorizontal ? 'force' : 'flex';
  }

  private unsubscribeModal(): void {
    this.modalSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.modalSubscriptions = [];
  }

  goToFileAdminErrors(row: any, validationCategory: string): void {
    this.router.navigate(['/fileAdminErrors', this.getLinkInfo(row, validationCategory)]);
  }

}
