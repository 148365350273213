<ngx-loading [show]="loading"></ngx-loading>
<div id="schema-audit" class="wrapper">
  <div class="row page-title">
    <div class="col-12">
      <h1>{{title}}</h1>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li *ngFor="let crumb of breadcrumbs" class="breadcrumb-item">
            <a [routerLink]="[crumb.link]" (click)="breadcrumbService.removeBreadcrumb(crumb)">{{crumb.label}}</a>
          </li>
          <li class="breadcrumb-item active">
            {{breadcrumb.label}}
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div *ngIf="rowData.length > 0" class="row mb-3">
    <div class="col-12">
      <ag-grid-angular
        style="width: 100%; height: 400px;"
        #agGrid
        id="schemaAuditGrid"
        class="ag-theme-balham"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="rowData"
        [context]="context"
        [frameworkComponents]="frameworkComponents"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>
</div>
