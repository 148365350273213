<!-- Datacall Admin -->
<ng-container *ngIf="params.value.userRole === 'datacallAdmin' else primaryUser">
    <input *ngIf="params.value.secondary === true else false" id="secondary" type="radio"
        [checked]="params.value.secondary === true" (change)="changeToSecondaryUser(params.value)">
</ng-container>

<!-- Primary (Company Admin) -->
<ng-template #primaryUser>
    <ng-container *ngIf="params.value.userRole === 'primaryUser' else rdcAdmin">
        <input *ngIf="params.value.secondary === true else false" id="secondary" type="radio"
            [checked]="params.value.secondary === true" (change)="changeToSecondaryUser(params.value)">

    </ng-container>
</ng-template>

<!-- Rdc Admin -->
<ng-template #rdcAdmin>
    <i *ngIf="params.value.secondary === true else false" class="fa fa-check"></i>
</ng-template>

<ng-template #false>

</ng-template>