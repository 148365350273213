import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import { AuthGuardService } from '../service/auth-guard.service';
import { UserNotAllowedComponent } from './user-not-allowed.component';

const userNotAllowedRoutes: Routes = [
  { path: 'userNotAllowed', component: UserNotAllowedComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [
    RouterModule.forChild(userNotAllowedRoutes)
  ],
  exports: [RouterModule]
})
export class UserNotAllowedRoutingModule {}
