<ng-container>
  <div class="row">
    <div class="col-12">
      <span class="file-download-link"
            (click)="openConfirmDownloadModal(confirmDownloadTemplate)"
      >{{ params.value }}</span>
    </div>
  </div>
</ng-container>

<ng-template #confirmDownloadTemplate>
  <div class="modal-header">
    <h3 class="modal-title">Confirm file download</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="confirmDownloadModal.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Would you like to download file {{params.value}}?</p>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="confirmDownloadModal.hide()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="downloadFile()">Download</button>
    </div>
  </div>
</ng-template>
