import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-notes-renderer',
  templateUrl: './notes-renderer.component.html'
})
export class NotesRendererComponent implements ICellRendererAngularComp {
  params: any;



  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  openNotes() {
    this.params.context.componentParent.openNotes(this.params.value);
  }

}
