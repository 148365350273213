import {Component, OnDestroy, OnInit} from '@angular/core';
import {MessageService} from '../../messages/service/message.service';
import {User} from '../../model/user';
import {UserService} from '../../service/user.service';
import {FileValidationMaintenanceService} from '../../file-validation-maintenance/services/file-validation-maintenance.service';
import {AlertMessage} from '../../messages/model/alert-message';
import {OnlineDataEntryService} from '../services/online-data-entry.service';
import {OnlineSubmissions} from '../model/online-submissions';
import {IFilingSearchObject} from '../model/filing-search-object';
import * as _ from 'lodash';
import {UserAssignments} from '../../user-assignment/model/user-assignment';
import {Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { DatacallSettingEnum } from '../../roleconfig/datacall-setting/model/datacall-setting.enum';
import { DatacallSettingService } from '../../roleconfig/datacall-setting/services/datacall-setting.service';
import { AssignmentValueRenderer } from './cell-renderer/assignment-value-renderer/assignment-value-renderer.component';
import { UnCamelCasePipe } from '../../pipes/un-camel-case.pipe';
import { AttachmentRenderer } from './cell-renderer/attachment-renderer/attachment-renderer.component';
import { OnlineSubmissionsStatus } from '../model/online-submissions-status';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';

/**
 * This component appears for administrators of an online data entry data call.
 */
@Component({
  selector: 'app-filing-search',
  templateUrl: './filing-search.component.html',
  styleUrls: ['./filing-search.component.css']
})
export class FilingSearchComponent implements OnInit, OnDestroy {
  loading = false;
  schema: string;
  user: User;
  assignmentKeyFieldName: string;
  userAssignments: UserAssignments[];
  validationSchema: any;
  searchByAssignmentKey = true;
  searchTerm: string;
  data: OnlineSubmissions[] = [];
  activeSearch: IFilingSearchObject = {
    searchByAssignmentKey: false,
    searchTerm: ''
  };
  frameworkComponents: any;
  defaultColDef: any;
  context: any;
  columnDefs = [];
  rowData = [];
  public gridApi: GridApi;
  public gridColumnApi;
  private gridOptions: GridOptions;

  constructor(
    private messageService: MessageService,
    private userService: UserService,
    private fileValidationMaintenaceService: FileValidationMaintenanceService,
    private onlineDataEntryService: OnlineDataEntryService,
    private router: Router,
    private datePipe: DatePipe,
    private datacallSettingService: DatacallSettingService,
    private unCamelCase: UnCamelCasePipe
    ) {
  }

  ngOnInit() {
    this.agGridOptions();
    this.user = this.userService.getStoredUser();
    this.schema = this.user.currentSchema.toLocaleUpperCase();
    // this method gets current data call fields and finds the ones where assignment key is true
    // if there is only one assignment key, it is set to the assignmentKeyFieldName property
    this.getAssignmentKeyNameFromDatcallFieldDefinitions();
    window.addEventListener('resize', this.onResize.bind(this));
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize.bind(this));
  }

  agGridOptions() {
    this.context = {componentParent: this};
    this.frameworkComponents = {
      assignmentValueRenderer: AssignmentValueRenderer,
      attachmentRenderer: AttachmentRenderer
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this.gridOptions = {
      columnDefs: this.columnDefs,
      domLayout: 'height: 400px; width: 400px'
    };
  }

  onGridReady(params: { api: GridApi; columnApi: ColumnApi }): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.onResize();
  }

  onResize(): void {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  selectFilingSearchMethod(event) {
    this.searchByAssignmentKey = event.target.id === 'assignment-key-input';
  }

  searchForFilings() {
    this.messageService.clear();

    if (this.isNewSearch() || this.isNullOrWhitespace(this.searchTerm)) {
        this.activeSearch.searchByAssignmentKey = this.searchByAssignmentKey;
        this.activeSearch.searchTerm = this.searchTerm;
        
        if (this.activeSearch.searchByAssignmentKey === true) {
            this.searchByAssignmentKeys();
        } else {
            this.searchByUserId();
        }
    }
}

  searchByAssignmentKeys() {
    this.loading = true;
    let assignmentField = this.isNullOrWhitespace(this.searchTerm) ? null : this.assignmentKeyFieldName;
    let assignmentValue = this.isNullOrWhitespace(this.searchTerm) ? null : this.searchTerm;
    this.onlineDataEntryService.getOnlineFilingsByAssignmentKey(this.schema, assignmentField, assignmentValue)
      .subscribe(onlineSubmissions => {
        this.handleTableData(onlineSubmissions);
        this.loading = false;
      }, error => {
        this.handleError(error);
      });
  }

  isNullOrWhitespace(value: string | null | undefined): boolean {
    return !value || value.trim().length === 0;
  }

  searchByUserId() {
    this.loading = true;
    let userId = this.isNullOrWhitespace(this.searchTerm) ? null : this.searchTerm;
    this.onlineDataEntryService.getOnlineFilingsByUserId(this.schema, userId).subscribe(submissions => {
      if (submissions.length === 0) {
        this.messageService.add(new AlertMessage(`This user is not assigned any filings.`, 'danger'));
      } else {
        this.handleTableData(submissions);
      }
      this.loading = false;
    }, error => {
      this.handleError(error);
    });
  }

  handleTableData(onlineSubmissions: any) {
    this.agGridOptions();
    this.handleOnlineSubmissions(onlineSubmissions);
  }

  handleOnlineSubmissions(submissions: any[]) {
    let assignmentField;
    if((submissions.length === 1 && submissions[0].status === OnlineSubmissionsStatus.UNKNOWN) || submissions.length === 0) {
      this.rowData = [];
    } else {
      submissions.forEach(submission => {
        let transformedObj = this.onlineDataEntryService.transformStatus(submission);
        transformedObj = this.transformFilingSearch(submission);
        this.data.push(transformedObj);
      });
      assignmentField = this.data[0].assignmentKey.assignmentField;
      this.rowData = this.onlineDataEntryService.getOnlineFilingData(submissions);
    }
    if (this.rowData.length > 0) {
      this.columnDefs = [];
      this.columnDefs = this.getColumnsDef(this.rowData[0], assignmentField);
      if (this.gridColumnApi) {
        const allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(column => {
          allColumnIds.push(column.colId);
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds);
      }
    }
    this.loading = false;
  }

  getColumnsDef(row: any, assignmentField): any[] {
    const columnsDef: any[] = [];
    if (row) {
      const keys = Object.keys(row);
      keys.forEach(key => {
        const headerName = this.unCamelCase.transform(key);
        const assignmentFieldValue = this.unCamelCase.transform(this.assignmentKeyFieldName);
        switch (headerName) {
          case assignmentFieldValue:
          case 'Null':
            columnsDef.push({
              headerName: headerName, resizable: true, field: key, cellRenderer: 'assignmentValueRenderer', flex: 1, sortable: true, sort: "asc", unSortIcon: true
            });
            break;
          case 'Filing Status':
            columnsDef.push({ headerName: headerName, resizable: true, field: key, flex: 1, unSortIcon: true });
            break;
          case 'Attachments':
            columnsDef.push({ headerName: headerName, field: key, resizable: true, autoHeight: true, cellRenderer: 'attachmentRenderer', flex: 1, unSortIcon: true });
            break;
          default:
            columnsDef.push({ headerName: headerName, field: key, flex: 1, unSortIcon: true });
            break;
        }
      });
    }
    return columnsDef;
  }

  handleError(error: any) {
    if (error.status === 404) {
      this.messageService.add(new AlertMessage(`This does not appear to be a valid username.`, 'danger'));
    } else {
      this.messageService.add(new AlertMessage(`Something went wrong retrieving filings.`, 'danger'));
    }
    this.loading = false;
  }

  goToOnlineDataEntry(assignmentValue) {
    this.router.navigate([`dataEntry/${this.schema}`]);
    let assignmentKey = {
      assignmentField: this.assignmentKeyFieldName,
      assignmentValue: assignmentValue,
    };
    this.onlineDataEntryService.assignmentKey = assignmentKey;
  }

  isNewSearch() {
    const newSearch: IFilingSearchObject = {
      searchByAssignmentKey: true,
      searchTerm: ''
    };
    newSearch.searchByAssignmentKey = this.searchByAssignmentKey;
    newSearch.searchTerm = this.searchTerm;

    return JSON.stringify(newSearch) !== JSON.stringify(this.activeSearch);
  }

  getAssignmentKeyNameFromDatcallFieldDefinitions() {
    this.loading = true;
    this.fileValidationMaintenaceService.getCurrentValidationSchemaByType(this.schema)
      .subscribe(
        validationSchema => {
          this.validationSchema = validationSchema;
          let key: Array<string> = _.keys(this.validationSchema.properties);
          let properties: any[] = [];
          let assignmentKeys: string[] = [];
          key.forEach(x => {
            properties.push(this.validationSchema.properties[x]);
          });
          properties.forEach(prop => {
            if (prop.isAssignmentsKey) {
              assignmentKeys.push(prop.name);
            }
          });
          if (assignmentKeys.length === 0) {
            this.messageService.add(new AlertMessage(`This data call does not have an assignment key.`, 'danger'));
          } else if (assignmentKeys.length > 1) {
            this.messageService.add(new AlertMessage(`This data call has more than one assignment key.`, 'danger'));
          } else {
            this.assignmentKeyFieldName = assignmentKeys[0];
          }
          this.getDatacallSettingValueForAllowAttachments();
        }
      );
  }

  getDatacallSettingValueForAllowAttachments(): void {
    this.loading = true;
    let datcallSettingName = DatacallSettingEnum.allowAttachments;
    this.datacallSettingService.getDatacallSettingName(this.user.currentSchema, datcallSettingName).subscribe(
      res => {
        this.onlineDataEntryService.isAllowAttachments = res;
        this.searchByAssignmentKeys();
      }
    );
  }

  transformFilingSearch(submission: OnlineSubmissions): OnlineSubmissions {
    submission.attachments.forEach(attach => {
      attach.uploadDate = this.datePipe.transform(attach.uploadDate, 'MM/dd/yy, h:mm a');
    });
    submission.attachments.sort((a, b) => a.originalFileName.localeCompare(b.originalFileName));
    return submission;
  }

  downloadAttachment(attachment) {
    this.loading = true;
    this.onlineDataEntryService.getDownloadUrlfromS3(this.user, attachment.fileName).subscribe(response => {
      if(response) {
        this.loading = false;
        const presignedUrl = response.uploadURL;
        this.handleS3Download(presignedUrl);
      } else {
        this.loading = false;
        window.scroll(0,0);
      }
    });
  }

  handleS3Download(presignedUrl: string) {
    let hiddenElement = document.createElement('a');
    hiddenElement.href = presignedUrl;
    hiddenElement.target = '_blank';
    hiddenElement.click();
    hiddenElement.remove();
    window.scroll(0,0);
  }

}
