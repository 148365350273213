import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { FileAdminService } from '../file-admin/service/file-admin.service';
import { SubmissionKeysService } from '../file-dashboard/service/submission-keys.service';
import { FileSubmission } from '../model/file-submission';
import { SubmissionKeys } from '../model/submission-keys';
import { User } from '../model/user';
import { UserService } from '../service/user.service';
import { UserAssignments } from '../user-assignment/model/user-assignment';
import { UserAssignmentService } from '../user-assignment/services/user-assignment.service';
import { GroupBy, roleStatusFactory, RoleStatusFactory } from './model/RoleStatusFactory';

@Component({
  selector: 'app-datacall-summary',
  templateUrl: './datacall-summary.component.html',
  styleUrls: ['./datacall-summary.component.css']
})

export class DatacallSummaryComponent implements OnInit {
  title: string;
  user: User;
  loading: boolean = false;
  fileSubmissionKeys: SubmissionKeys;
  fileList: FileSubmission[];
  totalCountOfUniqueStatus: any[] = [];
  tableDataForStatus: RoleStatusFactory[];
  roleStatusFactory: (RoleStatusFactory | GroupBy)[];
  displayedColumns: string[];
  dataSource: (RoleStatusFactory | GroupBy)[];
  userAssignments: UserAssignments[];
  defaultStatus: (RoleStatusFactory | GroupBy)[];
  counts: boolean;
  dataForSummaryPage: any[] = [];
  data: any[];
  summaryTableData: (RoleStatusFactory | GroupBy)[];

  constructor(
    private userService: UserService,
    private fileAdminService: FileAdminService,
    private submissionKeysService: SubmissionKeysService,
    private userAssignmentService: UserAssignmentService
  ) { }

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    this.title = `${this.user.currentSchema.toUpperCase()} Datacall Summary`;
    this.getDataForSummaryPage(this.user);
    this.resetRoles();
    this.defaultStatus = roleStatusFactory;
  }

  resetRoles() {
    roleStatusFactory.forEach((x: RoleStatusFactory) => {
      x.numberOfFilings = 0;
    });
  }

  getDataForSummaryPage(user: User) {
    this.loading = true;
    this.submissionKeysService.getKeys(user)
      .subscribe(
        (fileSubmissionKeys: SubmissionKeys) => {
          this.fileSubmissionKeys = fileSubmissionKeys;
          this.dataForSummaryPage.push(this.fileSubmissionKeys);
          this.fileAdminService.getFileList(user.currentSchema, this.fileSubmissionKeys)
            .subscribe(
              (fileList: FileSubmission[]) => {
                this.fileList = fileList;
                this.dataForSummaryPage.push(this.fileList);
                this.fileList = _.sortBy(fileList, 'created').reverse();
                this.userAssignmentService.getAssigmentsForDatacall(user.currentSchema)
                  .subscribe(
                    (userAssignments: UserAssignments[]) => {
                      this.userAssignments = userAssignments;
                      this.calcCounts(this.fileList);
                      this.dataForSummaryPage.push(this.userAssignments);
                      this.loading = false;
                    }
                  );
              }
            );
        }
      );
  }

  calcCounts(fileList: FileSubmission[]) {
    this.getTotalCountOfUniqueStatus(this.fileList);
    this.getUnsccessfulFilings(this.totalCountOfUniqueStatus);
    this.getInProgresFilings(this.summaryTableData, this.totalCountOfUniqueStatus);
    this.getSuccessfulFilings(this.summaryTableData);
    this.getUserAssignmentsInfo(this.summaryTableData);
  }

  getTotalCountOfUniqueStatus(fileList: FileSubmission[]) {
    let roleFactoryStatus = this.defaultStatus;
    let totalCountOfUniqueStatus = {};

    totalCountOfUniqueStatus = fileList.reduce((accumulator, value) => {
      return { ...accumulator, [value.fileSubmissionState]: (accumulator[value.fileSubmissionState] || 0) + 1 };
    }, {});
    Object.entries(totalCountOfUniqueStatus).forEach(([key, value]) => {
      let data: any = {};
      roleFactoryStatus.forEach((x: RoleStatusFactory) => {
        if (x.name === key) {
          data['name'] = x.name;
          data['value'] = value
        }
      });
      this.totalCountOfUniqueStatus.push(data);
    });
  }

  getUnsccessfulFilings(totalCountOfUniqueStatus: any) {
    this.summaryTableData = this.defaultStatus;

    this.summaryTableData.map((x: RoleStatusFactory) => {
      let result = totalCountOfUniqueStatus.filter(a => a.name === x.name);
      if (result.length > 0) {
        if (result[0].name === 'SYSTEM_REJECTED_FILE') x.numberOfFilings = result[0].value;
      }
      return x;
    });
  }

  getInProgresFilings(summaryTableData, totalCountOfUniqueStatus) {
    summaryTableData.map((x: RoleStatusFactory) => {
      let result = totalCountOfUniqueStatus.filter(a => a.name === x.name);
      if (result.length > 0) {
        x.numberOfFilings = result[0].value;
      }
      return x;
    });
  }

  getSuccessfulFilings(summaryTableData: (RoleStatusFactory | GroupBy)[]) {
    let assignmentValues = [];
    let submissionKeyValues = [];

    this.fileList.forEach(x => {
      if (x.fileSubmissionState === 'FILE_PROCESSED' || x.fileSubmissionState === 'NAIC_ACCEPTS_WITH_ERRORS') {
        if (x.assignmentFieldValues) assignmentValues.push(x.assignmentFieldValues);
        if (this.fileSubmissionKeys.keys.length === 1) submissionKeyValues.push(x.submissionKeys[0].values);
      }
    });

    summaryTableData.forEach((x: RoleStatusFactory) => {
      if (x.name === 'ASSIGNMENT_KEYS') x.numberOfFilings = Array.from(new Set(assignmentValues.flat())).length;
      if (x.name === 'SUBMISSION_KEYS') x.numberOfFilings = (this.fileSubmissionKeys.keys.length === 1) ? Array.from(new Set(submissionKeyValues.flat())).length : 'N/A';
    });
  }

  getUserAssignmentsInfo(summaryTableData: (RoleStatusFactory | GroupBy)[]) {
    summaryTableData.forEach((x: RoleStatusFactory) => {
      if (x.name === 'USER_ASSIGNMENTS') x.numberOfFilings = this.userAssignments.length;
    });

    this.roleStatusFactory = summaryTableData;
    this.displayedColumns = ['value', 'numberOfFilings'];
    this.dataSource = this.roleStatusFactory;
  }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }

}
