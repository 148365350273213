import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { Breadcrumb } from '../model/breadcrumb';
import { User } from '../model/user';
import { BreadcrumbService } from '../service/breadcrumb.service';
import { UserService } from '../service/user.service';
import { CrossFieldValidation } from './model/cross-field-validation';
import { CrossFieldValidationMaintenanceService } from './services/cross-field-validation-maintenance.service';
import { MessageService } from '../messages/service/message.service';
import { UnCamelCasePipe } from '../pipes/un-camel-case.pipe';
import { EditRendererComponent } from './cell-renderer/edit-renderer/edit-renderer.component';
import { AuditRendererComponent } from './cell-renderer/audit-renderer/audit-renderer.component';
import { DatacallSettingService } from '../roleconfig/datacall-setting/services/datacall-setting.service';

@Component({
  selector: 'app-validation-maintenance',
  templateUrl: './cross-field-validation-maintenance.component.html'
})
export class CrossFieldValidationMaintenanceComponent implements OnInit, OnDestroy {
  title: string;
  private _message = new Subject<string>();
  messageText: string;
  error = false;
  breadcrumb = new Breadcrumb('Cross Field Validations', '/validationMaintenance');
  user: User;
  crossFieldValidations: CrossFieldValidation[];
  loading = false;
  scrollBarHorizontal = (window.innerWidth < 1200);
  subscriptions: Array<Subscription> = [];
  nameToSearch: string;

  columnDefs = [];
  rowData = [];
  defaultColDef: any;
  context: any;
  frameworkComponents: any;
  gridApi: any;
  isOnlineDataEntry: boolean;

  constructor(
    private userService: UserService,
    private router: Router,
    private validationMaintenanceService: CrossFieldValidationMaintenanceService,
    public breadcrumbService: BreadcrumbService,
    private messageService: MessageService,
    private unCamelCase: UnCamelCasePipe,
    private dataCallSettingsService: DatacallSettingService,
  ) {
    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 12000);
    };
  }

  ngOnInit() {
    this.context = { componentParent: this };
    this.frameworkComponents = {
      editRenderer: EditRendererComponent,
      auditRenderer: AuditRendererComponent
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this._message.subscribe(message => this.messageText = message);
    this.breadcrumbService.clearBreadcrumbs();
    this.user = this.userService.getStoredUser();
    this.isOnlineDataEntry = this.dataCallSettingsService.getOnlineDataEntrySetting();
    this.title = `${this.user.currentSchema.toUpperCase()} Cross Field Validations`;
    this.getFileSubmissionValidations();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.messageService.clear();
  }

  getFileSubmissionValidations(): void {
    this.loading = true;
    const sub = this.validationMaintenanceService.getCrossFieldValidationsForSchema(this.user.currentSchema)
      .subscribe(
        crossFieldValidations => {
          this.loading = false;
          this.error = false;
          this.crossFieldValidations = crossFieldValidations;
          this.rowData = this.getTableData(this.crossFieldValidations);
          this.columnDefs = this.getColumnDef(this.rowData[0]);
        }
      );
    this.subscriptions.push(sub);
  }

  getColumnDef(row: any): any[] {
    const columnsDef: any[] = [];
    if (row) {
      const keys = Object.keys(row);
      keys.forEach(key => {
        const headerName = this.unCamelCase.transform(key);
        if (headerName === 'Edit') {
          columnsDef.push({ headerName: headerName, field: key, cellRenderer: 'editRenderer', autoHeight: true });
        } else if (headerName === 'Audit') {
          columnsDef.push({ headerName: headerName, field: key, cellRenderer: 'auditRenderer', autoHeight: true });
        } else if (headerName === 'Public Viewable') {
          columnsDef.push({ headerName: 'Public', field: key });
        } else {
          columnsDef.push({ headerName: headerName, field: key });
        }
      });
    };
    return columnsDef;
  }

  onGridReady(params) {
    this.gridApi = params.api;

    this.sizeColumnsToFit();
  }

  sizeColumnsToFit(): void {
    if (this.columnDefs.length <= 8) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  addNewCrossFieldValidation() {
    this.breadcrumbService.addBreadcrumb(this.breadcrumb);
    this.router.navigate(['validationEdit', { schemaType: this.user.currentSchema, newCrossFieldValidation: true }]);
  }

  editCrossFieldValidation(crossFieldValidation: CrossFieldValidation): void {
    this.breadcrumbService.addBreadcrumb(this.breadcrumb);
    this.validationMaintenanceService.setSelectedCrossFieldValidation(crossFieldValidation);
    this.router.navigate(['validationEdit', { schemaType: this.user.currentSchema, newCrossFieldValidation: false }]);
  }

  getCrossFieldValidationAuditTrail(crossFieldValidation: CrossFieldValidation): void {
    this.breadcrumbService.addBreadcrumb(this.breadcrumb);
    this.validationMaintenanceService.setSelectedCrossFieldValidation(crossFieldValidation);
    this.router.navigate(['validationAudit', { schemaType: this.user.currentSchema }]);
  }

  getTableData(validations: Array<CrossFieldValidation>): Array<any> {
    const tableData: Array<any> = [];
    validations.forEach((validation => {
      const data = {};
      data['name'] = validation.name;
      data['description'] = validation.description;
      data['active'] = validation.active;
      data['publicViewable'] = validation.publicViewable;
      if (this.isOnlineDataEntry) { data['validationLevel'] = validation.validationLevel; }
      data['expression'] = validation.expression;
      data['errorMessage'] = validation.errorMessage;
      data['edit'] = validation.id;
      data['audit'] = validation.id;
      tableData.push(data);
    }));
    return tableData;
  }

  getSortable(name: string): boolean {
    const nonSortable: Array<string> = ['edit', 'audit'];
    return _.indexOf(nonSortable, name) === -1;
  }

  getFlexGrow(name: string): number {
    const bigColumns = ['description', 'expression', 'errorMessage'];
    const buttonColumns = ['edit', 'audit'];
    if (_.indexOf(bigColumns, name) > -1) {
      return 3;
    }
    if (_.indexOf(buttonColumns, name) > -1) {
      return 2;
    }
    return 1;
  }

  getHeaderClass(name: string): string {
    const centeredHeaders = ['active', 'public', 'edit', 'audit'];
    if (_.indexOf(centeredHeaders, name) > -1) {
      return 'font-weight-bold text-center';
    }
    return 'font-weight-bold';
  }

  getCellClass(name: string): string {
    const centeredHeaders = ['active', 'public', 'edit', 'audit'];
    if (_.indexOf(centeredHeaders, name) > -1) {
      return 'text-center';
    }
    return '';
  }

  getValidationList(nameToSearch) {
    let matchedItems: CrossFieldValidation[] = [];
    for (let index = 0; index < this.crossFieldValidations.length; index++) {
      if (this.crossFieldValidations[index].name.toLowerCase().indexOf(nameToSearch.toLowerCase()) > -1) {
        matchedItems.push(this.crossFieldValidations[index]);
      }
    }
    this.rowData = this.getTableData(matchedItems);
  }

}
