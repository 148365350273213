<ngx-loading [show]="loading"></ngx-loading>

<app-messages></app-messages>
<div id="roleconfig">
  <div class="row page-title">
    <div class="col-12">
      <h1>{{title}}</h1>
    </div>
  </div>
  <br />
  <br />
  <div class="row">
    <div class="col-12" style="padding-left: 3rem;">
      <h4>Role Permissions</h4>
    </div>
  </div>
  <br />

  <form *ngIf="datacallSettings" (ngSubmit)="save()" [formGroup]="datacallSetupConfigurationsForm" name="datacallSetupConfigurationsForm">
    <div class="row" style="padding-left: 10rem;">
      <div class="col-12" formGroupName="userRole">
        <div class="form-group form-inline">
          <label for="role" class="font-weight-normal float-right" style="padding-left: 10rem; padding-right: 1rem;">User Group Role:</label>
          <input class="form-control form-control-sm" id="userRoleField" formControlName="role" type="text" maxlength="100" size="70" />
        </div>
      </div>
      <br />
      <br />
      <div class="col-12" formGroupName="adminRole">
        <div class="form-group form-inline">
          <label for="role" class="font-weight-normal float-right" style="padding-right:11px; padding-left: 6rem;">Administration Group Role:</label>
          <input class="form-control form-control-sm" id="adminRoleField" formControlName="role" type="text" maxlength="100" size="70" />
        </div>
      </div>
    </div>
  <br />
  <br />

  <app-datacall-setting [submissionSettingsForm]="datacallSetupConfigurationsForm"></app-datacall-setting>

  <div class="row col-12">
    <div class="col-4"></div>
    <div class="col-3"></div>
    <div class="col-3"></div>
    <div class="btn-group pull-right col-2" role="group">
      <button id="cancelButton" type="button" class="btn btn-link" (click)="reset()">Cancel</button>
      &nbsp;&nbsp;
      <button id="saveButton" class="btn btn-primary" [disabled]="!datacallSetupConfigurationsForm.valid || !datacallSetupConfigurationsForm.dirty">Save</button>
    </div>
  </div>
  <!-- <pre>{{datacallSetupConfigurationsForm.value | json}}</pre> -->
</form>

</div><!-- 
<ng-template #notAdmin>
  <div *ngIf="!loading" class="col-12 mx-auto main-display text-center pt-2">
    <h2>You are not authorized to view this page. Please contact MFL production support if you want to modify roles</h2>
  </div>
</ng-template> -->