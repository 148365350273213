import { Injectable } from '@angular/core';
import * as acorn from 'acorn';

@Injectable()
export class StringToModelParserService {
  validation: any = {};

  constructor() {
    this.validation = {};
    this.validation.name = 'V1-HasClaims';
    this.validation.tests = new Array();
  }

  visitNodes(nodes) { for (const node of nodes) { this.visitNode(node, node); } }

  visitNode(node, parentNode) {
    switch (node.type) {
      case 'Program': return this.visitProgram(node);
      case 'Identifier': return this.visitIdentifier(node);
      case 'Literal': return this.visitLiteral(node);
      case 'IfStatement': return this.visitIfStatement(node);
      case 'BinaryExpression': return this.visitBinaryExpression(node);
      case 'MemberExpression': return this.visitMemberExpression(node);
      case 'BlockStatement': return this.visitBlockStatement(node);
      case 'ReturnStatement': return this.visitReturnStatement(node);
      case 'LogicalExpression': return this.visitLogicalExpression(node, parentNode);
    }
  }

  visitProgram(node) { return this.visitNodes(node.body); }
  visitIdentifier(node) { return node.name; }
  visitLiteral(node) { return node.raw; }

  visitIfStatement(node, newTestModelObj?: any) {
    let testModelObj: any = {};
    if (!newTestModelObj) {
      testModelObj.name = 'Test-1';
    } else {
      testModelObj.name = newTestModelObj.name;
    }

    let expressions = new Array();
    expressions.push(this.visitNode(node.test, node));
    testModelObj.expressions = expressions;

    let trueActionObj: any = {};
    if (node.consequent.type === 'BlockStatement' && node.consequent.body[0].type === 'IfStatement') {
      trueActionObj.type = 'TEST-REFERENCE';
      trueActionObj.value = testModelObj.name + 'T';
      let newTestModelObj: any = {};
      newTestModelObj.name = testModelObj.name + 'T';
      this.visitIfStatement(node.consequent.body[0], newTestModelObj);
    } else {
      trueActionObj.type = 'VALIDATION_RESULT';
      trueActionObj.value = this.visitNode(node.consequent, node);
    }
    testModelObj.trueAction = trueActionObj;

    let falseActionObj: any = {};
    falseActionObj.type = 'VALIDATION_RESULT';
    falseActionObj.value = this.visitNode(node.alternate, node);
    testModelObj.falseAction = falseActionObj;

    this.validation.tests.push(testModelObj);
    return testModelObj;
  }

  visitBinaryExpression(node) {
    let expressionObj: any = {};
    expressionObj.expression = this.visitNode(node.left, node) + node.operator + this.visitNode(node.right, node);
    return expressionObj;
  }
  visitMemberExpression(node) { return this.visitNode(node.object, node) + '[' + this.visitNode(node.property, node) + ']'; }
  visitBlockStatement(node) { return this.visitNode(node.body[0], node); }
  visitReturnStatement(node) { return this.visitNode(node.argument, node); }

  visitLogicalExpression(node, parentNode) {
    let logicalExpressionObj: any = {};

    if (node.operator === '&&' || node.operator === '||') {
      if (parentNode.type === 'LogicalExpression' && (parentNode.operator === node.operator)) {
        parentNode.logicalExpressions.expressions.push(this.visitNode(node.left, node));
        parentNode.logicalExpressions.expressions.push(this.visitNode(node.right, node));
        return;
      }
      logicalExpressionObj.andOrCondition = node.operator;

    }

    logicalExpressionObj.expressions = new Array();
    node.logicalExpressions = logicalExpressionObj;
    logicalExpressionObj.expressions.push(this.visitNode(node.left, node));
    logicalExpressionObj.expressions.push(this.visitNode(node.right, node));
    return logicalExpressionObj;
  }

  visitUnaryExpression(node) {

    return node;
  }

  findValidationStartNode(node) {
    return node.body[0].body.body[0];
  }

  validationStringToModel(validationStr): any {
    let ast = acorn.parse('function run(data) {' + validationStr + '}');
    let visitor = new StringToModelParserService();
    let astValidationStartNode = visitor.findValidationStartNode(ast);
    JSON.stringify(visitor.visitNode(astValidationStartNode, null));
    return visitor.validation;
  }

}





