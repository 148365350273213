export const MOCK_PBR_DISCLAIMER_USER = {
  "schema": "PBR",
  "frequency": 1,
  "user": "pbruser",
  "needsAcceptance": true,
  "disclaimer": "I acknowledge that the information I am about to submit is being provided pursuant to the examination authority of the Kansas Insurance Department, which directed the submission of the information to the National Association of Insurance Commissioners. I further acknowledge I have proper authority to do so by the insurer or insurance group to which this information relates. I understand that I may access this application for the purposes of submitting the data, accessing validation reports, and withdrawing the submission upon completion of data validation, but I am not to access the application for any other purpose, including but not limited to reverse assembling, reverse engineering or reverse compiling of the contents of the application. I further understand that any improper access or use may result in the immediate termination of my access. This application is provided 'as-is' and the NAIC makes no warranties and assumes no responsibility for the timeliness, accuracy, completeness or mis-delivery of information."
};
export const MOCK_PBR_DISCLAIMER_ADMIN = {
  "schema": "PBR",
  "frequency": 1,
  "user": "pbradmin",
  "needsAcceptance": true,
  "disclaimer": "I acknowledge that the information I am about to submit is being provided pursuant to the examination authority of the Kansas Insurance Department, which directed the submission of the information to the National Association of Insurance Commissioners. I further acknowledge I have proper authority to do so by the insurer or insurance group to which this information relates. I understand that I may access this application for the purposes of submitting the data, accessing validation reports, and withdrawing the submission upon completion of data validation, but I am not to access the application for any other purpose, including but not limited to reverse assembling, reverse engineering or reverse compiling of the contents of the application. I further understand that any improper access or use may result in the immediate termination of my access. This application is provided 'as-is' and the NAIC makes no warranties and assumes no responsibility for the timeliness, accuracy, completeness or mis-delivery of information."
};
export const MOCK_PBR_DISCLAIMER_ACTUARY = {
  "schema": "PBR",
  "frequency": 1,
  "user": "pbractuary",
  "needsAcceptance": true,
  "disclaimer": "I acknowledge that the information I am about to submit is being provided pursuant to the examination authority of the Kansas Insurance Department, which directed the submission of the information to the National Association of Insurance Commissioners. I further acknowledge I have proper authority to do so by the insurer or insurance group to which this information relates. I understand that I may access this application for the purposes of submitting the data, accessing validation reports, and withdrawing the submission upon completion of data validation, but I am not to access the application for any other purpose, including but not limited to reverse assembling, reverse engineering or reverse compiling of the contents of the application. I further understand that any improper access or use may result in the immediate termination of my access. This application is provided 'as-is' and the NAIC makes no warranties and assumes no responsibility for the timeliness, accuracy, completeness or mis-delivery of information."
};
export const MOCK_CAS_DISCLAIMER_ADMIN = {
  "schema": "CAS",
  "frequency": 1,
  "user": "pbradmin",
  "needsAcceptance": true,
  "disclaimer": "I acknowledge that access to this application is provided pursuant to a written agreement between the NAIC and the Connecticut Insurance Department for purposes of a Homeowner’s Insurance study. I further acknowledge I have proper authority to do so by the Insurance Department.  I understand that I may access this application for the purposes of submitting relevant data, but I am not to access the application for any other purpose, including but not limited to reverse assembling, reverse engineering or reverse compiling of the contents of the application. I further understand that any improper access or use may result in the immediate termination of my access. This application is provided “as-is” and the NAIC makes no warranties and assumes no responsibility for the timeliness, accuracy, completeness or mis-delivery of information."
};
