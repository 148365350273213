import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DatacallSettingEnum } from './model/datacall-setting.enum';
import { DatacallSettings } from './model/datacall-setting';
import { DatacallSettingService } from './services/datacall-setting.service';
import { User } from '../../model/user';
import { UserService } from '../../service/user.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-datacall-setting',
  templateUrl: './datacall-setting.component.html',
  styleUrls: ['./datacall-setting.component.css']
})
export class DatacallSettingComponent implements OnInit {

  @Input() submissionSettingsForm: FormGroup;

  loading: boolean;
  subscriptions: Subscription[];

  user: User;
  datacallSettings: DatacallSettings[];
  updatedDatacallSetting: any[] = [];
  initialDatacallSettings: any[] = [];
  disableInputBox: boolean;
  initial: any;
  isOnlineDataEntry: boolean = false;

  constructor(
    private datacallSettingService: DatacallSettingService,
    private userService: UserService,
  ) {
    this.loading = true;
    this.subscriptions = [];
  }

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    this.updatedDatacallSetting = this.datacallSettings;
    this.isOnlineDataEntry = this.datacallSettingService.getOnlineDataEntrySetting();
    this.getDatacallSettings();
  }

  get submissionSettings(): FormArray {
    return this.submissionSettingsForm.get('submissionSettings') as FormArray;
  }

  getDatacallSettings(): void {
    this.loading = true;
    const subscription = this.datacallSettingService.getDatacallSettings(this.user.currentSchema).subscribe(
      res => {
        this.datacallSettings = res;
        this.updatedDatacallSetting = this.datacallSettings;
        this.setDatacallSettings();
        this.loading = false;
      }
    );
    this.subscriptions.push(subscription);
  }

  setDatacallSettings(): void {
    const settings = [
      DatacallSettingEnum.rejectSubmission,
      DatacallSettingEnum.copyToReportingDB,
      DatacallSettingEnum.userAssignment,
      DatacallSettingEnum.notificationOnSubmission,
      DatacallSettingEnum.notificationOnSubmissionEmailAddress
    ];
    
    if (this.isOnlineDataEntry) {
      settings.push(DatacallSettingEnum.allowAttachments);
    }
  
    this.updatedDatacallSetting = this.updatedDatacallSetting.filter(x => settings.includes(x.name));
    
    this.updatedDatacallSetting.forEach(y => {
      y.value = (y.name === DatacallSettingEnum.notificationOnSubmissionEmailAddress) ? y.value : (y.value === 'true');
    });
    
    this.createSubmissionSettingForm();
  }

  createSubmissionSettingForm() {
    const settings = this.submissionSettingsForm.get('submissionSettings') as FormArray;
    this.updatedDatacallSetting.map(x => {
      settings.push(this.setSubmissionSettings(x))
    });
    this.submissionSettingsForm.setControl('submissionSettings', settings);
    this.disableInput();
    this.getValueChanges();
    this.initial = this.submissionSettingsForm.getRawValue();
    this.datacallSettingService.setSubmissionSettingForm(this.initial);
  }

  getValueChanges() {
    this.submissionSettingsForm.get('submissionSettings').valueChanges
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe(v => {
        if (v) {
          this.disableInput();
        }
      });
  }

  setSubmissionSettings(x) {
    let formGroup: FormGroup;
    if(x.name === DatacallSettingEnum.notificationOnSubmissionEmailAddress){
      formGroup = new FormGroup({
        name: new FormControl(x.name),
        value: new FormControl(x.value, [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])
      });
    } else {
      formGroup = new FormGroup({
        name: new FormControl(x.name),
        value: new FormControl(x.value)
      });
    }
    return formGroup;
  }

  setSettingsName(settings, set): string {
    if (settings.name === DatacallSettingEnum.rejectSubmission) {
      return 'Reject submission for more than one unique set of submission key values per file';
    } else if (settings.name === DatacallSettingEnum.copyToReportingDB) {
      return 'Automatically move submitted data to reporting database based on submission keys';
    } else if (settings.name === DatacallSettingEnum.userAssignment) {
      return 'Use Assignment key for User Assignment';
    } else if (settings.name === DatacallSettingEnum.notificationOnSubmission) {
      return 'Send Notification when file is submitted (no errors or submitter accepted with errors)';
    } else if (settings.name === DatacallSettingEnum.notificationOnSubmissionEmailAddress) {
      return 'Email Address for Notification:';
    } else if (settings.name === DatacallSettingEnum.allowAttachments) {
      return 'Allow Attachments in ODE Datacall';
    }
  }

  disableInput() {
    if (!(this.submissionSettingsForm.get('submissionSettings') as FormArray).at(3).get('value').value) {
      (this.submissionSettingsForm.get('submissionSettings') as FormArray).at(4).get('name').disable();
      (this.submissionSettingsForm.get('submissionSettings') as FormArray).at(4).get('value').patchValue('');
      (this.submissionSettingsForm.get('submissionSettings') as FormArray).at(4).get('value').disable();
    } else {
      (this.submissionSettingsForm.get('submissionSettings') as FormArray).at(4).get('name').enable();
      (this.submissionSettingsForm.get('submissionSettings') as FormArray).at(4).get('value').enable();
    }
    this.disableCopyToReportingDBForODEDatacall();
  }

  disableCopyToReportingDBForODEDatacall() {
      if(this.isOnlineDataEntry) {
        (this.submissionSettingsForm.get('submissionSettings') as FormArray).at(0).get('name').disable();
        (this.submissionSettingsForm.get('submissionSettings') as FormArray).at(0).get('value').disable();
        (this.submissionSettingsForm.get('submissionSettings') as FormArray).at(1).get('name').disable();
        (this.submissionSettingsForm.get('submissionSettings') as FormArray).at(1).get('value').disable();
        (this.submissionSettingsForm.get('submissionSettings') as FormArray).at(2).get('name').disable();
        (this.submissionSettingsForm.get('submissionSettings') as FormArray).at(2).get('value').disable();
      } else {
        (this.submissionSettingsForm.get('submissionSettings') as FormArray).at(0).get('name').enable();
        (this.submissionSettingsForm.get('submissionSettings') as FormArray).at(0).get('value').enable();
        (this.submissionSettingsForm.get('submissionSettings') as FormArray).at(1).get('name').enable();
        (this.submissionSettingsForm.get('submissionSettings') as FormArray).at(1).get('value').enable();
        (this.submissionSettingsForm.get('submissionSettings') as FormArray).at(2).get('name').enable();
        (this.submissionSettingsForm.get('submissionSettings') as FormArray).at(2).get('value').enable();
      }
  }

  setSettingsId(settings): string {
    if (settings.name === DatacallSettingEnum.rejectSubmission) {
      return 'rejectSubmission';
    } else if (settings.name === DatacallSettingEnum.copyToReportingDB) {
      return 'copyToReportingDB';
    } else if (settings.name === DatacallSettingEnum.userAssignment) {
      return 'assignmentKeyForUserAssignment';
    } else if (settings.name === DatacallSettingEnum.notificationOnSubmission) {
      return 'notificationOnSubmission';
    } else if (settings.name === DatacallSettingEnum.notificationOnSubmissionEmailAddress) {
      return 'notificationOnSubmissionEmailAddress';
    } else if (settings.name === DatacallSettingEnum.allowAttachments) {
      return 'allowAttachments';
    }
  }

  setSettingsIdForLabel(settings): string {
    if (settings.name === DatacallSettingEnum.rejectSubmission) {
      return 'rejectSubmissionLabel';
    } else if (settings.name === DatacallSettingEnum.copyToReportingDB) {
      return 'copyToReportingDBLabel';
    } else if (settings.name === DatacallSettingEnum.userAssignment) {
      return 'assignmentKeyForUserAssignmentLabel';
    } else if (settings.name === DatacallSettingEnum.notificationOnSubmission) {
      return 'notificationOnSubmissionLabel';
    } else if (settings.name === DatacallSettingEnum.notificationOnSubmissionEmailAddress) {
      return 'notificationOnSubmissionEmailAddressLabel';
    } else if (settings.name === DatacallSettingEnum.allowAttachments) {
      return 'allowAttachmentsLabel';
    }
  }

  setSubmissionSettingForm() {
    this.datacallSettingService.setSubmissionSettingForm(this.initial);
  }

}
