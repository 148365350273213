import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RoleconfigComponent} from './roleconfig.component';
import {RoleconfigService} from './roleconfig.service';
import {LoadingModule} from 'ngx-loading';
import {MessagesModule} from '../messages/messages.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RoleconfigRoutingModule} from './roleconfig-routing.module';
import { DatacallSettingComponent } from './datacall-setting/datacall-setting.component';

@NgModule({
  declarations: [RoleconfigComponent, DatacallSettingComponent],
  imports: [
    CommonModule,
    MessagesModule,
    LoadingModule,
    FormsModule,
    ReactiveFormsModule,
    RoleconfigRoutingModule,
  ],
  exports: [RoleconfigComponent],
  providers: [RoleconfigService, RoleconfigComponent],
  entryComponents: []
})
export class RoleconfigModule { }
