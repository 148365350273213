import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {User} from '../model/user';
import {UserService} from '../service/user.service';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {SchemaSelectionService} from '../service/schema-selection.service';
import {UserAssignmentService} from '../user-assignment/services/user-assignment.service';
import {DatacallSettingService} from '../roleconfig/datacall-setting/services/datacall-setting.service';
import {
  ExpressionBuilderService
} from '../cross-field-validation-maintenance/expression-builder/services/expression-builder.service';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {
  CrossFieldValidationMaintenanceService
} from '../cross-field-validation-maintenance/services/cross-field-validation-maintenance.service';
import {CrossFieldValidation} from '../cross-field-validation-maintenance/model/cross-field-validation';
import {FormBuilderService} from '../form-builder/services/form-builder.service';
import {MessageService} from '../messages/service/message.service';
import {OnlineDataEntryService} from '../online-data-entry/services/online-data-entry.service';
import { FormSection } from '../form-builder/model/form-section';
import { DependenciesService } from '../form-builder/dependencies/services/dependencies.service';
import { CommonConfirmationModalComponent } from '../common-confirmation-modal/common-confirmation-modal.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit, OnDestroy {
  // TODO: Put the filing blank permissions back to using the required permission instead of being wide open
  isNavCollapsed = true;
  error = false;
  messageText: string;
  schemaSubject: BehaviorSubject<string>;
  private _message = new Subject<string>();
  user: User;
  subscriptions: Array<Subscription> = [];
  modalRef: BsModalRef;
  links = {
    rdcHome: {
      isActive: true,
      isVisible: true
    },
    fileDashboard: {
      isActive: false,
      isVisible: false
    },
    aggregationMaintenance: {
      isActive: false,
      isVisible: false
    },
    schemaMaintenance: {
      isActive: false,
      isVisible: false
    },
    fileAdmin: {
      isActive: false,
      isVisible: false
    },
    toleranceMaintenance: {
      isActive: false,
      isVisible: false
    },
    filingBlankEditor: {
      isActive: false,
      isVisible: false
    },
    userAssignmentAdmin: {
      isActive: false,
      isVisible: false
    },
    userAssignment: {
      isActive: false,
      isVisible: false
    },
    formBuilder: {
      isActive: false,
      isVisible: false
    },
    filingSearch: {
      isActive: false,
      isVisible: false
    },
    schemaSelection: {
      isActive: false,
      isVisible: false
    }
  };
  crossFieldValidation: CrossFieldValidation;
  routingLink: string;
  title: string;
  testExpressionFlag: boolean;

  constructor(
    private userService: UserService,
    private schemaSelectionService: SchemaSelectionService,
    private datacallSettingService: DatacallSettingService,
    private userAssignmentService: UserAssignmentService,
    private expressionBuilderService: ExpressionBuilderService,
    private router: Router,
    private validationMaintenanceService: CrossFieldValidationMaintenanceService,
    private modalService: BsModalService,
    private formBuilderService: FormBuilderService,
    private messageService: MessageService,
    private onlineDataEntryService: OnlineDataEntryService,
    private dependenciesService: DependenciesService
  ) {
  }

  public get showUserFilling(): boolean {
    return this.router.url.startsWith('/dataEntry') || (this.datacallSettingService.getOnlineDataEntrySetting() && this.links.userAssignment.isVisible);
  }

  ngOnInit() {
    this._message.subscribe(message => this.messageText = message);
    this.schemaSelectionService.selectedSchemaBeforeDisclaimerAcceptance.subscribe(schemaBeforeAcceptance => {
      this.setLinksBeforeDisclaimerAccepted();
    });
    this.schemaSelectionService.selectedSchemaAfterDisclaimerAcceptance.subscribe(schemaAfterAcceptance => {
      this.setLinksAfterDisclaimerAccepted();
    });
    this.testExpressionFlag = this.expressionBuilderService.getValidationNotSavedFromTestExpressionPage();

  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  setLinksAfterDisclaimerAccepted() {
    this.user = this.userService.getStoredUser();
    this.links.fileDashboard.isVisible = (this.userService.canViewFileDashboard(this.user) && !this.datacallSettingService.getOnlineDataEntrySetting());
    if (this.user.selectedDatacallGroup === 'pbr' || this.user.selectedDatacallGroup === 'cas' || this.user.selectedDatacallGroup === 'rdc') {
      this.links.aggregationMaintenance.isVisible = true;
    } else {
      this.links.aggregationMaintenance.isVisible = false;
    }
    this.links.schemaMaintenance.isVisible = this.userService.canViewSchemaMaintenanceLink(this.user);
    this.links.schemaSelection.isVisible = !this.userService.isUserNotAuthorized(this.user);
    this.links.fileAdmin.isVisible = this.userService.canViewFileAdminLink(this.user);
    this.links.toleranceMaintenance.isVisible = this.userService.canViewToleranceMaintenanceLink(this.user);
    // this.links.userAssignmentAdmin.isVisible = this.datacallSettingService.showUserAssignmentsLink === (undefined || false) ? false : true;

    if (this.datacallSettingService.showUserAssignmentsLink === undefined || this.datacallSettingService.showUserAssignmentsLink === false) {
      this.links.userAssignmentAdmin.isVisible = false;
    } else {
      this.links.userAssignmentAdmin.isVisible = true;
    }
    if (this.userAssignmentService.showuserAssignmentsPrimaryLink === undefined ||
      this.userAssignmentService.showuserAssignmentsPrimaryLink === false) {
      this.links.userAssignment.isVisible = false;
    } else {
      this.links.userAssignment.isVisible = true;
    }
    this.links.formBuilder.isVisible = this.datacallSettingService.getOnlineDataEntrySetting();
    this.links.filingSearch.isVisible = this.datacallSettingService.getOnlineDataEntrySetting();
    // this.links.aggregationMaintenance.isVisible = this.userService.canViewAggregationMaintenanceLink(this.user);
    // this.links.filingBlankEditor.isVisible = this.userService.isMcsAdmin(this.user);
  }

  setLinksBeforeDisclaimerAccepted() {
    this.links.fileDashboard.isVisible = false;
    this.links.aggregationMaintenance.isVisible = false;
    this.links.schemaMaintenance.isVisible = false;
    this.links.fileAdmin.isVisible = false;
    this.links.toleranceMaintenance.isVisible = false;
    this.links.userAssignmentAdmin.isVisible = false;
    this.links.userAssignment.isVisible = false;
    this.links.filingSearch.isVisible = false;
  }

  hasMultiplePermittedSchemas(): boolean {
    return this.userService.hasMultiplePermittedSchemas(this.user);
  }

  public get dataEntryReRoutingEnabled(): boolean {
    this.routingLink = 'schemaSelection';
    if (this.router.url.startsWith('/dataEntry') && this.onlineDataEntryService.dataChanged) {
      return false;
    }
    return true;
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
  }

  routeToClickedPage(routingLink: string, template: TemplateRef<any>) {
    if (this.dependenciesService.isUserOnDependencyPage) {
      this.showCommonModalAndRoute(routingLink);
    } else {
      this.messageService.clear();
      this.formBuilderService.setNewSectionKey('', '', [new FormSection()])
      if (routingLink === 'formBuilder') {
        this.userService.dataEntry = true;
      } else {
        this.userService.dataEntry = false;
      }
      this.routingLink = routingLink;
      if (this.expressionBuilderService.getRoutingHelper() || this.expressionBuilderService.getValidationNotSavedFromTestExpressionPage() || this.onlineDataEntryService.dataChanged) {
        this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
      } else {
        this.formBuilderService.dataChange.next([]);
        this.formBuilderService.isTitleVisible = true;
        this.router.navigate([`/${routingLink}`]);
      }
    }
  }

  showCommonModalAndRoute(routingLink) {
    const initialState = {
      message: 'Do you wish to proceed? Any changes will be lost.',
      title: 'Form Builder'
    };
    let isFormChanged: boolean = false;
    if(_.isEqual(this.formBuilderService.selectedExistingRuleKeys, this.dependenciesService.selectedExistingRuleKeys) === true) {
      isFormChanged = true;
    } else {
      if(this.dependenciesService.selectedExistingRuleKeys.length === 0) {
        isFormChanged = true;
      }
    }
    if(!isFormChanged) {
      const bsModalRef = this.modalService.show(CommonConfirmationModalComponent, { initialState, class: 'modal-lg' });
      bsModalRef.content.isYesButtonClicked.subscribe((data: any) => {
        if (data) {
          this.formBuilderService.dataChange.next([]);
          this.formBuilderService.isTitleVisible = true;
          this.router.navigate([`/${routingLink}`]);
        }
        bsModalRef.hide();
      });
    } else {
      this.formBuilderService.dataChange.next([]);
      this.formBuilderService.isTitleVisible = true;
      this.router.navigate([`/${routingLink}`]);
    }
  }

  /*
   * This method is a version of the `navigateToClickedPage` method below.
   * The need to have a better way to manage messages on the user filing dashboard page through several scenarios.
   * `navigateToClickedPage` is calling various services (form builder and expression builder) even though they are not necessary to update if a user is not on those pages.
   * todo: fill out the remaining switch statements and refactor references to `navigateToClickedPage` to use this method instead.
   */

  async navigateWithoutSavingToPage(page: string) {
    switch (page) {
      case 'user-filing' :
        this.modalRef.hide();
        this.messageService.clear();
        this.onlineDataEntryService.dataChanged = false;
        await this.router.navigate([`/${this.routingLink}`]);
    }
  }

  navigateToClickedPage() {
    this.modalRef.hide();
    this.expressionBuilderService.setRoutingHelper(false);
    this.expressionBuilderService.setValidationNotSavedFromTestExpressionPage(false);
    this.formBuilderService.setChangeStatus(false);
    this.formBuilderService.showDependencyPage.next(false);
    this.formBuilderService.dataChange.next([]);
    this.router.navigate([`/${this.routingLink}`]);
  }

  redirectToUserFiling(template) {
    if (!this.onlineDataEntryService.dataChanged) {
      //this.messageService.clear();
      this.router.navigate([`/user-filing`, this.user.currentSchema]);
    } else {
      this.routingLink = `/user-filing/${this.user.currentSchema}`;
      this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
    }

  }
}
