import { Injectable } from '@angular/core';
import {HandleError, HttpErrorHandler} from '../../shared/http-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CrossFieldJsValidation} from '../model/cross-field-js-validation';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';

@Injectable()
export class FilingValidationService {
  private storedValidation: CrossFieldJsValidation;
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('FilingValidationService');
  }

  getStoredValidation(): CrossFieldJsValidation {
    return this.storedValidation;
  }

  setStoredValidation(validation: CrossFieldJsValidation): void {
    this.storedValidation = validation;
  }

  getCrossFieldJsValidations(schema: string, current?: boolean): Observable<CrossFieldJsValidation[]> {
    const url = `${environment.apiUrl}${schema}/crossfieldvalidationjs?current=${current || true}`;
    return this.httpClient.get<CrossFieldJsValidation[]>(url)
      .pipe(catchError(this.handleError('getCrossFieldJsValidations', [])));
  }

  postCrossFieldJsValidations(schema: string, validation: CrossFieldJsValidation): Observable<any> {
    const url = `${environment.apiUrl}${schema}/crossfieldvalidationjs`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {headers: headers};
    return this.httpClient.post(url, validation, options)
      .pipe(catchError(this.handleError('postCrossFieldJsValidations', null)));
  }

}
