export const MOCK_VALIDATION_SCHEMAS = [
  {
    _id: {
      timestamp: 1479419118,
      machineIdentifier: 16586175,
      processIdentifier: 11214,
      counter: 4588612,
      date: 1479419118000,
      timeSecond: 1479419118,
      time: 1479419118000
    },
    type: 'TEST',
    created: 1468960307259,
    modified: 1475933740078,
    userCreated: 'test',
    dateCreated: 1479419118768,
    properties: {
      naic_company_code: {
        description: 'The unique identifier for a company.  I am changing the description to test if the change saves.',
        type: 'string',
        pattern: '\\d{5}',
        name: 'naic_company_code',
        required: true
      },
      observation_year: {
        type: 'number',
        pattern: '[12]\\d{3}',
        maxLength: 4,
        minLength: 4,
        name: 'observation_year',
        min: '1000',
        max: '2100',
        required: true
      },
      policy_number: {
        name: 'policy_number',
        type: 'string',
        maxLength: 20
      },
      segment_number: {
        name: 'segment_number',
        type: 'number',
        maxLength: 3
      },
      issue_state: {
        type: 'string',
        enum: [
          'AL',
          'AK',
          'AZ',
          'AR',
          'CA',
          'CO',
          'CT',
          'DE',
          'FL',
          'GA',
          'GU',
          'HI',
          'ID',
          'IL',
          'IN',
          'IA',
          'KS',
          'KY',
          'LA',
          'ME',
          'MD',
          'MA',
          'MI',
          'MN',
          'MS',
          'MO'
        ],
        name: 'issue_state',
        required: true
      }
    },
    id: '582b5ecebd8e8b190cf62ac0'
  },
];
