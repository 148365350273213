import { RouterModule, Routes } from '@angular/router';
import { FileDashboardComponent } from './file-dashboard.component';
import { NgModule } from '@angular/core';
import { FileSubmissionErrorsComponent } from './file-submission-errors/file-submission-errors.component';
import { FileSubmissionErrorTypeComponent } from './file-submission-error-type/file-submission-error-type.component';

const fileDashboardRoutes: Routes = [
  { path: 'fileDashboard', component: FileDashboardComponent },
  { path: 'fileSubmissionErrors', component: FileSubmissionErrorsComponent },
  { path: 'fileSubmissionErrorType', component: FileSubmissionErrorTypeComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(fileDashboardRoutes)
  ],
  exports: [RouterModule]
})
export class FileDashboardRoutingModule { }
