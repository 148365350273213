export const MOCK_FILING_DATES = {
  "2016":{
    "_id":{
      "timestamp":1490915513,
      "machineIdentifier":11694000,
      "processIdentifier":25275,
      "counter":6217554,
      "timeSecond":1490915513,
      "date":1490915513000,
      "time":1490915513000
    },
    "dataYear":"2016",
    "dateStart":"2016-01-27T00:00:00.000Z",
    "dateEnd":"2016-05-28T00:00:00.000Z",
    "userAdded":"jepaul",
    "dateAdded":1490915513605,
    "userLastModified":"vcpbradmin",
    "dateLastModified":1503684127900
  },
  "2015":{
    "_id":{
      "timestamp":1490915563,
      "machineIdentifier":11694000,
      "processIdentifier":25275,
      "counter":6217555,
      "timeSecond":1490915563,
      "date":1490915563000,
      "time":1490915563000
    },
    "dataYear":"2015",
    "dateStart":"2017-01-01T06:00:00.000Z",
    "dateEnd":"2017-03-31T05:00:00.000Z",
    "userAdded":"jepaul",
    "dateAdded":1490915563502,
    "userLastModified":"jepaul",
    "dateLastModified":1493129593478
  },
  "2017":{
    "_id":{
      "timestamp":1490915589,
      "machineIdentifier":11694000,
      "processIdentifier":25275,
      "counter":6217556,
      "timeSecond":1490915589,
      "date":1490915589000,
      "time":1490915589000
    },
    "dataYear":"2017",
    "dateStart":"2017-09-01T05:00:00.000Z",
    "dateEnd":"2017-09-30T05:00:00.000Z",
    "userAdded":"jepaul",
    "dateAdded":1490915589540,
    "userLastModified":"jepaul",
    "dateLastModified":1503946990348
  },
  "2019":{
    "_id":{
      "timestamp":1490917282,
      "machineIdentifier":11694000,
      "processIdentifier":25275,
      "counter":6217559,
      "timeSecond":1490917282,
      "date":1490917282000,
      "time":1490917282000
    },
    "dataYear":"2019",
    "dateStart":"2020-01-01T06:00:00.000Z",
    "dateEnd":"2020-12-31T06:00:00.000Z",
    "userAdded":"jepaul",
    "dateAdded":1490917282396,
    "userLastModified":"jepaul",
    "dateLastModified":1491308615909
  },
  "2014":{
    "_id":{
      "timestamp":1493735917,
      "machineIdentifier":11694000,
      "processIdentifier":14909,
      "counter":14236394,
      "timeSecond":1493735917,
      "date":1493735917000,
      "time":1493735917000
    },
    "dataYear":"2014",
    "dateStart":"2017-05-30T05:00:00.000Z",
    "dateEnd":"2017-08-31T05:00:00.000Z",
    "userAdded":"jepaul",
    "dateAdded":1493735917330
  },
  "2012":{
    "_id":{
      "timestamp":1493896858,
      "machineIdentifier":11694000,
      "processIdentifier":14909,
      "counter":5521613,
      "timeSecond":1493896858,
      "date":1493896858000,
      "time":1493896858000
    },
    "dataYear":"2012",
    "dateStart":"2001-11-11T06:00:00.000Z",
    "dateEnd":"2002-11-11T06:00:00.000Z",
    "userAdded":"jepaul",
    "dateAdded":1493896858406,
    "userLastModified":"vcarli",
    "dateLastModified":1507756311377
  },
  "2020":{
    "_id":{
      "timestamp":1494448292,
      "machineIdentifier":11694000,
      "processIdentifier":15335,
      "counter":15747613,
      "timeSecond":1494448292,
      "date":1494448292000,
      "time":1494448292000
    },
    "dataYear":"2020",
    "dateStart":"2017-05-09T05:00:00.000Z",
    "dateEnd":"2017-05-19T05:00:00.000Z",
    "userAdded":"jepaul",
    "dateAdded":1494448292956,
    "userLastModified":"jepaul",
    "dateLastModified":1494448307157
  },
  "2028":{
    "_id":{
      "timestamp":1507242411,
      "machineIdentifier":11694000,
      "processIdentifier":21324,
      "counter":304582,
      "timeSecond":1507242411,
      "date":1507242411000,
      "time":1507242411000
    },
    "dataYear":"2028",
    "dateStart":"2017-10-02T05:00:00.000Z",
    "dateEnd":"2017-10-18T05:00:00.000Z",
    "userAdded":"vcpbradmin",
    "dateAdded":1507242411578
  },
  "2000":{
    "_id":{
      "timestamp":1507756293,
      "machineIdentifier":11694000,
      "processIdentifier":21324,
      "counter":304601,
      "timeSecond":1507756293,
      "date":1507756293000,
      "time":1507756293000
    },
    "dataYear":"2000",
    "dateStart":"1111-01-01T06:00:00.000Z",
    "dateEnd":"2222-02-02T06:00:00.000Z",
    "userAdded":"vcarli",
    "dateAdded":1507756293215,
    "userLastModified":"vcarli",
    "dateLastModified":1507756890999
  }
};
