import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModule } from 'ngx-loading';
import { SchemaAuditComponent } from './schema-audit.component';
import { RouterModule } from '@angular/router';
import { SchemaViewModule } from './schema-view/schema-view.module';
import { PipesModule } from '../../pipes/pipes.module';
import { AgGridModule } from 'ag-grid-angular';
import { DateRendererComponent } from '../../cell-renderer/date-renderer/date-renderer.component';
import { ViewRendererComponent } from './cell-renderer/view-renderer/view-renderer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SchemaViewModule,
    PipesModule,
    AgGridModule.withComponents([DateRendererComponent, ViewRendererComponent]),
    FontAwesomeModule,
    LoadingModule,
  ],
  declarations: [SchemaAuditComponent, ViewRendererComponent],
  exports: [SchemaAuditComponent]
})
export class SchemaAuditModule { }
