import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {FilingBlankLayoutService} from './service/filing-blank-layout.service';
import {UserService} from '../service/user.service';
import {User} from '../model/user';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import {MessageService} from '../messages/service/message.service';
import {AlertMessage} from '../messages/model/alert-message';

@Component({
  selector: 'app-filing-blank-layout',
  templateUrl: './filing-blank-layout.component.html'
})
export class FilingBlankLayoutComponent implements OnInit, OnDestroy {
  editorOptions: JsonEditorOptions;
  data: any;
  @ViewChild(JsonEditorComponent, {static:true}) editor: JsonEditorComponent;
  title = 'Filing Blank Layout Editor';
  messageText: string;
  error: boolean;
  user: User;

  constructor(
    private userService: UserService,
    private filingBlankLayoutService: FilingBlankLayoutService,
    private messageService: MessageService,
  ) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['text', 'tree'];
    this.editorOptions.mode = 'text';

  }

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    this.getFilingBlankLayout();
  }

  ngOnDestroy() {
    this.messageService.clear();
  }

  getFilingBlankLayout(): void {
    this.filingBlankLayoutService.getFilingBlankLayout(this.user.currentSchema, true).subscribe(
      response => {
        this.data = response;
      }
    );
  }

  putFilingBlankLayout(): void {
    this.filingBlankLayoutService.putFilingBlankLayout(this.user.currentSchema, this.editor.get()).subscribe(
      () => {
        this.messageText = 'Filing blank layout has been saved';
        this.messageService.add(new AlertMessage(this.messageText, 'success'));
      }
    );
  }

}
