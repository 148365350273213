<div class="row">
  <div class="col-12" style="padding-left: 3rem;">
    <h4>Submission Settings</h4>
  </div>
</div>
<br />
<div *ngIf="datacallSettings" [formGroup]="submissionSettingsForm">

  <div class="col-12" style="padding-left:10rem" formArrayName="submissionSettings"
    *ngFor="let settings of submissionSettings.controls; let i=index;">

    <div [formGroupName]="i">

      <div class="form-group form-inline" *ngIf="settings.value.name === 'NOTIFICATION_ON_SUBMISSION_EMAIL_ADDRESS' else showCheckbox" style="padding-left: 50px;">
        <label for="value" style="padding-right: 15px;" id="{{setSettingsIdForLabel(settings.value)}}" class="font-weight-normal text-right pl-2">{{setSettingsName(settings.value, settings)}}</label>
        <input [class.is-invalid]="settings.get('value').invalid && settings.get('value').touched" class="form-control form-control-sm" id="{{setSettingsId(settings.value)}}" type="email" size="70" formControlName="value">

      </div>

      <ng-template #showCheckbox>
        <input id="{{setSettingsId(settings.value)}}" type="checkbox" formControlName="value">
        <label for="value" id="{{setSettingsIdForLabel(settings.value)}}" class="font-weight-normal text-right pl-2">{{setSettingsName(settings.value)}}</label>
      </ng-template>

    </div>

  </div>

</div>