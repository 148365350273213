import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AvailablePropertyTypes } from '../../services/file-validation-maintenance.service';

@Component({
  selector: 'app-dropdown-field-renderer',
  templateUrl: './dropdown-field-renderer.component.html'
})
export class DropdownFieldRendererComponent implements ICellRendererAngularComp {
  params: any;
  propertyTypes: string[];



  agInit(params: any): void {
    this.params = params;
    this.propertyTypes = AvailablePropertyTypes;
  }

  refresh(): boolean {
    return false;
  }

  checkValue(event: any, data) {
    this.params.context.componentParent.changeDropdownField(event.target.value, data.schemaProperty, this.params.colDef.field);
  }

}

