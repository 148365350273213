import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FileValidationMaintenanceComponent} from './file-validation-maintenance.component';
import {SchemaAuditComponent} from './schema-audit/schema-audit.component';
import {SchemaViewComponent} from './schema-audit/schema-view/schema-view.component';
import {SchemaPropertyViewComponent} from './schema-property-view/schema-property-view.component';

const fileValidationMaintenanceRoutes: Routes = [
  {path: 'schemaMaintenance', component: FileValidationMaintenanceComponent},
  {path: 'schemaAudit', component: SchemaAuditComponent},
  {path: 'schemaView', component: SchemaViewComponent},
  {path: 'schemaPropertyMaintenance', component: SchemaPropertyViewComponent},
];

@NgModule({
  imports: [
    RouterModule.forChild(fileValidationMaintenanceRoutes)
  ],
  exports: [RouterModule]
})
export class FileValidationMaintenanceRoutingModule { }
