import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {User} from '../model/user';
import {UserService} from '../service/user.service';
import {FilingSchemaService} from './service/filing-schema.service';
import {JsonEditorComponent, JsonEditorOptions} from 'ang-jsoneditor';
import {MessageService} from '../messages/service/message.service';
import {AlertMessage} from '../messages/model/alert-message';

@Component({
  selector: 'app-filing-schema',
  templateUrl: './filing-schema.component.html'
})
export class FilingSchemaComponent implements OnInit, OnDestroy {
  editorOptions: JsonEditorOptions;
  data: any;
  @ViewChild(JsonEditorComponent, {static:true}) editor: JsonEditorComponent;
  title: string = 'Filing Schema Editor';
  messageText: string;
  error: boolean;
  user: User;

  constructor(
    private userService: UserService,
    private filingSchemaService: FilingSchemaService,
    private messageService: MessageService,
  ) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['text', 'tree'];
    this.editorOptions.mode = 'text';
  }

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    this.getFilingSchema();
  }

  ngOnDestroy() {
    this.messageService.clear();
  }

  getFilingSchema() {
    this.filingSchemaService.getCurrentValidationSchemaByType(this.user.currentSchema).subscribe(
      response => {
        this.data = response;
      }
    )
  }

  postFilingSchema(): void {
    this.filingSchemaService.saveValidationSchema(this.editor.get()).subscribe(
      () => {
        this.messageText = 'Filing blank layout has been saved';
        this.messageService.add(new AlertMessage(this.messageText, 'success'));
      }
    )
  }

}
