<ngx-loading [show]="loading"></ngx-loading>
<div id="file-admin" class="wrapper">
  <div class="row page-title">
    <div class="col-12">
      <h1>{{title}}</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <app-messages></app-messages>
    </div>
  </div>

  <div class="row">
    <div class="col" style="padding-left:3rem;">
      <table id="summaryTable" *ngIf="dataSource" mat-table [dataSource]="dataSource" class="mat-elevation-z4 col-10"
        style="margin: auto;">

        <!-- Status/Description Column -->
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef style="padding-left:20rem; color:black" id="status::description"><strong>Status/Description</strong> </th>
          <td mat-cell *matCellDef="let element" style="padding-left:20rem" id="status::{{element.value}}"> {{element.value}} </td>
        </ng-container>

        <!-- Count Column -->
        <ng-container matColumnDef="numberOfFilings">
          <th mat-header-cell *matHeaderCellDef style="color: black" id="count"><strong>Count</strong> </th>
          <td mat-cell *matCellDef="let element" id="count::{{element.value}}::{{element.numberOfFilings}}"> {{element.numberOfFilings}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" style="background-color: silver; height: 40px;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="height: 30px;"></tr>

        <!-- Group header -->
        <ng-container matColumnDef="groupHeader">
          <th colspan="999" mat-cell *matCellDef="let groupBy" style="background-color: gainsboro; padding-left: 2rem;" id="{{groupBy.initial}}">
            {{groupBy.initial}}</th>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" style="height: 37px;"> </tr>

      </table>
    </div>
  </div>
</div>