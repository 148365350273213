/*
 * Copyright 2018 National Association of Insurance Commissioners
 */

import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RoleconfigComponent} from './roleconfig.component';


const roleconfigRoutes: Routes = [
  { path: 'roleconfig', component: RoleconfigComponent },
];

@NgModule({
  imports: [
    RouterModule.forChild(roleconfigRoutes)
  ],
  exports: [RouterModule]
})
export class RoleconfigRoutingModule { }
