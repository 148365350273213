import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {FileSubmission} from '../../../model/file-submission';
import {FileSubmissionStateModel} from '../model/file-submission-state-model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HandleError, HttpErrorHandler} from '../../../shared/http-error-handler.service';
import {catchError} from 'rxjs/operators';

@Injectable()
export class UpdateStatusService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('AggregationMaintenanceService');
  }

  getStatuses(fileSubmission: FileSubmission): Observable<string[]> {
    return this.httpClient.get<string[]>(`${environment.apiUrl}${fileSubmission.schemaType}/roleconfig/role/currentuserroles/status/${fileSubmission.fileSubmissionState}/canChangeTo`)
      .pipe(catchError(this.handleError('getStatuses', [])));
  }

  updateFileSubmissionState(schemaType: string, fileSubmissionState: FileSubmissionStateModel): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = {headers: headers};
    return this.httpClient.put<any>(`${environment.apiUrl}${schemaType}/fileadmin`, fileSubmissionState, options)
      .pipe(catchError(this.handleError('updateFileSubmissionState', null)));
  }

}
