import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDatacallNameValidator]'
})
export class DatacallNameValidatorDirective {

  private regex: RegExp = new RegExp(/^[a-zA-Z0-9_]*$/); // Only letters, numbers, and underscores

  constructor(private el: ElementRef, private control: NgControl) { }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const inputElement = this.el.nativeElement;
    let input = inputElement.value;

    // Restrict to 40 characters
    if (input.length > 40) {
      input = input.substring(0, 40);
    }

    input = input.replace(/[^a-zA-Z0-9_]/g, ''); // Invalid Charecter Regex

    // Remove leading numbers or underscores
    while (/^[0-9_]/.test(input)) {
      input = input.substring(1);
    }

    // Convert to uppercase
    input = input.toUpperCase();

    // Update the form control value without emitting event (this is to prevent stack size overload)
    if (this.control && this.control.control) {
      this.control.control.setValue(input, { emitEvent: false });
    }
  }
}
