export const MOCK_FILE_SUBMISSIONS = [
    {
      'id': '59e61795b26fb0534c04a604',
      'fileName': 'a44197ed-6d1a-4fac-ac67-1b363db14c3d1508251541444.csv',
      'originalFileName': '2013 250 records Duel header &442 obs year.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '99999',
            'naic'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2014',
            'o'
          ]
        }
      ],
      'totalErrorCount': 0,
      'totalPublicErrorCount': 100,
      'formFormatErrorCount': 0,
      'formFormatPublicErrorCount': 0,
      'crossFieldErrorCount': 0,
      'crossFieldPublicErrorCount': 0,
      'fileSubmissionState': 'SYSTEM_REJECTED_FILE',
      'submittedBy': 'jepaul',
      'created': 1508251541474,
      'modified': 1517258420284,
      'uploadTimeInSeconds': 0.03,
      'fileSizeInBytes': 27532,
      'schemaType': 'PBR',
      'rejectionReasons': ['OVER_TOLERANCE','NON_UNIQUE_SUBMISSION_KEY','NOT_ASSIGNED_COCODE'],
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1508251541477
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1508251545083
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_PENDING_REVALIDATION',
          'modified': 1516656487045
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'RERUN_VALIDATIONS',
          'modified': 1516656487264
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'FILE_PENDING_NAIC_REVIEW',
          'modified': 1516656489420
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_PENDING_REVALIDATION',
          'modified': 1516714520718
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'RERUN_VALIDATIONS',
          'modified': 1516714520803
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'FILE_PENDING_NAIC_REVIEW',
          'modified': 1516714522441
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_PENDING_REVALIDATION',
          'modified': 1516715480929
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'RERUN_VALIDATIONS',
          'modified': 1516715481028
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'FILE_PENDING_NAIC_REVIEW',
          'modified': 1516715482266
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_PENDING_REVALIDATION',
          'modified': 1516715688980
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'RERUN_VALIDATIONS',
          'modified': 1516715689054
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'FILE_PENDING_NAIC_REVIEW',
          'modified': 1516715690297
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'NAIC_REVIEW_IN_PROGRESS',
          'modified': 1516820137353
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'NAIC_ACCEPTS_WITH_ERRORS',
          'modified': 1516820144306
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'NAIC_REVIEW_COMPLETE',
          'modified': 1516820149634
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'ACTUARY_CONDUCTING_REASONABILITY_CHECK',
          'modified': 1516820154775
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'REASONABILITY_CHECK_COMPLETE',
          'modified': 1516820162949
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'PENDING_REASONABILITY_CHECK',
          'modified': 1517236855179
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'ACTUARY_CONDUCTING_REASONABILITY_CHECK',
          'modified': 1517236982596
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'RELOAD_FILING',
          'modified': 1517255807122
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'RELOAD_FILING',
          'modified': 1517255872262
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'RELOAD_FILING',
          'modified': 1517258420221
        }
      ],
      'validateAndSaveTimeInSeconds': 3.32,
      'formFormatTimeInSeconds': 0.44,
      'crossFieldTimeInSeconds': 0.96,
      'insertTimeInSeconds': 2.07,
      'totalDocuments': 249,
      'insertionComplete': 'true',
      'notes': [
        {
          'updateBy': 'jepaul',
          'note': 'entering a note.',
          'modified': 1517236855176
        },
        {
          'updateBy': 'jepaul',
          'note': 'this is an additional note',
          'modified': 1517255182652
        }
      ],
      'submissionReloaded': 'true',
      'lastReloaded': 1517258420219,
      'initialSubmissionProcessingDone': true,
      'initialSubmissionProcessingDoneTime': 1517258420284
    },
    {
      'id': '59e617acb26fb0534c04a605',
      'fileName': 'f6706598-3529-4cec-ba61-2ebc23f23b0d1508251564355.csv',
      'originalFileName': '2013 250 records no header.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '99999'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2014'
          ]
        }
      ],
      'totalErrorCount': 0,
      'totalPublicErrorCount': 0,
      'formFormatErrorCount': 0,
      'formFormatPublicErrorCount': 0,
      'crossFieldErrorCount': 0,
      'crossFieldPublicErrorCount': 0,
      'rejectionReasons': ['OVER_TOLERANCE','NON_UNIQUE_SUBMISSION_KEY','NOT_ASSIGNED_COCODE'],
      'fileSubmissionState': 'SYSTEM_REJECTED_FILE',
      'submittedBy': 'jepaul',
      'created': 1508251564369,
      'modified': 1517256783856,
      'uploadTimeInSeconds': 0.01,
      'fileSizeInBytes': 26327,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1508251564371
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1508251566448
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'SENDER_REJECTED_FILE',
          'modified': 1509652609547
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'RELOAD_FILING',
          'modified': 1517256783408
        }
      ],
      'validateAndSaveTimeInSeconds': 1.9,
      'formFormatTimeInSeconds': 0.15,
      'crossFieldTimeInSeconds': 0.7,
      'insertTimeInSeconds': 1.15,
      'totalDocuments': 249,
      'insertionComplete': 'true',
      'submissionReloaded': 'true',
      'lastReloaded': 1517256783444,
      'initialSubmissionProcessingDone': true,
      'initialSubmissionProcessingDoneTime': 1517256783856
    },
    {
      'id': '59e6182bb26fb0534c04a606',
      'fileName': 'c3508e06-17de-4424-8835-2a6cc94d88181508251691951.csv',
      'originalFileName': '2013 250 records obs year 2.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '99999'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            'obs&&&',
            '2014',
            'obs_year'
          ]
        }
      ],
      'totalErrorCount': 0,
      'totalPublicErrorCount': 0,
      'formFormatErrorCount': 0,
      'formFormatPublicErrorCount': 0,
      'crossFieldErrorCount': 0,
      'crossFieldPublicErrorCount': 0,
      'rejectionReasons': ['OVER_TOLERANCE','NON_UNIQUE_SUBMISSION_KEY','NOT_ASSIGNED_COCODE'],
      'fileSubmissionState': 'SYSTEM_REJECTED_FILE',
      'submittedBy': 'jepaul',
      'created': 1508251691979,
      'modified': 1517256790893,
      'uploadTimeInSeconds': 0.04,
      'fileSizeInBytes': 26954,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1508251691981
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1508251697456
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_PENDING_REVALIDATION',
          'modified': 1516714532459
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'RERUN_VALIDATIONS',
          'modified': 1516714532523
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'FILE_PENDING_NAIC_REVIEW',
          'modified': 1516714534190
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'RELOAD_FILING',
          'modified': 1517256790678
        }
      ],
      'validateAndSaveTimeInSeconds': 5.18,
      'formFormatTimeInSeconds': 0.51,
      'crossFieldTimeInSeconds': 2.42,
      'insertTimeInSeconds': 2.83,
      'totalDocuments': 249,
      'insertionComplete': 'true',
      'submissionReloaded': 'true',
      'lastReloaded': 1517256790695,
      'initialSubmissionProcessingDone': true,
      'initialSubmissionProcessingDoneTime': 1517256790893
    },
    {
      'id': '59e6186db26fb0534c04a607',
      'fileName': '0f891a9a-4be3-4b1e-bfd9-6ec1dde6114a1508251757574.csv',
      'originalFileName': '2014 99998 Two Headers.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '99998',
            'naic'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2014',
            'o'
          ]
        }
      ],
      'totalErrorCount': 0,
      'totalPublicErrorCount': 0,
      'formFormatErrorCount': 0,
      'formFormatPublicErrorCount': 0,
      'crossFieldErrorCount': 0,
      'crossFieldPublicErrorCount': 0,
      'rejectionReasons': ['OVER_TOLERANCE','NON_UNIQUE_SUBMISSION_KEY','NOT_ASSIGNED_COCODE'],
      'fileSubmissionState': 'SYSTEM_REJECTED_FILE',
      'submittedBy': 'jepaul',
      'created': 1508251757702,
      'modified': 1517256832937,
      'uploadTimeInSeconds': 0.14,
      'fileSizeInBytes': 1925,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1508251757724
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1508251759343
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'RELOAD_FILING',
          'modified': 1517256832757
        }
      ],
      'validateAndSaveTimeInSeconds': 0.92,
      'formFormatTimeInSeconds': 0.41,
      'crossFieldTimeInSeconds': 0.03,
      'insertTimeInSeconds': 0.35,
      'totalDocuments': 7,
      'insertionComplete': 'true',
      'submissionReloaded': 'true',
      'lastReloaded': 1517256832767,
      'initialSubmissionProcessingDone': true,
      'initialSubmissionProcessingDoneTime': 1517256832937
    },
    {
      'id': '59e618c5b26fb0534c04a608',
      'fileName': 'cceea074-8884-424c-9ba0-067b3bad175a1508251845629.csv',
      'originalFileName': '2014 99998 2  Dupes.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '99998',
            'naic'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2014',
            'o'
          ]
        }
      ],
      'totalErrorCount': 78,
      'totalPublicErrorCount': 78,
      'formFormatErrorCount': 76,
      'formFormatPublicErrorCount': 76,
      'crossFieldErrorCount': 2,
      'crossFieldPublicErrorCount': 2,
      'fileSubmissionState': 'SENDER_REJECTED_FILE',
      'submittedBy': 'jepaul',
      'created': 1508251845651,
      'modified': 1508251846089,
      'uploadTimeInSeconds': 0.02,
      'fileSizeInBytes': 2135,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1508251845656
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1508251846092
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'SENDER_REJECTED_FILE',
          'modified': 1509652611637
        }
      ],
      'validateAndSaveTimeInSeconds': 0.26,
      'formFormatTimeInSeconds': 0.13,
      'crossFieldTimeInSeconds': 0.02,
      'insertTimeInSeconds': 0.08,
      'totalDocuments': 9,

      'insertionComplete': 'true'
    },
    {
      'id': '59e61998b26fb0534c04a609',
      'fileName': '1f1e4dcf-48d8-44aa-bf34-ea1a020254c61508252056696.csv',
      'originalFileName': '2015 99999 2  Dupes.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '99998',
            '99999'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2015'
          ]
        }
      ],
      'totalErrorCount': 20,
      'totalPublicErrorCount': 20,
      'formFormatErrorCount': 18,
      'formFormatPublicErrorCount': 18,
      'crossFieldErrorCount': 2,
      'crossFieldPublicErrorCount': 2,
      'fileSubmissionState': 'PENDING_REASONABILITY_CHECK',
      'submittedBy': 'jepaul',
      'created': 1508252056718,
      'modified': 1516714534518,
      'uploadTimeInSeconds': 0.02,
      'fileSizeInBytes': 1536,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1508252056720
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1508252057155
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_PENDING_NAIC_REVIEW',
          'modified': 1510150875036
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'NAIC_REVIEW_IN_PROGRESS',
          'modified': 1513023053487
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'NAIC_ACCEPTS_WITH_ERRORS',
          'modified': 1513023078702
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'NAIC_REVIEW_COMPLETE',
          'modified': 1513023092064
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'ACTUARY_CONDUCTING_REASONABILITY_CHECK',
          'modified': 1513023121404
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_PENDING_REVALIDATION',
          'modified': 1516714534423
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'RERUN_VALIDATIONS',
          'modified': 1516714534452
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'FILE_PENDING_NAIC_REVIEW',
          'modified': 1516714534521
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'NAIC_REVIEW_IN_PROGRESS',
          'modified': 1517257620567
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'NAIC_ACCEPTS_WITH_ERRORS',
          'modified': 1517257629596
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'NAIC_REVIEW_COMPLETE',
          'modified': 1517257636080
        }
      ],
      'validateAndSaveTimeInSeconds': 0.23,
      'formFormatTimeInSeconds': 0.11,
      'crossFieldTimeInSeconds': 0.1,
      'insertTimeInSeconds': 0.08,
      'totalDocuments': 9,
      'insertionComplete': 'true'
    },
    {
      'id': '5a294eccb26fb0140524194c',
      'fileName': '26158042-cfe0-493e-8ee8-4da5966512b11512656588052.xlsx',
      'originalFileName': '60054_10k.xlsx',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '60054'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2015'
          ]
        }
      ],
      'totalErrorCount': 4614,
      'totalPublicErrorCount': 4304,
      'formFormatErrorCount': 0,
      'formFormatPublicErrorCount': 0,
      'crossFieldErrorCount': 4614,
      'crossFieldPublicErrorCount': 4304,
      'fileSubmissionState': 'PARTIALLY_CREDIBLE',
      'submittedBy': 'vcpbradmin',
      'created': 1512656588125,
      'modified': 1516992374571,
      'uploadTimeInSeconds': 0.75,
      'fileSizeInBytes': 911756,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1512656588189
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1512656660323
        },
        {
          'updateBy': 'vcarli',
          'fileSubmissionState': 'FILE_PENDING_REVALIDATION',
          'modified': 1516393834567
        },
        {
          'updateBy': 'vcarli',
          'fileSubmissionState': 'RERUN_VALIDATIONS',
          'modified': 1516393836642
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'FILE_PENDING_NAIC_REVIEW',
          'modified': 1516393858616
        },
        {
          'updateBy': 'vcarli',
          'fileSubmissionState': 'DISCREPANCY',
          'modified': 1516394032652
        },
        {
          'updateBy': 'vcarli',
          'fileSubmissionState': 'FILE_PENDING_REVALIDATION',
          'modified': 1516992347242
        },
        {
          'updateBy': 'vcarli',
          'fileSubmissionState': 'RERUN_VALIDATIONS',
          'modified': 1516992349891
        },
        {
          'updateBy': 'vcarli',
          'fileSubmissionState': 'DISCREPANCY',
          'modified': 1516992354732
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'FILE_PENDING_NAIC_REVIEW',
          'modified': 1516992377626
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'NAIC_REVIEW_IN_PROGRESS',
          'modified': 1517257647208
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'NAIC_ACCEPTS_WITH_ERRORS',
          'modified': 1517257673738
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'NAIC_REVIEW_COMPLETE',
          'modified': 1517257681678
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'ACTUARY_CONDUCTING_REASONABILITY_CHECK',
          'modified': 1517257699819
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'REASONABILITY_CHECK_COMPLETE',
          'modified': 1517257807438
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'PARTIALLY_CREDIBLE',
          'modified': 1517257972266
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'PARTIALLY_CREDIBLE',
          'modified': 1517258005797
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'PARTIALLY_CREDIBLE',
          'modified': 1517258173150
        }
      ],
      'validateAndSaveTimeInSeconds': 78.21,
      'formFormatTimeInSeconds': 16.62,
      'crossFieldTimeInSeconds': 28.3,
      'insertTimeInSeconds': 42.6,
      'totalDocuments': 10000,
      'insertionComplete': 'true',
      'notes': [
        {
          'updateBy': 'jepaul',
          'note': 'this company is partially credible due to findings during actuarial study.',
          'modified': 1517257970754
        }
      ]
    },
    {
      'id': '5a29560cb26fb0140524194d',
      'fileName': 'dcf69997-a850-4197-a02b-2b2d7daf3f141512658443954.xlsx',
      'originalFileName': '60054_10k.xlsx',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '60054'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2015'
          ]
        }
      ],
      'totalErrorCount': 5007,
      'totalPublicErrorCount': 4697,
      'formFormatErrorCount': 393,
      'formFormatPublicErrorCount': 393,
      'crossFieldErrorCount': 4614,
      'crossFieldPublicErrorCount': 4304,
      'fileSubmissionState': 'PENDING_USER_SUBMISSION',
      'submittedBy': 'vcpbradmin',
      'created': 1512658444042,
      'modified': 1512658510865,
      'uploadTimeInSeconds': 0.82,
      'fileSizeInBytes': 911756,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1512658444053
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1512658512123
        }
      ],
      'validateAndSaveTimeInSeconds': 76.15,
      'formFormatTimeInSeconds': 11.94,
      'crossFieldTimeInSeconds': 21.11,
      'insertTimeInSeconds': 45.45,
      'totalDocuments': 10000,
      'insertionComplete': 'true'
    },
    {
      'id': '5a29569ab26fb0140524194e',
      'fileName': '8d956de1-ba7d-4e2d-ad72-d03ee5b21fe41512658586021.xlsx',
      'originalFileName': '60054_10k.xlsx',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '60054'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2015'
          ]
        }
      ],
      'totalErrorCount': 5007,
      'totalPublicErrorCount': 4697,
      'formFormatErrorCount': 393,
      'formFormatPublicErrorCount': 393,
      'crossFieldErrorCount': 4614,
      'crossFieldPublicErrorCount': 4304,
      'fileSubmissionState': 'PENDING_USER_SUBMISSION',
      'submittedBy': 'vcpbradmin',
      'created': 1512658586163,
      'modified': 1512658669252,
      'uploadTimeInSeconds': 0.64,
      'fileSizeInBytes': 911756,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1512658586188
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1512658670385
        }
      ],
      'validateAndSaveTimeInSeconds': 81.89,
      'formFormatTimeInSeconds': 15.47,
      'crossFieldTimeInSeconds': 35.62,
      'insertTimeInSeconds': 51.04,
      'totalDocuments': 10000,
      'insertionComplete': 'true'
    },
    {
      'id': '5a295707b26fb0140524194f',
      'fileName': '1af9018f-237b-4575-a581-0751901126be1512658695985.csv',
      'originalFileName': '60054singlerow.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '60054'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2014'
          ]
        }
      ],
      'totalErrorCount': 1,
      'totalPublicErrorCount': 0,
      'formFormatErrorCount': 0,
      'formFormatPublicErrorCount': 0,
      'crossFieldErrorCount': 1,
      'crossFieldPublicErrorCount': 0,
      'fileSubmissionState': 'SENDER_REJECTED_FILE',
      'submittedBy': 'vcpbradmin',
      'created': 1512658695998,
      'modified': 1512658696233,
      'uploadTimeInSeconds': 0.01,
      'fileSizeInBytes': 739,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1512658696000
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1512658696239
        },
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'SENDER_REJECTED_FILE',
          'modified': 1512658698723
        }
      ],
      'validateAndSaveTimeInSeconds': 0.07,
      'formFormatTimeInSeconds': 0.01,
      'crossFieldTimeInSeconds': 0.0,
      'insertTimeInSeconds': 0.01,
      'totalDocuments': 1,
      'insertionComplete': 'true'
    },
    {
      'id': '5a295738b26fb01405241950',
      'fileName': 'ba5dfeb6-64bf-48e3-9e42-1c7f1dac497a1512658744211.csv',
      'originalFileName': '60054singlerow.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '60054'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2014'
          ]
        }
      ],
      'totalErrorCount': 1,
      'totalPublicErrorCount': 0,
      'formFormatErrorCount': 0,
      'formFormatPublicErrorCount': 0,
      'crossFieldErrorCount': 1,
      'crossFieldPublicErrorCount': 0,
      'fileSubmissionState': 'SENDER_REJECTED_FILE',
      'submittedBy': 'vcpbradmin',
      'created': 1512658744243,
      'modified': 1512658744474,
      'uploadTimeInSeconds': 0.02,
      'fileSizeInBytes': 739,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1512658744245
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1512658744498
        },
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'SENDER_REJECTED_FILE',
          'modified': 1512658776344
        }
      ],
      'validateAndSaveTimeInSeconds': 0.08,
      'formFormatTimeInSeconds': 0.02,
      'crossFieldTimeInSeconds': 0.0,
      'insertTimeInSeconds': 0.01,
      'totalDocuments': 1,
      'insertionComplete': 'true'
    },
    {
      'id': '5a295751b26fb01405241951',
      'fileName': '5412c69d-590d-4b0c-8942-f98b9aa380161512658769738.csv',
      'originalFileName': '60054singlerow.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '60054'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2014'
          ]
        }
      ],
      'totalErrorCount': 2,
      'totalPublicErrorCount': 1,
      'formFormatErrorCount': 1,
      'formFormatPublicErrorCount': 1,
      'crossFieldErrorCount': 1,
      'crossFieldPublicErrorCount': 0,
      'fileSubmissionState': 'FILE_PENDING_NAIC_REVIEW',
      'submittedBy': 'vcpbradmin',
      'created': 1512658769763,
      'modified': 1512658769997,
      'uploadTimeInSeconds': 0.01,
      'fileSizeInBytes': 739,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1512658769774
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1512658770000
        },
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'FILE_PENDING_NAIC_REVIEW',
          'modified': 1512658772039
        }
      ],
      'validateAndSaveTimeInSeconds': 0.1,
      'formFormatTimeInSeconds': 0.02,
      'crossFieldTimeInSeconds': 0.0,
      'insertTimeInSeconds': 0.01,
      'totalDocuments': 1,
      'insertionComplete': 'true'
    },
    {
      'id': '5a2957aab26fb01405241952',
      'fileName': '65c37c56-28a8-495a-a4ff-e418342423ed1512658858425.csv',
      'originalFileName': 'fakecsv.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [

          ]
        },
        {
          'name': 'observation_year',
          'values': [

          ]
        }
      ],
      'totalErrorCount': 0,
      'totalPublicErrorCount': 0,
      'formFormatErrorCount': 0,
      'formFormatPublicErrorCount': 0,
      'crossFieldErrorCount': 0,
      'crossFieldPublicErrorCount': 0,
      'rejectionReasons': null,
      'fileSubmissionState': 'SYSTEM_REJECTED_FILE',
      'submittedBy': 'vcpbradmin',
      'created': 1512658858473,
      'modified': 1512658858757,
      'uploadTimeInSeconds': 0.02,
      'fileSizeInBytes': 7,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1512658858476
        }
      ],
      'initialSubmissionProcessingDone': true,
      'insertionComplete': 'true',
      'initialSubmissionProcessingDoneTime': 1512658858759
    },
    {
      'id': '5a2957e3b26fb01405241953',
      'fileName': 'c5a0adaa-25d9-4e90-addb-e79b4d206c461512658914831.csv',
      'originalFileName': '60054Smaller Sample.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '60054'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2014'
          ]
        }
      ],
      'totalErrorCount': 25652,
      'totalPublicErrorCount': 24237,
      'formFormatErrorCount': 0,
      'formFormatPublicErrorCount': 0,
      'crossFieldErrorCount': 25652,
      'crossFieldPublicErrorCount': 24237,
      'fileSubmissionState': 'PENDING_USER_SUBMISSION',
      'submittedBy': 'vcpbradmin',
      'created': 1512658915049,
      'modified': 1512659210594,
      'uploadTimeInSeconds': 2.21,
      'fileSizeInBytes': 4690940,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1512658915067
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1512659218484
        }
      ],
      'validateAndSaveTimeInSeconds': 294.3,
      'formFormatTimeInSeconds': 92.75,
      'crossFieldTimeInSeconds': 149.46,
      'insertTimeInSeconds': 268.86,
      'totalDocuments': 51049,
      'insertionComplete': 'true'
    },
    {
      'id': '5a295814b26fb01405241954',
      'fileName': '1ff4d9b4-e47b-431f-8d40-896d758f113e1512658964368.csv',
      'originalFileName': '60054 - SmallerSample&more.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '60054'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2014'
          ]
        }
      ],
      'totalErrorCount': 27513,
      'totalPublicErrorCount': 26098,
      'formFormatErrorCount': 1861,
      'formFormatPublicErrorCount': 1861,
      'crossFieldErrorCount': 25652,
      'crossFieldPublicErrorCount': 24237,
      'fileSubmissionState': 'PENDING_USER_SUBMISSION',
      'submittedBy': 'vcpbradmin',
      'created': 1512658964624,
      'modified': 1512659492855,
      'uploadTimeInSeconds': 2.56,
      'fileSizeInBytes': 4690940,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1512658964639
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1512659499830
        }
      ],
      'validateAndSaveTimeInSeconds': 273.48,
      'formFormatTimeInSeconds': 77.16,
      'crossFieldTimeInSeconds': 145.45,
      'insertTimeInSeconds': 254.0,
      'totalDocuments': 51049,
      'insertionComplete': 'true'
    },
    {
      'id': '5a2961e5b26fb01405241955',
      'fileName': '420f15f6-5427-4da2-91eb-b2c702844c251512661477344.xlsx',
      'originalFileName': '60054_10k.xlsx',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '60054'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2015'
          ]
        }
      ],
      'totalErrorCount': 5007,
      'totalPublicErrorCount': 4697,
      'formFormatErrorCount': 393,
      'formFormatPublicErrorCount': 393,
      'crossFieldErrorCount': 4614,
      'crossFieldPublicErrorCount': 4304,
      'fileSubmissionState': 'PENDING_USER_SUBMISSION',
      'submittedBy': 'vcpbradmin',
      'created': 1512661477374,
      'modified': 1512661587405,
      'uploadTimeInSeconds': 0.49,
      'fileSizeInBytes': 911756,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1512661477383
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1512661588429
        }
      ],
      'validateAndSaveTimeInSeconds': 109.1,
      'formFormatTimeInSeconds': 17.04,
      'crossFieldTimeInSeconds': 33.7,
      'insertTimeInSeconds': 71.08,
      'totalDocuments': 10000,
      'insertionComplete': 'true'
    },
    {
      'id': '5a296281b26fb01405241956',
      'fileName': '2022e799-4a35-4abb-9b7f-730eb86f73371512661633132.csv',
      'originalFileName': '60054singlerow.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '60054'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2014'
          ]
        }
      ],
      'totalErrorCount': 2,
      'totalPublicErrorCount': 1,
      'formFormatErrorCount': 1,
      'formFormatPublicErrorCount': 1,
      'crossFieldErrorCount': 1,
      'crossFieldPublicErrorCount': 0,
      'fileSubmissionState': 'SENDER_REJECTED_FILE',
      'submittedBy': 'vcpbradmin',
      'created': 1512661633165,
      'modified': 1512661633437,
      'uploadTimeInSeconds': 0.01,
      'fileSizeInBytes': 739,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1512661633180
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1512661633442
        },
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'SENDER_REJECTED_FILE',
          'modified': 1512661635881
        }
      ],
      'validateAndSaveTimeInSeconds': 0.06,
      'formFormatTimeInSeconds': 0.01,
      'crossFieldTimeInSeconds': 0.0,
      'insertTimeInSeconds': 0.02,
      'totalDocuments': 1,
      'insertionComplete': 'true'
    },
    {
      'id': '5a2962a4b26fb01405241957',
      'fileName': 'a659da3b-480b-48fb-a487-36edb905e3421512661668246.csv',
      'originalFileName': '60054singlerow.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '60054'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2014'
          ]
        }
      ],
      'totalErrorCount': 0,
      'totalPublicErrorCount': 0,
      'formFormatErrorCount': 0,
      'formFormatPublicErrorCount': 0,
      'crossFieldErrorCount': 0,
      'crossFieldPublicErrorCount': 0,
      'rejectionReasons': ['OVER_TOLERANCE','NON_UNIQUE_SUBMISSION_KEY','NOT_ASSIGNED_COCODE'],
      'fileSubmissionState': 'SYSTEM_REJECTED_FILE',
      'submittedBy': 'vcpbradmin',
      'created': 1512661668255,
      'modified': 1517258427180,
      'uploadTimeInSeconds': 0.01,
      'fileSizeInBytes': 739,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1512661668258
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1512661668470
        },
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'FILE_PENDING_NAIC_REVIEW',
          'modified': 1512661670915
        },
        {
          'updateBy': 'vcarli',
          'fileSubmissionState': 'NAIC_REVIEW_IN_PROGRESS',
          'modified': 1515165072552
        },
        {
          'updateBy': 'vcarli',
          'fileSubmissionState': 'NAIC_ACCEPTS_WITH_ERRORS',
          'modified': 1515165093900
        },
        {
          'updateBy': 'vcarli',
          'fileSubmissionState': 'NAIC_REVIEW_COMPLETE',
          'modified': 1515165106355
        },
        {
          'updateBy': 'vcarli',
          'fileSubmissionState': 'ACTUARY_CONDUCTING_REASONABILITY_CHECK',
          'modified': 1515165544155
        },
        {
          'updateBy': 'vcarli',
          'fileSubmissionState': 'ACTUARY_AWAITING_RESPONSE',
          'modified': 1515165557843
        },
        {
          'updateBy': 'vcarli',
          'fileSubmissionState': 'ACTUARY_RESPONSE_RECEIVED',
          'modified': 1515165568057
        },
        {
          'updateBy': 'vcarli',
          'fileSubmissionState': 'REASONABILITY_CHECK_COMPLETE',
          'modified': 1515165583338
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'RELOAD_FILING',
          'modified': 1517258427094
        }
      ],
      'validateAndSaveTimeInSeconds': 0.11,
      'formFormatTimeInSeconds': 0.02,
      'crossFieldTimeInSeconds': 0.0,
      'insertTimeInSeconds': 0.01,
      'totalDocuments': 1,
      'insertionComplete': 'true',
      'submissionReloaded': 'true',
      'lastReloaded': 1517258427096,
      'initialSubmissionProcessingDone': true,
      'initialSubmissionProcessingDoneTime': 1517258427180
    },
    {
      'id': '5a2962cbb26fb01405241958',
      'fileName': '0ca0d8b2-0216-4e73-85e6-f756b3b366d81512661707321.csv',
      'originalFileName': '60054singlerow.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '60054'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2014'
          ]
        }
      ],
      'totalErrorCount': 2,
      'totalPublicErrorCount': 1,
      'formFormatErrorCount': 1,
      'formFormatPublicErrorCount': 1,
      'crossFieldErrorCount': 1,
      'crossFieldPublicErrorCount': 0,
      'fileSubmissionState': 'PENDING_USER_SUBMISSION',
      'submittedBy': 'vcpbradmin',
      'created': 1512661707396,
      'modified': 1512661708060,
      'uploadTimeInSeconds': 0.06,
      'fileSizeInBytes': 739,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1512661707402
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1512661708099
        }
      ],
      'validateAndSaveTimeInSeconds': 0.14,
      'formFormatTimeInSeconds': 0.04,
      'crossFieldTimeInSeconds': 0.0,
      'insertTimeInSeconds': 0.01,
      'totalDocuments': 1,
      'insertionComplete': 'true'
    },
    {
      'id': '5a296354b26fb01405241959',
      'fileName': '98a8181f-399f-46cd-b1de-a99605b31ec11512661843610.csv',
      'originalFileName': '60054Smaller Sample.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '60054'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2014'
          ]
        }
      ],
      'totalErrorCount': 27513,
      'totalPublicErrorCount': 26098,
      'formFormatErrorCount': 1861,
      'formFormatPublicErrorCount': 1861,
      'crossFieldErrorCount': 25652,
      'crossFieldPublicErrorCount': 24237,
      'fileSubmissionState': 'PENDING_USER_SUBMISSION',
      'submittedBy': 'vcpbradmin',
      'created': 1512661844063,
      'modified': 1512662236312,
      'uploadTimeInSeconds': 2.97,
      'fileSizeInBytes': 4690940,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1512661844086
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1512662241944
        }
      ],
      'validateAndSaveTimeInSeconds': 391.17,
      'formFormatTimeInSeconds': 99.14,
      'crossFieldTimeInSeconds': 182.34,
      'insertTimeInSeconds': 355.88,
      'totalDocuments': 51049,
      'insertionComplete': 'true'
    },
    {
      'id': '5a296378b26fb0140524195a',
      'fileName': 'be6e7dfc-bd38-4f57-b034-62600dfae1481512661880379.csv',
      'originalFileName': '60054 - SmallerSample&more.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '60054'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2014'
          ]
        }
      ],
      'totalErrorCount': 27513,
      'totalPublicErrorCount': 26098,
      'formFormatErrorCount': 1861,
      'formFormatPublicErrorCount': 1861,
      'crossFieldErrorCount': 25652,
      'crossFieldPublicErrorCount': 24237,
      'fileSubmissionState': 'PENDING_USER_SUBMISSION',
      'submittedBy': 'vcpbradmin',
      'created': 1512661880588,
      'modified': 1512662626806,
      'uploadTimeInSeconds': 2.25,
      'fileSizeInBytes': 4690940,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'vcpbradmin',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1512661880594
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1512662633089
        }
      ],
      'validateAndSaveTimeInSeconds': 383.51,
      'formFormatTimeInSeconds': 99.07,
      'crossFieldTimeInSeconds': 197.06,
      'insertTimeInSeconds': 352.49,
      'totalDocuments': 51049,
      'insertionComplete': 'true'
    },
    {
      'id': '5a2ed406b26fb0140524195b',
      'fileName': '425c9f74-d0ad-477d-9503-cde4a310ce911513018374522.csv',
      'originalFileName': '10022_2015_spun.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            'Company',
            '10022'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            'Policy Year',
            '2015'
          ]
        }
      ],
      'totalErrorCount': 46028,
      'totalPublicErrorCount': 20,
      'formFormatErrorCount': 20,
      'formFormatPublicErrorCount': 20,
      'crossFieldErrorCount': 46008,
      'crossFieldPublicErrorCount': 0,
      'fileSubmissionState': 'FILE_PENDING_NAIC_REVIEW',
      'submittedBy': 'jepaul',
      'created': 1513018374588,
      'modified': 1513018605637,
      'uploadTimeInSeconds': 0.29,
      'fileSizeInBytes': 4618822,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1513018374591
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1513018611016
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_PENDING_NAIC_REVIEW',
          'modified': 1517256848253
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_PENDING_NAIC_REVIEW',
          'modified': 1517256853868
        }
      ],
      'validateAndSaveTimeInSeconds': 230.22,
      'formFormatTimeInSeconds': 34.08,
      'crossFieldTimeInSeconds': 120.85,
      'insertTimeInSeconds': 211.82,
      'totalDocuments': 46070,
      'insertionComplete': 'true'
    },
    {
      'id': '5a2ed423b26fb0140524195c',
      'fileName': 'a2ce09b4-d4fe-48c9-a2f7-f572485086221513018403296.csv',
      'originalFileName': '2013 250 records no issue age.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '0',
            '99999',
            '11111'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '0',
            '2014'
          ]
        }
      ],
      'totalErrorCount': 764,
      'totalPublicErrorCount': 763,
      'formFormatErrorCount': 579,
      'formFormatPublicErrorCount': 579,
      'crossFieldErrorCount': 85,
      'crossFieldPublicErrorCount': 84,
      'fileSubmissionState': 'SENDER_REJECTED_FILE',
      'submittedBy': 'jepaul',
      'created': 1513018403335,
      'modified': 1513018613207,
      'uploadTimeInSeconds': 0.05,
      'fileSizeInBytes': 25721,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1513018403347
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1513018613256
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'SENDER_REJECTED_FILE',
          'modified': 1516711081734
        }
      ],
      'validateAndSaveTimeInSeconds': 1.68,
      'formFormatTimeInSeconds': 0.14,
      'crossFieldTimeInSeconds': 0.82,
      'insertTimeInSeconds': 0.91,
      'totalDocuments': 249,
      'insertionComplete': 'true'
    },
    {
      'id': '5a2ed4e7b26fb0140524195d',
      'fileName': '4d4bdf6d-1719-445a-ad8c-45987909c6a41513018599804.csv',
      'originalFileName': '2013TestFile3_spunExtra.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '99999',
            '11111'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2014',
            '2013'
          ]
        }
      ],
      'totalErrorCount': 38,
      'totalPublicErrorCount': 38,
      'formFormatErrorCount': 34,
      'formFormatPublicErrorCount': 34,
      'crossFieldErrorCount': 4,
      'crossFieldPublicErrorCount': 4,
      'fileSubmissionState': 'SENDER_REJECTED_FILE',
      'submittedBy': 'jepaul',
      'created': 1513018599851,
      'modified': 1513018613903,
      'uploadTimeInSeconds': 0.05,
      'fileSizeInBytes': 2726,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1513018599856
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1513018613913
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'SENDER_REJECTED_FILE',
          'modified': 1513021407095
        }
      ],
      'validateAndSaveTimeInSeconds': 0.24,
      'formFormatTimeInSeconds': 0.06,
      'crossFieldTimeInSeconds': 0.06,
      'insertTimeInSeconds': 0.14,
      'totalDocuments': 21,
      'insertionComplete': 'true'
    },
    {
      'id': '5a2edf06b26fb0140524195e',
      'fileName': '3ab75774-9e82-409c-bc0c-402f5787e44e1513021190928.csv',
      'originalFileName': '2013 250 records no issue age.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '0',
            '99999',
            '11111'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '0',
            '2014'
          ]
        }
      ],
      'totalErrorCount': 0,
      'totalPublicErrorCount': 0,
      'formFormatErrorCount': 0,
      'formFormatPublicErrorCount': 0,
      'crossFieldErrorCount': 0,
      'crossFieldPublicErrorCount': 0,
      'rejectionReasons': ['OVER_TOLERANCE','NON_UNIQUE_SUBMISSION_KEY','NOT_ASSIGNED_COCODE'],
      'fileSubmissionState': 'SYSTEM_REJECTED_FILE',
      'submittedBy': 'jepaul',
      'created': 1513021190962,
      'modified': 1517256872411,
      'uploadTimeInSeconds': 0.01,
      'fileSizeInBytes': 25721,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1513021190976
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1513021194002
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_PENDING_NAIC_REVIEW',
          'modified': 1513021370171
        },
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'RELOAD_FILING',
          'modified': 1517256872134
        }
      ],
      'validateAndSaveTimeInSeconds': 1.72,
      'formFormatTimeInSeconds': 0.33,
      'crossFieldTimeInSeconds': 1.01,
      'insertTimeInSeconds': 0.88,
      'totalDocuments': 249,
      'insertionComplete': 'true',
      'submissionReloaded': 'true',
      'lastReloaded': 1517256872154,
      'initialSubmissionProcessingDone': true,
      'initialSubmissionProcessingDoneTime': 1517256872411
    },
    {
      'id': '5a4f954db26fb014f6a37cbc',
      'fileName': '9d86e953-b9d6-4748-b555-ddd7d46c3a531515165004728.csv',
      'originalFileName': '60054Smaller Sample.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [
            '60054'
          ]
        },
        {
          'name': 'observation_year',
          'values': [
            '2014'
          ]
        }
      ],
      'totalErrorCount': 27513,
      'totalPublicErrorCount': 26098,
      'formFormatErrorCount': 1861,
      'formFormatPublicErrorCount': 1861,
      'crossFieldErrorCount': 25652,
      'crossFieldPublicErrorCount': 24237,
      'fileSubmissionState': 'PENDING_USER_SUBMISSION',
      'submittedBy': 'vcarli',
      'created': 1515165004993,
      'modified': 1515165263071,
      'uploadTimeInSeconds': 0.33,
      'fileSizeInBytes': 4690940,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'vcarli',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1515165005088
        },
        {
          'updateBy': 'System',
          'fileSubmissionState': 'PENDING_USER_SUBMISSION',
          'modified': 1515165268919
        }
      ],
      'validateAndSaveTimeInSeconds': 253.79,
      'formFormatTimeInSeconds': 71.32,
      'crossFieldTimeInSeconds': 151.83,
      'insertTimeInSeconds': 223.75,
      'totalDocuments': 51049,
      'insertionComplete': 'true'
    },
    {
      'id': '5a69ecb3b26fb0668826cac5',
      'fileName': '36fe65a0-f50d-4251-bb84-35d7b3f1169c1516891315772.csv',
      'originalFileName': '60054 - SmallerSample&morePBR177.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [

          ]
        },
        {
          'name': 'observation_year',
          'values': [

          ]
        }
      ],
      'totalErrorCount': 0,
      'totalPublicErrorCount': 0,
      'formFormatErrorCount': 0,
      'formFormatPublicErrorCount': 0,
      'crossFieldErrorCount': 0,
      'crossFieldPublicErrorCount': 0,
      'rejectionReasons': ['OVER_TOLERANCE','NON_UNIQUE_SUBMISSION_KEY','NOT_ASSIGNED_COCODE'],
      'fileSubmissionState': 'SYSTEM_REJECTED_FILE',
      'submittedBy': 'vcpbruser',
      'created': 1516891315960,
      'modified': 1516891316791,
      'uploadTimeInSeconds': 0.29,
      'fileSizeInBytes': 4690936,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'vcpbruser',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1516891315971
        }
      ],
      'initialSubmissionProcessingDone': true,
      'insertionComplete': 'true',
      'initialSubmissionProcessingDoneTime': 1516891316791
    },
    {
      'id': '5a6f815eb26fb02b086fb60c',
      'fileName': 'cc28bf9b-d8d5-4a70-8759-cfce8d1727c01517257054822.csv',
      'originalFileName': '2014 Records.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [

          ]
        },
        {
          'name': 'observation_year',
          'values': [

          ]
        }
      ],
      'totalErrorCount': 0,
      'totalPublicErrorCount': 0,
      'formFormatErrorCount': 0,
      'formFormatPublicErrorCount': 0,
      'crossFieldErrorCount': 0,
      'crossFieldPublicErrorCount': 0,
      'rejectionReasons': ['OVER_TOLERANCE','NON_UNIQUE_SUBMISSION_KEY','NOT_ASSIGNED_COCODE'],
      'fileSubmissionState': 'SYSTEM_REJECTED_FILE',
      'submittedBy': 'jepaul',
      'created': 1517257054856,
      'modified': 1517257055095,
      'uploadTimeInSeconds': 0.05,
      'fileSizeInBytes': 1271,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1517257054865
        }
      ],
      'initialSubmissionProcessingDone': true,
      'insertionComplete': 'true',
      'initialSubmissionProcessingDoneTime': 1517257055095
    },
    {
      'id': '5a6f8181b26fb02b086fb60d',
      'fileName': '3fbe2410-77a4-48fa-a499-6ea5133e9f231517257089224.csv',
      'originalFileName': '2013TestFile3_spunExtra.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [

          ]
        },
        {
          'name': 'observation_year',
          'values': [

          ]
        }
      ],
      'totalErrorCount': 0,
      'totalPublicErrorCount': 0,
      'formFormatErrorCount': 0,
      'formFormatPublicErrorCount': 0,
      'crossFieldErrorCount': 0,
      'crossFieldPublicErrorCount': 0,
      'rejectionReasons': ['OVER_TOLERANCE','NON_UNIQUE_SUBMISSION_KEY','NOT_ASSIGNED_COCODE'],
      'fileSubmissionState': 'SYSTEM_REJECTED_FILE',
      'submittedBy': 'jepaul',
      'created': 1517257089246,
      'modified': 1517257089463,
      'uploadTimeInSeconds': 0.02,
      'fileSizeInBytes': 2726,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1517257089249
        }
      ],
      'initialSubmissionProcessingDone': true,
      'insertionComplete': 'true',
      'initialSubmissionProcessingDoneTime': 1517257089463
    },
    {
      'id': '5a6f8194b26fb02b086fb60e',
      'fileName': '29ced4e9-407c-49ba-bad2-33f9fa4cb48f1517257108767.csv',
      'originalFileName': '2016BaeBae4More.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [

          ]
        },
        {
          'name': 'observation_year',
          'values': [

          ]
        }
      ],
      'totalErrorCount': 0,
      'totalPublicErrorCount': 0,
      'formFormatErrorCount': 0,
      'formFormatPublicErrorCount': 0,
      'crossFieldErrorCount': 0,
      'crossFieldPublicErrorCount': 0,
      'rejectionReasons': ['OVER_TOLERANCE','NON_UNIQUE_SUBMISSION_KEY','NOT_ASSIGNED_COCODE'],
      'fileSubmissionState': 'SYSTEM_REJECTED_FILE',
      'submittedBy': 'jepaul',
      'created': 1517257108791,
      'modified': 1517257108991,
      'uploadTimeInSeconds': 0.01,
      'fileSizeInBytes': 2797,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1517257108794
        }
      ],
      'initialSubmissionProcessingDone': true,
      'insertionComplete': 'true',
      'initialSubmissionProcessingDoneTime': 1517257108991
    },
    {
      'id': '5a6f81f1b26fb02b086fb60f',
      'fileName': 'c5531e32-3a36-40f7-a364-0abf945265851517257201930.csv',
      'originalFileName': '2013TestFile3_spun  no header.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [

          ]
        },
        {
          'name': 'observation_year',
          'values': [

          ]
        }
      ],
      'totalErrorCount': 0,
      'totalPublicErrorCount': 0,
      'formFormatErrorCount': 0,
      'formFormatPublicErrorCount': 0,
      'crossFieldErrorCount': 0,
      'crossFieldPublicErrorCount': 0,
      'rejectionReasons': ['OVER_TOLERANCE','NON_UNIQUE_SUBMISSION_KEY','NOT_ASSIGNED_COCODE'],
      'fileSubmissionState': 'SYSTEM_REJECTED_FILE',
      'submittedBy': 'jepaul',
      'created': 1517257201959,
      'modified': 1517257202189,
      'uploadTimeInSeconds': 0.02,
      'fileSizeInBytes': 1279,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1517257201970
        }
      ],
      'initialSubmissionProcessingDone': true,
      'insertionComplete': 'true',
      'initialSubmissionProcessingDoneTime': 1517257202189
    },
    {
      'id': '5a6f8b57b26fb02b086fb611',
      'fileName': '584c89e3-aad9-460c-8b15-0779da58c6ca1517259607610.csv',
      'originalFileName': '2013 250 records obs year.csv',
      'submissionKeys': [
        {
          'name': 'naic_company_code',
          'values': [

          ]
        },
        {
          'name': 'observation_year',
          'values': [

          ]
        }
      ],
      'totalErrorCount': 0,
      'totalPublicErrorCount': 0,
      'formFormatErrorCount': 0,
      'formFormatPublicErrorCount': 0,
      'crossFieldErrorCount': 0,
      'crossFieldPublicErrorCount': 0,
      'rejectionReasons': null,
      'fileSubmissionState': 'SYSTEM_REJECTED_FILE',
      'submittedBy': 'jepaul',
      'created': 1517259607626,
      'modified': 1517259607789,
      'uploadTimeInSeconds': 0.01,
      'fileSizeInBytes': 26948,
      'schemaType': 'PBR',
      'fileSubmissionStateHistory': [
        {
          'updateBy': 'jepaul',
          'fileSubmissionState': 'FILE_RECEIVED',
          'modified': 1517259607630
        }
      ],
      'initialSubmissionProcessingDone': true,
      'insertionComplete': 'true',
      'initialSubmissionProcessingDoneTime': 1517259607789
    }
  ];
