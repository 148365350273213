<ngx-loading [show]="loading"></ngx-loading>
<div *ngIf="fileLevelValidation" id="file-level-validation" class="wrapper">
    <div>

        <div class="row">
            <div class="col-12">
                <h1>{{ title }}</h1>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <app-messages></app-messages>
            </div>
        </div>

        <form id="fileLevelValidationForm" [formGroup]="fileLevelValidationForm" class="height">

            <div class="row">
                <div class="col-12 align-top pl-10 pt-3">
                    &nbsp;&nbsp;
                    <input id="fileLevelValidationCheckBox" type="checkbox" #activeCheck formControlName="active" (change)="disableText(activeCheck.checked)"/>
                    <label id="fileLevelValidationLabel" class="pl-2 pr-2">{{fileLevelValidation.name}}: </label>
                    <input id="fileLevelValidationInputBox" type="number" formControlName="value" appOnlyDigits/>
                </div>
            </div>

            <div *ngIf="!isRdcAdmin" class="row col-12 bottom">
                <div class="btn-group col-2" role="group">
                    <button id="cancelButton" type="button" class="btn btn-link" (click)="reset()">Cancel</button>
                    &nbsp;&nbsp;
                    <button id="saveButton" type="submit" (click)="saveFileLevelValidation()" class="btn btn-primary"
                        [disabled]="!fileLevelValidationForm.valid || !fileLevelValidationForm.dirty">Save</button>
                </div>
            </div>

        </form>

    </div>
</div>