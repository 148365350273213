<div id="sideNavigation" class="wrapper">

  <div class="row">
    <h4 class="w-100">
      Set Page Navigation
    </h4>
    <p class="text-center">Add Sections and Sub Sections for Navigation</p>
  </div>

  <div id="sections" class="row bg-light">
    <app-filing-navigation (selectedFormSection)="selectedFormSectionHandler($event)"></app-filing-navigation>
  </div>

  <div *ngIf="!formBuilderService.isFilingBlankEmpty.value">
    <hr />
    <h4>
      <span id="actions" aria-controls="actions"> Actions </span>
    </h4>
  
    <div id="dependencies:button">
      <div class="pb-2 pt-2">
        <button id="filingSummary" type="button" class="btn btn-primary btn-sm btn-block mr-2" (click)="setDependencies()"
          [disabled]="formBuilderService.isFormUpdated || formBuilderService.disableDependencyPage.value ||formBuilderService.isDependencyPage">
          Set Dependencies
        </button>
      </div>
    </div>
    <hr />
  </div>

</div>