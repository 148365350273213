import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { FileAdminService } from '../../service/file-admin.service';

@Component({
  selector: 'app-cross-field-exceptions-renderer',
  templateUrl: './cross-field-exceptions-renderer.component.html',
  styleUrls: ['./cross-field-exceptions-renderer.component.css']
})
export class CrossFieldExceptionsRendererComponent implements ICellRendererAngularComp {
  params: any;

  constructor(
    private fileAdminService: FileAdminService
  ) { }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  goToFileAdminErrors() {
    this.fileAdminService.setCrossFieldErrorTypeCounts(this.params.value.crossFieldErrorTypeCounts);
    this.fileAdminService.setCrossFieldNonPublicErrorTypeCounts(this.params.value.crossFieldNonPublicErrorTypeCounts);
    this.params.context.componentParent.goToFileAdminErrors(this.params.value, 'CROSSFIELD');
  }

}
