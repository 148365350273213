import { FileLevelValidation } from '../file-level-validation/model/file-level-validation';

export const MOCK_FILE_LEVEL_VALIDATION: FileLevelValidation[] = [
  {
    id: '64248eb3b9cae96fb4fc101b',
    name: 'RECORDS_IN_FILE',
    value: '',
    active: false,
    dateCreated: '2023-03-29T19:17:07.718+00:00',
    userCreated: 'kkatali@naic.org',
  },
];
