<div *ngIf="submissionAudit">
  <div class="modal-header">
    <h3 class="modal-title">{{title}}</h3>
  </div>
  <div class="modal-body">
    <div class="row mb-3">
      <div class="col-12">
        <ag-grid-angular
          style="width: 100%; height: 200px;"
          #agGrid
          id="submissionAuditGrid"
          class="ag-theme-balham"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="rowData"
          [context]="context"
          [frameworkComponents]="frameworkComponents"
          (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" type="button" (click)="modalRef.hide()">Close</button>
  </div>
</div>
