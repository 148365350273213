<div *ngIf="!loading">
  <div class="modal-header">
    <h3 class="modal-title">{{title}}</h3>
  </div>
  <ng-container *ngIf="statuses && statuses.length > 0; else noStatuses">
    <div class="modal-body">

      <div class="row mb-3">
        <div class="col-12">
          Please select a new status from the dropdown.
        </div>
      </div>

      <div class="row">

        <div class="col-6">
          <div class="btn-group" dropdown>
            <button dropdownToggle type="button" class="btn btn-primary dropdown-toggle" [disabled]="status.isDisabled" id="file-status">
              {{status.value | noUnderscore}} <span class="caret"></span>
            </button>
            <ul *dropdownMenu class="dropdown-menu" role="menu">
              <li *ngFor="let status of statuses" role="menuitem">
                <button class="dropdown-item" type="button" (click)="setStatus(status)">{{status}}</button>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-6 pull-right">
          <textarea id="textareaComments" [(ngModel)]="fileSubmission.comments" placeholder="Note"></textarea>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-link btn-sm" (click)="modalRef.hide()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="updateStatus()">Update</button>
    </div>
  </ng-container>
</div>


<ng-template #noStatuses>
  <div class="container-fluid">
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-10">
          The current status is not one that you are configured to be able to move.
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-link btn-sm" type="button" (click)="modalRef.hide()">Cancel</button>
    </div>
  </div>
</ng-template>



