import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject ,  Subscription } from 'rxjs';
import { Breadcrumb } from '../model/breadcrumb';
import { User } from '../model/user';
import { BreadcrumbService } from '../service/breadcrumb.service';
import { UserService } from '../service/user.service';
import { Aggregate } from './model/aggregate';
import { AggregationMaintenanceService } from './services/aggregation-maintenance.service';
import {MessageService} from '../messages/service/message.service';

@Component({
  selector: 'app-aggregation-maintenance',
  templateUrl: './aggregation-maintenance.component.html'
})
export class AggregationMaintenanceComponent implements OnInit, OnDestroy {
  title: string;
  private _message = new Subject<string>();
  messageText: string;
  error = false;
  user: User;
  aggregates: Aggregate[];
  breadcrumb = new Breadcrumb('Aggregates', '/aggregation');
  loading = false;
  private subscriptions: Array<Subscription> = [];

  constructor(
    private userService: UserService,
    private router: Router,
    private aggregationService: AggregationMaintenanceService,
    public breadcrumbService: BreadcrumbService,
    public messageService: MessageService,
  ) { }

    ngOnInit() {
    this._message.subscribe(message => this.messageText = message);
    this.breadcrumbService.clearBreadcrumbs();
    this.user = this.userService.getStoredUser();
    this.title = `${this.user.currentSchema.toUpperCase()} Aggregates`;
    this.getAggregates();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.messageService.clear();
  }

  getAggregates(): void {
    this.loading = true;
    const sub = this.aggregationService.getAggregates(this.user.currentSchema)
      .subscribe(
        aggregates => {
          this.loading = false;
          this.error = false;
          this.aggregates = aggregates;
        },
        error => {
          this.loading = false;
          this.error = true;
          this.messageText = <any>error;
          this._message.next(`${this.messageText}`);
        }
      );
    this.subscriptions.push(sub);
  }

  selectAggregate(aggregate: Aggregate) {
    this.breadcrumbService.addBreadcrumb(this.breadcrumb);
    this.aggregationService.setSelectedAggregate(aggregate);
    this.router.navigate(['/aggregate']);
  }

}
