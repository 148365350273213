<div class="modal-header">
    <h3 class="modal-title">{{title}}</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
   {{message}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link btn-sm" (click)="onClickYes()" >Yes</button>
    <button type="button" class="btn btn-primary" (click)="close()">No</button>
</div>