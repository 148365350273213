import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-constant-value',
  templateUrl: './constant-value.component.html'
})
export class ConstantValueComponent implements OnInit {
  title: string;
  form: FormGroup;
  enteredConstantValue: string = '';
  public event: EventEmitter<any> = new EventEmitter();
  regex: any;
  numericSelection: boolean = false;
  alphaNumericSelection: boolean = false;
  disableOkButton: boolean = true;

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.title = `Enter Constant Value`;
  }

  ok() {
    this.alphaNumericSelection ? (this.enteredConstantValue = `'${this.enteredConstantValue}'`) : this.enteredConstantValue;
    this.triggerEvent(this.enteredConstantValue);
    this.bsModalRef.hide();
  }

  triggerEvent(value: string) {
    this.event.emit({ data: value, res: 200 })
  }

  onChangeNumeric() {
    this.alphaNumericSelection = false;
    this.numericSelection = true;
    this.regex = '^[-+]?[0-9]*\.?[0-9]+$';
    this.disableOkButton = false;
  }

  onChangeAlphanumeric() {
    this.numericSelection = false;
    this.alphaNumericSelection = true;
    this.regex = '.*';
    this.disableOkButton = false;
  }

  onChangeEnteredValue(value: any) {
    if (value === '' && (!this.numericSelection && !this.alphaNumericSelection)) {
      this.disableOkButton = true;
    } else if (value === '' && (!this.numericSelection || !this.alphaNumericSelection)) {
      this.disableOkButton = true;
    } else {
      this.disableOkButton = false;
    }
  }
}
