import { Injectable } from '@angular/core';
import { QuestionBase } from './question-base';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormSection } from '../model/form-section';

@Injectable()
export class QuestionControlService {
  private disabledInputs: string[] = [];



  // Create the form control
  toFormGroup(inputs: QuestionBase<any>[], disabled: boolean, summary: boolean): FormGroup {
    let defaultDisabled = disabled;
    let group: any = {};

    inputs.forEach(input => {
      if (input.disableableInputs) {
        if (input.value === input.disableableInputs.disabledValue) {
          input.disableableInputs.disabledInputs.forEach((disabledInput: string) => {
            const keys = disabledInput.split('.');
            this.disabledInputs.push(keys[keys.length - 1]);
          });
        }
      }
      if (this.disabledInputs.indexOf(input.schemaPropertyNameKey) > -1) {
        disabled = true;
      }
      if (input.disabled) {
        disabled = true;
      }
      let formControl: FormControl;
      formControl = input.required ? new FormControl(input.value || '', Validators.required)
        : new FormControl(input.value || '');
      if (disabled || summary) {
        formControl.disable();
      }
      disabled = defaultDisabled;
      group[input.schemaPropertyNameKey] = formControl;
    });
    return new FormGroup(group);
  }

  generateForm(section: FormSection, disabled: boolean, summary: boolean, forRules: boolean): FormGroup {
    let group: any = {};
    if (section.sections.length > 0 && section.questions.length === 0) {
      section.sections.forEach(subSection => {
        group[section.key] = this.generateForm(subSection, disabled, summary, false);
      });
    }
    if (section.questions.length > 0) {
      group[section.key] = this.generateQuestions(section.questions, disabled, summary, forRules);
    }

    if (section.sections.length === 0 && section.questions.length === 0) {
      group[section.key] = new FormControl({});
    }

    return new FormGroup(group);
  }

  generateQuestions(questions: any[], disabled: boolean, summary: boolean, forRules): FormGroup {
    let group: any = {};

    if(forRules) {
      questions.forEach(question => {
        group[question.key] = this.toFormGroup(question.inputs, disabled, summary);
      });
    } else {
      questions.forEach(question => {
        group[question.label] = this.toFormGroup(question.inputs, disabled, summary);
      });
    }

    return new FormGroup(group);
  }

}
