import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { CommonConfirmationModalComponent } from '../../common-confirmation-modal/common-confirmation-modal.component';
import { MessageService } from '../../messages/service/message.service';
import { DependenciesService } from '../dependencies/services/dependencies.service';
import { FormBuilderService } from '../services/form-builder.service';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.css']
})
export class SideNavigationComponent implements OnInit {
  @Output() selectedFormSection = new EventEmitter<any>();
  showDependencyPage: boolean = false;
  modalSubscriptions: Subscription[] = [];
  bsModalRef: BsModalRef;
  selectedTemplate: any;
  selectedEvent: any;

  constructor(
    public formBuilderService: FormBuilderService,
    private messageService: MessageService,
    private dependenciesService: DependenciesService,
    public modalService: BsModalService,
  ) {
    this.formBuilderService.showDependencyPage.next(false);
    this.formBuilderService.disableDependencyPage.next(false);
   }

  ngOnInit() {
    this.formBuilderService.sideNavTitle.getValue();
  }

  selectedFormSectionHandler(event) {
    this.selectedEvent = event;
    const initialState = {
      message: 'Do you wish to proceed? Any changes will be lost.',
      title: 'Form Builder'
    };
    let isFormChanged: boolean = false;
    if(_.isEqual(this.formBuilderService.selectedExistingRuleKeys, this.dependenciesService.selectedExistingRuleKeys) === true) {
      isFormChanged = true;
    } else {
      if(this.dependenciesService.selectedExistingRuleKeys.length === 0) {
        isFormChanged = true;
      }
    }
    if(!isFormChanged) {
      const bsModalRef = this.modalService.show(CommonConfirmationModalComponent, { initialState, class: 'modal-lg' });
      bsModalRef.content.isYesButtonClicked.subscribe((data: any) => {
        if (data) {
          this.selectedFormSection.emit(event);
          this.formBuilderService.showDependencyPage.next(false);
        }
        bsModalRef.hide();
      });
    } else {
      this.selectedFormSection.emit(event);
      this.formBuilderService.showDependencyPage.next(false);
    }
  }



  setDependencies(){
    const initialState = {
      message: 'Do you wish to proceed? Any changes will be lost.',
      title: 'Form Builder'
    };
    let isFormChanged: boolean = false;
    if(_.isEqual(this.formBuilderService.selectedExistingRuleKeys, this.dependenciesService.selectedExistingRuleKeys) === true) {
      isFormChanged = true;
    } else {
      if(this.dependenciesService.selectedExistingRuleKeys.length === 0) {
        isFormChanged = true;
      }
    }
    if(!isFormChanged) {
      const bsModalRef = this.modalService.show(CommonConfirmationModalComponent, { initialState, class: 'modal-lg' });
      bsModalRef.content.isYesButtonClicked.subscribe((data: any) => {
        if (data) {
          this.messageService.clear();
          this.showDependencyPage = true;
          this.formBuilderService.showDependencyPage.next(true);
          this.dependenciesService.isSelectQuestionsVisible.next(true);
        }
        bsModalRef.hide();
      });
    } else {
      this.messageService.clear();
      this.showDependencyPage = true;
      this.formBuilderService.showDependencyPage.next(true);
      this.dependenciesService.isSelectQuestionsVisible.next(true);
    }
  }

}
