import { FormQuestion } from "./form-question";
import { FormSection } from "./form-section";

export class FilingBlank {
    questions: FormQuestion[];
    sections: FormSection[];
  
    constructor(questions?: FormQuestion[], sections?: FormSection[]) {
      this.questions = questions;
      this.sections = sections;
    }
  }
  