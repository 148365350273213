
export class Datacall {
  dataCallName: string;
  dataCallDisplayName: string;
  isOnlineDataEntry: boolean;
  fileFormatType?: string;
  responsiblePartiesUserNames: string[];
  adminRole: string;
  userRole: string;
}
