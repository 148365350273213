import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {MessageService} from '../../messages/service/message.service';
import {User} from '../../model/user';
import {Subject} from 'rxjs';
import {ImportService} from '../service/import.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-import-dialog',
  templateUrl: './import-dialog.component.html'
})
export class ImportDialogComponent implements OnInit, OnDestroy {

  private ngUnsubscribe = new Subject();
  modalRef: BsModalRef;
  user: User;
  private _message = new Subject<string>();
  messageText: string;
  fileContents: string;
  importedFile: File;
  loading: boolean;


  constructor(
    public bsModalRef: BsModalRef,
    public messageService: MessageService,
    public importService: ImportService,
  ) { this.loading = true; }

  ngOnInit() {
    this._message.subscribe(message => this.messageText = message);
    this.clearContents();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.messageService.clear();
  }

  onImportFile() {
    this.loading = true;
    this.importService.import(this.user.currentSchema, this.fileContents)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response) => {
          if (response.status === 201) {
            this.messageText = `Success importing - ${this.importedFile.name}`;
            this.clearContents();
            (<HTMLInputElement> document.getElementById('input:file')).value = '';
            this.loading = false;
          }
        },
        (err) => {
          this.messageText = `Error importing - ${this.importedFile.name}`;
          this.clearContents();
          this.loading = false;
        }
      );
  }

  openFile(event) {
    this.importedFile = event.target.files[0];

    if (this.importedFile) {
      const reader = new FileReader();

      reader.onload = () => {
        this.fileContents = reader.result.toString();

        if (this.isJsonString()) {
          this.messageText = '';
        } else {
          this.messageText = `${this.importedFile.name} - is not a valid JSON file.`;
          this.fileContents = '';
        }
      };

      reader.readAsText(this.importedFile);
    } else {
      this.clearContents();
    }
  }

  isJsonString(): boolean {
    try {
      JSON.parse(this.fileContents);
    } catch (e) {
      return false;
    }
    return true;
  }

  clearContents(): void {
    this.fileContents = '';
    this.importedFile = null;
  }

}
