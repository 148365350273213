import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../../service/breadcrumb.service';
import {ValidationSchema} from '../../model/validation-schema';
import {FileValidationMaintenanceService} from '../../services/file-validation-maintenance.service';
import {Property} from '../../model/property';
import {Breadcrumb} from '../../../model/breadcrumb';

@Component({
  selector: 'app-schema-view',
  templateUrl: './schema-view.component.html'
})
export class SchemaViewComponent implements OnInit {
  title: string;
  messageText: string;
  error = false;
  validationSchema: ValidationSchema;
  breadcrumbs: Breadcrumb[];

  constructor(
    private schemaMaintenanceService: FileValidationMaintenanceService,
    public breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit() {
    this.validationSchema = this.schemaMaintenanceService.getSelectedAuditSchema();
    this.breadcrumbs = this.breadcrumbService.getBreadcrumbs();
    this.title = `${this.validationSchema.type.toUpperCase()} Field Definition Details`;
  }

  getPropertiesKeys(): string[] {
    return Object.keys(this.validationSchema.properties);
  }

  getPropertyKeys(property: Property): string[] {
    return Object.keys(property);
  }

  shouldDisplay(name: string): boolean {
    return (name !== 'name' && name !== 'required' && name !== 'type');
  }

}
