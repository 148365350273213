<ng-container *ngIf="checkForSystemRejected(params.node.data); else notWaiting">
    <div class="row">
        <div class="col-12">
            <span class="system-rejected-link"
                (click)="goToRejectionReason(params.node.data, rejectionReasons)">{{ params.value | noUnderscore }}</span>
        </div>
    </div>
</ng-container>


<ng-template #rejectionReasons>
    <div class="modal-header">
        <h3 class="modal-title">Rejection Reason</h3>
        <button type="button" class="close pull-right" aria-label="Close" (click)="openRejectReasonsRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <ng-container *ngIf="showTable; else noData">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z1">

                <ng-container matColumnDef="reason">
                    <th style="background-color: #e9ecef;" mat-header-cell *matHeaderCellDef> Reason </th>
                    <td mat-cell *matCellDef="let element; let i = index" id="rejection_reason_{{i + 1}}"> {{element.reason}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
        </ng-container>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="openRejectReasonsRef.hide()">Ok</button>
    </div>
</ng-template>

<ng-template #notWaiting>
    <div class="row">
        <div class="col-12">
            <span>{{ params.value | noUnderscore }}</span>
        </div>
    </div>
</ng-template>

<ng-template #noData>
    No Reasons
</ng-template>