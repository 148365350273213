export class CrossFieldValidation {
  id: string;
  name: string;
  errorMessage: string;
  description: string;
  expression: string;
  active: boolean;
  publicViewable: boolean;
  validationLevel: string;
  dateCreated: Date;
  userCreated: string;
}
