<div id="landing" class="wrapper">
  <div *ngIf="user" id="fileDashboardLanding">
    <div class="row">
      <div class="col-12 page-title">
        <h1>{{ title }}</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <p id="label:submissionSteps">Follow these steps to submit a file:</p>
        <ol>
          <li>
            Click
            <a
              *ngIf="canViewFileDashboard(); else not_user"
              routerLink="/fileDashboard"
              routerLinkActive="active">
              File Dashboard
            </a>
            on the navigation bar.
          </li>
          <li>
            Click on the boxed area in the center of the window to navigate to
            the file, or drag/drop the file to this area.
          </li>
          <li>Click Upload!</li>
        </ol>
        <p>
          Your file submission and status will display underneath the upload
          button on the
          <a
            *ngIf="canViewFileDashboard(); else not_user"
            routerLink="/fileDashboard"
            routerLinkActive="active">
            File Dashboard
          </a>
          window. After the system validates the data file, if any exceptions
          occur, the exception count will display. Select the exception count
          link to view the exception details.
        </p>
      </div>
    </div>
  </div>
</div>

<ng-template #not_user>
    File Dashboard
</ng-template>
