export enum FileSubmissionState {
  pendingUserSubmission = 'PENDING_USER_SUBMISSION',
  filePendingNaicReview = 'FILE_PENDING_NAIC_REVIEW',
  senderRejectedFile = 'SENDER_REJECTED_FILE',
  systemRejectedFile = 'SYSTEM_REJECTED_FILE',
  naicRejectsFileResubmit = 'NAIC_REJECTS_FILE_RESUBMIT',
  adminRejectsFileResubmit = 'ADMIN_REJECTS_FILE_RESUBMIT',
  reasonabilityCheckComplete = 'REASONABILITY_CHECK_COMPLETE',
  credible = 'CREDIBLE',
  notCredible = 'NOT_CREDIBLE',
  partiallyCredible = 'PARTIALLY_CREDIBLE',
  fileReceived = 'FILE_RECEIVED',
}
