<div id="functionHeader" class="modal-header">
    <h4 id="functionTitle" class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form name="functionForm" (ngSubmit)="ok()" #functionForm="ngForm">
    <div id="functionBody" class="modal-body row">
            <div class="col-md-4">
                <ul class="list-inline">
                    <li *ngFor="let func of functions">
                        <button id="{{func.label}}" type="button" class="btn btn-sm text-left"
                            style="color:black" (click)="selectFunction(func)">{{func.label}}</button>
                        <div class="dropdown-divider"></div>
                    </li>
                </ul>
            </div>
            <div class="col-md-8">
                <p id="functionDescription" [innerHtml] ="functionsDescription | noSanitize" ></p>
            </div>
        </div>

    <div class="modal-footer">
        <button id="functionCancel" type="button" class="btn btn-primary float-right" (click)="bsModalRef.hide()">
            Cancel </button>
        <button id="functionSubmit" type="submit" class="btn btn-primary" [disabled]="saveFlag"> Ok</button>
    </div>
</form>