import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { UserService } from '../service/user.service';
import { User } from '../model/user';
import { Router } from '@angular/router';
import { MessageService } from '../messages/service/message.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription, Subject } from 'rxjs';
import { AlertMessage } from '../messages/model/alert-message';
import { DatacallCreationComponent } from './datacall-creation/datacall-creation.component';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { UserAssignments } from '../user-assignment/model/user-assignment';
import { UserAssignmentService } from '../user-assignment/services/user-assignment.service';
import { DatacallSettingService } from '../roleconfig/datacall-setting/services/datacall-setting.service';
import { DatacallSettingEnum } from '../roleconfig/datacall-setting/model/datacall-setting.enum';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-schema-selection',
  templateUrl: './schema-selection.component.html',
  styleUrls: ['./schema-selection.component.css']
})
export class SchemaSelectionComponent implements OnInit, OnDestroy {
  schemaType: string;
  user: User;
  messageText: string;
  error = false;
  datacallCreationRef: BsModalRef;
  modalSubscriptions: Array<Subscription> = [];
  datacallSchemas: any[];
  fromUser: FormGroup;
  selectedDatacallGroup: string;
  private unsubscribe: Subject<void> = new Subject();
  datcallAdminUserRole: boolean;
  rdcAdminUserRole: boolean;
  loading: boolean;
  companyAdminPrimaryUser: any;
  showUserAssignmentsLink: boolean;
  userAssignmentsForPrimary: UserAssignments[] = [];
  showuserAssignmentsPrimaryLink: boolean;
  isCopyToReportingDB: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    public messageService: MessageService,
    private modalService: BsModalService,
    private datacallSettingService: DatacallSettingService,
    private userAssignmentService: UserAssignmentService
  ) { }

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    this.fromUser = new FormGroup({
      DatacallGroup: new FormControl(''),
      DatacallSchema: new FormControl('')
    });
    this.messageService.clear();
    this.selectedDatacallGroup = '';
  }

  ngOnDestroy() {
    this.messageService.clear();
  }

  goToDisclaimer(event) {
    this.user.currentSchema = event.target.selectedOptions[0].value;
    this.user.selectedDatacallGroup = this.selectedDatacallGroup;
    this.datcallAdminUserRole = this.userService.canViewandUpdateUserAssignments(this.user);
    this.rdcAdminUserRole = this.user.isRdcAdmin;
    this.getOnlineDataEntryDatacallSetting();
    if (this.datcallAdminUserRole === true || this.rdcAdminUserRole === true) {
      this.getDatacallSettingValue();
    } else {
      this.getDatacallSettingValueForCompanyAdmin();
    }
  }

  getOnlineDataEntryDatacallSetting() {
    this.loading = true;
    let datacallSettingName = DatacallSettingEnum.isOnlineDataEntry;
    this.datacallSettingService.getDatacallSettingName(this.user.currentSchema, datacallSettingName).subscribe(
      res => {
        this.datacallSettingService.setOnlineDataEntrySetting(res);
        this.getCopyToReportingDBDatacallSetting();
      }
    )
  }

  getCopyToReportingDBDatacallSetting() {
    this.loading = true;
    let datacallSettingName = DatacallSettingEnum.copyToReportingDB;
    this.datacallSettingService.getDatacallSettingName(this.user.currentSchema, datacallSettingName).subscribe(
      res => {
        if(!isNullOrUndefined(res)) {
        this.isCopyToReportingDB = res;
        this.datacallSettingService.iscopyToReportingDBSetting = this.isCopyToReportingDB;
        }
        this.loading = false;
      }
    )
  }

  getDatacallSettingValue(): void {
    let datcallSettingName = DatacallSettingEnum.userAssignment;
    this.datacallSettingService.getDatacallSettingName(this.user.currentSchema, datcallSettingName).subscribe(
      res => {
        if (res === true) {
          this.showUserAssignmentsLink = true;
          this.datacallSettingService.showUserAssignmentsLink = this.showUserAssignmentsLink;
        } else {
          this.showUserAssignmentsLink = false;
          this.datacallSettingService.showUserAssignmentsLink = this.showUserAssignmentsLink;
        }
        this.userService.setStoredUser(this.user);
        this.router.navigate(['disclaimer', this.user.currentSchema]);
      }
    )
  }

  getDatacallSettingValueForCompanyAdmin(): void {
    let datcallSettingName = DatacallSettingEnum.userAssignment;
    this.datacallSettingService.getDatacallSettingName(this.user.currentSchema, datcallSettingName).subscribe(
      res => {
        if (res === true) {
          this.getUserAssignmentsForPrimaryUser();
        } else {
          this.showuserAssignmentsPrimaryLink = false;
          this.userAssignmentService.setShowuserAssignmentsPrimaryLink(this.showuserAssignmentsPrimaryLink);
          this.userAssignmentService.showuserAssignmentsPrimaryLink = this.showuserAssignmentsPrimaryLink;
          this.userService.setStoredUser(this.user);
          this.router.navigate(['disclaimer', this.user.currentSchema]);
        }

      }
    )
  }

  getUserAssignmentsForPrimaryUser() {
    this.loading = true;
    this.userAssignmentService.getUserAssignmentsPrimaryUser(this.user.currentSchema, this.user.userId)
      .subscribe(
        res => {
          if (res.length !== 0) {
            this.showuserAssignmentsPrimaryLink = true;
          this.userAssignmentService.setShowuserAssignmentsPrimaryLink(this.showuserAssignmentsPrimaryLink);
          this.userAssignmentService.showuserAssignmentsPrimaryLink = this.showuserAssignmentsPrimaryLink;
          } else {
            this.showuserAssignmentsPrimaryLink = false;
          this.userAssignmentService.setShowuserAssignmentsPrimaryLink(this.showuserAssignmentsPrimaryLink);
          this.userAssignmentService.showuserAssignmentsPrimaryLink = this.showuserAssignmentsPrimaryLink;
            this.loading = false;
          }
          this.userService.setStoredUser(this.user);
          this.router.navigate(['disclaimer', this.user.currentSchema]);
        }
      );
  }


  openCreateDatacall(dataCallGroup: string) {
    const initialState = { dataCallGroupFromParent: dataCallGroup, user: this.user };

    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        this.error = this.datacallCreationRef.content.error;
        this.messageText = this.datacallCreationRef.content.messageText;
        if (this.messageText !== undefined) {
          if (!this.error) {
            this.messageService.add(new AlertMessage(this.messageText, 'success'));
          }
          this.resetUser();
        }
        this.unsubscribeModal();
      })
    );

    this.datacallCreationRef = this.modalService.show(DatacallCreationComponent, { initialState: initialState, class: 'modal-lg', backdrop: 'static', keyboard: false });
  }

  private unsubscribeModal(): void {
    this.modalSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.modalSubscriptions = [];
  }

  onChangeDatacallGroup(event) {
    this.selectedDatacallGroup = event.target.selectedOptions[0].value;
    this.refreshDatacallSchemaList();
  }

  private refreshDatacallSchemaList(): void {
    this.datacallSchemas = _.get(this.user.dataCallGroups.find(dcGroup => dcGroup.groupName === this.selectedDatacallGroup), 'dataCalls');
  }

  private resetUser() {
    this.userService.getUser()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        user => {
          if (user) {
            user.currentSchema = this.user.currentSchema;
            user.isAuthenticated = this.user.isAuthenticated;
            this.user = user;
            this.userService.setStoredUser(this.user);
            this.refreshDatacallSchemaList();
          }
        }
      );
  }

}
