<div *ngIf="formSection" id="summaryPage">
  <app-form-section *ngFor="let section of formSection.sections; let i = index;" [section]="section" [parentId]=""
  [index]="i" summary="true" [questionNo]="count"></app-form-section>
</div>

<div class="col-6" *ngIf="onlineDataEntryService.isAllowAttachments">
  <div class="col-6">
    <h5 class="fontBold">
      <span id="attachments">
        Attachments:
      </span>
    </h5>
  </div>
  <table id="filing-attachments" class="table table-bordered shortened">
    <tr id="filing-attachments-header">
      <th *ngFor="let header of tableHeaders; let i = index" id={{header}} class="font-weight-bold text-capitalize" [ngClass]="{'left-align': i === 0, 'center-align': i !== 0}">
        {{header}}
      </th>
    </tr>

    <tr *ngFor="let attachment of formAttachments">
      <td class="assignment-value-cell left-align wrap-text">
        <a class="text-primary assignment-values" (click)="downloadAttachment(attachment)">
          {{attachment.originalFileName}}
        </a>
      </td>
      <td class="center-align">
        <button mat-icon-button (click)="deleteAttachment(attachment)" [disabled]="isDisabled" #tooltip="matTooltip" matTooltip="Delete attachment" [matTooltipPosition]="tooltipPosition">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </tr>
  </table>

</div>
