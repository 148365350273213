import { NgModule } from "@angular/core";
import { DatacallSettingComponent } from "./datacall-setting.component";
import { DatacallSettingService } from "./services/datacall-setting.service";


@NgModule({
  declarations: [],
  imports: [],
  exports: [],
  providers: [DatacallSettingService, DatacallSettingComponent],
  entryComponents: []
})
export class DatacallSettingModule { }
