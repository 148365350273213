<input
*ngIf="(((params.colDef.field === 'minValue' || params.colDef.field === 'maxValue') 
&& params.data.propertyType === 'string') 
)
; else regularInput"
    #inputBox id="{{params.colDef.field}}: {{params.data.schemaProperty}}: {{params.value}}" name="inputField"
    type="text" disabled>

<ng-template #regularInput>
    <input #inputBox id="{{params.colDef.field}}: {{params.data.schemaProperty}}: {{params.value}}" name="inputField"
        type="text" size="20" value={{params.value}} (keyup.enter)="checkValue($event, params.data)"
        (keyup)="checkValue($event, params.data)" (blur)="checkValue($event, params.data)"
        [disabled]="params.colDef.field === 'listOfValidValues' && isRemoveDependencyDisabled" >
</ng-template>