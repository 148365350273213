import { Component } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-form-format-exceptions-renderer',
  templateUrl: './form-format-exceptions-renderer.component.html',
  styleUrls: ['./form-format-exceptions-renderer.component.css']
})
export class FormFormatExceptionsRendererComponent implements  ICellRendererAngularComp {
  params: any;



  agInit(params: any): void {
    this.params = params;
}

  refresh(): boolean {
    return false;
  }

  goToErrorType() {
    this.params.context.componentParent.goToErrorType(this.params.data, 'FORMFORMAT');
  }

}
