<ngx-loading [show]="loading"></ngx-loading>

<div id="importexport" class="wrapper">
  <div class="row">
    <div class="col-12 page-title bottomSpacing">
      <h1>{{title}}</h1>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <app-messages></app-messages>
    </div>
  </div>
  <br />
  <div class="title">{{datacallInfoTitle}}</div>
  <div class="subTitle bottomSpacing">{{datacallInfo}}</div>
  <div style="padding-left: 10rem;">
    <h2>Export</h2>
  </div>
  <div class="row" style="padding-left: 15rem;">
    <div *ngFor="let options of exportOptions" class="col-12">
        &nbsp;&nbsp;
        <input id="fileLevelValidationCheckBox" type="checkbox" [checked]="options.isChecked" (change)="changeExportOptions(options, $event)"/>
        <label id="fileLevelValidationLabel" class="pl-2 pr-2">{{options.value}} </label>
    </div>
  </div>
  <div class="row" style="padding-left: 15rem;">
    <div class="col-12 mb-3">
      <button id="exportButton" class="col-3 btn btn-primary" (click)="exportJson()" [disabled]="disableExport">Export Datacall</button>
    </div>
  </div>
  <div style="padding-left: 10rem;">
    <h2>Import</h2>
  </div>
  <div class="row" style="padding-left: 15rem;">
    <div class="col-12">
      <button id="importButton" class="col-3 btn btn-primary" (click)="openImportDialog()">Import Datacall</button>
    </div>
  </div>
  <div class="title textSpacing">{{moveToSnowflakeInfoTitle}}</div>
  <div class="subTitle bottomSpacing">{{moveToSnowflakeInfo}}</div>
  <div class="subTitle bottomSpacing">{{moveToSnowflakeAlert}}</div>
  <div class="row">
    <div class="col-12 text-center">
      <button id="sendSnowflakeButton" class="col-3 btn btn-primary" (click)="sendToSnowflake()" [disabled]="isSendToSnowflakeInProgress">{{snowflakeButtonText}}</button>
    </div>
  </div>
  <div class="title textSpacing">{{collectedDataInfoTitle}}</div>
  <div class="subTitle bottomSpacing">{{collectedDataInfo}}</div>
  <div class="row">
    <div class="col-12 text-center">
      <button id="exportDataButton" class="col-3 btn btn-primary" (click)="exportData()" [disabled]="isCopyToReportingDB || exportDataResponseFlag || isExportInProgress">{{exportButtonText}}</button>
    </div>
  </div>
  <div *ngIf="exportDataResponseFlag" class="row">
    <div class="col-12 text-center">
      <div class="col-12" style="color: red; text-align: center; padding-top: 5px;">Data is being exported, check back Later</div>
    </div>
  </div>
</div>

<ng-template #notAdmin>
  <div *ngIf="!loading" class="col-12 mx-auto main-display text-center pt-2">
    <h2>You are not authorized to view this page. Please contact MFL production support if you want to Import or Export
      Datacall</h2>
  </div>
</ng-template>
