import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-attachment-renderer',
  templateUrl: './attachment-renderer.component.html',
  styleUrls: ['./attachment-renderer.component.css']
})
export class AttachmentRenderer implements ICellRendererAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  downloadAttachment(attachment) {
    this.params.context.componentParent.downloadAttachment(attachment);
  }

}
