import { FormQuestion } from './form-question';
import {FormHeaders} from '../../form-builder/model/form-headers';

export class FormSection {
  key?: string;
  name?: string;
  description?: string;
  sectionNumber?: number;
  sections?: FormSection[];
  headings?: FormHeaders[];
  questions?: FormQuestion[];
  order?: number;
  active?: boolean;

  constructor(key?: string, sectionNumber?: number, name?: string, description?: string, order?: number, sections?: FormSection[], questions?: FormQuestion[], headers?: FormHeaders[]) {
    this.key = key || '';
    this.sectionNumber = sectionNumber || 1;
    this.name = name || '';
    this.description = description || '';
    this.order = order || 0;
    this.sections = sections || [];
    this.questions = questions || [];
    this.headings = headers || [];
    this.active = false;
  }
}
