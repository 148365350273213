import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FileValidationMaintenanceService } from '../../services/file-validation-maintenance.service';

@Component({
  selector: 'app-inputbox-field-renderer',
  templateUrl: './inputbox-field-renderer.component.html'
})
export class InputboxFieldRendererComponent implements ICellRendererAngularComp {
  params: any;
  isRemoveDependencyDisabled: boolean = false;
  usedSchemaPropertiesForDependency: any[];
  constructor(
    private modalService: BsModalService,
    private fileValidationMaintenanceService: FileValidationMaintenanceService
  ) { }


  agInit(params: any): void {
    this.params = params;
    this.usedSchemaPropertiesForDependency = this.fileValidationMaintenanceService.getUsedSchemaPropertiesForDependency();
    this.isRemoveDependencyDisabled = 
    Array.isArray(this.usedSchemaPropertiesForDependency) && 
    this.usedSchemaPropertiesForDependency.includes(this.params.data.schemaProperty);
  }

  refresh(): boolean {
    return false;
  }

  checkValue(event: any, data) {
    this.params.context.componentParent.changeInputField(event.target.value, data.schemaProperty, this.params.colDef.field);
  }

}
