<ngx-loading [show]="loading"></ngx-loading>
<div id="file-validation-maintenance" class="wrapper">
  <div class="row">
    <div class="col-12 page-title">
      <h1>{{title}}</h1>
    </div>
  </div>

  <div id="infoMessage" *ngIf="showInfoMessage" class="row mb-3">
    <div class="col-12">
      <alert type="info" [dismissible]="false">
        <span>{{infoMessage}}</span>
      </alert>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <app-messages></app-messages>
    </div>
  </div>

  <div *ngIf="validationSchema" class="row">
    <div class="col-12">
      <b>Last Modification Date: </b>{{validationSchema.dateCreated | date: 'short'}} <br>
      <b>Last Modified By: </b>{{validationSchema.userCreated}} <br><br>
    </div>
  </div>

  <div class="row" *ngIf="showFileType === false && !loading">
    <div class="col-12">
      <label id="file-type-label" class="font-weight-normal text-left">File type:</label>
      <select id="file-type" class="rounded" (change)="onChangeFileType($event);">
        <option [selected]="!validationSchema?.fileType || validationSchema?.fileType === 'CSV'" value="CSV">CSV
        </option>
        <option [selected]="validationSchema?.fileType === 'XLSX'" value="XLSX">XLSX</option>
      </select>
    </div>
  </div>

  <div id="xlsx-info" *ngIf="validationSchema?.fileType === 'XLSX' && showFileType === false">
    <div id="xlsx-card-info" class="card">
      <div id="xlsx-card-body-info" class="card-body">
        <div class="row">
          <div class="col-12 form-inline">
            <div class="form-group">
              <label id="worksheet-begin-label" class="pr-2">Worksheet Begin:</label>
              <input id="worksheet-begin" name="worksheet-begin" type="text" class="form-control-sm" pattern="[1-9]\d*$"
                [(ngModel)]="newXlsIteration.worksheetRange.begin" required notOnlyWhitespace>
            </div>
            <div class="form-group"></div>
            <label id="worksheet-end-label" class="pr-2 pl-2">End:</label>
            <input id="worksheet-end" name="worksheet-end" type="text" class="form-control-sm" pattern="[1-9+]\d*$"
              [(ngModel)]="newXlsIteration.worksheetRange.end" required notOnlyWhitespace>
          </div>
        </div>
        <div class="row">
          <div class="col-12 form-inline">
            <div class="form-group">
              <label id="row-begin-label" class="pr-2">Row Begin:</label>
              <input id="row-begin" name="row-begin" type="text" class="form-control-sm" pattern="[1-9]\d*$"
                [(ngModel)]="newXlsIteration.rowRange.begin" required notOnlyWhitespace>
            </div>
            <div class="form-group">
              <label id="row-end-label" class="pr-2 pl-2">End: </label>
              <input id="row-end" name="row-end" type="text" class="form-control-sm pr-3" pattern="[1-9+]\d*$"
                [(ngModel)]="newXlsIteration.rowRange.end" required notOnlyWhitespace>
            </div>
          </div>
          <div>
            Enter '+' for repeat until last record
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>

  <div class="row">
    <div class="col-12">
      <button id="btn:auditTrail" type="button" class="btn btn-primary" (click)="schemaValidationAudit()">
        <i class="fa fa-random"></i> Audit Trail
      </button>
      &nbsp;
      <button [disabled]="isUploadDisabled()" id="btn:upload"
              title="Upload Datacall Fields (Name and Description) in a CSV file format."
              class="btn btn-primary" (click)="uploadDataField()" type="button">
        <i class="fa fa-upload">Upload CSV File</i><span class="sr-only">Upload CSV File</span>
      </button>
      &nbsp;
      <button  id="btn:formatUpload"
               title="Upload Datacall Fields from an Annotated Excel file. The file will contain annotations for input fields that will be used to generate the Name and Description values."
               class="btn btn-primary" (click)="UploadFormattedFile()" type="button">
        <i class="fa fa-upload"> Upload Annotated XLSX File</i><span class="sr-only">Upload Annotated XLSX File</span>
      </button>
      <hr />
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text font-weight-bold">New Property Name</span>
        </div>
        <input id="input:addNew" type="text" class="form-control" [(ngModel)]="newPropertyName" notOnlyWhitespace appNoSpace>
        <div class="input-group-append">
          <button id="btn:addNew" class="btn btn-primary" (click)="addNewProperty()" type="button"
            [disabled]="isAddDisabled(newPropertyName)">
            <i class="fa fa-plus"> Add New Property</i><span class="sr-only">Add</span>
          </button>
        </div>
      </div>
      <span class="font-italic small ml-3">***New property name must be unique. Spaces are not allowed.</span>
    </div>
  </div>
  
  <div *ngIf="data.length > 0" class="row mb-3">
    <div class="col-12">
      <ag-grid-angular
        style="width: 100%; height: 500px;"
        #agGrid
        id="fileValidationMaintenanceGrid"
        class="ag-theme-balham"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="rowData"
        [context]="context"
        [frameworkComponents]="frameworkComponents"
        (gridReady)="onGridReady($event)"
        [gridOptions]="gridOptions"
        >  
      </ag-grid-angular>
    </div >
  </div>

  <div *ngIf="data.length > 0">
    <div class="col-12">
      <button id="btn:cancel" type="button" class="btn btn-link btn-sm" (click)="cancelUpdate()"> Cancel</button> &nbsp;
      <button id="btn:save" type="button" [disabled]="isSaveDisabled()" class="btn btn-primary" (click)="saveCompleteValidationSchema()"> Save </button>
    </div>
  </div>

</div>
