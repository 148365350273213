<ngx-loading [show]="loading"></ngx-loading>
<div *ngIf="user" id="datacall-creation" class="wrapper">
  <form>

    <div>
      <div class="modal-header">
        <h3 class="modal-title">{{this.user.currentSchema.toUpperCase()}} Import Datacall Schema</h3>
      </div>

      <div class="row mb-1">
        <alert *ngIf="messageText" class="col-12" type="warning">
          <span id="alert:message">{{messageText}}</span>
        </alert>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <input id="input:file" type="file" accept="application/json, .json" class="col-8" (change)="openFile($event)"/>
          </div>
          <div *ngIf="importedFile !== null" class="col-12 ml-5 mt-2">
            <ul>
              <li class="col-10">
                <strong>* {{importedFile.name}}</strong> ({{importedFile.type}}) - {{importedFile.size}} bytes, last modified: {{importedFile.lastModifiedDate.toLocaleString()}}
              </li>
            </ul>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 modal-footer">
            <button id="button:cancel" class="btn btn-primary" type="button" (click)="bsModalRef.hide()">Cancel</button>
            <button id="button:import" class="ml-2 btn btn-primary" type="button" (click)="onImportFile()" [disabled]="fileContents === ''" >Import</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
