import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoadingModule } from 'ngx-loading';
import { PipesModule } from '../pipes/pipes.module';
import { CrossFieldValidationAuditModule } from './cross-field-validation-audit/cross-field-validation-audit.module';
import { CrossFieldValidationEditModule } from './cross-field-validation-edit/cross-field-validation-edit.module';
import { CrossFieldValidationMaintenanceComponent } from './cross-field-validation-maintenance.component';
import { CrossFieldValidationMaintenanceService } from './services/cross-field-validation-maintenance.service';
import { CrossFieldValidationMaintenanceRoutingModule } from './cross-field-validation-maintenance-routing.module';
import { MessagesModule } from '../messages/messages.module';
import { AgGridModule } from 'ag-grid-angular';
import { EditRendererComponent } from './cell-renderer/edit-renderer/edit-renderer.component';
import { AuditRendererComponent } from './cell-renderer/audit-renderer/audit-renderer.component';
import { ExpressionBuilderModule } from './expression-builder/expression-builder.module';
import { StringToModelParserService } from './expression-builder/shared/parserModelObjNestedIfs.service';
import { TestExpressionBuilderComponent } from './test-expression-builder/test-expression-builder.component';
import { TestExpressionBuilderModule } from './test-expression-builder/test-expression-builder.module';

@NgModule({
  imports: [
    CommonModule,
    AgGridModule.withComponents([EditRendererComponent, AuditRendererComponent]),
    FormsModule,
    MessagesModule,
    LoadingModule,
    RouterModule,
    PipesModule,
    CrossFieldValidationEditModule,
    CrossFieldValidationAuditModule,
    ExpressionBuilderModule,
    TestExpressionBuilderModule,
    CrossFieldValidationMaintenanceRoutingModule
  ],
  declarations: [CrossFieldValidationMaintenanceComponent, EditRendererComponent, AuditRendererComponent, TestExpressionBuilderComponent],
  providers: [
    CrossFieldValidationMaintenanceService,
    StringToModelParserService
  ],
  exports: [CrossFieldValidationMaintenanceComponent]
})
export class CrossFieldValidationMaintenanceModule {
}
