import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { UserService } from './service/user.service';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  loggedIn = false;

  constructor(
    private oauthService: OAuthService,
    private userService: UserService
    // private zone: NgZone
  ) {
    if (environment.mock) {
      this.loggedIn = true;
    } else {
      this.configure();
    }
  }

  private configure(): void {
    const config: AuthConfig = {
      strictDiscoveryDocumentValidation: true,
      issuer: `${environment.auth.issuer}`,
      redirectUri: environment.auth.redirectBaseUrl,
      silentRefreshRedirectUri: environment.auth.redirectBaseUrl,
      logoutUrl: `${environment.auth.logoutUrl}`,
      clientId: environment.auth.clientId,
      tokenEndpoint: `${environment.auth.tokenEndpoint}`,
      userinfoEndpoint: `${environment.auth.userInfoEndpoint}`,
      scope: 'openid profile email offline_access',
      timeoutFactor: environment.auth.tokenTimeoutFactor,
      responseType: 'code',
      showDebugInformation: true,
      useSilentRefresh: false,
      sessionChecksEnabled: false,
    };

    this.oauthService.configure(config);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.loadDiscoveryDocument().then(() => {
      this.oauthService.tryLoginCodeFlow().then((response) => {
        this.loadUserProfile();
      }).catch((err) => {
        console.log(err);
      });
    });
  }

  private loadUserProfile(): void {
    if (this.oauthService.hasValidAccessToken()) {
      this.oauthService.loadUserProfile()
        .then(userProfile => {
          this.userService.setStoredUserProfile(userProfile);
          this.loggedIn = true;
        });
    } else {
      this.oauthService.initCodeFlow();
    }

    this.oauthService.events.pipe(filter(e => e.type === 'session_terminated')).subscribe(e => {
      console.debug('Your session has been terminated!');
    })
  }

}
