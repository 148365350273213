import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalModule} from 'ngx-bootstrap/modal';
import {LoadingModule} from 'ngx-loading';
import {PipesModule} from '../pipes/pipes.module';
import {FileAdminComponent} from './file-admin.component';
import {CredibleModule} from './credible/credible.module';
import {SubmissionAuditModule} from './submission-audit/submission-audit.module';
import {NotesModule} from './notes/notes.module';
import {FormsModule} from '@angular/forms';
import {FileAdminRoutingModule} from './file-admin-routing.module';
import {FileAdminService} from './service/file-admin.service';
import {CredibleComponent} from './credible/credible.component';
import {NotesComponent} from './notes/notes.component';
import {SubmissionAuditComponent} from './submission-audit/submission-audit.component';
import {UpdateStatusComponent} from './update-status/update-status.component';
import {UpdateStatusModule} from './update-status/update-status.module';
import {ErrorsModule} from './errors/errors.module';
import {ErrorTypeModule} from './error-type/error-type.module';
import {MessagesModule} from '../messages/messages.module';
import {AgGridModule} from 'ag-grid-angular';
import {DateRendererComponent} from '../cell-renderer/date-renderer/date-renderer.component';
import {
  FormFormatExceptionsRendererComponent
} from './cell-renderer/form-format-exceptions-renderer/form-format-exceptions-renderer.component';
import {
  CrossFieldExceptionsRendererComponent
} from './cell-renderer/cross-field-exceptions-renderer/cross-field-exceptions-renderer.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NumberRendererComponent} from '../cell-renderer/number-renderer/number-renderer.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {NotesRendererComponent} from './cell-renderer/notes-renderer/notes-renderer.component';
import {AuditTrailRendererComponent} from './cell-renderer/audit-trail-renderer/audit-trail-renderer.component';
import {ReloadRendererComponent} from './cell-renderer/reload-renderer/reload-renderer.component';
import {UpdateStatusRendererComponent} from './cell-renderer/update-status-renderer/update-status-renderer.component';
import {CredibleRendererComponent} from './cell-renderer/credible-renderer/credible-renderer.component';
import {
  StatusRendererForAdminComponent
} from './cell-renderer/status-renderer-for-admin/status-renderer-for-admin.component';
import {FileNameRendererComponent} from './cell-renderer/file-name-renderer/file-name-renderer.component';
import {MatTableModule} from '@angular/material';
import { FileLevelValidationsRendererComponent } from './cell-renderer/file-level-validations-renderer/file-level-validations-renderer.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    FontAwesomeModule,
    MessagesModule,
    MatTableModule,
    LoadingModule,
    AgGridModule.withComponents([
      DateRendererComponent,
      NumberRendererComponent,
      FormFormatExceptionsRendererComponent,
      CrossFieldExceptionsRendererComponent,
      FileLevelValidationsRendererComponent,
      NotesRendererComponent,
      AuditTrailRendererComponent,
      ReloadRendererComponent,
      UpdateStatusRendererComponent,
      CredibleRendererComponent,
      StatusRendererForAdminComponent,
      FileNameRendererComponent
    ]),
    PipesModule,
    NotesModule,
    CredibleModule,
    SubmissionAuditModule,
    FileAdminRoutingModule,
    UpdateStatusModule,
    ErrorsModule,
    ErrorTypeModule
  ],
  declarations: [
    FileAdminComponent,
    FormFormatExceptionsRendererComponent,
    CrossFieldExceptionsRendererComponent,
    FileLevelValidationsRendererComponent,
    NotesRendererComponent,
    AuditTrailRendererComponent,
    ReloadRendererComponent,
    UpdateStatusRendererComponent,
    CredibleRendererComponent,
    StatusRendererForAdminComponent,
    FileNameRendererComponent
  ],
  providers: [FileAdminService],
  exports: [FileAdminComponent],
  entryComponents: [
    CredibleComponent,
    NotesComponent,
    SubmissionAuditComponent,
    UpdateStatusComponent
  ]
})
export class FileAdminModule {
}
