import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoadingModule } from 'ngx-loading';
import { PipesModule } from '../pipes/pipes.module';
import { RerunValidationsRoutingModule } from './rerun-validations-routing.module';
import { RerunValidationsComponent } from './rerun-validations.component';
import { RerunValidationsService } from './services/rerun-validations.service';
import {MessagesModule} from '../messages/messages.module';
import { RerunValidationsRendererComponent } from './cell-renderer/rerun-validations-renderer/rerun-validations-renderer.component';
import { RunDiscrepancyRendererComponent } from './cell-renderer/run-discrepancy-renderer/run-discrepancy-renderer.component';
import { AgGridModule } from 'ag-grid-angular';
import { DateRendererComponent } from '../cell-renderer/date-renderer/date-renderer.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    PipesModule,
    MessagesModule,
    AgGridModule.withComponents([DateRendererComponent, RerunValidationsRendererComponent, RunDiscrepancyRendererComponent]),
    LoadingModule,
    RerunValidationsRoutingModule,
  ],
  declarations: [RerunValidationsComponent, RerunValidationsRendererComponent, RunDiscrepancyRendererComponent],
  providers: [RerunValidationsService],
  exports: [RerunValidationsComponent]
})
export class RerunValidationsModule { }
