export const MOCK_PBR_USER = {
  'userId': 'pbruser',
  'datacallPermissions': {
    'pbr': ['read_own_filesubmissions'],
    'cas': ['read_own_filesubmissions']
  },
  'schemas': ['pbr', 'cas'],
  'dataCallGroups':
    [
      {
        'groupId': '5d49e44e08a6a78a449cdb33',
        'groupName': 'pbr',
        'database': 'pbr',
        'dataCalls': [{'dataCallName': 'pbr'}]
      },
      {
        'groupId': '5d49e49608a6a78a449cdf8b',
        'groupName': 'cas',
        'database': 'cas',
        'dataCalls': [{'dataCallName': 'cas'}]
      }
    ]
};
export const MOCK_PBR_ADMIN = {
  'userId': 'pbradmin',
  'datacallPermissions': {
    'pbr': ['read_alladmin_validationschema', 'read_all_aggregates', 'read_all_datacalltolerances', 'read_all_validationschema'],
    'cas': ['read_alladmin_validationschema', 'read_all_aggregates', 'read_all_datacalltolerances', 'read_all_validationschema']
  },
  'schemas': ['pbr', 'cas'],
  'dataCallGroups':
    [
      {
        'groupId': '5d49e44e08a6a78a449cdb33',
        'groupName': 'pbr',
        'database': 'pbr',
        'dataCalls': [{'dataCallName': 'pbr'}]
      },
      {
        'groupId': '5d49e49608a6a78a449cdf8b',
        'groupName': 'cas',
        'database': 'cas',
        'dataCalls': [{'dataCallName': 'cas'}]
      }
    ]
};
export const MOCK_PBR_ACTUARY = {
  'userId': 'pbractuary',
  'datacallPermissions': {
    'pbr': ['read_filesubmission_crediblecocodes']
  },
  'schemas': ['pbr', 'cas'],
  'dataCallGroups':
    [
      {
        'groupId': '5d49e44e08a6a78a449cdb33',
        'groupName': 'pbr',
        'database': 'pbr',
        'dataCalls': [{'dataCallName': 'pbr'}]
      },
      {
        'groupId': '5d49e49608a6a78a449cdf8b',
        'groupName': 'cas',
        'database': 'cas',
        'dataCalls': [{'dataCallName': 'cas'}]
      }
    ]
};
export const MOCK_TLL = {
  'userId': 'tleininger',
  'datacallPermissions': {
    'pbr': ['read_own_filesubmissions', 'read_alladmin_validationschema', 'read_all_aggregates', 'read_all_datacalltolerances', 'read_all_validationschema'],
    'mcas': ['read_own_filesubmissions']
  },
  'schemas': ['pbr', 'cas', 'mcs'],
  'dataCallGroups':
    [
      {
        'groupId': '5d49e44e08a6a78a449cdb33',
        'groupName': 'pbr',
        'database': 'pbr',
        'dataCalls': [{'dataCallName': 'pbr'}]
      },
      {
        'groupId': '5d49e49608a6a78a449cdf8b',
        'groupName': 'cas',
        'database': 'cas',
        'dataCalls': [{'dataCallName': 'cas'}]
      },
      {
        'groupId': '5d49e49608a6a78a449cdf11',
        'groupName': 'mcs',
        'database': 'mcs',
        'dataCalls': [{'dataCallName': 'mcs'}, {'dataCallName': 'mcs2'}]
      }
    ],
  'selectedDatacallGroup': 'pbr',
  'isAuthenticated': true,
  'currentSchema': 'andie_ode_sep22',
  'isRdcAdmin': false,
};
export const MOCK_TLL_PBR = {
  'userId': 'tleininger',
  'datacallPermissions': {
    'pbr': ['read_own_filesubmissions', 'read_alladmin_validationschema', 'read_all_aggregates', 'read_all_datacalltolerances', 'read_all_validationschema']
  },
  'schemas': ['pbr', 'cas', 'mcs'],
  'currentSchema': 'pbr',
  'dataCallGroups':
    [
      {
        'groupId': '5d49e44e08a6a78a449cdb33',
        'groupName': 'pbr',
        'database': 'pbr',
        'dataCalls': [{'dataCallName': 'pbr'}]
      },
      {
        'groupId': '5d49e49608a6a78a449cdf8b',
        'groupName': 'cas',
        'database': 'cas',
        'dataCalls': [{'dataCallName': 'cas'}]
      },
      {
        'groupId': '5d49e49608a6a78a449cdf11',
        'groupName': 'mcs',
        'database': 'mcs',
        'dataCalls': [{'dataCallName': 'mcs'}, {'dataCallName': 'mcs2'}]
      }
    ]
};
export const MOCK_RDC_ADMIN = {
  'userId': 'slhteam1',
  'password': 'mytest19',
  'datacallPermissions': {
    'pbr': ['read_own_filesubmissions', 'read_alladmin_validationschema', 'read_all_aggregates', 'read_all_datacalltolerances', 'read_all_validationschema'],
    'cas': ['read_own_filesubmissions', 'read_alladmin_validationschema', 'read_all_aggregates', 'read_all_datacalltolerances', 'read_all_validationschema'],
    'mcas': ['read_own_filesubmissions', 'read_alladmin_validationschema', 'read_all_aggregates', 'read_all_datacalltolerances', 'read_all_validationschema'],
  },
  'schemas': ['pbr', 'cas', 'mcs'],
  'dataCallGroups':
    [
      {
        'groupId': '5d49e44e08a6a78a449cdb33',
        'groupName': 'pbr',
        'database': 'pbr',
        'dataCalls': [{'dataCallName': 'pbr'}]
      },
      {
        'groupId': '5d49e49608a6a78a449cdf8b',
        'groupName': 'cas',
        'database': 'cas',
        'dataCalls': [{'dataCallName': 'cas'}]
      },
      {
        'groupId': '5d49e49608a6a78a449cdf11',
        'groupName': 'mcs',
        'database': 'mcs',
        'dataCalls': [{'dataCallName': 'mcs'}, {'dataCallName': 'mcs2'}]
      }
    ],
  'isRdcAdmin': true,
  'currentSchema': 'pbr'
};
export const MOCK_ALL = {
  'userId': 'slhteam1',
  'password': 'mytest19',
  'datacallPermissions': {
    'pbr': ['read_own_filesubmissions', 'read_alladmin_validationschema', 'read_all_aggregates', 'read_all_datacalltolerances', 'read_all_validationschema', 'update_datacall_configurations'],
    'cas': ['read_own_filesubmissions', 'read_alladmin_validationschema', 'read_all_aggregates', 'read_all_datacalltolerances', 'read_all_validationschema'],
    'mcas': ['read_own_filesubmissions', 'read_alladmin_validationschema', 'read_all_aggregates', 'read_all_datacalltolerances', 'read_all_validationschema'],
  },
  'schemas': ['pbr', 'cas', 'mcs'],
  'dataCallGroups':
    [
      {
        'groupId': '5d49e44e08a6a78a449cdb33',
        'groupName': 'pbr',
        'database': 'pbr',
        'dataCalls': [{'dataCallName': 'pbr'}]
      },
      {
        'groupId': '5d49e49608a6a78a449cdf8b',
        'groupName': 'cas',
        'database': 'cas',
        'dataCalls': [{'dataCallName': 'cas'}]
      },
      {
        'groupId': '5d49e49608a6a78a449cdf11',
        'groupName': 'mcs',
        'database': 'mcs',
        'dataCalls': [{'dataCallName': 'mcs'}, {'dataCallName': 'mcs2'}]
      }
    ]
};
export const MOCK_NONE = {
  'userId':'mock_none',
  'datacallPermissions':{},
  'schemas':[],
  'dataCallGroups':[],
  'isRdcAdmin':false
}
