<ngx-loading [show]="loading"></ngx-loading>
<div class="wrapper">
  <div id="validationMaintenance">
    <div class="row">
      <div class="col-12">
        <h1>{{title}}</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li *ngFor="let crumb of breadcrumbs" class="breadcrumb-item">
              <a [routerLink]="[crumb.link]" (click)="breadcrumbService.removeBreadcrumb(crumb)">{{crumb.label}}</a>
            </li>
            <li class="breadcrumb-item active">
              {{breadcrumb.label}}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <app-messages></app-messages>
      </div>
    </div>

    <div>

      <div *ngIf="crossFieldValidationHistory" class="row mb-3">
        <div class="col-12">
          <ag-grid-angular
            style="width: 100%; height: 200px;"
            #agGrid
            id="submissionAuditGrid"
            class="ag-theme-balham"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="rowData"
            [context]="context"
            [frameworkComponents]="frameworkComponents"
            (gridReady)="onGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
</div>

