import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { CrossFieldValidationEditComponent } from './cross-field-validation-edit.component';
import {MessagesModule} from '../../messages/messages.module';
import { FocusDirective } from '../../shared/auto-focus.directive';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ModalModule.forRoot(),
    MessagesModule,
    SharedModule,
  ],
  declarations: [CrossFieldValidationEditComponent, FocusDirective],
  exports: [CrossFieldValidationEditComponent]
})
export class CrossFieldValidationEditModule { }
