<ng-container>
  <div class="row">
    <div class="col-12">
      <ul>
        <li *ngFor="let attachment of params.value">
          <a class="file-download-link" (click)="downloadAttachment(attachment)">
            {{ attachment.originalFileName }} - {{attachment.uploadDate}}
          </a>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
