<div id="user-not-allowed" class="wrapper">
  <div class="row page-title">
    <div class="col-12">
      <h1>Unauthorized</h1>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <p>You are not authorized to view this page or perform this action.</p>
    </div>
  </div>
</div>
