<div class="tab-content active" id="content" #pdfContent>

  <div [formGroup]="sectionForm">
    <div [formGroupName]="section.key" class="well well-lg" [id]="section.key" *ngIf="isSectionVisible(section)">

      <div id="infoMessage" *ngIf="status === 'PROCESSING'" class="row mb-3">
        <div class="col-12">
          <alert type="info" [dismissible]="false">
            <span>{{fileProcessingMessage}}</span>
          </alert>
        </div>
      </div>
      <div class="row pb-3">


        <div class="col-6">
          <h5 class="fontBold">
            <span [ngClass]="getSectionStyles()">
              {{section.name}}
            </span>
          </h5>
        </div>
        <div *ngIf="index === 0" class="col-6 clearfix">
          <span class="float-right">
            <button *ngIf="getActiveSection() === '' || previous && previous.length > 0" type="button"
                    class="btn btn-primary mr-2" [title]="formatSectionName(previous)" (click)="goTo('previous')">Previous</button>
            <button *ngIf="next && next.length > 0" type="button" class="btn btn-primary mr-2"
                    [title]="formatSectionName(next)" (click)="goTo('next')">Next</button>
            <button *ngIf="!summary" type="button" class="btn btn-primary" (click)="goTo('summary')">Summary</button>
          </span>
        </div>
      </div>

        <div class="row pb-2">
          <div class="col-12">
            <ng-container *ngIf="sectionErrors && sectionErrors.length > 0">
              <div class="alert alert-danger">
                <ul *ngFor="let error of sectionErrors" style="list-style-type: disc !important;">
                  <li>{{error}}</li>
                </ul>
              </div>
            </ng-container>
            <ng-container *ngIf="sectionWarnings && sectionWarnings.length > 0">
              <div class="alert alert-warning">
                <ul *ngFor="let warning of sectionWarnings" style="list-style-type: disc !important;">
                  <li>{{warning}}</li>
                </ul>
              </div>
            </ng-container>
          </div>
        </div>
          <div class="row" *ngIf="section.questions.length > 0">
            <div class="col-4"></div>
            <ng-container *ngIf="inputLabels.length > 0">
              <div *ngFor="let label of inputLabels" class="col">
                <h5 *ngIf="isLabelHeadingVisible(label)">{{label}}</h5>
              </div>
            </ng-container>
          </div>

        <div *ngFor="let model of updatedModel; let isEven = even;">
          <div class="row">
            <!-- Heading -->
            <ng-container>
              <div class="col" *ngIf="model.label === '' else question">
                <h5>{{model.heading}}</h5>
              </div>
            </ng-container>
          </div>
          <ng-template #question>
              <!-- Questions -->
              <div *ngIf="isQuestionVisible(model)" [formGroupName]="model.label" class="col well well-lg indent-questions" [class.bg-light]="isEven" [id]="model.label">
                <div class="form-group row">
                  <div class="col-4">
                    <span class="custom-text"><b>{{getQuestionNumber(model.label)}}</b>&nbsp;&nbsp;&nbsp;&nbsp;{{model.text}}</span>
                  </div>

                  <!-- Inputs -->

                  <ng-container *ngFor="let input of model.inputs">
                    <ng-container *ngIf="isLabelVisible(input)">
                    <ng-container [ngSwitch]="input.controlType">
                      <div class="col">
                        <!-- Switch Case: Dropdown -->
                        <ng-container *ngSwitchCase="'dropdown'">
                          <select
                            [id]="input.schemaPropertyNameKey"
                            [name]="input.schemaPropertyNameKey"
                            [formControlName]="input.schemaPropertyNameKey"
                            [ngClass]="getInputStyle(input)"
                            [attr.title]="input.label"
                            (change)="section || disableChildren(input.disableableInputs, input.schemaPropertyNameKey, model.label);setRules(section)"
                          >
                            <option value="" disabled hidden>Choose One</option>
                            <option *ngFor="let opt of input.options" [value]="opt.key">{{opt.value}}</option>
                          </select>
                        </ng-container>

                        <!-- Switch Case: TextArea -->
                        <ng-container *ngSwitchCase="'textarea'">
                          <div *ngIf="!summary; else isSummary">
                          <textarea autoResize
                            [ngClass]="getInputStyle(input)"
                            [id]="input.schemaPropertyNameKey" [name]="input.schemaPropertyNameKey"
                            [attr.placeholder]="input.label"
                            [formControlName]="input.schemaPropertyNameKey"
                            [rows]="input.rows"
                            [attr.title]="input.label"
                            (keyup)="section || disableChildren(input.disableableInputs, input.schemaPropertyNameKey, model.label)"
                          >
                        </textarea>
                      </div>
                      <ng-template #isSummary>
                        <span class="custom-text">{{input.schemaPropertyNameKey}}</span>
                      </ng-template>
                        </ng-container>

                        <ng-container *ngSwitchDefault>
                          <ng-container [ngSwitch]="input.dataType">
                            <ng-container *ngSwitchCase="'integer'">
                              <textarea autoResize
                                style="height: auto;"
                                [formControlName]="input.schemaPropertyNameKey"
                                [ngClass]="getInputStyle(input)"
                                [id]="input.schemaPropertyNameKey" app type="number"
                                appOnlyDigits
                                [allowDecimal]="false"
                                [attr.title]="input.label"
                                [attr.placeholder]="input.label"
                                (keydown.enter)="onIntegerInput($event, input)"
                                (keydown.tab)="onIntegerInput($event, input)"
                                (blur)="onIntegerInput($event, input)"
                              ></textarea>
                            </ng-container>

                            <!-- Switch Case: Decimal -->
                            <ng-container *ngSwitchCase="'decimal'">
                              <textarea autoResize
                                style="height: auto;"
                                [formControlName]="input.schemaPropertyNameKey"
                                [ngClass]="getInputStyle(input)" step="any"
                                [id]="input.schemaPropertyNameKey"
                                type="number" [attr.title]="input.label" [attr.placeholder]="input.label"
                                (keydown.enter)="onIntegerInput($event, input)"
                                (keydown.tab)="onIntegerInput($event, input)"
                                (blur)="onIntegerInput($event, input)"
                              ></textarea>
                            </ng-container>

                            <ng-container *ngSwitchDefault>
                              
                              <textarea autoResize
                                style="height: auto;"
                                [formControlName]="input.schemaPropertyNameKey"
                                [ngClass]="getInputStyle(input)"
                                [id]="input.schemaPropertyNameKey" type="text"
                                [attr.title]="input.label"
                                [attr.placeholder]="input.label"
                                (keyup)="section || disableChildren(input.disableableInputs, input.schemaPropertyNameKey, model.label)"
                                pattern="{{input.pattern}}"
                                minlength="{{input.minLength}}" maxlength="{{input.maxLength}}"
                                (keydown.enter)="onStringInput($event, input)"
                                (keydown.tab)="onStringInput($event, input)"
                                (blur)="onStringInput($event, input)"></textarea>
                            </ng-container>
                          </ng-container>
                        </ng-container>

                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
                </div>
                <div class="row">
                  <div class="col">
                    <!-- Question Errors -->
                    <ng-container *ngIf="questionErrors[model.key] && questionErrors[model.key].length > 0">
                      <div class="alert alert-danger">
                        <ul *ngFor="let error of questionErrors[model.key]" style="list-style-type: disc !important;">
                          <li>{{error}}</li>
                        </ul>
                      </div>
                    </ng-container>
                    <!-- Question Warnings -->
                    <ng-container *ngIf="questionWarnings[model.key] && questionWarnings[model.key].length > 0">
                      <div class="alert alert-warning">
                        <ul *ngFor="let warning of questionWarnings[model.key]"
                            style="list-style-type: disc !important;">
                          <li>{{warning}}</li>
                        </ul>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
          </ng-template>
        </div>
    </div>
  </div>
  <hr/>
</div>
<!-- More Sections -->
<ng-container *ngIf="summary">
  <app-form-section *ngFor="let child of section.sections; let i = index"
                    [section]="child" [parentId]="getSectionId()" [disabled]="true"
                    [parentPath]="path" [summary]="true" [questionNo]="getQuestionNumbers()"></app-form-section>
</ng-container>
