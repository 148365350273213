import { OnInit, ElementRef, Renderer, Input, Directive } from '@angular/core';

@Directive({ selector: '[focusMe]' })
export class AppAutoFocusDirective implements OnInit {

    @Input('focusMe') isFocused: boolean;

    constructor(private hostElement: ElementRef, private renderer: Renderer) { }

    ngOnInit() {
        if (this.isFocused) {
            this.hostElement.nativeElement.focus();
        }
    }
}
