import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilingBlankLayoutComponent } from './filing-blank-layout.component';

import {FilingBlankLayoutRoutingModule} from './filing-blank-layout-routing.module';
import {FilingBlankLayoutService} from './service/filing-blank-layout.service';
import {LoadingModule} from 'ngx-loading';
import {NgJsonEditorModule} from 'ang-jsoneditor';
import {MessagesModule} from '../messages/messages.module';

@NgModule({
  imports: [
    CommonModule,
    NgJsonEditorModule,
    MessagesModule,
    LoadingModule,
    FilingBlankLayoutRoutingModule
  ],
  declarations: [FilingBlankLayoutComponent],
  providers: [FilingBlankLayoutService],
  exports: [FilingBlankLayoutComponent]
})
export class FilingBlankLayoutModule { }
