import {RouterModule, Routes} from '@angular/router';
import {SchemaSelectionComponent} from './schema-selection.component';
import {NgModule} from '@angular/core';
import { AuthGuardService } from '../service/auth-guard.service';

const schemaSelectionRoutes: Routes = [
  { path: 'schemaSelection', component: SchemaSelectionComponent, canActivate: [AuthGuardService] }
];

@NgModule({
  imports: [
    RouterModule.forChild(schemaSelectionRoutes)
  ],
  exports: [RouterModule]
})
export class SchemaSelectionRoutingModule {}
