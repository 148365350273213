import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { FileSubmission } from '../../model/file-submission';
import { CredibleService } from './service/credible.service';
import { Credible } from './model/credible';
import { FileSubmissionState } from '../../file-dashboard/model/file-submission-state.enum';
import { CocodeModel } from './model/cocode-model';
import {MessageService} from '../../messages/service/message.service';
import {AlertMessage} from '../../messages/model/alert-message';

@Component({
  selector: 'app-credible',
  templateUrl: './credible.component.html'
})
export class CredibleComponent implements OnInit, OnDestroy {
  @Input() fileSubmission: FileSubmission;
  title: string;
  showSave = false;
  credibleData: Credible;
  cocodeModelsChecked: any;
  subscriptions: Array<Subscription> = [];

  constructor(
    private credibleService: CredibleService,
    public modalRef: BsModalRef,
    private messageService: MessageService,
  ) {
  }

  ngOnInit() {
    this.title = `${this.fileSubmission.schemaType} Actuary Credible/Not Credible`;
    this.cocodeModelsChecked = [];
    this.getCredibleData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getCredibleData() {
    this.showSave = true;
    const sub = this.credibleService.getCredibleData(this.fileSubmission)
      .subscribe(
        credibleData => {
          this.credibleData = credibleData;
          this.setCocodeModelsChecked();
        }
      );
    this.subscriptions.push(sub);
  }

  setCocodeModelsChecked() {
    const cocodeModels = this.credibleData.cocodeModels;
    cocodeModels.forEach(cocodeModel => {
      this.cocodeModelsChecked[cocodeModel.naicCompanyCode] = this.credibleService.isAllChecked(cocodeModel);
    });
  }

  fillSubmissionState(): Credible {
    const changes = this.credibleData;
    changes.cocodeModels.forEach(cocodeModel => {
      cocodeModel.planCodes.forEach(planCode => {
        planCode.fileSubmissionState = planCode.checked ? FileSubmissionState.credible : FileSubmissionState.notCredible;
      });
    });
    return changes;
  }

  save() {
    this.fillSubmissionState();
    this.credibleService.putCredibleData(this.fileSubmission.schemaType, this.credibleData)
      .subscribe(
        () => {
          this.messageService.add(new AlertMessage('Credible set', 'success'));
          this.modalRef.hide();
        }
      );
  }

  selectAll(cocodeModel: CocodeModel) {
    const allChecked = this.cocodeModelsChecked[cocodeModel.naicCompanyCode];
    cocodeModel.planCodes.forEach(planCode => {
      planCode.checked = !allChecked;
    });
    this.cocodeModelsChecked[cocodeModel.naicCompanyCode] = !allChecked;
  }

}
