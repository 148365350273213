import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModule } from 'ngx-loading';
import { PipesModule } from '../pipes/pipes.module';
import { FileDashboardComponent } from './file-dashboard.component';
import { FileDashboardRoutingModule } from './file-dashboard-routing.module';
import { FileService } from './service/file.service';
import { FileSubmissionErrorsModule } from './file-submission-errors/file-submission-errors.module';
import { FileSubmissionErrorTypeModule } from './file-submission-error-type/file-submission-error-type.module';
import { FileUploadModule } from './file-upload/file-upload.module';
import { MessagesModule } from '../messages/messages.module';
import { AgGridModule } from 'ag-grid-angular';
import { StatusRendererComponent } from './cell-renderer/status-renderer/status-renderer.component';
import { DateRendererComponent } from '../cell-renderer/date-renderer/date-renderer.component';
import { NumberRendererComponent } from '../cell-renderer/number-renderer/number-renderer.component';
import { ExceptionsRendererComponent } from './cell-renderer/exceptions-renderer/exceptions-renderer.component';
import { MatTableModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    AgGridModule.withComponents([
      StatusRendererComponent,
      DateRendererComponent,
      NumberRendererComponent,
      ExceptionsRendererComponent
    ]),
    PipesModule,
    LoadingModule,
    MessagesModule,
    MatTableModule,
    FileSubmissionErrorsModule,
    FileSubmissionErrorTypeModule,
    FileUploadModule,
    FileDashboardRoutingModule
  ],
  declarations: [
    FileDashboardComponent,
    StatusRendererComponent,
    ExceptionsRendererComponent
  ],
  providers: [FileService],
  exports: [FileDashboardComponent]
})
export class FileDashboardModule {
}
