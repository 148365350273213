import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { LoadingModule } from 'ngx-loading';
import { ErrorTypeComponent } from './error-type.component';
import { RouterModule } from '@angular/router';
import {MessagesModule} from '../../messages/messages.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    LoadingModule,
    MessagesModule,
    PaginationModule.forRoot(),
  ],
  declarations: [ErrorTypeComponent],
  exports: [ErrorTypeComponent]
})
export class ErrorTypeModule {
}
