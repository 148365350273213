import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationEditComponent } from './validation-edit.component';
import {LoadingModule} from 'ngx-loading';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MessagesModule} from '../../messages/messages.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MessagesModule,
    LoadingModule,
  ],
  declarations: [ValidationEditComponent],
  exports: [ValidationEditComponent]
})
export class ValidationEditModule { }
