import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {HandleError, HttpErrorHandler} from '../../shared/http-error-handler.service';

@Injectable()
export class FilingSchemaService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('FilingSchemaService');
  }

  getCurrentValidationSchemaByType(schemaType: string): Observable<any> {
    const url = `${environment.apiUrl}${schemaType}/validationschema/current`;
    return this.httpClient.get<any>(url)
      .pipe(catchError(this.handleError('getCurrentValidationSchemaByType', null)));
  }

  getValidationSchemasByType(schemaType: string): Observable<any[]> {
    const url = `${environment.apiUrl}${schemaType}/validationschema`;
    return this.httpClient.get<any[]>(url)
      .pipe(catchError(this.handleError('getValidationSchemasByType', [])));
  }

  saveValidationSchema(validationSchema: any): Observable<any> {
    const url = `${environment.apiUrl}${validationSchema.type}/validationschema`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.httpClient.post<any>(url, validationSchema, options)
      .pipe(catchError(this.handleError('saveValidationSchema', null)));
  }

}
