import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PipesModule } from '../../pipes/pipes.module';
import { SubmissionAuditService } from './service/submission-audit.service';
import { SubmissionAuditComponent } from './submission-audit.component';
import { AgGridModule } from 'ag-grid-angular';
import { DateRendererComponent } from '../../cell-renderer/date-renderer/date-renderer.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    AgGridModule.withComponents([DateRendererComponent]),
    PipesModule,
  ],
  declarations: [SubmissionAuditComponent],
  providers: [SubmissionAuditService],
  exports: [SubmissionAuditComponent]
})
export class SubmissionAuditModule {
}
