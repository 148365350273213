import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileSubmissionErrorType } from '../file-dashboard/file-submission-errors/model/file-submission-error-type';
import { environment } from '../../environments/environment';




import { HandleError, HttpErrorHandler } from '../shared/http-error-handler.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class FileSubmissionErrorsService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('FileSubmissionErrorsService');
  }

  getErrors(schemaType: string, fileSubmissionId: string, skip: number, limit: number): Observable<FileSubmissionErrorType[]> {
    const url = `${environment.apiUrl}${schemaType}/filesubmissions/${fileSubmissionId}/filesubmissionerrorssummary`;
    const params = new HttpParams()
      .set('skip', String(skip))
      .set('limit', String(limit));
    const options = { params: params };
    return this.httpClient.get<FileSubmissionErrorType[]>(url, options)
      .pipe(catchError(this.handleError('getFileSubmissionErrors', [])));
  }

}
