import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-xls-inputbox-field-renderer',
  templateUrl: './xls-inputbox-field-renderer.component.html'
})
export class XlsInputboxFieldRendererComponent implements ICellRendererAngularComp {
  params: any;
  disable: boolean;



  agInit(params: any): void {
    this.params = params;
    if (this.params.data.pullFromWorksheet) {
      this.disable = true;
    } else{
      this.disable = false;
    }
  }

  refresh(): boolean {
    return false;
  }

  checkValue(event: any, data) {
    this.params.context.componentParent.changeXlsInputField(event.target.value, data.schemaProperty, this.params.colDef.field);
  }

}
