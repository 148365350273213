import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FilingDatesComponent } from './filing-dates.component';


const filingDatesRoutes: Routes = [
  { path: 'filingDates', component: FilingDatesComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(filingDatesRoutes)
  ],
  exports: [RouterModule]
})
export class FilingDatesRoutingModule { }
