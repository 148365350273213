import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class DataTableHelperService {



  getColumns(data: any[]): any[] {
    let columns: any[] = [];
    _.keys(data[0]).forEach((key, i) => {
      let column = {};
      column['name'] = key;
      column['index'] = i;
      columns.push(column);
    });
    return columns;
  }

  getRows(data: any[]): any[] {
    let rows: any[] = [];
    data.forEach(row => {
      rows.push(row);
    });
    return rows;
  }

}
