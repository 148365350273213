import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-edit-renderer',
  templateUrl: './edit-renderer.component.html'
})
export class EditRendererComponent implements ICellRendererAngularComp {
  params: any;



  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  editCrossFieldValidation(): void {
    this.params.context.componentParent.editCrossFieldValidation(this.params.data);
  }

}
