import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PipesModule } from '../../pipes/pipes.module';
import { NotesComponent } from './notes.component';
import { FormsModule } from '@angular/forms';
import { NotesService } from './service/notes.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ModalModule.forRoot(),
    NgxDatatableModule,
    PipesModule,
  ],
  declarations: [NotesComponent],
  providers: [NotesService],
  exports: [NotesComponent]
})
export class NotesModule {
}
