import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoadingModule } from 'ngx-loading';
import { SharedModule } from '../shared/shared.module';
import { FileValidationMaintenanceRoutingModule } from './file-validation-maintenance-routing.module';
import { FileValidationMaintenanceComponent } from './file-validation-maintenance.component';
import { SchemaAuditModule } from './schema-audit/schema-audit.module';
import { SchemaPropertyViewModule } from './schema-property-view/schema-property-view.module';
import { FileValidationMaintenanceService } from './services/file-validation-maintenance.service';
import { MessagesModule } from '../messages/messages.module';
import { PipesModule } from '../pipes/pipes.module';
import { AgGridModule } from 'ag-grid-angular';
import { SchemaPropertyLinkRendererComponent } from './cell-renderer/schema-property-link-renderer/schema-property-link-renderer.component';
import { RemoveSchemaPropertyRendererComponent } from './cell-renderer/remove-schema-property-renderer/remove-schema-property-renderer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OrderNumberRendererComponent } from './cell-renderer/order-number-renderer/order-number-renderer.component';
import { CheckboxFieldRendererComponent } from './cell-renderer/checkbox-field-renderer/checkbox-field-renderer.component';
import { InputboxFieldRendererComponent } from './cell-renderer/inputbox-field-renderer/inputbox-field-renderer.component';
import { AssignmentKeyRendererComponent } from './cell-renderer/assignment-key-renderer/assignment-key-renderer.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { XlsInputboxFieldRendererComponent } from './cell-renderer/xls-inputbox-field-renderer/xls-inputbox-field-renderer.component';
import { RadiobuttonFieldRendererComponent } from './cell-renderer/radiobutton-field-renderer/radiobutton-field-renderer.component';
import { DropdownFieldRendererComponent } from './cell-renderer/dropdown-field-renderer/dropdown-field-renderer.component';
import { DatafieldFileUploadComponent } from './datafield-file-upload/datafield-file-upload.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import {
  SubmissionKeyRendererComponent
} from './cell-renderer/submission-key-renderer/submission-key-renderer.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FontAwesomeModule,
    SharedModule,
    MessagesModule,
    MatTooltipModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    ProgressbarModule.forRoot(),
    PipesModule,
    LoadingModule,
    AgGridModule,
    AgGridModule.withComponents([SchemaPropertyLinkRendererComponent, DropdownFieldRendererComponent, RemoveSchemaPropertyRendererComponent, OrderNumberRendererComponent, CheckboxFieldRendererComponent, InputboxFieldRendererComponent, AssignmentKeyRendererComponent, SubmissionKeyRendererComponent, XlsInputboxFieldRendererComponent, RadiobuttonFieldRendererComponent]),
    SchemaAuditModule,
    SchemaPropertyViewModule,
    FileValidationMaintenanceRoutingModule,
  ],
  entryComponents: [DatafieldFileUploadComponent],
  declarations: [FileValidationMaintenanceComponent, SchemaPropertyLinkRendererComponent, RemoveSchemaPropertyRendererComponent, OrderNumberRendererComponent, CheckboxFieldRendererComponent, InputboxFieldRendererComponent, AssignmentKeyRendererComponent, SubmissionKeyRendererComponent, XlsInputboxFieldRendererComponent, RadiobuttonFieldRendererComponent, DropdownFieldRendererComponent, DatafieldFileUploadComponent],
  providers: [FileValidationMaintenanceService],
  exports: [FileValidationMaintenanceComponent],
})
export class FileValidationMaintenanceModule {
}
