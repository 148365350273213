import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ValidationSchema } from '../model/validation-schema';
import { Property } from '../model/property';
import {HandleError, HttpErrorHandler} from '../../shared/http-error-handler.service';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import * as _ from 'lodash';
import { ValidationSchemaUploadResult } from '../model/datafield-file-upload';

export const AvailablePropertyTypes = [
  'decimal', 'integer', 'string'
];

export const AVAILABLE_VALIDATION_PROPERTIES = {
    description: {
      types: ['decimal', 'string', 'integer']
    },
    anyOf: {
      types: ['decimal', 'string', 'integer']
    },
    enum: {
      types: ['decimal', 'string', 'integer']
    },
    max: {
      types: ['decimal', 'integer']
    },
    min: {
      types: ['decimal', 'integer']
    },
    maxLength: {
      types: ['decimal', 'string', 'integer']
    },
    minLength: {
      types: ['decimal', 'string', 'integer']
    },
    pattern: {
      types: ['decimal', 'string', 'integer']
    }
  };


  @Injectable()
export class FileValidationMaintenanceService {
  private selectedValidationSchema: ValidationSchema;
  private selectedAuditSchema: ValidationSchema;
  private selectedPropertyName: string;
  private newProperty: Property;
  readonly handleError: HandleError;
  isAssignedToUser: any;
  assignmentKeyFieldName: string;
  lengthOfRows: number;
  showFileType: any;
  assignmentKeyChecked: boolean;
  inc: boolean;
  pullFromWorksheet: boolean;
  fileUpload: ValidationSchemaUploadResult;
  usedSchemaProperties: any[];
  usedSchemaPropertiesForDependency: any[];


  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('FileValidationMaintenanceService');
  }

  getFileUplaod(): ValidationSchemaUploadResult {
    return this.fileUpload;
  }

  setFileUpload(fileUpload): void {
    this.fileUpload = fileUpload;
  }

  getIsAssignedToUser(): any {
    return this.isAssignedToUser;
  }

  setIsAssignedToUser(assignedToUser: any): void {
    this.isAssignedToUser = assignedToUser
  }

  getAssignmentKeyChecked(): boolean {
    return  this.assignmentKeyChecked;
  }

  setAssignmentKeyChecked(assignmentKeyChecked: boolean): void {
    this.assignmentKeyChecked = assignmentKeyChecked;
  }

  getAssignmentKeyFieldName(): any {
    return this.assignmentKeyFieldName;
  }

  setAssignmentKeyFieldName(assignmentKeyFieldName: string): void {
    this.assignmentKeyFieldName = assignmentKeyFieldName;
  }

  getSelectedValidationSchema(): ValidationSchema {
    return this.selectedValidationSchema;
  }

  setSelectedValidationSchema(validationSchema: ValidationSchema): void {
    this.selectedValidationSchema = validationSchema;
  }

  getSelectedAuditSchema(): ValidationSchema {
    return this.selectedAuditSchema;
  }

  setSelectedAuditSchema(validationSchema: ValidationSchema): void {
    this.selectedAuditSchema = validationSchema;
  }

  getSelectedPropertyName(): string {
    return this.selectedPropertyName;
  }

  setSelectedPropertyName(name: string) {
    this.selectedPropertyName = name;
  }

  getNewProperty(): Property {
    return this.newProperty;
  }

  setNewProperty(property: Property): void {
    this.newProperty = property;
  }

  getUsedSchemaPropertiesForDependency(): string[] {
    return this.usedSchemaPropertiesForDependency;
  }

  setUsedSchemaPropertiesForDependency(properties: string[]): void {
    this.usedSchemaPropertiesForDependency = properties;
  }


  getLengthOfRows(): number {
    return this.lengthOfRows;
  }

  setLengthOfRows(lengthOfRows: number): void {
    this.lengthOfRows = lengthOfRows;
  }

  getUsedSchemaProperties() {
    return this.usedSchemaProperties;
  }

  setUsedSchemaProperties(usedSchemaProperties: any[]) {
    this.usedSchemaProperties = usedSchemaProperties;
  }

  getCurrentValidationSchemaByType(schemaType: string): Observable<ValidationSchema> {
    const url = `${environment.apiUrl}${schemaType}/validationschema/current`;
    return this.httpClient.get<ValidationSchema>(url)
      .pipe(catchError(this.handleError('getCurrentValidationSchemaByType', null)));
  }

  getValidationSchemasByType(schemaType: string): Observable<ValidationSchema[]> {
    const url = `${environment.apiUrl}${schemaType}/validationschema`;
    return this.httpClient.get<ValidationSchema[]>(url);
  }

  saveValidationSchema(validationSchema: ValidationSchema): Observable<any> {
    const url = `${environment.apiUrl}${validationSchema.type}/validationschema`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.httpClient.post<any>(url, validationSchema, options)
      .pipe(catchError(this.handleError('saveValidationSchema', null)));
  }

  getAvailableValidationsCollection(validationSchema: ValidationSchema): any {
    const availableValidationsCollection = {};

    Object.keys(validationSchema.properties).forEach(key => {
      availableValidationsCollection[key] = this.getListOfAvailableValidationsForValidationProperty(validationSchema.properties[key]);
    });

    return availableValidationsCollection;
  }

  getListOfAvailableValidationsForValidationProperty(property: Property): string[] {
    const availableValidations: string[] = [];
    const filteredProperties = {};
  
    Object.keys(AVAILABLE_VALIDATION_PROPERTIES).forEach((key) => {
      if (AVAILABLE_VALIDATION_PROPERTIES.hasOwnProperty(key)) {
        if (AVAILABLE_VALIDATION_PROPERTIES[key].types.includes(property.type)) {
          filteredProperties[key] = AVAILABLE_VALIDATION_PROPERTIES[key];
        }
      }
    });
  
    Object.keys(filteredProperties).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(filteredProperties, key)) {
        if (!(key in property)) {
          availableValidations.push(key);
        } else {
          const availableTypes = filteredProperties[key].types;
          const propertyType = property.type;
          if (!availableTypes.includes(propertyType)) {
            availableValidations.push(key);
          }
        }
      }
    });

    return availableValidations;
  }

  getTableData(validationSchema: ValidationSchema): any[] {
    let tableData: Array<any> = [];
    const keys: Array<string> = this.getPropertiesKeys(validationSchema);
    this.setLengthOfRows(keys.length);
    keys.forEach(key => {
      const data = {};
      data['id'] = validationSchema.properties[key].name;
      data['orderNumber'] = validationSchema.properties[key];
      data['schemaProperty'] = key;
      data['description'] = validationSchema.properties[key].description;
      data['requiredField'] = validationSchema.properties[key].required;
      data['uniqueRecordIdentifier'] = validationSchema.properties[key].isDocumentKey;
      data['submissionKey'] = validationSchema.properties[key].isSubmissionKey;
      data['assignmentKey'] = validationSchema.properties[key].isAssignmentsKey;
      data['propertyType'] = validationSchema.properties[key].type;
      if (validationSchema.fileType === 'XLSX') {

        data['pullFromWorksheet'] = validationSchema.properties[key].xlsLocation.useWorksheetName;
        data['worksheetIterationLocation'] = validationSchema.properties[key].xlsLocation.worksheet.useIterationValue;
        data['worksheetExactLocation'] = !validationSchema.properties[key].xlsLocation.worksheet.useIterationValue;
        data['worksheetLocation'] = validationSchema.properties[key].xlsLocation.worksheet.numberedLocation;
        data['rowIterationLocation'] = validationSchema.properties[key].xlsLocation.row.useIterationValue;
        data['rowExactLocation'] = !validationSchema.properties[key].xlsLocation.row.useIterationValue;
        data['rowLocation'] = validationSchema.properties[key].xlsLocation.row.numberedLocation;
        data['columnExactLocation'] = validationSchema.properties[key].xlsLocation.column.numberedLocation;

      }
      data['minValue'] = validationSchema.properties[key].min;
      data['maxValue'] = validationSchema.properties[key].max;
      data['minLength'] = validationSchema.properties[key].minLength;
      data['maxLength'] = validationSchema.properties[key].maxLength;
      data['pattern'] = validationSchema.properties[key].pattern;
      data['anyOf'] = validationSchema.properties[key].anyOf;
      data['listOfValidValues'] = validationSchema.properties[key].enum;
      data['remove'] = key;
      tableData.push(data);
    });
  
    tableData.sort((a, b) => parseFloat(a.orderNumber.orderNumber) - parseFloat(b.orderNumber.orderNumber));
    
    return tableData;
  }

  getPropertiesKeys(validationSchema: ValidationSchema): string[] {
    return _.keys(validationSchema.properties);
  }

  objSize(property: Property): number {
    const exclusions = ['name', 'required', 'isSubmissionKey', 'isDocumentKey', 'isAssignmentsKey', 'description', 'orderNumber'];
    let count = 0;
    _.keys(property).forEach(key => {
      if (exclusions.indexOf(key) === -1 || this.isPropertySetToTrue(property, key)) {
        count += 1;
      }
    });
    return count;
  }

  isPropertySetToTrue(property: Property, key: string): boolean {
    return (property[key] === 'true' || property[key] === true);
  }

  uploadFile(schemaType, formData ) {
    const url = `${environment.apiUrl}${schemaType}/validationschema/fileupload`;
    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true
    });
    return this.httpClient.request(req)
    .pipe(catchError(this.handleError('uploadFile', null)));
  }

  isPropertyKeyDuplicate(enteredPropertyName: string, validationSchema: ValidationSchema): boolean {
    const lowerCaseEnteredString = enteredPropertyName.replace(/ /g, '').toLowerCase();
    const matchingKey = Object.keys(validationSchema.properties).find(key => key.toLowerCase() === lowerCaseEnteredString);
    return !!matchingKey;
  }

}
