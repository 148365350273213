import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { LoadingModule } from 'ngx-loading';
import { AggregateComponent } from './aggregate.component';
import { MessagesModule } from '../../messages/messages.module';
import { AgGridModule } from 'ag-grid-angular';
import { PipesModule } from '../../pipes/pipes.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    AgGridModule.withComponents([]),
    FormsModule,
    RouterModule,
    FontAwesomeModule,
    LoadingModule,
    PipesModule,
    MessagesModule,
    CollapseModule.forRoot()
  ],
  declarations: [AggregateComponent],
  exports: [AggregateComponent]
})
export class AggregateModule { }
