import {Component, OnInit} from '@angular/core';
import {User} from '../model/user';
import {UserService} from '../service/user.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  title: string;
  user: User;
  private _message = new Subject<string>();
  messageText: string;
  error: boolean;

  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this._message.subscribe(message => this.messageText = message);
    this.user = this.userService.getStoredUser();
    this.setTitle();
  }

  private setTitle(): void {
    const datacallGroupArray = this.user.dataCallGroups;
    const datacallGroup = datacallGroupArray.find(dcg => dcg.groupName.toLocaleLowerCase() === this.user.selectedDatacallGroup.toLocaleLowerCase() );
    const datacalls = datacallGroup.dataCalls;
    const datacall = datacalls.find(dc => dc.dataCallName.toLocaleLowerCase() === this.user.currentSchema.toLocaleLowerCase());
    const displayName: string = datacall['dataCallDisplayName'];
    if (this.user.currentSchema.toUpperCase() === 'PBR') {
      this.title = 'Welcome to the Company Experience Data Collection System';
    } else if (displayName && displayName.trim().length > 0) { 
      this.title = `Welcome to the ${displayName.trim()} Data Collection System`;
    } else {
      this.title = `Welcome to the ${this.user.currentSchema.toUpperCase()} Data Collection System`;
    }
  }

  canViewFileDashboard(): boolean {
    return this.userService.canViewFileDashboard(this.user);
  }

}
