import {Component, TemplateRef} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {
  FileValidationMaintenanceService
} from '../../../file-validation-maintenance/services/file-validation-maintenance.service';
import {UnCamelCasePipe} from '../../../pipes/un-camel-case.pipe';
import {FileSubmissionState} from '../../../file-dashboard/model/file-submission-state.enum';
import {FileAdminService} from '../../service/file-admin.service';

@Component({
  selector: 'app-status-renderer-for-admin',
  templateUrl: './status-renderer-for-admin.component.html',
  styleUrls: ['./status-renderer-for-admin.component.css']
})
export class StatusRendererForAdminComponent implements ICellRendererAngularComp {
  params: any;
  openRejectReasonsRef: BsModalRef;
  displayedColumns: String[];
  dataSource: any[];
  showTable: boolean;
  tableData: any[] = [];
  assignmentKeyFieldName: any;
  submissionKeys: any;
  submissionKeyFieldNames: string[] = [];
  nonUniqueSubmissionKeyMessage: string;

  agInit(params: any): void {
    this.params = params;
  }

  constructor(
    public modalService: BsModalService,
    public schemaValidationService: FileValidationMaintenanceService,
    public fileAdminService: FileAdminService,
    public unCamelCase: UnCamelCasePipe,
  ) {
  }

  checkForSystemRejected(data: any): boolean {
    if (data.status === FileSubmissionState.systemRejectedFile && data.rejectionReasons !== undefined) {
      return data.status === FileSubmissionState.systemRejectedFile;
    } else {
      return false;
    }
  }

  goToRejectionReason(params, template: TemplateRef<any>): void {
    this.assignmentKeyFieldName = this.schemaValidationService.getAssignmentKeyFieldName();
    this.submissionKeys = this.fileAdminService.getSubmissionKeys();
    if (params.rejectionReasons === null || params.rejectionReasons.length === 0) {
      this.showTable = false;
    } else {
      this.showTable = true;
      params.rejectionReasons.forEach(x => {
        const data = {};
        data['reason'] = x;
        this.tableData.push(data);
      });
    }
    this.getSubmissionKeyFieldNames();
    if (this.assignmentKeyFieldName != undefined) {
      this.getAssignmentKeyFieldName();
    }
    this.setReasonMessageNames();
    this.displayedColumns = ['reason'];
    this.dataSource = this.tableData;
    this.openRejectReasonsRef = this.modalService.show(template, {class: 'modal-lg'});
    this.tableData = [];
    this.submissionKeyFieldNames = [];
  }

  getAssignmentKeyFieldName() {
    let name = '';
    this.assignmentKeyFieldName.split('_').forEach((item, index) => {
      if (index > 0) {
        item = item.charAt(0).toUpperCase() + item.slice(1);
      }
      name = name + item;
    });
    this.assignmentKeyFieldName = this.unCamelCase.transform(name);
  }

  getSubmissionKeyFieldNames() {
    this.submissionKeys.keys.forEach(key => {
      let name = '';
      key.name.split('_').forEach((item, index) => {
        if (index > 0) {
          item = item.charAt(0).toUpperCase() + item.slice(1);
        }
        name = name + item;
      });
      this.submissionKeyFieldNames.push(`"${this.unCamelCase.transform(name)}"`);
    });
    this.setSubmissionKeyFieldNamesForReasonMessageNames();
  }

  setSubmissionKeyFieldNamesForReasonMessageNames() {
    if (this.submissionKeyFieldNames.length > 1) {
      this.nonUniqueSubmissionKeyMessage = `The file contains more than one unique value in the ${this.submissionKeyFieldNames.join(', ')} fields. Only one is allowed.`;
    } else if (this.submissionKeyFieldNames.length === 1) {
      this.nonUniqueSubmissionKeyMessage = `The file contains more than one unique value in the ${this.submissionKeyFieldNames[0]} field. Only one is allowed.`;
    }
  }

  setReasonMessageNames() {
    this.tableData.forEach(x => {
      if (x.reason === 'OVER_TOLERANCE') {
        x.reason = 'The submitted file has more errors than are allowed for this datacall.';
      }
      if (x.reason === 'NOT_ASSIGNED_ASSIGNMENT_FIELD_VALUE') {
        x.reason = `The file has values in the "${this.assignmentKeyFieldName}" field that user is not assigned to.`;
      }
      if (x.reason === 'NON_UNIQUE_SUBMISSION_KEY') {
        x.reason = this.nonUniqueSubmissionKeyMessage;
      }
    });
  }

  refresh(): boolean {
    return false;
  }

}
