<ngx-loading [show]="loading"></ngx-loading>
<div *ngIf="rowData" id="fileSubmissionErrors" class="wrapper">
  <div class="row page-title">
    <div class="col-12">
      <h1>{{ fileNameWithoutExtension }} File Exceptions Summary</h1>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/fileDashboard']">File Dashboard</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ originalFileName }}
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row mb-3 pl-3" id="fileLevelValidations">
    <div class="col-12">
      <h2><b>File Level Validations</b></h2>
    </div>
  </div>

  <div class="row pl-5" *ngIf="fileLevelExceptionTypes ; else noFileLevelValidations" id="fileLevelValidationsMessage">
    <div class="col-12">
      <p>File contains {{fileLevelExceptionsActualValue}} number of records and should have {{fileLevelExceptionsExpectedValue}} number of records.</p>
    </div>
  </div>

  <ng-template #noFileLevelValidations>
    <div class="row pl-5" id="noValidationsMessage">
      <div class="col-12">
        <p>No File Level Validations Found.</p>
      </div>
    </div>
  </ng-template>

  <div class="row mb-3 pl-3" id="recordLevelValidations">
    <div class="col-12">
      <h2><b>Record Level Validations</b></h2>
    </div>
  </div>

    <div class="row mb-3">
      <div class="col-12">
        <ag-grid-angular style="width: 100%; height: 520px;" #agGrid id="dashboardGrid" class="ag-theme-balham"
          [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData" [context]="context"
          [frameworkComponents]="frameworkComponents" (gridReady)="onGridReady($event)" pagination="true"
          [paginationPageSize]="paginationPageSize">
        </ag-grid-angular>
      </div>
    </div>
</div>
