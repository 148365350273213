import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { faRandom } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { FileValidationMaintenanceService } from '../../../../file-validation-maintenance/services/file-validation-maintenance.service';
import { BreadcrumbService } from '../../../../service/breadcrumb.service';
import { Breadcrumb } from '../../../../model/breadcrumb';

@Component({
  selector: 'app-view-renderer',
  templateUrl: './view-renderer.component.html'
})
export class ViewRendererComponent implements ICellRendererAngularComp {
  params: any;
  faRandom = faRandom;

  constructor(
    private router: Router,
    private schemaMaintenanceService: FileValidationMaintenanceService,
    private breadcrumbService: BreadcrumbService
  ) {}

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  viewValidationSchema(): void {
    this.schemaMaintenanceService.setSelectedAuditSchema(this.params.data);
    this.breadcrumbService.addBreadcrumb(new Breadcrumb('Field Definitions', '/schemaAudit'));
    this.router.navigate(['/schemaView']);
  }

}
