import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilingValidationComponent } from './filing-validation.component';
import {FilingValidationRoutingModule} from './filing-validation-routing.module';
import {LoadingModule} from 'ngx-loading';
import {FilingValidationService} from './service/filing-validation.service';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {ValidationEditModule} from './validation-edit/validation-edit.module';
import {MessagesModule} from '../messages/messages.module';

@NgModule({
  imports: [
    CommonModule,
    MessagesModule,
    LoadingModule,
    NgxDatatableModule,
    ValidationEditModule,
    FilingValidationRoutingModule
  ],
  declarations: [FilingValidationComponent],
  providers: [FilingValidationService],
  exports: [FilingValidationComponent]
})
export class FilingValidationModule { }
