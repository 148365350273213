<input *ngIf="assignedToUser.assignedToUser; else notAssignedToUser" id="{{params.colDef.field}}: {{params.data.schemaProperty}}: {{params.value}}" name="requiredField"
    type="checkbox" [checked]="params.value" disabled>

<ng-template #notAssignedToUser>
    <input *ngIf = "(params.value && includesTrue) ; else noValue" id="{{params.colDef.field}}: {{params.data.schemaProperty}}: {{params.value}}" name="requiredField" type="checkbox" [checked]="params.value"
        value={{params.value}} (change)="checkValue($event, params.data)">
</ng-template>

<ng-template #noValue>
    <input *ngIf = "(!includesTrue) ; else notTrue" id="{{params.colDef.field}}: {{params.data.schemaProperty}}: {{params.value}}" name="requiredField" type="checkbox" [checked]="params.value"
        value={{params.value}} (change)="checkValue($event, params.data)">
</ng-template>

<ng-template #notTrue>
    <input id="{{params.colDef.field}}: {{params.data.schemaProperty}}: {{params.value}}" name="requiredField" type="checkbox" [checked]="params.value"
        value={{params.value}} (change)="checkValue($event, params.data)" disabled>
</ng-template>