export interface RoleStatusFactory {
    _id: number;
    name: string;
    value: string;
    numberOfFilings: number | any;
}

export interface GroupBy {
    initial: string;
    isGroupBy: boolean;
}

export const roleStatusFactory: (RoleStatusFactory | GroupBy)[] = [
    { initial: "User Assignment Information (Distinct)", isGroupBy: true},
        { _id: 1, name: 'USER_ASSIGNMENTS', value: 'User Assignments', numberOfFilings: 0},

    { initial: "Successful Filings", isGroupBy: true},
        { _id: 2, name: 'FILE_PROCESSED', value: 'File Processed', numberOfFilings: 0 },
        { _id: 3, name: 'NAIC_ACCEPTS_WITH_ERRORS', value: 'NAIC Accepts with Errors', numberOfFilings: 0 },
        { _id: 4, name: 'SUBMISSION_KEYS', value: 'Distinct By Submission Keys', numberOfFilings: 0 },
        { _id: 5, name: 'ASSIGNMENT_KEYS', value: 'Distinct By Assignment Keys', numberOfFilings: 0},

    { initial: "Unsuccessful Filings", isGroupBy: true},
        { _id: 6, name: 'FILE_REJECTED', value: 'File Rejected', numberOfFilings: 0 },
        { _id: 7, name: 'SYSTEM_REJECTED_FILE', value: 'System Rejected', numberOfFilings: 0 },

    { initial: "In Progress Filings", isGroupBy: true},
        { _id: 8, name: 'FILE_PENDING_NAIC_REVIEW', value: 'File Pending NAIC Review', numberOfFilings: 0  },
        { _id: 9, name: 'NAIC_REVIEW_IN_PROGRESS', value: 'NAIC Review in Progress', numberOfFilings: 0 },
        { _id: 10, name: 'FILE_PENDING_ADMIN_REVIEW', value: 'File Pending Admin Review', numberOfFilings: 0 },
        { _id: 11, name: 'AWAITING_SENDER_VERIFICATION', value: 'Awaiting Sender Verification', numberOfFilings: 0 },
        { _id: 12, name: 'NAIC_REJECTS_FILE_RESUBMIT', value: 'NAIC Rejects File Resubmit', numberOfFilings: 0 },
        { _id: 13, name: 'RESPONSE_RECEIVED', value: 'Response Received', numberOfFilings: 0 },
        { _id: 14, name: 'NAIC_REVIEW_COMPLETE', value: 'NAIC Review Complete', numberOfFilings: 0 }
]