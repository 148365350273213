import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MessagesModule} from '../../messages/messages.module';
import {LoadingModule} from 'ngx-loading';
import {FilingSearchComponent} from './filing-search.component';
import {FilingSearchRoutingModule} from './filing-search-routing.module';
import {PipesModule} from '../../pipes/pipes.module';
import {FormsModule} from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { AssignmentValueRenderer } from './cell-renderer/assignment-value-renderer/assignment-value-renderer.component';
import { AttachmentRenderer } from './cell-renderer/attachment-renderer/attachment-renderer.component';

@NgModule({
  imports: [
    FilingSearchRoutingModule,
    CommonModule,
    MessagesModule,
    LoadingModule,
    PipesModule,
    FormsModule,
    AgGridModule.withComponents([
      AssignmentValueRenderer,
      AttachmentRenderer
    ]),
  ],
  declarations: [FilingSearchComponent, AssignmentValueRenderer, AttachmentRenderer],
  entryComponents: [FilingSearchComponent],
})

export class FilingSearchModule {
}
