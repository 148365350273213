import { Component, TemplateRef } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ICellRenderer } from 'ag-grid-community';
import { FileValidationMaintenanceService } from '../../services/file-validation-maintenance.service';

@Component({
  selector: 'app-remove-schema-property-renderer',
  templateUrl: './remove-schema-property-renderer.component.html'
})
export class RemoveSchemaPropertyRendererComponent implements ICellRenderer {
  params: any;
  faTimes = faTimes;
  deleteKey: string;
  modalRef: BsModalRef;
  isRemoveDisabled: boolean = false;
  isRemoveDependencyDisabled: boolean = false;
  usedSchemaProperties: any[];
  usedSchemaPropertiesForDependency: any[];
  constructor(
    private modalService: BsModalService,
    private fileValidationMaintenanceService: FileValidationMaintenanceService
  ) { }

  agInit(params: any): void {
    this.params = params;
    this.usedSchemaProperties = this.fileValidationMaintenanceService.getUsedSchemaProperties();
    this.usedSchemaPropertiesForDependency = this.fileValidationMaintenanceService.getUsedSchemaPropertiesForDependency();
    this.isRemoveDisabled = this.usedSchemaProperties.includes(this.params.value);
    this.isRemoveDependencyDisabled = Array.isArray(this.usedSchemaPropertiesForDependency) && this.usedSchemaPropertiesForDependency.includes(this.params.value);
  }
  

  refresh(): boolean {
    return true;
  }

  removeProperty(key: string, template: TemplateRef<any>): void {
    this.deleteKey = key;
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
  }

  cancelRemove(): void {
    this.modalRef.hide();
  }

  confirmRemove(): void {
    this.modalRef.hide();
    this.params.context.componentParent.confirmRemove(this.deleteKey);
  }

}
