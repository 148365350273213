import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotOnlyWhitespaceDirective } from './not-only-whitespace.directive';
import {DataTableHelperService} from './data-table-helper.service';
import { NoSpaceDirective } from './no-space.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [NotOnlyWhitespaceDirective, NoSpaceDirective],
  providers: [DataTableHelperService],
  exports:[NotOnlyWhitespaceDirective, NoSpaceDirective]
})
export class SharedModule { }
