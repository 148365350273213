import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject ,  Subscription } from 'rxjs';
import { FileSubmission } from '../../model/file-submission';
import { UpdateStatusService } from './service/update-status.service';

@Component({
  selector: 'app-update-status',
  templateUrl: './update-status.component.html'
})
export class UpdateStatusComponent implements OnInit, OnDestroy {
  @Input() fileSubmission: FileSubmission;
  title: string;
  statuses: string[];
  status = {
    isOpen: false,
    isDisabled: false,
    placeholder: 'File Status',
    value: 'File Status'
  };
  private _message = new Subject<string>();
  messageText: string;
  error = false;
  loading = false;
  subscriptions: Array<Subscription> = [];

  constructor(private updateStatusService: UpdateStatusService,
              public modalRef: BsModalRef) {
  }

  ngOnInit() {
    this._message.subscribe(message => this.messageText = message);
    this.loading = true;
    this.title = `Update Status for ${this.fileSubmission.originalFileName}`;
    this.getStatuses();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getStatuses() {
    const sub = this.updateStatusService.getStatuses(this.fileSubmission)
      .subscribe(
        statuses => {
          this.error = false;
          this.statuses = statuses;
          this.loading = false;
        },
        error => {
          this.error = true;
          this.messageText = <any>error;
          this._message.next(`${this.messageText}`);
        }
      );
    this.subscriptions.push(sub);
  }

  setStatus(status: string) {
    this.status.value = status;
  }

  updateStatus() {
    if (this.status.value === this.status.placeholder) {
      this.messageText = 'Please select a status for this file.';
      this._message.next(`${this.messageText}`);
      return;
    }

    const fileSubmissionStateData = {
      fileSubmissionId: this.fileSubmission.id,
      fileSubmissionState: this.status.value,
      fileSubmissionComments: this.fileSubmission.comments
    };
    this.loading = true;
    const sub = this.updateStatusService.updateFileSubmissionState(this.fileSubmission.schemaType, fileSubmissionStateData)
      .subscribe(
        () => {
          this.error = false;
          this.messageText = 'Status updated';
          this.modalRef.hide();
        },
        error => {
          this.error = true;
          this.messageText = <any>error;
          this._message.next(`${this.messageText}`);
        }
      );
    this.subscriptions.push(sub);
  }

}
