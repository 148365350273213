import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription ,  Subject } from 'rxjs';
import { FileSubmission } from '../../model/file-submission';
import { FileSubmissionStateHistory } from '../../model/file-submission-state-history';
import { SubmissionAuditService } from './service/submission-audit.service';
import { DateRendererComponent } from '../../cell-renderer/date-renderer/date-renderer.component';
import { UnCamelCasePipe } from '../../pipes/un-camel-case.pipe';

@Component({
  selector: 'app-submission-audit',
  templateUrl: './submission-audit.component.html'
})
export class SubmissionAuditComponent implements OnInit, OnDestroy {
  @Input() fileSubmission: FileSubmission;
  title: string;
  private _message = new Subject<string>();
  messageText: string;
  error = false;
  submissionAudit: FileSubmissionStateHistory[];
  scrollBarHorizontal = (window.innerWidth < 1200);
  subscriptions: Array<Subscription> = [];

  columnDefs = [];
  rowData = [];
  defaultColDef: any;
  context: any;
  frameworkComponents: any;

  constructor(
    private submissionAuditService: SubmissionAuditService,
    public modalRef: BsModalRef,
    private unCamelCase: UnCamelCasePipe
  ) {
    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 12000);
    };
  }

  ngOnInit() {
    this.context = { componentParent: this };
    this.frameworkComponents = {
      dateRenderer: DateRendererComponent,
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this._message.subscribe(message => this.messageText = message);
    this.title = `Submission Audit Trail for ${this.fileSubmission.originalFileName}`;
    this.getSubmissionAudit();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getSubmissionAudit() {
    const sub = this.submissionAuditService.getSubmissionAudit(this.fileSubmission.schemaType, this.fileSubmission.id)
      .subscribe(
        submissionAudit => {
          this.error = false;
          this.submissionAudit = submissionAudit;
          this.rowData = this.getAuditData();
          this.columnDefs = this.getColumnDef(this.rowData[0]);
        },
        error => {
          this.error = true;
          this.messageText = <any>error;
          this._message.next(`${this.messageText}`);
        }
      );
    this.subscriptions.push(sub);
  }

  getColumnDef(row: any): any[] {
    const columnsDef: any[] = [];
    const keys = Object.keys(row);
    keys.forEach(key => {
      const headerName = this.unCamelCase.transform(key);
      if (headerName === 'Date') {
        columnsDef.push({ headerName: headerName, field: key, cellRenderer: 'dateRenderer' });
      } else {
        columnsDef.push({ headerName: headerName, field: key });
      }
    });
    return columnsDef;
  }

  onGridReady(params: any): void {
    const gridApi = params.api;

    gridApi.sizeColumnsToFit();
  }

  getAuditData(): Array<any> {
    const auditData: Array<any> = [];
    this.submissionAudit.forEach(audit => {
      const data = {};
      data['date'] = audit.modifiedDate;
      data['user'] = audit.updatedBy;
      data['status'] = audit.fileSubmissionState;
      auditData.push(data);
    });
    return auditData;
  }

}
