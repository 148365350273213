import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingModule } from 'ngx-loading';
import { ErrorsComponent } from './errors.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ErrorsService } from './service/errors.service';
import {MessagesModule} from '../../messages/messages.module';
import { PipesModule } from '../../pipes/pipes.module';
import { AgGridModule } from 'ag-grid-angular';
import { NumberRendererComponent } from '../../cell-renderer/number-renderer/number-renderer.component';
import { FormFormatExceptionsRendererComponent } from './cell-renderer/form-format-exceptions-renderer/form-format-exceptions-renderer.component';
import { CrossFieldExceptionsRendererComponent } from './cell-renderer/cross-field-exceptions-renderer/cross-field-exceptions-renderer.component';
import { FileAdminService } from '../service/file-admin.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AgGridModule.withComponents([NumberRendererComponent, FormFormatExceptionsRendererComponent, CrossFieldExceptionsRendererComponent]),
    LoadingModule,
    PipesModule,
    MessagesModule,
    RouterModule,
  ],
  declarations: [ErrorsComponent, FormFormatExceptionsRendererComponent, CrossFieldExceptionsRendererComponent],
  providers: [ErrorsService, FileAdminService],
  exports: [ErrorsComponent]
})
export class ErrorsModule {
}
