import { AndOrConditions } from "../model/and-or-conditions";
import { Functions } from "../model/functions";
import { GlobalFunctions } from "../model/global-functions";
import { Operaters } from "../model/operators";
import { OrderOfPrecedence } from "../model/order-of-precedence";

export const andOrConditions: AndOrConditions[] = [
    { value: '&&', label: 'All' },
    { value: '||', label: 'Any' }
];
export const operators: Operaters[] = [
    { label: '+', value: 'Add' },
    { label: '-', value: 'Subtract' },
    { label: '*', value: 'Multiply' },
    { label: '/', value: 'Divide' },
    { label: '>', value: 'Greater Than' },
    { label: '>=', value: 'Greater Than or Equal' },
    { label: '<', value: 'Less Than' },
    { label: '<=', value: 'Less Than or Equal' },
    { label: '===', value: 'Equal' },
    { label: '!==', value: 'Not Equal' }
];

export const orderOfPrecedence: OrderOfPrecedence[] = [
    { operator: '*', order: 1 },
    { operator: '/', order: 1 },
    { operator: '+', order: 2 },
    { operator: '-', order: 2 }
]

export const functions: Functions[] = [
    { value: 'IsNull(<ENTER FIELD REFERENCE>)', label: 'Check for null value', setSelection: 8, selectionWord: '<ENTER FIELD REFERENCE>' },
    { value: '!IsNull(<ENTER FIELD REFERENCE>)', label: 'Check not null value', setSelection: 9 , selectionWord: '<ENTER FIELD REFERENCE>' },
    { value: 'Round(<EXPRESSION OR FIELD REFERENCE TO ROUND>, <DECIMAL PLACES TO ROUND TO>)', label: 'Round Value', setSelection: 7, selectionWord: '<EXPRESSION OR FIELD REFERENCE TO ROUND>' },
    { value: 'StrToUpper(<STRING OR STRING FIELD REFERENCE>)', label: 'Set value to uppercase', setSelection: 12, selectionWord: '<STRING OR STRING FIELD REFERENCE>'},
    { value: 'ToDate(<DATE IN YYYYMMDD FORMAT>)', label: 'Convert character to date', setSelection: 8, selectionWord: '<DATE IN YYYYMMDD FORMAT>'},
    { value: 'Abs(<EXPRESSION OR NUMBER/DECIMAL FIELD REFERENCE>)', label: 'Absolute value', setSelection: 5, selectionWord: '<EXPRESSION OR NUMBER/DECIMAL FIELD REFERENCE>'},
    { value: 'IsOdd(<NUMBER EXPRESSION OR NUMBER FIELD REFERENCE>)', label: 'Value is an odd number', setSelection: 7, selectionWord: '<NUMBER EXPRESSION OR NUMBER FIELD REFERENCE>'},
    { value: 'Int(<EXPRESSION OR NUMBER/DECIMAL FIELD REFERENCE>)', label: 'Truncate decimal', setSelection: 5, selectionWord: '<EXPRESSION OR NUMBER/DECIMAL FIELD REFERENCE>'},
    { value: 'NumDaysBetween(ToDate(<FROM DATE>),ToDate(<TO DATE>))', label: 'Number of days between', setSelection: 23, selectionWord: '<FROM DATE>'}
];

export const globalFunctions: GlobalFunctions[] = [{
    name: 'IsNull',
    label: 'Check for null value', 
    description: 'This function checks for a null value. It takes one Parameter that is checked for null. It evaluates to true if the value given as the Parameter is null. A zero is not considered null.',
    parameters:[{
        parameterNumber: 1,
        description: 'This is the value to be checked for null'
    }],
    examplesText: [{
        exampleTextNumber: 1,
        description: 'IsNull( data, "Field_A" ) will evaluate to false if Field_A has a value.'
    }],
    usageSteps: [{
        usageStepNumber: 1,
        description: 'isNull(&lt;ENTER FIELD REFERENCE&gt;) displays in the condition box where cursor is placed.'
    },{
        usageStepNumber: 2,
        description: 'Replace &lt;ENTER FIELD REFERENCE&gt; text with data field reference by highlighting the text &lt;ENTER FIELD REFERENCE&gt; in the text box, then clicking add element to select a field.'
    }]
},{
    name: '!IsNull',
    label: 'Check not null value',
    description: 'This function checks for a null value. It takes one Parameter that is checked for null. It evaluates to false if the value given as the Parameter is null. A zero is not considered null.',
    parameters:[{
        parameterNumber: 1,
        description: 'This is the value to be checked for not null.'
    }],
    examplesText: [{
        exampleTextNumber: 1,
        description: '!IsNull( data, "Field_A" ) will evaluate to true if Field_A has a value.'
    }],
    usageSteps: [{
        usageStepNumber: 1,
        description: '!isNull(&lt;ENTER FIELD REFERENCE&gt;) displays in the condition box where cursor is placed.'
    },{
        usageStepNumber: 2,
        description: 'Replace &lt;ENTER FIELD REFERENCE&gt; text with data field reference by highlighting the text &lt;ENTER FIELD REFERENCE&gt; in the text box, then clicking add element to select a field.'
    }]
},{
    name: 'Round',
    label: 'Round Value',
    description: 'This function returns a number that has been rounded to the number of decimal places given.',
    parameters:[{
        parameterNumber: 1,
        description: 'The value to be rounded.'
    },{
        parameterNumber: 2,
        description: 'The number of decimal places to round to.'
    }],
    examplesText: [{
        exampleTextNumber: 1,
        description: 'Round(data["Field_A"] / 2, 0) will return 3 if Field_A has a value 5.'
    },{
        exampleTextNumber: 2,
        description: 'Round(data["Field_A"] / 2, 1) will return 2.5 if Field_A has a value 5.'
    }],
    usageSteps: [{
        usageStepNumber: 1,
        description: 'Round(&lt;EXPRESSION OR FIELD REFERENCE TO ROUND&gt;, &lt;DECIMAL PLACES TO ROUND TO&gt;) displays in the condition box where cursor is placed.'
    },{
        usageStepNumber: 2,
        description: 'Replace &lt;EXPRESSION OR FIELD REFERENCE TO ROUND&gt; with an expression that evaluates to a number that is desired to be rounded by highlighting the text &lt;EXPRESSION OR FIELD REFERENCE TO ROUND&gt; in the text box, then clicking add element to select the input(s).'
    },{
        usageStepNumber: 3,
        description: 'Replace &lt;DECIMAL PLACES TO ROUND TO&gt; with the number of decimal places to round to by highlighting the text &lt;DECIMAL PLACES TO ROUND TO&gt; in the text box, then entering the value.'
    }]
},{
    name: 'ToDate',
    label: 'Convert character to date', 
    description: `This function returns a data object from a string or field reference and is limited to the format of yyyyMMDD.`,
    parameters:[{
        parameterNumber: 1,
        description: 'a string or field reference to be converted to a date object. Must be of the format yyyyMMDD such as 20210130.'
    }],
    examplesText: [{
        exampleTextNumber: 1,
        description: 'ToDate(data["Field_A"]) will return a date object if Field_A has a value that can be represented as a valid.'
    },{
        exampleTextNumber: 2,
        description: 'ToDate("20200130").'
    }],
    usageSteps: [{
        usageStepNumber: 1,
        description: 'ToDate(&lt;DATE IN YYYYMMDD FORMAT&gt;) displays in the condition box where cursor is placed.'
    },{
        usageStepNumber: 2,
        description: 'Replace &lt;DATE IN YYYYMMDD FORMAT&gt; with a date or date field reference that is in yyyyMMDD format by highlighting the text &lt;DATE IN YYYYMMDD FORMAT&gt; in the text box, then clicking add element to select the field.'
    }]
},{
    name: 'StrToUpper',
    label: 'Set value to uppercase',
    description: `This function returns the uppercased string of the parameter.`,
    parameters:[{
        parameterNumber: 1,
        description: 'a string or field reference to be converted to upper case.'
    }],
    examplesText: [{
        exampleTextNumber: 1,
        description: 'StrToUpper(data["Field_A"]) will return a string of AAA if Field_A has a value of aaa.'
    }],
    usageSteps: [{
        usageStepNumber: 1,
        description: ' StrToUpper(&lt;STRING OR STRING FIELD REFERENCE&gt;) displays in the condition box where cursor is placed.'
    },{
        usageStepNumber: 2,
        description: 'Replace &lt;STRING OR STRING FIELD REFERENCE&gt; with a string or string field reference to be uppercased by highlighting the text &lt;STRING OR STRING FIELD REFERENCE&gt; in the text box, then clicking add element to select a field.'
    }]
},{
    name: 'IsOdd',
    label: 'Value is an odd number',
    description: `This function returns a true or false for the check of whether a number is odd.`,
    parameters:[{
        parameterNumber: 1,
        description: 'a number or number field reference to be checked for odd.'
    }],
    examplesText: [{
        exampleTextNumber: 1,
        description: 'IsOdd(data["Field_A"] / 2) will return a true if Field_A has a value of 6. 6 /2 = 3. 3 is Odd.'
    }],
    usageSteps: [{
        usageStepNumber: 1,
        description: 'IsOdd(&lt;NUMBER EXPRESSION OR NUMBER FIELD REFERENCE&gt;) displays in the condition box where cursor is placed.'
    },{
        usageStepNumber: 2,
        description: ' Replace &lt;NUMBER EXPRESSION OR NUMBER FIELD REFERENCE&gt; with a number expressions or number field reference to be checked for odd by highlighting the text &lt;NUMBER EXPRESSION OR NUMBER FIELD REFERENCE&gt; in the text box, then clicking add element to select the input(s).'
    }]
},{
    name: 'Int',
    label: 'Truncate decimal',
    description: `This function returns the integer portion of a number/decimal value.`,
    parameters:[{
        parameterNumber: 1,
        description: 'a number/decimal expression or number/decimal field reference to be truncated to integer value.'
    }],
    examplesText: [{
        exampleTextNumber: 1,
        description: 'Int(3.7) will return a 3.'
    }],
    usageSteps: [{
        usageStepNumber: 1,
        description: 'Int(&lt;EXPRESSION OR NUMBER/DECIMAL FIELD REFERENCE&gt;) displays in the condition box where cursor is placed.'
    },{
        usageStepNumber: 2,
        description: 'Replace &lt;EXPRESSION OR NUMBER/DECIMAL FIELD REFERENCE&gt; with a number/decimal expressions or number/decimal field reference to be truncated by highlighting the text &lt;EXPRESSION OR NUMBER/DECIMAL FIELD REFERENCE&gt; in the text box, then clicking add element to select the input(s).'
    }]
},{
    name: 'NumDaysBetween',
    label: 'Number of days between',
    description: `This function returns the number of days between two dates.`,
    parameters:[{
        parameterNumber: 1,
        description: 'From date.'
    },{
        parameterNumber: 2,
        description: 'To date.'
    }],
    examplesText: [{
        exampleTextNumber: 1,
        description: 'NumDaysBetween(ToDate("20210101"),ToDate(data["DateField1"])). If DateField1 is January 2nd 2021, this will return 1.  The From and To Date must be in the YYYYMMDD format.'
    }],
    usageSteps: [{
        usageStepNumber: 1,
        description: 'NumDaysBetween(ToDate(&lt;FROM DATE&gt;),ToDate(&lt;TO DATE&gt;)) displays in the condition box where cursor is placed.'
    },{
        usageStepNumber: 2,
        description: 'Replace &lt;FROM DATE&gt; with string or field reference in the YYYYMMMDD format by highlighting the text &lt;FROM DATE&gt; in the text box, then clicking add element to select a field.'
    },{
        usageStepNumber: 3,
        description: 'Replace &lt;TO DATE&gt; with string or field reference in the YYYYMMMDD format by highlighting the text &lt;TO DATE&gt; in the text box, then clicking add element to select a field.'
    }]
},{
    name: 'Abs',
    label: 'Absolute value',
    description: `This function will return a positive value for any numeric parameter.`,
    parameters:[{
        parameterNumber: 1,
        description: 'A number/decimal expression or number/decimal field reference whose absolute value is to be determined.'
    }],
    examplesText: [{
        exampleTextNumber: 1,
        description: 'Abs(-100) will return 100.'
    },{
        exampleTextNumber: 2,
        description: 'Abs(100) will return 100.'
    }],
    usageSteps: [{
        usageStepNumber: 1,
        description: 'Abs(&lt;EXPRESSION OR NUMBER/DECIMAL FIELD REFERENCE&gt;) displays in the condition box where cursor is placed.'
    },{
        usageStepNumber: 2,
        description: 'Replace &lt;EXPRESSION OR NUMBER/DECIMAL FIELD REFERENCE&gt; with a number/decimal  expression or number/decimal field reference to determine the Absolute value by highlighting the text &lt;EXPRESSION OR NUMBER/DECIMAL FIELD REFERENCE&gt; in the text box, then clicking add element to select the input(s).'
    }]
}]