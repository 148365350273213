<div id="fieldNameHeader" class="modal-header">
    <h4 id="fieldNameTitle" class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form name="fieldNameForm" (ngSubmit)="ok()" #fieldNameForm="ngForm">
    <div id="fieldNameBody" class="modal-body">
        <div class="row mb-3">
            <div class="col-12">
                <ul class="list-inline">
                    <li class="list-inline-item">
                        <label>Field List Search: </label>
                    </li>
                    <li class="list-inline-item"><input id="fieldNameSearch" type="text" class="form-control"
                            placeholder="Field Name" name="text" [(ngModel)]="nameToSearch"
                            (keyup)="search(nameToSearch)" />
                    </li>
                </ul>
            </div>
            <div class="col-12">
                <ul class="list-inline">
                    <li *ngFor="let fields of fieldNames">
                        <button id="{{fields.fieldNames}}" [disabled]="fields.isDisable" type="button" class="btn btn-sm text-left"
                            style="color:black" (click)="selectField(fields.fieldNames)">{{fields.fieldNames}}</button>
                        <div class="dropdown-divider"></div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button id="fieldNameCancel" type="button" class="btn btn-primary float-right" (click)="bsModalRef.hide()">
            Cancel </button>
        <button id="fieldNameSubmit" type="submit" class="btn btn-primary" [disabled]="saveFlag"> Ok</button>
    </div>
</form>