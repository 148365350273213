import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { MessagesModule } from '../../messages/messages.module';
import { ExpressionBuilderComponent } from './expression-builder.component';
import { MatExpansionModule } from '@angular/material';
import { ConstantValueComponent } from './constant-value/constant-value.component';
import { FieldSelectionComponent } from './field-selection/field-selection.component';
import { ExpressionSetComponent } from './expression-set/expression-set.component';
import { StringToModelParserService } from './shared/parserModelObj';
import { GlobalErrorHandler } from '../../shared/global-error-handler';
import { FunctionComponent } from './functions/function.component';
import { NoSanitizePipe } from '../../pipes/noSanitize.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MessagesModule,
    SharedModule,
    MatExpansionModule,
  ],
  providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler },
    StringToModelParserService],
  declarations: [ExpressionBuilderComponent, ConstantValueComponent, FieldSelectionComponent, ExpressionSetComponent, FunctionComponent, NoSanitizePipe],
  exports: [ExpressionBuilderComponent, NoSanitizePipe],
  entryComponents: [ConstantValueComponent, FieldSelectionComponent, FunctionComponent] 
})
export class ExpressionBuilderModule { }
