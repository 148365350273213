import {XlsLocationField} from './xls-location-field';

export class XlsLocation {
  useWorksheetName: boolean;
  worksheet: XlsLocationField;
  row: XlsLocationField;
  column: XlsLocationField;

  constructor() {
    this.useWorksheetName = false;
    this.worksheet = new XlsLocationField();
    this.row = new XlsLocationField();
    this.column = new XlsLocationField();
  }
}
