  export let MOCK_ODE: any = {
    "sections": [
        {
          key: "7aea4dfe-9b5a-4758-9257-8ee601b081c9",
          name: "IN-EXCHANGE (SINGLE)",
          sectionNumber: 0,
          sections: [
            {
              key: "7896b0bc-c6b4-4fb4-bd45-542fb7d2b8fd",
              name: "POLICY",
              sectionNumber: 0,
              sections: [
              ],
              questions: [
                {
                  key: "58a56504-62c6-47cb-87de-fd4011c0f83d",
                  text: "What is Policy? ",
                  inputs: [
                    {
                      schemaPropertyNameKey: "Disabled",
                      key: "1e12ecce-1174-45ee-9b1a-c3c8a21c63b7",
                      label: "Platinum",
                      dataType: null,
                      controlType: "",
                      options: [
                      ],
                      disabled: true,
                      value: null,
                      required: false,
                      warnings: [
                      ],
                      errors: [
                      ],
                      minLength: null,
                      maxLength: null,
                      pattern: null,
                      min: null,
                      max: null,
                      anyOf: null,
                    },
                    {
                      schemaPropertyNameKey: "Disabled",
                      key: "e6d25e64-0889-4d0e-8b43-12f840485dde",
                      label: "New",
                      dataType: null,
                      controlType: "",
                      options: [
                      ],
                      disabled: true,
                      value: null,
                      required: false,
                      warnings: [
                      ],
                      errors: [
                      ],
                      minLength: null,
                      maxLength: null,
                      pattern: null,
                      min: null,
                      max: null,
                      anyOf: null,
                    },
                    {
                      schemaPropertyNameKey: "Disabled",
                      key: "1cb5a0b0-b75c-4775-9f62-605c05dae00e",
                      label: "Silver",
                      dataType: null,
                      controlType: "",
                      options: [
                      ],
                      disabled: true,
                      value: null,
                      required: false,
                      warnings: [
                      ],
                      errors: [
                      ],
                      minLength: null,
                      maxLength: null,
                      pattern: null,
                      min: null,
                      max: null,
                      anyOf: null,
                    },
                  ],
                  order: 2,
                  label: "1",
                  rules: [
                  ],
                },
              ],
              headings: [
                {
                  heading: "Policy Heading",
                  order: 1,
                },
              ],
            },
          ],
          questions: [
            {
              key: "cc4bf670-51a5-4c9c-9f12-23a515946d4d",
              text: "What is Policy? ",
              inputs: [
                {
                  schemaPropertyNameKey: "eNum 1",
                  key: "8ddf3bb5-78d5-4577-a935-a1fbc0ee426a",
                  label: "Platinum",
                  dataType: "string",
                  controlType: "dropdown",
                  options: [
                    {
                      key: "yes",
                      value: "yes",
                    },
                    {
                      key: " no",
                      value: " no",
                    },
                  ],
                  disabled: false,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
                {
                  schemaPropertyNameKey: "Disabled",
                  key: "0b704189-1e03-4ff2-b54b-0772ed020ebd",
                  label: "New",
                  dataType: null,
                  controlType: "",
                  options: [
                  ],
                  disabled: true,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
                {
                  schemaPropertyNameKey: "Disabled",
                  key: "fb2d7acb-e8ed-4d98-b428-6f6e1d46a0e9",
                  label: "Silver",
                  dataType: null,
                  controlType: "",
                  options: [
                  ],
                  disabled: true,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
              ],
              order: 2,
              label: "1",
              rules: [
                {
                  ruleInputs: [
                    {
                      schemaPropertyNameKey: "8ddf3bb5-78d5-4577-a935-a1fbc0ee426a",
                      value: "yes",
                    },
                  ],
                  ruleKeys: [
                    {
                      key: "823b55b8-e304-4197-aa14-28f9b83ff2c6",
                    },
                    {
                      key: "e52d2c34-3cd2-4400-bf67-b54094f8aec3",
                    },
                    {
                      key: "b3a3f988-c679-40b3-9172-ffdebe2b984d",
                    },
                    {
                      key: "e102e3e7-48d0-413a-9bc2-dea0e92a7a44",
                    },
                    {
                      key: "ed6b0a4e-e207-4b9c-a62c-079d9c018ac1",
                    },
                    {
                      key: "8304aa56-5436-483a-aafd-d2bb9b9bff8e",
                    },
                  ],
                },
                {
                  ruleInputs: [
                    {
                      schemaPropertyNameKey: "8ddf3bb5-78d5-4577-a935-a1fbc0ee426a",
                      value: "no",
                    },
                  ],
                  ruleKeys: [
                  ],
                },
              ],
            },
          ],
          headings: [
            {
              heading: "Policy Heading",
              order: 1,
            },
          ],
        },
        {
          key: "920b14d7-aa0d-446e-a4b2-a5d25579faed",
          name: "IN-EXCHANGE (COPIED)",
          sectionNumber: 0,
          sections: [
            {
              key: "342652e5-1d49-4e4a-bbb0-36c068603405",
              name: "NEW POLICY",
              sectionNumber: 0,
              sections: [
              ],
              questions: [
                {
                  key: "a2894271-cbc1-40cf-a911-ec6b974d22d7",
                  text: "What is Policy? ",
                  inputs: [
                    {
                      schemaPropertyNameKey: "Disabled",
                      key: "59f8aa32-daac-4501-9052-e2f2442c4a0a",
                      label: "Platinum",
                      dataType: null,
                      controlType: "",
                      options: [
                      ],
                      disabled: true,
                      value: null,
                      required: false,
                      warnings: [
                      ],
                      errors: [
                      ],
                      minLength: null,
                      maxLength: null,
                      pattern: null,
                      min: null,
                      max: null,
                      anyOf: null,
                    },
                    {
                      schemaPropertyNameKey: "Disabled",
                      key: "823b55b8-e304-4197-aa14-28f9b83ff2c6",
                      label: "New",
                      dataType: null,
                      controlType: "",
                      options: [
                      ],
                      disabled: true,
                      value: null,
                      required: false,
                      warnings: [
                      ],
                      errors: [
                      ],
                      minLength: null,
                      maxLength: null,
                      pattern: null,
                      min: null,
                      max: null,
                      anyOf: null,
                    },
                    {
                      schemaPropertyNameKey: "Disabled",
                      key: "b7f01b64-5fc2-4ead-b42b-4b49e23af067",
                      label: "Silver",
                      dataType: null,
                      controlType: "",
                      options: [
                      ],
                      disabled: true,
                      value: null,
                      required: false,
                      warnings: [
                      ],
                      errors: [
                      ],
                      minLength: null,
                      maxLength: null,
                      pattern: null,
                      min: null,
                      max: null,
                      anyOf: null,
                    },
                  ],
                  order: 2,
                  label: "1",
                  rules: [
                  ],
                },
              ],
              headings: [
                {
                  heading: "Policy Heading",
                  order: 1,
                },
              ],
            },
            {
              key: "0d5f12a6-bf2a-434c-8bb4-3328c9f50aa9",
              name: "POLICY",
              sectionNumber: 0,
              sections: [
              ],
              questions: [
                {
                  key: "837145c0-4430-40f5-a3a2-137762991afc",
                  text: "What is Policy? ",
                  inputs: [
                    {
                      schemaPropertyNameKey: "Disabled",
                      key: "cac77f2f-5676-4c5a-8bfb-253ea5610b5f",
                      label: "Platinum",
                      dataType: null,
                      controlType: "",
                      options: [
                      ],
                      disabled: true,
                      value: null,
                      required: false,
                      warnings: [
                      ],
                      errors: [
                      ],
                      minLength: null,
                      maxLength: null,
                      pattern: null,
                      min: null,
                      max: null,
                      anyOf: null,
                    },
                    {
                      schemaPropertyNameKey: "Disabled",
                      key: "cc23d5fc-a538-4969-ab75-938eb74f88f3",
                      label: "New",
                      dataType: null,
                      controlType: "",
                      options: [
                      ],
                      disabled: true,
                      value: null,
                      required: false,
                      warnings: [
                      ],
                      errors: [
                      ],
                      minLength: null,
                      maxLength: null,
                      pattern: null,
                      min: null,
                      max: null,
                      anyOf: null,
                    },
                    {
                      schemaPropertyNameKey: "Disabled",
                      key: "caa01755-ceeb-4f54-a84a-23a283d118f1",
                      label: "Silver",
                      dataType: null,
                      controlType: "",
                      options: [
                      ],
                      disabled: true,
                      value: null,
                      required: false,
                      warnings: [
                      ],
                      errors: [
                      ],
                      minLength: null,
                      maxLength: null,
                      pattern: null,
                      min: null,
                      max: null,
                      anyOf: null,
                    },
                  ],
                  order: 2,
                  label: "1",
                  rules: [
                  ],
                },
              ],
              headings: [
                {
                  heading: "Policy Heading",
                  order: 1,
                },
              ],
            },
          ],
          questions: [
            {
              key: "2efc993e-abfd-41a7-a6d3-e20849612675",
              text: "What is Policy? ",
              inputs: [
                {
                  schemaPropertyNameKey: "Enum 3",
                  key: "8304aa56-5436-483a-aafd-d2bb9b9bff8e",
                  label: "Platinum",
                  dataType: "string",
                  controlType: "dropdown",
                  options: [
                    {
                      key: "yes",
                      value: "yes",
                    },
                    {
                      key: " no",
                      value: " no",
                    },
                  ],
                  disabled: false,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
                {
                  schemaPropertyNameKey: "Disabled",
                  key: "9bd37dc9-1e05-4114-bf73-a7fd08108e58",
                  label: "New",
                  dataType: null,
                  controlType: "",
                  options: [
                  ],
                  disabled: true,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
                {
                  schemaPropertyNameKey: "Enum 2",
                  key: "266ec784-3505-48ef-abad-7d2addf455d6",
                  label: "Silver",
                  dataType: "string",
                  controlType: "dropdown",
                  options: [
                    {
                      key: "yes",
                      value: "yes",
                    },
                    {
                      key: " no",
                      value: " no",
                    },
                  ],
                  disabled: false,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
              ],
              order: 2,
              label: "1",
              rules: [
              ],
            },
          ],
          headings: [
            {
              heading: "Policy Heading",
              order: 1,
            },
          ],
        },
        {
          key: "ea0c6ec2-bc87-4523-84a5-36d3d70516ee",
          name: "IN-EXCHANGE(COPIED NEW SECTION)",
          sectionNumber: 0,
          sections: [
            {
              key: "80ed8145-b803-40c8-901e-0a4246681c3e",
              name: "POLICY",
              sectionNumber: 0,
              sections: [
              ],
              questions: [
                {
                  key: "89dda2af-03d0-447e-8f97-1e3a449d26df",
                  text: "What is Policy? ",
                  inputs: [
                    {
                      schemaPropertyNameKey: "Disabled",
                      key: "0580bbb0-f95c-417a-be52-259f781bb3ba",
                      label: "Platinum",
                      dataType: null,
                      controlType: "",
                      options: [
                      ],
                      disabled: true,
                      value: null,
                      required: false,
                      warnings: [
                      ],
                      errors: [
                      ],
                      minLength: null,
                      maxLength: null,
                      pattern: null,
                      min: null,
                      max: null,
                      anyOf: null,
                    },
                    {
                      schemaPropertyNameKey: "Disabled",
                      key: "98fd94e6-748d-4a98-b71c-98445a9603fd",
                      label: "New",
                      dataType: null,
                      controlType: "",
                      options: [
                      ],
                      disabled: true,
                      value: null,
                      required: false,
                      warnings: [
                      ],
                      errors: [
                      ],
                      minLength: null,
                      maxLength: null,
                      pattern: null,
                      min: null,
                      max: null,
                      anyOf: null,
                    },
                    {
                      schemaPropertyNameKey: "Disabled",
                      key: "41f93590-43ce-4df9-9497-fee54ccd4818",
                      label: "Silver",
                      dataType: null,
                      controlType: "",
                      options: [
                      ],
                      disabled: true,
                      value: null,
                      required: false,
                      warnings: [
                      ],
                      errors: [
                      ],
                      minLength: null,
                      maxLength: null,
                      pattern: null,
                      min: null,
                      max: null,
                      anyOf: null,
                    },
                  ],
                  order: 2,
                  label: "1",
                  rules: [
                  ],
                },
              ],
              headings: [
                {
                  heading: "Policy Heading",
                  order: 1,
                },
              ],
            },
          ],
          questions: [
            {
              key: "d2d12e79-b3b2-44b3-8dd9-6841b3dc9450",
              text: "What is Policy? ",
              inputs: [
                {
                  schemaPropertyNameKey: "Disabled",
                  key: "6a456102-8d05-48c7-82d9-29d35a6ef22f",
                  label: "Platinum",
                  dataType: null,
                  controlType: "",
                  options: [
                  ],
                  disabled: true,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
                {
                  schemaPropertyNameKey: "Disabled",
                  key: "3b7798fd-6d72-4c4b-ac95-7bf566b86d19",
                  label: "New",
                  dataType: null,
                  controlType: "",
                  options: [
                  ],
                  disabled: true,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
                {
                  schemaPropertyNameKey: "Disabled",
                  key: "51558160-7d53-4178-9819-8838928fc838",
                  label: "Silver",
                  dataType: null,
                  controlType: "",
                  options: [
                  ],
                  disabled: true,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
              ],
              order: 2,
              label: "1",
              rules: [
              ],
            },
          ],
          headings: [
            {
              heading: "Policy Heading",
              order: 1,
            },
          ],
        },
        {
          key: "088baec9-4961-4c23-8802-5e5c06e2fe93",
          name: "COPY FORM",
          sectionNumber: 0,
          sections: [
          ],
          questions: [
            {
              key: "e52d2c34-3cd2-4400-bf67-b54094f8aec3",
              text: "New Question?",
              inputs: [
                {
                  schemaPropertyNameKey: "Disabled",
                  key: "a9858d6b-60f8-44b6-848d-4469493d1503",
                  label: "Gold",
                  dataType: null,
                  controlType: "",
                  options: [
                  ],
                  disabled: true,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
              ],
              order: 2,
              label: "1",
              rules: [
              ],
            },
            {
              key: "b3a3f988-c679-40b3-9172-ffdebe2b984d",
              text: "Is this French?",
              inputs: [
                {
                  schemaPropertyNameKey: "Disabled",
                  key: "9fd51859-f451-4bda-afe8-164fae65ff51",
                  label: "Gold",
                  dataType: null,
                  controlType: "",
                  options: [
                  ],
                  disabled: true,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
              ],
              order: 3,
              label: "2",
              rules: [
              ],
            },
            {
              key: "e6dc3a92-a1e1-4449-9c46-679b7d88028d",
              text: "Is this French Fries?",
              inputs: [
                {
                  schemaPropertyNameKey: "Disabled",
                  key: "2922c98a-4958-4641-9693-888ab7c7ebdd",
                  label: "Gold",
                  dataType: null,
                  controlType: "",
                  options: [
                  ],
                  disabled: true,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
              ],
              order: 4,
              label: "3",
              rules: [
              ],
            },
            {
              key: "e102e3e7-48d0-413a-9bc2-dea0e92a7a44",
              text: "Is this Mexico?",
              inputs: [
                {
                  schemaPropertyNameKey: "Disabled",
                  key: "69e924d2-f54c-4fb6-aee7-87db325caff4",
                  label: "Gold",
                  dataType: null,
                  controlType: "",
                  options: [
                  ],
                  disabled: true,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
              ],
              order: 5,
              label: "4",
              rules: [
              ],
            },
            {
              key: "1570d6fb-9b45-41c0-b169-2883d5690778",
              text: "Is this Enchilada?",
              inputs: [
                {
                  schemaPropertyNameKey: "Disabled",
                  key: "df76be24-6489-47c8-9c31-93b8b5ffb18c",
                  label: "Gold",
                  dataType: null,
                  controlType: "",
                  options: [
                  ],
                  disabled: true,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
              ],
              order: 6,
              label: "5",
              rules: [
              ],
            },
            {
              key: "dcc2a7fd-ec4d-4583-8e09-c56b97fd4791",
              text: "Is this Spain?",
              inputs: [
                {
                  schemaPropertyNameKey: "Disabled",
                  key: "f6d377f1-657e-4606-83b3-13b15626d96d",
                  label: "Gold",
                  dataType: null,
                  controlType: "",
                  options: [
                  ],
                  disabled: true,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
              ],
              order: 7,
              label: "6",
              rules: [
              ],
            },
          ],
          headings: [
            {
              heading: "NEW ",
              order: 1,
            },
            {
              heading: "Second Header",
              order: 8,
            },
          ],
        },
        {
          key: "1979b914-6708-4246-b675-6a14949ad476",
          name: "Years",
          sectionNumber: 0,
          sections: [
          ],
          questions: [
            {
              key: "9637e7cf-7f70-4ae9-9da5-05d9017d4506",
              text: "What year is it",
              inputs: [
                {
                  schemaPropertyNameKey: "string_pattern",
                  key: "f01ba160-e024-4282-8246-79cc9c02de7c",
                  label: "2023",
                  dataType: "string",
                  controlType: "dropdown",
                  options: [
                    {
                      key: "yes",
                      value: "yes",
                    },
                    {
                      key: " no",
                      value: " no",
                    },
                  ],
                  disabled: false,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: "^[a-zA-Z0-9]{1,10}$",
                  min: null,
                  max: null,
                  anyOf: null,
                },
                {
                  schemaPropertyNameKey: "Disabled",
                  key: "d2ae1ccf-b4f1-4714-bc43-d7b62f6155b3",
                  label: "2022",
                  dataType: null,
                  controlType: "",
                  options: [
                  ],
                  disabled: true,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
                {
                  schemaPropertyNameKey: "string_required",
                  key: "39b723cc-4f95-434e-8af5-84cd49c8ccde",
                  label: "2021",
                  dataType: "string",
                  controlType: "textbox",
                  options: [
                  ],
                  disabled: false,
                  value: null,
                  required: true,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
              ],
              order: 2,
              label: "1",
              rules: [
              ],
            },
            {
              key: "ed6b0a4e-e207-4b9c-a62c-079d9c018ac1",
              text: "another question",
              inputs: [
                {
                  schemaPropertyNameKey: "Disabled",
                  key: "21f3e2ed-b175-4592-aac1-5107e5d3883f",
                  label: "2023",
                  dataType: null,
                  controlType: "",
                  options: [
                  ],
                  disabled: true,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
                {
                  schemaPropertyNameKey: "integer_min_max_value",
                  key: "dcdb10f9-37cc-4df4-ae7e-dc1bae36ac49",
                  label: "2022",
                  dataType: "integer",
                  controlType: "dropdown",
                  options: [
                    {
                      key: "yes",
                      value: "yes",
                    },
                    {
                      key: " no",
                      value: " no",
                    },
                  ],
                  disabled: false,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: "fghgfh",
                  min: "10",
                  max: "20",
                  anyOf: [
                    "vbnvbnv",
                  ],
                },
                {
                  schemaPropertyNameKey: "Disabled",
                  key: "6214dd61-b2a8-4193-b9f3-2acfc30db7b6",
                  label: "2021",
                  dataType: null,
                  controlType: "",
                  options: [
                  ],
                  disabled: true,
                  value: null,
                  required: false,
                  warnings: [
                  ],
                  errors: [
                  ],
                  minLength: null,
                  maxLength: null,
                  pattern: null,
                  min: null,
                  max: null,
                  anyOf: null,
                },
              ],
              order: 3,
              label: "2",
              rules: [
              ],
            },
          ],
          headings: [
            {
              heading: null,
              order: 1,
            },
          ],
        },
      ],
    "questions": []
  }
