import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {FileSubmissionStateHistory} from '../../../model/file-submission-state-history';
import {HttpClient} from '@angular/common/http';
import {HandleError, HttpErrorHandler} from '../../../shared/http-error-handler.service';
import {catchError} from 'rxjs/operators';

@Injectable()
export class SubmissionAuditService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('AggregationMaintenanceService');
  }

  getSubmissionAudit(schemaType: string, fileSubmissionId: string): Observable<FileSubmissionStateHistory[]> {
    return this.httpClient.get<FileSubmissionStateHistory[]>(`${environment.apiUrl}${schemaType}/audit/submission/${fileSubmissionId}`);
  }

}
