import { Component, OnDestroy, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { Subscription } from 'rxjs';
import {FileAdminService} from '../service/file-admin.service';
import {UserService} from '../../service/user.service';
import {User} from '../../model/user';
import {DocumentErrorList} from './model/document-error-list';
import {DocumentObject} from './model/document-object';
import * as _ from 'lodash';
import {MessageService} from '../../messages/service/message.service';

@Component({
  selector: 'app-error-type',
  templateUrl: './error-type.component.html'
})
export class ErrorTypeComponent implements OnInit, OnDestroy {
  title: string;
  fileSubmissionId: string;
  errorType: string;
  originalFileName: string;
  validationCategory: string;
  backToErrors: any;
  documentErrorList: DocumentErrorList;
  user: User;
  skip = 0;
  limit = 5;
  currentPage = 1;
  loading = false;
  subscriptions: Array<Subscription> = [];
  maxSizeOfPageLinksInPager = 10;

  constructor(
    private route: ActivatedRoute,
    private fileAdminService: FileAdminService,
    private userService: UserService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {
    this.fileSubmissionId = this.route.snapshot.paramMap.get('fileSubmissionId');
    this.errorType = this.route.snapshot.paramMap.get('errorType');
    this.originalFileName = this.route.snapshot.paramMap.get('originalFileName');
    this.validationCategory = this.route.snapshot.paramMap.get('validationCategory');
    this.backToErrors = {
      documentId: this.fileSubmissionId,
      originalFileName: this.originalFileName,
      validationCategory: this.validationCategory
    };
    this.user = this.userService.getStoredUser();
    this.title = `${this.originalFileName} Data with Exceptions`;
    this.getDocumentErrorList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.messageService.clear();
  }

  getDocumentErrorList() {
    this.loading = true;
    const sub = this.fileAdminService.getErrorTypeList(this.user.currentSchema, this.fileSubmissionId, this.validationCategory, this.errorType, this.skip, this.limit)
      .subscribe(
        documentErrorList => {
          this.loading = false;
          this.documentErrorList = documentErrorList;
        }
      );
    this.subscriptions.push(sub);
  }

  page(page: any) {
    this.skip = (page.page - 1) * this.limit;
    this.getDocumentErrorList();
  }

  getDocumentKeys(document: DocumentObject): string[] {
    return _.keys(document);
  }

  getDocumentValues(document: DocumentObject): string[] {
    return _.values(document);
  }

}


