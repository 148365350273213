<!--
  ~ Copyright 2017 National Association of Insurance Commissioners
  -->
<ngx-loading [show]="loading"></ngx-loading>
<div id="file-dashboard" class="wrapper">
  <div class="row">
    <div class="col-12 page-title">
      <h1>{{title}}</h1>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <app-messages></app-messages>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <form name="fileUploadForm">
        <div class="form-group row" *ngIf="!isFileUploading">
          <div class="col-md-12">
            <app-file-upload [maxFiles]="1" (isFileUploaded)="fileUploadedEventListner($event)">
            </app-file-upload>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div>
    <div *ngIf="rowData && rowData.length > 0; else noFileSubmissions">
      <div class="row mb-3">
        <div class="col-6">
          <h3>Your Uploaded Files</h3>
        </div>
        <div class="col-6">
          <button type="button" class="btn btn-secondary btn-sm pull-right" (click)="checkIfUserAssignmentsAsigned()">Refresh</button>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-12" >
          <ag-grid-angular
            style="width: 100%; height: 520px;"
            #agGrid
            id="dashboardGrid"
            class="ag-theme-balham"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="rowData"
            [context]="context"
            [frameworkComponents]="frameworkComponents"
            (gridReady)="onGridReady($event)"
            pagination="true"
            [paginationPageSize]="paginationPageSize">
          </ag-grid-angular>

        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #noFileSubmissions>
  <div *ngIf="searchComplete" class="row">
    <div class="col-12 mb-3">
      <p>No Uploaded Files</p>
    </div>
  </div>
</ng-template>
