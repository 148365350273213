import {Error} from '../../file-admin/error-type/model/error';

export const MOCK_ERROR_A: Error = {errorMessage: 'mock error message a', errorType: 'mock_error_type_a', isPublic: true};
export const MOCK_ERROR_B: Error = {errorMessage: 'mock error message b', errorType: 'mock_error_type_b', isPublic: true};

export function generateMockErrors(numberOfErrors: number): Error[] {
  let errors: Error[] = [];
  for (let i = 1; i <= numberOfErrors; i++) {
    errors.push({errorMessage: `mock error message ${i}`, errorType: 'mock_error_type', isPublic: true});
  }
  return errors;
}
