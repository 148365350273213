<ng-container *ngIf="credibleData">
  <div class="modal-header">
    <h3 class="modal-title">{{title}}</h3>
    <button type="button" class="close" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <p>Please check to mark credible:</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-messages></app-messages>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <ul>
          <li style="list-style-type: none" *ngFor="let cocodeModel of credibleData.cocodeModels; let i = index">
            <div class="form-inline">
              <input type="checkbox" (change)="selectAll(cocodeModel)" [checked]="cocodeModelsChecked[cocodeModel.naicCompanyCode]">Company Code: {{cocodeModel.naicCompanyCode}}
            </div>
            <ol>
              <li style="list-style-type: none;" *ngFor="let planCode of cocodeModel.planCodes">
                <input type="checkbox" [(ngModel)]="planCode.checked">Plan Code: {{planCode.planCode}}
              </li>
            </ol>
          </li>
        </ul>
      </div>
      <div class="col-6">
        <textarea id="textareaComments" [(ngModel)]="credibleData.note" placeholder="Note"></textarea>
      </div>
    </div>

  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-link btn-sm" (click)="modalRef.hide()">Close</button>
    <button type="button" class="btn btn-primary" (click)="save()">Save</button>
  </div>
</ng-container>
