import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {HandleError, HttpErrorHandler} from '../../shared/http-error-handler.service';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('ExportService');
  }

  getSchemaForExport(schemaType: string, queryParam): Observable<string> {
    let url = `${environment.apiUrl}${schemaType}/importexports`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let params = new HttpParams();
    params = params.append('exportOptions', queryParam.join(','));
    const options = { headers: headers, params: params };

    return this.httpClient.get<string>(url, options)
      .pipe(catchError(this.handleError(`getSchemaForExport`, null)));
  }

  postSchemaForExportData(schemaType: string): Observable<any> {
    let url = `${environment.apiUrl}${schemaType}/reporting/export`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.httpClient.post<any>(url, '', options)
      .pipe(catchError(this.handleError('postSchemaForExportData', null)));
  }

  getResponseForExportData(schemaType: string): Observable<boolean> {
    let url = `${environment.apiUrl}${schemaType}/reporting/export`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.httpClient.get<boolean>(url, options)
      .pipe(catchError(this.handleError(`getResponseForExportData`, null)));
  }

}
