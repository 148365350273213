import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from '../../../shared/http-error-handler.service';
import { environment } from '../../../../environments/environment';
import { FormSection } from '../../model/form-section';
import { QuestionBase } from '../../model/question-base';


@Injectable({
  providedIn: 'root'
})
export class DependenciesService {
  readonly handleError: HandleError;
  private disabledInputs: string[] = [];
  isSelectQuestionsVisible = new BehaviorSubject<boolean>(true);
  selectedQuestion: string = '';
  selectedQuestionKey: string = '';
  isQuestionSelected: boolean = false;
  selectedSectionKey: string = '';
  isCheckBoxDisabled: boolean = true;
  selectedSingleValue: any;
  selectedSingleKey: any;
  inputCount: any;
  clonedValues: any;
  clonedArray: any;
  selectedExistingRuleKeys: any[] = [];
  selectedSectionKeys: any[] = [];
  isUserOnDependencyPage: boolean = false;
  selectedExistingRuleKeysSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  selectedExistingRuleKeys$ = this.selectedExistingRuleKeysSubject.asObservable();
  isDependencyQuestionClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('DependenciesService');
   }

  setIsDependencyQuestionClicked(value: boolean) {
    this.isDependencyQuestionClicked.next(value);
}

  // Create the form control
  toFormGroup(inputs: QuestionBase<any>[]): FormGroup {
    let group: any = {};

    inputs.forEach(input => {
      if (input.disableableInputs) {
        if (input.value === input.disableableInputs.disabledValue) {
          input.disableableInputs.disabledInputs.forEach((disabledInput: string) => {
            const keys = disabledInput.split('.');
            this.disabledInputs.push(keys[keys.length - 1]);
          });
        }
      }
      let formControl: FormControl;
      formControl = new FormControl(input.value || '');
      group[input.schemaPropertyNameKey] = formControl;
    });
    return new FormGroup(group);
  }

  generateForm(section: FormSection): FormGroup {
    let group: any = {};
    if (section.sections.length > 0 && section.questions.length === 0) {
      section.sections.forEach(subSection => {
        group[section.key] = this.generateForm(subSection);
      });
    }
    if (section.questions.length > 0) {
      group[section.key] = this.generateQuestions(section.questions);
    }

    if (section.sections.length === 0 && section.questions.length === 0) {
      group[section.key] = new FormControl({});
    }

    return new FormGroup(group);
  }

  generateForms(section: FormSection, disabled: boolean, summary: boolean): FormGroup {
    let group: any = {};
    if (section.sections.length > 0 && section.questions.length === 0) {
      section.sections.forEach(subSection => {
        group[section.key] = this.generateForms(subSection, disabled, summary);
      });
    }
    if (section.questions.length > 0) {
      group[section.key] = this.generateQuestionss(section.questions, disabled, summary);
    }

    if (section.sections.length === 0 && section.questions.length === 0) {
      group[section.key] = new FormControl({});
    }

    return new FormGroup(group);
  }

  generateQuestionss(questions: any[], disabled: boolean, summary: boolean): FormGroup {
    let group: any = {};

    questions.forEach(question => {
      group[question.label] = this.toFormGroups(question.inputs, disabled, summary);
    });

    return new FormGroup(group);
  }

  toFormGroups(inputs: QuestionBase<any>[], disabled: boolean, summary: boolean): FormGroup {
    let group: any = {};
    inputs.forEach(input => {
      let formControl: FormControl;
      formControl = new FormControl(input.value || '')
      group[input.schemaPropertyNameKey] = formControl;
    });
    return new FormGroup(group);
  }

  generateQuestions(questions: any[]): FormGroup {
    let group: any = {};

    questions.forEach(question => {
      group[question.label] = this.toFormGroup(question.inputs);
    });

    return new FormGroup(group);
  }

  addDependentHeaders(formData: Array<any>): Array<any> {
    formData = formData.map(item => {
      return {
        ...item,
        type: item.key === "" ? "heading" : "question",
      };
    });
    let headingOrders: number[] = [];
  
    // Iterate through the array to add dependentHeaders field
    formData.forEach((item, index) => {
      if (item.type === "heading") {
        // If heading, update headingOrders array
        headingOrders.push(item.order);
      } else if (item.type === "question") {
        // If question, add dependentHeaders field with previous heading orders
        item.dependentHeaders = [...headingOrders];
        // Clear headingOrders array only if the next item is not a question
        if (index + 1 < formData.length && formData[index + 1].type !== "question") {
          headingOrders = [];
        }
      }
    });
  
    return formData;
  }

  removeNonDependentOrders(items: any[]) {
    let allHeadings: any[] = [];
    items.forEach(ele => {
      if(ele.type === 'question') {
        allHeadings.push(ele.dependentHeaders);
      }
    });
    return items.filter((item) => {
      if (item.type === "heading") {
          return allHeadings.flat().includes(item.order);
      }
      return true;
  });
  }

  resetValues() {
    this.isCheckBoxDisabled = true;
    this.selectedExistingRuleKeys = [];
  }

  deleteRules(schema: string, questionKey: string): Observable<any> {
    type bodyType = 'body';
    let url = `${environment.apiUrl}${schema}/filingblanklayout/dependencies/delete`;
    let ruleInput = this.clonedValues;
    const ruleInputJson = JSON.stringify(ruleInput);
    const httpParams = new HttpParams().set('questionKey', questionKey);
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: httpParams,
      observe: <bodyType>'response'
    };

    return this.httpClient.post<any>(url, ruleInputJson, httpOptions)
    .pipe(catchError(this.handleError('deleteRules', null)));
  }
}
