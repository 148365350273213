import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { isNullOrUndefined } from "util";
import { AlertMessage } from "../messages/model/alert-message";
import { MessageService } from "../messages/service/message.service";
import { MOCK_FILE_LEVEL_VALIDATION } from "../mock/mock-file-level-validation";
import { User } from "../model/user";
import { UserService } from "../service/user.service";
import { FileLevelValidation } from "./model/file-level-validation";
import { FileLevelValidationEnum, FileLevelValidationNameEnum } from "./model/file-level-validation.enum";
import { FileLevelValidationService } from "./services/file-level-validation.service";

@Component({
  selector: "app-file-level-validation",
  templateUrl: "./file-level-validation.component.html",
  styleUrls: ["./file-level-validation.component.css"],
})
export class FileLevelValidationComponent implements OnInit, OnDestroy {
  title: string = '';
  user: User;
  fileLevelValidation: FileLevelValidation;
  fileLevelValidationForm: FormGroup;
  loading: boolean = false;
  fileLevelValidations: FileLevelValidation[];
  isRdcAdmin: boolean = false;

  constructor(
    private messageService: MessageService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private fileLevelValidationService: FileLevelValidationService
  ) {
    this.fileLevelValidationForm = this.defaultFileLevelValidationFormValues();
   }

  ngOnInit() {
    this.messageService.clear();
    this.user = this.userService.getStoredUser();
    this.isRdcAdmin = this.userService.getStoredUser().isRdcAdmin;
    this.title = `${this.user.currentSchema.toUpperCase()} File Level Validation`;
    this.getFileLevelValidation();
  }

  ngOnDestroy() {
    this.messageService.clear();
  }

  get fileLevelValidationFormControls() {
    return this.fileLevelValidationForm.controls;
  }

  defaultFileLevelValidationFormValues(): FormGroup {
    return this.formBuilder.group({
      value: new FormControl({value: null, disabled: true}, Validators.required),
      active: new FormControl({value: false, disabled: true}),
    });
  }

  disableText(isChecked) {
    this.isRdcAdmin ? this.fileLevelValidationFormControls['active'].disable() : this.fileLevelValidationFormControls['active'].enable();
      if(!isChecked) {
        this.fileLevelValidationFormControls['value'].disable();
      } else {
        this.isRdcAdmin ? this.fileLevelValidationFormControls['value'].disable() : this.fileLevelValidationFormControls['value'].enable();
      }
  }

  setInitialFileLevelValidationForm() {
    if (isNullOrUndefined(this.fileLevelValidation)) {
      this.fileLevelValidationForm = this.defaultFileLevelValidationFormValues();
    } else {
      this.fileLevelValidationForm.patchValue({
        value: (this.fileLevelValidation.value === '') ? null : this.fileLevelValidation.value,
        active: this.fileLevelValidation.active
      });
      this.disableText(this.fileLevelValidation.active);
    }
  }

  getFileLevelValidation() {
    this.loading = true;
    this.fileLevelValidationService
      .getFileLevelValidationForSchema(this.user.currentSchema).subscribe((res) => {
        if (!isNullOrUndefined(res)) {
          if (res.length !== 0) {
            this.fileLevelValidations = res;
            this.setNumberOfRecords();
          } else {
            this.setNames(MOCK_FILE_LEVEL_VALIDATION[0]);
          }
        }
        this.setInitialFileLevelValidationForm();
        this.loading = false;
      });
  }
  
  setNumberOfRecords() {
    this.fileLevelValidations = this.fileLevelValidations.filter(validation => {
      return validation.name === FileLevelValidationEnum.numberOfRecordsInFile;
    });
    let fileLevelValidation: FileLevelValidation;
    this.fileLevelValidations.forEach(x => {
      fileLevelValidation = x;
    });
    this.setNames(fileLevelValidation);
  }

  setNames(fileLevelValidation: FileLevelValidation) {
    if(fileLevelValidation.name === FileLevelValidationEnum.numberOfRecordsInFile) {
      fileLevelValidation.name = FileLevelValidationNameEnum.RECORDS_IN_FILE;
    }
    this.fileLevelValidation = fileLevelValidation;
  }

  saveFileLevelValidation() {
    this.loading = true;
    let updateFileLevelValidations: FileLevelValidation[] = this.setFileLevelValidation();
    this.fileLevelValidationService.insertFileLevelValidation(updateFileLevelValidations, this.user.currentSchema).subscribe(res => {
      this.fileLevelValidations = res;
      this.messageService.clear();
      this.messageService.add(new AlertMessage(`File Level Validation has been updated successfully`, 'success'));
      this.setNumberOfRecords();
      this.setInitialFileLevelValidationForm();
      this.loading = false;
    }, err => {
      this.messageService.clear();
      this.messageService.add(new AlertMessage(`File Level Validation has failed to update`, 'error'));
      this.loading = false;
    });
  }

  setFileLevelValidation(): FileLevelValidation[] {
    let updatedFileLevelValidation: FileLevelValidation = new FileLevelValidation();
    let updatedFileLevelValidations: FileLevelValidation[] = [];
    updatedFileLevelValidation.active = this.fileLevelValidationFormControls.active.value;
    /* Change this if there are more settings */
    updatedFileLevelValidation.name = FileLevelValidationEnum.numberOfRecordsInFile;
    updatedFileLevelValidation.value = String(this.fileLevelValidationFormControls.value.value);
    updatedFileLevelValidations.push(updatedFileLevelValidation);
    return updatedFileLevelValidations;
  }

  reset() {
    this.setInitialFileLevelValidationForm();
  }
}
