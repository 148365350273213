import {IterationRange} from './iteration-range';

export class XlsIteration {
  worksheetRange: IterationRange;
  rowRange: IterationRange;

  constructor() {
    this.worksheetRange = new IterationRange();
    this.rowRange = new IterationRange();
  }
}
