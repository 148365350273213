import { CommonModule } from "@angular/common";
import { NgModule, ErrorHandler } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material";
import { RouterModule } from "@angular/router";
import { LoadingModule } from "ngx-loading";
import { MessagesModule } from "../../messages/messages.module";
import { GlobalErrorHandler } from "../../shared/global-error-handler";
import { SharedModule } from "../../shared/shared.module";
import { StringToModelParserService } from "../expression-builder/shared/parserModelObj";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MessagesModule,
    SharedModule,
    MatExpansionModule,
    LoadingModule
  ],
  providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler },
    StringToModelParserService],
  declarations: [],
  exports: [],
  entryComponents: [] 
})
export class TestExpressionBuilderModule { }
