import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-common-confirmation-modal',
  templateUrl: './common-confirmation-modal.component.html'
})
export class CommonConfirmationModalComponent {

  message: string;
  title: string;
  @Output() isYesButtonClicked = new EventEmitter<boolean>();

  onClickYes() {
    this.isYesButtonClicked.emit(true);
  }

  close() {
    this.isYesButtonClicked.emit(false);
  }

}
