import { Component, TemplateRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-reload-renderer',
  templateUrl: './reload-renderer.component.html'
})
export class ReloadRendererComponent implements ICellRendererAngularComp {
  params: any;
  confirmReloadRef: BsModalRef;

  constructor(
    public modalService: BsModalService,
  ) { }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  reload(template: TemplateRef<any>): void {
    this.params.context.componentParent.fileSubmissionForReload = this.params.value;
    this.confirmReloadRef = this.modalService.show(template, {class: 'modal-lg'});
  }

  cancelReload(): void {
    this.confirmReloadRef.hide();
  }

  reloadConfirm(): void {
    this.confirmReloadRef.hide();
    this.params.context.componentParent.confirmReload();
  }

}
