import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CrossFieldValidation } from '../model/cross-field-validation';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HandleError, HttpErrorHandler} from '../../shared/http-error-handler.service';
import {catchError} from 'rxjs/operators';

@Injectable()
export class CrossFieldValidationMaintenanceService {
  private selectedCrossFieldValidation: CrossFieldValidation;
  readonly handleError: HandleError;
  storedSchemaType: string;
  storedNewCrossfieldValidation: boolean;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('CrossFieldValidationMaintenanceService');
  }


  getSelectedCrossFieldValidation(): CrossFieldValidation {
    return this.selectedCrossFieldValidation;
  }

  setSelectedCrossFieldValidation(crossFieldValidation: CrossFieldValidation): void {
    this.selectedCrossFieldValidation = crossFieldValidation;
  }

  getCrossFieldValidationsForSchema(schemaType: string): Observable<CrossFieldValidation[]> {
    const url = `${environment.apiUrl}${schemaType}/crossfieldvalidations/current`;
    return this.httpClient.get<CrossFieldValidation[]>(url)
      .pipe(catchError(this.handleError('getCrossFieldValidationsForSchema', [])));
  }

  insertCrossFieldValidation(crossFieldValidation: CrossFieldValidation, schemaType: string): Observable<any> {
    const url = `${environment.apiUrl}${schemaType}/crossfieldvalidations`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.httpClient.post<any>(url, crossFieldValidation, options)
      .pipe(catchError(this.handleError('insertCrossFieldValidation', null)));

  }

  updateCrossFieldValidation(crossFieldValidation: CrossFieldValidation, schemaType: string): Observable<any> {
    const url = `${environment.apiUrl}${schemaType}/crossfieldvalidations/${crossFieldValidation.id}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers};

    return this.httpClient.put<any>(url, crossFieldValidation, options)
      .pipe(catchError(this.handleError('updateCrossFieldValidation', null )));
  }

  getCrossFieldValidationHistory(crossFieldValidation: CrossFieldValidation, schemaType: string): Observable<CrossFieldValidation[]> {
    const url = `${environment.apiUrl}${schemaType}/crossfieldvalidations/${crossFieldValidation.name}/history`;
    return this.httpClient.get<CrossFieldValidation[]>(url)
      .pipe(catchError(this.handleError('getCrossFieldValidationHistory', [])));
  }
}
