<div class="ml-1 mr-1" [formGroup]="expression">
  <div style="padding-bottom: 15px;">

    <fieldset [ngClass]="getClassNames()">
      <legend> <select id="andOrCondition" formControlName="andOrCondition">
          <option value="" disabled>Choose One</option>
          <option *ngFor="let condition of andOrConditions" id="{{condition.label}}" value="{{condition.value}}"
            [disabled]=disableIfRdcAdmin>{{condition.label}}</option>
        </select> of these conditions are met
        <div class="float-right">
          <button *ngIf="disableConditionSet" id="deleteConditionSet" type="button" class="btn btn-danger customButton" style="position: absolute; right: 50px;"
           (click)="deleteConditionSet(deleteConditionSetModal)">X</button>
        </div></legend>

      <div class="ml-2" formArrayName="expressions">
        <div class="row col-12" style="padding-bottom: 5px;"
          *ngFor="let expression of expression['controls'].expressions['controls']; let j=index;" [formGroupName]="j">
          <textarea *ngIf="expression.get('expression')" id="expressionTextArea" placeholder="Add Condition"
            class="horizontal-resize" type="text" formControlName="expression" cols="80" rows="3" [readonly]=disableIfRdcAdmin></textarea>

          <div *ngIf="expression.get('expression')" class="dropdown ml-2 pb-1">
            <div class="row" style="padding-left: 20px;">
              <div>
            <button class="btn customButton btn-primary dropdown-toggle" type="button" id="dropdownMenu1-{{j+1}}"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" [disabled]=disableIfRdcAdmin>
              Add Element
            </button>

            <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu">
              <li><a class="dropdown-item" id="fieldSelection-{{j+1}}" (click)="addFieldSelection(j)">Field
                  Selection</a></li>
              <li class="dropdown-divider"></li>
              <li class="dropdown-submenu">
                <a id="operator-{{j+1}}" class="dropdown-item" tabindex="-1">Operator</a>
                <ul class="dropdown-menu">
                  <li *ngFor="let operator of operators" >
                    <a class="dropdown-item" id="{{operator.value}}" tabindex="-1"
                    (click)="addOperator(operator.label ,j)">{{operator.value}}</a> 
                  </li>
                </ul>
              </li>
              <li class="dropdown-divider"></li>
              <li id="constantValue={{j+1}}"><a class="dropdown-item" id="constantValue"
                  (click)="addConstantValue(j)">Constant
                  Value</a></li>
                  <li class="dropdown-divider"></li>
                  <li id="functions={{j+1}}"><a class="dropdown-item" id="functions"
                    (click)="addFunctionFromModal(j)">Functions</a></li>
            </ul>
            </div>
          
          </div>
          </div>
          <div *ngIf="expression.get('expression')" style="padding-left: 30px;">
            <button *ngIf="disableCondition" id="deleteCondition-{{j+1}}" type="button" class="btn btn-danger customButton" (click)="deleteCondition(j, deleteConditionModal)">X</button>
          </div>
          <div class="col pt-3">
            <app-expression-set [expression]="expression" [expressionBuilder]="form" [even]="j" [num]="1" *ngIf="expression.get('expressions')">
            </app-expression-set>
          </div>
        </div>
        <div class="row col-12 pt-3" style="padding-left: 20px; padding-bottom: 20px;">
          <button id="addCondition" class="btn btn-primary customButton" type="button" (click)="addCondition()"
            value="Add Condition" [disabled]=disableIfRdcAdmin>
            Add
            Condition</button> &nbsp;&nbsp;
          <button id="addConditionSet" class="btn btn-primary customButton" type="button"
            (click)="addConditionSet()" value="Add Condition" [disabled]=disableIfRdcAdmin>
            Add Condition
            Set</button>
        </div>
      </div>
    </fieldset>

  </div>

</div>

<ng-template #deleteConditionModal>
  <div class="modal-header">
    <h3 class="modal-title">Delete Condition</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{errorMessageText}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link btn-sm" (click)="bsModalRef.hide()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="deleteC()">{{deleteConditionButtonText}}</button>
  </div>
</ng-template>

<ng-template #deleteConditionSetModal>
  <div class="modal-header">
    <h3 class="modal-title">Delete Condition Set</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Do you want to proceed with the deletion of the condition set.
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link btn-sm" (click)="bsModalRef.hide()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="deleteCS()">Delete Condition Set</button>
  </div>
</ng-template>