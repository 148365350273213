import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DisclaimerComponent} from './disclaimer.component';

const disclaimerRoutes: Routes = [
  { path: 'disclaimer/:schema', component: DisclaimerComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(disclaimerRoutes)
  ],
  exports: [RouterModule]
})
export class DisclaimerRoutingModule { }
