import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FilingValidationService} from './service/filing-validation.service';
import {UserService} from '../service/user.service';
import {User} from '../model/user';
import {CrossFieldJsValidation} from './model/cross-field-js-validation';
import {Subscription} from 'rxjs';
import {DataTableHelperService} from '../shared/data-table-helper.service';
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {Router} from '@angular/router';
import {BreadcrumbService} from '../service/breadcrumb.service';
import {Breadcrumb} from '../model/breadcrumb';
import {MessageService} from '../messages/service/message.service';

@Component({
  selector: 'app-filing-validation',
  templateUrl: './filing-validation.component.html',
  encapsulation: ViewEncapsulation.None
})
export class FilingValidationComponent implements OnInit, OnDestroy {
  user: User;
  error: boolean;
  messageText: string;
  data: CrossFieldJsValidation[];
  loading: boolean = false;
  title: string;
  scrollBarHorizontal = (window.innerWidth < 1200);
  subscriptions: Subscription[];
  rows = [];
  columns = [];
  allColumns = [];
  loadingIndicator: boolean = true;
  @ViewChild(DatatableComponent, {static:true}) table: DatatableComponent;
  alertTimeout: number = 5000;
  breadcrumb: Breadcrumb;

  constructor(
    private service: FilingValidationService,
    private userService: UserService,
    private dataTableHelper: DataTableHelperService,
    private router: Router,
    public breadcrumbService: BreadcrumbService,
    private messageService: MessageService,
  ) {
    this.error = false;
    this.data = [];
    this.subscriptions = [];
    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 12000);
    }
  }

  ngOnInit(): void {
    this.breadcrumbService.clearBreadcrumbs();
    this.user = this.userService.getStoredUser();
    this.title = `${this.user.currentSchema.toUpperCase()} Filing Validations`;
    this.breadcrumb = new Breadcrumb(this.title, '/filingValidation');
    this.getCrossFieldJsValidations();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.messageService.clear();
  }

  getCrossFieldJsValidations(): void {
    this.loading = true;
    const subscription = this.service.getCrossFieldJsValidations(this.user.currentSchema).subscribe(
      response => {
        this.loading = false;
        this.error = false;
        this.data = response;
        this.setData();
      }
    );
    this.subscriptions.push(subscription);
  }

  setData(): void {
    this.columns = this.dataTableHelper.getColumns(this.data);
    this.rows = this.dataTableHelper.getRows(this.data);
    this.columns = [...this.columns];
    this.rows = [...this.rows];
  }

  updateFilter(event) {
    const value = event.target.value.toLowerCase();
    let temp = [];

    let nameData = this.data.filter(function(d) {
      return d.name.toLowerCase().indexOf(value) !== -1 || !value;
    });
    temp.push(...nameData);

    let errorMessageData = this.data.filter(function(d) {
      return d.errorMessage.toLowerCase().indexOf(value) !== -1 || !value;
    });
    temp.push(...errorMessageData);

    let descriptionData = this.data.filter(function(d) {
      return d.description.toLowerCase().indexOf(value) !== -1 || !value;
    });
    temp.push(...descriptionData);

    let expressionData = this.data.filter(function(d) {
      return d.expression.toLowerCase().indexOf(value) !== -1 || !value;
    });
    temp.push(...expressionData);

    let schemaReferenceData = this.data.filter(function(d) {
      return d.schemaReference.toLowerCase().indexOf(value) !== -1 || !value;
    });
    temp.push(...schemaReferenceData);

    let validationLevelData = this.data.filter(function(d) {
      return d.validationLevel.toLowerCase().indexOf(value) !== -1 || !value;
    });
    temp.push(...validationLevelData);

    this.rows = temp;
    this.table.offset = 0;
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  editValidation(validation: CrossFieldJsValidation): void {
    this.service.setStoredValidation(validation);
    this.breadcrumbService.addBreadcrumb(this.breadcrumb);
    this.router.navigate(['/jsValidationEdit']);
  }

  addValidation(): void {
    this.service.setStoredValidation(null);
    this.breadcrumbService.addBreadcrumb(this.breadcrumb);
    this.router.navigate(['/jsValidationEdit']);
  }

}
