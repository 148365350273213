import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AggregateComponent } from './aggregate/aggregate.component';
import { AggregationMaintenanceComponent } from './aggregation-maintenance.component';

const aggregationMaintenanceRoutes: Routes = [
  { path: 'aggregation', component: AggregationMaintenanceComponent },
  { path: 'aggregate', component: AggregateComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(aggregationMaintenanceRoutes)
  ],
  exports: [RouterModule]
})
export class AggregationMaintenanceRoutingModule { }
