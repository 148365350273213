import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, HostListener, OnInit, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { Message } from '../../model/message';
import { UserService } from '../../service/user.service';
import { User } from '../../model/user';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FileValidationMaintenanceService } from '../services/file-validation-maintenance.service';
import { ValidationSchemaUploadResult } from '../model/datafield-file-upload';

@Component({
  selector: 'app-datafield-file-upload',
  templateUrl: './datafield-file-upload.component.html',
  styleUrls: ['./datafield-file-upload.component.css']
})
export class DatafieldFileUploadComponent implements OnInit {
  @Output() event: EventEmitter<any> = new EventEmitter();
  messages: Array<Message> = [];
  dragAreaClass = 'file-upload-drag-area';
  fileExt = ['CSV'];
  maxFiles = 1;
  maxSize = 16; // 16MB
  public events: EventEmitter<any> = new EventEmitter();
  user: User;
  files: any[];
  error = false;
  messageText: string;
  subscriptions: Array<Subscription> = [];
  uploadingFileName: string;
  uploadPercentage: number;
  uploadingFileSize: any;
  fileWithoutFormData: File;
  alerType: string;
  alertType: string;
  fileUpload: ValidationSchemaUploadResult;
  errorResponse: boolean = false;
  isFileUploading: boolean;

  constructor(
    private userService: UserService,
    public bsModalRef: BsModalRef,
    private schemaMaintenanceService: FileValidationMaintenanceService
  ) {

  }

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    this.files = [];
  }

  onFileChange(event) {
    const files = event.target.files;
    this.fileWithoutFormData = files[0];
    this.error = false;
    this.error = this.isValidFiles(files);
    if (this.error === false) {
      this.files.push(_.values(files));
      _.values(files).forEach((file) => {
        this.error = false;
        this.errorResponse = false;
        this.messageText = `${file.name} is ready for upload`;
        this.uploadingFileName = file.name;
      });
    }
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event) {
    this.dragAreaClass = 'file-upload-drop-area';
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event'])
  onDragEnter(event) {
    this.dragAreaClass = 'file-upload-drop-area';
    event.preventDefault();
  }

  @HostListener('dragend', ['$event'])
  onDragEnd(event) {
    this.dragAreaClass = 'file-upload-drag-area';
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event) {
    this.dragAreaClass = 'file-upload-drag-area';
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  onDrop(event) {
    this.dragAreaClass = 'file-upload-drag-area';
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    this.fileWithoutFormData = files[0];
    this.error = false;
    this.error = this.isValidFiles(files);
    if (this.error === false) {
      this.files.push(_.values(files));
      _.values(files).forEach((file) => {
        this.error = false;
        this.errorResponse = false;
        this.messageText = `${file.name} is ready for upload`;
        this.uploadingFileName = file.name;
      });
    }
  }


  isValidFiles(files): boolean {
    this.errorResponse = false;
    const fileSizeInMB = files[0].size / (1024 * 1000);
    const size = Math.round(fileSizeInMB * 100) / 100;
    const fileType: string = files[0].name.split('.').pop();
    if (((files.length + this.files.length) > this.maxFiles) || (files.length > this.maxFiles)) {
      this.messageText = `File upload limit is ${this.maxFiles} file.`;
      this.alertType = 'danger';
      return true;
    } else if (!this.fileExt.includes(fileType.toUpperCase())) {
      this.messageText = `Only ${this.fileExt.join(',')} files will be accepted. (${files[0].name})`;
      this.alertType = 'danger';
      return true;
    } else if (size > this.maxSize) {
      this.messageText = `${files[0].name} exceeds file size limit of ${this.maxSize}MB (${size}MB)`;
      this.alertType = 'danger';
      return true;
    }
    this.alertType = 'success';
    return this.error;
  }

  uploadFile() {
    if (this.files.length > 0) {
      const formData: FormData = new FormData();
      const fileNames: Array<string> = [];
      this.files.forEach((file, index) => {
        fileNames.push(this.files[index].name);
        const fileBlob = new Blob(this.files[index]);
        formData.append('file', fileBlob, this.files[index][0].name);
      });
      const sub =this.schemaMaintenanceService.uploadFile(this.user.currentSchema, formData)
        .subscribe(
          (event: HttpEvent<any>) => {
            this.uploadingFileName = fileNames[0];
            switch (event.type) {
              case HttpEventType.UploadProgress:
                this.isFileUploading = true;
                this.uploadPercentage = Math.round((100 * event.loaded / event.total));
                this.uploadingFileName = fileNames.length > 1 ? fileNames.join(', ') : fileNames[0];
                this.uploadingFileSize = event.total;
                break;
              case HttpEventType.Response:
                this.isFileUploading = false;
                let fileUpload: ValidationSchemaUploadResult = new ValidationSchemaUploadResult();
                fileUpload.uploaded = event.body.uploaded;
                fileUpload.errors = event.body.errors;
                this.schemaMaintenanceService.setFileUpload(fileUpload);
                this.fileUpload = fileUpload;
                if(this.fileUpload.uploaded) {
                  this.bsModalRef.hide();
                  this.events.emit({data: this.fileUpload, res: 200});
                } else {
                  this.errorResponse = true;
                  this.messageText = `File has been rejected, see errors below.`;
                  this.alertType = 'danger';
                }
                break;
              default:
                this.isFileUploading = false;
                this.cancel();
                break;
            }
          }
        );
      this.subscriptions.push(sub);
    }
  }

  finishUpload() {
    if(this.fileUpload.uploaded) {
      this.bsModalRef.hide();
      this.events.emit({data: this.fileUpload, res: 200});
    } else {
      this.errorResponse = true;
    }
  }

  cancel() {
    this.files = [];
    this.messages = [];
    this.messageText = null;
    this.error = false;
    this.dragAreaClass = 'file-upload-drag-area';
  }

  readyForUpload(): boolean {
    return this.files.length > 0;
  }

  removeMessage(index: number) {
    this.messages.splice(index, 1);
  }

}
