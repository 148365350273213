import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesComponent } from './messages.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { MessageService } from './service/message.service';

@NgModule({
  imports: [
    CommonModule,
    AlertModule.forRoot()
  ],
  declarations: [MessagesComponent],
  providers: [MessageService],
  exports: [MessagesComponent]
})
export class MessagesModule { }
