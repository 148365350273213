import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HandleError, HttpErrorHandler } from '../../shared/http-error-handler.service';
import { environment } from '../../../environments/environment';
import { FormSection } from '../model/form-section';
import { TodoItemFlatNode } from '../model/ItemFlatNode';
import { FilingBlank } from '../model/filingblank';
import { catchError } from 'rxjs/operators';
import { FormQuestion } from '../model/form-question';
import { InputsForQuestion } from '../model/input';
import { FormGroup } from '@angular/forms';
import { FormHeaders } from '../model/form-headers';

@Injectable({
  providedIn: "root",
})
export class FormBuilderService {
  showDependencyPage = new BehaviorSubject<boolean>(false);
  sectionHeadings: FormHeaders[];
  formSection: FormQuestion[];
  readonly handleError: HandleError;
  dataChange = new BehaviorSubject<FormSection[]>([]);
  loading = new BehaviorSubject<boolean>(false);
  sideNavTitle = new BehaviorSubject<string>("");
  clickedName: string;
  filingBlank: FilingBlank;
  originalFilingBlank: FilingBlank;
  changedStatus: boolean = false;
  showErrorMessages = new BehaviorSubject<boolean>(false);
  isDisableAddEditButton = new BehaviorSubject<boolean>(false);
  selectedFormSection: FormSection;
  fieldName: any;
  isFormUpdated: boolean = false;
  selectedDataCallFields: any;
  usedDataCallFields: any[] = [];
  newSectionValues: {} = {
    newSectionKey: "",
    component: "",
    formSecion: new FormSection(),
  };
  isTitleVisible: boolean = true;
  isFilingBlankEmpty = new BehaviorSubject<boolean>(true);
  disableDependencyPage = new BehaviorSubject<boolean>(false);
  initialSectionName = new BehaviorSubject<string>('');
  inputObjs: any[] = [];

  generatedUUIDs: any[] = [];
  isDependencyPage: boolean;
  selectedExistingRuleKeys: any[] = [];

  initiateFilingBlank = new BehaviorSubject<boolean>(false);

  get data(): FormSection[] {
    return this.dataChange.value;
  }

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError(
      "OnlineDataEntryService"
    );
    this.initialize();
  }

  setSelectedDataCallFieldsList(list: any) {
    this.selectedDataCallFields = list;
  }

  getSelectedDataCallFieldsList() {
    return this.selectedDataCallFields;
  }

  setFieldName(fieldName: any): any {
    this.fieldName = fieldName;
  }

  getFieldName(): any {
    return this.fieldName;
  }

  setSelectedSection(formSection: FormSection) {
    this.selectedFormSection = formSection;
  }

  getSelectedFormSection(): FormSection {
    return this.selectedFormSection;
  }

  setClickedName(name: string) {
    this.clickedName = name;
  }

  getClickedName(): string {
    return this.clickedName;
  }

  setFilingBlank(filingBlank: FilingBlank) {
    this.filingBlank = filingBlank;
    this.dataChange.next(this.filingBlank.sections);
    this.usedDataCallFields = [];
    this.setSelectedDataCallFieldsList(
      this.recursiveGetKeys(this.filingBlank.sections)
    );
  }

  getFilingBlank(): FilingBlank {
    return this.filingBlank;
  }

  setChangeStatus(changedStatus: boolean) {
    this.changedStatus = changedStatus;
  }

  getChangeStatus(): boolean {
    return this.changedStatus;
  }

  setOriginalFilingBlank(filingBlank: FilingBlank) {
    this.originalFilingBlank = filingBlank;
  }

  getOriginalFilingBlank(): FilingBlank {
    return this.originalFilingBlank;
  }

  getFilingBlankLayout(schema: string): Observable<any> {
    type bodyType = "body";
    const url = `${environment.apiUrl}${schema}/filingblanklayout`;
    const headers = new HttpHeaders({ Accept: "application/json" });
    const options = { headers: headers, observe: <bodyType>"response" };
    return this.httpClient.get(url, options);
  }

  getFilingBlankLayoutAndGenerateBlank(schema: string): Observable<any> {
    type bodyType = "body";
    const url = `${environment.apiUrl}${schema}/filingblanklayout/generatedBlank`;
    const headers = new HttpHeaders({ Accept: "application/json" });
    const options = { headers: headers, observe: <bodyType>"response" };
    return this.httpClient.get(url, options);
  }

  postFilingBlankLayout(
    schema: string,
    filingBlank: FilingBlank
  ): Observable<any> {
    const url = `${environment.apiUrl}${schema}/filingblanklayout `;
    const headers = new HttpHeaders({ Accept: "application/json" });
    const options = { headers: headers };
    return this.httpClient
      .post(url, filingBlank, options)
      .pipe(catchError(this.handleError("postFilingBlankLayout", null)));
  }

  putFilingBlankLayout(
    schema: string,
    filingBlank: FilingBlank
  ): Observable<any> {
    const url = `${environment.apiUrl}${schema}/filingblanklayout `;
    const headers = new HttpHeaders({ Accept: "application/json" });
    const options = { headers: headers };
    return this.httpClient
      .put(url, filingBlank, options)
      .pipe(catchError(this.handleError("putFilingBlankLayout", null)));
  }

  initialize() {
    let data = [];

    this.dataChange.next(data);
  }

  /** Add an name to to-do list */
  insertItem(parent: FormSection, name: string) {
    if (!parent.sections) parent.sections = [];
    parent.sections.push({
      name: name,
      key: null,
      description: "",
      sectionNumber: null,
      order: null,
      active: null,
      questions: [],
      headings: [],
      sections: [],
    } as FormSection);
    this.dataChange.next(this.data);
  }

  insertParentLevelItem() {
    this.data.push({
      name: name,
      key: null,
      displayKey: name,
      description: "",
      sectionNumber: null,
      order: null,
      active: null,
      questions: [],
      headings: [],
      sections: [],
    } as unknown as FormSection);
    this.dataChange.next(this.data);
    this.checkIfDataHasOnlyOneSection(this.data);
  }

  updateItem( nestedNode: TodoItemFlatNode, node: FormSection, name: string, treeControl: FormSection[]) {
    let key: string = "";
    treeControl.forEach((x) => {
      key = key === "" ? x.name : key + "." + x.name;
    });
    if (!nestedNode.isEditable) {
      node.name = name;
      node.displayKey = key === "" ? name : key + name;
      node.description = "";
      node.sectionNumber = null;
      node.active = null;
      node.questions = [];
      node.headings = [];
      node.sections = [];
    } else {
      node.name = name;
      node.displayKey = "";
      key = "";
      treeControl.forEach((x) => {
        key = key === "" ? x.name : key + "." + x.name;
      });
      node.displayKey = key === "" ? name : key;
    }
    this.dataChange.next(this.data);
    this.checkIfDataHasOnlyOneSection(this.data);
  }

  delete(nestedNode: FormSection) {
    let data = this.recursiveRemove(this.data, nestedNode.key);
    this.dataChange.next(data);
    this.checkIfDataHasOnlyOneSection(data);
  }

  checkIfDataHasOnlyOneSection(data) {
    (data.length === 1) ? this.initialSectionName.next(data[0].name) : this.initialSectionName.next('');
  }

  recursiveRemove(list: FormSection[], key: string) {
    return list
      .map((item) => {
        return { ...item };
      })
      .filter((item) => {
        if ("sections" in item) {
          item.sections = this.recursiveRemove(item.sections, key);
        }
        return item.key !== key;
      });
  }

  getSubMenuItem(subMenuItems: FormSection[], name) {
    if (subMenuItems) {
      for (let i = 0; i < subMenuItems.length; i++) {
        if (subMenuItems[i].name == name) {
          return subMenuItems[i];
        }
        let found = this.getSubMenuItem(subMenuItems[i].sections, name);
        if (found) return found;
      }
    }
  }

  setFormData(formSection: FormSection): any[] {
    let questions: any[] = [];
    let inputs: any[] = [];
    let obj;
    let inputObj;
    let objw;
    let i: any[];
    formSection.headings.forEach((x) => {
      obj = {};
      obj["key"] = '';
      obj["text"] = '';
      obj["label"] = '';
      obj["order"] = x.order;
      obj["heading"] = x.heading;
      questions.push(obj);
    });
    formSection.questions.forEach((x) => {
      let obj = {};
      this.inputObjs = [];
      obj["key"] = x.key;
      obj["label"] = x.label;      
      obj["text"] = x.text;      
      obj["order"] = x.order;      
      obj["heading"] = '';  
  
      x.inputs.forEach((y) => {
          obj[y.key] = y.schemaPropertyNameKey;
          this.inputObjs.push({key: y.key, label: y.label});
      });
      questions.push(obj);
  });

    const referenceFields = Array.from(
      new Set(questions.flatMap((item) => Object.keys(item)))
    );

    // Add missing fields and create new objects
    const updatedData = questions.map((item) => {
      const missingFields = referenceFields.filter(
        (field) => !item.hasOwnProperty(field)
      );
      const updatedItem = { ...item };

      missingFields.forEach((field) => {
        updatedItem[field] = ''; // Add missing fields with null values
      });

      return updatedItem;
    });

    return updatedData.slice().sort((a, b) => a.order - b.order);
  }  

  setFormModel(addForm: FormGroup, columnArray): FilingBlank {
    const orderedColumns = columnArray
    .filter(column => column.value !== "label")
    .filter(column => column.value !== "text")
    .filter(column => column.value !== "heading")
    .filter(column => column.value !== "order") 
    .map(column => column.value);
    let filingBlank = this.getFilingBlank();
    let formQuestion: FormQuestion[] = [];
    let formHeader: FormHeaders[] = [];
  
    addForm.value.rows.forEach((x) => {
      if (x.text !== '' && x.heading === '') {
        let formInputs = this.createFormInputs(x, orderedColumns, columnArray);
        let question = this.createFormQuestion(x, formInputs);
        formQuestion.push(question);
      } 
      if ((x.text === "" || x.text === null) && (x.label === "" || x.label === null)) {
        let headings = this.createFormHeader(x);
        formHeader.push(headings);
      }
    });
    this.generatedUUIDs = [];
  
    let newFilingBlank = this.updateFormSection(
      filingBlank.sections,
      formQuestion,
      formHeader
    );
  
    return newFilingBlank;
  }
  
  createFormInputs(x: any, orderedColumns, columnArray): InputsForQuestion[] {
    let keys = Object.entries(x);
    keys = keys
      .filter(([key, value]) => key !== "label")
      .filter(([key, value]) => key !== "text")
      .filter(([key, value]) => key !== "heading")
      .filter(([key, value]) => key !== "order");    

      keys.sort((a, b) => {
        return orderedColumns.indexOf(a[0]) - orderedColumns.indexOf(b[0]);
      });
    let formInputs: InputsForQuestion[] = keys.map(([key, value], index) => {
      let obj: InputsForQuestion = {
        key: key,
        label: columnArray.find(item => item.value === key).label,
        datatype: "",
        controlType: "",
        options: [],
        disabled: value === "Disabled",
        required: false,
        schemaPropertyNameKey: String(value)
      };
      return obj;
    });

    return formInputs;
  }
  
  createFormQuestion(x: any, formInputs: InputsForQuestion[]): FormQuestion {
    let question: FormQuestion = {
      key: null,
      text: x.text,
      order: x.order,
      inputs: formInputs,
      label: x.label,
      rules: []
    };
  
    return question;
  }
  
  createFormHeader(x: any): FormHeaders {
    let headings: FormHeaders = {
      order: x.order,
      heading: x.heading,
    };
  
    return headings;
  }

  updateFormSection(
    sections: FormSection[],
    formQuestion: FormQuestion[],
    formHeader: FormHeaders[]
  ): FilingBlank {
    sections.forEach((x) => {
      if ((x.key && x.key === this.selectedFormSection.key) || (!x.key && x.displayKey && x.displayKey === this.selectedFormSection.displayKey)) {
        x.questions = this.updateValuesWithKeys(x.questions, formQuestion);
        x.headings = formHeader;
        delete x.displayKey;
      } else {
        if (x.sections.length !== 0) {
          this.updateFormSection(x.sections, formQuestion, formHeader);
        }
      }
    });
    let filingBlank = new FilingBlank();
    filingBlank.questions = [];
    filingBlank.sections = sections;
    return filingBlank;
  }

  updateValuesWithKeys(originalArray, newArray): any {
    const findMatchingObject = (text: string) => {
      return originalArray.find(obj => obj.text === text);
    }
    newArray.forEach((newObj, newIndex) => {
      const matchingObj = findMatchingObject(newObj.text);
      if (matchingObj) {

        newArray[newIndex].key = matchingObj.key;


        newArray[newIndex].rules = matchingObj.rules;
      }
    });

    return newArray;
  }

  recursiveGetKeys(list: FormSection[]) {
    list.forEach((item) => {
      item.questions.forEach((element) => {
        element.inputs.forEach((element) => {
          this.usedDataCallFields.push(element.schemaPropertyNameKey);
        });
      });
      if (item.sections.length > 0) {
        this.recursiveGetKeys(item.sections);
      }
    });

    return this.usedDataCallFields;
  }

  /** Check the Duplicate value  */
  isItemAlreadyInSection(itemValue: string, sections: FormSection[]): boolean {
    return (
      sections.filter(
        (ele) => ele.name.toLowerCase() === itemValue.toLowerCase()
      ).length > 0
    );
  }

  setCopiedSection(formSection: FormQuestion[]) {
    this.formSection = formSection;
  }

  getCopiedSection(): FormQuestion[] {
    return this.formSection;
  }

  setNewSectionKey(key: string, component: string, formSection: FormSection[]) {
    let newObj = {};
    newObj["newSectionKey"] = key;
    newObj["component"] = component;
    newObj["formSection"] = formSection;
    this.newSectionValues = newObj;
  }

  setCopiedSectionHeaders(headings: FormHeaders[]) {
    this.sectionHeadings = headings
  }

  getCopiedSectionHeaders(): FormHeaders[] {
    return this.sectionHeadings;
  }

  getNewSectionKey(): any {
    return this.newSectionValues;
  }
}

