import { FileSubmissionError } from '../file-dashboard/file-submission-errors/model/file-submission-error';

export const MOCK_DUPLICATE_RECORD = new FileSubmissionError('This is a duplicate record', ['20', '25', '29']);
export const MOCK_YEAR_MUST_EQUAL_ZERO = new FileSubmissionError('The observation year - duration - issue year must equal zero', ['10', '60', '78']);
export const MOCK_DURATION_ONE = new FileSubmissionError('If issue year = observation year then duration = 1', ['43', '61', '94', '142']);
export const MOCK_DURATION_TWO = new FileSubmissionError('If issue year = observation year then duration = 1', ['43', '61', '94', '142']);
export const MOCK_DURATION_THREE = new FileSubmissionError('If issue year = observation year then duration = 1', ['43', '61', '94', '142']);
export const MOCK_DURATION_FOUR = new FileSubmissionError('If issue year = observation year then duration = 1', ['43', '61', '94', '142']);
export const MOCK_DURATION_FIVE = new FileSubmissionError('If issue year = observation year then duration = 1', ['43', '61', '94', '142']);
export const MOCK_DURATION_SIX = new FileSubmissionError('If issue year = observation year then duration = 1', ['43', '61', '94', '142']);
export const MOCK_DURATION_SEVEN = new FileSubmissionError('If issue year = observation year then duration = 1', ['43', '61', '94', '142']);
export const MOCK_DURATION_EIGHT = new FileSubmissionError('If issue year = observation year then duration = 1', ['43', '61', '94', '142']);

