import {DocumentErrorList} from '../../file-dashboard/file-submission-error-type/model/document-error-list';
import {generateMockDocumentsWithErrors} from './mock-document-with-error';

export const MOCK_DOCUMENT_ERROR_LIST_50 = new DocumentErrorList(generateMockDocumentsWithErrors(50, 20, 1), 50);
export const MOCK_DOCUMENT_ERROR_LIST_100 = new DocumentErrorList(generateMockDocumentsWithErrors(2, 20, 50), 50);

export const MOCK_DOCUMENT_ERROR_LIST_USER = {
  "totalErrors": 9,
  "documentsWithErrors": [
    {
      "errors": [
        {
          "errorType": "substandard_indicator",
          "errorMessage": "The value, null, is not one of these values: [ 0,1,2 ].",
          "isPublic": true
        }
      ],
      "document": {
        "naic_company_code": "99999",
        "observation_year": 2015,
        "policy_number": "442",
        "segment_number": 1,
        "issue_state": "UT",
        "Gender": "1",
        "birth_date": "19590306",
        "age_basis": "1",
        "issue_age": 43,
        "issue_date": "20021010",
        "smoker_status": "1",
        "preferred_indicator": "1",
        "number_classes_nonsmoker_pfd_cl_str": "5",
        "nonsmoker_preferred_class": "4",
        "number_classes_smoker_pfd_cl_str": null,
        "smoker_preferred_class": null,
        "type_underwriting_requirements": "3",
        "substandard_indicator": null,
        "plan_code": 24,
        "inforce_indicator": "1",
        "face_amount_at_issue": 250000,
        "face_amount_beginning_Obs_Year": 250000,
        "face_amount_end_Obs_Year": 250000,
        "death_claim_amount": null,
        "termination_reported_date": null,
        "actual_termination_date": null,
        "termination_cause": null,
        "level_term_premium_at_issue": null,
        "level_term_premium_at_beg_obs_year": 456,
        "level_term_premium_at_end_obs_year": 456,
        "premium_mode": "5",
        "lineNo": 2
      }
    },
    {
      "errors": [
        {
          "errorType": "substandard_indicator",
          "errorMessage": "The value, null, is not one of these values: [ 0,1,2 ].",
          "isPublic": true
        }
      ],
      "document": {
        "naic_company_code": "99998",
        "observation_year": 2015,
        "policy_number": "442",
        "segment_number": 1,
        "issue_state": "IN",
        "Gender": "1",
        "birth_date": "19581230",
        "age_basis": "1",
        "issue_age": 43,
        "issue_date": "20021011",
        "smoker_status": "1",
        "preferred_indicator": "1",
        "number_classes_nonsmoker_pfd_cl_str": "5",
        "nonsmoker_preferred_class": "1",
        "number_classes_smoker_pfd_cl_str": null,
        "smoker_preferred_class": null,
        "type_underwriting_requirements": "3",
        "substandard_indicator": null,
        "plan_code": 24,
        "inforce_indicator": "1",
        "face_amount_at_issue": 250000,
        "face_amount_beginning_Obs_Year": 250000,
        "face_amount_end_Obs_Year": 250000,
        "death_claim_amount": null,
        "termination_reported_date": null,
        "actual_termination_date": null,
        "termination_cause": null,
        "level_term_premium_at_issue": null,
        "level_term_premium_at_beg_obs_year": 346,
        "level_term_premium_at_end_obs_year": 346,
        "premium_mode": "5",
        "lineNo": 4
      }
    },
    {
      "errors": [
        {
          "errorType": "substandard_indicator",
          "errorMessage": "The value, null, is not one of these values: [ 0,1,2 ].",
          "isPublic": true
        }
      ],
      "document": {
        "naic_company_code": "99998",
        "observation_year": 2015,
        "policy_number": "442",
        "segment_number": 1,
        "issue_state": "CA",
        "Gender": "2",
        "birth_date": "19650913",
        "age_basis": "1",
        "issue_age": 37,
        "issue_date": "20021228",
        "smoker_status": "1",
        "preferred_indicator": "1",
        "number_classes_nonsmoker_pfd_cl_str": "5",
        "nonsmoker_preferred_class": "1",
        "number_classes_smoker_pfd_cl_str": null,
        "smoker_preferred_class": null,
        "type_underwriting_requirements": "3",
        "substandard_indicator": null,
        "plan_code": 26,
        "inforce_indicator": "1",
        "face_amount_at_issue": 500000,
        "face_amount_beginning_Obs_Year": 500000,
        "face_amount_end_Obs_Year": 500000,
        "death_claim_amount": null,
        "termination_reported_date": null,
        "actual_termination_date": null,
        "termination_cause": null,
        "level_term_premium_at_issue": null,
        "level_term_premium_at_beg_obs_year": 520,
        "level_term_premium_at_end_obs_year": 520,
        "premium_mode": "2",
        "lineNo": 3
      }
    },
    {
      "errors": [
        {
          "errorType": "substandard_indicator",
          "errorMessage": "The value, null, is not one of these values: [ 0,1,2 ].",
          "isPublic": true
        }
      ],
      "document": {
        "naic_company_code": "99998",
        "observation_year": 2015,
        "policy_number": "44415830",
        "segment_number": 1,
        "issue_state": "KY",
        "Gender": "1",
        "birth_date": "19560316",
        "age_basis": "1",
        "issue_age": 46,
        "issue_date": "20021201",
        "smoker_status": "1",
        "preferred_indicator": "1",
        "number_classes_nonsmoker_pfd_cl_str": "5",
        "nonsmoker_preferred_class": "5",
        "number_classes_smoker_pfd_cl_str": null,
        "smoker_preferred_class": null,
        "type_underwriting_requirements": "3",
        "substandard_indicator": null,
        "plan_code": 24,
        "inforce_indicator": "1",
        "face_amount_at_issue": 100000,
        "face_amount_beginning_Obs_Year": 100000,
        "face_amount_end_Obs_Year": 100000,
        "death_claim_amount": null,
        "termination_reported_date": null,
        "actual_termination_date": null,
        "termination_cause": null,
        "level_term_premium_at_issue": null,
        "level_term_premium_at_beg_obs_year": 344,
        "level_term_premium_at_end_obs_year": 344,
        "premium_mode": "5",
        "lineNo": 10
      }
    },
    {
      "errors": [
        {
          "errorType": "substandard_indicator",
          "errorMessage": "The value, null, is not one of these values: [ 0,1,2 ].",
          "isPublic": true
        }
      ],
      "document": {
        "naic_company_code": "99998",
        "observation_year": 2015,
        "policy_number": "44793715",
        "segment_number": 1,
        "issue_state": "DC",
        "Gender": "2",
        "birth_date": "19550208",
        "age_basis": "1",
        "issue_age": 47,
        "issue_date": "20021102",
        "smoker_status": "1",
        "preferred_indicator": "1",
        "number_classes_nonsmoker_pfd_cl_str": "5",
        "nonsmoker_preferred_class": "4",
        "number_classes_smoker_pfd_cl_str": null,
        "smoker_preferred_class": null,
        "type_underwriting_requirements": "3",
        "substandard_indicator": null,
        "plan_code": 26,
        "inforce_indicator": "1",
        "face_amount_at_issue": 175000,
        "face_amount_beginning_Obs_Year": 175000,
        "face_amount_end_Obs_Year": 175000,
        "death_claim_amount": null,
        "termination_reported_date": null,
        "actual_termination_date": null,
        "termination_cause": null,
        "level_term_premium_at_issue": null,
        "level_term_premium_at_beg_obs_year": 568,
        "level_term_premium_at_end_obs_year": 568,
        "premium_mode": "3",
        "lineNo": 7
      }
    },
    {
      "errors": [
        {
          "errorType": "substandard_indicator",
          "errorMessage": "The value, null, is not one of these values: [ 0,1,2 ].",
          "isPublic": true
        }
      ],
      "document": {
        "naic_company_code": "99998",
        "observation_year": 2015,
        "policy_number": "44257563",
        "segment_number": 1,
        "issue_state": "OH",
        "Gender": "2",
        "birth_date": "19660921",
        "age_basis": "1",
        "issue_age": 35,
        "issue_date": "20020920",
        "smoker_status": "1",
        "preferred_indicator": "1",
        "number_classes_nonsmoker_pfd_cl_str": "5",
        "nonsmoker_preferred_class": "2",
        "number_classes_smoker_pfd_cl_str": null,
        "smoker_preferred_class": null,
        "type_underwriting_requirements": "3",
        "substandard_indicator": null,
        "plan_code": 24,
        "inforce_indicator": "1",
        "face_amount_at_issue": 250000,
        "face_amount_beginning_Obs_Year": 250000,
        "face_amount_end_Obs_Year": 250000,
        "death_claim_amount": null,
        "termination_reported_date": null,
        "actual_termination_date": null,
        "termination_cause": null,
        "level_term_premium_at_issue": null,
        "level_term_premium_at_beg_obs_year": 190,
        "level_term_premium_at_end_obs_year": 190,
        "premium_mode": "1",
        "lineNo": 6
      }
    },
    {
      "errors": [
        {
          "errorType": "substandard_indicator",
          "errorMessage": "The value, null, is not one of these values: [ 0,1,2 ].",
          "isPublic": true
        }
      ],
      "document": {
        "naic_company_code": "99998",
        "observation_year": 2015,
        "policy_number": "43541704",
        "segment_number": 1,
        "issue_state": "MN",
        "Gender": "1",
        "birth_date": "19700812",
        "age_basis": "1",
        "issue_age": 32,
        "issue_date": "20021203",
        "smoker_status": "1",
        "preferred_indicator": "1",
        "number_classes_nonsmoker_pfd_cl_str": "5",
        "nonsmoker_preferred_class": "1",
        "number_classes_smoker_pfd_cl_str": null,
        "smoker_preferred_class": null,
        "type_underwriting_requirements": "3",
        "substandard_indicator": null,
        "plan_code": 24,
        "inforce_indicator": "1",
        "face_amount_at_issue": 500000,
        "face_amount_beginning_Obs_Year": 500000,
        "face_amount_end_Obs_Year": 500000,
        "death_claim_amount": null,
        "termination_reported_date": null,
        "actual_termination_date": null,
        "termination_cause": null,
        "level_term_premium_at_issue": null,
        "level_term_premium_at_beg_obs_year": 331,
        "level_term_premium_at_end_obs_year": 331,
        "premium_mode": "5",
        "lineNo": 5
      }
    },
    {
      "errors": [
        {
          "errorType": "substandard_indicator",
          "errorMessage": "The value, null, is not one of these values: [ 0,1,2 ].",
          "isPublic": true
        }
      ],
      "document": {
        "naic_company_code": "99998",
        "observation_year": 2015,
        "policy_number": "44415830",
        "segment_number": 1,
        "issue_state": "KY",
        "Gender": "1",
        "birth_date": "19560316",
        "age_basis": "1",
        "issue_age": 46,
        "issue_date": "20021201",
        "smoker_status": "1",
        "preferred_indicator": "1",
        "number_classes_nonsmoker_pfd_cl_str": "5",
        "nonsmoker_preferred_class": "5",
        "number_classes_smoker_pfd_cl_str": null,
        "smoker_preferred_class": null,
        "type_underwriting_requirements": "3",
        "substandard_indicator": null,
        "plan_code": 24,
        "inforce_indicator": "1",
        "face_amount_at_issue": 100000,
        "face_amount_beginning_Obs_Year": 100000,
        "face_amount_end_Obs_Year": 100000,
        "death_claim_amount": null,
        "termination_reported_date": null,
        "actual_termination_date": null,
        "termination_cause": null,
        "level_term_premium_at_issue": null,
        "level_term_premium_at_beg_obs_year": 344,
        "level_term_premium_at_end_obs_year": 344,
        "premium_mode": "5",
        "lineNo": 8
      }
    },
    {
      "errors": [
        {
          "errorType": "substandard_indicator",
          "errorMessage": "The value, null, is not one of these values: [ 0,1,2 ].",
          "isPublic": true
        }
      ],
      "document": {
        "naic_company_code": "99998",
        "observation_year": 2015,
        "policy_number": "44415830",
        "segment_number": 1,
        "issue_state": "KY",
        "Gender": "1",
        "birth_date": "19560316",
        "age_basis": "1",
        "issue_age": 46,
        "issue_date": "20021201",
        "smoker_status": "1",
        "preferred_indicator": "1",
        "number_classes_nonsmoker_pfd_cl_str": "5",
        "nonsmoker_preferred_class": "5",
        "number_classes_smoker_pfd_cl_str": null,
        "smoker_preferred_class": null,
        "type_underwriting_requirements": "3",
        "substandard_indicator": null,
        "plan_code": 24,
        "inforce_indicator": "1",
        "face_amount_at_issue": 100000,
        "face_amount_beginning_Obs_Year": 100000,
        "face_amount_end_Obs_Year": 100000,
        "death_claim_amount": null,
        "termination_reported_date": null,
        "actual_termination_date": null,
        "termination_cause": null,
        "level_term_premium_at_issue": null,
        "level_term_premium_at_beg_obs_year": 344,
        "level_term_premium_at_end_obs_year": 344,
        "premium_mode": "5",
        "lineNo": 9
      }
    }
  ]
}
