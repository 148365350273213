import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FileLevelValidationComponent } from "./file-level-validation.component";


const fileLevelValidationRoutes: Routes = [
  { path: 'fileLevelValidation', component: FileLevelValidationComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(fileLevelValidationRoutes)
  ],
  exports: [RouterModule]
})
export class FileLevelValidationRoutingModule { }
