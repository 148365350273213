<div id="filing-schema" class="wrapper">

  <div class="row page-title">
    <div class="col-12">
      <h1>{{title}}</h1>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <app-messages></app-messages>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <json-editor #jsonEditor
                   [options]="editorOptions"
                   [data]="data">
      </json-editor>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <button type="button" class="btn btn-primary" (click)="postFilingSchema()">Save</button>
    </div>
  </div>
</div>


