import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'noUnderscore'
})
export class NoUnderscorePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    const text = value.split('_');
    const transformedText: string[] = [];
    text.forEach(t => {
      if (t.toLowerCase() === 'naic') {
        transformedText.push(t.toUpperCase());
      } else {
        transformedText.push(_.capitalize(t));
      }
    });
    return transformedText.join(' ');
  }

}
