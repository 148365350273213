import { HttpClient, HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../../model/user';
import { FileSubmission } from '../../model/file-submission';
import { DocumentErrorList } from '../file-submission-error-type/model/document-error-list';
import { ArrayToStringPipe } from '../../pipes/array-to-string.pipe';
import * as _ from 'lodash';
import { HandleError, HttpErrorHandler } from '../../shared/http-error-handler.service';
import { catchError } from 'rxjs/operators';
import { UploadURLResponse } from '../model/upload-url-response';
import { FileUpload } from '../model/file-upload';
import { ErrorTypeCounts } from '../../model/error-type-counts';
import {FileLevelExceptionTypes} from '../../model/file-level-exception-types';


@Injectable()
export class FileService {
  readonly handleError: HandleError;
  submissionKeys: any;
  fileUpload: FileUpload;
  formFormatErrorTypeCounts: ErrorTypeCounts[];
  crossFieldErrorTypeCounts: ErrorTypeCounts[];
  fileLevelExceptiontypes: FileLevelExceptionTypes;

  constructor(
    private httpClient: HttpClient,
    private arrayToString: ArrayToStringPipe,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('FileService');
  }

  static isStaticColumn(header: string): boolean {
    const staticColumns: string[] = ['fileName', 'status', 'modified', 'created', 'exceptions', 'numberOfRecords'];
    return _.indexOf(staticColumns, header) > -1;
  }

  getSubmissionKeys(): any {
    return this.submissionKeys;
  }

  setSubmissionKeys(submissionKeys): void {
    this.submissionKeys = submissionKeys;
  }

  getFileUplaod(): any {
    return this.fileUpload;
  }

  setFileUpload(fileUpload): void {
    this.fileUpload = fileUpload;
  }

  setFormFormatErrorTypeCounts(formFormatErrorTypeCounts: ErrorTypeCounts[]): void {
    this.formFormatErrorTypeCounts = formFormatErrorTypeCounts;
  }

  getFormFormatErrorTypeCounts(): ErrorTypeCounts[] {
    return this.formFormatErrorTypeCounts;
  }

  setCrossFieldErrorTypeCounts(crossFieldErrorTypeCounts: ErrorTypeCounts[]): void {
    this.crossFieldErrorTypeCounts = crossFieldErrorTypeCounts;
  }

  getCrossFieldErrorTypeCounts(): ErrorTypeCounts[] {
    return this.crossFieldErrorTypeCounts;
  }

  setFileLevelExceptionTypes(fileLevelExceptionTypes: FileLevelExceptionTypes): void {
    this.fileLevelExceptiontypes = fileLevelExceptionTypes;
  }

  getFileLevelExceptionTypes(): FileLevelExceptionTypes {
    return this.fileLevelExceptiontypes;
  }

  getPreSignedUrlfromS3(user: User, fileName: string): Observable<UploadURLResponse> {
    const url = `${environment.apiUrl}upload/url/${user.currentSchema}/${fileName}?schemaType=${user.currentSchema}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {
      headers: headers
    };
    return this.httpClient.get<UploadURLResponse>(url, options)
      .pipe(catchError(this.handleError('getPreSignedUrlfromS3', null)));
  }

  uploadtoS3(formData: File, uploadURL: string): Observable<any> {
    const req = new HttpRequest('PUT', uploadURL, formData, {
      reportProgress: true
    });
    return this.httpClient.request(req)
      .pipe(catchError(this.handleError('updatetoS3', null)));
  }

  createHandler(user: User, fileUpload: FileUpload): Observable<any> {
    const url = `${environment.apiUrl}${user.currentSchema}/filesubmissions`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.httpClient.post<any>(url, fileUpload, options)
      .pipe(catchError(this.handleError('createHandler', null)));
  }

  getFileSubmissionForUser(user: User): Observable<FileSubmission[]> {
    const url = `${environment.apiUrl}${user.currentSchema}/filesubmissions/currentuser`;
    return this.httpClient.get<FileSubmission[]>(url)
      .pipe(catchError(this.handleError('getFileSubmissionForUser', [])));
  }

  getFileSubmissions(assignmentKeys: any, schemaType): Observable<FileSubmission[]> {

    const params = new HttpParams().set('assignmentKeys', assignmentKeys.assignmentValues);
    const options = { params: params };
    return this.httpClient.get<DocumentErrorList>(`${environment.apiUrl}${schemaType}/filesubmissions`, options)
      .pipe(catchError(this.handleError('getFileSubmissions', null)));
  }

  updateFileSubmissionState(user: User, fileSubmissionId: string, fileSubmissionState: string): Observable<FileSubmission> {
    const url = `${environment.apiUrl}${user.currentSchema}/fileadmin`;
    return this.httpClient.put<FileSubmission>(url, { fileSubmissionId: fileSubmissionId, fileSubmissionState: fileSubmissionState })
      .pipe(catchError(this.handleError('updateFileSubmissionState', null)));
  }

  updateFileSubmissionNotes(user: User, fileSubmissionState: string): Observable<FileSubmission> {
    const url = `${environment.apiUrl}${user.currentSchema}/fileadmin/notes`;
    return this.httpClient.put<FileSubmission>(url, fileSubmissionState)
      .pipe(catchError(this.handleError('updateFileSubmissionNotes', null)));
  }

  getErrorTypeList(schemaType: string, fileSubmissionId: string, errorType: string, skip?: number, limit?: number): Observable<DocumentErrorList> {
    if (skip !== undefined && limit !== undefined) {
    const params = new HttpParams()
      .set('skip', String(skip))
      .set('limit', String(limit));
    const options = { params: params };
    return this.httpClient.get<DocumentErrorList>(`${environment.apiUrl}${schemaType}/filesubmissions/${fileSubmissionId}/documentErrorLists/${errorType}`, options)
      .pipe(catchError(this.handleError('getErrorTypeList', null)));
    } else {
      return this.httpClient.get<DocumentErrorList>(`${environment.apiUrl}${schemaType}/filesubmissions/${fileSubmissionId}/documentErrorLists/${errorType}`)
      .pipe(catchError(this.handleError('getErrorTypeList', null)));
    }
  }

  getFileDashboardData(files: FileSubmission[]): any[] {
    const dashboardData: any[] = [];
    files.forEach(file => {
      const data = {};
      data['fileName'] = file.originalFileName;
      file.submissionKeys.forEach(key => {
        let name = '';
        key.name.split('_').forEach((item, index) => {
          if (index > 0) {
            item = item.charAt(0).toUpperCase() + item.slice(1);
          }
          name = name + item;
        });
        if (file.insertionComplete && key.values.length <= 0) {
          data[name] = '--';
        } else if (file.insertionComplete && key.values.length > 0) {
          data[name] = this.arrayToString.transform(key.values);
        } else {
          data[name] = 'Processing...';
        }
      });
      data['status'] = file.fileSubmissionState;
      data['modified'] = file.modified;
      data['created'] = file.created;
      data['exceptions'] = file;
      data['numberOfRecords'] = file.totalDocuments;
      data['rejectionReasons'] = file.rejectionReasons;
      data['submittedBy'] = file.submittedBy;
      dashboardData.push(data);
    });
    return dashboardData;
  }

  getLinkInfo(fileSubmissions: FileSubmission[], row: any): any {
    const fileSubmission = this.findFileSubmission(fileSubmissions, row);
    return { fileSubmissionId: fileSubmission.id, originalFileName: fileSubmission.originalFileName };
  }

  findFileSubmission(fileSubmissions: FileSubmission[], row: any): FileSubmission {
    const search = {};
    if (row.fileName) {
      search['originalFileName'] = row.fileName;
    }
    if (row.created) {
      search['created'] = row.created;
    }
    if (row.numberOfRecords) {
      search['totalDocuments'] = row.numberOfRecords;
    }
    if (row.status) {
      search['fileSubmissionState'] = row.status;
    }
    if (row.exceptions.totalPublicErrorCount) {
      search['totalPublicErrorCount'] = row.exceptions.totalPublicErrorCount;
    }
    if (row.modified) {
      search['modified'] = row.modified;
    }
    const fileSubmission = _.find(fileSubmissions, search);

    return fileSubmission;
  }

  saveDataInCSV(data: Array<any>): string {
    if (data.length == 0) {
      return '';
    }

    let propertyNames = Object.keys(data[0]);
    let rowWithPropertyNames = propertyNames.join(',') + '\n';

    let csvContent = rowWithPropertyNames;

    let rows: string[] = [];

    data.forEach((item) => {
      let values: string[] = [];

      propertyNames.forEach((key) => {
        let val: any = item[key];
        if (val !== undefined && val !== null) {
          val = new String(val);
        } else {
          val = '';
        }
        // make sure to change the key if the field name for error message changes.
        if (key === 'error_message') {
          val = `"${val}"`;
          values.push(val);
        } else {
          values.push(val);
        }
      });
      rows.push(values.join(','));
    });
    csvContent += rows.join('\n');
    return csvContent;
  }


}
