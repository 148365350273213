export const MOCK_FILE_SUBMISSION_TOLERANCES = [
  {
    "_id": {
      "timestamp": 1507746138,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797774,
      "timeSecond": 1507746138,
      "date": 1507746138000,
      "time": 1507746138000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1507746138789
  },
  {
    "_id": {
      "timestamp": 1507305572,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797767,
      "timeSecond": 1507305572,
      "date": 1507305572000,
      "time": 1507305572000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1507305572498
  },
  {
    "_id": {
      "timestamp": 1507305569,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797766,
      "timeSecond": 1507305569,
      "date": 1507305569000,
      "time": 1507305569000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1507305569102
  },
  {
    "_id": {
      "timestamp": 1507305336,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797765,
      "timeSecond": 1507305336,
      "date": 1507305336000,
      "time": 1507305336000
    },
    "count": "0",
    "percentage": "0",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1507305336343
  },
  {
    "_id": {
      "timestamp": 1507304748,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797764,
      "timeSecond": 1507304748,
      "date": 1507304748000,
      "time": 1507304748000
    },
    "count": "100000",
    "percentage": "0",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1507304748081
  },
  {
    "_id": {
      "timestamp": 1507301605,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797763,
      "timeSecond": 1507301605,
      "date": 1507301605000,
      "time": 1507301605000
    },
    "count": "100000",
    "percentage": "3",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1507301605291
  },
  {
    "_id": {
      "timestamp": 1507301590,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797762,
      "timeSecond": 1507301590,
      "date": 1507301590000,
      "time": 1507301590000
    },
    "count": "0",
    "percentage": "3",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1507301590202
  },
  {
    "_id": {
      "timestamp": 1507301576,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797761,
      "timeSecond": 1507301576,
      "date": 1507301576000,
      "time": 1507301576000
    },
    "count": "0",
    "percentage": "3",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1507301576310
  },
  {
    "_id": {
      "timestamp": 1507301472,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797760,
      "timeSecond": 1507301472,
      "date": 1507301472000,
      "time": 1507301472000
    },
    "count": "1",
    "percentage": "3",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1507301472377
  },
  {
    "_id": {
      "timestamp": 1507297954,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797759,
      "timeSecond": 1507297954,
      "date": 1507297954000,
      "time": 1507297954000
    },
    "count": "100000",
    "percentage": "3",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1507297954766
  },
  {
    "_id": {
      "timestamp": 1507297926,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797758,
      "timeSecond": 1507297926,
      "date": 1507297926000,
      "time": 1507297926000
    },
    "count": "100000",
    "percentage": "3",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1507297926144
  },
  {
    "_id": {
      "timestamp": 1507297919,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797757,
      "timeSecond": 1507297919,
      "date": 1507297919000,
      "time": 1507297919000
    },
    "count": "100000",
    "percentage": "999",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1507297919190
  },
  {
    "_id": {
      "timestamp": 1507297794,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797756,
      "timeSecond": 1507297794,
      "date": 1507297794000,
      "time": 1507297794000
    },
    "count": "100000",
    "percentage": "999",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1507297794983
  },
  {
    "_id": {
      "timestamp": 1507297720,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797755,
      "timeSecond": 1507297720,
      "date": 1507297720000,
      "time": 1507297720000
    },
    "count": "100000",
    "percentage": "000",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1507297720605
  },
  {
    "_id": {
      "timestamp": 1507297711,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797754,
      "timeSecond": 1507297711,
      "date": 1507297711000,
      "time": 1507297711000
    },
    "count": "100000",
    "percentage": "0",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1507297711954
  },
  {
    "_id": {
      "timestamp": 1507297706,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797753,
      "timeSecond": 1507297706,
      "date": 1507297706000,
      "time": 1507297706000
    },
    "count": "100000",
    "percentage": "999",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1507297706164
  },
  {
    "_id": {
      "timestamp": 1507242391,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797752,
      "timeSecond": 1507242391,
      "date": 1507242391000,
      "time": 1507242391000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1507242391329
  },
  {
    "_id": {
      "timestamp": 1507242386,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797751,
      "timeSecond": 1507242386,
      "date": 1507242386000,
      "time": 1507242386000
    },
    "count": "100000",
    "percentage": "2",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1507242386976
  },
  {
    "_id": {
      "timestamp": 1507242385,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797750,
      "timeSecond": 1507242385,
      "date": 1507242385000,
      "time": 1507242385000
    },
    "count": "100000",
    "percentage": "2",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1507242385573
  },
  {
    "_id": {
      "timestamp": 1507242163,
      "machineIdentifier": 5005829,
      "processIdentifier": 26058,
      "counter": 2797749,
      "timeSecond": 1507242163,
      "date": 1507242163000,
      "time": 1507242163000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1507242163940
  },
  {
    "_id": {
      "timestamp": 1505175730,
      "machineIdentifier": 1236543,
      "processIdentifier": 5341,
      "counter": 15840888,
      "timeSecond": 1505175730,
      "date": 1505175730000,
      "time": 1505175730000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1505175730959
  },
  {
    "_id": {
      "timestamp": 1505174838,
      "machineIdentifier": 1236543,
      "processIdentifier": 5341,
      "counter": 15840887,
      "timeSecond": 1505174838,
      "date": 1505174838000,
      "time": 1505174838000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1505174838167
  },
  {
    "_id": {
      "timestamp": 1505158386,
      "machineIdentifier": 1236543,
      "processIdentifier": 5341,
      "counter": 15840886,
      "timeSecond": 1505158386,
      "date": 1505158386000,
      "time": 1505158386000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1505158386104
  },
  {
    "_id": {
      "timestamp": 1505148372,
      "machineIdentifier": 1236543,
      "processIdentifier": 5341,
      "counter": 15840885,
      "timeSecond": 1505148372,
      "date": 1505148372000,
      "time": 1505148372000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1505148372742
  },
  {
    "_id": {
      "timestamp": 1505148163,
      "machineIdentifier": 1236543,
      "processIdentifier": 5341,
      "counter": 15840884,
      "timeSecond": 1505148163,
      "date": 1505148163000,
      "time": 1505148163000
    },
    "count": "178",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1505148163467
  },
  {
    "_id": {
      "timestamp": 1505148131,
      "machineIdentifier": 1236543,
      "processIdentifier": 5341,
      "counter": 15840883,
      "timeSecond": 1505148131,
      "date": 1505148131000,
      "time": 1505148131000
    },
    "count": "100000",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1505148131518
  },
  {
    "_id": {
      "timestamp": 1505148107,
      "machineIdentifier": 1236543,
      "processIdentifier": 5341,
      "counter": 15840882,
      "timeSecond": 1505148107,
      "date": 1505148107000,
      "time": 1505148107000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1505148107254
  },
  {
    "_id": {
      "timestamp": 1505147587,
      "machineIdentifier": 1236543,
      "processIdentifier": 5341,
      "counter": 15840881,
      "timeSecond": 1505147587,
      "date": 1505147587000,
      "time": 1505147587000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1505147587814
  },
  {
    "_id": {
      "timestamp": 1504898142,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567519,
      "timeSecond": 1504898142,
      "date": 1504898142000,
      "time": 1504898142000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504898142248
  },
  {
    "_id": {
      "timestamp": 1504897985,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567518,
      "timeSecond": 1504897985,
      "date": 1504897985000,
      "time": 1504897985000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504897985425
  },
  {
    "_id": {
      "timestamp": 1504897885,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567517,
      "timeSecond": 1504897885,
      "date": 1504897885000,
      "time": 1504897885000
    },
    "count": "28",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504897885426
  },
  {
    "_id": {
      "timestamp": 1504897882,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567516,
      "timeSecond": 1504897882,
      "date": 1504897882000,
      "time": 1504897882000
    },
    "count": "78",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504897882621
  },
  {
    "_id": {
      "timestamp": 1504897856,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567515,
      "timeSecond": 1504897856,
      "date": 1504897856000,
      "time": 1504897856000
    },
    "count": "178",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504897856264
  },
  {
    "_id": {
      "timestamp": 1504897826,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567514,
      "timeSecond": 1504897826,
      "date": 1504897826000,
      "time": 1504897826000
    },
    "count": "100000",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504897826410
  },
  {
    "_id": {
      "timestamp": 1504897805,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567513,
      "timeSecond": 1504897805,
      "date": 1504897805000,
      "time": 1504897805000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504897805952
  },
  {
    "_id": {
      "timestamp": 1504897286,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567512,
      "timeSecond": 1504897286,
      "date": 1504897286000,
      "time": 1504897286000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504897286571
  },
  {
    "_id": {
      "timestamp": 1504896280,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567511,
      "timeSecond": 1504896280,
      "date": 1504896280000,
      "time": 1504896280000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504896280972
  },
  {
    "_id": {
      "timestamp": 1504886474,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567510,
      "timeSecond": 1504886474,
      "date": 1504886474000,
      "time": 1504886474000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504886474115
  },
  {
    "_id": {
      "timestamp": 1504886303,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567509,
      "timeSecond": 1504886303,
      "date": 1504886303000,
      "time": 1504886303000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504886303897
  },
  {
    "_id": {
      "timestamp": 1504886180,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567508,
      "timeSecond": 1504886180,
      "date": 1504886180000,
      "time": 1504886180000
    },
    "count": "28",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504886180800
  },
  {
    "_id": {
      "timestamp": 1504886171,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567507,
      "timeSecond": 1504886171,
      "date": 1504886171000,
      "time": 1504886171000
    },
    "count": "78",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504886171629
  },
  {
    "_id": {
      "timestamp": 1504886137,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567506,
      "timeSecond": 1504886137,
      "date": 1504886137000,
      "time": 1504886137000
    },
    "count": "178",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504886137770
  },
  {
    "_id": {
      "timestamp": 1504886094,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567505,
      "timeSecond": 1504886094,
      "date": 1504886094000,
      "time": 1504886094000
    },
    "count": "100000",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504886094498
  },
  {
    "_id": {
      "timestamp": 1504886054,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567504,
      "timeSecond": 1504886054,
      "date": 1504886054000,
      "time": 1504886054000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504886054884
  },
  {
    "_id": {
      "timestamp": 1504885608,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567503,
      "timeSecond": 1504885608,
      "date": 1504885608000,
      "time": 1504885608000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504885608363
  },
  {
    "_id": {
      "timestamp": 1504880431,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567502,
      "timeSecond": 1504880431,
      "date": 1504880431000,
      "time": 1504880431000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504880431062
  },
  {
    "_id": {
      "timestamp": 1504827707,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567501,
      "timeSecond": 1504827707,
      "date": 1504827707000,
      "time": 1504827707000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504827707278
  },
  {
    "_id": {
      "timestamp": 1504827608,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567500,
      "timeSecond": 1504827608,
      "date": 1504827608000,
      "time": 1504827608000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504827608964
  },
  {
    "_id": {
      "timestamp": 1504827511,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567499,
      "timeSecond": 1504827511,
      "date": 1504827511000,
      "time": 1504827511000
    },
    "count": "28",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504827511627
  },
  {
    "_id": {
      "timestamp": 1504827508,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567498,
      "timeSecond": 1504827508,
      "date": 1504827508000,
      "time": 1504827508000
    },
    "count": "78",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504827508941
  },
  {
    "_id": {
      "timestamp": 1504827487,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567497,
      "timeSecond": 1504827487,
      "date": 1504827487000,
      "time": 1504827487000
    },
    "count": "178",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504827487293
  },
  {
    "_id": {
      "timestamp": 1504827458,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567496,
      "timeSecond": 1504827458,
      "date": 1504827458000,
      "time": 1504827458000
    },
    "count": "100000",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504827458866
  },
  {
    "_id": {
      "timestamp": 1504827416,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567495,
      "timeSecond": 1504827416,
      "date": 1504827416000,
      "time": 1504827416000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504827416476
  },
  {
    "_id": {
      "timestamp": 1504827019,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567494,
      "timeSecond": 1504827019,
      "date": 1504827019000,
      "time": 1504827019000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504827019841
  },
  {
    "_id": {
      "timestamp": 1504826745,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567493,
      "timeSecond": 1504826745,
      "date": 1504826745000,
      "time": 1504826745000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504826745427
  },
  {
    "_id": {
      "timestamp": 1504796692,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567492,
      "timeSecond": 1504796692,
      "date": 1504796692000,
      "time": 1504796692000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504796692822
  },
  {
    "_id": {
      "timestamp": 1504796009,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567491,
      "timeSecond": 1504796009,
      "date": 1504796009000,
      "time": 1504796009000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504796009583
  },
  {
    "_id": {
      "timestamp": 1504788944,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567490,
      "timeSecond": 1504788944,
      "date": 1504788944000,
      "time": 1504788944000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504788944502
  },
  {
    "_id": {
      "timestamp": 1504788347,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567489,
      "timeSecond": 1504788347,
      "date": 1504788347000,
      "time": 1504788347000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504788347814
  },
  {
    "_id": {
      "timestamp": 1504738390,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567488,
      "timeSecond": 1504738390,
      "date": 1504738390000,
      "time": 1504738390000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504738390958
  },
  {
    "_id": {
      "timestamp": 1504738283,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567487,
      "timeSecond": 1504738283,
      "date": 1504738283000,
      "time": 1504738283000
    },
    "count": "28",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504738283797
  },
  {
    "_id": {
      "timestamp": 1504738280,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567486,
      "timeSecond": 1504738280,
      "date": 1504738280000,
      "time": 1504738280000
    },
    "count": "78",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504738280248
  },
  {
    "_id": {
      "timestamp": 1504738252,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567485,
      "timeSecond": 1504738252,
      "date": 1504738252000,
      "time": 1504738252000
    },
    "count": "178",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504738253005
  },
  {
    "_id": {
      "timestamp": 1504738227,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567484,
      "timeSecond": 1504738227,
      "date": 1504738227000,
      "time": 1504738227000
    },
    "count": "100000",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504738227731
  },
  {
    "_id": {
      "timestamp": 1504738201,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567483,
      "timeSecond": 1504738201,
      "date": 1504738201000,
      "time": 1504738201000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504738201493
  },
  {
    "_id": {
      "timestamp": 1504200698,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567464,
      "timeSecond": 1504200698,
      "date": 1504200698000,
      "time": 1504200698000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504200698460
  },
  {
    "_id": {
      "timestamp": 1504199895,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567463,
      "timeSecond": 1504199895,
      "date": 1504199895000,
      "time": 1504199895000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504199895327
  },
  {
    "_id": {
      "timestamp": 1504196362,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567462,
      "timeSecond": 1504196362,
      "date": 1504196362000,
      "time": 1504196362000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504196362261
  },
  {
    "_id": {
      "timestamp": 1504196054,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567461,
      "timeSecond": 1504196054,
      "date": 1504196054000,
      "time": 1504196054000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504196054490
  },
  {
    "_id": {
      "timestamp": 1504195228,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567460,
      "timeSecond": 1504195228,
      "date": 1504195228000,
      "time": 1504195228000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504195228358
  },
  {
    "_id": {
      "timestamp": 1504195120,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567459,
      "timeSecond": 1504195120,
      "date": 1504195120000,
      "time": 1504195120000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504195120719
  },
  {
    "_id": {
      "timestamp": 1504194782,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567458,
      "timeSecond": 1504194782,
      "date": 1504194782000,
      "time": 1504194782000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504194783022
  },
  {
    "_id": {
      "timestamp": 1504186136,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567453,
      "timeSecond": 1504186136,
      "date": 1504186136000,
      "time": 1504186136000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504186136603
  },
  {
    "_id": {
      "timestamp": 1504183700,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567452,
      "timeSecond": 1504183700,
      "date": 1504183700000,
      "time": 1504183700000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504183700422
  },
  {
    "_id": {
      "timestamp": 1504024807,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567437,
      "timeSecond": 1504024807,
      "date": 1504024807000,
      "time": 1504024807000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504024807548
  },
  {
    "_id": {
      "timestamp": 1504023337,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567436,
      "timeSecond": 1504023337,
      "date": 1504023337000,
      "time": 1504023337000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504023337275
  },
  {
    "_id": {
      "timestamp": 1504020163,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567435,
      "timeSecond": 1504020163,
      "date": 1504020163000,
      "time": 1504020163000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1504020163000
  },
  {
    "_id": {
      "timestamp": 1503946931,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567433,
      "timeSecond": 1503946931,
      "date": 1503946931000,
      "time": 1503946931000
    },
    "count": "100000000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1503946931315
  },
  {
    "_id": {
      "timestamp": 1503946920,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567432,
      "timeSecond": 1503946920,
      "date": 1503946920000,
      "time": 1503946920000
    },
    "count": "100000000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1503946920079
  },
  {
    "_id": {
      "timestamp": 1503946914,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567431,
      "timeSecond": 1503946914,
      "date": 1503946914000,
      "time": 1503946914000
    },
    "count": "10000000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1503946914261
  },
  {
    "_id": {
      "timestamp": 1503946906,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567430,
      "timeSecond": 1503946906,
      "date": 1503946906000,
      "time": 1503946906000
    },
    "count": "1000000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1503946906581
  },
  {
    "_id": {
      "timestamp": 1503691287,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567429,
      "timeSecond": 1503691287,
      "date": 1503691287000,
      "time": 1503691287000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503691267598
  },
  {
    "_id": {
      "timestamp": 1503691097,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567428,
      "timeSecond": 1503691097,
      "date": 1503691097000,
      "time": 1503691097000
    },
    "count": "28",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503691077521
  },
  {
    "_id": {
      "timestamp": 1503691082,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567427,
      "timeSecond": 1503691082,
      "date": 1503691082000,
      "time": 1503691082000
    },
    "count": "78",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503691063143
  },
  {
    "_id": {
      "timestamp": 1503691042,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567426,
      "timeSecond": 1503691042,
      "date": 1503691042000,
      "time": 1503691042000
    },
    "count": "178",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503691023136
  },
  {
    "_id": {
      "timestamp": 1503690988,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567425,
      "timeSecond": 1503690988,
      "date": 1503690988000,
      "time": 1503690988000
    },
    "count": "100000",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503690968731
  },
  {
    "_id": {
      "timestamp": 1503690935,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567424,
      "timeSecond": 1503690935,
      "date": 1503690935000,
      "time": 1503690935000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503690915231
  },
  {
    "_id": {
      "timestamp": 1503688257,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567423,
      "timeSecond": 1503688257,
      "date": 1503688257000,
      "time": 1503688257000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503688236665
  },
  {
    "_id": {
      "timestamp": 1503686349,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567422,
      "timeSecond": 1503686349,
      "date": 1503686349000,
      "time": 1503686349000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503686327337
  },
  {
    "_id": {
      "timestamp": 1503686309,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567421,
      "timeSecond": 1503686309,
      "date": 1503686309000,
      "time": 1503686309000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503686287762
  },
  {
    "_id": {
      "timestamp": 1503685173,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567420,
      "timeSecond": 1503685173,
      "date": 1503685173000,
      "time": 1503685173000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503685150889
  },
  {
    "_id": {
      "timestamp": 1503684478,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567419,
      "timeSecond": 1503684478,
      "date": 1503684478000,
      "time": 1503684478000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503684455024
  },
  {
    "_id": {
      "timestamp": 1503680630,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567418,
      "timeSecond": 1503680630,
      "date": 1503680630000,
      "time": 1503680630000
    },
    "count": "100000",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503680605659
  },
  {
    "_id": {
      "timestamp": 1503680581,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567417,
      "timeSecond": 1503680581,
      "date": 1503680581000,
      "time": 1503680581000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503680556938
  },
  {
    "_id": {
      "timestamp": 1503680471,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567416,
      "timeSecond": 1503680471,
      "date": 1503680471000,
      "time": 1503680471000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503680446370
  },
  {
    "_id": {
      "timestamp": 1503674491,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567415,
      "timeSecond": 1503674491,
      "date": 1503674491000,
      "time": 1503674491000
    },
    "count": "178",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503674463572
  },
  {
    "_id": {
      "timestamp": 1503674435,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567414,
      "timeSecond": 1503674435,
      "date": 1503674435000,
      "time": 1503674435000
    },
    "count": "100000",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503674407210
  },
  {
    "_id": {
      "timestamp": 1503674368,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567413,
      "timeSecond": 1503674368,
      "date": 1503674368000,
      "time": 1503674368000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503674340573
  },
  {
    "_id": {
      "timestamp": 1503673961,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567412,
      "timeSecond": 1503673961,
      "date": 1503673961000,
      "time": 1503673961000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503673932784
  },
  {
    "_id": {
      "timestamp": 1503673798,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567411,
      "timeSecond": 1503673798,
      "date": 1503673798000,
      "time": 1503673798000
    },
    "count": "28",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503673770386
  },
  {
    "_id": {
      "timestamp": 1503673782,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567410,
      "timeSecond": 1503673782,
      "date": 1503673782000,
      "time": 1503673782000
    },
    "count": "78",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503673754151
  },
  {
    "_id": {
      "timestamp": 1503673738,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567409,
      "timeSecond": 1503673738,
      "date": 1503673738000,
      "time": 1503673738000
    },
    "count": "178",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503673709591
  },
  {
    "_id": {
      "timestamp": 1503673682,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567408,
      "timeSecond": 1503673682,
      "date": 1503673682000,
      "time": 1503673682000
    },
    "count": "100000",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503673653937
  },
  {
    "_id": {
      "timestamp": 1503673618,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567407,
      "timeSecond": 1503673618,
      "date": 1503673618000,
      "time": 1503673618000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503673590383
  },
  {
    "_id": {
      "timestamp": 1503673371,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567406,
      "timeSecond": 1503673371,
      "date": 1503673371000,
      "time": 1503673371000
    },
    "count": "100000",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503673342995
  },
  {
    "_id": {
      "timestamp": 1503673310,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567405,
      "timeSecond": 1503673310,
      "date": 1503673310000,
      "time": 1503673310000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503673282223
  },
  {
    "_id": {
      "timestamp": 1503673088,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567404,
      "timeSecond": 1503673088,
      "date": 1503673088000,
      "time": 1503673088000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503673059236
  },
  {
    "_id": {
      "timestamp": 1503672990,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567403,
      "timeSecond": 1503672990,
      "date": 1503672990000,
      "time": 1503672990000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503672962130
  },
  {
    "_id": {
      "timestamp": 1503670812,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567402,
      "timeSecond": 1503670812,
      "date": 1503670812000,
      "time": 1503670812000
    },
    "count": "78",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503670782657
  },
  {
    "_id": {
      "timestamp": 1503670752,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567401,
      "timeSecond": 1503670752,
      "date": 1503670752000,
      "time": 1503670752000
    },
    "count": "178",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503670722437
  },
  {
    "_id": {
      "timestamp": 1503670666,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567400,
      "timeSecond": 1503670666,
      "date": 1503670666000,
      "time": 1503670666000
    },
    "count": "100000",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503670636498
  },
  {
    "_id": {
      "timestamp": 1503670609,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567399,
      "timeSecond": 1503670609,
      "date": 1503670609000,
      "time": 1503670609000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503670579640
  },
  {
    "_id": {
      "timestamp": 1503670331,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567398,
      "timeSecond": 1503670331,
      "date": 1503670331000,
      "time": 1503670331000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503670301368
  },
  {
    "_id": {
      "timestamp": 1503666929,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567397,
      "timeSecond": 1503666929,
      "date": 1503666929000,
      "time": 1503666929000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503666897906
  },
  {
    "_id": {
      "timestamp": 1503666426,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567396,
      "timeSecond": 1503666426,
      "date": 1503666426000,
      "time": 1503666426000
    },
    "count": "100000",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503666393916
  },
  {
    "_id": {
      "timestamp": 1503666372,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567395,
      "timeSecond": 1503666372,
      "date": 1503666372000,
      "time": 1503666372000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503666340657
  },
  {
    "_id": {
      "timestamp": 1503615747,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567394,
      "timeSecond": 1503615747,
      "date": 1503615747000,
      "time": 1503615747000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503615690522
  },
  {
    "_id": {
      "timestamp": 1503615293,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567393,
      "timeSecond": 1503615293,
      "date": 1503615293000,
      "time": 1503615293000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503615236111
  },
  {
    "_id": {
      "timestamp": 1503614938,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567392,
      "timeSecond": 1503614938,
      "date": 1503614938000,
      "time": 1503614938000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503614880546
  },
  {
    "_id": {
      "timestamp": 1503614890,
      "machineIdentifier": 14737140,
      "processIdentifier": -28912,
      "counter": 2567391,
      "timeSecond": 1503614890,
      "date": 1503614890000,
      "time": 1503614890000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503614832842
  },
  {
    "_id": {
      "timestamp": 1503529422,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386720,
      "timeSecond": 1503529422,
      "date": 1503529422000,
      "time": 1503529422000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503529422336
  },
  {
    "_id": {
      "timestamp": 1503529380,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386719,
      "timeSecond": 1503529380,
      "date": 1503529380000,
      "time": 1503529380000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503529380503
  },
  {
    "_id": {
      "timestamp": 1503528817,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386718,
      "timeSecond": 1503528817,
      "date": 1503528817000,
      "time": 1503528817000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503528817871
  },
  {
    "_id": {
      "timestamp": 1503528256,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386717,
      "timeSecond": 1503528256,
      "date": 1503528256000,
      "time": 1503528256000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503528256159
  },
  {
    "_id": {
      "timestamp": 1503527623,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386716,
      "timeSecond": 1503527623,
      "date": 1503527623000,
      "time": 1503527623000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503527623291
  },
  {
    "_id": {
      "timestamp": 1503527378,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386715,
      "timeSecond": 1503527378,
      "date": 1503527378000,
      "time": 1503527378000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503527378298
  },
  {
    "_id": {
      "timestamp": 1503526819,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386714,
      "timeSecond": 1503526819,
      "date": 1503526819000,
      "time": 1503526819000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503526819048
  },
  {
    "_id": {
      "timestamp": 1503526429,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386713,
      "timeSecond": 1503526429,
      "date": 1503526429000,
      "time": 1503526429000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503526429880
  },
  {
    "_id": {
      "timestamp": 1503524526,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386712,
      "timeSecond": 1503524526,
      "date": 1503524526000,
      "time": 1503524526000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503524526632
  },
  {
    "_id": {
      "timestamp": 1503523884,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386711,
      "timeSecond": 1503523884,
      "date": 1503523884000,
      "time": 1503523884000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1503523884238
  },
  {
    "_id": {
      "timestamp": 1503513463,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386710,
      "timeSecond": 1503513463,
      "date": 1503513463000,
      "time": 1503513463000
    },
    "count": "28",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503513463769
  },
  {
    "_id": {
      "timestamp": 1503513287,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386709,
      "timeSecond": 1503513287,
      "date": 1503513287000,
      "time": 1503513287000
    },
    "count": "28",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503513287020
  },
  {
    "_id": {
      "timestamp": 1503513225,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386708,
      "timeSecond": 1503513225,
      "date": 1503513225000,
      "time": 1503513225000
    },
    "count": "28",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503513225449
  },
  {
    "_id": {
      "timestamp": 1503512174,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386707,
      "timeSecond": 1503512174,
      "date": 1503512174000,
      "time": 1503512174000
    },
    "count": "28",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503512174291
  },
  {
    "_id": {
      "timestamp": 1503512169,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386706,
      "timeSecond": 1503512169,
      "date": 1503512169000,
      "time": 1503512169000
    },
    "count": "78",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503512169885
  },
  {
    "_id": {
      "timestamp": 1503512133,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386705,
      "timeSecond": 1503512133,
      "date": 1503512133000,
      "time": 1503512133000
    },
    "count": "178",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503512133542
  },
  {
    "_id": {
      "timestamp": 1503512084,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386704,
      "timeSecond": 1503512084,
      "date": 1503512084000,
      "time": 1503512084000
    },
    "count": "28",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503512084352
  },
  {
    "_id": {
      "timestamp": 1503512024,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386703,
      "timeSecond": 1503512024,
      "date": 1503512024000,
      "time": 1503512024000
    },
    "count": "28",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503512024040
  },
  {
    "_id": {
      "timestamp": 1503511121,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386702,
      "timeSecond": 1503511121,
      "date": 1503511121000,
      "time": 1503511121000
    },
    "count": "28",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503511121009
  },
  {
    "_id": {
      "timestamp": 1503511116,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386701,
      "timeSecond": 1503511116,
      "date": 1503511116000,
      "time": 1503511116000
    },
    "count": "78",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503511116330
  },
  {
    "_id": {
      "timestamp": 1503511068,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386700,
      "timeSecond": 1503511068,
      "date": 1503511068000,
      "time": 1503511068000
    },
    "count": "178",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503511068095
  },
  {
    "_id": {
      "timestamp": 1503511019,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386699,
      "timeSecond": 1503511019,
      "date": 1503511019000,
      "time": 1503511019000
    },
    "count": "28",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503511019504
  },
  {
    "_id": {
      "timestamp": 1503510977,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386698,
      "timeSecond": 1503510977,
      "date": 1503510977000,
      "time": 1503510977000
    },
    "count": "28",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503510977081
  },
  {
    "_id": {
      "timestamp": 1503510692,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386697,
      "timeSecond": 1503510692,
      "date": 1503510692000,
      "time": 1503510692000
    },
    "count": "28",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503510692362
  },
  {
    "_id": {
      "timestamp": 1503510688,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386696,
      "timeSecond": 1503510688,
      "date": 1503510688000,
      "time": 1503510688000
    },
    "count": "78",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503510688937
  },
  {
    "_id": {
      "timestamp": 1503510646,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386695,
      "timeSecond": 1503510646,
      "date": 1503510646000,
      "time": 1503510646000
    },
    "count": "178",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503510646694
  },
  {
    "_id": {
      "timestamp": 1503510601,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386694,
      "timeSecond": 1503510601,
      "date": 1503510601000,
      "time": 1503510601000
    },
    "count": "100000",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503510601825
  },
  {
    "_id": {
      "timestamp": 1503510558,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386693,
      "timeSecond": 1503510558,
      "date": 1503510558000,
      "time": 1503510558000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503510558925
  },
  {
    "_id": {
      "timestamp": 1503509861,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386692,
      "timeSecond": 1503509861,
      "date": 1503509861000,
      "time": 1503509861000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503509861227
  },
  {
    "_id": {
      "timestamp": 1503501766,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386691,
      "timeSecond": 1503501766,
      "date": 1503501766000,
      "time": 1503501766000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503501766404
  },
  {
    "_id": {
      "timestamp": 1503501592,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386690,
      "timeSecond": 1503501592,
      "date": 1503501592000,
      "time": 1503501592000
    },
    "count": "28",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503501592321
  },
  {
    "_id": {
      "timestamp": 1503501587,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386689,
      "timeSecond": 1503501587,
      "date": 1503501587000,
      "time": 1503501587000
    },
    "count": "78",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503501587842
  },
  {
    "_id": {
      "timestamp": 1503501540,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386688,
      "timeSecond": 1503501540,
      "date": 1503501540000,
      "time": 1503501540000
    },
    "count": "178",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503501540061
  },
  {
    "_id": {
      "timestamp": 1503501507,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386687,
      "timeSecond": 1503501507,
      "date": 1503501507000,
      "time": 1503501507000
    },
    "count": "100000",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503501507637
  },
  {
    "_id": {
      "timestamp": 1503501454,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386686,
      "timeSecond": 1503501454,
      "date": 1503501454000,
      "time": 1503501454000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503501454319
  },
  {
    "_id": {
      "timestamp": 1503498970,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386685,
      "timeSecond": 1503498970,
      "date": 1503498970000,
      "time": 1503498970000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503498970555
  },
  {
    "_id": {
      "timestamp": 1503498826,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386684,
      "timeSecond": 1503498826,
      "date": 1503498826000,
      "time": 1503498826000
    },
    "count": "28",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503498826754
  },
  {
    "_id": {
      "timestamp": 1503498815,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386683,
      "timeSecond": 1503498815,
      "date": 1503498815000,
      "time": 1503498815000
    },
    "count": "78",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503498815066
  },
  {
    "_id": {
      "timestamp": 1503498774,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386682,
      "timeSecond": 1503498774,
      "date": 1503498774000,
      "time": 1503498774000
    },
    "count": "178",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503498774276
  },
  {
    "_id": {
      "timestamp": 1503498738,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386681,
      "timeSecond": 1503498738,
      "date": 1503498738000,
      "time": 1503498738000
    },
    "count": "100000",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503498738067
  },
  {
    "_id": {
      "timestamp": 1503498699,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386680,
      "timeSecond": 1503498699,
      "date": 1503498699000,
      "time": 1503498699000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503498699488
  },
  {
    "_id": {
      "timestamp": 1503443711,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386679,
      "timeSecond": 1503443711,
      "date": 1503443711000,
      "time": 1503443711000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503443710999
  },
  {
    "_id": {
      "timestamp": 1503443435,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386678,
      "timeSecond": 1503443435,
      "date": 1503443435000,
      "time": 1503443435000
    },
    "count": "28",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503443435254
  },
  {
    "_id": {
      "timestamp": 1503443431,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386677,
      "timeSecond": 1503443431,
      "date": 1503443431000,
      "time": 1503443431000
    },
    "count": "78",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503443431704
  },
  {
    "_id": {
      "timestamp": 1503443396,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386676,
      "timeSecond": 1503443396,
      "date": 1503443396000,
      "time": 1503443396000
    },
    "count": "178",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503443396059
  },
  {
    "_id": {
      "timestamp": 1503443361,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386675,
      "timeSecond": 1503443361,
      "date": 1503443361000,
      "time": 1503443361000
    },
    "count": "100000",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503443361659
  },
  {
    "_id": {
      "timestamp": 1503443322,
      "machineIdentifier": 4905588,
      "processIdentifier": 16190,
      "counter": 386674,
      "timeSecond": 1503443322,
      "date": 1503443322000,
      "time": 1503443322000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1503443322552
  },
  {
    "_id": {
      "timestamp": 1501101645,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002294,
      "timeSecond": 1501101645,
      "date": 1501101645000,
      "time": 1501101645000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501101645365
  },
  {
    "_id": {
      "timestamp": 1501101580,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002293,
      "timeSecond": 1501101580,
      "date": 1501101580000,
      "time": 1501101580000
    },
    "count": "28",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501101580714
  },
  {
    "_id": {
      "timestamp": 1501101538,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002292,
      "timeSecond": 1501101538,
      "date": 1501101538000,
      "time": 1501101538000
    },
    "count": "28",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501101538004
  },
  {
    "_id": {
      "timestamp": 1501101499,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002291,
      "timeSecond": 1501101499,
      "date": 1501101499000,
      "time": 1501101499000
    },
    "count": "28",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501101499214
  },
  {
    "_id": {
      "timestamp": 1501101494,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002290,
      "timeSecond": 1501101494,
      "date": 1501101494000,
      "time": 1501101494000
    },
    "count": "78",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501101494937
  },
  {
    "_id": {
      "timestamp": 1501101461,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002289,
      "timeSecond": 1501101461,
      "date": 1501101461000,
      "time": 1501101461000
    },
    "count": "178",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501101461384
  },
  {
    "_id": {
      "timestamp": 1501084751,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002288,
      "timeSecond": 1501084751,
      "date": 1501084751000,
      "time": 1501084751000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501084751356
  },
  {
    "_id": {
      "timestamp": 1501084709,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002287,
      "timeSecond": 1501084709,
      "date": 1501084709000,
      "time": 1501084709000
    },
    "count": "28",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501084709626
  },
  {
    "_id": {
      "timestamp": 1501084678,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002286,
      "timeSecond": 1501084678,
      "date": 1501084678000,
      "time": 1501084678000
    },
    "count": "28",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501084678347
  },
  {
    "_id": {
      "timestamp": 1501084645,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002285,
      "timeSecond": 1501084645,
      "date": 1501084645000,
      "time": 1501084645000
    },
    "count": "28",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501084645037
  },
  {
    "_id": {
      "timestamp": 1501084641,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002284,
      "timeSecond": 1501084641,
      "date": 1501084641000,
      "time": 1501084641000
    },
    "count": "78",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501084641363
  },
  {
    "_id": {
      "timestamp": 1501084611,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002283,
      "timeSecond": 1501084611,
      "date": 1501084611000,
      "time": 1501084611000
    },
    "count": "178",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501084611183
  },
  {
    "_id": {
      "timestamp": 1501081349,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002282,
      "timeSecond": 1501081349,
      "date": 1501081349000,
      "time": 1501081349000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501081349474
  },
  {
    "_id": {
      "timestamp": 1501081310,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002281,
      "timeSecond": 1501081310,
      "date": 1501081310000,
      "time": 1501081310000
    },
    "count": "28",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501081310579
  },
  {
    "_id": {
      "timestamp": 1501081276,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002280,
      "timeSecond": 1501081276,
      "date": 1501081276000,
      "time": 1501081276000
    },
    "count": "28",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501081276342
  },
  {
    "_id": {
      "timestamp": 1501081240,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002279,
      "timeSecond": 1501081240,
      "date": 1501081240000,
      "time": 1501081240000
    },
    "count": "28",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501081240093
  },
  {
    "_id": {
      "timestamp": 1501081236,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002278,
      "timeSecond": 1501081236,
      "date": 1501081236000,
      "time": 1501081236000
    },
    "count": "78",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501081236737
  },
  {
    "_id": {
      "timestamp": 1501081198,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002277,
      "timeSecond": 1501081198,
      "date": 1501081198000,
      "time": 1501081198000
    },
    "count": "178",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501081198395
  },
  {
    "_id": {
      "timestamp": 1501080926,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002276,
      "timeSecond": 1501080926,
      "date": 1501080926000,
      "time": 1501080926000
    },
    "count": "28",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501080926550
  },
  {
    "_id": {
      "timestamp": 1501080925,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002275,
      "timeSecond": 1501080925,
      "date": 1501080925000,
      "time": 1501080925000
    },
    "count": "78",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501080925667
  },
  {
    "_id": {
      "timestamp": 1501080863,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002274,
      "timeSecond": 1501080863,
      "date": 1501080863000,
      "time": 1501080863000
    },
    "count": "28",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501080863682
  },
  {
    "_id": {
      "timestamp": 1501080862,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002273,
      "timeSecond": 1501080862,
      "date": 1501080862000,
      "time": 1501080862000
    },
    "count": "78",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501080862462
  },
  {
    "_id": {
      "timestamp": 1501080763,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002272,
      "timeSecond": 1501080763,
      "date": 1501080763000,
      "time": 1501080763000
    },
    "count": "28",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501080763969
  },
  {
    "_id": {
      "timestamp": 1501080763,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002271,
      "timeSecond": 1501080763,
      "date": 1501080763000,
      "time": 1501080763000
    },
    "count": "78",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501080763211
  },
  {
    "_id": {
      "timestamp": 1501080704,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002270,
      "timeSecond": 1501080704,
      "date": 1501080704000,
      "time": 1501080704000
    },
    "count": "28",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501080704187
  },
  {
    "_id": {
      "timestamp": 1501080703,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002269,
      "timeSecond": 1501080703,
      "date": 1501080703000,
      "time": 1501080703000
    },
    "count": "78",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501080703376
  },
  {
    "_id": {
      "timestamp": 1501080655,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002268,
      "timeSecond": 1501080655,
      "date": 1501080655000,
      "time": 1501080655000
    },
    "count": "28",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501080655804
  },
  {
    "_id": {
      "timestamp": 1501080655,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002267,
      "timeSecond": 1501080655,
      "date": 1501080655000,
      "time": 1501080655000
    },
    "count": "78",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501080655109
  },
  {
    "_id": {
      "timestamp": 1501080560,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002266,
      "timeSecond": 1501080560,
      "date": 1501080560000,
      "time": 1501080560000
    },
    "count": "28",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501080560511
  },
  {
    "_id": {
      "timestamp": 1501080559,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002265,
      "timeSecond": 1501080559,
      "date": 1501080559000,
      "time": 1501080559000
    },
    "count": "78",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501080559667
  },
  {
    "_id": {
      "timestamp": 1501080527,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002264,
      "timeSecond": 1501080527,
      "date": 1501080527000,
      "time": 1501080527000
    },
    "count": "28",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501080528009
  },
  {
    "_id": {
      "timestamp": 1501080526,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002263,
      "timeSecond": 1501080526,
      "date": 1501080526000,
      "time": 1501080526000
    },
    "count": "78",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501080526986
  },
  {
    "_id": {
      "timestamp": 1501080453,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002262,
      "timeSecond": 1501080453,
      "date": 1501080453000,
      "time": 1501080453000
    },
    "count": "28",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501080453703
  },
  {
    "_id": {
      "timestamp": 1501080158,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002261,
      "timeSecond": 1501080158,
      "date": 1501080158000,
      "time": 1501080158000
    },
    "count": "28",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501080158876
  },
  {
    "_id": {
      "timestamp": 1501080155,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002260,
      "timeSecond": 1501080155,
      "date": 1501080155000,
      "time": 1501080155000
    },
    "count": "78",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501080155569
  },
  {
    "_id": {
      "timestamp": 1501079549,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002258,
      "timeSecond": 1501079549,
      "date": 1501079549000,
      "time": 1501079549000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501079549412
  },
  {
    "_id": {
      "timestamp": 1501079516,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002257,
      "timeSecond": 1501079516,
      "date": 1501079516000,
      "time": 1501079516000
    },
    "count": "78",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501079516248
  },
  {
    "_id": {
      "timestamp": 1501079480,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002256,
      "timeSecond": 1501079480,
      "date": 1501079480000,
      "time": 1501079480000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501079480333
  },
  {
    "_id": {
      "timestamp": 1501079440,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002255,
      "timeSecond": 1501079440,
      "date": 1501079440000,
      "time": 1501079440000
    },
    "count": "78",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501079441012
  },
  {
    "_id": {
      "timestamp": 1501079408,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002254,
      "timeSecond": 1501079408,
      "date": 1501079408000,
      "time": 1501079408000
    },
    "count": "178",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501079408170
  },
  {
    "_id": {
      "timestamp": 1501079125,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002253,
      "timeSecond": 1501079125,
      "date": 1501079125000,
      "time": 1501079125000
    },
    "count": "100000",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501079125222
  },
  {
    "_id": {
      "timestamp": 1501079123,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002252,
      "timeSecond": 1501079123,
      "date": 1501079123000,
      "time": 1501079123000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501079123869
  },
  {
    "_id": {
      "timestamp": 1501079123,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002251,
      "timeSecond": 1501079123,
      "date": 1501079123000,
      "time": 1501079123000
    },
    "count": "78",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501079123520
  },
  {
    "_id": {
      "timestamp": 1501078587,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002250,
      "timeSecond": 1501078587,
      "date": 1501078587000,
      "time": 1501078587000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501078587736
  },
  {
    "_id": {
      "timestamp": 1501078008,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002249,
      "timeSecond": 1501078008,
      "date": 1501078008000,
      "time": 1501078008000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501078008355
  },
  {
    "_id": {
      "timestamp": 1501077784,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002248,
      "timeSecond": 1501077784,
      "date": 1501077784000,
      "time": 1501077784000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501077784146
  },
  {
    "_id": {
      "timestamp": 1501021415,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002244,
      "timeSecond": 1501021415,
      "date": 1501021415000,
      "time": 1501021415000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501021415638
  },
  {
    "_id": {
      "timestamp": 1501021405,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002243,
      "timeSecond": 1501021405,
      "date": 1501021405000,
      "time": 1501021405000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501021405771
  },
  {
    "_id": {
      "timestamp": 1501020779,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002242,
      "timeSecond": 1501020779,
      "date": 1501020779000,
      "time": 1501020779000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501020779111
  },
  {
    "_id": {
      "timestamp": 1501020771,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002241,
      "timeSecond": 1501020771,
      "date": 1501020771000,
      "time": 1501020771000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1501020771221
  },
  {
    "_id": {
      "timestamp": 1500996096,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002240,
      "timeSecond": 1500996096,
      "date": 1500996096000,
      "time": 1500996096000
    },
    "count": "78",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1500996096067
  },
  {
    "_id": {
      "timestamp": 1500996040,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002239,
      "timeSecond": 1500996040,
      "date": 1500996040000,
      "time": 1500996040000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1500996040089
  },
  {
    "_id": {
      "timestamp": 1500995986,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002238,
      "timeSecond": 1500995986,
      "date": 1500995986000,
      "time": 1500995986000
    },
    "count": "78",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1500995986104
  },
  {
    "_id": {
      "timestamp": 1500995936,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002237,
      "timeSecond": 1500995936,
      "date": 1500995936000,
      "time": 1500995936000
    },
    "count": "178",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1500995936609
  },
  {
    "_id": {
      "timestamp": 1500995839,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002236,
      "timeSecond": 1500995839,
      "date": 1500995839000,
      "time": 1500995839000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1500995839646
  },
  {
    "_id": {
      "timestamp": 1500591599,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002219,
      "timeSecond": 1500591599,
      "date": 1500591599000,
      "time": 1500591599000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1500591599449
  },
  {
    "_id": {
      "timestamp": 1500591595,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002218,
      "timeSecond": 1500591595,
      "date": 1500591595000,
      "time": 1500591595000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1500591595272
  },
  {
    "_id": {
      "timestamp": 1500591289,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002217,
      "timeSecond": 1500591289,
      "date": 1500591289000,
      "time": 1500591289000
    },
    "count": "78",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1500591289822
  },
  {
    "_id": {
      "timestamp": 1500591254,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002216,
      "timeSecond": 1500591254,
      "date": 1500591254000,
      "time": 1500591254000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1500591255026
  },
  {
    "_id": {
      "timestamp": 1500591215,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002215,
      "timeSecond": 1500591215,
      "date": 1500591215000,
      "time": 1500591215000
    },
    "count": "78",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1500591215204
  },
  {
    "_id": {
      "timestamp": 1500591169,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002214,
      "timeSecond": 1500591169,
      "date": 1500591169000,
      "time": 1500591169000
    },
    "count": "178",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1500591169372
  },
  {
    "_id": {
      "timestamp": 1500591126,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002213,
      "timeSecond": 1500591126,
      "date": 1500591126000,
      "time": 1500591126000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1500591126354
  },
  {
    "_id": {
      "timestamp": 1500045976,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002199,
      "timeSecond": 1500045976,
      "date": 1500045976000,
      "time": 1500045976000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcrdcall",
    "dateCreated": 1500045976937
  },
  {
    "_id": {
      "timestamp": 1500045832,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002198,
      "timeSecond": 1500045832,
      "date": 1500045832000,
      "time": 1500045832000
    },
    "count": "78",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcrdcall",
    "dateCreated": 1500045832255
  },
  {
    "_id": {
      "timestamp": 1500045797,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002197,
      "timeSecond": 1500045797,
      "date": 1500045797000,
      "time": 1500045797000
    },
    "count": "178",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcrdcall",
    "dateCreated": 1500045797273
  },
  {
    "_id": {
      "timestamp": 1500045759,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002196,
      "timeSecond": 1500045759,
      "date": 1500045759000,
      "time": 1500045759000
    },
    "count": "100000",
    "percentage": "78",
    "schemaType": "PBR",
    "userCreated": "vcrdcall",
    "dateCreated": 1500045759339
  },
  {
    "_id": {
      "timestamp": 1500045721,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002195,
      "timeSecond": 1500045721,
      "date": 1500045721000,
      "time": 1500045721000
    },
    "count": "100000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcrdcall",
    "dateCreated": 1500045721657
  },
  {
    "_id": {
      "timestamp": 1499120775,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002116,
      "timeSecond": 1499120775,
      "date": 1499120775000,
      "time": 1499120775000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1499120775492
  },
  {
    "_id": {
      "timestamp": 1498669370,
      "machineIdentifier": 6995187,
      "processIdentifier": 23283,
      "counter": 7002100,
      "timeSecond": 1498669370,
      "date": 1498669370000,
      "time": 1498669370000
    },
    "count": "100000",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1498669370234
  },
  {
    "_id": {
      "timestamp": 1496690380,
      "machineIdentifier": 9584485,
      "processIdentifier": -23228,
      "counter": 2079739,
      "timeSecond": 1496690380,
      "date": 1496690380000,
      "time": 1496690380000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1496691793432
  },
  {
    "_id": {
      "timestamp": 1496690375,
      "machineIdentifier": 9584485,
      "processIdentifier": -23228,
      "counter": 2079738,
      "timeSecond": 1496690375,
      "date": 1496690375000,
      "time": 1496690375000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1496691787538
  },
  {
    "_id": {
      "timestamp": 1496343473,
      "machineIdentifier": 11192344,
      "processIdentifier": -8191,
      "counter": 6056357,
      "timeSecond": 1496343473,
      "date": 1496343473000,
      "time": 1496343473000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1496343473296
  },
  {
    "_id": {
      "timestamp": 1495735730,
      "machineIdentifier": 11192344,
      "processIdentifier": -8191,
      "counter": 6056320,
      "timeSecond": 1495735730,
      "date": 1495735730000,
      "time": 1495735730000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1495735730755
  },
  {
    "_id": {
      "timestamp": 1495735683,
      "machineIdentifier": 11192344,
      "processIdentifier": -8191,
      "counter": 6056319,
      "timeSecond": 1495735683,
      "date": 1495735683000,
      "time": 1495735683000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1495735683566
  },
  {
    "_id": {
      "timestamp": 1495735671,
      "machineIdentifier": 11192344,
      "processIdentifier": -8191,
      "counter": 6056318,
      "timeSecond": 1495735671,
      "date": 1495735671000,
      "time": 1495735671000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1495735671996
  },
  {
    "_id": {
      "timestamp": 1495735659,
      "machineIdentifier": 11192344,
      "processIdentifier": -8191,
      "counter": 6056317,
      "timeSecond": 1495735659,
      "date": 1495735659000,
      "time": 1495735659000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1495735659367
  },
  {
    "_id": {
      "timestamp": 1495735658,
      "machineIdentifier": 11192344,
      "processIdentifier": -8191,
      "counter": 6056316,
      "timeSecond": 1495735658,
      "date": 1495735658000,
      "time": 1495735658000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1495735658305
  },
  {
    "_id": {
      "timestamp": 1495735646,
      "machineIdentifier": 11192344,
      "processIdentifier": -8191,
      "counter": 6056315,
      "timeSecond": 1495735646,
      "date": 1495735646000,
      "time": 1495735646000
    },
    "count": "100000",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1495735646411
  },
  {
    "_id": {
      "timestamp": 1495735645,
      "machineIdentifier": 11192344,
      "processIdentifier": -8191,
      "counter": 6056314,
      "timeSecond": 1495735645,
      "date": 1495735645000,
      "time": 1495735645000
    },
    "count": "100000",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1495735645377
  },
  {
    "_id": {
      "timestamp": 1495576669,
      "machineIdentifier": 11192344,
      "processIdentifier": -8191,
      "counter": 6056309,
      "timeSecond": 1495576669,
      "date": 1495576669000,
      "time": 1495576669000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1495576669673
  },
  {
    "_id": {
      "timestamp": 1495576623,
      "machineIdentifier": 11192344,
      "processIdentifier": -8191,
      "counter": 6056308,
      "timeSecond": 1495576623,
      "date": 1495576623000,
      "time": 1495576623000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1495576623773
  },
  {
    "_id": {
      "timestamp": 1495576612,
      "machineIdentifier": 11192344,
      "processIdentifier": -8191,
      "counter": 6056307,
      "timeSecond": 1495576612,
      "date": 1495576612000,
      "time": 1495576612000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1495576612252
  },
  {
    "_id": {
      "timestamp": 1495576600,
      "machineIdentifier": 11192344,
      "processIdentifier": -8191,
      "counter": 6056306,
      "timeSecond": 1495576600,
      "date": 1495576600000,
      "time": 1495576600000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1495576600697
  },
  {
    "_id": {
      "timestamp": 1495576599,
      "machineIdentifier": 11192344,
      "processIdentifier": -8191,
      "counter": 6056305,
      "timeSecond": 1495576599,
      "date": 1495576599000,
      "time": 1495576599000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1495576599628
  },
  {
    "_id": {
      "timestamp": 1495576587,
      "machineIdentifier": 11192344,
      "processIdentifier": -8191,
      "counter": 6056304,
      "timeSecond": 1495576587,
      "date": 1495576587000,
      "time": 1495576587000
    },
    "count": "100000000",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1495576587592
  },
  {
    "_id": {
      "timestamp": 1495576586,
      "machineIdentifier": 11192344,
      "processIdentifier": -8191,
      "counter": 6056303,
      "timeSecond": 1495576586,
      "date": 1495576586000,
      "time": 1495576586000
    },
    "count": "100000000",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1495576586506
  },
  {
    "_id": {
      "timestamp": 1494517892,
      "machineIdentifier": 11192344,
      "processIdentifier": -8191,
      "counter": 6056294,
      "timeSecond": 1494517892,
      "date": 1494517892000,
      "time": 1494517892000
    },
    "count": "100000000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1494517760487
  },
  {
    "_id": {
      "timestamp": 1494517883,
      "machineIdentifier": 11192344,
      "processIdentifier": -8191,
      "counter": 6056293,
      "timeSecond": 1494517883,
      "date": 1494517883000,
      "time": 1494517883000
    },
    "count": "100000",
    "percentage": "99",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1494517751593
  },
  {
    "_id": {
      "timestamp": 1494517876,
      "machineIdentifier": 11192344,
      "processIdentifier": -8191,
      "counter": 6056292,
      "timeSecond": 1494517876,
      "date": 1494517876000,
      "time": 1494517876000
    },
    "count": "100000",
    "percentage": "99",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1494517744513
  },
  {
    "_id": {
      "timestamp": 1494448267,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 14902924,
      "timeSecond": 1494448267,
      "date": 1494448267000,
      "time": 1494448267000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1494448267184
  },
  {
    "_id": {
      "timestamp": 1493899258,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 14718802,
      "timeSecond": 1493899258,
      "date": 1493899258000,
      "time": 1493899258000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493899258503
  },
  {
    "_id": {
      "timestamp": 1493899210,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 14718801,
      "timeSecond": 1493899210,
      "date": 1493899210000,
      "time": 1493899210000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493899210275
  },
  {
    "_id": {
      "timestamp": 1493899197,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 14718800,
      "timeSecond": 1493899197,
      "date": 1493899197000,
      "time": 1493899197000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493899197686
  },
  {
    "_id": {
      "timestamp": 1493899185,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 14718799,
      "timeSecond": 1493899185,
      "date": 1493899185000,
      "time": 1493899185000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493899185226
  },
  {
    "_id": {
      "timestamp": 1493899184,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 14718798,
      "timeSecond": 1493899184,
      "date": 1493899184000,
      "time": 1493899184000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493899184164
  },
  {
    "_id": {
      "timestamp": 1493899172,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 14718797,
      "timeSecond": 1493899172,
      "date": 1493899172000,
      "time": 1493899172000
    },
    "count": "100000",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493899172370
  },
  {
    "_id": {
      "timestamp": 1493899171,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 14718796,
      "timeSecond": 1493899171,
      "date": 1493899171000,
      "time": 1493899171000
    },
    "count": "100000",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493899171270
  },
  {
    "_id": {
      "timestamp": 1493896828,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 14384513,
      "timeSecond": 1493896828,
      "date": 1493896828000,
      "time": 1493896828000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1493896828658
  },
  {
    "_id": {
      "timestamp": 1493849399,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724377,
      "timeSecond": 1493849399,
      "date": 1493849399000,
      "time": 1493849399000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493849399253
  },
  {
    "_id": {
      "timestamp": 1493849351,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724376,
      "timeSecond": 1493849351,
      "date": 1493849351000,
      "time": 1493849351000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493849351074
  },
  {
    "_id": {
      "timestamp": 1493849339,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724375,
      "timeSecond": 1493849339,
      "date": 1493849339000,
      "time": 1493849339000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493849339665
  },
  {
    "_id": {
      "timestamp": 1493849327,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724374,
      "timeSecond": 1493849327,
      "date": 1493849327000,
      "time": 1493849327000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493849327512
  },
  {
    "_id": {
      "timestamp": 1493849326,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724373,
      "timeSecond": 1493849326,
      "date": 1493849326000,
      "time": 1493849326000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493849326453
  },
  {
    "_id": {
      "timestamp": 1493849314,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724372,
      "timeSecond": 1493849314,
      "date": 1493849314000,
      "time": 1493849314000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493849314993
  },
  {
    "_id": {
      "timestamp": 1493849313,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724371,
      "timeSecond": 1493849313,
      "date": 1493849313000,
      "time": 1493849313000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493849313915
  },
  {
    "_id": {
      "timestamp": 1493849271,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724370,
      "timeSecond": 1493849271,
      "date": 1493849271000,
      "time": 1493849271000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493849271872
  },
  {
    "_id": {
      "timestamp": 1493849270,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724369,
      "timeSecond": 1493849270,
      "date": 1493849270000,
      "time": 1493849270000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493849270798
  },
  {
    "_id": {
      "timestamp": 1493849237,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724368,
      "timeSecond": 1493849237,
      "date": 1493849237000,
      "time": 1493849237000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493849237665
  },
  {
    "_id": {
      "timestamp": 1493849236,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724367,
      "timeSecond": 1493849236,
      "date": 1493849236000,
      "time": 1493849236000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493849236560
  },
  {
    "_id": {
      "timestamp": 1493849188,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724366,
      "timeSecond": 1493849188,
      "date": 1493849188000,
      "time": 1493849188000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493849188034
  },
  {
    "_id": {
      "timestamp": 1493849186,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724365,
      "timeSecond": 1493849186,
      "date": 1493849186000,
      "time": 1493849186000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493849186961
  },
  {
    "_id": {
      "timestamp": 1493849056,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724364,
      "timeSecond": 1493849056,
      "date": 1493849056000,
      "time": 1493849056000
    },
    "count": "178",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493849056279
  },
  {
    "_id": {
      "timestamp": 1493849036,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724363,
      "timeSecond": 1493849036,
      "date": 1493849036000,
      "time": 1493849036000
    },
    "count": "178",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493849036933
  },
  {
    "_id": {
      "timestamp": 1493847273,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724362,
      "timeSecond": 1493847273,
      "date": 1493847273000,
      "time": 1493847273000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493847273802
  },
  {
    "_id": {
      "timestamp": 1493847226,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724361,
      "timeSecond": 1493847226,
      "date": 1493847226000,
      "time": 1493847226000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493847226583
  },
  {
    "_id": {
      "timestamp": 1493847214,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724360,
      "timeSecond": 1493847214,
      "date": 1493847214000,
      "time": 1493847214000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493847214127
  },
  {
    "_id": {
      "timestamp": 1493847203,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724359,
      "timeSecond": 1493847203,
      "date": 1493847203000,
      "time": 1493847203000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493847203184
  },
  {
    "_id": {
      "timestamp": 1493847191,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724358,
      "timeSecond": 1493847191,
      "date": 1493847191000,
      "time": 1493847191000
    },
    "count": "100000",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493847191795
  },
  {
    "_id": {
      "timestamp": 1493847152,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724357,
      "timeSecond": 1493847152,
      "date": 1493847152000,
      "time": 1493847152000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493847152869
  },
  {
    "_id": {
      "timestamp": 1493847104,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724356,
      "timeSecond": 1493847104,
      "date": 1493847104000,
      "time": 1493847104000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493847104867
  },
  {
    "_id": {
      "timestamp": 1493847094,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724355,
      "timeSecond": 1493847094,
      "date": 1493847094000,
      "time": 1493847094000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493847094077
  },
  {
    "_id": {
      "timestamp": 1493847082,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724354,
      "timeSecond": 1493847082,
      "date": 1493847082000,
      "time": 1493847082000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493847082316
  },
  {
    "_id": {
      "timestamp": 1493847070,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724353,
      "timeSecond": 1493847070,
      "date": 1493847070000,
      "time": 1493847070000
    },
    "count": "100000",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493847070436
  },
  {
    "_id": {
      "timestamp": 1493847031,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724352,
      "timeSecond": 1493847031,
      "date": 1493847031000,
      "time": 1493847031000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493847031860
  },
  {
    "_id": {
      "timestamp": 1493846983,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724351,
      "timeSecond": 1493846983,
      "date": 1493846983000,
      "time": 1493846983000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846983925
  },
  {
    "_id": {
      "timestamp": 1493846972,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724350,
      "timeSecond": 1493846972,
      "date": 1493846972000,
      "time": 1493846972000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846972303
  },
  {
    "_id": {
      "timestamp": 1493846960,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724349,
      "timeSecond": 1493846960,
      "date": 1493846960000,
      "time": 1493846960000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846960404
  },
  {
    "_id": {
      "timestamp": 1493846948,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724348,
      "timeSecond": 1493846948,
      "date": 1493846948000,
      "time": 1493846948000
    },
    "count": "78",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846948728
  },
  {
    "_id": {
      "timestamp": 1493846764,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724347,
      "timeSecond": 1493846764,
      "date": 1493846764000,
      "time": 1493846764000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846764263
  },
  {
    "_id": {
      "timestamp": 1493846752,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724346,
      "timeSecond": 1493846752,
      "date": 1493846752000,
      "time": 1493846752000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846752786
  },
  {
    "_id": {
      "timestamp": 1493846740,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724345,
      "timeSecond": 1493846740,
      "date": 1493846740000,
      "time": 1493846740000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846740985
  },
  {
    "_id": {
      "timestamp": 1493846730,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724344,
      "timeSecond": 1493846730,
      "date": 1493846730000,
      "time": 1493846730000
    },
    "count": "100000",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846730396
  },
  {
    "_id": {
      "timestamp": 1493846683,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724343,
      "timeSecond": 1493846683,
      "date": 1493846683000,
      "time": 1493846683000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846683690
  },
  {
    "_id": {
      "timestamp": 1493846637,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724342,
      "timeSecond": 1493846637,
      "date": 1493846637000,
      "time": 1493846637000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846637774
  },
  {
    "_id": {
      "timestamp": 1493846625,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724341,
      "timeSecond": 1493846625,
      "date": 1493846625000,
      "time": 1493846625000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846625860
  },
  {
    "_id": {
      "timestamp": 1493846615,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724340,
      "timeSecond": 1493846615,
      "date": 1493846615000,
      "time": 1493846615000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846615243
  },
  {
    "_id": {
      "timestamp": 1493846604,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724339,
      "timeSecond": 1493846604,
      "date": 1493846604000,
      "time": 1493846604000
    },
    "count": "100000",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846604352
  },
  {
    "_id": {
      "timestamp": 1493846564,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724338,
      "timeSecond": 1493846564,
      "date": 1493846564000,
      "time": 1493846564000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846564252
  },
  {
    "_id": {
      "timestamp": 1493846518,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724337,
      "timeSecond": 1493846518,
      "date": 1493846518000,
      "time": 1493846518000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846518680
  },
  {
    "_id": {
      "timestamp": 1493846507,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724336,
      "timeSecond": 1493846507,
      "date": 1493846507000,
      "time": 1493846507000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846507054
  },
  {
    "_id": {
      "timestamp": 1493846496,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724335,
      "timeSecond": 1493846496,
      "date": 1493846496000,
      "time": 1493846496000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846496283
  },
  {
    "_id": {
      "timestamp": 1493846485,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724334,
      "timeSecond": 1493846485,
      "date": 1493846485000,
      "time": 1493846485000
    },
    "count": "100000",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846485246
  },
  {
    "_id": {
      "timestamp": 1493846447,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724333,
      "timeSecond": 1493846447,
      "date": 1493846447000,
      "time": 1493846447000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846447962
  },
  {
    "_id": {
      "timestamp": 1493846402,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724332,
      "timeSecond": 1493846402,
      "date": 1493846402000,
      "time": 1493846402000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846402112
  },
  {
    "_id": {
      "timestamp": 1493846391,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724331,
      "timeSecond": 1493846391,
      "date": 1493846391000,
      "time": 1493846391000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846391396
  },
  {
    "_id": {
      "timestamp": 1493846380,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724330,
      "timeSecond": 1493846380,
      "date": 1493846380000,
      "time": 1493846380000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846380639
  },
  {
    "_id": {
      "timestamp": 1493846370,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724329,
      "timeSecond": 1493846370,
      "date": 1493846370000,
      "time": 1493846370000
    },
    "count": "78",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846370253
  },
  {
    "_id": {
      "timestamp": 1493846006,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724328,
      "timeSecond": 1493846006,
      "date": 1493846006000,
      "time": 1493846006000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493846006083
  },
  {
    "_id": {
      "timestamp": 1493845995,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724327,
      "timeSecond": 1493845995,
      "date": 1493845995000,
      "time": 1493845995000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493845995484
  },
  {
    "_id": {
      "timestamp": 1493845985,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724326,
      "timeSecond": 1493845985,
      "date": 1493845985000,
      "time": 1493845985000
    },
    "count": "100000",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493845985185
  },
  {
    "_id": {
      "timestamp": 1493845928,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724325,
      "timeSecond": 1493845928,
      "date": 1493845928000,
      "time": 1493845928000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493845928623
  },
  {
    "_id": {
      "timestamp": 1493845883,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724324,
      "timeSecond": 1493845883,
      "date": 1493845883000,
      "time": 1493845883000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493845883221
  },
  {
    "_id": {
      "timestamp": 1493845872,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724323,
      "timeSecond": 1493845872,
      "date": 1493845872000,
      "time": 1493845872000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493845872067
  },
  {
    "_id": {
      "timestamp": 1493845860,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724322,
      "timeSecond": 1493845860,
      "date": 1493845860000,
      "time": 1493845860000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493845860911
  },
  {
    "_id": {
      "timestamp": 1493845849,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724321,
      "timeSecond": 1493845849,
      "date": 1493845849000,
      "time": 1493845849000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493845849328
  },
  {
    "_id": {
      "timestamp": 1493845286,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724320,
      "timeSecond": 1493845286,
      "date": 1493845286000,
      "time": 1493845286000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493845286368
  },
  {
    "_id": {
      "timestamp": 1493845276,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724319,
      "timeSecond": 1493845276,
      "date": 1493845276000,
      "time": 1493845276000
    },
    "count": "100000",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493845276584
  },
  {
    "_id": {
      "timestamp": 1493844997,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724318,
      "timeSecond": 1493844997,
      "date": 1493844997000,
      "time": 1493844997000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493844997122
  },
  {
    "_id": {
      "timestamp": 1493844953,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724317,
      "timeSecond": 1493844953,
      "date": 1493844953000,
      "time": 1493844953000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493844953192
  },
  {
    "_id": {
      "timestamp": 1493844942,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724316,
      "timeSecond": 1493844942,
      "date": 1493844942000,
      "time": 1493844942000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493844942742
  },
  {
    "_id": {
      "timestamp": 1493844932,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724315,
      "timeSecond": 1493844932,
      "date": 1493844932000,
      "time": 1493844932000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493844932481
  },
  {
    "_id": {
      "timestamp": 1493844922,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724314,
      "timeSecond": 1493844922,
      "date": 1493844922000,
      "time": 1493844922000
    },
    "count": "100000",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493844922310
  },
  {
    "_id": {
      "timestamp": 1493844801,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724313,
      "timeSecond": 1493844801,
      "date": 1493844801000,
      "time": 1493844801000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493844801135
  },
  {
    "_id": {
      "timestamp": 1493844756,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724312,
      "timeSecond": 1493844756,
      "date": 1493844756000,
      "time": 1493844756000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493844756739
  },
  {
    "_id": {
      "timestamp": 1493844745,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724311,
      "timeSecond": 1493844745,
      "date": 1493844745000,
      "time": 1493844745000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493844745214
  },
  {
    "_id": {
      "timestamp": 1493844734,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724310,
      "timeSecond": 1493844734,
      "date": 1493844734000,
      "time": 1493844734000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493844734180
  },
  {
    "_id": {
      "timestamp": 1493844723,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724309,
      "timeSecond": 1493844723,
      "date": 1493844723000,
      "time": 1493844723000
    },
    "count": "78",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493844723727
  },
  {
    "_id": {
      "timestamp": 1493841393,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724307,
      "timeSecond": 1493841393,
      "date": 1493841393000,
      "time": 1493841393000
    },
    "count": "78",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493841393127
  },
  {
    "_id": {
      "timestamp": 1493841380,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724306,
      "timeSecond": 1493841380,
      "date": 1493841380000,
      "time": 1493841380000
    },
    "count": "78",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493841380496
  },
  {
    "_id": {
      "timestamp": 1493841183,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724305,
      "timeSecond": 1493841183,
      "date": 1493841183000,
      "time": 1493841183000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493841183398
  },
  {
    "_id": {
      "timestamp": 1493841172,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724304,
      "timeSecond": 1493841172,
      "date": 1493841172000,
      "time": 1493841172000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493841172337
  },
  {
    "_id": {
      "timestamp": 1493841162,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724303,
      "timeSecond": 1493841162,
      "date": 1493841162000,
      "time": 1493841162000
    },
    "count": "78",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493841162239
  },
  {
    "_id": {
      "timestamp": 1493840414,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724302,
      "timeSecond": 1493840414,
      "date": 1493840414000,
      "time": 1493840414000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493840414853
  },
  {
    "_id": {
      "timestamp": 1493840359,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724301,
      "timeSecond": 1493840359,
      "date": 1493840359000,
      "time": 1493840359000
    },
    "count": "78",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493840359653
  },
  {
    "_id": {
      "timestamp": 1493840348,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724300,
      "timeSecond": 1493840348,
      "date": 1493840348000,
      "time": 1493840348000
    },
    "count": "178",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493840348613
  },
  {
    "_id": {
      "timestamp": 1493840337,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724299,
      "timeSecond": 1493840337,
      "date": 1493840337000,
      "time": 1493840337000
    },
    "count": "100000000",
    "percentage": "128",
    "schemaType": "PBR",
    "userCreated": "vcpbradmin",
    "dateCreated": 1493840337878
  },
  {
    "_id": {
      "timestamp": 1493738958,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13724294,
      "timeSecond": 1493738958,
      "date": 1493738958000,
      "time": 1493738958000
    },
    "count": "100000000",
    "percentage": "99",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1493738958044
  },
  {
    "_id": {
      "timestamp": 1493128653,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497583,
      "timeSecond": 1493128653,
      "date": 1493128653000,
      "time": 1493128653000
    },
    "count": "100000000",
    "percentage": "99",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1493128653213
  },
  {
    "_id": {
      "timestamp": 1493128012,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497582,
      "timeSecond": 1493128012,
      "date": 1493128012000,
      "time": 1493128012000
    },
    "count": "0",
    "percentage": "0",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1493128012640
  },
  {
    "_id": {
      "timestamp": 1493126843,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497581,
      "timeSecond": 1493126843,
      "date": 1493126843000,
      "time": 1493126843000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1493126843806
  },
  {
    "_id": {
      "timestamp": 1493126806,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497580,
      "timeSecond": 1493126806,
      "date": 1493126806000,
      "time": 1493126806000
    },
    "count": "100000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1493126806390
  },
  {
    "_id": {
      "timestamp": 1493126448,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497579,
      "timeSecond": 1493126448,
      "date": 1493126448000,
      "time": 1493126448000
    },
    "count": "0",
    "percentage": "0",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1493126448889
  },
  {
    "_id": {
      "timestamp": 1493126445,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497578,
      "timeSecond": 1493126445,
      "date": 1493126445000,
      "time": 1493126445000
    },
    "count": "0",
    "percentage": "0",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1493126445307
  },
  {
    "_id": {
      "timestamp": 1493126347,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497577,
      "timeSecond": 1493126347,
      "date": 1493126347000,
      "time": 1493126347000
    },
    "count": "28",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1493126347105
  },
  {
    "_id": {
      "timestamp": 1493126339,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497576,
      "timeSecond": 1493126339,
      "date": 1493126339000,
      "time": 1493126339000
    },
    "count": "28",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1493126339050
  },
  {
    "_id": {
      "timestamp": 1493126314,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497575,
      "timeSecond": 1493126314,
      "date": 1493126314000,
      "time": 1493126314000
    },
    "count": "0",
    "percentage": "0",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1493126314721
  },
  {
    "_id": {
      "timestamp": 1493126167,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497574,
      "timeSecond": 1493126167,
      "date": 1493126167000,
      "time": 1493126167000
    },
    "count": "0",
    "percentage": "0",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1493126167817
  },
  {
    "_id": {
      "timestamp": 1493126034,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497573,
      "timeSecond": 1493126034,
      "date": 1493126034000,
      "time": 1493126034000
    },
    "count": "0",
    "percentage": "0",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1493126034066
  },
  {
    "_id": {
      "timestamp": 1493125848,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497572,
      "timeSecond": 1493125848,
      "date": 1493125848000,
      "time": 1493125848000
    },
    "count": "100",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1493125848731
  },
  {
    "_id": {
      "timestamp": 1493125762,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497571,
      "timeSecond": 1493125762,
      "date": 1493125762000,
      "time": 1493125762000
    },
    "count": "100",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1493125762524
  },
  {
    "_id": {
      "timestamp": 1493125668,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497570,
      "timeSecond": 1493125668,
      "date": 1493125668000,
      "time": 1493125668000
    },
    "count": "0",
    "percentage": "0",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1493125668890
  },
  {
    "_id": {
      "timestamp": 1493125664,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497569,
      "timeSecond": 1493125664,
      "date": 1493125664000,
      "time": 1493125664000
    },
    "count": "0",
    "percentage": "0",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1493125664702
  },
  {
    "_id": {
      "timestamp": 1493125648,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497568,
      "timeSecond": 1493125648,
      "date": 1493125648000,
      "time": 1493125648000
    },
    "count": "77",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1493125648177
  },
  {
    "_id": {
      "timestamp": 1493124300,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497567,
      "timeSecond": 1493124300,
      "date": 1493124300000,
      "time": 1493124300000
    },
    "count": "77",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1493124300606
  },
  {
    "_id": {
      "timestamp": 1493124279,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497566,
      "timeSecond": 1493124279,
      "date": 1493124279000,
      "time": 1493124279000
    },
    "count": "2",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1493124279754
  },
  {
    "_id": {
      "timestamp": 1493045300,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497565,
      "timeSecond": 1493045300,
      "date": 1493045300000,
      "time": 1493045300000
    },
    "count": "2",
    "percentage": "10",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1493045300208
  },
  {
    "_id": {
      "timestamp": 1493045245,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497564,
      "timeSecond": 1493045245,
      "date": 1493045245000,
      "time": 1493045245000
    },
    "count": "2",
    "percentage": "2",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1493045245569
  },
  {
    "_id": {
      "timestamp": 1493045220,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497563,
      "timeSecond": 1493045220,
      "date": 1493045220000,
      "time": 1493045220000
    },
    "count": "28",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1493045220300
  },
  {
    "_id": {
      "timestamp": 1493045195,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497562,
      "timeSecond": 1493045195,
      "date": 1493045195000,
      "time": 1493045195000
    },
    "count": "7500000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1493045195847
  },
  {
    "_id": {
      "timestamp": 1493045173,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497561,
      "timeSecond": 1493045173,
      "date": 1493045173000,
      "time": 1493045173000
    },
    "count": "28",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1493045173264
  },
  {
    "_id": {
      "timestamp": 1492995637,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497560,
      "timeSecond": 1492995637,
      "date": 1492995637000,
      "time": 1492995637000
    },
    "count": "100000000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1492995637595
  },
  {
    "_id": {
      "timestamp": 1492995633,
      "machineIdentifier": 5720766,
      "processIdentifier": 6477,
      "counter": 13497559,
      "timeSecond": 1492995633,
      "date": 1492995633000,
      "time": 1492995633000
    },
    "count": "100000000",
    "percentage": "28",
    "schemaType": "PBR",
    "userCreated": "vcarli",
    "dateCreated": 1492995633676
  },
  {
    "_id": {
      "timestamp": 1491243841,
      "machineIdentifier": 16363140,
      "processIdentifier": -32454,
      "counter": 8654390,
      "timeSecond": 1491243841,
      "date": 1491243841000,
      "time": 1491243841000
    },
    "count": "100000000",
    "percentage": "60",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1491243841052
  },
  {
    "_id": {
      "timestamp": 1491243840,
      "machineIdentifier": 16363140,
      "processIdentifier": -32454,
      "counter": 8654389,
      "timeSecond": 1491243840,
      "date": 1491243840000,
      "time": 1491243840000
    },
    "count": "100000000",
    "percentage": "60",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1491243840326
  },
  {
    "_id": {
      "timestamp": 1491243837,
      "machineIdentifier": 16363140,
      "processIdentifier": -32454,
      "counter": 8654388,
      "timeSecond": 1491243837,
      "date": 1491243837000,
      "time": 1491243837000
    },
    "count": "100000000",
    "percentage": "75",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1491243837245
  },
  {
    "_id": {
      "timestamp": 1491243836,
      "machineIdentifier": 16363140,
      "processIdentifier": -32454,
      "counter": 8654387,
      "timeSecond": 1491243836,
      "date": 1491243836000,
      "time": 1491243836000
    },
    "count": "100000000",
    "percentage": "75",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1491243836591
  },
  {
    "_id": {
      "timestamp": 1491243835,
      "machineIdentifier": 16363140,
      "processIdentifier": -32454,
      "counter": 8654386,
      "timeSecond": 1491243835,
      "date": 1491243835000,
      "time": 1491243835000
    },
    "count": "100000000",
    "percentage": "75",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1491243835120
  },
  {
    "_id": {
      "timestamp": 1491243831,
      "machineIdentifier": 16363140,
      "processIdentifier": -32454,
      "counter": 8654385,
      "timeSecond": 1491243831,
      "date": 1491243831000,
      "time": 1491243831000
    },
    "count": "100000000",
    "percentage": "75",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1491243831680
  },
  {
    "_id": {
      "timestamp": 1491236210,
      "machineIdentifier": 16363140,
      "processIdentifier": -32454,
      "counter": 8654384,
      "timeSecond": 1491236210,
      "date": 1491236210000,
      "time": 1491236210000
    },
    "count": "100000000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1491236210194
  },
  {
    "_id": {
      "timestamp": 1491236197,
      "machineIdentifier": 16363140,
      "processIdentifier": -32454,
      "counter": 8654383,
      "timeSecond": 1491236197,
      "date": 1491236197000,
      "time": 1491236197000
    },
    "count": "75000000",
    "percentage": "100",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1491236197607
  },
  {
    "_id": {
      "timestamp": 1491236137,
      "machineIdentifier": 16363140,
      "processIdentifier": -32454,
      "counter": 8654382,
      "timeSecond": 1491236137,
      "date": 1491236137000,
      "time": 1491236137000
    },
    "count": "7500000",
    "percentage": "90",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1491236137069
  },
  {
    "_id": {
      "timestamp": 1491236132,
      "machineIdentifier": 16363140,
      "processIdentifier": -32454,
      "counter": 8654381,
      "timeSecond": 1491236132,
      "date": 1491236132000,
      "time": 1491236132000
    },
    "count": "7500000",
    "percentage": "90",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1491236132577
  },
  {
    "_id": {
      "timestamp": 1491236124,
      "machineIdentifier": 16363140,
      "processIdentifier": -32454,
      "counter": 8654380,
      "timeSecond": 1491236124,
      "date": 1491236124000,
      "time": 1491236124000
    },
    "count": "7500000",
    "percentage": "10",
    "schemaType": "PBR",
    "userCreated": "jepaul",
    "dateCreated": 1491236124304
  },
  {
    "_id": {
      "timestamp": 1470198497,
      "machineIdentifier": 13675390,
      "processIdentifier": 20347,
      "counter": 1947127,
      "timeSecond": 1470198497,
      "date": 1470198497000,
      "time": 1470198497000
    },
    "percentage": 100,
    "count": 10000000,
    "schemaType": "PBR"
  }
];
