<ngx-loading [show]="formBuilderService.loading.getValue()"></ngx-loading>
<div id="form-builder" class="wrapper ">

  <div class="row">
    <div class="col-12">
      <h1>{{title}}</h1>
    </div>
  </div>


  <div class="vHeight">
    <button id="toggleBtn" class="toggleBtn" (click)="toggleSideBar()">
      <fa-icon [icon]="isSidebarCollapsed ? faAngleDoubleRight : faAngleDoubleLeft"></fa-icon>
    </button>
    <div id="sideNav" class="d-none d-md-block bg-light sidebar">
      <app-side-navigation (selectedFormSection)="selectedFormSectionHandler($event)"></app-side-navigation>
    </div>

    <div id="main" >
      <div *ngIf="formBuilderService.showErrorMessages" class="row mb-3">
        <div class="col-12">
          <app-messages></app-messages>
        </div>
      </div>

      <div class="row mb-3" *ngIf="!formBuilderService.showDependencyPage.value">
        <div class="col-12 small-display">
          <app-build-edit-form [selectedFormSection]="selectedFormSection"></app-build-edit-form>
        </div>
      </div>

      <div class="row mb-3" *ngIf="formBuilderService.showDependencyPage.value">
        <div class="col-12 small-display">
          <app-dependencies></app-dependencies>
        </div>
      </div>
    </div>

  </div>

</div>
