export const MOCK_CREDIBLE_DATA = {
  'fileSubmissionId': '5a294eccb26fb0140524194c',
  'note': null,
  'cocodeModels': [
    {
      'naicCompanyCode': '60054',
      'planCodes': [
        {
          'plan_code': '10',
          'fileSubmissionState': 'NOT_CREDIBLE',
          'checked': false
        },
        {
          'plan_code': '12',
          'fileSubmissionState': 'CREDIBLE',
          'checked': true
        },
        {
          'plan_code': '13',
          'fileSubmissionState': 'CREDIBLE',
          'checked': true
        },
        {
          'plan_code': '19',
          'fileSubmissionState': 'NOT_CREDIBLE',
          'checked': false
        },
        {
          'plan_code': '98',
          'fileSubmissionState': 'NOT_CREDIBLE',
          'checked': false
        },
        {
          'plan_code': '99',
          'fileSubmissionState': 'NOT_CREDIBLE',
          'checked': false
        }
      ]
    }
  ]
};
