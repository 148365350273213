import {FileSubmissionErrorForXLSX} from '../file-dashboard/file-submission-errors/model/file-submission-error';

export const MOCK_PBR_ERROR_ONE = new FileSubmissionErrorForXLSX(100, 'mock_error_type');
export const MOCK_PBR_ERROR_TWO = new FileSubmissionErrorForXLSX(200, 'mock_error_type');
export const MOCK_PBR_ERROR_THREE = new FileSubmissionErrorForXLSX(300, 'mock_error_type');
export const MOCK_PBR_ERROR_FOUR = new FileSubmissionErrorForXLSX(400, 'mock_error_type');

export const MOCK_PBR_ALL_ERRORS_USER = [
  MOCK_PBR_ERROR_ONE,
  MOCK_PBR_ERROR_TWO,
  MOCK_PBR_ERROR_THREE,
  MOCK_PBR_ERROR_FOUR
];
