<ngx-loading [show]="loading"></ngx-loading>
<div id="filing-wizard" class="wrapper">

  <div class="row">
    <div class="col-12">
      <h1>{{title}}</h1>
    </div>
  </div>

  <div class="vHeight">
    <button id="toggleBtn" class="toggleBtn" (click)="toggleSideBar()">
      <fa-icon [icon]="isSidebarCollapsed ? faAngleDoubleRight : faAngleDoubleLeft"></fa-icon>
    </button>
    <div id="sideNav" class="d-none d-md-block bg-light sidebar">
      <app-side-navigation
        (selectedFormSection)="selectedFormSectionHandler($event)"
        (save)="saveHandler($event)"
        (saveAndValidate)="saveAndValidateHandler($event)"
        (submit)="submitHandler()"
        [isSummary]="showSummary"
        [status]="filingStatus"
        (openSummary)="openSideNavSummary('summary')">
      </app-side-navigation>
    </div>

    <div id="main" class="scrollForm on-section" #mainContainer>
      <div class="col-12" *ngIf="!showSummary">
        <app-messages></app-messages>
      </div>
      <div class="row" *ngIf="showSummary && filingBlank">
        <div class="col-12">
          <div *ngIf="isProcessing()" class="alert alert-info">
            This filing is locked until processing is completed.
          </div>
          <div *ngIf="validateClick && !isProcessing() && !isUserAdmin()" class="alert alert-info">
            Please press 'Save and Validate' prior to submitting your filing.
          </div>
          <div *ngIf="errorMessage" class="alert alert-danger">
            {{errorMessage}}
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex justify-content-between">
            <h1>Summary</h1>
            <div>
              <button class="btn btn-primary mr-2" (click)="movePrevious()">Previous</button>
              <button id="downloadPdf" type="button" class="btn btn-primary" (click)="downloadPDF()">Download</button>
            </div>
          </div>
        </div>
        <div class="col-12">
          <app-messages></app-messages>
        </div>
        <div class="col-12">
          <app-form-summary [formSection]="allSections" (closeSummary)="movePrevious()" (delete)="deleteHandler($event)" (download)="downloadAttachment($event)"></app-form-summary>
        </div>
      </div>
      <ng-container *ngIf="!showSummary&&filingBlank">
        <div class="row">
          <div class="col-12">
            <form name="onlineDataEntryForm" novalidate *ngIf="filingBlank.sections.length" autocomplete="off">
              <app-form-section *ngFor="let section of filingBlank.sections; let i = index;"
                                [section]="section"
                                [status]="filingStatus"
                                [parentId]=""
                                [index]="i"
                                [previous]="filingBlank.previous"
                                [next]="filingBlank.next"
                                (goToSection)="goTo($event)"
              >
              </app-form-section>
            </form>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="!filingBlank&&showSummary" class="col-12 mx-auto main-display text-center pt-2">
    <h2>Form is not available</h2>
  </div>
</div>
