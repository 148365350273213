import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-credible-renderer',
  templateUrl: './credible-renderer.component.html'
})
export class CredibleRendererComponent implements ICellRendererAngularComp {
  params: any;



  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  showCredible(): boolean {
    return this.params.context.componentParent.showCredible(this.params.value);
  }

  openCredible() {
    this.params.context.componentParent.openCredible(this.params.value);
  }

}
