import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CrossFieldValidationAuditComponent } from './cross-field-validation-audit/cross-field-validation-audit.component';
import { CrossFieldValidationEditComponent } from './cross-field-validation-edit/cross-field-validation-edit.component';
import { CrossFieldValidationMaintenanceComponent } from './cross-field-validation-maintenance.component';
import { ExpressionBuilderComponent } from './expression-builder/expression-builder.component';
import { TestExpressionBuilderComponent } from './test-expression-builder/test-expression-builder.component';

const crossFieldValidationMaintenanceRoutes: Routes = [
  { path: 'validationMaintenance', component: CrossFieldValidationMaintenanceComponent },
  { path: 'validationEdit', component: CrossFieldValidationEditComponent },
  { path: 'validationAudit', component: CrossFieldValidationAuditComponent },
  { path: 'expressionBuilder', component: ExpressionBuilderComponent },
  { path: 'testExpressionBuilder', component: TestExpressionBuilderComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(crossFieldValidationMaintenanceRoutes)
  ],
  exports: [RouterModule]
})
export class CrossFieldValidationMaintenanceRoutingModule { }
