import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { User } from '../../../model/user';
import { ValidationSchema } from '../../../file-validation-maintenance/model/validation-schema';
import { functions, globalFunctions } from '../shared/expression-builder-constant-values';
import { GlobalFunctions } from '../model/global-functions';
import { Functions } from '../model/functions';

@Component({
  selector: 'app-function',
  templateUrl: './function.component.html',
  styleUrls: ['./function.component.css']
})
export class FunctionComponent implements OnInit {
  title: string;
  form: FormGroup;
  selectedFunction: string = '';
  user: User;
  validationSchema: ValidationSchema;
  public event: EventEmitter<any> = new EventEmitter();
  fieldNames = [];
  selectedName: any;
  saveFlag: boolean = true;
  nameToSearch: string;
  functions: Functions[];
  functionsDescription: string = '';
  globalFunctions: GlobalFunctions[];

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
    this.title = `Select Function`;
    this.functions = functions;
    this.globalFunctions = globalFunctions;
  }

  selectFunction(field) {
    this.globalFunctions.forEach(globalFunc => {
      let description: string;
      let parameterDescription: string = '';
      let exampleTextDescription: string = '';
      let usageTextDescription: string = '';
      if (globalFunc.label === field.label) {
        description = `<b>Function Name:</b> ${globalFunc.name}` +
                      `<br>` +
                      `<b>Description:</b> ${globalFunc.description}` +
                      `<br>` +
                      `<br>` +
                      `<b>Number of Parameters:</b> ${globalFunc.parameters.length}` +
                      `<br>`
        globalFunc.parameters.sort((a, b) => a.parameterNumber - b.parameterNumber).forEach(x => {
          parameterDescription = parameterDescription + `${x.parameterNumber}. ${x.description}` + `<br>`;
        });
        globalFunc.examplesText.sort((a,b) => a.exampleTextNumber - b.exampleTextNumber).forEach(x => {
          exampleTextDescription = exampleTextDescription + `${x.exampleTextNumber}. ${x.description}` + `<br>`;
        });
        globalFunc.usageSteps.sort((a,b) => a.usageStepNumber - b.usageStepNumber).forEach(x => {
          usageTextDescription = usageTextDescription + `${x.usageStepNumber}. ${x.description}` + `<br>`;
        });
        this.functionsDescription = description + `<b>Parameter Descriptions: </b><br>` + parameterDescription + `<span style='background-color:#e9ecef; word-wrap: break-word;'><br><b>Examples: </b><br>` + exampleTextDescription + `</span>` + `<br><b>Usage Steps: </b><br>` + usageTextDescription;
      }
    });
    this.selectedFunction = `${field.value}`;
    this.saveFlag = false;
  }

  ok() {
    this.triggerEvent(this.selectedFunction);
    this.bsModalRef.hide();
  }

  triggerEvent(value: string) {
    this.event.emit({ data: value, res: 200 })
  }
}
