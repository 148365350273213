import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileSubmissionErrorsComponent } from './file-submission-errors.component';
import { FileSubmissionErrorsService } from '../../service/file-submission-errors.service';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { ExceptionsRendererComponent } from './cell-renderer/exceptions-renderer/exceptions-renderer.component';
import { LoadingModule } from 'ngx-loading';
import { DownloadCsvRendererComponent } from './cell-renderer/download-csv-renderer/download-csv-renderer.component';

@NgModule({
  imports: [
    CommonModule,
    LoadingModule,
    RouterModule,
    AgGridModule.withComponents([
      ExceptionsRendererComponent,
      DownloadCsvRendererComponent
    ])
  ],
  declarations: [
    FileSubmissionErrorsComponent,
    ExceptionsRendererComponent,
    DownloadCsvRendererComponent
  ],
  providers: [FileSubmissionErrorsService],
  exports: [FileSubmissionErrorsComponent]
})
export class FileSubmissionErrorsModule { }
