import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HandleError, HttpErrorHandler} from '../../shared/http-error-handler.service';
import { FileLevelValidation } from '../model/file-level-validation';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable()
export class FileLevelValidationService {
  readonly handleError: HandleError;
  storedSchemaType: string;
  storedNewCrossfieldValidation: boolean;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('FileLevelValidationService');
  }

  getFileLevelValidationForSchema(schemaType: string): Observable<FileLevelValidation[]> {
    const url = `${environment.apiUrl}${schemaType}/filelevelvalidations`;
    return this.httpClient.get<FileLevelValidation[]>(url)
      .pipe(catchError(this.handleError('getFileLevelValidationForSchema', null)));
  }

  insertFileLevelValidation(fileLevelValidation: FileLevelValidation[], schemaType: string): Observable<any> {
    const url = `${environment.apiUrl}${schemaType}/filelevelvalidations`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.httpClient.post<any>(url, fileLevelValidation, options)
      .pipe(catchError(this.handleError('insertFileLevelValidation', null)));

  }
}
