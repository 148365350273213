import {Component, OnInit, OnDestroy} from '@angular/core';
import {User} from '../model/user';
import {UserService} from '../service/user.service';
import {Router} from '@angular/router';
import {MessageService} from '../messages/service/message.service';
import {Subject, Subscription} from 'rxjs';
import {ExportService} from './service/export.service';
import {takeUntil} from 'rxjs/operators';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ImportDialogComponent} from './import-dialog/import-dialog.component';
import {AlertMessage} from '../messages/model/alert-message';
import { DatacallSettingService } from '../roleconfig/datacall-setting/services/datacall-setting.service';
import {  ExportOptions } from './model/export-options';
import {SnowflakeService} from './service/snowflake.service';

@Component({
  selector: 'app-import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.css']
})

export class ImportExportComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  user: User;
  loading: boolean;
  title: string;
  datacallInfoTitle: string;
  datacallInfo: string;
  collectedDataInfoTitle: string;
  collectedDataInfo: string;
  moveToSnowflakeInfoTitle: string;
  moveToSnowflakeInfo: string;
  moveToSnowflakeAlert: string;
  modalSubscriptions: Array<Subscription> = [];
  importDialogRef: BsModalRef;
  exportDataResponseFlag: boolean;
  blank: string;
  messageText: string;
  isExportInProgress: boolean;
  isSendToSnowflakeInProgress: boolean;
  exportButtonText: string;
  snowflakeButtonText: string;
  isCopyToReportingDB: boolean = false;
  exportOptions: ExportOptions[];
  isUserAssignmentExportVisible: boolean;
  disableExport = true;
  defaultExportOptions: { key: string; value: string; isChecked: boolean; }[];

  constructor(
    private userService: UserService,
    private router: Router,
    private messageService: MessageService,
    private exportService: ExportService,
    private modalService: BsModalService,
    private datacallService: DatacallSettingService,
    private snowflakeService: SnowflakeService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    this.loading = false;
    this.title = `${this.user.currentSchema.toUpperCase()} Import/Export`;
    this.datacallInfoTitle = `Datacall Information - Definitions and Validations`;
    this.datacallInfo = `This will import or export the datacall information established and provide a file to your default "downloads" folder.`;
    this.collectedDataInfoTitle = `Export Collected Data`;
    this.collectedDataInfo = `This will export all the accepted data (File Processed and NAIC Review with Errors statuses) that has been collected to the reporting database and Snowflake.`;
    this.moveToSnowflakeInfoTitle = 'Send Metadata/Settings to Snowflake';
    this.moveToSnowflakeInfo = 'This is a one time* move to snowflake of the following: the Configuration Settings, Datacall Definition Field Values, ' +
      'Crossfield Validation Information, User Assignments (if used) and Form Questions and Columns Information (if used).';
    this.moveToSnowflakeAlert = '*If parameters change this move can be performed again.';
    this.isUserAssignmentExportVisible = this.datacallService.showUserAssignmentsLink;
    this.defaultExportOptions = [
      {
        key: "configuration_settings",
        value: "Submission Configuration Settings",
        isChecked: false,
      },
      {
        key: "fields_validations_filing_blank_layout",
        value: "Data Fields, Validations and Filing Blank Layout",
        isChecked: true,
      },
      { key: "disclaimer", value: "Disclaimer", isChecked: false },
      { key: "user_assignments", value: "User Assignments", isChecked: false },
    ];
    this.exportOptions = this.getExportOptions();
    this.checkExportOptions();
    this.responseFlag();
    this.isCopyToReportingDB = this.datacallService.iscopyToReportingDBSetting;
    this.exportButtonText = this.isExportInProgress ? `Exporting Data` : `Export Data`;
    this.snowflakeButtonText = this.isSendToSnowflakeInProgress ? `Sending Data` : `Send`;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.messageService.clear();
  }

  exportJson(): void {
    this.messageService.clear();
    let requestBody =  this.exportOptions
    .filter(item => item.isChecked && item.key)
    .map(item => item.key);
    this.exportService.getSchemaForExport(this.user.currentSchema, requestBody)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        response => {
          if (response) {
            const blob = new Blob([JSON.stringify(response)], {type: 'application/json'});
            const url = URL.createObjectURL(blob);
            const element = document.createElement('a');

            element.setAttribute('href', url);
            element.setAttribute('download', this.user.currentSchema + '_export.json');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click(); // simulate click
            document.body.removeChild(element);
          }
        }
      );
  }

  openImportDialog() {
    const initialState = {user: this.user};

    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {

      })
    );

    this.importDialogRef = this.modalService.show(ImportDialogComponent, {
      initialState: initialState,
      class: 'modal-lg'
    });
  }

  exportData() {
    this.messageService.clear();
    this.isExportInProgress = true;
    this.exportButtonText = this.isExportInProgress ? `Exporting Data` : `Export Data`;
    this.messageText = `${this.user.currentSchema} - Export is processing`;
    this.messageService.add(new AlertMessage(this.messageText, 'warning'));
    this.exportService.postSchemaForExportData(this.user.currentSchema)
      .subscribe((res) => {
        this.isExportInProgress = false;
        this.exportButtonText = this.isExportInProgress ? `Exporting Data` : `Export Data`;
        this.messageService.remove(this.messageService.messages.length - 1);
        this.blank = res;
        this.messageText = `Success exporting - ${this.user.currentSchema}`;
        this.messageService.add(new AlertMessage(this.messageText, 'success'));
      });
  }

  sendToSnowflake() {
    this.messageService.clear();
    this.isSendToSnowflakeInProgress = true;
    this.snowflakeButtonText = this.isSendToSnowflakeInProgress ? `Sending Data` : `Send`;
    this.messageText = `${this.user.currentSchema} - Send to Snowflake is processing`;
    this.messageService.add(new AlertMessage(this.messageText, 'warning'));
    this.snowflakeService.postSchemaForSnowflake(this.user.currentSchema)
      .subscribe((res) => {
        this.isSendToSnowflakeInProgress = false;
        this.snowflakeButtonText = this.isSendToSnowflakeInProgress ? `Sending Data` : `Send`;
        this.messageService.remove(this.messageService.messages.length - 1);
        this.blank = res;
        this.messageText = `Success sending data to Snowflake - ${this.user.currentSchema}`;
        this.messageService.add(new AlertMessage(this.messageText, 'success'));
      });
  }

  responseFlag() {
    this.exportService.getResponseForExportData(this.user.currentSchema)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        flag => {
          this.exportDataResponseFlag = flag;
        }
      );
  }

  getExportOptions(): ExportOptions[] {
    let options = this.defaultExportOptions;
    if (!this.isUserAssignmentExportVisible) {
      options = options.filter(opt => opt.key !== 'user_assignments');
    }
    return options;
  }

  changeExportOptions(option, event) {
    this.disableExport = true;
    let isChecked = event.target.checked;
    this.exportOptions.forEach(opt => {(opt.key === option.key) ? (opt.isChecked = isChecked) : (opt.isChecked = opt.isChecked); });
    this.checkExportOptions();
  }

  checkExportOptions() {
    this.exportOptions.forEach(opt => {
      if (opt.isChecked) {
        this.disableExport = false;
      }
    });
  }

}
