import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Disclaimer} from '../../model/disclaimer';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HandleError, HttpErrorHandler} from '../../shared/http-error-handler.service';
import {catchError} from 'rxjs/operators';

@Injectable()
export class DisclaimerService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler,
  ) {
    this.handleError = httpErrorHandler.createHandleError('DisclaimerService');
  }

  getDisclaimer(schema: string): Observable<Disclaimer> {
    const url = `${environment.apiUrl}${schema}/disclaimer`;
    return this.httpClient.get<Disclaimer>(url)
      .pipe(catchError(this.handleError('getDisclaimer', null)));
  }

  agree(schema: string): Observable<any> {
    const url = `${environment.apiUrl}${schema}/disclaimer`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.httpClient.put(url, {}, options)
      .pipe(catchError(this.handleError('agree', null)));
  }

}
