<ngx-loading [show]="loading"></ngx-loading>

<div id="expressionBuilder" class="wrapper">
  <div class="row">
    <div class="col-12 page-title bottomSpacing">
      <h1>{{title}}</h1>
    </div>
  </div>

  <div *ngIf="breadcrumbs" class="row">
    <div class="col-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li *ngFor="let crumb of breadcrumbs" class="breadcrumb-item">
            <a (click)="routeToCrossFieldPage(crumb, validateIfExpressionIsNotSaved)">{{crumb.label}}</a>
          </li>
          <li class="breadcrumb-item active">
            {{breadcrumb.label}}
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <h2 class="bottomSpacing">{{validationName}}</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <app-messages></app-messages>
    </div>
  </div>

  <br>

  <div class="row" style="padding-left: 80px;">
    <div id="expression" class="col-12" style="word-wrap: break-word;"><b id="expressionLabel">Expression:</b> <i id="expressionValue">{{builtExpression}}</i>
    </div>
  </div>

  <br>

  <div class="row">
    <div class="col-4 subTitle">Enter Field Values to Test </div>
    <div class="col subTitle float-right" style="padding-left: 20rem;">Result</div>
  </div>

  <br>

  <div class="flex-container">

    <div class="flex-child borderbox" style="overflow-y: auto; overflow-x: hidden; height: 20rem; padding: 2px;">
      <form if="selectedFieldsInExpression" name="selectedFieldsInExpression"
         #selectedFieldsInExpression="ngForm" style="padding-left: 5rem;">
        <div class="row mb-3">
          <div class="col-12 pt-3">
            <ul class="list-inline" *ngFor="let fieldNames of fieldNamesInExpression">
              <li class="list-inline-item">
                <label id="label-{{fieldNames}}" class="text-right" for="{{fieldNames}}">{{fieldNames}}:</label>
              </li>
              <li class="list-inline-item pl-5">
                <input id="input-{{fieldNames}}" type="text" class="form-control" name="{{fieldNames}}"
                  (keyup)="enteredFieldNameValue(selectedFieldsInExpression)" ngModel />
              </li>
            </ul>
          </div>
        </div>
      </form>
    </div>

    <div id="resultBoxDiv" class="flex-child borderbox" [ngClass]=getClassNamesForResultsPanel()>
      <div class="blue pl-3 pt-3" id="resultMessageDiv">
        <p id="result" *ngIf="color === 1; else redColor" style="color: #155724" [innerHtml] ="messageText | noSanitize">{{messageText}}</p>
      </div>
    </div>

  </div>

  <div class="row pl-5 pt-3">
    <button id="test" type="submit" class="btn btn-primary col-1" (click)="testValues(unableToRunRule)"> Test </button>
  </div>

</div>

<ng-template #redColor id="resultDiv">
<p id="result" *ngIf="color === 2;" style="color: #721c24" [innerHtml] ="messageText | noSanitize">{{messageText}}</p>
</ng-template>

<ng-template #unableToRunRule>
  <div class="modal-header">
    <h3 class="modal-title">Unable to Run Rule</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Details below: <br> {{exception}}
  </div>
  <div class="modal-footer">
    <button id="ok" type="button" class="btn btn-primary btn-sm" (click)="modalRef.hide()">Ok</button>
  </div>
</ng-template>

<ng-template #validateIfExpressionIsNotSaved>
  <div class="modal-header">
    <h3 class="modal-title">{{title}}</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    If you have not set your expression in expression builder and saved your validation then your changes will be lost. Do you want to proceed?
  </div>
  <div class="modal-footer">
    <button id="yes" type="button" class="btn btn-link btn-sm" (click)="routeToPage()">Yes</button>
    <button id="no" type="button" class="btn btn-primary" (click)="modalRef.hide()">No</button>
  </div>
</ng-template>