<!--
  ~ Copyright 2017 National Association of Insurance Commissioners
  -->

<ngx-loading [show]="loading"></ngx-loading>
<div id="file-admin" class="wrapper">
  <div class="row page-title">
    <div class="col-12">
      <h1>{{title}}</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <app-messages></app-messages>
    </div>
  </div>

  <div *ngIf="fileSubmissionKeys" class="row mb-3">
    <div class="col-12">
      <ul class="list-inline">
        <li class="list-inline-item" *ngFor="let submissionKey of fileSubmissionKeys.keys">
          <label for="input:{{submissionKey.name}}">{{submissionKey.name | uppercase | noUnderscore}}</label>
          <input id="input:{{submissionKey.name}}" type="text" class="form-control"
                 [(ngModel)]="submissionKey.values[0]"
                 required/>
        </li>
        <li class="list-inline-item">
          <button id="btn:search" type="button" class="btn btn-primary" (click)="getFileList()"><i
            class="fa fa-search"></i> Search
          </button>
        </li>
      </ul>
    </div>
  </div>

  <div *ngIf="rowData.length > 0">
    <ag-grid-angular
      style="width: 100%; height: 400px;"
      #agGrid
      id="fileAdminGrid"
      class="ag-theme-balham"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [rowData]="rowData"
      [context]="context"
      [frameworkComponents]="frameworkComponents"
      pagination="true"
      [paginationPageSize]="paginationPageSize">
    </ag-grid-angular>
  </div>
</div>

<ng-template #noResults>
  <div class="row">
    <div class="col-12 text-center"><h3 *ngIf="searched">No Results Found</h3></div>
  </div>
</ng-template>



