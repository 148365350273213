import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HandleError, HttpErrorHandler} from '../shared/http-error-handler.service';
import {Roleconfig} from './roleconfig';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleconfigService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('RoleconfigService');
  }

  getRoleconfigs(schema: string): Observable<any> {
    const url = `${environment.apiUrl}${schema}/roleconfig`;
    const headers = new HttpHeaders( {'Accept': 'application/json'} );
    const options = {headers: headers};
    return this. httpClient.get(url, options)
      .pipe(catchError(this.handleError('getRoleconfigs', null)));
  }

  putRoleconfig(schema: string, roleconfig: Roleconfig): Observable<Roleconfig> {
    const url = `${environment.apiUrl}${schema}/roleconfig`;
    const headers = new HttpHeaders( {'Content-Type': 'application/json'} );
    const options = {headers: headers};
    return this.httpClient.put(url, roleconfig, options)
      .pipe(catchError(this.handleError('putRoleconfig', null)));
  }

  createUserRoleconfig(schema: string, userLdapRole: string): Observable<Roleconfig> {
    const userRoleconfig = new Roleconfig();
    userRoleconfig.role = userLdapRole;
    userRoleconfig.systemRole = 'user';
    return this.putRoleconfig(schema, userRoleconfig);
  }

  createAdminRoleconfig(schema: string, adminLdapRole: string): Observable<Roleconfig> {
    const adminRoleconfig = new Roleconfig();
    adminRoleconfig.role = adminLdapRole;
    adminRoleconfig.systemRole = 'admindatacall';
    return this.putRoleconfig(schema, adminRoleconfig);
  }
}
