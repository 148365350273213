import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private oauthService: OAuthService,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (environment.mock) {
      return true;
    } else {
      if (this.oauthService.hasValidIdToken()) {
        return true;
      }
      this.router.navigate(['']);
      return false;
    }
  }

}
