import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormControl, FormControlDirective, FormControlName, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { UserService } from '../../../service/user.service';
import { ConstantValueComponent } from '../constant-value/constant-value.component';
import { FieldSelectionComponent } from '../field-selection/field-selection.component';
import { FunctionComponent } from '../functions/function.component';
import { AndOrConditions } from '../model/and-or-conditions';
import { Functions } from '../model/functions';
import { GlobalFunctions } from '../model/global-functions';
import { Operaters } from '../model/operators';
import { ExpressionBuilderService } from '../services/expression-builder.service';
import { andOrConditions, functions, globalFunctions, operators } from '../shared/expression-builder-constant-values';

const originFormControlNgOnChanges = FormControlDirective.prototype.ngOnChanges;
FormControlDirective.prototype.ngOnChanges = function () {
  this.form.nativeElement = this.valueAccessor._elementRef.nativeElement;
  return originFormControlNgOnChanges.apply(this, arguments);
};

const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function () {
  const result = originFormControlNameNgOnChanges.apply(this, arguments);
  this.control.nativeElement = this.valueAccessor._elementRef.nativeElement;
  return result;
};

@Component({
  selector: 'app-expression-set',
  templateUrl: './expression-set.component.html',
  styleUrls: ['./expression-set.component.css']
})
export class ExpressionSetComponent implements OnInit {

  @Input() expression: FormGroup;
  @Input() expressionBuilder: FormGroup;
  @Input() even: number;
  @Input() num: number;
  bsModalRef: BsModalRef;
  modalSubscriptions: Subscription[];
  enteredExpression: string;
  colorIndex: number = 1;
  idIndex: number = 0;
  andOrConditions: AndOrConditions[];
  operators: Operaters[];
  functions: Functions[];
  globalFunctions: GlobalFunctions[];
  disableIfRdcAdmin: boolean;
  deleteCIndex: number;
  disableConditionSet: boolean = false;
  form: FormGroup;
  disableCondition: boolean = false;
  expressionArray: any;
  errorMessageText: string;
  deleteConditionButtonText: string;

  constructor(
    private modalService: BsModalService,
    public expressionBuilderService: ExpressionBuilderService,
    private router: Router,
    private userService: UserService
  ) {
    this.modalSubscriptions = [];
    this.colorIndex = this.expressionBuilderService.colorIndex;
    this.expressionBuilderService.colorIndex++;
    this.idIndex = this.expressionBuilderService.idIndex;
    this.idIndex++;
  }

  ngOnInit() {
    this.form = this.expressionBuilder;
    this.disableIfRdcAdmin = this.userService.getStoredUser().isRdcAdmin;
    this.andOrConditions = andOrConditions;
    this.operators = operators;
    this.functions = functions;
    this.globalFunctions = globalFunctions;
    this.setButtonValidations();
  }

  setButtonValidations() {
    if (!this.expression.contains('falseAction') && !this.expression.contains('trueAction')) {
      this.disableConditionSet = true;
    }
    this.expressionArray = ((this.expression['controls'].expressions) as FormArray).value.filter(x => x.expression !== undefined);
    if ((this.expression.contains('falseAction') && this.expression.contains('trueAction'))) {
      if (this.expressionArray.length !== 1) {
        this.disableCondition = true;
      } else {
        this.disableCondition = false;
      }
    } else {
      this.disableCondition = true;
    }
  }

  get expressions() {
    return this.expression.get('expressions') as FormArray;
  }

  addFieldSelection(index: number): void {
    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        this.unsubscribeModal();
      })
    );
    this.bsModalRef = this.modalService.show(FieldSelectionComponent, { class: 'modal-lg' });
    this.bsModalRef.content.event.subscribe(res => {
      const isFieldSelection: boolean = true;
      this.setValueInConditionAtCurser(res.data, index, isFieldSelection);
    });
  }

  addConstantValue(index: number): void {
    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        this.unsubscribeModal();
      })
    );
    this.bsModalRef = this.modalService.show(ConstantValueComponent, { class: 'modal-lg' });
    this.bsModalRef.content.event.subscribe(res => {
      const isFieldSelection: boolean = false;
      this.setValueInConditionAtCurser(res.data, index, isFieldSelection);
    });
  }

  addOperator(value, index): void {
    const isFieldSelection: boolean = false;
    this.setValueInConditionAtCurser(value, index, isFieldSelection);
  }

  addFunctionFromModal(index: number): void {
    this.modalSubscriptions.push(
      this.modalService.onHidden.subscribe(() => {
        this.unsubscribeModal();
      })
    );
    this.bsModalRef = this.modalService.show(FunctionComponent, { class: 'modal-xl' });
    this.bsModalRef.content.event.subscribe(res => {
      const isFieldSelection: boolean = false;
      let nativeElement = (<any>this.expressions.at(index).get('expression')).nativeElement;
      let initialCurserPosition = nativeElement.selectionStart;
      let formElement = this.setValueInConditionAtCurser(res.data, index, isFieldSelection);
      this.setSelectionInCondition(formElement, initialCurserPosition, res.data);
    });
  }

  addFunction(index: number, selectedFunction: any) {
    const isFieldSelection: boolean = false;
    let nativeElement = (<any>this.expressions.at(index).get('expression')).nativeElement;
    let initialCurserPosition = nativeElement.selectionStart;
    let formElement = this.setValueInConditionAtCurser(selectedFunction, index, isFieldSelection);
    this.setSelectionInCondition(formElement, initialCurserPosition, selectedFunction);
  }

  setValueInConditionAtCurser(value, index, isFieldSelection) {
    let nativeElement = (<any>this.expressions.at(index).get('expression')).nativeElement;
    let initialCurserPosition = nativeElement.selectionStart;
    let finalCursorPosition = nativeElement.selectionEnd;
    if (initialCurserPosition !== finalCursorPosition) {
      let valueToInsert = this.checkForFunctionSyntax(value, nativeElement, initialCurserPosition, isFieldSelection);
      nativeElement.value = nativeElement.value.substr(0, initialCurserPosition) + ` ${valueToInsert} ` + nativeElement.value.substr(finalCursorPosition, nativeElement.value.length);
    } else {
      let valueToInsert = this.checkForFunctionSyntax(value, nativeElement, initialCurserPosition, isFieldSelection);
      nativeElement.value = nativeElement.value.substr(0, initialCurserPosition) + ` ${valueToInsert} ` + nativeElement.value.substr(initialCurserPosition, nativeElement.value.length);
    }
    this.expressions.at(index).get('expression').patchValue(`${nativeElement.value}`);
    finalCursorPosition = nativeElement.value.length;
    nativeElement.focus();
    return nativeElement;
  }

  checkForFunctionSyntax(value, nativeElement, initialCurserPosition, isFieldSelection): string {
    let valueToBeInserted: string;
    if (isFieldSelection) {
      let testing: string = nativeElement.value.substr(0, initialCurserPosition);
      let isNullFunction = testing.replace(/\s\s+/g, '').trim().endsWith('IsNull(');
      if (isNullFunction) {
        valueToBeInserted = `data, '${value}'`
      } else {
        valueToBeInserted = `data['${value}']`
      }
      return valueToBeInserted;
    } else {
      return value;
    }
  }

  setSelectionInCondition(nativeElement, initial: number, selectedFunction: any) {
    let startingPosition: number;
    let endingPosition: number;
    this.functions.forEach(func => {
      if (selectedFunction === func.value) {
        let word = func.selectionWord;
        startingPosition = initial + func.setSelection;
        endingPosition = startingPosition + String(word).length;
      }
    });
    if (typeof (nativeElement.selectionStart) != "undefined") {
      nativeElement.focus();
      nativeElement.selectionStart = startingPosition;
      nativeElement.selectionEnd = endingPosition;
      return true;
    }
  }

  getClassNames(): string[] {
    if (this.colorIndex % 2 === 0) {
      return ['fieldSetBlue']
    } else {
      return ['fieldSetGrey']
    }
  }

  private unsubscribeModal(): void {
    this.modalSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.modalSubscriptions = [];
  }

  addCondition() {
    this.idIndex = this.idIndex + 1;
    this.expressions.push(new FormGroup({
      expression: new FormControl('')
    }));
    this.expressionArray = ((this.expression['controls'].expressions) as FormArray).value.filter(x => x.expression !== undefined);
    if ((this.expression.contains('falseAction') && this.expression.contains('trueAction'))) {
      if (this.expressionArray.length !== 1) {
        this.disableCondition = true;
      } else {
        this.disableCondition = false;
      }
    } else {
      this.disableCondition = true;
    }
  }

  deleteCondition(index: number, template: TemplateRef<any>) {
    this.errorMessageText = 'Do you want to proceed with the delete of the condition.';
    this.deleteConditionButtonText = 'Delete Condition';
    this.deleteCIndex = index;
    let expressionArray = ((this.expression['controls'].expressions) as FormArray).value.filter(x => x.expression !== undefined);
    if ((this.expression.contains('falseAction') && this.expression.contains('trueAction'))) {
      if (expressionArray.length !== 1) {
        this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
      }
    } else {
      if (expressionArray.length === 1) {
        this.errorMessageText = 'Do you want to proceed with the deletion of the condition set.';
        this.deleteConditionButtonText = 'Delete Condition Set';
      }
      this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
    }
  }

  deleteC() {
    this.expressions.removeAt(this.deleteCIndex);
    this.setButtonValidations();
    this.expressionArray = ((this.expression['controls'].expressions) as FormArray).value.filter(x => x.expression !== undefined);
    if ((!this.expression.contains('falseAction') && !this.expression.contains('trueAction'))) {
      if (this.expressionArray.length === 0) {
        this.expression.removeControl('andOrCondition');
        this.expression.removeControl('expressions');
      }
    }
    this.bsModalRef.hide();
  }

  addConditionSet() {
    this.expressionBuilderService.idIndex = this.idIndex;
    this.idIndex = this.idIndex + 1;
    this.expressions.push(new FormGroup({
      andOrCondition: new FormControl('||'),
      expressions: new FormArray([new FormGroup({
        expression: new FormControl('')
      })]
      )
    }));
    this.expressionArray = ((this.expression['controls'].expressions) as FormArray).value.filter(x => x.expression !== undefined);
  }

  deleteConditionSet(template: TemplateRef<any>) {
    if (!this.expression.contains('falseAction') && !this.expression.contains('trueAction')) {
      this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
    }
  }

  deleteCS() {
    this.expression.removeControl('andOrCondition');
    this.expression.removeControl('expressions');
    this.bsModalRef.hide();
  }

}
