import { Id } from '../../model/id';

export class FilingDate {
  _id: Id;
  dataYear: number;
  dateStart: Date;
  dateEnd: Date;
  userAdded: string;
  dateAdded: Date;
  userLastModified: string;
  dateLastModified: Date;
}
