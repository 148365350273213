<button type="button" class="btn btn-primary btn-sm" (click)="reload(confirmReload)">Reload</button>

<ng-template #confirmReload>
    <div class="modal-header">
      <h3 class="modal-title">Perform Reload</h3>
      <button type="button" class="close pull-right" aria-label="Close" (click)="confirmReloadRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Reloading will discard all data and treat the file as though it was just now submitted by the user. Do you wish to proceed?
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-link btn-sm" (click)="cancelReload()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="reloadConfirm()">Proceed</button>
    </div>
  </ng-template>
