import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FilingDate } from '../model/filing-date';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HandleError, HttpErrorHandler} from '../../shared/http-error-handler.service';
import {catchError} from 'rxjs/operators';

@Injectable()
export class FilingDatesService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('AggregationMaintenanceService');
  }

  getDates(schemaType: string): Observable<any> {
    const url = `${environment.apiUrl}${schemaType}/schemaDates`;
    return this.httpClient.get(url)
      .pipe(catchError(this.handleError('getDates', null)));
  }

  saveDate(schemaType: string, filingDate: FilingDate): Observable<any> {
    const url = `${environment.apiUrl}${schemaType}/schemaDates`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.httpClient.post(url, filingDate, options)
      .pipe(catchError(this.handleError('saveDate', null)));
  }

}
