import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {FilingBlankLayout} from '../model/filing-blank-layout';
import {environment} from '../../../environments/environment';



import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HandleError, HttpErrorHandler} from '../../shared/http-error-handler.service';
import {catchError} from 'rxjs/operators';

@Injectable()
export class FilingBlankLayoutService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('FilingBlankLayoutService');
  }

  getFilingBlankLayout(schema: string, current?: boolean): Observable<FilingBlankLayout> {
    const url = `${environment.apiUrl}${schema}/filingblanklayout?current=${current || true}`;
    return this.httpClient.get<FilingBlankLayout>(url)
      .pipe(catchError(this.handleError('getFilingBlankLayout', null)));
  }

  postFilingBlankLayout(schema: string, filingBlankLayout: any): Observable<any> {
    const url = `${environment.apiUrl}${schema}/filingblanklayout`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {headers: headers};
    return this.httpClient.post(url, filingBlankLayout, options)
      .pipe(catchError(this.handleError('postFilingBlankLayout', null)));
  }

  putFilingBlankLayout(schema: string, filingBlankLayout: any): Observable<any> {
    const url = `${environment.apiUrl}${schema}/filingblanklayout`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {headers: headers};
    return this.httpClient.put(url, filingBlankLayout, options)
      .pipe(catchError(this.handleError('putFilingBlankLayout', null)));
  }
}
