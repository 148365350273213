<ngx-loading [show]="loading"></ngx-loading>
<div id="cross-field-validation-maintenance" class="wrapper">
  <div>

    <div class="row">
      <div class="col-12">
        <h1>{{ title }}</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <app-messages></app-messages>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-6">
        <button id="btn:addNew" class="btn btn-primary" (click)="addNewCrossFieldValidation()" type="button">
          <i class="fa fa-plus"> Add New Validation</i><span class="sr-only">Add Validation</span>
        </button>
      </div>
      <div class="col-6" style="margin-top: -40px">
        <div class="d-flex flex-row-reverse" style="flex: auto">
          <div class="p-2">
            <div *ngIf="crossFieldValidations" class="row mb-3">
              <div class="col-12">
                <ul class="list-inline">
                  <li class="list-inline-item">
                    <label>Name</label>
                    <input type="text" class="form-control" [(ngModel)]="nameToSearch" required />
                  </li>
                  <li class="list-inline-item">
                    <button id="btn:search" type="button" class="btn btn-primary"
                      (click)="getValidationList(nameToSearch)"><i class="fa fa-search"></i> Search
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div *ngIf="crossFieldValidations" class="row mb-3">
        <div class="col-12">
          <ag-grid-angular style="width: 100%; height: 400px;" #agGrid id="crossFieldValidationsGrid"
            class="ag-theme-balham" [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [rowData]="rowData"
            [context]="context" [frameworkComponents]="frameworkComponents" (gridReady)="onGridReady($event)">
          </ag-grid-angular>
        </div>
      </div>
    </div>

  </div>
  
</div>