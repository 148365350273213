import { FormHeaders } from './form-headers';
import { FormQuestion } from './form-question';

export class FormSection {
  key?: string;
  name?: string;
  displayKey?: string;
  description?: string;
  sectionNumber?: number;
  headings?: FormHeaders[];
  sections?: FormSection[];
  questions?: FormQuestion[];
  active?: boolean;

  constructor(key?: string, sectionNumber?: number, name?: string, displayKey?: string, description?: string, sections?: FormSection[], questions?: FormQuestion[], headers?: FormHeaders[]) {
    this.key = key || '';
    this.sectionNumber = sectionNumber || 1;
    this.displayKey = displayKey || '';
    this.name = name || '';
    this.description = description || '';
    this.sections = sections || [];
    this.questions = questions || [];
    this.headings = headers || [];
    this.active = false;
  }
}
