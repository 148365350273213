<span *ngIf="params.value.fileLevelExceptionCount !== undefined && params.value.fileLevelExceptionCount === 0">0</span>
<span *ngIf="params.value.fileLevelExceptionCount !== undefined && params.value.fileLevelExceptionCount > 0" class="span-link" (click)="goToFileAdminFileLevelExceptions(params.value.fileLevelExceptionTypes, template)">{{params.value.fileLevelExceptionCount | number: 0 }}</span>


<ng-template #template>
    <div class="modal-header">
        <h3 class="modal-title">File Level Validations</h3>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        File contains {{modalService.config.initialState.expectedValue}} number of records and should have {{modalService.config.initialState.actualValue}} number of records.
      </div>
      <div class="modal-footer">
        <button id="no" type="button" class="btn btn-primary" (click)="modalRef.hide()">Ok</button>
      </div>
</ng-template>