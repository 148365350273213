import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AlertModule } from "ngx-bootstrap/alert";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { LoadingModule } from "ngx-loading";
import { MessagesModule } from "../messages/messages.module";
import { MessageService } from "../messages/service/message.service";
import { FileLevelValidationRoutingModule } from "./file-level-validation-routing.module";
import { FileLevelValidationService } from "./services/file-level-validation.service";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    MessagesModule,
    FileLevelValidationRoutingModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    LoadingModule
  ],
  declarations: [],
  providers: [
    MessageService,
    FileLevelValidationService
  ],
  exports: []
})
export class FileLevelValidationModule {
}
