import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HandleError, HttpErrorHandler} from '../../../shared/http-error-handler.service';
import {catchError} from 'rxjs/operators';

@Injectable()
export class ErrorsService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('AggregationMaintenanceService');
  }

  getErrorList(schemaType: string, fileSubmissionId: string, validationCategory: string): Observable<any[]> {
    let params = new HttpParams().set('validationCategory', validationCategory);
    let options = {params: params};
    return this.httpClient.get<any[]>(`${environment.apiUrl}${schemaType}/fileadmin/fileSubmissions/${fileSubmissionId}/documentErrors`, options)
      .pipe(catchError(this.handleError('', [])));
  }
}
