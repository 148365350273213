export class FileSubmissionError {
  errorMessage: string;
  lineNumbers: string[];

  constructor(errorMessage: string, lineNumbers: string[]) {
    this.errorMessage = errorMessage;
    this.lineNumbers = lineNumbers;
  }
}

export class FileSubmissionErrorForXLSX {
  errorCount: number;
  errorType: string;

  constructor(errorCount: number, errorType: string) {
    this.errorCount = errorCount;
    this.errorType = errorType;
  }
}