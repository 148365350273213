import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AggregationMaintenanceModule} from './aggregation-maintenance/aggregation-maintenance.module';
import {AppComponent} from './app.component';
import {SubmissionKeysService} from './file-dashboard/service/submission-keys.service';
import {FilingDatesModule} from './filing-dates/filing-dates.module';
import {NavModule} from './nav/nav.module';
import {ArrayToStringPipe} from './pipes/array-to-string.pipe';
import {PipesModule} from './pipes/pipes.module';
import {RerunValidationsModule} from './rerun-validations/rerun-validations.module';
import {BreadcrumbService} from './service/breadcrumb.service';
import {UserService} from './service/user.service';
import {AppRoutingModule} from './app-routing.module';
import {SchemaSelectionModule} from './schema-selection/schema-selection.module';
import {FileDashboardModule} from './file-dashboard/file-dashboard.module';
import {DisclaimerModule} from './disclaimer/disclaimer.module';
import {LandingModule} from './landing/landing.module';
import {FileAdminModule} from './file-admin/file-admin.module';
import {FileValidationMaintenanceModule} from './file-validation-maintenance/file-validation-maintenance.module';
import {
  CrossFieldValidationMaintenanceModule
} from './cross-field-validation-maintenance/cross-field-validation-maintenance.module';
import {SubmissionLevelTolerancesModule} from './submission-level-tolerances/submission-level-tolerances.module';
import {UnCamelCasePipe} from './pipes/un-camel-case.pipe';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RequestInterceptor} from './shared/request.interceptor';
import {HttpErrorHandler} from './shared/http-error-handler.service';
import {FilingBlankLayoutModule} from './filing-blank-layout/filing-blank-layout.module';
import {FilingSchemaModule} from './filing-schema/filing-schema.module';
import {FilingValidationModule} from './filing-validation/filing-validation.module';
import {LoadingModule} from 'ngx-loading';
import {MessageService} from './messages/service/message.service';
import {MainComponent} from './main/main.component';
import {AuthGuardService} from './service/auth-guard.service';
import {NcuiModule} from 'common-ui-components';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OAuthModule} from 'angular-oauth2-oidc';
import {environment} from '../environments/environment';
import {DateRendererComponent} from './cell-renderer/date-renderer/date-renderer.component';
import {NumberRendererComponent} from './cell-renderer/number-renderer/number-renderer.component';
import {SchemaSelectionService} from './service/schema-selection.service';
import {RoleconfigService} from './roleconfig/roleconfig.service';
import {RoleconfigModule} from './roleconfig/roleconfig.module';
import {ImportExportModule} from './import-export/import-export.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UserAssignmentModule} from './user-assignment/user-assignment.module';
import {DatacallSettingModule} from './roleconfig/datacall-setting/datacall-setting.module';
import {OnlineDataEntryModule} from './online-data-entry/online-data-entry.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {DatacallSummaryModule} from './datacall-summary/datacall-summary.module';
import {FormBuilderModule} from './form-builder/form-builder.module';
import {FilingSearchModule} from './online-data-entry/filing-search/filing-search.module';
import { FileLevelValidationComponent } from './file-level-validation/file-level-validation.component';
import { FileLevelValidationModule } from './file-level-validation/file-level-validation.module';
import { MessagesModule } from './messages/messages.module';
import { UserNotAllowedModule } from './user-not-allowed/user-not-allowed.module';
import { SessionService } from './service/session.service';
import { UserSessionInactivePopupComponent } from './user-session-inactive-popup/user-session-inactive-popup.component';
import { NgIdleModule } from '@ng-idle/core';
import { CommonConfirmationModalComponent } from './common-confirmation-modal/common-confirmation-modal.component';
import { CommonConfirmationModalModule } from './common-confirmation-modal/common-confirmation-modal.module';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    DateRendererComponent,
    NumberRendererComponent,
    FileLevelValidationComponent,
    UserSessionInactivePopupComponent,
    CommonConfirmationModalComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgIdleModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiUrl, environment.financialApiUrl, environment.correspondenceApiUrl],
        sendAccessToken: true
      }
    }),
    LoadingModule,
    AppRoutingModule,
    PipesModule,
    NcuiModule,
    NavModule,
    DisclaimerModule,
    SchemaSelectionModule,
    FontAwesomeModule,
    LandingModule,
    FileDashboardModule,
    FileAdminModule,
    FileValidationMaintenanceModule,
    CrossFieldValidationMaintenanceModule,
    RerunValidationsModule,
    SubmissionLevelTolerancesModule,
    FilingDatesModule,
    AggregationMaintenanceModule,
    FilingBlankLayoutModule,
    UserNotAllowedModule,
    FilingSchemaModule,
    UserAssignmentModule,
    FilingValidationModule,
    RoleconfigModule,
    ImportExportModule,
    DatacallSettingModule,
    OnlineDataEntryModule,
    DatacallSummaryModule,
    FormBuilderModule,
    FilingSearchModule,
    FileLevelValidationModule,
    MessagesModule,
    CommonConfirmationModalModule
  ],
  providers: [
    UserService,
    SubmissionKeysService,
    BreadcrumbService,
    UnCamelCasePipe,
    ArrayToStringPipe,
    HttpErrorHandler,
    MessageService,
    AuthGuardService,
    SchemaSelectionService,
    RoleconfigService,
    SessionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
  ],
  exports: [],
  bootstrap: [AppComponent],
  entryComponents: [UserSessionInactivePopupComponent]
})
export class AppModule {

}