<div id="addValidationEdit" class="wrapper" *ngIf="crossFieldValidation">
  <div class="row">
    <div class="col-12">
      <h1>{{title}}</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li *ngFor="let crumb of breadcrumbs" class="breadcrumb-item">
            <a (click)="routeToCrossFieldPage(crumb, validateIfExpressionIsNotSaved)">{{crumb.label}}</a>
          </li>
          <li class="breadcrumb-item active">
            {{breadcrumb.label}}
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <app-messages></app-messages>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
          <form name="validationEditForm" (ngSubmit)="save()" #validationEditForm="ngForm">
        <div class="form-group">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="nameLabel">Name <span *ngIf = "newCrossFieldValidation" class="text-danger">*</span></span>
            </div>
            <input type="text" class="form-control" (keyup)="isNameAlreadyInList(crossFieldValidation.name, name)" title="Name" id="name" name="name" [(ngModel)]="crossFieldValidation.name" [disabled]="!newCrossFieldValidation" #name="ngModel" required notOnlyWhitespace/>
            <div *ngIf="(name.invalid && (name.dirty || name.touched)) || nameAlreadyExist" class="input-group-append">
              <div *ngIf="!nameAlreadyExist">
                  <span *ngIf="name.errors['required']" class="input-group-text text-danger">
                      <small>Name is required</small>
                    </span>
                    <span *ngIf="name.errors['whitespace']" class="input-group-text text-danger">
                      <small>Cannot be whitespace</small>
                    </span>
              </div>
              <div *ngIf="nameAlreadyExist">
                  <span class="input-group-text text-danger">
                      <small>Name already exists</small>
                    </span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="description">Description</span>
            </div>
            <input type="text" class="form-control" title="Description"
                   aria-describedby="description" name="description" #description="ngModel"
                   [(ngModel)]="crossFieldValidation.description" notOnlyWhitespace/>
            <div *ngIf="description.invalid && (description.dirty || description.touched)" class="input-group-append">
              <span *ngIf="description.errors['whitespace']" class="input-group-text text-danger">
                <small>Cannot be whitespace</small>
              </span>
            </div>
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="error-message">Error Message</span>
            </div>
            <input type="text" class="form-control" title="Error Message"
                   aria-describedby="error_message" name="error_message" #error_message="ngModel"
                   [(ngModel)]="crossFieldValidation.errorMessage" notOnlyWhitespace/>
            <div *ngIf="error_message.invalid && (error_message.dirty || error_message.touched)" class="input-group-append">
              <span *ngIf="error_message.errors['whitespace']" class="input-group-text text-danger">
                <small>Cannot be whitespace</small>
              </span>
            </div>
          </div>

          <div *ngIf="isOnlineDataEntry" class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="level-message">Validation Level<span *ngIf = "newCrossFieldValidation" class="text-danger">*</span></span>
            </div>
            <select class="rounded" aria-describedby="validation_level" name="validation_level" #validation_level="ngModel"
                    [(ngModel)]="crossFieldValidation.validationLevel" required notOnlyWhitespace>
              <option [ngValue]="null" disabled>Select Level</option>
              <option *ngFor="let level of validationLevels" [ngValue]=level>{{level}}</option>
            </select>
            <div *ngIf="validation_level.invalid && (validation_level.dirty || validation_level.touched)" class="input-group-append">
              <span *ngIf="validation_level.errors['required'] || validation_level.errors['whitespace']" class="input-group-text text-danger">
                <small>Validation Level is required</small>
              </span>
            </div>
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="expression">Expression</span>
            </div>
            <textarea type="text" class="form-control" title="Expression"
                   aria-describedby="expression" name="expression" #expression="ngModel"
                   [(ngModel)]="crossFieldValidation.expression" [focuMe]="isFocus" (keyup)="changeExpression()" rows="5" notOnlyWhitespace></textarea>
            <div *ngIf="expression.invalid && (expression.dirty || expression.touched)" class="input-group-append">
              <span *ngIf="expression.errors['whitespace']" class="input-group-text text-danger">
                <small>Cannot be whitespace</small>
              </span>
            </div>
            <div *ngIf="isFocus" class="input-group-append">
              <span class="input-group-text text-danger">
                <small>Expression Changed, Click Save.</small>
              </span>
            </div>
          </div>
          <div class="checkbox">
            <label><input type="checkbox" [(ngModel)]="crossFieldValidation.active" aria-label="required" title="Active" name="active"> Active</label>
            <label><input type="checkbox" [(ngModel)]="crossFieldValidation.publicViewable" aria-label="required" title="Public" name="public"> Public</label>
          </div>
        </div>
        <div *ngIf = "newCrossFieldValidation" class="mb-3">
          <span class="text-danger">*</span> = Required
        </div>
        <div>
          <button *ngIf="!saveFlag" type="submit" class="btn btn-primary" [disabled]="!validationEditForm.form.valid || !validationEditForm.form.dirty || nameAlreadyExist"><i class="fa fa-floppy-o"></i> Save</button>
          <button *ngIf="saveFlag" type="submit" class="btn btn-primary" [disabled]="!validationEditForm.form.valid || nameAlreadyExist"><i class="fa fa-floppy-o"></i> Save</button>
          <button id="expressionBuilder" type="button" class="btn btn-primary float-right" [disabled]="!validationEditForm.form.valid || nameAlreadyExist" (click)="addEditExpression()"> {{expressionButtonText}} </button>
        </div>
      </form>
    </div>
  </div>

</div>

<ng-template #validateIfExpressionIsNotSaved>
  <div class="modal-header">
    <h3 class="modal-title">{{title}}</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Do you wish to proceed without saving changes?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link btn-sm" (click)="validateExpressionSave()">Yes</button>
    <button type="button" class="btn btn-primary" (click)="modalRef.hide()">No</button>
  </div>
</ng-template>
