import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';




import { FileSubmissionTolerance } from '../model/file-submission-tolerance';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HandleError, HttpErrorHandler} from '../../shared/http-error-handler.service';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable()
export class SubmissionLevelTolerancesService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('SubmissionLevelTolerancesService');
  }


  getAllFileSubmissionTolerancesBySchemaType(schemaType: string): Observable<FileSubmissionTolerance[]> {
    const url = `${environment.apiUrl}${schemaType}/fileSubmissionTolerances`;

    return this.httpClient.get<FileSubmissionTolerance[]>(url)
      .pipe(catchError(this.handleError('getAllFileSubmissionTolerancesBySchemaType', [])));
  }

  updateFileSubmissionTolerance(newTolerance: FileSubmissionTolerance): Observable<FileSubmissionTolerance[]> {
    const url = `${environment.apiUrl}${newTolerance.schemaType}/fileSubmissionTolerances`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };

    return this.httpClient.post<FileSubmissionTolerance[]>(url, newTolerance, options)
      .pipe(catchError(this.handleError('updateFileSubmissionTolerance', [])));
  }

}
