/*
 * Copyright 2018 National Association of Insurance Commissioners
 */

import {CrossFieldJsValidation} from '../filing-validation/model/cross-field-js-validation';

export function createMockValidations(amount: number): CrossFieldJsValidation[] {
  const validations: CrossFieldJsValidation[] = [];
  for (let i = 1; i <= amount; i++) {
    validations.push({
      name: `name ${i}`,
      errorMessage: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc eget dui at ex viverra ultrices non sit amet nibh. Morbi sit amet vulputate purus, id.`,
      description: `description ${i}`,
      expression: `IF ((StrToNumber([issue_date]) - StrToNumber([birth_date])) = [issue_age]) And (StrToNumber([age_basis]) > 1) THEN False ELSE True`,
      active: true,
      publicViewable: true,
      schemaReference: `schema reference ${i}`,
      validationLevel: `validation level ${i}`
    });
  }
  return validations;
}
