import { NgModule } from '@angular/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SchemaSelectionComponent } from './schema-selection.component';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SchemaSelectionRoutingModule } from './schema-selection-routing.module';
import {MessagesModule} from '../messages/messages.module';
import { DatacallCreationComponent } from './datacall-creation/datacall-creation.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { LoadingModule } from 'ngx-loading';
import { DatacallNameValidatorDirective } from '../shared/app-datacall-name-validator.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    MessagesModule,
    SchemaSelectionRoutingModule,
    ReactiveFormsModule,
    AlertModule.forRoot(),
    LoadingModule
  ],
  declarations: [SchemaSelectionComponent, DatacallCreationComponent, DatacallNameValidatorDirective],
  entryComponents: [DatacallCreationComponent],
  exports: [SchemaSelectionComponent]
})
export class SchemaSelectionModule {
}
