<div class="row">


  <input 
      id="orderNumber: {{params.value.orderNumber}}" 
      name="orderNumber" 
      type="number" 
      min="{{min_value}}"
      max="{{max_value}}"
      class="form-control-sm pr-3 input-sm " 
      style="border: 1px solid #ddd;"
      pattern="[1-9+]\d*$"
      (keyup.enter)="onEnter($event, reorderDatafieldModal)" 
      (keyup)="onChange($event)"
      (blur)="onBlur($event, reorderUnsavedDatafieldModal)" 
      oninput="validity.valid||(value=``);"
      size="4" 
      value="{{params.value.orderNumber}}" 
      data-backdrop="static" data-keyboard="false"
      notOnlyWhitespace>
  <div style="padding-left: 15px;">
    <button *ngIf="visible; else hide" id="btn:save" type="submit" class="btn btn-primary button-size"
      (click)="confirm()">
      <i class="fa fa-floppy-o"></i> Update
    </button>
  </div>
</div>

<ng-template #reorderDatafieldModal data-backdrop="static" data-keyboard="false">
  <div class="modal-header">
    <h3 class="modal-title">Reorder Datafield</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure you wish to reorder the <b>'{{datafieldName}}'</b> data field from <b>row '{{defaultValue}}'</b> to <b>row '{{changedValue}}'</b>? All subsequent fields will be incremented after this field.
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link btn-sm" (click)="cancelUpdate()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="confirmUpdate()">Update</button>
  </div>
</ng-template>

<ng-template #reorderUnsavedDatafieldModal data-backdrop="static" data-keyboard="false">
  <div class="modal-header">
    <h3 class="modal-title">Reorder Datafield</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="cancelUpdate()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure you wish to reorder the <b>'{{datafieldName}}'</b> data field from <b>row '{{defaultValue}}'</b> to <b>row '{{changedValue}}'</b>? All subsequent fields will be incremented after this field.
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link btn-sm" (click)="cancelUpdate()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="confirmUpdate()">Update</button>
  </div>
</ng-template>

<ng-template #hide>

</ng-template>