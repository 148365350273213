<ngx-loading [show]="loading"></ngx-loading>

<!-- <ng-container *ngIf="!user && mock">
  <p>For mock data please select a user</p>
  <div class="row">
    <div class="col-4">
      <select class="form-control" name="mock-user" [(ngModel)]="selectedMockUser">
        <option *ngFor="let mockUser of mockUsers" [ngValue]="mockUser">{{mockUser}}</option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <button type="button" (click)="setMockUser()">Select</button>
    </div>
  </div>
</ng-container> -->

<div id="rdc">
  <header >
    <ncui-header
      [hideAppHeader]="true"
      [loggedInUser]="user"
      [realm]="getEnvironmentName()"
      (logout)="logout()">
    </ncui-header>
    <div *ngIf="!loading" class="app-header">
      <app-nav></app-nav>
    </div>
  </header>
  <main>
    <ng-container class="container-fluid" *ngIf="user && !loading; else noUser">
      <router-outlet></router-outlet>
      <router-outlet name="popup"></router-outlet>
    </ng-container>
    <ng-template #noUser>
        <div *ngIf="!loading" class="col-md-2 d-none d-md-block bg-light sidebar d-print-none">
          <h2>User is not authorized</h2>
        </div>
      </ng-template>
  </main>
  <div ><!-- *ngIf="!userService.dataEntry" -->
  <footer class="footer noprint">
      <ncui-footer></ncui-footer>
    </footer>
  </div>
</div>
