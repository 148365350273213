import {Component, OnDestroy, OnInit} from '@angular/core';
import {FilingValidationService} from '../service/filing-validation.service';
import {UserService} from '../../service/user.service';
import {BreadcrumbService} from '../../service/breadcrumb.service';
import {Breadcrumb} from '../../model/breadcrumb';
import {CrossFieldJsValidation} from '../model/cross-field-js-validation';
import {User} from '../../model/user';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {MessageService} from '../../messages/service/message.service';
import {AlertMessage} from '../../messages/model/alert-message';

@Component({
  selector: 'app-validation-edit',
  templateUrl: './validation-edit.component.html'
})
export class ValidationEditComponent implements OnInit, OnDestroy {
  title: string;
  messageText: string;
  error = false;
  breadcrumbs: Breadcrumb[];
  breadcrumb: Breadcrumb;
  validation: CrossFieldJsValidation;
  user: User;
  subscriptions: Subscription[];
  loading: boolean;
  validationForm: FormGroup;
  newValidation: boolean;

  constructor(
    private service: FilingValidationService,
    private userService: UserService,
    public breadcrumbService: BreadcrumbService,
    private formBuilder: FormBuilder,
    private router: Router,
    private messageService: MessageService
  ) {
    this.loading = true;
    this.subscriptions = [];
    this.breadcrumbs = [];
  }

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    this.validation = this.service.getStoredValidation();
    this.breadcrumbs = this.breadcrumbService.getBreadcrumbs();
    this.setBreadcrumb();
    this.title = 'Validation Editor';
    this.createForm();
    this.loading = false;

  }

  ngOnDestroy() {
    this.messageService.clear();
  }

  setBreadcrumb(): void {
    if (this.validation) {
      this.newValidation = false;
      this.breadcrumb = new Breadcrumb(this.validation.name, '/jsValidationEdit');
    } else {
      this.newValidation = true;
      this.breadcrumb = new Breadcrumb('New Validation', '/jsValidationEdit');
    }
  }

  createForm() {
    this.validationForm = this.formBuilder.group({
      name: [{value: '', disabled: !this.newValidation}, [Validators.required]],
      description: ['', [Validators.required]],
      active: [false],
      publicViewable: [false],
      schemaReference: ['', [Validators.required]],
      validationLevel: ['', [Validators.required]],
      expression: ['', [Validators.required]],
      errorMessage: ['', [Validators.required]],
    });
    if(this.validation) {
      this.validationForm.setValue(this.validation);
    }
  }

  onSubmit() {
    this.validation = this.validationForm.value;
    this.loading = true;
    const subscription = this.service.postCrossFieldJsValidations(this.user.currentSchema, this.validation).subscribe(
      () => {
        this.loading = false;
        this.messageText = 'Validation has been saved';
        this.messageService.add(new AlertMessage(this.messageText, 'success'));
      }
    );
    this.subscriptions.push(subscription);
  }

  onCancel() {
    const crumbToRemove = this.breadcrumbs.length - 1;
    this.breadcrumbService.removeBreadcrumb(this.breadcrumbs[crumbToRemove]);
    this.router.navigate(['/filingValidation']);
  }

  get name() { return this.validationForm.get('name'); }

  get description() { return this.validationForm.get('description'); }

  get active() { return this.validationForm.get('active'); }

  get publicViewable() { return this.validationForm.get('publicViewable'); }

  get schemaReference() { return this.validationForm.get('schemaReference'); }

  get validationLevel() { return this.validationForm.get('validationLevel'); }

  get expression() { return this.validationForm.get('expression'); }

  get errorMessage() { return this.validationForm.get('errorMessage'); }

}
