import {FormSection} from './form-section';

export class FilingBlank {
  sections: FormSection[];
  previous: string;
  next: string;
  comments?: string;

  constructor(sections?: FormSection[], comments?: string) {
    this.sections = sections || [];
    this.comments = comments || '';
  }
}

