import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { AssignedToUser } from '../../model/property-name.enum';
import { FileValidationMaintenanceService } from '../../services/file-validation-maintenance.service';

@Component({
  selector: 'app-assignment-key-renderer',
  templateUrl: './assignment-key-renderer.component.html'
})
export class AssignmentKeyRendererComponent implements ICellRendererAngularComp {
  params: any;
  assignedToUser: AssignedToUser;
  includesTrue: boolean;

  constructor(
    private schemaMaintenanceService: FileValidationMaintenanceService
  ) { }

  agInit(params: any): void {
    this.params = params;
    this.includesTrue = this.schemaMaintenanceService.inc;
    this.assignedToUser = this.schemaMaintenanceService.getIsAssignedToUser();
  }

  refresh(): boolean {
    return false;
  }

  checkValue(event: any, data) {
    this.params.context.componentParent.changeAssignmentKeyField(event.target.checked, data.schemaProperty, this.params.colDef.field);
    this.params.api.refreshCells();
  }

}

