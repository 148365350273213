import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SubmissionKeys } from '../../model/submission-keys';
import { FileSubmission } from '../../model/file-submission';



import { ArrayToStringPipe } from '../../pipes/array-to-string.pipe';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {HandleError, HttpErrorHandler} from '../../shared/http-error-handler.service';
import {catchError} from 'rxjs/operators';

@Injectable()
export class RerunValidationsService {
  private storedFileSubmissionKeys: SubmissionKeys;
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    private arrayToString: ArrayToStringPipe,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('RerunValidationsService');
  }

  static isStaticColumn(header: string): boolean {
    const staticColumns: string[] = ['Date', 'File', 'Status', 'Action1', 'Action2'];
    return _.indexOf(staticColumns, header) > -1;
  }

  getStoredSubmissionKeys(): SubmissionKeys {
    return this.storedFileSubmissionKeys;
  }

  setStoredSubmissionKeys(fileSubmissionKeys: SubmissionKeys): void {
    this.storedFileSubmissionKeys = fileSubmissionKeys;
  }

  getFileList(schemaType: string, fileSubmissionKeys: SubmissionKeys): Observable<FileSubmission[]> {
    const url = `${environment.apiUrl}${schemaType}/fileadmin/fileSubmissionSearchRequest`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.httpClient.post<FileSubmission[]>(url, JSON.stringify(fileSubmissionKeys), options)
      .pipe(catchError(this.handleError('getFileList', [])));
  }

  rerunValidations(schemaType: string, fileSubmissionId: string): Observable<any> {
    const url = `${environment.apiUrl}${schemaType}/rerunValidations`;
    const params = new HttpParams().set('fileSubmissionId', fileSubmissionId);
    const options = {params: params};
    return this.httpClient.get<any>(url, options)
      .pipe(catchError(this.handleError('rerunValidations', null)));
  }

  runDiscrepancy(schemaType: string, fileSubmissionId: string): Observable<any> {
    const url = `${environment.apiUrl}${schemaType}/rerunValidations/discrepancy`;
    const params = new HttpParams().set('fileSubmissionId', fileSubmissionId);
    const options = {params: params};
    return this.httpClient.get(url, options)
      .pipe(catchError(this.handleError('runDiscrepancy', null)));
  }

  getTableData(files: FileSubmission[]): any[] {
    const dashboardData: any[] = [];
    files.forEach(file => {
      const data = {};
      file.submissionKeys.forEach(key => {
        let name = '';
        key.name.split('_').forEach((item, index) => {
          if (index > 0) {
            item = item.charAt(0).toUpperCase() + item.slice(1);
          }
          name = name + item;
        });
        data[name] = this.arrayToString.transform(key.values);
      });
      data['date'] = file.created;
      data['file'] = file.originalFileName;
      data['status'] = file.fileSubmissionState;
      data['action1'] = file.id;
      data['action2'] = file.id;
      dashboardData.push(data);
    });
    return dashboardData;
  }
}
