import { ModalModule } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PipesModule } from '../../pipes/pipes.module';
import { UpdateStatusService } from './service/update-status.service';
import { UpdateStatusComponent } from './update-status.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot()
  ],
  declarations: [UpdateStatusComponent],
  providers: [UpdateStatusService],
  exports: [UpdateStatusComponent]
})
export class UpdateStatusModule {
}
