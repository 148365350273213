export const environment = {
  envName: 'dev',
  production: false,
  apiUrl: 'https://rdc-web.dev.naic.org/api/',
  redirectBaseUrl: 'https://rdc-dev.naic.org',
  mock: false,
  financialApiUrl: 'https:/services-dvlp.naic.org/',
  correspondenceApiUrl: 'rdc-correspondence-dev.naic.org',
  auth: {
    clientId: '0oa1sowww83mtpDrg0h8',
    issuer: 'https://authenticate-nonprod.naic.org/oauth2/default',
    authorizationEndpoint: 'https://authenticate-nonprod.naic.org/oauth2/default/v1/authorize',
    tokenEndpoint: 'https://authenticate-nonprod.naic.org/oauth2/v1/token',
    redirectBaseUrl: 'https://rdc-dev.naic.org',
    logoutUrl: 'https://rdc-dev.naic.org',
    userInfoEndpoint: 'https://authenticate-nonprod.naic.org/userinfo',
    tokenTimeoutFactor: 0.75,
    session_popup_time: 300, // 5 min
    session_idle_time: 1500 // 25 min
  }
};
