import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {User} from '../model/user';
import { environment } from '../../environments/environment';
import { RdcNavigationPermissions } from '../model/user-permissions.enum';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {HandleError, HttpErrorHandler} from '../shared/http-error-handler.service';
import { UserInfo } from '../model/user-info';

@Injectable()
export class UserService {
  private user: User;
  private userProfile: any;
  readonly handleError: HandleError;
  dataEntry: boolean = false;

  constructor(
    protected httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('UserService');
  }

  getUser(): Observable<User> {
    return this.httpClient.get<User>(`${environment.apiUrl}priv`)
      .pipe(catchError(this.handleError('getUser', null)));
  }

  getUserInfo(): Observable<UserInfo> {
    const url = `${environment.auth.userInfoEndpoint}`;
    return this.httpClient.get<UserInfo>(url)
      .pipe(catchError(this.handleError('getUserInfo', null)));
  }

  getLogoutUrl(): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiUrl}configuration/logoutUrl`).
      pipe(catchError(this.handleError('getLogoutUrl', null)));
  }

  setStoredUser(user: User): void {
    this.user = user;
  }

  getStoredUser(): User {
    return this.user;
  }

  setStoredUserProfile(userProfile: any): void {
    this.userProfile = userProfile;
  }

  getStoredUserProfile(): any {
    return this.userProfile;
  }

  isUserNotAuthorized(user: User) {
    let isUserNotAuthorized: boolean = false;
    if (user.schemas.length === 0) {
      isUserNotAuthorized = true;
    }
    return isUserNotAuthorized;
  }

  canViewFileDashboard(user: User) {
    if (user.currentSchema) {
      const datacallPermissions: string[] = this.getDatacallPermissions(user);
      return datacallPermissions.indexOf(RdcNavigationPermissions.viewOwnFilesubmissions) > -1;
    }
    return false;
  }

  canViewAggregationMaintenanceLink(user: User) {
    if (user.currentSchema) {
      const datacallPermissions: string[] = this.getDatacallPermissions(user);
      return datacallPermissions.indexOf(RdcNavigationPermissions.listAllAggregates) > -1;
    }
  }

  canViewSchemaMaintenanceLink(user: User) {
    if (user.currentSchema) {
      const datacallPermissions: string[] = this.getDatacallPermissions(user);
      return datacallPermissions.indexOf(RdcNavigationPermissions.viewAllSchema) > -1;
    }
  }

  canViewFileAdminLink(user: User) {
    if (user.currentSchema) {
      const datacallPermissions: string[] = this.getDatacallPermissions(user);
      return datacallPermissions.indexOf(RdcNavigationPermissions.viewAllAdminSchema) > -1;
    }
  }

  canViewToleranceMaintenanceLink(user: User) {
    if (user.currentSchema) {
      const datacallPermissions: string[] = this.getDatacallPermissions(user);
      return datacallPermissions.indexOf(RdcNavigationPermissions.viewAllTolerances) > -1;
    }
  }

  canViewCredible(user: User) {
    if (user.currentSchema) {
      const datacallPermissions: string[] = this.getDatacallPermissions(user);
      return datacallPermissions.indexOf(RdcNavigationPermissions.viewCredibleCocodes) > -1;
    }
  }

  canViewandUpdateUserAssignments(user: User) {
    if(user.currentSchema) {
      const datacallPermissions: string[] = this.getDatacallPermissions(user);
      return datacallPermissions.indexOf(RdcNavigationPermissions.updateUserAssignments) > -1;
    }
  }

  getDatacallPermissions(user: User) {
    let datacallPermissions: string[] = [];
    datacallPermissions = datacallPermissions.concat(user.datacallPermissions[user.currentSchema]);
    return datacallPermissions;
  }

  hasMultiplePermittedSchemas(user: User): boolean {
    return user.schemas.length > 1;
  }

}
