import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {DatacallSettingService} from '../../../roleconfig/datacall-setting/services/datacall-setting.service';

@Component({
  selector: 'app-submission-key-renderer',
  templateUrl: './submission-key-renderer.component.html'
})
export class SubmissionKeyRendererComponent implements ICellRendererAngularComp {

  params: any;
  isOnlineDataEntry: boolean;

  constructor(
    private datacallSettingService: DatacallSettingService
  ) {
  }

  agInit(params: any): void {
    this.params = params;
    this.isOnlineDataEntry = this.datacallSettingService.getOnlineDataEntrySetting();
  }

  refresh(): boolean {
    return false;
  }

  checkValue(event: any, data) {
    this.params.context.componentParent.changeCheckboxField(event.target.checked, data.schemaProperty, this.params.colDef.field);
  }

}
