<ngx-loading [show]="loading"></ngx-loading>
<div id="filing-validation" class="wrapper">
  <div class="row page-title">
    <div class="col-12">
      <h1>{{title}}</h1>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <app-messages></app-messages>
    </div>
  </div>

  <div id="file-validation:data-table">

    <div *ngIf="data.length > 0; else noData" class="row mb-3">
      <div class="col-12">
        <div class="row mb-3">
          <div class="col-md-6">
              <input type="text" class="form-control form-control-lg" placeholder="Type to filter..."
                     (keyup)="updateFilter($event)"/>
          </div>
          <div class="col-md-6">
              <button type="button" class="btn btn-primary  pull-right" (click)="addValidation()">
                <i class="fa fa-plus"> Add Validation</i><span class="sr-only">Add Validation</span>
              </button>
          </div>
        </div>
        <ngx-datatable
          class="bootstrap expandable"
          [rows]="rows"
          [columnMode]="'force'"
          [headerHeight]="50"
          [footerHeight]="50"
          [rowHeight]="50"
          [reorderable]="false"
          [loadingIndicator]="loadingIndicator"
          [sorts]="[{prop: 'name', dir: 'asc'}]"
          [scrollbarH]="scrollBarHorizontal"
          [limit]="15">

          <ngx-datatable-row-detail
            #validationRowDetail
            [rowHeight]="'auto'">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
              <div class="ml-3 mt-3"><strong>Expression</strong></div>
              <div class="ml-3 mb-3">{{row.expression}}</div>
              <div class="ml-3"><strong>Error Message</strong></div>
              <div class="ml-3 mb-3">{{row.errorMessage}}</div>
            </ng-template>
          </ngx-datatable-row-detail>

          <ngx-datatable-column
            [width]="50"
            [resizeable]="false"
            [sortable]="false"
            [draggable]="false"
            [canAutoResize]="false">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
              <a
                href="javascript:void(0)"
                [class.datatable-icon-right]="!expanded"
                [class.datatable-icon-down]="expanded"
                title="Expand/Collapse Row"
                (click)="toggleExpandRow(row)">
              </a>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Name">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
              <span class="font-weight-bold">{{column.name}}</span>
            </ng-template>
            <ng-template let-value="value" let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
              <a href="javascript:void(0)" (click)="editValidation(row)" title="Click to edit">
                {{value}}
              </a>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Description">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
              <span class="font-weight-bold">{{column.name}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{value}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Active">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
              <span class="font-weight-bold">{{column.name}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{value}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Public Viewable">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
              <span class="font-weight-bold">{{column.name}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{value}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Schema Reference">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
              <span class="font-weight-bold">{{column.name}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{value}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Validation Level">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
              <span class="font-weight-bold">{{column.name}}</span>
            </ng-template>
            <ng-template let-value="value" ngx-datatable-cell-template>
              {{value}}
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>

</div>

<ng-template #noData>
  <div *ngIf="!loading" class="row">
    <div class="col-12 mb-3">
      <p>No file validations</p>
    </div>
  </div>
</ng-template>
