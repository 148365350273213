import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisclaimerComponent } from './disclaimer.component';
import {DisclaimerRoutingModule} from './disclaimer-routing.module';
import { DisclaimerService } from './service/disclaimer.service';
import {MessagesModule} from '../messages/messages.module';
import { LoadingModule } from 'ngx-loading';

@NgModule({
  imports: [
    CommonModule,
    MessagesModule,
    DisclaimerRoutingModule,
    LoadingModule
  ],
  declarations: [DisclaimerComponent,
    ],
  providers: [DisclaimerService],
  exports: [DisclaimerComponent]
})
export class DisclaimerModule { }
