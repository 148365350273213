import { Injectable } from '@angular/core';
import { User } from '../../../model/user';
import { HandleError, HttpErrorHandler } from '../../../shared/http-error-handler.service';


@Injectable({
  providedIn: 'root'
})

export class ExpressionBuilderService {
  readonly handleError: HandleError;
  expressionJsonObj: any;
  javascriptExpression: string;
  fieldName: any;
  colorIndex: number = 1;
  routingHelperValue: boolean = false;
  idIndex: number = 0;
  user: User;
  validateExpressionFromCrossFieldEdit: boolean;
  validateExpressionFromEB: boolean;
  schemaType: string;
  expression: string;
  validationName: string;
  model: any;
  validationNotSavedFromTestExpression: boolean = false;
  dataEntry: boolean;

  constructor( httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('ExpressionBuilderService');
  }

  setRoutingHelper(routingHelperValue: boolean) {
    this.routingHelperValue = routingHelperValue;
  }

  getRoutingHelper(): boolean {
    return this.routingHelperValue;
  }

  setValidationNotSavedFromTestExpressionPage(validationNotSavedFromTestExpression: boolean) {
    this.validationNotSavedFromTestExpression = validationNotSavedFromTestExpression;
  }

  getValidationNotSavedFromTestExpressionPage(): boolean {
    return this.validationNotSavedFromTestExpression;
  }

  setValuesFromExpressionBuilder(schemaType: string, expression: string, validationName: string) {
    this.schemaType = schemaType;
    this.expression = expression;
    this.validationName = validationName;
  }

  getValuesFromExpressionBuilder(): any {
    let values: any = {};
    values.schemaType = this.schemaType;
    values.expression = this.expression;
    values.validationName = this.validationName;
    return values;
  } 

  setExpressionBuilderModel(model) {
    this.model = model;
  }

  getExpressionBuilderModel(): any {
    return this.model;
  }

  generateExpressionFromJson(jsonObj: object): string {
    this.javascriptExpression = '';
    this.expressionJsonObj = jsonObj;
    this.processJsonObject(this.expressionJsonObj, '');
    return this.javascriptExpression;
  }

   private processJsonObject(jsonObj: any, logicalOperator: string): void {

    if (Array.isArray(jsonObj)) {
      for ( let index = 0; index < jsonObj.length; index++) {
        this.processElementKey(jsonObj[index], logicalOperator, index);

        // only process the main test; all others are child tests & will be built into the expression syntax
        if (jsonObj[index].hasOwnProperty('name')) { break; }
      }
    } else {
      this.processElementKey(jsonObj, logicalOperator, 0);
    }
  }

   private processElementKey(jsonObj, logicalOperator, index): void {
    let wasLastTypeATest = false;
    let previousLogicalOperator = logicalOperator;

    Object.entries(jsonObj).forEach(([key, value]) => {
      switch (key) {
        case 'name' : this.javascriptExpression += ' if '; break;
        case 'expression' : this.javascriptExpression +=  (index > 0 ? logicalOperator + value.toString().trim() : value.toString().trim()); break;
        case 'expressions' : this.javascriptExpression += (index > 0 ? previousLogicalOperator : '') + '('; this.processJsonObject(value, logicalOperator); this.javascriptExpression += ')'; break;
        case 'trueAction' : this.javascriptExpression += ' {'; this.processJsonObject(value, logicalOperator); this.javascriptExpression += '}'; break;
        case 'falseAction' : this.javascriptExpression += ' else {'; this.processJsonObject(value, logicalOperator); this.javascriptExpression += '}'; break;
        case 'andOrCondition' : logicalOperator = ' ' + value + ' '; break;
        case 'type' : wasLastTypeATest = (value === 'CHILD_TEST'); break;
        case 'value' :
          if (wasLastTypeATest) {
            const childObj = this.expressionJsonObj.find(o => o.name === value);
            this.processJsonObject(childObj, logicalOperator);
            wasLastTypeATest = false;
          } else {
            this.javascriptExpression += 'return ' + value + ';';
          }
          break;
      }
    });
  }

  setFieldName(fieldName: any): any {
    this.fieldName = fieldName;
  }

  getFieldName(): any {
    return this.fieldName;
  }

}
