import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoadingModule } from 'ngx-loading';
import { AggregateModule } from './aggregate/aggregate.module';
import { AggregationMaintenanceRoutingModule } from './aggregation-maintenance-routing.module';
import { AggregationMaintenanceComponent } from './aggregation-maintenance.component';
import { AggregationMaintenanceService } from './services/aggregation-maintenance.service';
import { MessagesModule } from '../messages/messages.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LoadingModule,
    MessagesModule,
    AggregateModule,
    AggregationMaintenanceRoutingModule,
  ],
  declarations: [AggregationMaintenanceComponent],
  providers: [AggregationMaintenanceService],
  exports: [AggregationMaintenanceComponent],
})
export class AggregationMaintenanceModule { }
