import {Component, TemplateRef} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {FileAdminService} from '../../service/file-admin.service';
import {ICellRendererParams} from 'ag-grid-community';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-file-name-renderer',
  templateUrl: './file-name-renderer.component.html',
  styleUrls: ['./file-name-renderer.component.css']
})
export class FileNameRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;
  confirmDownloadModal: BsModalRef;

  constructor(
    private fileAdminService: FileAdminService,
    public modalService: BsModalService,
  ) {
  }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  openConfirmDownloadModal(template: TemplateRef<any>) {
    this.confirmDownloadModal = this.modalService.show(template, {class: 'modal-lg'});
  }

  downloadFile() {
    this.modalService.hide(1);
    this.params.context.componentParent.getFileFromS3(this.params.data.notes.fileName);
  }

}
