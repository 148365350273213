/*
 * Copyright 2018 National Association of Insurance Commissioners
 */

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

import { environment } from '../../environments/environment';
import {rdcMockBackend} from '../mock/rdc-mock-backend';
import {MessageService} from '../messages/service/message.service';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(
    private messageService: MessageService,
    private router: Router
  ) {}


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.mock) {
      const url: string = request.url;
      const method: string = request.method;

      return rdcMockBackend(url, method, request) || next.handle(request);
    } else {
      return next.handle(request)
        .pipe(tap(() => {
          // success
        }, (err: any) => {
          // error
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              // redirect user to login
              this.router.navigate(['']);
            }
          } else {
            console.error('error in request.interceptor');
          }
        }));
    }
  }
}
