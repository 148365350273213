<div class="wrapper">
    <div class="schema-maintenance">
        <div class="row page-title">
          <div class="col-12">
            <h1>{{title}}</h1>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li *ngFor="let crumb of breadcrumbs" class="breadcrumb-item">
                  <a [routerLink]="crumb.link" (click)="breadcrumbService.removeBreadcrumb(crumb)">{{crumb.label}}</a>
                </li>
                <li class="breadcrumb-item active">
                  Field Definition Details
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div *ngFor="let key of getPropertiesKeys()" class="row mb-3">
          <div class="col-12">
            <h3>{{key}}</h3>
            <div>type: {{validationSchema.properties[key].type}}</div>
            <div class="checkbox">
              <label>
                <input type="checkbox" [(ngModel)]="validationSchema.properties[key].required" aria-label="required" disabled> required
              </label>
            </div>
            <ng-container *ngFor="let validationName of getPropertyKeys(validationSchema.properties[key])">
              <ng-container *ngIf="shouldDisplay(validationName)">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text font-weight-bold">{{validationName}}</span>
                  </div>
                  <input type="text" class="form-control" [(ngModel)]="validationSchema.properties[key][validationName]" disabled>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
</div>

