<nav id="rdc-nav" class="navbar navbar-light navbar-expand-md global-nav hidden-print second-bar">
  <a class="navbar-brand" href="#">
    <img id="rdc-logo" alt="RDC" src="../assets/images/rdc-abbr-logo-white.png">
  </a>
  <button *ngIf="(user.currentSchema || !hasMultiplePermittedSchemas())" class="navbar-toggler"
    (click)="isNavCollapsed = !isNavCollapsed">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div *ngIf="(user.currentSchema || !hasMultiplePermittedSchemas())" [collapse]="isNavCollapsed"
    class="collapse navbar-collapse">
    <ul class="navbar-nav mr-auto">
      <li *ngIf="links.schemaSelection.isVisible" class="nav-item" routerLinkActive="active">
        <a id="ln:rdcHome" class="nav-link"
          (click)="isNavCollapsed = !isNavCollapsed;
         dataEntryReRoutingEnabled ?
         routeToClickedPage('schemaSelection', showModalIfCrossFieldValidatorPagehasExpressionChanged) : openModal(showModalFromFormBuilderIfNotSaved);">
          RDC Home
        </a>
      </li>
      <li *ngIf="links.fileDashboard.isVisible" class="nav-item" routerLinkActive="active">
        <a id="ln:fileDashboard" class="nav-link"
          (click)="isNavCollapsed = !isNavCollapsed; routeToClickedPage('fileDashboard', showModalIfCrossFieldValidatorPagehasExpressionChanged)">
          File Dashboard
        </a>
      </li>
      <!--  User Assignment Primary -->
      <li *ngIf="links.userAssignment.isVisible" class="nav-item" routerLinkActive="active">
        <a id="ln:userAssignmentPrimary" class="nav-link"
          (click)="isNavCollapsed = !isNavCollapsed;
           routeToClickedPage('userAssignment', (showUserFilling ? showModalFromFormBuilderIfNotSaved : showModalIfCrossFieldValidatorPagehasExpressionChanged))">
          User Assignments
        </a>
      </li>
      <li *ngIf="links.aggregationMaintenance.isVisible" class="nav-item" routerLinkActive="active">
        <a id="ln:aggregates" class="nav-link"
          (click)="isNavCollapsed = !isNavCollapsed; routeToClickedPage('aggregation', showModalIfCrossFieldValidatorPagehasExpressionChanged)">Aggregates</a>
      </li>
      <li *ngIf="links.schemaMaintenance.isVisible" class="nav-item">
        <div class="btn-group" dropdown>
          <button dropdownToggle type="button" class="btn btn-link btn-sm dropdown-toggle" id="drop:admin">
            Datacall Administration <span class="caret"></span>
          </button>
          <ul *dropdownMenu class="dropdown-menu" role="menu">
            <li role="menuitem">
              <a class="dropdown-item" id="ln:fileValidation:roleconfig"
                (click)="isNavCollapsed = !isNavCollapsed; routeToClickedPage('roleconfig', showModalIfCrossFieldValidatorPagehasExpressionChanged)">
                Setup Configuration
              </a>
            </li>
            <!--  User Assignment Admin -->
            <li *ngIf="links.userAssignmentAdmin.isVisible" class="divider dropdown-divider"></li>
            <li *ngIf="links.userAssignmentAdmin.isVisible" role="menuitem">
              <a class="dropdown-item" id="ln:fileValidation:userAssignmentAdmin"
                (click)="isNavCollapsed = !isNavCollapsed; routeToClickedPage('userAssignment', showModalIfCrossFieldValidatorPagehasExpressionChanged)">
                User Assignments
              </a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <a class="dropdown-item" id="ln:fileValidation:fileValidationMaintenance"
                (click)="isNavCollapsed = !isNavCollapsed; routeToClickedPage('schemaMaintenance', showModalIfCrossFieldValidatorPagehasExpressionChanged)">
                Datacall Field Definitions
              </a>
            </li>
            <li *ngIf="links.formBuilder.isVisible" class="divider dropdown-divider"></li>
            <li *ngIf="links.formBuilder.isVisible" role="menuitem">
              <a class="dropdown-item" id="ln:fileValidation:formBuilder"
                (click)="isNavCollapsed = !isNavCollapsed; routeToClickedPage('formBuilder', showModalIfCrossFieldValidatorPagehasExpressionChanged)">
                Form Builder
              </a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li class="dropdown-submenu">
              <a class="dropdown-item dropdown-toggle" id="ln:fileValidation:reasonabilityValidations">
                Validations
              </a>
              <ul class="dropdown-menu">
                <li role="menuitem">
                  <a class="dropdown-item" id="ln:fileValidation:reasonabilityFileLevelValidation" tabindex="-1"
                    (click)="isNavCollapsed = !isNavCollapsed; routeToClickedPage('fileLevelValidation', showModalIfCrossFieldValidatorPagehasExpressionChanged)">File
                    Level Validation</a>
                </li>
                <li class="divider dropdown-divider"></li>
                <li role="menuitem">
                  <a class="dropdown-item" id="ln:fileValidation:reasonabilityCrossfieldValidation" tabindex="-1"
                    (click)="isNavCollapsed = !isNavCollapsed; routeToClickedPage('validationMaintenance', showModalIfCrossFieldValidatorPagehasExpressionChanged)">Cross
                    Field Validations</a>
                </li>
              </ul>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <a class="dropdown-item" id="ln:fileValidation:tolerances"
                (click)="isNavCollapsed = !isNavCollapsed; routeToClickedPage('toleranceMaintenance', showModalIfCrossFieldValidatorPagehasExpressionChanged)">
                Tolerances
              </a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <a class="dropdown-item" id="ln:fileValidation:filingDates"
                (click)="isNavCollapsed = !isNavCollapsed; routeToClickedPage('filingDates', showModalIfCrossFieldValidatorPagehasExpressionChanged)">
                Filing Dates
              </a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <a class="dropdown-item" id="ln:fileValidation:importexport"
                (click)="isNavCollapsed = !isNavCollapsed; routeToClickedPage('importExport', showModalIfCrossFieldValidatorPagehasExpressionChanged)">
                Import/Export
              </a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <a class="dropdown-item" id="ln:fileValidation:rerunValidationRunDiscrepancy"
                (click)="isNavCollapsed = !isNavCollapsed; routeToClickedPage('rerunValidations', showModalIfCrossFieldValidatorPagehasExpressionChanged)">
                Rerun Validations / Run Discrepancy
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li *ngIf="links.fileAdmin.isVisible" class="nav-item">
        <div class="btn-group" dropdown>
          <button dropdownToggle type="button" class="btn btn-link btn-sm dropdown-toggle" id="drop:submissions">
            Submissions <span class="caret"></span>
          </button>
          <ul *dropdownMenu class="dropdown-menu" role="menu">
            <li role="menuitem">
              <a class="dropdown-item" id="ln:submissions:summary"
                (click)="isNavCollapsed = !isNavCollapsed; routeToClickedPage('datacallSummary', showModalIfCrossFieldValidatorPagehasExpressionChanged)">
                Summary
              </a>
            </li>
            <li *ngIf="!links.filingSearch.isVisible" class="divider dropdown-divider"></li>
            <li *ngIf="!links.filingSearch.isVisible" role="menuitem">
              <a class="dropdown-item" id="ln:submissions:fileAdministration"
                (click)="isNavCollapsed = !isNavCollapsed; routeToClickedPage('fileAdmin', showModalIfCrossFieldValidatorPagehasExpressionChanged)">
                File Administration
              </a>
            </li>
            <li *ngIf="links.filingSearch.isVisible" class="divider dropdown-divider"></li>
            <li *ngIf="links.filingSearch.isVisible" role="menuitem">
              <a class="dropdown-item" id="ln:submissions:onlineFilingSearch"
                (click)="isNavCollapsed = !isNavCollapsed; routeToClickedPage('filingSearch', showModalIfCrossFieldValidatorPagehasExpressionChanged)">
                Filing Search
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li *ngIf="links.filingBlankEditor.isVisible" class="nav-item">
        <div class="btn-group" dropdown>
          <button dropdownToggle type="button" class="btn btn-link btn-sm dropdown-toggle">
            Filing Blank Editor <span class="caret"></span>
          </button>
          <ul *dropdownMenu class="dropdown-menu" role="menu">
            <li role="menuitem">
              <a class="dropdown-item" id="ln:filingBlankEditor:filingBlankLayout"
                (click)="isNavCollapsed = !isNavCollapsed; routeToClickedPage('filingBlankLayout', showModalIfCrossFieldValidatorPagehasExpressionChanged)">
                Filing Blank Layout
              </a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <a class="dropdown-item" id="ln:filingBlankEditor:filingSchema"
                (click)="isNavCollapsed = !isNavCollapsed; routeToClickedPage('filingSchema', showModalIfCrossFieldValidatorPagehasExpressionChanged)">
                Filing Schema
              </a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <a class="dropdown-item" id="ln:filingBlankEditor:filingValidation"
                (click)="isNavCollapsed = !isNavCollapsed; routeToClickedPage('filingValidation', showModalIfCrossFieldValidatorPagehasExpressionChanged)">
                Filing Validation
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li class="nav-item" *ngIf="showUserFilling && !links.fileAdmin.isVisible" routerLinkActive="active">
        <a id="ln:dashboard" class="nav-link"
          (click)="isNavCollapsed = !isNavCollapsed; redirectToUserFiling(showModalFromFormBuilderIfNotSaved)">Dashboard</a>
      </li>
    </ul>
  </div>

</nav>

<ng-template #showModalIfCrossFieldValidatorPagehasExpressionChanged>
  <div
    *ngIf="(!expressionBuilderService.getValidationNotSavedFromTestExpressionPage() && !formBuilderService.getChangeStatus()); else showModalFromTestExpressionIfExpressionNotSaved">
    <div class="modal-header">
      <h3 class="modal-title">Cross Field Validation Editor</h3>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Do you wish to proceed without saving changes?
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-link btn-sm" (click)="navigateToClickedPage()">Yes</button>
      <button type="button" class="btn btn-primary" (click)="modalRef.hide()">No</button>
    </div>
  </div>
</ng-template>


<ng-template #showModalFromTestExpressionIfExpressionNotSaved>
  <div
    *ngIf="(!formBuilderService.getChangeStatus() && !expressionBuilderService.getRoutingHelper()); else showModalFromFormBuilderIfNotSaved">
    <div class="modal-header">
      <h3 class="modal-title">Test Expression</h3>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      If you have not set your expression in expression builder and saved your validation then your changes will be
      lost. Do you want to proceed?
    </div>
    <div class="modal-footer">
      <button id="yes" type="button" class="btn btn-link btn-sm" (click)="navigateToClickedPage()">Yes</button>
      <button id="no" type="button" class="btn btn-primary" (click)="modalRef.hide()">No</button>
    </div>
  </div>
</ng-template>

<ng-template #showModalFromFormBuilderIfNotSaved>
  <div class="modal-header">
    <h3 class="modal-title">Form Builder</h3>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Do you wish to proceed without saving changes?
  </div>
  <div class="modal-footer">
    <button id="yes" type="button" class="btn btn-link btn-sm" (click)="navigateWithoutSavingToPage('user-filing')">
      Yes
    </button>
    <button id="no" type="button" class="btn btn-primary" (click)="modalRef.hide()">No</button>
  </div>
</ng-template>