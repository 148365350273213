<ngx-loading [show]="loading"></ngx-loading>
<div id="filing-dates" class="wrapper">
  <div class="row page-title">
    <div class="col-12">
      <h1>{{title}}</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <app-messages></app-messages>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <form name="datesForm" #datesForm="ngForm">
        <div class="row mb-3">
          <div class="col-sm-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold">Data Year</span>
              </div>
              <input id="input:dataYear" title="Data Year" type="text" name="data_year" #data_year="ngModel"
                     class="form-control" [(ngModel)]="dataYear" required pattern="^20[0-9]{2}$"/>
              <div class="input-group-append" [hidden]="data_year.valid || data_year.pristine">
                <span class="input-group-text text-danger border border-danger">Not a valid data year</span>
              </div>
              <div *ngIf="dataYear" class="input-group-append">
                <button class="btn btn-secondary btn-sm" (click)="dataYear = undefined" type="button" tooltip="Clear">
                  <i class="fa fa-times fa-fw"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold">Starting Date</span>
              </div>
              <input id="input:dateStart"
                     class="form-control"
                     placeholder="MM/DD/YYYY"
                     name="startDP"
                     [(ngModel)]="dateStart"
                     bsDatepicker
                     #sd="bsDatepicker"
                     [maxDate]="dateEnd"
                     [bsConfig]="dpConfig"
                     [attr.disabled]="'disabled'"
                     required/>
              <div class="input-group-append">
                <button *ngIf="!dateStart" class="btn btn-outline-primary btn-sm" (click)="sd.toggle()" type="button">
                  <i class="fa fa-calendar fa-fw"></i>
                </button>
                <button *ngIf="dateStart" class="btn btn-secondary btn-sm" (click)="dateStart = null" type="button" tooltip="Clear">
                  <i class="fa fa-times fa-fw"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text font-weight-bold">Ending Date</span>
              </div>
              <input id="input:dateEnd"
                     class="form-control"
                     placeholder="MM/DD/YYYY"
                     name="endDP"
                     [(ngModel)]="dateEnd"
                     bsDatepicker
                     #ed="bsDatepicker"
                     [minDate]="dateStart"
                     [attr.disabled]="'disabled'"
                     [bsConfig]="dpConfig"
                     required>
              <div class="input-group-append">
                <button *ngIf="!dateEnd" class="btn btn-outline-primary btn-sm" (click)="ed.toggle()" type="button" [disabled]="!dateStart">
                  <i class="fa fa-calendar fa-fw"></i>
                </button>
                <button *ngIf="dateEnd" class="btn btn-secondary btn-sm" (click)="dateEnd = null" type="button" tooltip="Clear">
                  <i class="fa fa-times fa-fw"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button id="btn:create" type="button" class="btn btn-primary" (click)="create()" [disabled]="!datesForm.valid || isCreateButtonDisabled()">
              <i class="fa fa-plus"></i> Create
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div>

    <div *ngIf="rowData.length > 0" class="row mb-3">
      <div class="col-12">
        <ag-grid-angular
        style="width: 100%; height: 400px;"
        #agGrid
        id="filingDateGrid"
        class="ag-theme-balham"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="rowData"
        [context]="context"
        [frameworkComponents]="frameworkComponents"
        (gridReady)="onGridReady($event)"
          >
        </ag-grid-angular>
      </div>
    </div>
  </div>


</div>
