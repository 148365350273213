import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { User } from '../../../model/user';
import { ValidationSchema } from '../../../file-validation-maintenance/model/validation-schema';
import { UserService } from '../../../service/user.service';
import * as _ from 'lodash';
import { ExpressionBuilderService } from '../services/expression-builder.service';

@Component({
  selector: 'app-field-selection',
  templateUrl: './field-selection.component.html',
  styleUrls: ['./field-selection.component.css']
})
export class FieldSelectionComponent implements OnInit {
  title: string;
  form: FormGroup;
  enteredFieldName: string = '';
  user: User;
  validationSchema: ValidationSchema;
  public event: EventEmitter<any> = new EventEmitter();
  fieldNames = [];
  selectedName: any;
  saveFlag: boolean = true;
  nameToSearch: string;

  constructor(
    public bsModalRef: BsModalRef,
    private userService: UserService,
    private expressionBuilderService: ExpressionBuilderService
  ) { }

  ngOnInit() {
    this.title = `Select Field from Datacall`;
    this.user = this.userService.getStoredUser();
    this.fieldNames = this.expressionBuilderService.getFieldName();
  }

  search(searchText) {
    if (searchText === undefined || searchText === '') {
      this.fieldNames = this.expressionBuilderService.getFieldName();
    } else {
      this.fieldNames = this.expressionBuilderService.getFieldName();
      const result = this.fieldNames.filter(filteredText => filteredText.fieldNames.startsWith(String(searchText)));
      this.fieldNames = result;
    }
  }

  selectField(field) {
    this.enteredFieldName = `${field}`;
    this.saveFlag = false;
  }

  ok() {
    this.triggerEvent(this.enteredFieldName);
    this.bsModalRef.hide();
  }

  triggerEvent(value: string) {
    this.event.emit({ data: value, res: 200 })
  }
}
