import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { FileAdminService } from '../../service/file-admin.service';

@Component({
  selector: 'app-form-format-exceptions-renderer',
  templateUrl: './form-format-exceptions-renderer.component.html',
  styleUrls: ['./form-format-exceptions-renderer.component.css']
})
export class FormFormatExceptionsRendererComponent implements ICellRendererAngularComp {
  params: any;

  constructor(
    private fileAdminService: FileAdminService
  ) { }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  goToFileAdminErrors() {
    this.fileAdminService.setFormFormatErrorTypeCounts(this.params.value.formFormatErrorTypeCounts);
    this.params.context.componentParent.goToFileAdminErrors(this.params.value, 'FORMFORMAT');
  }

}
