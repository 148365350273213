import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {LandingComponent} from './landing.component';

const landingRoutes: Routes = [
  {path: 'landing', component: LandingComponent}
];

@NgModule({
  imports: [
    RouterModule.forChild(landingRoutes)
  ],
  exports: [RouterModule]
})
export class LandingRoutingModule {}
