import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {FileSubmission} from '../../model/file-submission';
import {SubmissionKeys} from '../../model/submission-keys';
import {ArrayToStringPipe} from '../../pipes/array-to-string.pipe';
import {DocumentErrorList} from '../error-type/model/document-error-list';
import * as _ from 'lodash';
import {HandleError, HttpErrorHandler} from '../../shared/http-error-handler.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {ErrorTypeCounts} from '../../model/error-type-counts';
import {User} from '../../model/user';
import {DownloadURLResponse} from '../models/DownloadURLResponse';

@Injectable()
export class FileAdminService {
  private storedFileSubmissionKeys: SubmissionKeys;
  readonly handleError: HandleError;
  submissionKeys: any;
  formFormatErrorTypeCounts: ErrorTypeCounts[];
  crossFieldErrorTypeCounts: ErrorTypeCounts[];
  crossFieldNonPublicErrorTypeCounts: ErrorTypeCounts[];

  constructor(
    private httpClient: HttpClient,
    private arrayToString: ArrayToStringPipe,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('FileAdminService');
  }

  static isStaticAdminColumn(header: string): boolean {
    const staticColumns: string[] = ['date', 'file', 'status', 'formFormatExceptions',
      'crossFieldExceptions', 'numberOfRecords', 'adminAction'];
    return _.indexOf(staticColumns, header) > -1;
  }

  getSubmissionKeys(): any {
    return this.submissionKeys;
  }

  setSubmissionKeys(submissionKeys): void {
    this.submissionKeys = submissionKeys;
  }

  getStoredSubmissionKeys(): SubmissionKeys {
    return this.storedFileSubmissionKeys;
  }

  setStoredSubmissionKeys(submissionKeys: SubmissionKeys): void {
    this.storedFileSubmissionKeys = submissionKeys;
  }

  setFormFormatErrorTypeCounts(formFormatErrorTypeCounts: ErrorTypeCounts[]): void {
    this.formFormatErrorTypeCounts = formFormatErrorTypeCounts;
  }

  getFormFormatErrorTypeCounts(): ErrorTypeCounts[] {
    return this.formFormatErrorTypeCounts;
  }

  setCrossFieldErrorTypeCounts(crossFieldErrorTypeCounts: ErrorTypeCounts[]): void {
    this.crossFieldErrorTypeCounts = crossFieldErrorTypeCounts;
  }

  getCrossFieldErrorTypeCounts(): ErrorTypeCounts[] {
    return this.crossFieldErrorTypeCounts;
  }

  setCrossFieldNonPublicErrorTypeCounts(crossFieldNonPublicErrorTypeCounts: ErrorTypeCounts[]): void {
    this.crossFieldNonPublicErrorTypeCounts = crossFieldNonPublicErrorTypeCounts;
  }

  getCrossFieldNonPublicErrorTypeCounts(): ErrorTypeCounts[] {
    return this.crossFieldNonPublicErrorTypeCounts;
  }

  getFileList(schemaType: string, submissionKeys: SubmissionKeys): Observable<FileSubmission[]> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const options = {headers: headers};
    return this.httpClient.post<FileSubmission[]>(`${environment.apiUrl}${schemaType}/fileadmin/fileSubmissionSearchRequest`, JSON.stringify(submissionKeys), options)
      .pipe(catchError(this.handleError('getFileList', [])));
  }

  doReload(fileSubmission: FileSubmission): Observable<any> {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const options = {headers: headers};
    return this.httpClient.post(`${environment.apiUrl}${fileSubmission.schemaType}/fileadmin/fileSubmissions/${fileSubmission.id}`, JSON.stringify(fileSubmission), options)
      .pipe(catchError(this.handleError('doReload', null)));
  }

  getErrorTypeList(schemaType: string, fileSubmissionId: string, validationCategory: string, errorType: string, skip: number, limit: number): Observable<DocumentErrorList> {
    const params = new HttpParams()
      .set('validationCategory', validationCategory)
      .set('skip', String(skip))
      .set('limit', String(limit));
    const options = {params: params};
    return this.httpClient.get<DocumentErrorList>(`${environment.apiUrl}${schemaType}/fileadmin/fileSubmissions/${fileSubmissionId}/documentErrorLists/${errorType}`, options)
      .pipe(catchError(this.handleError('getErrorTypeList', null)));
  }

  getFileAdminData(files: FileSubmission[]): any[] {
    const fileAdminData: Array<any> = [];
    files.forEach(file => {
      const data = {};
      file.submissionKeys.forEach(key => {
        let name = '';
        key.name.split('_').forEach((item, index) => {
          if (index > 0) {
            item = item.charAt(0).toUpperCase() + item.slice(1);
          }
          name = name + item;
        });
        data[name] = this.arrayToString.transform(key.values);
      });
      data['date'] = file.created;
      data['file'] = file.originalFileName;
      data['status'] = file.fileSubmissionState;
      data['formFormatExceptions'] = file;
      data['crossFieldExceptions'] = file;
      data['fileLevelValidations'] = file;
      data['numberOfRecords'] = file.totalDocuments;
      data['notes'] = file;
      data['auditTrail'] = file;
      data['reload'] = file;
      data['updateStatus'] = file;
      data['credible'] = file;
      data['rejectionReasons'] = file.rejectionReasons;
      data['submittedBy'] = file.submittedBy;
      fileAdminData.push(data);
    });
    return fileAdminData;
  }

  getPreSignedUrlfromS3(user: User, fileName: string): Observable<DownloadURLResponse> {
    const url = `${environment.apiUrl}${user.currentSchema}/fileadmin/download/url/${fileName}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const options = {
      headers: headers
    };
    return this.httpClient.get<DownloadURLResponse>(url, options)
      .pipe(catchError(this.handleError('getDownloadPreSignedUrlfromS3', null)));
  }

}
