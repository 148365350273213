import { Injectable } from "@angular/core";
import { Idle } from "@ng-idle/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { environment } from "../../environments/environment";
import { UserSessionInactivePopupComponent } from "../user-session-inactive-popup/user-session-inactive-popup.component";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  private countdownModalRef: BsModalRef;
  isTimeout: boolean = false;

  constructor(private idle: Idle, private modalService: BsModalService) {}

  configureSessionTimeout() {
    const session_popup_time = environment.auth.session_popup_time;
    const session_idle_time = environment.auth.session_idle_time;

    // Setting the idle and timeout durations
    this.idle.setIdle(session_idle_time);
    this.idle.setTimeout(session_popup_time);

    // Starting the idle timer
    this.idle.watch();

    this.idle.onIdleStart.subscribe(() => {
      // Countdown modal
      this.showCountdownModal();
      this.isTimeout = true; // Setting the timeout flag
    });

    // Resetting the idle timer on user activity
    window.addEventListener("mousemove", () => {
      if (!this.isTimeout) {
        this.resetIdleTimer();
      }
    });
  }

  showCountdownModal() {
    // Open the countdown modal
    this.countdownModalRef = this.modalService.show(
      UserSessionInactivePopupComponent,
      {
        backdrop: "static",
        keyboard: false,
        initialState: {
          countdownDuration: this.idle.getTimeout()
        },
      }
    );

    this.countdownModalRef.content.extendSessionClick.subscribe(() => {
      this.isTimeout = false;
      this.resetIdleTimer();
    });
  }

  resetIdleTimer() {
    this.idle.watch();
    this.hideCountdownModal();
  }

  hideCountdownModal() {
    if (this.countdownModalRef) {
      this.countdownModalRef.hide();
    }
  }
}
