<ngx-loading [show]="loading"></ngx-loading>
<div class="wrapper">
    <div id="aggregate">
        <div class="row page-title">
          <div class="col-12">
            <h1>{{title}}</h1>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li *ngFor="let crumb of breadcrumbs" class="breadcrumb-item">
                  <a [routerLink]="[crumb.link]" (click)="breadcrumbService.removeBreadcrumb(crumb)">{{crumb.label}}</a>
                </li>
                <li class="breadcrumb-item active">
                  {{breadcrumb.label}}
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <app-messages></app-messages>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12">
            <form name="aggregateParamValuesForm" class="form-inline" (submit)="getData()" #aggregateParamValuesForm="ngForm">
                <ng-container *ngFor="let param of aggregateData.params">
                  <label class="control-label mr-2" for="input:{{param.fieldName}}">{{param.fieldName | noUnderscore}}</label>
                  <input id="input:{{param.fieldName}}" name="input:{{param.fieldName}}" type="text" class="form-control mr-2"
                         [(ngModel)]="param.value" required/>
                </ng-container>
                <ng-container *ngIf="displayButton()">
                  <button id="btn:submit" type="submit" [disabled]="!aggregateParamValuesForm.valid" class="btn btn-primary">
                    <fa-icon [icon]="faPlay"></fa-icon> {{getButtonLabel()}}
                  </button>
                </ng-container>

            </form>
          </div>
        </div>

        <hr/>

        <ng-container *ngIf="rowData.length > 0 && columnDefs.length > 0">
          <div class="row mb-2">
            <div class="col-12">
              <label for="fileName" class="control-label mr-2">
                File Name: <input type="text" id="fileName" class="form-control form-control-sm" [(ngModel)]="csvFileName" />
              </label>
              <label><button type="button" class="btn btn-sm btn-secondary" (click)="csvExport()">Export to CSV</button></label>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <ag-grid-angular
                style="width: 100%; height: 400px;"
                #agGrid
                id="aggregateGrid"
                class="ag-theme-balham"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [rowData]="rowData"
                (gridReady)="onGridReady($event)"
                (rowDataUpdated)="sizeColumnsToFit()"
                (firstDataRendered)="onGridReady($event)"
                pagination="false"
                [paginationPageSize]="paginationPageSize">
              </ag-grid-angular>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 float-left">
              <span>Rows: {{rowData.length}}</span>
            </div>
          </div>
          <div *ngIf="rowData.length > 0" class="row mb-3">
            <div class="col-12">
              <button *ngIf="skip !== 0" id="btn:previous" type="button" (click)="previousPage()" class="btn btn-secondary pull-left">Previous</button>
              <button id="btn:next" type="button" *ngIf="rowData && rowData.length === limit" (click)="nextPage()" class="btn btn-secondary pull-right">Next</button>
            </div>
          </div>
        </ng-container>
      </div>
</div>

