<div class="wrapper scrollForm">
    <div class="row" id="form-builder:title:{{title}}">
        <h4 class="w-100"><span>{{dependenciesService.isSelectQuestionsVisible.value ? title : title2}}</span></h4>
        <p style="white-space: pre-wrap">{{dependenciesService.isSelectQuestionsVisible.value ? subTitle : subTitle2}}</p>
    </div>
    <hr />

<div *ngIf="!dependenciesService.isSelectQuestionsVisible.value" class="row float-right">
    <button class="btn btn-primary" (click)="goBackToQuestions()">Go Back to Questions</button>
</div>

<div *ngIf="filingBlank && dependenciesService.isSelectQuestionsVisible.value">
    <app-set-dependencies-select-questions *ngFor="let section of filingBlank.sections; let i = index;" 
        [section]="section" 
        [parentId]=""
        [index]="i" 
        [filteredQuestions] = "filteredQuestions" 
        [isSectionVisible] = true>
    </app-set-dependencies-select-questions>
</div>

<div *ngIf="!dependenciesService.isSelectQuestionsVisible.value">
    <app-set-dependencies-establish-rule *ngFor="let section of filingBlank.sections; let i = index;"
        [section]="section"
        [parentId]=""
        [count]= "1"
        summary="true"
        [filteredQuestions] = "filteredQuestions" 
        [questionNo]="count"
        [isSectionVisible] = true>
    </app-set-dependencies-establish-rule>
</div>

<div *ngIf="!dependenciesService.isSelectQuestionsVisible.value" class="row">
    <div class="col-12">
        <button id="btn:cancel" type="button" class="btn btn-link btn-sm" (click)="cancel()"> Cancel</button> &nbsp;
        <button id="btn:save" type="button" class="btn btn-primary" (click)="saveFilingBlank()" [disabled]="isSaveDisabled()"> Save </button> &nbsp;
        <button class="btn btn-primary float-right" (click)="goToTop(scrollForm)">Go to Top</button>
    </div>
</div>

<div *ngIf="filingBlank && dependenciesService.isSelectQuestionsVisible.value" class="row float-right">
    <button class="btn btn-primary" (click)="goToTop(scrollForm)">Go to Top</button>
</div>
</div>