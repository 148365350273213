import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {OnlineDataEntryComponent} from './online-data-entry.component';
import {UserFilingComponent} from './user-filing-dasboard/user-filing.component';


const routes: Routes = [
  {path: 'dataEntry/:id', component: OnlineDataEntryComponent},
  {path: 'user-filing/:schema', component: UserFilingComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OnlineDataEntryRoutingModule {
}
