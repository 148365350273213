import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { FileSubmissionErrorType } from './model/file-submission-error-type';
import { ActivatedRoute, Router } from '@angular/router';
import { FileSubmissionErrorsService } from '../../service/file-submission-errors.service';
import { UserService } from '../../service/user.service';
import { User } from '../../model/user';
import { UnCamelCasePipe } from '../../pipes/un-camel-case.pipe';
import { ExceptionsRendererComponent } from './cell-renderer/exceptions-renderer/exceptions-renderer.component';
import { FileService } from '../service/file.service';
import { ErrorTypeCounts } from '../../model/error-type-counts';
import { DownloadCsvRendererComponent } from './cell-renderer/download-csv-renderer/download-csv-renderer.component';
import { DocumentErrorList } from '../file-submission-error-type/model/document-error-list';
import {FileLevelExceptionTypes} from '../../model/file-level-exception-types';


@Component({
  selector: 'app-file-submission-errors',
  templateUrl: './file-submission-errors.component.html'
})
export class FileSubmissionErrorsComponent implements OnInit, OnDestroy {
  private _message = new Subject<string>();
  messageText: string;
  error = false;
  user: User;
  loading = false;
  documentId: string;
  errorList: Error[];
  fileSubmissionErrors: FileSubmissionErrorType[];
  fileLevelExceptionTypes: FileLevelExceptionTypes;
  fileSubmissionId: string;
  originalFileName: string;
  originalFileNameType: string;
  fileNameWithoutExtension: string;
  fileLevelExceptionsActualValue: string;
  fileLevelExceptionsExpectedValue: string;
  skip: number;
  limit = 25;
  subscriptions: Array<Subscription> = [];
  validationCategory: string;
  columnDefs = [];
  rowData = [];
  defaultColDef: any;
  context: any;
  frameworkComponents: any;
  paginationPageSize = 25;

  private gridApi;
  private gridColumnApi;
  formFormatErrorTypeCounts: ErrorTypeCounts[];
  crossFieldErrorTypeCounts: ErrorTypeCounts[];
  errors: ErrorTypeCounts[];
  documentErrorList: DocumentErrorList;

  skipForDownloadingExceptions: number = 0;
  limitForDownloadingExceptions: number = 10000;

  constructor(
    private route: ActivatedRoute,
    private fileSubmissionErrorsService: FileSubmissionErrorsService,
    private userService: UserService,
    private unCamelCase: UnCamelCasePipe,
    private router: Router,
    private fileService: FileService
  ) { }

  ngOnInit() {
    this.fileSubmissionId = this.route.snapshot.paramMap.get('fileSubmissionId');
    this.originalFileName = this.route.snapshot.paramMap.get('originalFileName');
    this.originalFileNameType = this.originalFileName.split('.').pop();
    this.fileNameWithoutExtension = this.originalFileName.split('.').slice(0, -1).join('.');
    this._message.subscribe(message => this.messageText = message);
    this.user = this.userService.getStoredUser();
    this.formFormatErrorTypeCounts = this.fileService.getFormFormatErrorTypeCounts();
    this.crossFieldErrorTypeCounts = this.fileService.getCrossFieldErrorTypeCounts();
    this.fileLevelExceptionTypes = this.fileService.getFileLevelExceptionTypes();
    if (this.fileLevelExceptionTypes) {
      this.fileLevelExceptionsActualValue = this.fileLevelExceptionTypes.actualValue;
      this.fileLevelExceptionsExpectedValue = this.fileLevelExceptionTypes.expectedValue;
    }
    if (!this.formFormatErrorTypeCounts && !this.crossFieldErrorTypeCounts) {
      this.getFileSubmissionErrors(0);
    } else {
      this.getFileSubmissionErrorsFromCurrent();
    }
    this.context = { componentParent: this };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this.frameworkComponents = {
      exceptionsRenderer: ExceptionsRendererComponent,
      downloadCsvRenderer: DownloadCsvRendererComponent
    };

  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getFileSubmissionErrors(skip: number) {
    this.loading = true;
    this.skip = skip;
    const sub = this.fileSubmissionErrorsService.getErrors(this.user.currentSchema, this.fileSubmissionId, this.skip, this.limit)
      .subscribe(
        fileSubmissionErrors => {
          this.error = false;
          this.fileSubmissionErrors = fileSubmissionErrors;
          this.fileSubmissionErrors.forEach(x => {
            x.downloadCsv = x.errorType;
          });
          this.rowData = this.fileSubmissionErrors;
          this.columnDefs = this.getColumnDef(this.rowData[0]);
          this.loading = false;
        },
        error => {
          this.error = true;
          this.messageText = <any>error;
          this._message.next(`${this.messageText}`);
          this.loading = false;
        }
      );
    this.subscriptions.push(sub);
  }

  getFileSubmissionErrorsFromCurrent() {
    if (this.formFormatErrorTypeCounts !== null && this.crossFieldErrorTypeCounts !== null) {
      this.errors = this.formFormatErrorTypeCounts.concat(this.crossFieldErrorTypeCounts);
    }
    this.errors.forEach(x => {
      x.downloadCsv = x.type;
    })
    this.rowData = this.errors;
    if (this.rowData.length !== 0) {
      this.columnDefs = this.getColumnDefFromCurrent(this.rowData[0]);
    }
  }

  getColumnDef(row: any): any[] {
    const columnsDef: any[] = [];
    const keys = Object.keys(row);
    keys.forEach(key => {
      const headerName = this.unCamelCase.transform(key).replace('Error', 'Exception');
      if (headerName === 'Exception Count') {
        columnsDef.push({ headerName: headerName, field: key, cellRenderer: 'exceptionsRenderer' });
      } else if (headerName === 'Download Csv') {
        columnsDef.push({ headerName: 'Download CSV (Max 10K exceptions)', field: key, cellRenderer: 'downloadCsvRenderer' });
      } else {
        columnsDef.push({ headerName: headerName, field: key });
      }
    });
    return columnsDef;
  }

  getColumnDefFromCurrent(row: any): any[] {
    const columnsDef: any[] = [];
    const keys = Object.keys(row);
    keys.forEach(key => {
      const headerName = this.unCamelCase.transform(key);
      if (headerName === 'Count') {
        columnsDef.push({ headerName: 'Exception Count', field: key, cellRenderer: 'exceptionsRenderer' });
      } else if (headerName === 'Download Csv') {
        columnsDef.push({ headerName: 'Download CSV (Max 10K exceptions)', field: key, cellRenderer: 'downloadCsvRenderer' });
      } else {
        columnsDef.push({ headerName: 'Exception Type', field: key });
      }
    });
    return columnsDef;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  getErrorTypeData(row: any): any {
    if (this.formFormatErrorTypeCounts === null && this.crossFieldErrorTypeCounts === null) {
      return {
        fileSubmissionId: this.fileSubmissionId,
        errorType: row.errorType,
        originalFileName: this.originalFileName,
      };
    } else {
      return {
        fileSubmissionId: this.fileSubmissionId,
        errorType: row.type,
        originalFileName: this.originalFileName,
      };
    }
  }

  goToErrorType(row: any): void {
    this.router.navigate(['/fileSubmissionErrorType', this.getErrorTypeData(row)]);
  }

  downloadCsv(row) {
   let errorTypeData = this.getErrorTypeData(row);
   this.loading = true;
   const sub = this.fileService.getErrorTypeList(this.user.currentSchema, errorTypeData.fileSubmissionId, errorTypeData.errorType, this.skipForDownloadingExceptions, this.limitForDownloadingExceptions)
     .subscribe(
       documentErrorList => {
         this.loading = false;
         this.documentErrorList = documentErrorList;
         this.createCsvFile(this.documentErrorList, errorTypeData.errorType);
       }
     );
   this.subscriptions.push(sub);
  }

  createCsvFile(documentErrorList: DocumentErrorList, errorType: string) {
    let csvDocumentFile: any[] = [];
    documentErrorList.documentsWithErrors.forEach(x => {

      let errorMessages: any[] =[];
       x.errors.forEach(y => {
        errorMessages.push(y.errorMessage)
      });

      x.document = Object.assign( x.document, {error_message: String(errorMessages)});
      csvDocumentFile.push(x.document);
    });
    this.saveDataInCSV(errorType, csvDocumentFile);
  }

  saveDataInCSV(name: string, data: Array<any>): void {
    let csvContent = this.fileService.saveDataInCSV(data);
    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
    hiddenElement.target = '_blank';
    hiddenElement.download = name + '.csv';
    hiddenElement.click();
  }
}
