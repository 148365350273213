import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import * as _ from 'lodash';
import {QuestionControlService} from '../dynamic-forms/question-control-service';
import {FormQuestion} from '../model/form-question';
import {FormSection} from '../model/form-section';
import {InputErrorWarning} from '../model/input-error-warning';
import {OnlineDataEntryService} from '../services/online-data-entry.service';
import {User} from '../../model/user';
import {UserService} from '../../service/user.service';
import {OnlineSubmissionsStatus} from '../model/online-submissions-status';
import { FilingBlank } from '../model/filingblank';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-form-section',
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.css']
})
export class FormSectionComponent implements OnInit, AfterViewInit {
  @ViewChild('pdfContent', {static: true}) pdfContent: ElementRef;
  @Input() section: FormSection;
  @Input() status: string;
  @Input() parentId: string;
  @Input() index: number;
  @Input() disabled = false;
  @Input() previous: string;
  @Input() next: string;
  @Input() nextSectionName: string;
  @Input() parentPath: string;
  @Input() summary: boolean;
  @Input() questionNo: any;
  @Output() goToSection = new EventEmitter<string>();
  @Input() filingBlank: FilingBlank;

  sectionForm: FormGroup;
  sectionFormForRules: FormGroup;
  path: any;
  inputLabels: any[] = [];
  sectionHeaders: any = {};
  updatedModel: any;

  fileProcessingMessage = 'This filing is locked until processing is completed.';

  sectionErrors: InputErrorWarning[] = [];
  questionErrors: any = {};
  sectionWarnings: InputErrorWarning[] = [];
  questionWarnings: any = {};
  user: User;
  onInitFormFormatErrors: any[] = [];
  inputLabelsForRules: any[] = [];

  constructor(
    private qcs: QuestionControlService,
    private onlineDataEntryService: OnlineDataEntryService,
    private userService: UserService
  ) {
  }

  // Display Question Sequentially
  public getQuestionNumbers() {
    this.questionNo.current = this.questionNo.current + 1;
    return this.questionNo;
  }

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    this.getErrorsAndWarnings();
    this.getInputLabels(this.section);
    this.getOrderOfSections(this.section);
    this.sectionForm = this.qcs.generateForm(this.section, this.isFormSectionEnabled(), this.summary, false);
    this.sectionFormForRules = this.qcs.generateForm(this.section, this.isFormSectionEnabled(), this.summary, true);
    this.onlineDataEntryService.masterSectionForm.push(this.sectionForm.value);
    let masterFilingBlank = this.onlineDataEntryService.getOriginalFilingBlank();
    if(!this.onlineDataEntryService.masterSelectedValues) {
      this.onlineDataEntryService.isMasterValueEmpty.next(true);
    }
    if (!this.summary) {
      this.setDataValues(masterFilingBlank.sections);
      this.onlineDataEntryService.dataUpdates = this.sectionForm.value;
      this.onlineDataEntryService.fileURIs = [];
    }
    this.onChange();
    this.setRules(this.section);
  }

  setDataValues(sections: FormSection[]) {
    sections.forEach(section => {
      if (!isNullOrUndefined(this.onlineDataEntryService.masterSelectedValues) && this.onlineDataEntryService.masterSelectedValues[section.key]) {
        const renamedDataEntry = this.renameKeys(section.questions, this.onlineDataEntryService.masterSelectedValues[section.key]);
        this.onlineDataEntryService.masterSelectedValues[section.key] = renamedDataEntry;
      }
      if(section.sections.length > 0) {
        this.setDataValues(section.sections);
      }
    });
  }

  ngAfterViewInit() {
    this.onInitFormFormatErrors = [];
    this.setInitialFormatErrors();
  }

  setInitialFormatErrors() {
    this.updatedModel.forEach(model => {
      if(model.inputs.length > 0) {
        model.inputs.forEach(input => {
          if(input.dataType === 'string') {
            this.onStringInput(input.value, input, true);
          } else {
            this.onIntegerInput(input.value, input, true);
          }
        });
      }
    });
    if(this.onInitFormFormatErrors.some(element => element === true)) {
      this.onlineDataEntryService.isFormFormatError = true;
    }
  }

  onChange(): void {
    if (this.onlineDataEntryService.activeSection.length > 0) {
      this.sectionForm.valueChanges.subscribe(val => {
        this.onlineDataEntryService.dataChanged = true;
        const valKeys = _.keys(val);
        valKeys.forEach(key => {
          const data = val[key];
          Object.keys(data).forEach(x => {
            Object.keys(data[x]).forEach(y => {
              if (data[x][y] === null || data[x][y] === undefined) {
                data[x][y] = '';
              }
            });
          });
          this.onlineDataEntryService.dataUpdates[key] = data;
        });
      });
    }
  }

  getActiveSection(): string {
    return this.onlineDataEntryService.activeSection;
  }

  getQuestionNumber(key: string): number {
    let keyArray = key.split('-');
    return parseInt(keyArray[keyArray.length - 1], 10);
  }

  getInputStyle(input: any): string[] {
    // let styles: string[] = ['border', 'border-danger'];
    let styles: string[] = ['form-control', 'form-control-sm'];
    let errorOrWarning = this.isErrorOrWarning(input);
    if (errorOrWarning.length > 0) {
      styles.push(errorOrWarning);
    }
    return styles;
  }

  isErrorOrWarning(input: any): string {
    if (input.errors && input.errors.length > 0) {
      return 'is-invalid';
    } else if (input.warnings && input.warnings.length > 0) {
      return 'is-warning';
    } else {
      return '';
    }
  }

  getErrorsAndWarnings() {
    this.section.questions.forEach((question: FormQuestion) => {
      let errors: string[] = [];
      let warnings: string[] = [];
      question.inputs.forEach(input => {
        if (input.errors) {
          input.errors.forEach(error => {
            if (errors.indexOf(error.message) === -1) {
              errors.push(error.message);
            }
            // errors.push(error.message);
            if (this.sectionErrors.indexOf(error.message) === -1) {
              this.sectionErrors.push(error.message);
            }
          });
        }
        if (input.warnings) {
          input.warnings.forEach(warning => {
            if (warnings.indexOf(warning.message) === -1) {
              warnings.push(warning.message);
            }
            // warnings.push(warning.message);
            if (this.sectionWarnings.indexOf(warning.message) === -1) {
              this.sectionWarnings.push(warning.message);
            }
          });
        }
      });
      this.questionErrors[question.key] = errors;
      this.questionWarnings[question.key] = warnings;
    });
  }

  onIntegerInput(event, input, onInitialize?) {
    let enteredValue = onInitialize ? event : event.target.value;
    if (!enteredValue) {
      this.checkRequiredFields(enteredValue, input);
    } else if (parseFloat(enteredValue) < parseFloat(input.min) || parseFloat(enteredValue) > parseFloat(input.max)) {
      this.setFormFormatError(true, input, onInitialize);
    } else {
      this.setFormFormatError(false, input, onInitialize);
    }
  }

  onStringInput(event, input, onInitialize?) {
    let enteredValue = onInitialize ? event : event.target.value;
    if (!enteredValue) {
      this.checkRequiredFields(enteredValue, input);
    } else if ((input.minLength && String(enteredValue).length < input.minLength) ||
      (input.maxLength && String(enteredValue).length > input.maxLength) || (input.pattern && !String(enteredValue).match(input.pattern))) {
      this.setFormFormatError(true, input, onInitialize);
    } else {
      this.setFormFormatError(false, input, onInitialize);
    }
  }

  setFormFormatError(error: boolean, input, onInitialize?) {
    // use this to disable save and validate button for form format errors.
    // this.onlineDataEntryService.formFormatError = error;
    if (document.getElementById(input.schemaPropertyNameKey)) { document.getElementById(input.schemaPropertyNameKey).className = '' }
    if (error) {
      onInitialize ? this.onInitFormFormatErrors.push(true) : this.onInitFormFormatErrors.push(false);
      this.onlineDataEntryService.isFormFormatError = true;
      if (document.getElementById(input.schemaPropertyNameKey)) { document.getElementById(input.schemaPropertyNameKey).className = 'inv' }
    } else {
      this.onInitFormFormatErrors.push(false);
      this.onlineDataEntryService.isFormFormatError = false;
    }
  }

  checkRequiredFields(enteredValue, input) {
    if (input.required) {
      this.setFormFormatError(true, input);
    } else {
      this.setFormFormatError(false, input);
    }
  }

  disableChildren(disableableInputs: any, inputKey: string, questionKey: string) {
    if (this.section) {
      this.sectionForm.controls.disableableInputs.disable();
    } else {
      this.sectionForm.controls.disableableInputs.enable();
    }
    if (disableableInputs) {
      if (disableableInputs.disabledValue === this.sectionForm.get(`${this.path}.${questionKey}.${inputKey}`).value) {
        disableableInputs.disabledInputs.forEach(input =>
          this.sectionForm.get(input).disable()
        );
      } else {
        disableableInputs.disabledInputs.forEach(input =>
          this.sectionForm.get(input).enable()
        );
      }
    }
  }

  getSectionStyles(): string[] {
    let headerNumber = this.section.sectionNumber + 3;
    let marginLeftNumber =
      (this.section.sectionNumber === 1
        ? this.section.sectionNumber - 1
        : this.section.sectionNumber) + 2;
    let styles: string[] = [];
    styles.push(`align-bottom`);
    return styles;
  }

  getInputLabels(section: FormSection) {
    this.inputLabels = [];
    if (section.questions.length > 0) {
      let question = section.questions[0];
      question.inputs.forEach((input, index) => {
        this.sectionHeaders[input.label] = index;
        this.inputLabels.push(input.label);
        this.inputLabelsForRules.push({key: input.key, label: input.label})
      });
    }
  }

  getSectionId(): string {
    let sectionId = '';
    if (this.parentId) {
      sectionId = `${this.parentId}-${this.section.key}`;
    } else {
      sectionId = this.section.key;
    }
    return sectionId;
  }

  formatSectionName(sectionName: string): string {
    return this.formatSectionsName(sectionName);
  }

  goTo(section: string): void {
    this.goToSection.emit(section);
  }

  formatSectionsName(sectionName: string): string {
    let sections = sectionName.split('.');
    const allSections = this.onlineDataEntryService.getAllSections().sections;
    let sctnName = this.getNameByKey(allSections, sections[0]);
    return sctnName;
  }


  getNameByKey(sections: any[], key: string): string {
    const foundSection = this.findSectionByKey(sections, key);
    return foundSection ? foundSection.name : '';
  }

  findSectionByKey(sections: FormSection[], key: string): FormSection {
    for (const section of sections) {
      if (section.key === key) {
        return section;
      }

      if (section.sections && section.sections.length > 0) {
        const nestedSection = this.findSectionByKey(section.sections,key);
        if (nestedSection) {
          return nestedSection;
        }
      }
    }
  }

  isFormSectionEnabled(): boolean {
    return this.status === OnlineSubmissionsStatus.PROCESSING || this.userService.canViewFileAdminLink(this.user);
  }

  getOrderOfSections(section: FormSection) {
    this.updatedModel = this.onlineDataEntryService.setFormData(section);
  }

  isQuestionVisible(model): boolean {
    if(this.onlineDataEntryService.newRuleKeys) {
      return !this.onlineDataEntryService.newRuleKeys.includes(model.key);
    } else {
      return true;
    }
  }

  isSectionVisible(section): boolean {
    if(this.onlineDataEntryService.newRuleKeys) {
      return !this.onlineDataEntryService.newRuleKeys.includes(section.key);
    } else {
      return true;
    }
  }

  isLabelVisible(input) {
    if(this.onlineDataEntryService.newRuleKeys) {
      return !this.onlineDataEntryService.newRuleKeys.includes(input.key);
    } else {
      return true;
    }
  }

  isLabelHeadingVisible(label) {
    let labels = this.inputLabelsForRules.filter(item => this.onlineDataEntryService.newRuleKeys.includes(item.key)).map(item => item.label);
    if(labels.includes(label)) {
      return false;
    } else {
      return true;
    }
  }

  setRules(section) {
    if (!this.summary) {
    const renamedDataEntry = this.renameKeys(section.questions, this.onlineDataEntryService.dataUpdates[section.key]);
    this.onlineDataEntryService.masterSelectedValues[section.key] = renamedDataEntry;
    }
    let masterFilingBlank = this.onlineDataEntryService.getOriginalFilingBlank();
    this.onlineDataEntryService.newRuleKeys = [];
    this.getRuleKeys(masterFilingBlank.sections, this.onlineDataEntryService.masterSelectedValues);
  }

  getRuleKeys(sections: FormSection[], masterSelectedValues: any) {

    sections.forEach(section => {
      const newDataEntry = masterSelectedValues[section.key];
      this.processQuestion(section, newDataEntry);
      if (section.sections.length > 0) {
        this.getRuleKeys(section.sections, masterSelectedValues);
      }
    })

    // Remove duplicates by converting to Set and back to array
    const uniqueRuleKeys: string[] = Array.from(new Set<string>(this.onlineDataEntryService.newRuleKeys));
    this.onlineDataEntryService.newRuleKeys = uniqueRuleKeys;
  }

  processQuestion(section, newDataEntry) {
      for (const q of section.questions) {
        if (!isNullOrUndefined(newDataEntry)) {
          if (q.key in newDataEntry) {
            const ruleInputs = q.rules.map(rule => rule.ruleInputs);
            newDataEntry[q.key].map(entry => entry.value.replace(/\s/g, ''));
            this.newDataEntries(ruleInputs, newDataEntry, q);
          }
        }
      }
  }

  newDataEntries(ruleInputs, newDataEntry, q) {
    for (const inputs of ruleInputs) {
      if (inputs.length === newDataEntry[q.key].length) {
        const inputsMatch = inputs.every(input =>
          newDataEntry[q.key].some(entry =>
            entry.schemaPropertyNameKey === input.schemaPropertyNameKey &&
            entry.value.replace(/\s/g, '') === input.value.replace(/\s/g, '')
          )
        );

        this.inputsMatch(inputsMatch, q, inputs)
      }
    }
  }

  inputsMatch(inputsMatch, q, inputs) {
    if (inputsMatch) {
      for (const rule of q.rules) {
        if(inputs === rule.ruleInputs) {
        this.onlineDataEntryService.newRuleKeys.push(...rule.ruleKeys.map(ruleKey => ruleKey.key));
        }
      }
    }
  }

  renameKeys(question, dataEntry) {
    const newDataEntry = {};

    for (const q of question) {
      const label = q.label;
      const inputs = q.inputs;


        if (label in dataEntry) {
          const newKey = q.key;
          newDataEntry[newKey] = [];

          for (const input of inputs) {
            const schemaPropertyNameKey = input.schemaPropertyNameKey;
            const key = input.key;

            const value = dataEntry[label][schemaPropertyNameKey];
            if (value !== undefined) {
              newDataEntry[newKey].push({ schemaPropertyNameKey: key, value });
            }
          }
        }

    }

    return newDataEntry;
  }
}
