import { OrderOfPrecedence } from "../model/order-of-precedence";
import { orderOfPrecedence } from "./expression-builder-constant-values";

declare function require(name: string);

export class StringToModelParserService {
  validation: any;
  orderOfPrecedence: OrderOfPrecedence[];

  constructor() {
    this.validation = {};
    this.validation.name = 'Root Name';
    this.validation.expressionBuilder = [{
      'name': 'Test 1',
      'andOrCondition': '||',
      'expressions': [
        {
          'expression': ''
        }
      ],
      'trueAction': {
        'type': '',
        'value': ''
      },
      'falseAction': {
        'type': '',
        'value': ''
      }
    }];
  }

  /* Deal with nodes in an array */
  visitNodes(nodes) {
    for (const node of nodes) {
      this.visitNode(node, node);
    }
  }

  /* Dispatch each type of node to a function */
  visitNode(node, parentNode?) {
    switch (node.type) {
      case 'Program': return this.visitProgram(node);
      case 'Identifier': return this.visitIdentifier(node);
      case 'Literal': return this.visitLiteral(node);
      case 'IfStatement': return this.visitIfStatement(node);
      case 'UnaryExpression': return this.visitUnaryExpression(node);
      case 'BinaryExpression': return this.visitBinaryExpression(node, parentNode);
      case 'MemberExpression': return this.visitMemberExpression(node);
      case 'BlockStatement': return this.visitBlockStatement(node);
      case 'ReturnStatement': return this.visitReturnStatement(node);
      case 'LogicalExpression': return this.visitLogicalExpression(node);
      case 'CallExpression': return this.visitCallExpression(node);
    }
  }

  /* Functions to deal with each type of node */
  visitProgram(node) {
    return this.visitNodes(node.body);
  }

  visitIdentifier(node) {
    return node.name;
  }

  visitLiteral(node) {
    return node.raw;
  }

  visitIfStatement(node, modelObj?) {
    let testModelObj: any = {};
    testModelObj.name = 'Test-' + this.validation.expressionBuilder.length + 1;

    let expressions = new Array();
    if (node.test.type === 'LogicalExpression') {
      testModelObj = this.visitNode(node.test, node);
    } else {
      let expression: any = {};
      expression.expression = this.visitNode(node.test, node);
      expressions.push(expression);
      testModelObj.expressions = expressions;
    }

    let trueActionObj: any = {};
    trueActionObj.type = 'VALIDATION_RESULT';
    trueActionObj.value = this.visitNode(node.consequent, node);
    testModelObj.trueAction = trueActionObj;

    let falseActionObj: any = {};
    falseActionObj.type = 'VALIDATION_RESULT';
    falseActionObj.value = this.visitNode(node.alternate, node);
    testModelObj.falseAction = falseActionObj;

    this.validation.expressionBuilder.push(testModelObj);
    return testModelObj;
  }

  visitBinaryExpression(node, parentNode) {
    if (parentNode.type === 'BinaryExpression' && this.isHigherPrecendence(parentNode.operator, node.operator)) {
      return '(' + this.visitNode(node.left, node) + node.operator + this.visitNode(node.right, node) + ')';
    } else {
      return this.visitNode(node.left, node) + node.operator + this.visitNode(node.right, node);
    }
  }

  visitMemberExpression(node) {
    return this.visitNode(node.object, node) + '[' + this.visitNode(node.property, node) + ']';
  }

  visitBlockStatement(node) {
    return this.visitNode(node.body[0], node);
  }

  visitReturnStatement(node) {
    return this.visitNode(node.argument, node);
  }

  visitLogicalExpression(node) {
    let logicalExpressionObj: any = {};

    logicalExpressionObj.andOrCondition = node.operator;
    logicalExpressionObj.expressions = new Array();

    let leftExpression: any = {};
    leftExpression = this.visitNode(node.left, node);

    if (node.left.type === 'LogicalExpression') {
      if (leftExpression.andOrCondition === node.operator) {
        logicalExpressionObj.expressions = [].concat.apply(logicalExpressionObj.expressions, leftExpression.expressions);
      } else {
        logicalExpressionObj.expressions.push(leftExpression);
      }

    } else {
      let expressionObj: any = {};
      expressionObj.expression = leftExpression;
      logicalExpressionObj.expressions.push(expressionObj);
    }

    let rightExpression: any = {};
    rightExpression = this.visitNode(node.right, node);

    if (node.right.type === 'LogicalExpression') {
      if (rightExpression.andOrCondition === node.operator) {
        logicalExpressionObj.expressions = [].concat.apply(logicalExpressionObj.expressions, rightExpression.expressions);
      } else {
        logicalExpressionObj.expressions.push(rightExpression);
      }
    } else {
      let expressionObj: any = {};
      expressionObj.expression = rightExpression;
      logicalExpressionObj.expressions.push(expressionObj);
    }
    return logicalExpressionObj;
  }

  visitCallExpression(node) {
    let returnString: string = node.callee.name + '(';

    for (let i = 0; i < node.arguments.length; i++) {
      returnString += this.visitNode(node.arguments[i], node);
      if (i < node.arguments.length - 1) { returnString += ','; }
    }
    returnString += ')';
    return returnString;
  }

  private visitUnaryExpression(node: any) {
    return node.operator + this.visitNode(node.argument);
  }

  findValidationStartNode(node) {
    return node.body[0].body.body[0];
  }

  validationStringToModel(validationStr): any {
    let acorn = require('acorn');
    let visitor = new StringToModelParserService();

    if (validationStr === undefined || validationStr === 'undefined' || validationStr === '' || validationStr === 'null' || validationStr === null) {
      return visitor.validation;
    }
    visitor.validation.expressionBuilder = new Array();

    let ast = acorn.parse('function run(data) {' + validationStr + '}');
    let astValidationStartNode = visitor.findValidationStartNode(ast);

    visitor.visitNode(astValidationStartNode);

    this.checkSyntaxValidaton(validationStr, visitor);

    return visitor.validation;
  }

  checkSyntax(validation: any) {
    return validation.expressionBuilder[0].expressions.some(x => String(x.expression).includes('[object Object]') === true);
  }

  checkSyntaxValidaton(validationStr, visitor) {
    let syntaxCheck: boolean;
    if (validationStr !== undefined || validationStr !== 'undefined' || validationStr !== '' || validationStr !== 'null') {
      syntaxCheck = this.checkSyntax(visitor.validation);
    }
    if (syntaxCheck) {
      throw SyntaxError('Invalid Syntax: Expression is incorrect');
    }
  }

  private isHigherPrecendence(operator1: any, operator2: any): boolean {
    // is first operator higher precedence then operator2
    this.orderOfPrecedence = orderOfPrecedence;
    let operator1order: number;
    let operator2order: number;
    this.orderOfPrecedence.forEach(x => {
      if (x.operator === operator1) {
        operator1order = x.order;
      }
      if (x.operator === operator2) {
        operator2order = x.order;
      }
    });
    if (operator1order < operator2order) {
      return true;
    } else {
      return false;
    }
  }
}
