/*
 * Copyright 2018 National Association of Insurance Commissioners
 */

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ImportExportComponent} from './import-export.component';


const importExportRoutes: Routes = [{path: 'importExport', component: ImportExportComponent}];

@NgModule({
  imports: [
    RouterModule.forChild(importExportRoutes)
  ],
  exports: [RouterModule]
})
export class ImportExportRoutingModule { }
