import { Injectable } from '@angular/core';
import {HandleError, HttpErrorHandler} from '../../../shared/http-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Datacall} from '../model/datacall';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatacallCreationService {

  readonly handleError: HandleError;
  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('DatacallCreationService');
  }

  createDataCall(groupName: string, dataCall: Datacall): Observable<any> {
    const url = `${environment.apiUrl}datacallgroups/${groupName}/datacalls`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.httpClient.post(url, dataCall, options);
      // .pipe(catchError(this.handleError('createDataCall', null)));
  }

}
