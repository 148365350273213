import { Component } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-download-csv-renderer',
  templateUrl: './download-csv-renderer.component.html',
  styleUrls: ['./download-csv-renderer.component.css']
})
export class DownloadCsvRendererComponent implements  ICellRendererAngularComp {
  params: any;



  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  downloadCsv() {
    this.params.context.componentParent.downloadCsv(this.params.data);
  }

}
 