import {Component, OnDestroy, OnInit} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {UserService} from '../../service/user.service';
import {User} from '../../model/user';
import {Datacall} from './model/datacall';
import {DatacallCreationService} from './services/datacall-creation.service';
import {Subject, Subscription} from 'rxjs';
import {MessageService} from '../../messages/service/message.service';
import {HttpErrorResponse} from '@angular/common/http';
import { ANSI } from '../../../assets/reservedWords/ANSI';

@Component({
  selector: 'app-datacall-creation',
  templateUrl: './datacall-creation.component.html'
})
export class DatacallCreationComponent implements OnInit, OnDestroy {

  modalRef: BsModalRef;
  dataCallGroupFromParent: string;
  datacallForm: FormGroup;
  user: User;
  userIdList: string[];
  selectedUserId: string;
  private _message = new Subject<string>();
  subscriptions: Array<Subscription> = [];
  messageText: string;
  error = false;

  constructor(
    private userService: UserService,
    private dataCallCreationService: DatacallCreationService,
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this._message.subscribe(message => this.messageText = message);

    this.datacallForm = this.formBuilder.group({
      datacallGroup: [this.dataCallGroupFromParent],
      datacallName: ['', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9_]*$/),
        this.noNumberStart, 
        this.noSpecialCharsOrSpaces,
        this.noANSIWords,
        Validators.maxLength(40),
        Validators.minLength(2)
      ]],
      datacallDisplayName: ['', Validators.maxLength(128)],
      isOnlineDataEntry: [false],
      fileFormatType: ['', Validators.required],
      userId: ['', [Validators.pattern, Validators.maxLength(100)]],
      userIdList: ['', Validators.required],
      userRole: ['', [Validators.pattern, Validators.maxLength(128)]],
      adminRole: ['', [Validators.required, Validators.pattern, Validators.maxLength(128)]]
    });

    this.userIdList = [];
    this.selectedUserId = '';
    this.onChange();
  }

  noNumberStart(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value;
    if (value && /^[0-9_]/.test(value)) {
      return { 'noNumberStart': true };
    }
    return null;
  }
  
  // Custom validator to check for no special characters except underscores and no spaces
  noSpecialCharsOrSpaces(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value;
    if (value && /[^a-zA-Z0-9_]/.test(value)) {
      return { 'noSpecialCharsOrSpaces': true };
    }
    return null;
  }

  noANSIWords(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value;
    if (value && ANSI.includes(value)) {
      return { 'noANSIWords': true };
    }
    return null;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  // convenience getter for easy access to form fields
  get form() { return this.datacallForm.controls; }

  onChange() {
    this.datacallForm.get("isOnlineDataEntry").valueChanges
      .subscribe(data => {
        this.changeValidators()
      });
    this.datacallForm.get('datacallName').valueChanges.subscribe((value) => {
      if (value) {
        this.datacallForm.get('datacallName').setValue(value.toLowerCase(), { emitEvent: false });
      }
    });
  }

  changeValidators() {
    if (this.datacallForm.get("isOnlineDataEntry").value) {
      this.datacallForm.controls["fileFormatType"].clearValidators();
    } else {
      this.datacallForm.controls["fileFormatType"].setValidators([Validators.required]);
    }
    this.datacallForm.controls["fileFormatType"].updateValueAndValidity();
  }

  save(): void {
    const newDataCall: Datacall = new Datacall();
    newDataCall.dataCallName = this.form.datacallName.value;
    newDataCall.dataCallDisplayName = this.form.datacallDisplayName.value;
    newDataCall.isOnlineDataEntry = this.form.isOnlineDataEntry.value;
    newDataCall.fileFormatType = (newDataCall.isOnlineDataEntry) ? null : this.form.fileFormatType.value;
    newDataCall.responsiblePartiesUserNames = this.form.userIdList.value;
    newDataCall.userRole = this.form.userRole.value;
    newDataCall.adminRole = this.form.adminRole.value;

    const sub = this.dataCallCreationService.createDataCall(this.dataCallGroupFromParent, newDataCall)
      .subscribe(
        res => {
          if (res) {
            this.error = false;
            this.messageText = this.form.datacallName.value + ' Data Call added';
          }
          this.bsModalRef.hide();
        },
        (err: HttpErrorResponse) => {
          this.error = true;
          this.messageText = '';
          if (err.status === 409) {
            this.messageText = 'Datacall Name is not unique';
          } else {
            const maybeErrorMessage = err.error || '';
            this.messageText = `Error received - ${err.status} ${maybeErrorMessage}`;
          }
        }
      );
    this.subscriptions.push(sub);
   }

  addUserIdToList() {
    const index = this.userIdList.indexOf(this.form.userId.value);
    if (index === -1) {
      this.userIdList.push(this.form.userId.value);
      this.form.userIdList.setValue(this.userIdList);
      this.form.userId.setValue('');
    }
  }

  deleteUserIdFromList() {
    if (this.selectedUserId !== '') {
      this.userIdList.splice(this.userIdList.indexOf(this.selectedUserId), 1);
      this.form.userIdList.setValue(this.userIdList);
      this.selectedUserId = '';
    }
  }

  setSelectedUserId(event) {
    this.selectedUserId = event.target.value;
  }

}
