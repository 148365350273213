import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {FileSubmissionStateModel} from '../../update-status/model/file-submission-state-model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HandleError, HttpErrorHandler} from '../../../shared/http-error-handler.service';
import {catchError} from 'rxjs/operators';

@Injectable()
export class NotesService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('NotesService');
  }

  updateFileSubmissionNotes(schemaType: string, fileSubmissionStateData: FileSubmissionStateModel): Observable<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = {headers: headers};
    return this.httpClient.put<any>(`${environment.apiUrl}${schemaType}/fileadmin/notes`, fileSubmissionStateData, options)
      .pipe(catchError(this.handleError('updateFileSubmissionNotes', null)));

  }

}
