<div id="notes">
  <div class="modal-header">
    <h3 class="modal-title">{{title}}</h3>
  </div>
  <div class="modal-body">
    <div *ngIf="fileSubmission.notes" class="row mb-3">
      <div class="col-12">
        <ngx-datatable
          class="bootstrap"
          [rows]="rows"
          [columnMode]="'force'"
          [headerHeight]="'auto'"
          [footerHeight]="50"
          [rowHeight]="'auto'"
          [reorderable]="false"
          [loadingIndicator]="loadingIndicator"
          [sorts]="[{prop: 'date', dir: 'desc'}]"
          [scrollbarH]="scrollBarHorizontal"
          [limit]="5">
          <ngx-datatable-column name="Date">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
              <span class="font-weight-bold">{{column.name}}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{value | date: 'shortDate'}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="User">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
              <span class="font-weight-bold">{{column.name}}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{value}}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column name="Note">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
              <span class="font-weight-bold">{{column.name}}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{value}}
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <textarea id="textareaComments" [(ngModel)]="fileSubmission.comments" placeholder="New Note..."></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-link btn-sm" type="button" (click)="bsModalRef.hide()">Close</button>
    <button class="btn btn-primary" type="button" (click)="addNote()" [disabled]="fileSubmission.comments === undefined || (fileSubmission.comments).trim() === ''">Add Note</button>
  </div>
</div>
