import { NgModule } from '@angular/core';
import { NoUnderscorePipe } from './no-underscore.pipe';
import { UnCamelCasePipe } from './un-camel-case.pipe';
import { ArrayToStringPipe } from './array-to-string.pipe';

@NgModule({
  imports: [
  ],
  declarations: [NoUnderscorePipe, UnCamelCasePipe, ArrayToStringPipe],
  exports: [NoUnderscorePipe, UnCamelCasePipe, ArrayToStringPipe]
})
export class PipesModule { }
