<div id="expressionBuilder" class="wrapper">
  <div class="row">
    <div class="col-12">
      <h1>{{title}}</h1>
    </div>
  </div>

  <div *ngIf="breadcrumbs" class="row">
    <div class="col-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li *ngFor="let crumb of breadcrumbs" class="breadcrumb-item">
            <a [routerLink]="[crumb.link]" (click)="breadcrumbService.removeBreadcrumb(crumb)">{{crumb.label}}</a>
          </li>
          <li class="breadcrumb-item active">
            {{breadcrumb.label}}
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <h3> {{validationName}} </h3>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <app-messages></app-messages>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <form name="expressionBuilderForm" [formGroup]="expressionBuilderForm">


        <div formArrayName="expressionBuilder">
          <div *ngFor="let express of expressionBuilderForm['controls'].expressionBuilder['controls']; let i=index;"
            [formGroupName]="i" style="padding-bottom: 15px;">
            <input type="text" formControlName="name" [value]="i + 1" hidden>

            <mat-accordion multi [togglePosition]="'before'" [displayMode]="'flat'">
              <mat-expansion-panel expanded="true">
                <mat-expansion-panel-header>
                  <mat-panel-title class="font-weight-bold">Test {{i + 1}}</mat-panel-title>
                </mat-expansion-panel-header>
                <p>If</p>

                <app-expression-set [expression]="express" [even]="i" [expressionBuilder]="expressionBuilderForm"></app-expression-set>

                <div class="row">
                  <div class="col-3">
                <div class="pb-2" formGroupName="trueAction">Then
                  <select id="thenOptionId" class="ml-1 mr-2 h-100 rounded" formControlName="value"
                    (change)="setResultCondition(i, $event, 'trueAction')">
                    <option *ngFor="let option of passFailOptions" [value]="option.label" [disabled]=disableIfRdcAdmin>
                      {{option.value}}</option>
                  </select>
                </div>
                <div class="" formGroupName="falseAction">Else
                  <select id="elseOptionId" class="ml-2 mr-2 h-100 rounded" formControlName="value"
                    (change)="setResultCondition(i, $event, 'falseAction')">
                    <option *ngFor="let option of passFailOptions" [value]="option.label" [disabled]=disableIfRdcAdmin>
                      {{option.value}}</option>
                  </select>
                </div>
              </div>
                <div *ngIf="!disableIfRdcAdmin" class="col" style="float:right">
                  <button style="float:right" id="testExpression" type="button" class="btn btn-primary" (click)="testExpression(i)"> Test Expression </button>
                </div>
              </div>

              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
        <br />
        <br />
        <div class="row">
          <div class="ml-3 pr-2">
            <button id="Cancel" type="button" class="btn btn-secondary" (click)="cancel()"> Cancel </button>
          </div>
          <div>
            <button type="submit" class="btn btn-primary" (click)="setExpression()" [disabled]=disableIfRdcAdmin> Set Expression</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
