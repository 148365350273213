/*
 * Copyright 2018 National Association of Insurance Commissioners
 */

import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FilingBlankLayoutComponent} from './filing-blank-layout.component';


const filingBlankLayoutRoutes: Routes = [
  { path: 'filingBlankLayout', component: FilingBlankLayoutComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(filingBlankLayoutRoutes)
  ],
  exports: [RouterModule]
})
export class FilingBlankLayoutRoutingModule { }
