import { Component } from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-cross-field-exceptions-renderer',
  templateUrl: './cross-field-exceptions-renderer.component.html',
  styleUrls: ['./cross-field-exceptions-renderer.component.css']
})
export class CrossFieldExceptionsRendererComponent implements  ICellRendererAngularComp {
  params: any;



  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  goToErrorType() {
    this.params.context.componentParent.goToErrorType(this.params.data, 'CROSSFIELD');
  }

}
