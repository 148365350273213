<ngx-loading [show]="loading"></ngx-loading>
<div class="wrapper">
  <div id="error-type">
    <div class="row page-title">
      <div class="col-12">
        <h1>{{originalFileName}}</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <app-messages></app-messages>
      </div>
    </div>

    <!-- TODO: Switch to breadcrumb service -->
    <div *ngIf="backToErrors" class="row mb-3">
      <div class="col-12">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="['/fileDashboard']">File Dashboard</a>
            </li>
            <li class="breadcrumb-item">
              <a [routerLink]="['/fileSubmissionErrors', backToErrors]">{{originalFileName}}</a>
            </li>
            <li class="breadcrumb-item active">
              {{errorType}} Exceptions
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div>

      <div *ngIf="documentErrorList" class="row mb-3">
        <div class="col-12">
          <div *ngFor="let errorList of documentErrorList.documentsWithErrors; let i=index" class="card mb-2">
            <div class="card-body">
              <div class="table-responsive">
                <table id="table_{{i + 1}}" class="table">
                  <tr id="table_{{i + 1}}_keys">
                    <th *ngFor="let key of getDocumentKeys(errorList.document)" id="table_{{i + 1}}_documentKey_{{key}}">{{key}}</th>
                  </tr>
                  <tr id="table_{{i + 1}}_value">
                    <td *ngFor="let value of getDocumentValues(errorList.document)" id="table_{{i + 1}}_documentValue_{{value}}">{{value}}</td>
                  </tr>
                </table>
              </div>
              <ul class="list-group">
                  <label id="exceptionMessageLabel_{{i + 1}}">Exception Message: </label>
                <li *ngFor="let error of errorList.errors; let j=index" id="exceptionMessage_table_{{i + 1}}_message_{{j + 1}}" class="list-group-item">
                  {{error.errorMessage}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="documentErrorList" class="row mb-3">
        <div class="col-12">
          <pagination
            [boundaryLinks]="documentErrorList.totalErrors > limit"
            [directionLinks]="documentErrorList.totalErrors > limit"
            [totalItems]="documentErrorList.totalErrors"
            [itemsPerPage]="limit"
            [(ngModel)]="currentPage"
            [maxSize]="maxSizeOfPageLinksInPager"
            (pageChanged)="page($event)">
          </pagination>
        </div>
      </div>
    </div>
  </div>
</div>
