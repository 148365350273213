import { Component } from '@angular/core';
import { ICellRenderer } from 'ag-grid-community';

@Component({
  selector: 'app-secondary-user-assignment',
  templateUrl: './secondary-user-assignment.component.html'
})

export class SecondaryUserAssignmentComponent implements ICellRenderer {
  params: any;
  deleteKey: string;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return true;
  }

  changeToSecondaryUser(secondary) {
    this.params.context.componentParent.changeToSecondaryUser(secondary);
  }
}
