<ngx-loading [show]="loading"></ngx-loading>
<div id="errors" class="wrapper">

  <div class="row page-title">
    <div class="col-12">
      <h1>{{title}}</h1>
    </div>
  </div>

  <!-- TODO:: use Breadcrumb Service -->
  <div class="row mb-3">
    <div class="col-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/fileAdmin']">File Administration</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            {{originalFileName}}
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <app-messages></app-messages>
    </div>
  </div>

  <div>

    <div *ngIf="rowData" class="row">
      <div class="col-12 mb-3">
        <ag-grid-angular
          style="width: 100%; height: 400px;"
          #agGrid
          id="errorsGrid"
          class="ag-theme-balham"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="rowData"
          [context]="context"
          [frameworkComponents]="frameworkComponents"
          pagination="true"
          (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
  </div>

</div>
