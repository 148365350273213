import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AssignedToUser} from '../../file-validation-maintenance/model/property-name.enum';
import {environment} from '../../../environments/environment';
import {HandleError, HttpErrorHandler} from '../../shared/http-error-handler.service';
import {AssignmentValueResources, UserAssignments} from '../model/user-assignment';

@Injectable({
  providedIn: 'root'
})
export class UserAssignmentService {
  readonly handleError: HandleError;
  assignmentKey: any = '';
  showuserAssignmentsPrimaryLink: boolean;
  userAssignmentsPrimaryLink: boolean;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('UserAssignmentService');
  }

  setShowuserAssignmentsPrimaryLink(showuserAssignmentsPrimaryLink: boolean) {
    this.userAssignmentsPrimaryLink = showuserAssignmentsPrimaryLink;
  }

  getShowuserAssignmentsPrimaryLink(): boolean {
    return this.userAssignmentsPrimaryLink;
  }

  getAssignmentKeys(schemaType: string): Observable<AssignedToUser> {
    const url = `${environment.apiUrl}${schemaType}/userAssignments/info`;
    const headers = new HttpHeaders({'Accept': 'application/json'});
    const options = {headers: headers};
    return this.httpClient.get(url, options)
      .pipe(catchError(this.handleError('getAssignmentKeys', null)));
  }

  getAssignmentValuesForUser(userId: any, schemaType: string): Observable<AssignmentValueResources> {
    const url = `${environment.apiUrl}${schemaType}/userAssignments/assignmentValues/${userId} `;
    const headers = new HttpHeaders({'Accept': 'application/json'});
    const options = {headers: headers};
    return this.httpClient.get(url, options)
      .pipe(catchError(this.handleError('getAssignmentValuesForUser', null)));
  }


  getUserAssignments(schemaType: string, assignmentValue: string, assignmentField: string): Observable<UserAssignments> {
    const url = `${environment.apiUrl}${schemaType}/userAssignments/assignmentValues`;
    const headers = new HttpHeaders({'Accept': 'application/json'});
    const httpParams = {assignmentField: `${assignmentField}`, assignmentValue: `${assignmentValue}`};
    const options = {headers: headers, params: httpParams};
    return this.httpClient.get(url, options)
      .pipe(catchError(this.handleError('getUserAssignments', null)));
  }

  getAssigmentsForDatacall(schemaType: string): Observable<UserAssignments[]> {
    const url = `${environment.apiUrl}${schemaType}/userAssignments`;
    const headers = new HttpHeaders({'Accept': 'application/json'});
    const options = {headers: headers};
    return this.httpClient.get(url, options)
      .pipe(catchError(this.handleError('getAssigmentsForDatacall', null)));
  }

  getUserAssignmentsPrimaryUser(schemaType: string, userId: any): Observable<any> {
    const url = `${environment.apiUrl}${schemaType}/userAssignments/primary/${userId} `;
    const headers = new HttpHeaders({'Accept': 'application/json'});
    const options = {headers: headers};
    return this.httpClient.get(url, options)
      .pipe(catchError(this.handleError('getUserAssignmentsPrimaryUser', null)));
  }

  putUpdatePrimaryUser(schema: string, assignmentValue: any, assignmentField: string, userId: any): Observable<any> {

    const url = `${environment.apiUrl}${schema}/userAssignments/primary/${userId}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const requestBody: any = {assignmentField: `${assignmentField}`, assignmentValue: `${assignmentValue}`};
    const options = {headers: headers};
    return this.httpClient.put(url, requestBody, options)
      .pipe(catchError(this.handleError('putAddPrimaryUser', null)));
  }

  putUpdateSecondaryUser(schema: string, assignmentValue: any, assignmentField: string, userId: any): Observable<any> {
    const url = `${environment.apiUrl}${schema}/userAssignments/secondary/${userId}`;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const requestBody: any = {assignmentField: `${assignmentField}`, assignmentValue: `${assignmentValue}`};
    const options = {headers: headers};
    return this.httpClient.put(url, requestBody, options)
      .pipe(catchError(this.handleError('putAddSecondaryUser', null)));
  }

  deletePrimaryUser(schema: string, assignmentValue: any, assignmentField: string, userId: any): Observable<any> {
    let url = `${environment.apiUrl}${schema}/userAssignments/primary/${userId}`;
    const httpParams = {assignmentField: `${assignmentField}`, assignmentValue: `${assignmentValue}`};
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    let options = {headers: headers, params: httpParams};

    return this.httpClient.delete(url, options)
      .pipe(catchError(this.handleError('deletePrimaryUser', null)));
  }

  deleteSecondaryUser(schema: string, assignmentValue: any, assignmentField: string, userId: any): Observable<any> {
    // Custom type for casting
    type bodyType = 'body';
    // upload file to the pre-signed url
    const httpParams = {assignmentField: `${assignmentField}`, assignmentValue: `${assignmentValue}`};
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      params: httpParams,
      observe: <bodyType>'response'
    };
    let url = `${environment.apiUrl}${schema}/userAssignments/secondary/${userId}`;

    return this.httpClient.delete(url, httpOptions)
      .pipe(catchError(this.handleError('deleteSecondaryUser', null)));
  }

  postAddPrimaryUser(schema: string, assignmentValue: any, assignmentField: string, userId: any): Observable<any> {
    // Custom type for casting
    type bodyType = 'body';

    // upload file to the pre-signed url
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      observe: <bodyType>'response'
    };
    const requestBody: any = {assignmentField: `${assignmentField}`, assignmentValue: `${assignmentValue}`};
    const url = `${environment.apiUrl}${schema}/userAssignments/primary/${userId} `;
    return this.httpClient.post<any>(url, requestBody, httpOptions)
      .pipe(catchError(this.handleError('postUpdatePrimaryUser', null)));
  }

  postAddSecondaryUser(schema: string, assignmentValue: any, assignmentField: string, userId: any): Observable<any> {
    const url = `${environment.apiUrl}${schema}/userAssignments/secondary/${userId} `;
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const options = {headers: headers};
    const params = new HttpParams()
      .set('assignmentField', assignmentField.toString())
      .set('assignmentValue', assignmentValue.toString());
    const requestBody: any = {assignmentField: `${assignmentField}`, assignmentValue: `${assignmentValue}`};
    return this.httpClient.post<any>(url, requestBody, options)
      .pipe(catchError(this.handleError('postUpdateSecondaryUser', null)));
  }

  setTableData(filtereduserAssignments: any, userRole): Array<any> {
    let rowData = [];
    const userAssignments: Array<any> = [];
    // Setting Company Admin
    if (filtereduserAssignments.primaryUser !== '') {
      let data = {};
      data['assignmentValue'] = filtereduserAssignments.assignmentValue;
      data['userId'] = filtereduserAssignments.primaryUser;
      data['companyAdmin'] = true;
      data['secondary'] = false;
      data['userRole'] = userRole;
      userAssignments.push(data);
    }
    // Setting Secondary Admin
    if (filtereduserAssignments.secondaryUsers !== []) {
      filtereduserAssignments.secondaryUsers.forEach(x => {
        let data = {};
        data['assignmentValue'] = filtereduserAssignments.assignmentValue;
        data['userId'] = x;
        data['companyAdmin'] = false;
        data['secondary'] = true;
        data['userRole'] = userRole;
        userAssignments.push(data);
      });
    }
    rowData = this.getTableData(userAssignments);
    return rowData;
  }

  getTableData(userAssignments: Array<any>): Array<any> {
    const tableData: Array<any> = [];
    userAssignments.forEach((x => {
      const data = {};
      data['assignmentValue'] = x.assignmentValue;
      data['userId'] = x.userId;
      data['companyAdmin'] = x;
      data['secondary'] = x;
      data['delete'] = x;
      tableData.push(data);
    }));
    return tableData;
  }

  getColumnDef(assignmentKeyFieldName): any[] {
    let columnsDef: any[];
    columnsDef = [
      {headerName: `${assignmentKeyFieldName}`, field: 'assignmentValue'},
      {headerName: 'User ID', field: 'userId'},
      {headerName: 'Primary', field: 'companyAdmin', cellRenderer: 'primaryuserAssignment'},
      {headerName: 'Secondary', field: 'secondary', cellRenderer: 'secondaryuserAssignment'},
      {headerName: 'Delete', field: 'delete', cellRenderer: 'removeuserAssignment', autoHeight: true},
    ];
    return columnsDef;
  }
}
