import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { LoadingModule } from "ngx-loading";
import { MessagesModule } from "../messages/messages.module";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MessagesModule,
    LoadingModule,
    RouterModule,
  ],
  declarations: [],
  providers: [ ],
  exports: []
})
export class CommonConfirmationModalModule {
}
