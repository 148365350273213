<ngx-loading [show]="loading"></ngx-loading>
<div id="form-builder:build-edit-form" class="wrapper scrollForm">

    <div *ngIf="isTitleVisible" class="row" id="form-builder:title:{{title}}">
        <h4 class="w-100"><span>{{title}}</span></h4>
        <p class="text-center">Enter the questions and select responses.</p>
    </div>

    <div class="row">
        <div class="col-12" id="form-builder:sub-title:{{formBuilderService.getClickedName()}}">
            <h4 class="w-100"><span>{{formBuilderService.getClickedName()}}</span></h4>
        </div>
    </div>

    <form id="buildEditForm" *ngIf="formSection" [formGroup]="addForm">
        <div formArrayName="rows">
            <table id=buildEditTable>
                <thead id="buildEditHeader">
                    <tr>
                        <th *ngFor="let column of columnArray; let i = index">
                            <p id="{{column.label}}" *ngIf="(i === 1 || i === 2)"> {{column.label}}</p>
                            <div class="row" style="padding-left: 20px; padding-right: 20px;">
                                <div class="col-8">
                                    <mat-form-field *ngIf="!(i === 0 || i === 1 || i === 2 || column.value === 'order')" appearance="fill" [class.mat-form-field-invalid]="isLabelDuplicate(column.label, i)">
                                        <mat-label>Column Name</mat-label>
                                        <input id="columnInput-{{i-1}}" class="fontCustomSize" matInput value={{column.label}}
                                            (blur)="changeColumnName(i, $event)" placeholder="Enter Column Name"
                                            required [readonly]="disableIfRdcAdmin"/>
                                    </mat-form-field>
                                </div>
                                <div class="col" *ngIf="!disableIfRdcAdmin">
                                    <button id="deleteColumn{{i+1}}" mat-icon-button [attr.aria-label]="Delete" (click)="removeColumn(i)"
                                        #tooltip="matTooltip" matTooltip="Delete Column"
                                        [matTooltipPosition]="tooltipPosition" *ngIf="!(i === 0 || i === 1 || i === 2 || i === 3 || i === 4 || column.value === 'order')" [disabled]="columnArray.length === 5">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </th>
                        <th *ngIf="!disableIfRdcAdmin"><button id="addColumn{{i+1}}" mat-icon-button [attr.aria-label]="Add" (click)="addColumnsForTable()"
                                #tooltip="matTooltip" matTooltip="Add Column" [matTooltipPosition]="tooltipPosition"
                                [disabled]="disable()">
                                <mat-icon>add</mat-icon> Add Column
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody id="buildEditBody">                      
                    <tr *ngFor="let row of addForm.controls.rows.controls; let i = index" [formGroupName]="i">
                        <!-- Questions and Field Names -->
                        <td *ngFor="let column of columnArray; let j = index;">
                           
                            <div *ngIf = "column.value !== 'order'" [ngClass]="getClassNames(j)">
                                <!-- Heading -->
                                <div  *ngIf="(j === 0); else number">
                                <div *ngIf="hasHeading(row);" class="col-sm-12"
                                    style="padding-left: 20px; padding-right: 20px;">
                                    <mat-form-field appearance="fill" style="width: 58rem;">
                                        <!-- <mat-label>Heading</mat-label> -->
                                        <textarea id="questionNumber-{{i+1}}" class="fontCustomSize" matInput [placeholder]="placeHolder(j)"
                                            [formControlName]="column.value" [readonly]="disableIfRdcAdmin"></textarea>
                                    </mat-form-field>
                                </div>
                                </div>
                                <!-- Question Number -->
                                <ng-template #number>
                                <div  *ngIf="(j === 1) ; else question">
                                <div *ngIf="!hasHeading(row)" class="col-sm-12"
                                    style="padding-left: 20px; padding-right: 20px;">
                                    <mat-form-field appearance="fill" style="width: 3rem;">
                                        <!-- <mat-label>No.</mat-label> -->
                                        <input id="questionNumber-{{i+1}}" class="fontCustomSize" matInput [placeholder]="placeHolder(j)"
                                            [formControlName]="column.value" [attr.disabled]="true" readonly />
                                    </mat-form-field>
                                </div>
                                </div>
                                </ng-template>
                                <!-- Question -->
                                <ng-template #question>
                                    <div *ngIf="(j === 2); else dropdownButton">
                                    <div *ngIf="!hasHeading(row)"  class="col-sm-12"
                                        style="padding-left: 20px; padding-right: 20px;">
                                        <mat-form-field appearance="fill" style="width: 28rem">
                                            <!-- <mat-label>Question</mat-label> -->
                                            <textarea id="questionText-{{i+1}}" class="fontCustomSize" matInput [placeholder]="placeHolder(j)"
                                                [formControlName]="column.value" [readonly]="disableIfRdcAdmin"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                </ng-template>
                                <!-- Field Selection -->
                                <ng-template #dropdownButton>
                                    <div *ngIf="!hasHeading(row)" class="dropdown ml-2 pb-1" style="padding-left: 20px; padding-right: 20px;">
                                        <div class="row" style="padding-left: 20px; padding-right: 20px;">
                                            <div>
                                                <button id="responseType-row:{{i+1}}-column{{j-2}}" class="btn customButton btn-primary dropdown-toggle"
                                                    type="button" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false" [disabled]="disableIfRdcAdmin" title="{{rows.at(i).get(column.value).value}}">
                                                    <span
                                                        *ngIf="rows.at(i).get(column.value).value; else defaultSelect ">{{rows.at(i).get(column.value).value}}</span>
                                                    <ng-template #defaultSelect>
                                                        <span>Select Response</span>
                                                    </ng-template>
                                                </button>

                                                <ul class="dropdown-menu multi-level" role="menu"
                                                    aria-labelledby="dropdownMenu" style="width: 17rem;">
                                                    <li><a class="dropdown-item" id="fieldSelection"
                                                            (click)="addFieldSelection(i, column.value)">Select Datacall
                                                            Field</a></li>
                                                    <li class="dropdown-divider"></li>
                                                    <li id="constantValue={{j+1}}"><a id="disabledField" class="dropdown-item"
                                                            (click)="addDisabledField(i, column.value, 'Disabled')">Disabled
                                                            Field</a></li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        
                        </td>
                        <td *ngIf="!disableIfRdcAdmin">
                            <button mat-icon-button id="addQuestion-{{i+1}}-column{{j-2}}" mat-icon-button [attr.aria-label]="Add" (click)="toggleMenu(i)" #tooltip="matTooltip"
                              matTooltip="Add Question/Header" [matTooltipPosition]="tooltipPosition" [disabled]="disableMenuAndSave()" style="vertical-align: middle;">
                              <mat-icon>{{ menuStates[i] ? 'close' : 'add' }}</mat-icon>
                            </button>
                            <div class="menu-items" [ngClass]="{ 'open': menuStates[i] }">
                              <ul>
                                <li><button mat-button (click)="initHeader(i, row)">Add Header</button></li>
                                <li><button mat-button (click)="initGroup(i, row)">Add Question</button></li>
                              </ul>
                            </div>
                            </td>
                            <td *ngIf="isDeleteHidden(row)">
                            <button id="deleteQuestion-{{i+1}}" mat-icon-button [attr.aria-label]="Delete" (click)="removeRow(i)"
                                #tooltip="matTooltip" matTooltip="Delete Question"
                                [matTooltipPosition]="tooltipPosition">
                                <mat-icon>delete</mat-icon>
                            </button>
                          </td>

                    </tr>
                </tbody>
            </table>
        </div>
        <!-- <pre>{{addForm.value | json}}</pre> -->
    </form>

    <div class="row" *ngIf="!disableIfRdcAdmin">
        <div *ngIf="this.formSection !== undefined" class="col-12">
            <button id="btn:cancel" type="button" class="btn btn-link btn-sm" (click)="cancel()"> Cancel</button> &nbsp;
            <button id="btn:save" type="button" class="btn btn-primary" (click)="saveFilingBlank()" [disabled]="disableMenuAndSave()"> Save </button> &nbsp;
            <button *ngIf="isCopySectionEnabled" id="btn:copy" type="button" class="btn btn-primary" (click)="copySection()" [disabled]="formBuilderService.isFormUpdated"> Copy Section </button> &nbsp;
            <button *ngIf="isPasteSectionEnabled" id="btn:paste" type="button" class="btn btn-primary" (click)="pasteSectionModal(overrideCurrentSectionWarning)" [disabled]="isPasteDisabled()"> Paste Section </button>
        </div>
    </div>

<ng-template #overrideCurrentSectionWarning>
   <div class="modal-header">
        <h3 class="modal-title">Form Builder</h3>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Any changes made to this section will be lost. Do you wish to proceed?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link btn-sm" (click)="pasteSection(); bsModalRef.hide()">Yes</button>
        <button type="button" class="btn btn-primary" (click)="bsModalRef.hide()">No</button>
    </div>
  </ng-template>