export enum RdcNavigationPermissions {
  /*
   read in order - standard user, overlap, standard admin
   */
  /*
   standard user
   */
  viewOwnFilesubmissions = 'read_own_filesubmissions',
  readErrorsOnOwnFileSubmissions = 'read_filesubmission_errors',

  /*
  overlap
   */
  viewTheDatacallDisclaimer = 'read_disclaimer_notice',
  read_adminfilesubmission_errors = 'read_adminfilesubmission_errors', // what's this?
  viewPropertiesForDatacall = 'read_validationschema_properties',

  /*
   standard admin
   */
  viewAllAdminSchema = 'read_alladmin_validationschema',
  listAllAggregates = 'read_all_aggregates',
  viewFilesubmissionAuditTrail = 'read_filesubmission_audittrail',
  viewAllCrossfieldValidations = 'read_all_crossfield_validations',
  viewFilesubmissionNote = 'read_filesubmission_note',
  viewAllTolerances = 'read_all_datacalltolerances',
  viewAllFilesubmissions = 'read_all_filesubmissions',
  viewAllStatusChanges = 'read_all_statuschanges',
  viewAllSchema = 'read_all_validationschema',
  viewCurrentSchema = 'read_current_validationschema',
  viewCurrentTolerance = 'read_filesubmission_tolerance_current',
  viewFilingblankLayout = 'read_filingblank_layout',
  viewDatacallDates = 'read_datacall_dates',

  viewCredibleCocodes = 'read_filesubmission_crediblecocodes',

  /*
   mutate in order - standard user, overlap, standard admin
   */
  /*
   standard user
   */
  createNewFilesubmission = 'create_own_filesubmission',

  /*
   overlap
   */
  acceptDisclaimer = 'update_disclaimer_acceptance',
  updateFilesubmissionStatus = 'update_filesubmission_status',

  /*
   standard admin
   */
  runAggregates = 'perform_aggregate_run',
  createOrUpdateFilesubmissionNotes = 'update_filesubmission_notes',
  createCrossfieldValidation = 'create_crossfield_validation',
  updateCrossfieldValidation = 'update_crossfield_validation',
  searchFilesubmissions = 'perform_filesubmission_search',
  createFilingblankLayout = 'create_filingblank_layout',
  rerunValidationsForFilesubmission = 'perform_validation_rerun',
  createValidationSchema = 'create_validation_schema',
  createFilesubmissionTolerance = 'create_filesubmission_tolerances',
  createDatacallDates = 'create_datacall_dates',
  runFilesubmissionReload = 'perform_filesubmission_reload',
  /*   'READ_DATACALL_CONFIGURATIONS'
UPDATE_DATACALL_CONFIGURATIONS */

  // leftovers (probably pbr specific actuary)
  updateFilesubmissionCredibility = 'update_filesubmission_crediblestatus',

  /* Datacall Admin */
  readUserAssignments = 'read_datacall_configurations',
  updateUserAssignments = 'update_datacall_configurations'

}
