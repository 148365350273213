import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from '../model/user';
import { UnCamelCasePipe } from '../pipes/un-camel-case.pipe';
import { UserService } from '../service/user.service';
import { FileSubmissionTolerance } from './model/file-submission-tolerance';
import { SubmissionLevelTolerancesService } from './services/submission-level-tolerances.service';
import {MessageService} from '../messages/service/message.service';
import {AlertMessage} from '../messages/model/alert-message';
import { takeUntil } from 'rxjs/operators';
import { DateRendererComponent } from '../cell-renderer/date-renderer/date-renderer.component';
import { NumberRendererComponent } from '../cell-renderer/number-renderer/number-renderer.component';

@Component({
  selector: 'app-submission-level-tolerances',
  templateUrl: './submission-level-tolerances.component.html'
})
export class SubmissionLevelTolerancesComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  title: string;
  infoMessage = 'New tolerances will be applied to all incoming file submissions. The new tolerance will not be applied to previous file submissions.';
  user: User;
  activeTolerance: FileSubmissionTolerance;
  toleranceHistory: FileSubmissionTolerance[];
  percentPattern = '^[0-9]{1,3}$';
  countPattern = '^[0-9]{1,9}$';
  loadingIndicator = true;
  loading = false;
  scrollBarHorizontal = (window.innerWidth < 1200);

  columnDefs = [];
  rowData: FileSubmissionTolerance[] = [];
  defaultColDef: any;
  context: any;
  frameworkComponents: any;

  constructor(
    private userService: UserService,
    private submissionLevelTolerancesService: SubmissionLevelTolerancesService,
    private unCamelCase: UnCamelCasePipe,
    private messageService: MessageService,
  ) {
    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 12000);
    };
  }

  ngOnInit() {
    this.context = { componentParent: this };
    this.frameworkComponents = {
      dateRenderer: DateRendererComponent,
      numberRenderer: NumberRendererComponent,
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this.user = this.userService.getStoredUser();
    this.title = `${this.user.currentSchema.toUpperCase()} Submission Level Tolerances`;
    this.getAllFileSubmissionTolerancesBySchemaType();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.messageService.clear();
  }

  getAllFileSubmissionTolerancesBySchemaType(): void {
    this.loading = true;
    this.submissionLevelTolerancesService.getAllFileSubmissionTolerancesBySchemaType(this.user.currentSchema)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        tolerances => {
          if (tolerances && tolerances !== null) {
            this.loading = false;
            this.rowData = tolerances;
            this.activeTolerance = (tolerances.length === 0 ? new FileSubmissionTolerance() : tolerances[0]);
            this.toleranceHistory = tolerances.slice(1);
            this.columnDefs = this.getColumnDef(this.rowData[0]);
          }
        }
      );
  }

  getColumnDef(row: any): any[] {
    const columnsDef: any[] = [];
    if (row) {
      const keys = Object.keys(row);
      keys.forEach(key => {
        const headerName = this.unCamelCase.transform(key);
        if (headerName === 'Count') {
          columnsDef.push({headerName: headerName, field: key, cellRenderer: 'numberRenderer'});
        } else if (headerName === 'Date Created') {
          columnsDef.push({headerName: headerName, field: key, cellRenderer: 'dateRenderer'});
        } else if (headerName === 'Schema Type') {
          columnsDef.push({headerName: headerName, field: key, hide: true, suppressToolPanel: true});
        } else if (headerName !== '_id') {
          columnsDef.push({headerName: headerName, field: key});
        }
      });
    }
    return columnsDef;
  }

  onGridReady(params: any): void {
    const gridApi = params.api;

    gridApi.sizeColumnsToFit();
  }

  updateTolerance(): void {
    this.loading = true;
    const newTolerance = new FileSubmissionTolerance();
    newTolerance.percentage = this.activeTolerance.percentage;
    newTolerance.count = this.activeTolerance.count;
    newTolerance.schemaType = (this.activeTolerance.schemaType ? this.activeTolerance.schemaType : this.user.currentSchema);
    this.submissionLevelTolerancesService.updateFileSubmissionTolerance(newTolerance)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        next => {
            this.loading = false;
            this.messageService.add(new AlertMessage('File submission tolerance has been updated', 'success'));
            this.getAllFileSubmissionTolerancesBySchemaType();
        }
      );
  }

}
