export enum PropertyNameEnum {
  requiredField = 'required',
  description = 'description',
  uniqueRecordIdentifier = 'isDocumentKey',
  submissionKey = 'isSubmissionKey',
  assignmentKey = 'isAssignmentsKey',
  minValue = 'min',
  minLength = 'minLength',
  maxValue = 'max',
  maxLength = 'maxLength',
  pattern = 'pattern',
  anyOf = 'anyOf',
  listOfValidValues = 'enum',
  worksheetLocation = 'xlsLocation',
  rowLocation = 'xlsLocation',
  columnExactLocation = 'xlsLocation',
  pullFromWorksheet = 'xlsLocation',
  worksheetIterationLocation = 'xlsLocation',
  worksheetExactLocation = 'xlsLocation',
  rowIterationLocation = 'xlsLocation',
  rowExactLocation = 'xlsLocation',
  propertyType = 'type'

}

export interface AssignedToUser {
  assignedToUser: boolean;
}
