import { Component, TemplateRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-file-level-validations-renderer',
  templateUrl: './file-level-validations-renderer.component.html',
  styleUrls: ['./file-level-validations-renderer.component.css']
})
export class FileLevelValidationsRendererComponent implements ICellRendererAngularComp {
  params: any;
  modalRef: BsModalRef

  constructor(
    private modalService: BsModalService,
  ) { }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  goToFileAdminFileLevelExceptions(exceptions, template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {initialState: exceptions[0], class: 'modal-lg'});
  }

}
