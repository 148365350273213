export class FileLevelValidation {
    id: string;
    name: string;
    value: string;
    active: boolean;
    dateCreated: string;
    userCreated: string;

    constructor(name?: string, value?: string, active?: boolean) {
        this.active = active || false;
        this.value = value || '';
        this.name = name || '';
    }
  }