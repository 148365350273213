import { SubmissionKey } from './submission-key';
import { FileSubmissionNote } from '../file-dashboard/model/file-submission-note';
import { FileSubmissionStateHistory } from './file-submission-state-history';
import { ErrorTypeCounts } from './error-type-counts';
import { FileLevelExceptionTypes } from './file-level-exception-types';

export class FileSubmission {
  id: string;
  schemaType: string;
  fileName: string;
  originalFileName: string;
  totalErrorCount: number;
  totalPublicErrorCount: number;
  formFormatErrorCount: number;
  formFormatPublicErrorCount: number;
  crossFieldErrorCount: number;
  crossFieldPublicErrorCount: number;
  fileSubmissionState: string;
  submittedBy: string;
  created: Date;
  modified: Date;
  submissionKeys: SubmissionKey[];
  notes: FileSubmissionNote[];
  totalDocuments: number;
  validateAndSaveTimeInSeconds: number;
  uploadTimeInSeconds: number;
  fileSizeInBytes: number;
  insertionComplete: any;
  rejectionReasons: any[];

  fileSubmissionStateHistory: FileSubmissionStateHistory[];
  formFormatTimeInSeconds: number;
  crossFieldTimeInSeconds: number;
  insertTimeInSeconds: number;
  comments: string;
  crossFieldErrorTypeCounts: ErrorTypeCounts[];
  crossFieldNonPublicErrorTypeCounts: ErrorTypeCounts[];
  formFormatErrorTypeCounts: ErrorTypeCounts[];
  fileLevelExceptionCount: number;
  fileLevelExceptionTypes: FileLevelExceptionTypes[];
  assignmentFieldValues: any[];
}
