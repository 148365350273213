import { UserAssignments } from "../../user-assignment/model/user-assignment";

export const MOCK_USER_ASSIGNMENT: UserAssignments[] = [
    {
        assignmentField: null,
        assignmentValue: "12345",
        primaryUser: "mock_1",
        secondaryUsers: ["user_1", "user_2"]
    },
    {
        assignmentField: null,
        assignmentValue: "15897",
        primaryUser: "mock_1",
        secondaryUsers: ["user_1", "user_2"]
    },
    {
        assignmentField: null,
        assignmentValue: "69855",
        primaryUser: "mock_1",
        secondaryUsers: ["user_1", "user_2"]
    },
    {
        assignmentField: null,
        assignmentValue: "78452",
        primaryUser: "mock_1",
        secondaryUsers: ["user_1", "user_2"]
    }
]

export const MOCK_USER_ASSIGNMENT_FOR_TEST: UserAssignments = {
    assignmentField: 'assignmentField',
    assignmentValue: "12345",
    primaryUser: "mock_1",
    secondaryUsers: ["user_1", "user_2"]
}
