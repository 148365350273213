import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from '../../model/user';
import { UnCamelCasePipe } from '../../pipes/un-camel-case.pipe';
import { UserService } from '../../service/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorsService } from './service/errors.service';
import { MessageService } from '../../messages/service/message.service';
import { FormFormatExceptionsRendererComponent } from './cell-renderer/form-format-exceptions-renderer/form-format-exceptions-renderer.component';
import { CrossFieldExceptionsRendererComponent } from './cell-renderer/cross-field-exceptions-renderer/cross-field-exceptions-renderer.component';
import { FileAdminService } from '../service/file-admin.service';
import { ErrorTypeCounts } from '../../model/error-type-counts';

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html'
})
export class ErrorsComponent implements OnInit, OnDestroy {
  title: string;
  user: User;
  documentId: string;
  originalFileName: string;
  validationCategory: string;
  errorList: Error[];
  loading = false;
  scrollBarHorizontal = (window.innerWidth < 1200);
  subscriptions: Array<Subscription> = [];

  columnDefs = [];
  rowData = [];
  defaultColDef: any;
  context: any;
  frameworkComponents: any;
  gridApi: any;
  formFormatErrorTypeCounts: ErrorTypeCounts[];
  crossFieldNonPublicErrorTypeCounts: ErrorTypeCounts[];
  crossFieldErrorTypeCounts: ErrorTypeCounts[];
  crossFieldErrorCounts: ErrorTypeCounts[];

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private errorsService: ErrorsService,
    private unCamelCase: UnCamelCasePipe,
    private messageService: MessageService,
    private router: Router,
    private fileAdminService: FileAdminService
  ) {
    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 12000);
    };
  }

  ngOnInit() {
    this.context = { componentParent: this };
    this.frameworkComponents = {
      crossFieldExceptionsRenderer: CrossFieldExceptionsRendererComponent,
      formFormatExceptionsRenderer: FormFormatExceptionsRendererComponent,
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this.user = this.userService.getStoredUser();
    this.documentId = this.route.snapshot.paramMap.get('documentId');
    this.originalFileName = this.route.snapshot.paramMap.get('originalFileName');
    this.validationCategory = this.route.snapshot.paramMap.get('validationCategory');
    this.title = `${this.user.currentSchema.toUpperCase()} File Exceptions`;
    this.formFormatErrorTypeCounts = this.fileAdminService.getFormFormatErrorTypeCounts();
    this.crossFieldNonPublicErrorTypeCounts = this.fileAdminService.getCrossFieldNonPublicErrorTypeCounts();
    this.crossFieldErrorTypeCounts = this.fileAdminService.getCrossFieldErrorTypeCounts();

    if (this.validationCategory === 'FORMFORMAT') {
      if (!this.formFormatErrorTypeCounts) {
        this.getErrorList();
      } else {
        this.getErrorListFromFileSubmission();
      }
    } else {
      if (!this.crossFieldErrorTypeCounts || !this.crossFieldNonPublicErrorTypeCounts) {
        this.getErrorList();
      } else {
        this.getErrorListFromFileSubmission();
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.messageService.clear();
  }

  getErrorList() {
    this.loading = true;
    const sub = this.errorsService.getErrorList(this.user.currentSchema, this.documentId, this.validationCategory)
      .subscribe(
        errorList => {
          this.loading = false;
          this.errorList = errorList;
          this.rowData = this.errorList;
          this.columnDefs = this.getColumnDefFromErrorList(this.rowData[0]);
        }
      );
    this.subscriptions.push(sub);
  }

  getErrorListFromFileSubmission() {
    if (this.validationCategory === 'FORMFORMAT') {
      this.rowData = this.formFormatErrorTypeCounts;
    } else {
      this.rowData = this.crossFieldErrorTypeCounts.concat(this.crossFieldNonPublicErrorTypeCounts);
    }
    if (this.rowData.length !== 0) {
      this.columnDefs = this.getColumnDefFromFileSubmission(this.rowData[0]);
    }
  }

  getColumnDefFromFileSubmission(row: any): any[] {
    const columnsDef: any[] = [];
    const keys = Object.keys(row);
    keys.forEach(key => {
      const headerName = this.unCamelCase.transform(key);
      if (headerName === 'Count') {
        if (this.validationCategory === 'FORMFORMAT') {
          columnsDef.push({ headerName: ' Exception Count', field: key, cellRenderer: 'formFormatExceptionsRenderer' });
        } else if (this.validationCategory === 'CROSSFIELD') {
          columnsDef.push({ headerName: 'Exception Count', field: key, cellRenderer: 'crossFieldExceptionsRenderer' });
        }

      } else {
        columnsDef.push({ headerName: 'Exception Type', field: key });
      }
    });
    return columnsDef;
  }

  getColumnDefFromErrorList(row: any): any[] {
    const columnsDef: any[] = [];
    const keys = Object.keys(row);
    keys.forEach(key => {
      const headerName = this.unCamelCase.transform(key).replace('Error', 'Exception');
      if (headerName === 'Exception Count') {
        if (this.validationCategory === 'FORMFORMAT') {
          columnsDef.push({ headerName: headerName, field: key, cellRenderer: 'formFormatExceptionsRenderer' });
        } else if (this.validationCategory === 'CROSSFIELD') {
          columnsDef.push({ headerName: headerName, field: key, cellRenderer: 'crossFieldExceptionsRenderer' });
        }

      } else {
        columnsDef.push({ headerName: headerName, field: key });
      }
    });
    return columnsDef;
  }

  onGridReady(params) {
    this.gridApi = params.api;

    this.sizeColumnsToFit();
  }

  sizeColumnsToFit(): void {
    if (this.columnDefs.length <= 8) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  getErrorTypeData(row: any): any {
    if (!this.crossFieldErrorTypeCounts && !this.crossFieldNonPublicErrorTypeCounts) {
      this.crossFieldErrorCounts = null;
    } else {
      this.crossFieldErrorCounts = this.crossFieldErrorTypeCounts.concat(this.crossFieldNonPublicErrorTypeCounts);
    }
    if (!this.formFormatErrorTypeCounts && !this.crossFieldErrorCounts) {
      return {
        fileSubmissionId: this.documentId,
        errorType: row.errorType,
        originalFileName: this.originalFileName,
        validationCategory: this.validationCategory
      };
    } else {
      return {
        fileSubmissionId: this.documentId,
        errorType: row.type,
        originalFileName: this.originalFileName,
        validationCategory: this.validationCategory
      };
    }

  }

  goToErrorType(row: any, validationCategory: string): void {
    this.router.navigate(['/fileAdminErrorType', this.getErrorTypeData(row)]);
  }

}
