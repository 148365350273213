<ngx-loading [show]="loading"></ngx-loading>
<div class="wrapper">
    <div id="aggregate-maintenance" class="container-fluid">
        <div class="row page-title">
          <div class="col-12">
            <h1>{{title}}</h1>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <app-messages></app-messages>
          </div>
        </div>

        <div>

          <div *ngIf="aggregates" class="am-aggregates row">
            <div class="col-12">
              <div class="card-columns">
                <div *ngFor="let aggregate of aggregates" class="card bg-light mb-3">
                  <div class="card-body" (click)="selectAggregate(aggregate)" style="cursor: pointer">
                    <h5 class="card-title">{{aggregate.displayName}}</h5>
                    <p class="card-text">{{aggregate.description}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>
