export class Error {
  companyCode: string;
  dataYear: number;
  errorType: string;
  errorCount: number;

  constructor(companyCode: string, dataYear: number, errorType: string, errorCount: number) {
    this.companyCode = companyCode;
    this.dataYear = dataYear;
    this.errorType = errorType;
    this.errorCount = errorCount;
  }
}
