<ngx-loading [show]="loading"></ngx-loading>
<div class="wrapper">
  <div *ngIf="disclaimer" id="disclaimer">
    <div class="row">
      <div class="col-12 page-title">
        <h1>{{ title }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12"><app-messages></app-messages></div>
    </div>
    <div class="row">
      <div class="col-12">
        <div id="disclaimerDiv">
          <p [innerHtml] ="disclaimer.disclaimer" ></p>
        </div>
        <br />
        <div class="mb-3" style="text-align: center;">
          <button
            id="btn:disclaimer:Agree"
            class="btn btn-primary"
            type="button"
            (click)="agree()">
            Agree
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
