import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appOnlyDigits]'
})
export class OnlyDigitsDirective {
  allowedKeys = [8, 9, 13, 27, 35, 36, 37, 39, 45, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105];
  decimal = [110, 190];
  @Input() allowDecimal = false;

  constructor(el: ElementRef) {}

  @HostListener('keydown', ['$event']) onkeydown(e: KeyboardEvent) {
    let keys = [];
    if (this.allowDecimal) {
      keys = this.allowedKeys.concat(this.decimal);
    } else {
      keys = this.allowedKeys;
    }

    if (
      keys.indexOf(e.keyCode) !== -1 ||
      (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.keyCode === 65 && e.metaKey === true) || // Allow: Cmd+A (Mac)
      (e.keyCode === 67 && e.metaKey === true) || // Allow: Cmd+C (Mac)
      (e.keyCode === 86 && e.metaKey === true) || // Allow: Cmd+V (Mac)
      (e.keyCode === 88 && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      return;
    } else {
      e.preventDefault();
    }
  }

}
