import { Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlattener, MatTreeFlatDataSource, TooltipPosition} from '@angular/material';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {MessageService} from '../../messages/service/message.service';
import {User} from '../../model/user';
import {UserService} from '../../service/user.service';
import {FormSection} from '../model/form-section';
import {NavigationItems} from '../model/Navigation';
import {TodoItemFlatNode} from '../model/ItemFlatNode';
import {OnlineDataEntryService} from '../services/online-data-entry.service';
import {OnlineSubmissionsStatus} from '../model/online-submissions-status';
import { CommonConfirmationModalComponent } from '../../common-confirmation-modal/common-confirmation-modal.component';
import { Subscription } from 'rxjs';
import { UploadAttachmentsComponent } from '../upload-attachments/upload-attachments.component';
import { AlertMessage } from '../../messages/model/alert-message';

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.css']
})

export class SideNavigationComponent implements OnChanges {
  @Input() isSummary: boolean;
  @Input() status: string;
  @Output() selectedFormSection = new EventEmitter<FormSection>();
  @Output() saveAndValidate = new EventEmitter<FormSection>();
  @Output() save = new EventEmitter<FormSection>();
  @Output() openSummary = new EventEmitter<void>();
  @Output() submit = new EventEmitter<void>();
  user: User;
  schema: string;
  title: string;
  loading = false;
  navigation: NavigationItems;
  dataSo: FormSection[];
  tooltipPosition: TooltipPosition = 'above';
  bsModalRef: BsModalRef;
  isFocus = false;
  goToSectionNode: FormSection;
  isDisabled: boolean;
  modalSubscriptions: Subscription[] = [];
  uploadAttachmentRef: BsModalRef;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, FormSection>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<FormSection, TodoItemFlatNode>();

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<FormSection, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<FormSection, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: FormSection): FormSection[] => node.sections;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.name === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: FormSection, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.name
      ? existingNode
      : new TodoItemFlatNode();
    flatNode.name = node.name;
    flatNode.key = node.key;
    flatNode.level = level;
    flatNode.expandable = true; // edit this to true to make it always expandable
    if (node.sections) {
      flatNode.hasChild = (node.sections.length === 0) ? false : true;
    } else {
      flatNode.hasChild = false;
    }  // add this line. this property will help
    // us to hide the expand button in a node
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  constructor(
    private userService: UserService,
    public onlineDataEntryService: OnlineDataEntryService,
    private messageService: MessageService,
    private modalService: BsModalService
  ) {
    this.initial();
  }

  public get isDataChanged() {
    return this.onlineDataEntryService.dataChanged;
  }

  public get isFormFormatError() {
    return this.onlineDataEntryService.isFormFormatError;
  }

  ngOnChanges() {
    this.isDisabled = this.status === OnlineSubmissionsStatus.PROCESSING || this.userService.canViewFileAdminLink(this.user);
  }

  initial() {
    this.user = this.userService.getStoredUser();
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.onlineDataEntryService.dataChange.subscribe(data => {
      this.dataSource.data = data;
      //this.refreshTree();
    });
  }

  selectedFormSectionHandler(event) {
    this.selectedFormSection.emit(event);
  }

  saveAndValidateHandler(event) {
    this.saveAndValidate.emit(event);
  }

  changeStyleDynamically(node) {
    this.treeControl.expandAll();
    this.treeControl.expand(node)
    this.onlineDataEntryService.activeNode.next(node.key);
  }


  goToSection(node: TodoItemFlatNode) {
    this.goToSectionNode = this.flatNodeMap.get(node);
    this.treeControl.expandAll();
    if (this.onlineDataEntryService.dataChanged) {
      const initialState = {
        message: ' Any changes made to this section will be lost. Do you wish to proceed without saving?',
        title: 'Online Data Entry'
      };
      const bsModalRef = this.modalService.show(CommonConfirmationModalComponent, { initialState, class: 'modal-lg' });
      bsModalRef.content.isYesButtonClicked.subscribe((data: any) => {
        if (data) {
          this.navigateToClickedSection();
          bsModalRef.hide();
        }
        bsModalRef.hide();
      });
    } else {
      this.navigateToClickedSection();
    }
  }

  hideQ(node) {
    if(this.onlineDataEntryService.newRuleKeys) {
      return !this.onlineDataEntryService.newRuleKeys.includes(node.key);
    } else {
      return true;
    }
  }

  navigateToClickedSection() {
    let nestedNode = this.goToSectionNode;
    let keyValue = nestedNode.key.replace(/\./g, ' > ');
    this.onlineDataEntryService.setClickedName(keyValue.toUpperCase());
    this.onlineDataEntryService.setSelectedSection(nestedNode);
    this.selectedFormSection.emit(nestedNode);
    this.onlineDataEntryService.isFormUpdated = false;
  }

  onCancel() {

  }

  onValidate() {
    this.saveAndValidate.emit(this.onlineDataEntryService.dataUpdates);
  }


  onSubmit() {
    this.submit.emit(this.onlineDataEntryService.dataUpdates);
  }

  onSave() {
    this.save.emit(this.onlineDataEntryService.dataUpdates);
  }

  // To open summary page directly from side nav
  onOpenSummary() {
    this.openSummary.emit();
  }

  uploadAttachment() {
    const initialState = {user: this.user};
    this.uploadAttachmentRef = this.modalService.show(UploadAttachmentsComponent, {
      initialState: {
        ...initialState,
        formatedFile: true,
        fileExt: ['EXE']
      }, 
      class: 'modal-xl',
      backdrop: 'static', 
      keyboard: false 
    });
    this.uploadAttachmentRef.content.events.subscribe(() => {
      this.messageService.add(new AlertMessage('File Uploaded Successfully.', 'success'));
    });
    this.uploadAttachmentRef.content.isFileUploaded.subscribe(() => {
      this.onlineDataEntryService.isFileUploaded.next(true);
    });
  }
}
