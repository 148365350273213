import {FileSubmissionError} from './file-submission-error';

export class FileSubmissionErrorType {
  errorType: string;
  errors: FileSubmissionError[];
  downloadCsv: any;

  constructor(errorType: string, errors: FileSubmissionError[], downloadCsv: any) {
    this.errorType = errorType;
    this.errors = errors;
    this.downloadCsv = downloadCsv;
  }

}
