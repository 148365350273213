<ngx-loading [show]="loading"></ngx-loading>
<div id="attachmentUploadHeader" class="modal-header">
    <h2 id="attachmentUploadTitle" class="modal-title">Upload Attachment</h2>
    <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="row mb-1">
    <alert *ngIf="messageText" class="col-12" type="{{alertType}}">
        <span id="alert:message">{{messageText}}</span>
    </alert>
</div>

<form name="attachmentUploadForm" (ngSubmit)="finishUpload()" #attachmentUploadForm="ngForm">
    <div id="attachmentUploadBody" class="modal-body">
        <br/>
        <div>
            <h4>Select the Click to browse link or drag and drop the file to this upload area and click Upload.</h4>
            <h4>File types allowed include PDF, Docx, Xlsx, JPEG and PNG. The file size limit is 10MB.</h4>
        </div>
        <br/>
        <br/>
        <div draggable="true" ngClass="{{dragAreaClass}}">
            <div class="row">
                <div class="col-md-12 text-center">
                    <a href="javascript:void(0)" (click)="file.click()">
                        Click to browse
                    </a>
                    or drag & drop to upload your file
                    <input type="file" #file [multiple]="(maxFiles > 1)" (change)="onFileChange($event)"
                        style="display:none" />
                </div>
            </div>
        </div>
        <br />
        <div class="row" *ngIf="isFileUploading">
            <div class="col-12">
                <h4 class="text-center">Uploading {{uploadingFileName}}</h4>
                <p>
                    <progressbar type="info" [value]="uploadPercentage" [striped]="true" [animate]="true">
                        {{uploadPercentage}}%</progressbar>
                </p>
            </div>
        </div>

    </div>

    <div class="modal-footer">
        <div class="row">
            <div class="col-md-12 text-center">
                <button id="btn:attachmentUpload" class="btn btn-primary" (click)="getPresignedURLFromS3()"
                    [disabled]="!readyForUpload()" type="button">Upload</button> &nbsp;
                <button id="btn:attachmentUpload-cancel" class="btn btn-secondary" (click)="cancel()"
                    [disabled]="!readyForUpload()" type="button">Cancel</button>
            </div>
        </div>
    </div>

</form>