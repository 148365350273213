/*
 * Copyright 2018 National Association of Insurance Commissioners
 */

import { Injectable } from '@angular/core';
import { Breadcrumb } from '../model/breadcrumb';

@Injectable()
export class BreadcrumbService {
  private breadcrumbs: Breadcrumb[] = [];



  getBreadcrumbs(): Breadcrumb[] {
    return this.breadcrumbs;
  }

  addBreadcrumb(breadcrumb: Breadcrumb): Breadcrumb[] {
    if (this.breadcrumbs.indexOf(breadcrumb) === -1) {
      this.breadcrumbs.push(breadcrumb);
    }
    return this.breadcrumbs;
  }

  removeBreadcrumb(breadcrumb: Breadcrumb): Breadcrumb[] {
    this.breadcrumbs.splice(this.breadcrumbs.indexOf(breadcrumb));
    return this.breadcrumbs;
  }

  clearBreadcrumbs(): Breadcrumb[] {
    this.breadcrumbs = [];
    return this.breadcrumbs;
  }

}
