export const MOCK_CROSS_FIELD_VALIDATIONS = [
  {
    id: null,
    name: 'RC-1001-AGE',
    errorMessage: 'For all plan types if an age nearest birthday, or age last birthday discrepancy occurs, accept the record’s issue age unless it differs from the system-calculated age by more than one. If ˃ 1, display an error.',
    description: "if ( data['naic_company_code']  +  data['max_length_property'] ) {return true;} else {return false;}",
    expression: "if ( data['naic_company_code']  +  data['max_length_property'] ) {return true;} else {return false;}",
    active: true,
    publicViewable: true,
    validationLevel: '',
    dateCreated: null,
    userCreated: null
  },
  {
    id: null,
    name: 'RC-1002-AGE',
    errorMessage: 'The Date of Birth must be prior to the Issue Date',
    description: 'DTE BRTH NOT  < ISS DTE',
    expression: 'IF (IsNull([birth_date]) Or (IsNull([issue_date]))) THEN True ELSE (StrToNumber([birth_date]) <= StrToNumber([issue_date]))',
    active: true,
    publicViewable: true,
    validationLevel: 'ERROR',
    dateCreated: null,
    userCreated: null
  },
  {
    id: null,
    name: 'RC-1003-AGE',
    errorMessage: 'The Issue Date must be after the Date of Birth.',
    description: 'ANB and ALB DISCREP COMP to SYSCALC AGE, RCRDS ISS AGE DIFF > 1',
    expression: 'StrToNumber([issue_date]) >= StrToNumber([birth_date])',
    active: true,
    publicViewable: false,
    validationLevel: 'WARNING',
    dateCreated: null,
    userCreated: null
  },
  {
    id: null,
    name: 'RC-1002-AGE',
    errorMessage: 'The Date of Birth must be prior to the Issue Date',
    description: 'DTE BRTH NOT  < ISS DTE',
    expression: 'IF (IsNull([birth_date]) Or (IsNull([issue_date]))) THEN True ELSE (StrToNumber([birth_date]) <= StrToNumber([issue_date]))',
    active: true,
    publicViewable: true,
    validationLevel: 'ERROR',
    dateCreated: null,
    userCreated: null
  },
  {
    id: null,
    name: 'RC-1003-AGE',
    errorMessage: 'The Issue Date must be after the Date of Birth.',
    description: 'ANB and ALB DISCREP COMP to SYSCALC AGE, RCRDS ISS AGE DIFF > 1',
    expression: 'StrToNumber([issue_date]) >= StrToNumber([birth_date])',
    active: true,
    publicViewable: false,
    validationLevel: '',
    dateCreated: null,
    userCreated: null
  },
  {
    id: null,
    name: 'RC-1002-AGE',
    errorMessage: 'The Date of Birth must be prior to the Issue Date',
    description: 'DTE BRTH NOT  < ISS DTE',
    expression: 'IF (IsNull([birth_date]) Or (IsNull([issue_date]))) THEN True ELSE (StrToNumber([birth_date]) <= StrToNumber([issue_date]))',
    active: true,
    publicViewable: true,
    validationLevel: '',
    dateCreated: null,
    userCreated: null
  },
  {
    id: null,
    name: 'RC-1003-AGE',
    errorMessage: 'The Issue Date must be after the Date of Birth.',
    description: 'ANB and ALB DISCREP COMP to SYSCALC AGE, RCRDS ISS AGE DIFF > 1',
    expression: 'StrToNumber([issue_date]) >= StrToNumber([birth_date])',
    active: true,
    publicViewable: false,
    validationLevel: 'WARNING',
    dateCreated: null,
    userCreated: null
  },
  {
    id: null,
    name: 'RC-1002-AGE',
    errorMessage: 'The Date of Birth must be prior to the Issue Date',
    description: 'DTE BRTH NOT  < ISS DTE',
    expression: 'IF (IsNull([birth_date]) Or (IsNull([issue_date]))) THEN True ELSE (StrToNumber([birth_date]) <= StrToNumber([issue_date]))',
    active: true,
    publicViewable: true,
    validationLevel: '',
    dateCreated: null,
    userCreated: null
  },
  {
    id: null,
    name: 'RC-1003-AGE',
    errorMessage: 'The Issue Date must be after the Date of Birth.',
    description: 'ANB and ALB DISCREP COMP to SYSCALC AGE, RCRDS ISS AGE DIFF > 1',
    expression: 'StrToNumber([issue_date]) >= StrToNumber([birth_date])',
    active: true,
    publicViewable: false,
    validationLevel: '',
    dateCreated: null,
    userCreated: null
  }
];
