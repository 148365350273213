import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Aggregate } from '../model/aggregate';
import { AggregateData } from '../model/aggregate-data';
import { AggregateDataParam } from '../model/aggregate-data-param';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {HandleError, HttpErrorHandler} from '../../shared/http-error-handler.service';

@Injectable()
export class AggregationMaintenanceService {
  private selectedAggregate: Aggregate;
  handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('AggregationMaintenanceService');
  }

  getSelectedAggregate(): Aggregate {
    return this.selectedAggregate;
  }

  setSelectedAggregate(aggregate: Aggregate): void {
    this.selectedAggregate = aggregate;
  }

  getAggregates(schemaType: string): Observable<Aggregate[]> {
    const url = `${environment.apiUrl}${schemaType}/aggregates`;

    return this.httpClient.get<Aggregate[]>(url)
      .pipe(catchError(this.handleError('getAggregates', [])));
  }

  runAggregate(schemaType: string, aggregateData: AggregateData, skip: number, limit: number): Observable<any[]> {
    const url = `${environment.apiUrl}${schemaType}/aggregates/aggregatedata`;
    const params = new HttpParams()
      .set('skip', skip.toString())
      .set('limit', limit.toString());
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {
      headers: headers,
      params: params
    };
    return this.httpClient.post<Aggregate[]>(url, aggregateData, options);
  }

  createAggregateData(aggregate: Aggregate): AggregateData {
    const aggregateData: AggregateData = new AggregateData();
    const aggregateDataParams: AggregateDataParam[] = [];
    aggregate.params.forEach(param => {
      const aggregateDataParam: AggregateDataParam = new AggregateDataParam();
      aggregateDataParam.fieldName = param.field;
      aggregateDataParam.value = '';
      aggregateDataParams.push(aggregateDataParam);
    });
    aggregateData.params = aggregateDataParams;
    aggregateData.aggregate = aggregate;
    return aggregateData;
  }

}
