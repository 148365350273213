import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-schema-property-link-renderer',
  templateUrl: './schema-property-link-renderer.component.html'
})
export class SchemaPropertyLinkRendererComponent implements ICellRendererAngularComp {
  params: any;



  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  goToPropertyMaintenance(): void {
    this.params.context.componentParent.goToPropertyMaintenance(this.params.value);
  }

}
