import { Component, OnDestroy, OnInit } from '@angular/core';
import {Subject } from 'rxjs';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import {FileValidationMaintenanceService} from '../services/file-validation-maintenance.service';
import {ValidationSchema} from '../model/validation-schema';
import {Breadcrumb} from '../../model/breadcrumb';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from '../../messages/service/message.service';
import { AlertMessage } from '../../messages/model/alert-message';
import { DateRendererComponent } from '../../cell-renderer/date-renderer/date-renderer.component';
import { UnCamelCasePipe } from '../../pipes/un-camel-case.pipe';
import { ViewRendererComponent } from './cell-renderer/view-renderer/view-renderer.component';

@Component({
  selector: 'app-schema-audit',
  templateUrl: './schema-audit.component.html'
})
export class SchemaAuditComponent implements OnInit, OnDestroy {
  title: string;
  private ngUnsubscribe = new Subject();
  messageText: string;
  error = false;
  schemaType: string;
  validationSchemas: ValidationSchema[];
  breadcrumbs: Breadcrumb[];
  breadcrumb = new Breadcrumb('Field Definitions', '/schemaAudit');
  loading = false;

  columnDefs = [];
  rowData = [];
  defaultColDef: any;
  context: any;
  frameworkComponents: any;

  constructor(
    private schemaMaintenanceService: FileValidationMaintenanceService,
    public breadcrumbService: BreadcrumbService,
    private unCamelCase: UnCamelCasePipe,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.context = { componentParent: this };
    this.frameworkComponents = {
      dateRenderer: DateRendererComponent,
      viewRenderer: ViewRendererComponent,
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this.schemaType = this.schemaMaintenanceService.getSelectedValidationSchema().type;
    this.breadcrumbs = this.breadcrumbService.getBreadcrumbs();
    this.title = `${this.schemaType} Field Definition Audit`;
    this.getValidationSchemasByType();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getValidationSchemasByType(): void {
    this.loading = true;
    this.schemaMaintenanceService.getValidationSchemasByType(this.schemaType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        validationSchemas => {
          this.loading = false;
          this.error = false;
          this.rowData = validationSchemas;
          this.columnDefs = this.getColumnDef();
        },
        error => {
          this.loading = false;
          this.error = true;
          this.messageText = <any>error;
          this.messageService.add(new AlertMessage(this.messageText, 'danger'));
        }
      );
  }

  getColumnDef(): any[] {
    const columnsDef: any[] = [];
    columnsDef.push({ headerName: 'User Created', field: 'userCreated' });
    columnsDef.push({ headerName: 'Date Created', field: 'dateCreated', cellRenderer: 'dateRenderer' });
    columnsDef.push({ headerName: 'View', field: 'id', cellRenderer: 'viewRenderer', autoHeight: true });
    return columnsDef;
  }

  onGridReady(params: any): void {
    const gridApi = params.api;

    gridApi.sizeColumnsToFit();
  }



}
