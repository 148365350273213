import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { keys } from 'lodash';
import { Subscription } from 'rxjs';
import { ValidationSchema } from '../file-validation-maintenance/model/validation-schema';
import { FileValidationMaintenanceService } from '../file-validation-maintenance/services/file-validation-maintenance.service';
import { AlertMessage } from '../messages/model/alert-message';
import { MessageService } from '../messages/service/message.service';
import { User } from '../model/user';
import { UserService } from '../service/user.service';
import { PrimaryUserAssignmentComponent } from './cell-renderer/primary-user-assignment/primary-user-assignment.component';
import { RemoveUserAssignmentComponent } from './cell-renderer/remove-user-assignment/remove-user-assignment.component';
import { SecondaryUserAssignmentComponent } from './cell-renderer/secondary-user-assignment/secondary-user-assignment.component';
import { UserAssignments } from './model/user-assignment';
import { UserAssignmentService } from './services/user-assignment.service';

@Component({
  selector: 'app-user-assignment',
  templateUrl: './user-assignment.component.html'
})
export class UserAssignmentComponent implements OnInit, OnDestroy {

  title: string;
  loading: boolean = false;
  error: boolean = false;
  scrollBarHorizontal = (window.innerWidth < 1200);
  user: User;
  schema: string;
  selectedAssignmentValue: any = '';
  userIdToBeAdded: string = '';

  subscriptions: Array<Subscription> = [];

  userAssignmentsForAdmin: UserAssignments;
  userAssignmentsForPrimary: UserAssignments[] = [];

  datcallAdminUserRole: boolean;
  rdcAdminUserRole: boolean;
  companyAdminUserId: string;
  companyAdminPrimaryUser: boolean;
  userRole: string;

  validationSchema: ValidationSchema;
  assignmentKeyFieldName: string;
  transformedAssignmentKeyFieldName: string;
  showUserAssignmentsPage: boolean;
  noAssignmentKeySelectedMessage: string;

  columnDefs = [];
  rowData = [];
  defaultColDef: any;
  context: any;
  frameworkComponents: any;
  gridApi: any;

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private userAssignmentService: UserAssignmentService,
    private schemaMaintenanceService: FileValidationMaintenanceService
  ) {
    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 12000);
    };
  }

  ngOnInit() {
    this.context = { componentParent: this };
    this.frameworkComponents = {
      removeuserAssignment: RemoveUserAssignmentComponent,
      primaryuserAssignment: PrimaryUserAssignmentComponent,
      secondaryuserAssignment: SecondaryUserAssignmentComponent
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this.user = this.userService.getStoredUser();
    this.messageService.clear();
    this.setUserRole();
    this.schema = this.user.currentSchema.toLocaleUpperCase();
    this.title = `${this.schema} User Assignments`;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.messageService.clear();
  }

  setUserRole() {
    this.datcallAdminUserRole = this.userService.canViewandUpdateUserAssignments(this.user);
    this.rdcAdminUserRole = this.user.isRdcAdmin;
    this.companyAdminPrimaryUser = this.userService.canViewFileDashboard(this.user);
    this.getAssignmentKeyNameFromDatcallFieldDefinitions();
    // this.getAssignmentKeys();
    if (this.datcallAdminUserRole === true) {
      this.userRole = 'datacallAdmin';
    } else if (this.companyAdminPrimaryUser === true) {
      this.userRole = 'primaryUser';
    } else if (this.rdcAdminUserRole === true) {
      this.userRole = 'rdcAdmin';
    }
    this.userAssignmentService.assignmentKey = '';
  }

  getAssignmentKeyNameFromDatcallFieldDefinitions() {
    this.loading = true;
    this.schemaMaintenanceService.getCurrentValidationSchemaByType(this.user.currentSchema)
      .subscribe(
        validationSchema => {
          this.validationSchema = validationSchema;
          let key: Array<string> = _.keys(this.validationSchema.properties);
          let properties: any[] = [];
          key.forEach(x => {
            properties.push(this.validationSchema.properties[x])
          });
          properties.forEach(x => {
            if (x.isAssignmentsKey) {
              this.assignmentKeyFieldName = x.name;
            }
          });
          if (this.assignmentKeyFieldName !== undefined) {
            this.showUserAssignmentsPage = true;
            this.transformedAssignmentKeyFieldName = this.noUnderscore();
            this.columnDefs = this.userAssignmentService.getColumnDef(this.transformedAssignmentKeyFieldName);
          } else {
            this.showUserAssignmentsPage = false;
            this.noAssignmentKeySelectedMessage = 'No Datacall Field has been assigned as an Assignment key.';
          }
          this.loading = false;
        }
      );
  }

  noUnderscore(): string {
    const text = this.assignmentKeyFieldName.split('_');
    const transformedText: string[] = [];
    text.forEach(t => {
      if (t.toLowerCase() === 'naic') {
        transformedText.push(t.toUpperCase());
      } else {
        transformedText.push(_.capitalize(t));
      }
    });
    return transformedText.join(' ');

  }

  getUserAssignmentsOnEnter(assignmentKey: any) {
    this.getUserAssignments(assignmentKey);
  }

  getUserAssignments(assignmentKey: any) {
    this.userAssignmentService.assignmentKey = assignmentKey;
    this.loading = true;
    const sub = this.userAssignmentService.getUserAssignments(this.user.currentSchema, assignmentKey, this.assignmentKeyFieldName)
      .subscribe(
        res => {
          if (this.userRole === 'datacallAdmin' || this.userRole === 'rdcAdmin') {
            if ((res.primaryUser === null || res.primaryUser === '') && res.secondaryUsers.length === 0) {
              this.userAssignmentsForAdmin = undefined;
              this.rowData = [];
              this.loading = false;
            } else {
              this.userAssignmentsForAdmin = res;
              if (this.userAssignmentsForAdmin.primaryUser === null) {
                this.userAssignmentsForAdmin.primaryUser = '';
              }
              this.rowData = this.userAssignmentService.setTableData(this.userAssignmentsForAdmin, this.userRole);
              this.loading = false;
            }
          } else {
            this.getUserAssignmentsForPrimaryUser();
          }
        },
        (error: HttpErrorResponse) => {
          this.messageService.clear();
          this.messageService.add(new AlertMessage(`Error Getting User Assignments`, 'failure'));
          this.loading = false;
        }
      );
    this.subscriptions.push(sub);
  }

  getUserAssignmentsForPrimaryUser() {
    this.loading = true;
    const sub = this.userAssignmentService.getUserAssignmentsPrimaryUser(this.user.currentSchema, this.user.userId)
      .subscribe(
        res => {
          this.userAssignmentsForPrimary = res;
          this.userAssignmentsForPrimary = this.userAssignmentsForPrimary.filter(x => x.assignmentValue === this.userAssignmentService.assignmentKey);
          if (this.userAssignmentsForPrimary.length !== 0) {
            if (this.userAssignmentsForPrimary[0].primaryUser === this.user.userId) {
              this.rowData = this.userAssignmentService.setTableData(this.userAssignmentsForPrimary[0], this.userRole);
              this.loading = false;
            } else {
              this.rowData = [];
              this.loading = false;
            }
          } else {
            this.rowData = [];
            this.loading = false;
          }
        }
      );
    this.subscriptions.push(sub);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.sizeColumnsToFit();
  }

  sizeColumnsToFit(): void {
    if (this.columnDefs.length <= 8) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  addUserId(userIdToBeAdded) {
    this.loading = true;
    if (this.userRole === 'datacallAdmin') {
      this.userAssignmentService.postAddPrimaryUser(this.user.currentSchema, this.selectedAssignmentValue, this.assignmentKeyFieldName, userIdToBeAdded).subscribe(
        (response) => {
          if (response) {
            this.getUserAssignments(this.userAssignmentService.assignmentKey);
            this.messageService.clear();
            this.messageService.add(new AlertMessage(`"${userIdToBeAdded}" has been added`, 'success'));
            this.userIdToBeAdded = '';
          } else {
            this.messageService.clear();
            this.messageService.add(new AlertMessage(`Entered User ID "${userIdToBeAdded}" is invalid.`, 'danger'));
            this.loading = false;
          }
        }
      )
    } else {
      this.loading = true;
      this.userAssignmentService.postAddSecondaryUser(this.user.currentSchema, this.selectedAssignmentValue, this.assignmentKeyFieldName, userIdToBeAdded).subscribe(
        (response) => {
          if (response) {
            this.getUserAssignments(this.userAssignmentService.assignmentKey);
            this.messageService.clear();
            this.messageService.add(new AlertMessage(`"${userIdToBeAdded}" has been added`, 'success'));
            this.userIdToBeAdded = '';
            this.loading = false;
          } else {
            this.messageService.clear();
            this.messageService.add(new AlertMessage(`Entered User ID "${userIdToBeAdded}" is invalid.`, 'danger'));
            this.loading = false;
          }
        }
      )
    }
  }

  confirmRemove(rowToBeDeleted) {
    let assignmentValue = rowToBeDeleted.assignmentValue;
    let userId = rowToBeDeleted.userId;
    this.loading = true;
    if (this.userRole === 'datacallAdmin') {
      this.userAssignmentService.deletePrimaryUser(this.user.currentSchema, assignmentValue, this.assignmentKeyFieldName, userId).subscribe(
        (response) => {
          if (response) {
            const message = `Primary User "${userId}" has been deleted`;
            this.getUserAssignments(this.userAssignmentService.assignmentKey);
            this.messageService.clear();
            this.messageService.add(new AlertMessage(message, 'success'));
          } else {
            this.messageService.clear();
            this.messageService.add(new AlertMessage(`Encountered error deleting ${userId}`, 'danger'));
            this.loading = false;
          }
        }
      )
    } else {
      this.userAssignmentService.deleteSecondaryUser(this.user.currentSchema, assignmentValue, this.assignmentKeyFieldName, userId).subscribe(
        (response) => {
          if (response) {
            const message = `Secondary User "${userId}" has been deleted`;
            this.getUserAssignments(this.userAssignmentService.assignmentKey);
            this.messageService.clear();
            this.messageService.add(new AlertMessage(message, 'success'));
          } else {
            this.messageService.clear();
            this.messageService.add(new AlertMessage(`Encountered error deleting ${userId}`, 'danger'));
            this.loading = false;
          }
        }
      )
    }
  }

  changeToPrimaryUser(data) {
    let assignmentKey = data.assignmentValue;
    let userId = data.userId;
    this.loading = true;
    this.userAssignmentService.putUpdatePrimaryUser(this.user.currentSchema, assignmentKey, this.assignmentKeyFieldName, userId).subscribe(
      () => {
        const message = `Company Admin "${userId}" Updated`;
        this.getUserAssignments(this.userAssignmentService.assignmentKey);
        this.messageService.clear();
        this.messageService.add(new AlertMessage(message, 'success'));
      }
    )
  }

  changeToSecondaryUser(data) {
    let assignmentKey = data.assignmentValue;
    let userId = data.userId;
    this.loading = true;
    this.userAssignmentService.putUpdateSecondaryUser(this.user.currentSchema, assignmentKey, this.assignmentKeyFieldName, userId).subscribe(
      () => {
        const message = `Secondary User "${userId}" Updated`;
        this.getUserAssignments(this.userAssignmentService.assignmentKey);
        this.messageService.clear();
        this.messageService.add(new AlertMessage(message, 'success'));
      }
    )
  }

  searchButtonDisable(selectedAssignmentValue): boolean {
    if (selectedAssignmentValue.trim().length === 0 || selectedAssignmentValue === null) {
      return true;
    } else {
      return false;
    }
  }

  addInputTextAndButtonDisable(): boolean {
    if (this.userAssignmentService.assignmentKey.trim().length !== 0) {
      if (this.userRole === 'datacallAdmin') {
        // Datacall Admin
        if (this.userAssignmentsForAdmin !== undefined) {
          if (this.userAssignmentsForAdmin.primaryUser === null || this.userAssignmentsForAdmin.primaryUser === '') {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else if (this.userRole === 'primaryUser') {
        //Primary User
        if (this.userAssignmentsForPrimary.length !== 0) {
          if (this.userAssignmentsForPrimary[0].primaryUser === this.user.userId) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        // Other Users
        return true;
      }

    } else {
      return true;
    }
  }
}
