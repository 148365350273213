import { Component, TemplateRef } from '@angular/core';
import { ICellRenderer } from 'ag-grid-community';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertMessage } from '../../../messages/model/alert-message';
import { MessageService } from '../../../messages/service/message.service';
import { FileValidationMaintenanceService } from '../../services/file-validation-maintenance.service';

@Component({
  selector: 'app-order-number-renderer',
  templateUrl: './order-number-renderer.component.html',
  styleUrls: ['./order-number-renderer.component.css']
})
export class OrderNumberRendererComponent implements ICellRenderer {
  params: any;
  modalRef: BsModalRef;
  deleteKey: string;
  defaultValue: any;
  changedValue: any;
  visible: boolean = false;
  min_value: number;
  datafieldName: any;
  clickedEnter: boolean = false;
  max_value: number;

  constructor(
    private modalService: BsModalService,
    private schemaValidationMaintanance: FileValidationMaintenanceService,
    private messageService: MessageService
  ) { }

  agInit(params: any): void {
    this.params = params;
    this.min_value = 1; //min value to reorder
    this.max_value = this.schemaValidationMaintanance.getLengthOfRows(); //max value to reorder
  }

  refresh(): boolean {
    return true;
  }

  onEnter(event, template: TemplateRef<any>) {
    this.messageService.clear();
    this.clickedEnter = true;
    this.changedValue = event.target.value;
    this.defaultValue = this.params.value.orderNumber;
    this.datafieldName = this.params.value.name;
    if (Number(this.defaultValue) !== Number(this.changedValue) && this.clickedEnter === true) {
      this.modalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard: false });
    }

  }

  onChange(event) {
    this.messageService.clear();
    this.changedValue = event.target.value.split(" ").join("");
    this.defaultValue = this.params.value.orderNumber;
    this.datafieldName = this.params.value.name;
    if (Number(this.defaultValue) !== Number(this.changedValue)) {
      this.visible = true;
    } else if (this.changedValue === '') {
      this.visible = false;
    } else {
      this.visible = false;
    }
  }

  onBlur(event, template: TemplateRef<any>) {
    this.messageService.clear();
    this.changedValue = event.target.value.split(" ").join("");
    this.defaultValue = this.params.value.orderNumber;
    this.datafieldName = this.params.value.name;
    if (Number(this.defaultValue) !== Number(this.changedValue) && this.clickedEnter === false) {
      this.modalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: 'static', keyboard: false });
    }
  }

  cancelUpdate(): void {
    this.modalRef.hide();
    this.params.context.componentParent.cancelUpdate();
  }

  cancel(): void {
    this.params.context.componentParent.cancelUpdate();
  }

  confirmUpdate(): void {
    this.modalRef.hide();
    if (this.changedValue === '') {
      this.messageService.add(new AlertMessage(`'Order Number must not be empty' `, 'danger'));
      this.params.context.componentParent.cancelUpdate();
    } else {
      this.params.context.componentParent.changeOrderNumber(this.changedValue, this.defaultValue);
    }
  }

  confirm(): void {
    this.params.context.componentParent.changeOrderNumber(this.changedValue, this.defaultValue);
  }
}
