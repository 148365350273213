/*
 * Copyright 2018 National Association of Insurance Commissioners
 */

import {HttpEvent, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MOCK_PBR_FILE_SUBMISSION_AUDITS} from './mock-file-submission-audit';
import {MOCK_CAS_DISCLAIMER_ADMIN, MOCK_PBR_DISCLAIMER_ACTUARY} from './mock-disclaimers';
import {MOCK_FILE_SUBMISSIONS} from './mock-file-submissions';
import {DocumentErrorList} from '../file-admin/error-type/model/document-error-list';
import {MOCK_DOCUMENT_ERROR_LIST} from './file-admin/mock-document-error-list';
import {SubmissionKeys} from '../model/submission-keys';
import {MOCK_PBR_ALL_ERRORS} from './mock-file-administration-errors';
import {MOCK_CROSS_FIELD_VALIDATIONS} from './validation-maintenance/mock-cross-field-validations';
import {MOCK_VALIDATION_SCHEMA} from './schema-maintenance/mock-validation-schema';
import {MOCK_FILE_SUBMISSION_TOLERANCES} from './submission-level-tolerances/mock-file-submission-tolerances';
import {MOCK_AGGREGATES} from './aggregation-maintenance/mock-aggregates';
import {MOCK_SUBMISSION_KEYS} from './mock-submission-keys';
import {MOCK_FILING_DATES} from './filing-dates/mock-filing-dates';
import {MOCK_VALIDATION_SCHEMAS} from './schema-maintenance/mock-validation-schemas';
import {MOCK_PBR_WAITING_FOR_USER_APPROVAL_ERRORS} from './mock-file-submission-errors';
import {MOCK_AGGREGATE_DATA} from './aggregation-maintenance/mock-aggregate-data';
import {MOCK_CREDIBLE_DATA} from './mock-credible-data';
import {MOCK_TLL} from './mock-users';
import {MOCK_FILING_BLANK_LAYOUT} from './mock-filing-blank-layout';
import {createMockValidations} from './mock-filing-validations';
import {MOCK_PBR_ALL_ERRORS_USER} from './mock-file-dashboard-errors';
import {MOCK_DOCUMENT_ERROR_LIST_USER} from './file-dashboard/mock-document-error-list';
import {MOCK_USER_ASSIGNMENT} from './user-assignment/mock-user-assignment';
import {DatacallSettingEnum} from '../roleconfig/datacall-setting/model/datacall-setting.enum';
import {
  MOCK_TEST_EXPRESSION_BUILDER_RESPONSE_FALSE} from './mock-test-expression-builder-response';
import {MOCK_ODE} from './mock-online-data-entry';
import {MOCK_DOWNLOAD_URL_S3_RESPONSE} from './file-admin/mock-download-url-s3';
import { MOCK_FILE_LEVEL_VALIDATION } from './mock-file-level-validation';

export function rdcMockBackend(url: string, method: string, request: HttpRequest<any>): Observable<HttpEvent<any>> {

  // RDC Role APIs
  if (url.endsWith('/priv')) {
    switch (method) {
      case 'GET': {
        return getOK(MOCK_TLL);
      }
      default: {
        return getBadRequest('priv');
      }
    }
  }

  // Audit Trail APIs
  if (url.match(/\w+\/audit/)) {
    switch (method) {
      case 'GET': {
        if (url.endsWith('/filter')) {
          // not currently being used
        } else if (url.match(/\/submission\/\w+$/)) {
          return getOK(MOCK_PBR_FILE_SUBMISSION_AUDITS);
        } else {
          return getOK(MOCK_PBR_FILE_SUBMISSION_AUDITS);
        }
        break;
      }
      default: {
        return getBadRequest('audit');
      }
    }
  }

  // Disclaimer APIs
  if (url.match(/\w+\/disclaimer/)) {
    switch (method) {
      case 'GET': {
        if (url.match(/\w+\/PBR\/\w+$/) || url.match(/\w+\/pbr\/\w+$/)) {
          return getOK(MOCK_PBR_DISCLAIMER_ACTUARY);
        } else if (url.match(/\w+\/CAS\/\w+$/) || url.match(/\w+\/cas\/\w+$/)) {
          return getOK(MOCK_CAS_DISCLAIMER_ADMIN);
        } else {
          return getBadRequest('disclaimer');
        }
      }
      case 'PUT': {
        return getNoContent();
      }
      default: {
        return getBadRequest('disclaimer');
      }
    }
  }


  // File Admin APIs
  if (url.match(/\w+\/fileadmin/)) {
    switch (method) {
      case 'GET': {
        if (url.match(/\/notes\/\w+$/)) {
          return getOK(MOCK_FILE_SUBMISSIONS);
        } else if (url.match(/\/fileSubmissions\/\w+\/documentErrors$/)) {
          return getOK(MOCK_PBR_ALL_ERRORS);
        } else if (url.match(/\/fileSubmissions\/\w+\/documentErrorLists\/\w+$/)) {
          const params = request.params;
          const skip = +params.get('skip');
          const limit = +params.get('limit');
          const docs = MOCK_DOCUMENT_ERROR_LIST.documentsWithErrors.slice(skip, skip + limit);
          const data: DocumentErrorList = new DocumentErrorList(docs, MOCK_DOCUMENT_ERROR_LIST.totalErrors);
          return getOK(data);
        } else {
          return getBadRequest('fileadmin');
        }
      }
      case 'PUT': {
        if (url.endsWith('/notes')) {
          return getNoContent();
        } else {
          return getNoContent();
        }
      }
      case 'POST': {
        if (url.endsWith('/fileSubmissionSearchRequest')) {
          const submissionKeys: SubmissionKeys = JSON.parse(request.body);
          let empty = false;
          submissionKeys.keys.forEach(key => {
            if (key.values.indexOf('empty') >= 0) {
              empty = true;
            }
          });
          if (empty) {
            return getOK([]);
          } else {
            return getOK(MOCK_FILE_SUBMISSIONS);
          }
        } else if (url.match(/\/fileSubmissions\/\w+$/)) {
          return getAccepted();
        } else {
          return getBadRequest('fileadmin');
        }
      }
      default: {
        return getBadRequest('fileadmin');
      }
    }
  }

  // File Submission APIs
  if (url.match(/\w+\/filesubmissions/)) {
    switch (method) {
      case 'GET': {
        if (url.endsWith('/currentuser')) {
          return getOK(MOCK_FILE_SUBMISSIONS);
        } else if (url.match(/\/\w+\/filesubmissionerrors$/)) {
          return getOK(MOCK_PBR_WAITING_FOR_USER_APPROVAL_ERRORS);
        } else if (url.match(/\/\w+\/filesubmissionerrorssummary$/)) {
          return getOK(MOCK_PBR_ALL_ERRORS_USER);
        } else if (url.match(/\/\w+\/documentErrorLists\/\w+$/)) {
          const params = request.params;
          const skip = +params.get('skip');
          const limit = +params.get('limit');
          const docs = MOCK_DOCUMENT_ERROR_LIST_USER.documentsWithErrors.slice(skip, skip + limit);
          const data: DocumentErrorList = new DocumentErrorList(docs, MOCK_DOCUMENT_ERROR_LIST_USER.totalErrors);
          return getOK(data);
        } else {
          return getBadRequest('filesubmissions');
        }
      }
      default: {
        return getBadRequest('filesubmissions');
      }
    }
  }

  // File Submission Error APIs
  if (url.match(/\w+\/errors/)) {
    switch (method) {
      case 'GET': {
        if (url.endsWith('/errortype')) {

        } else {

        }
        break;
      }
      default: {
        return getBadRequest('errors');
      }
    }
  }

  // Rerun Validation APIs
  if (url.match(/\w+\/rerunValidations/)) {
    switch (method) {
      case 'GET': {
        if (url.endsWith('/discrepancy')) {
          return getOK({});
        } else {
          return getOK({});
        }
      }
      default: {
        return getBadRequest('rerunValidations');
      }
    }
  }

  // Upload APIs
  if (url.match(/\/upload\//)) {
    switch (method) {
      case 'POST': {
        return getCreated();
      }
      default: {
        return getBadRequest('upload');
      }
    }
  }

  // Validation APIs
  if (url.match(/\w+\/fileSubmissionValidations/)) {
    switch (method) {
      case 'GET': {
        return getOK({});
      }
      case 'PUT': {
        return getOK({});
      }
      default: {
        return getBadRequest('fileSubmissionValidations');
      }
    }
  }

  // Aggregate APIs
  if (url.match(/\w+\/aggregates/)) {
    switch (method) {
      case 'GET': {
        return getOK(MOCK_AGGREGATES);
      }
      case 'POST': {
        const limit = +request.params.get('limit');
        const offset = +request.params.get('skip');
        const end = offset + limit;
        const results = MOCK_AGGREGATE_DATA.slice(offset, end);
        return getOK(results);
      }
      default: {
        return getBadRequest('aggregates');
      }
    }
  }

  // Credible APIs
  if (url.match(/\w+\/credibleData/)) {
    switch (method) {
      case 'GET': {
        return getOK(MOCK_CREDIBLE_DATA);
      }
      case 'PUT': {
        return getNoContent();
      }
      default: {
        return getBadRequest('credibleData');
      }
    }
  }

  // Role Config APIs
  if (url.match(/\w+\/roleconfig/)) {
    switch (method) {
      case 'GET': {
        if (url.match(/\/role\/currentuserroles\/status\/\w+\/canChangeTo$/)) {
          const options = [{
            'role': 'DATA_CALL_USER_PR',
            'statuses': [],
            'permissions': ['read_own_filesubmissions', 'read_adminfilesubmission_errors', 'update_filesubmission_status', 'create_own_filesubmission', 'read_disclaimer_notice', 'update_disclaimer_acceptance', 'read_validationschema_properties', 'read_filesubmission_errors'],
            'systemRole': 'user'
          }, {
            'role': 'DATA_CALL_ADMIN_PR',
            'statuses': [{
              'status': 'FILE_PENDING_NAIC_REVIEW',
              'canChangeTo': ['NAIC_REVIEW_IN_PROGRESS'],
              'systemAutoChangeTo': null
            }, {
              'status': 'FILE_PENDING_ADMIN_REVIEW',
              'canChangeTo': ['NAIC_REVIEW_IN_PROGRESS'],
              'systemAutoChangeTo': null
            }, {
              'status': 'NAIC_REVIEW_IN_PROGRESS',
              'canChangeTo': ['FILE_PENDING_NAIC_REVIEW', 'AWAITING_SENDER_VERIFICATION', 'NAIC_REJECTS_FILE_RESUBMIT', 'NAIC_ACCEPTS_WITH_ERRORS'],
              'systemAutoChangeTo': null
            }, {
              'status': 'AWAITING_SENDER_VERIFICATION',
              'canChangeTo': ['RESPONSE_RECEIVED'],
              'systemAutoChangeTo': null
            }, {
              'status': 'RESPONSE_RECEIVED',
              'canChangeTo': ['AWAITING_SENDER_VERIFICATION', 'NAIC_REJECTS_FILE_RESUBMIT', 'NAIC_ACCEPTS_WITH_ERRORS'],
              'systemAutoChangeTo': null
            }, {
              'status': 'NAIC_REJECTS_FILE_RESUBMIT',
              'canChangeTo': null,
              'systemAutoChangeTo': null
            }, {
              'status': 'NAIC_ACCEPTS_WITH_ERRORS',
              'canChangeTo': ['NAIC_REVIEW_COMPLETE'],
              'systemAutoChangeTo': null
            }],
            'permissions': ['read_adminfilesubmission_errors', 'update_filesubmission_status', 'read_disclaimer_notice', 'update_disclaimer_acceptance', 'read_validationschema_properties', 'read_alladmin_validationschema', 'read_all_aggregates', 'perform_aggregate_run', 'read_filesubmission_audittrail', 'read_filesubmission_crediblecocodes', 'update_filesubmission_crediblestatus', 'read_all_crossfieldvalidations', 'update_filesubmission_notes', 'read_filesubmission_notes', 'read_all_datacalltolerances', 'read_all_filesubmissions', 'read_all_statuschanges', 'read_all_validationschema', 'read_current_validationschema', 'create_crossfield_validation', 'update_crossfield_validation', 'perform_filesubmission_search', 'read_filesubmissiontolerance_current', 'read_filingblank_layout', 'create_filingblank_layout', 'perform_validation_rerun', 'create_validation_schema', 'create_filesubmission_tolerances', 'read_datacall_dates', 'create_datacall_dates', 'perform_filesubmission_reload', 'update_roleconfig_definitions', 'read_roleconfig_definitions', 'perform_export_process', 'perform_import_process'],
            'systemRole': 'admindatacall'
          }];
          return getOK(options);
        } else {
          const options = [{
            'role': 'DATA_CALL_USER_PR',
            'statuses': [],
            'permissions': ['read_own_filesubmissions', 'read_adminfilesubmission_errors', 'update_filesubmission_status', 'create_own_filesubmission', 'read_disclaimer_notice', 'update_disclaimer_acceptance', 'read_validationschema_properties', 'read_filesubmission_errors'],
            'systemRole': 'user'
          }, {
            'role': 'DATA_CALL_ADMIN_PR',
            'statuses': [{
              'status': 'FILE_PENDING_NAIC_REVIEW',
              'canChangeTo': ['NAIC_REVIEW_IN_PROGRESS'],
              'systemAutoChangeTo': null
            }, {
              'status': 'FILE_PENDING_ADMIN_REVIEW',
              'canChangeTo': ['NAIC_REVIEW_IN_PROGRESS'],
              'systemAutoChangeTo': null
            }, {
              'status': 'NAIC_REVIEW_IN_PROGRESS',
              'canChangeTo': ['FILE_PENDING_NAIC_REVIEW', 'AWAITING_SENDER_VERIFICATION', 'NAIC_REJECTS_FILE_RESUBMIT', 'NAIC_ACCEPTS_WITH_ERRORS'],
              'systemAutoChangeTo': null
            }, {
              'status': 'AWAITING_SENDER_VERIFICATION',
              'canChangeTo': ['RESPONSE_RECEIVED'],
              'systemAutoChangeTo': null
            }, {
              'status': 'RESPONSE_RECEIVED',
              'canChangeTo': ['AWAITING_SENDER_VERIFICATION', 'NAIC_REJECTS_FILE_RESUBMIT', 'NAIC_ACCEPTS_WITH_ERRORS'],
              'systemAutoChangeTo': null
            }, {
              'status': 'NAIC_REJECTS_FILE_RESUBMIT',
              'canChangeTo': null,
              'systemAutoChangeTo': null
            }, {
              'status': 'NAIC_ACCEPTS_WITH_ERRORS',
              'canChangeTo': ['NAIC_REVIEW_COMPLETE'],
              'systemAutoChangeTo': null
            }],
            'permissions': ['read_adminfilesubmission_errors', 'update_filesubmission_status', 'read_disclaimer_notice', 'update_disclaimer_acceptance', 'read_validationschema_properties', 'read_alladmin_validationschema', 'read_all_aggregates', 'perform_aggregate_run', 'read_filesubmission_audittrail', 'read_filesubmission_crediblecocodes', 'update_filesubmission_crediblestatus', 'read_all_crossfieldvalidations', 'update_filesubmission_notes', 'read_filesubmission_notes', 'read_all_datacalltolerances', 'read_all_filesubmissions', 'read_all_statuschanges', 'read_all_validationschema', 'read_current_validationschema', 'create_crossfield_validation', 'update_crossfield_validation', 'perform_filesubmission_search', 'read_filesubmissiontolerance_current', 'read_filingblank_layout', 'create_filingblank_layout', 'perform_validation_rerun', 'create_validation_schema', 'create_filesubmission_tolerances', 'read_datacall_dates', 'create_datacall_dates', 'perform_filesubmission_reload', 'update_roleconfig_definitions', 'read_roleconfig_definitions', 'perform_export_process', 'perform_import_process'],
            'systemRole': 'admindatacall'
          }];
          return getOK(options);
        }
      }
      default: {
        return getBadRequest('roleconfig');
      }
    }
  }

  // Datacall Settings APIs
  if (url.match(/\w+\/datacallsettings/)) {
    switch (method) {
      case 'GET': {
        if (url.endsWith('datacallsettings')) {
          const options = [{name: DatacallSettingEnum.rejectSubmission, value: 'true'}];
          return getOK(options);
        } else {
          const options = [{name: DatacallSettingEnum.userAssignment, value: 'true'},
            {name: DatacallSettingEnum.rejectSubmission, value: 'true'},
            {name: DatacallSettingEnum.copyToReportingDB, value: 'true'},
            {name: DatacallSettingEnum.notificationOnSubmission, value: 'true'},
            {name: DatacallSettingEnum.notificationOnSubmissionEmailAddress, value: 'kkatali@naic.org'}];
          return getOK(options);
        }
      }
      default: {
        return getBadRequest('datacallsettings');
      }
    }
  }

  //Online Data Entry

  if (url.match(/\w+\/filingblanklayout/)) {
    switch (method) {
      case 'GET': {
        return getOK(MOCK_ODE);
      }
      default: {
        return getBadRequest('filingblanklayout');
      }
    }
  }

  //Company Assignmnets
  if (url.match(/\w+\/userAssignments/)) {
    switch (method) {
      case 'GET': {
        if (url.endsWith(`info`)) {
          return getOK(true);
        } else if (url.endsWith(`${MOCK_USER_ASSIGNMENT[0].primaryUser}`)) {
          return getOK(true);
        }
      }
      case 'PUT': {
        if (url.endsWith(`${MOCK_USER_ASSIGNMENT[0].primaryUser}`)) {
          return getOK(true);
        }
      }
    }
  }

  // Cross Field Validation APIs
  if (url.match(/\w+\/crossfieldvalidations/)) {
    switch (method) {
      case 'GET': {
        if (url.endsWith('current')) {
          return getOK(MOCK_CROSS_FIELD_VALIDATIONS);
        } else if (url.endsWith('current/active')) {
          return getOK(MOCK_CROSS_FIELD_VALIDATIONS);
        } else if (url.match(/\/\w+\D\w+\D\w+\/history$/)) {
          return getOK(MOCK_CROSS_FIELD_VALIDATIONS);
        } else {
          return getOK(MOCK_CROSS_FIELD_VALIDATIONS);
        }
      }
      case 'PUT': {
        if (url.match(/\w+$/)) {
          return getNoContent();
        } else {
          return getBadRequest('crossfieldvalidations');
        }
      }
      case 'POST': {
        return getNoContent();
      }
      default: {
        return getBadRequest('crossfieldvalidations');
      }
    }
  }

  // File Submission Tolerance APIs
  if (url.match(/\w+\/fileSubmissionTolerances/)) {
    switch (method) {
      case 'GET': {
        if (url.endsWith('/current')) {
          return getOK(MOCK_FILE_SUBMISSION_TOLERANCES);
        } else {
          return getOK(MOCK_FILE_SUBMISSION_TOLERANCES);
        }
      }
      case 'POST': {
        return getCreated();
      }
      default: {
        return getBadRequest('fileSubmissionTolerances');
      }
    }

  }

  // Test Expression

  if (url.match(/\w+\/crossfieldvalidations/)) {
    switch (method) {
      case 'POST': {
        if (url.endsWith('/test')) {
          return getOK(MOCK_TEST_EXPRESSION_BUILDER_RESPONSE_FALSE);
        }
      }
      default: {
        return getBadRequest('fileSubmissionTolerances');
      }
    }
  }

  // Schema Date APIs
  if (url.match(/\w+\/schemaDates/)) {
    switch (method) {
      case 'GET': {
        return getOK(MOCK_FILING_DATES);
      }
      case 'POST': {
        return getCreated();
      }
      default: {
        return getBadRequest('schemaDates');
      }
    }

  }

  // Validation Schema APIs
  if (url.match(/\w+\/validationschema/)) {
    switch (method) {
      case 'GET': {
        if (url.endsWith('/current')) {
          return getOK(MOCK_VALIDATION_SCHEMA);
        } else if (url.match(/\w+\/current\/properties/)) {
          return getOK(MOCK_SUBMISSION_KEYS);
        } else {
          return getOK(MOCK_VALIDATION_SCHEMAS);
        }
      }
      case 'PUT': {
        if (url.match(/\/\w+$/)) {
          return getNoContent();
        } else {
          return getBadRequest('validationschema');
        }
      }
      case 'POST': {
        return getNoContent();
      }
      default: {
        return getBadRequest('validationschema');
      }
    }
  }

  // Filing Blank Layout APIs
  if (url.match(/\w+\/filingblanklayout/)) {
    switch (method) {
      case 'GET': {
        return getOK(MOCK_FILING_BLANK_LAYOUT);
      }
      case 'POST': {
        return getNoContent();
      }
      default: {
        return getBadRequest('filingblanklayout');
      }
    }
  }

  // Get download file URL from S3
  if (url.match(/\w+\/download\/url/)) {
    switch (method) {
      case 'GET': {
        return getOK(MOCK_DOWNLOAD_URL_S3_RESPONSE);
      }
      default: {
        return getBadRequest('get download s3 url');
      }
    }
  }

  if (url.match(/\w+\/fileLevel/)) {
    switch (method) {
      case 'GET': {
        return getOK(MOCK_FILE_LEVEL_VALIDATION);
      }
      case 'POST': {
        return getOK(MOCK_FILE_LEVEL_VALIDATION);
      }
      default: {
        return getBadRequest('fileLevelValidation failed');
      }
    }
  }


  // Crossfield JS Validations APIs
  if (url.match(/\w+\/crossfieldvalidationjs/)) {
    switch (method) {
      case 'GET': {
        return getOK(createMockValidations(50));
      }
      case 'POST': {
        return getCreated();
      }
      default: {
        return getBadRequest('filingblanklayout');
      }
    }
  }


}

function getOK(data: any): Observable<any> {
  return new Observable(resp => {
    resp.next(new HttpResponse({
      status: 200,
      body: JSON.parse(JSON.stringify(data))
    }));
    resp.complete();
  });
}

function getNoContent(): Observable<any> {
  return new Observable(resp => {
    resp.next(new HttpResponse({
      status: 204
    }));
    resp.complete();
  });
}

function getCreated(): Observable<any> {
  return new Observable(resp => {
    resp.next(new HttpResponse({
      status: 201
    }));
    resp.complete();
  });
}

function getAccepted(): Observable<any> {
  return new Observable(resp => {
    resp.next(new HttpResponse({
      status: 204
    }));
    resp.complete();
  });
}

function getBadRequest(apiName: string): Observable<any> {
  return new Observable(resp => {
    resp.next(new HttpResponse({
      status: 400,
      body: {error: `MockBackend - Unknown ${apiName} url`}
    }));
    resp.complete();
  });
}
