import {DocumentWithError} from './document-with-error';

export class DocumentErrorList {
  documentsWithErrors: DocumentWithError[];
  totalErrors: number;

  constructor(documentsWithErrors: DocumentWithError[], totalErrors: number) {
    this.documentsWithErrors = documentsWithErrors;
    this.totalErrors = totalErrors;
  }
}
