import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Breadcrumb } from '../../model/breadcrumb';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { CrossFieldValidation } from '../model/cross-field-validation';
import { CrossFieldValidationMaintenanceService } from '../services/cross-field-validation-maintenance.service';
import { MessageService } from '../../messages/service/message.service';
import { AlertMessage } from '../../messages/model/alert-message';
import { UserService } from '../../service/user.service';
import { User } from '../../model/user';
import { ExpressionBuilderService } from '../expression-builder/services/expression-builder.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { StringToModelParserService } from '../expression-builder/shared/parserModelObj';
import {DatacallSettingService} from '../../roleconfig/datacall-setting/services/datacall-setting.service';
import { ValidationLevelsEnum } from '../model/validation-levels.enum';

@Component({
  selector: 'app-validation-edit',
  templateUrl: './cross-field-validation-edit.component.html'
})
export class CrossFieldValidationEditComponent implements OnInit, OnDestroy {
  @ViewChild('validationEditForm', { static: true }) ngForm: NgForm;
  title: string;
  messageText: string;
  breadcrumb: Breadcrumb;
  breadcrumbs: Breadcrumb[];
  schemaType: string;
  newCrossFieldValidation: boolean;
  crossFieldValidation: CrossFieldValidation;
  subscriptions: Array<Subscription> = [];
  user: User;
  crossFieldData: CrossFieldValidation[];
  nameAlreadyExist: boolean = false;
  expressionButtonText: string;
  expressionBuilderFlag: boolean;
  saveFlag: boolean = false;
  isFocus: boolean = false;
  modalRef: BsModalRef;
  crumb: any;
  checkNestedIf: boolean;
  isOnlineDataEntry: boolean;
  validationLevelsEnum = ValidationLevelsEnum;
  validationLevels: string[];

  constructor(
    private validationMaintenanceService: CrossFieldValidationMaintenanceService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public breadcrumbService: BreadcrumbService,
    public messageService: MessageService,
    private userService: UserService,
    private modalService: BsModalService,
    private expressionBuilderService: ExpressionBuilderService,
    private visitor: StringToModelParserService,
    private dataCallSettingsService: DatacallSettingService,
  ) {
    this.validationLevels = Object.keys(this.validationLevelsEnum);
  }

  ngOnInit() {
    this.messageService.clear();
    this.isOnlineDataEntry = this.dataCallSettingsService.getOnlineDataEntrySetting();
    this.expressionBuilderService.setExpressionBuilderModel(null);
    this.expressionBuilderService.setValuesFromExpressionBuilder(null, null, null);
    this.breadcrumbs = this.breadcrumbService.getBreadcrumbs();
    this.saveFlag = this.activatedRoute.snapshot.paramMap.has('saveFlag');
    this.checkForAddEditPage();
    if (this.newCrossFieldValidation) {
      this.crossFieldValidation = new CrossFieldValidation();
      this.breadcrumb = new Breadcrumb('Add Cross Field Validation', '/validationEdit');
    } else {
      this.crossFieldValidation = this.validationMaintenanceService.getSelectedCrossFieldValidation();
      if (this.activatedRoute.snapshot.paramMap.get('expression') !== null) {
        this.crossFieldValidation.expression = this.activatedRoute.snapshot.paramMap.get('expression');
      }
      this.breadcrumb = new Breadcrumb(this.crossFieldValidation.name, '/validationEdit');
    }
    this.title = `${this.schemaType.toUpperCase()} Cross Field Validation Editor`;
    this.user = this.userService.getStoredUser();
    this.isFocus = this.expressionBuilderService.getRoutingHelper();
    this.getFileSubmissionData();
    this.setExpressionButtonText();
    this.validationMaintenanceService.setSelectedCrossFieldValidation(this.crossFieldValidation);
    this.expressionBuilderService.validateExpressionFromEB = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.messageService.clear();
  }

  changeExpression() {
    this.validationMaintenanceService.setSelectedCrossFieldValidation(this.crossFieldValidation);
  }

  getFileSubmissionData(): void {
    const sub = this.validationMaintenanceService.getCrossFieldValidationsForSchema(this.user.currentSchema)
      .subscribe(
        crossFieldValidations => {
          this.crossFieldData = crossFieldValidations;
        }
      );
    this.subscriptions.push(sub);
  }

  isNameAlreadyInList(name, value) {
    let nameMatched: boolean = false;
    for (let index = 0; index < this.crossFieldData.length; index++) {
      if (this.crossFieldData[index].name.toLowerCase() === name || this.crossFieldData[index].name === name) {
        this.nameAlreadyExist = true;
        nameMatched = true;
      }
    }
    if (!nameMatched) {
      this.nameAlreadyExist = false;
    }

  }

  save(): void {
    if (this.crossFieldValidation.id) {
      const sub = this.validationMaintenanceService.updateCrossFieldValidation(this.crossFieldValidation, this.schemaType)
        .subscribe(
          () => {
            this.expressionBuilderService.setRoutingHelper(false);
            this.isFocus = false;
            this.messageText = 'The validation has been updated';
            this.messageService.add(new AlertMessage(this.messageText, 'success'));
          }
        );
      this.subscriptions.push(sub);
    } else {
      const sub = this.validationMaintenanceService.insertCrossFieldValidation(this.crossFieldValidation, this.schemaType)
        .subscribe(
          () => {
            this.expressionBuilderService.setRoutingHelper(false);
            this.isFocus = false;
            this.messageText = 'The validation has been updated';
            this.messageService.add(new AlertMessage(this.messageText, 'success'));
          }
        );
      this.subscriptions.push(sub);
    }
  }

  validateExpressionSave() {
    this.breadcrumbService.removeBreadcrumb(this.crumb);
    this.modalRef.hide();
    this.expressionBuilderService.setRoutingHelper(false);
    this.router.navigate(['/validationMaintenance']);
  }

  checkForAddEditPage() {
    if (this.activatedRoute.snapshot.paramMap.get('schemaType') === null) {
      this.schemaType = this.validationMaintenanceService.storedSchemaType;
    } else {
      this.schemaType = this.activatedRoute.snapshot.paramMap.get('schemaType');
      this.validationMaintenanceService.storedSchemaType = this.schemaType;
    }
    if (this.activatedRoute.snapshot.paramMap.get('newCrossFieldValidation') === null) {
      this.newCrossFieldValidation = this.validationMaintenanceService.storedNewCrossfieldValidation;
    } else {
      this.newCrossFieldValidation = this.activatedRoute.snapshot.paramMap.get('newCrossFieldValidation') === 'true';
      this.validationMaintenanceService.storedNewCrossfieldValidation = this.newCrossFieldValidation;
    }
  }

  setExpressionButtonText() {
    if (this.crossFieldValidation.expression === "" || this.crossFieldValidation.expression === undefined) {
      this.expressionButtonText = "Add Expression";
    } else {
      this.expressionButtonText = "Edit Expression";
    }
  }

  addEditExpression() {
    this.expressionBuilderService.validateExpressionFromCrossFieldEdit = true;
    this.checkNestedIf = (this.crossFieldValidation.expression === null || this.crossFieldValidation.expression === undefined || this.crossFieldValidation.expression === "") ? false : (this.crossFieldValidation.expression.replace(/\s/g, '').includes('if(', 5));
    if (this.checkNestedIf) {
      this.expressionBuilderService.validateExpressionFromCrossFieldEdit = false;
      this.messageText = "The expression string contains nested if's which is not currently supported by expression builder. ";
      this.messageService.add(new AlertMessage(this.messageText, 'danger'));
    } else if (this.visitor.validationStringToModel(this.crossFieldValidation.expression)) {
      this.expressionBuilderService.validateExpressionFromCrossFieldEdit = false;
      this.validationMaintenanceService.setSelectedCrossFieldValidation(this.crossFieldValidation);
      this.breadcrumbService.addBreadcrumb(this.breadcrumb);
      this.router.navigate(['/expressionBuilder', { schemaType: this.schemaType, expression: this.crossFieldValidation.expression, validationName: this.crossFieldValidation.name }]);
    } else {
      this.expressionBuilderService.validateExpressionFromCrossFieldEdit = false;
    }
  }

  routeToCrossFieldPage(crumb, template: TemplateRef<any>) {
    this.crumb = crumb;
    if (this.isFocus) {
      this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    } else {
      this.breadcrumbService.removeBreadcrumb(crumb);
      this.router.navigate([`${crumb.link}`]);
    }
  }

}
