import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MessagesModule} from '../messages/messages.module';
import {LoadingModule} from 'ngx-loading';
import {ImportExportRoutingModule} from './import-export-routing.module';
import {ImportExportComponent} from './import-export.component';
import { ImportDialogComponent } from './import-dialog/import-dialog.component';
import {AlertModule} from 'ngx-bootstrap/alert';
import {ModalModule} from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    CommonModule,
    MessagesModule,
    LoadingModule,
    ImportExportRoutingModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
  ],
  declarations: [ImportExportComponent, ImportDialogComponent],
  providers: [],
  entryComponents: [ImportDialogComponent],
  exports: [ImportExportComponent]
})
export class ImportExportModule { }
