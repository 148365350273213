import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject ,  Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Breadcrumb } from '../../model/breadcrumb';
import { User } from '../../model/user';
import { UnCamelCasePipe } from '../../pipes/un-camel-case.pipe';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { UserService } from '../../service/user.service';
import { Aggregate } from '../model/aggregate';
import { AggregationMaintenanceService } from '../services/aggregation-maintenance.service';
import { MessageService } from '../../messages/service/message.service';
import { AlertMessage } from '../../messages/model/alert-message';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-aggregate',
  templateUrl: './aggregate.component.html'
})
export class AggregateComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  title: string;
  messageText: string;
  error = false;
  breadcrumb: Breadcrumb;
  breadcrumbs: Breadcrumb[];
  aggregate: Aggregate;
  aggregateData: any;
  user: User;
  results: any[] = [];
  loading = false;
  subscriptions: Array<Subscription> = [];
  options: any;
  data: any[] = [];
  fileName = '';

  skip = 0;
  limit = 0;
  page = 1;

  faPlay = faPlay;

  columnDefs = [];
  rowData = [];
  defaultColDef: any;
  context: any;
  frameworkComponents: any;
  paginationPageSize = 10;
  csvFileName = '';
  gridApi: any;

  constructor(
    public aggregateService: AggregationMaintenanceService,
    private userService: UserService,
    private unCamelCase: UnCamelCasePipe,
    public breadcrumbService: BreadcrumbService,
    public messageService: MessageService,
  ) {
    this.skip = 0;
    this.limit = 250;
  }

  ngOnInit() {
    this.context = { componentParent: this };
    this.frameworkComponents = {};
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this.aggregate = this.aggregateService.getSelectedAggregate();
    this.aggregateData = this.aggregateService.createAggregateData(this.aggregate);
    this.user = this.userService.getStoredUser();
    this.breadcrumbs = this.breadcrumbService.getBreadcrumbs();
    this.breadcrumb = new Breadcrumb(this.aggregate.displayName, '/aggregate');
    this.title = this.aggregate.displayName;
    this.setCsvFileName();
    if (this.aggregateData.params.length === 0) {
      this.getData();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.messageService.clear();
  }

  getData(): void {
    this.loading = true;
    this.rowData = [];
    this.aggregateService.runAggregate(this.user.currentSchema, this.aggregateData, this.skip, this.limit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        response => {
          this.loading = false;
          this.error = false;
          this.rowData = response;
          if (this.rowData.length > 0) {
            this.columnDefs = this.getColumnsDef(this.rowData[0]);
          } else {
            this.messageText = 'No Results Found';
            this.messageService.add(new AlertMessage(this.messageText, 'info'));
          }
        },
        error => {
          this.loading = false;
          this.error = true;
          this.messageText = <any>error;
          this.messageService.add(new AlertMessage(this.messageText, 'danger'));
        }
      );
  }

  getColumnsDef(row: any): any[] {
    const columnsDef: any[] = [];
    const keys = Object.keys(row);
    keys.forEach(key => {
      const headerName = this.unCamelCase.transform(key);
      columnsDef.push({ headerName: headerName, field: key });
    });
    return columnsDef;
  }

  onGridReady(params) {
    this.gridApi = params.api;

    this.sizeColumnsToFit();
  }

  sizeColumnsToFit(): void {
    if (this.columnDefs.length <= 8) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  previousPage(): void {
    this.skip = this.skip - this.limit;
    this.page--;
    this.setCsvFileName();
    this.getData();
  }

  nextPage(): void {
    this.skip = this.skip + this.limit;
    this.page++;
    this.setCsvFileName();
    this.getData();
  }

  getButtonLabel() {
    if (this.results.length === 0) {
      return 'Run';
    } else {
      return 'Reload';
    }
  }

  displayButton(): boolean {
    if (this.aggregateData.params.length === 0) {
      return this.results.length > 0;
    }
    return true;
  }

  csvExport(): void {
    const params = {
      fileName: this.csvFileName
    };

    this.gridApi.exportDataAsCsv(params);
  }

  setCsvFileName(): void {
    this.csvFileName = `${this.aggregate.name}_${this.page}`;
  }
}
