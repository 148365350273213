import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayToString'
})
export class ArrayToStringPipe implements PipeTransform {

  transform(value: any[], args?: any): any {
    if (!value || !Array.isArray(value)) {
      return '';
    }
    return value.join(' ');
  }

}
