import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTableModule } from "@angular/material";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NcuiModule } from "common-ui-components";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { LoadingModule } from "ngx-loading";
import { MessagesModule } from "../messages/messages.module";
import { QuestionControlService } from "../online-data-entry/dynamic-forms/question-control-service";
import { DatacallSummaryRoutingModule } from "./datacall-summary-routing.module";
import { DatacallSummaryComponent } from "./datacall-summary.component";



@NgModule({
  declarations: [DatacallSummaryComponent],
  imports: [
    CommonModule,
    DatacallSummaryRoutingModule,
    NcuiModule,
    FontAwesomeModule,
    CollapseModule.forRoot(),
    LoadingModule,
    FormsModule,
    ReactiveFormsModule,
    MessagesModule,
    MatTableModule
  ],
  providers: [QuestionControlService]
})
export class DatacallSummaryModule { }
