import { CrossFieldValidation } from "../../model/cross-field-validation";

export class TestExpressionBuilderValues {
    crossFieldValidation: CrossFieldValidation;
    testData: any; 

    constructor(crossFieldValidation: CrossFieldValidation, testData: any) {
        this.crossFieldValidation = crossFieldValidation;
        this.testData = testData;
    }
}

export class TestExpressionBuilderResponse {
    validation: CrossFieldValidation;
    passed: boolean; 
    exception: string; 

    constructor(validation: CrossFieldValidation, passed: boolean, exception: string) {
        this.validation = validation;
        this.passed = passed;
        this.exception = exception;
    }
}