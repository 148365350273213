import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CredibleComponent } from './credible.component';
import { FormsModule } from '@angular/forms';
import { CredibleService } from './service/credible.service';
import {MessagesModule} from '../../messages/messages.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MessagesModule,
    ModalModule.forRoot(),
  ],
  declarations: [CredibleComponent],
  providers: [CredibleService],
  exports: [CredibleComponent]
})
export class CredibleModule {
}
