import { Component } from '@angular/core';
import { ICellRenderer } from 'ag-grid-community';
import { User } from '../../../model/user';
import { UserService } from '../../../service/user.service';

@Component({
  selector: 'app-primary-user-assignment',
  templateUrl: './primary-user-assignment.component.html'
})

export class PrimaryUserAssignmentComponent implements ICellRenderer {
  params: any;
  deleteKey: string;
  disable: boolean;
  user: User

  constructor(
    private userService: UserService
   ) { }

  agInit(params: any): void {
    this.params = params;
    this.user = this.userService.getStoredUser();
  }

  refresh(): boolean {
    return true;
  }

  changeToPrimaryUser(value) {
    this.params.context.componentParent.changeToPrimaryUser(value);
  }

  disableRadioButtonForPrimary(): boolean {
    // Cannot assign self as Primary
    if(this.params.value.secondary === true && this.params.value.userId === this.user.userId) {
      return false; 
    } else {
      return true;
    }
  }
}
