import { Component } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ICellRenderer } from 'ag-grid-community';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-remove-user-assignment',
  templateUrl: './remove-user-assignment.component.html'
})
export class RemoveUserAssignmentComponent implements ICellRenderer {
  params: any;
  faTimes = faTimes;
  rowToBeDeleted: any;
  modalRef: BsModalRef;

  constructor(
  ) { }

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return true;
  }

  disableRemoveButton(): boolean {
    if (this.params.value.userRole === 'datacallAdmin') {
      if(this.params.value.secondary === true) {
        return true;
      } else {
        return false;
      }
    } else if (this.params.value.userRole === 'primaryUser') {
      if(this.params.value.companyAdmin === true) {
        return true;
      } else {
        return false;
      }
    } else {
      if(this.params.value.companyAdmin === true || this.params.value.secondary === true) {
        return true;
      } else {
        return false;
      }
    }
  }

  removeuserAssignment(key: any): void {
    this.rowToBeDeleted = key.value;
    this.params.context.componentParent.confirmRemove(this.rowToBeDeleted);
  }

}
