import { Component, OnInit, ViewChild } from '@angular/core';
import { Breadcrumb } from '../../model/breadcrumb';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { MatAccordion } from '@angular/material/expansion';
import { ExpressionBuilderService } from './services/expression-builder.service';
import { FormBuilder, FormGroup, FormArray, FormControl, FormControlDirective, FormControlName } from '@angular/forms';
import { MessageService } from '../../messages/service/message.service';
import { AlertMessage } from '../../messages/model/alert-message';
import { StringToModelParserService } from './shared/parserModelObj';
import { UserService } from '../../service/user.service';
import { FileValidationMaintenanceService } from '../../file-validation-maintenance/services/file-validation-maintenance.service';
import { ValidationSchema } from '../../file-validation-maintenance/model/validation-schema';
import * as _ from 'lodash';

const originFormControlNgOnChanges = FormControlDirective.prototype.ngOnChanges;
FormControlDirective.prototype.ngOnChanges = function () {
  this.form.nativeElement = this.valueAccessor._elementRef.nativeElement;
  return originFormControlNgOnChanges.apply(this, arguments);
};

const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
FormControlName.prototype.ngOnChanges = function () {
  const result = originFormControlNameNgOnChanges.apply(this, arguments);
  this.control.nativeElement = this.valueAccessor._elementRef.nativeElement;
  return result;
};

@Component({
  selector: 'app-expression-builder',
  templateUrl: './expression-builder.component.html',
  styleUrls: ['./expression-builder.component.css'],
})

export class ExpressionBuilderComponent implements OnInit {
  @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
  expressionBuilderForm: FormGroup;

  title: string;
  breadcrumbs: Breadcrumb[];
  breadcrumb: Breadcrumb;
  schemaType: string;
  expression: string;
  validationName: string;
  testExpressions: any = [{ name: 'Test 1', expression: 'something == anotherThing' }];
  operators: any[];
  enteredExpression: any = '';
  passFailOptions: any[];
  data: any;
  andOrConditions: any[];
  model: any;
  trueActionValue: any;
  falseActionValue: any;
  disableIfRdcAdmin = false;
  loading: boolean;
  validationSchema: ValidationSchema;
  fieldNames: any[];
  fieldNamesInExpression: any[] = [];
  customFieldNamesInExpression: any[] = [];
  difference: any[];

  constructor(
    private activatedRoute: ActivatedRoute,
    public breadcrumbService: BreadcrumbService,
    public expressionBuilderService: ExpressionBuilderService,
    private formBuilder: FormBuilder,
    private router: Router,
    private messageService: MessageService,
    private visitor: StringToModelParserService,
    private userService: UserService,
    private schemaMaintenanceService: FileValidationMaintenanceService
  ) { }

  ngOnInit() {
    let values = this.expressionBuilderService.getValuesFromExpressionBuilder();
    if (!values.schemaType && !values.expression && !values.validationName) {
      this.schemaType = this.activatedRoute.snapshot.paramMap.get('schemaType');
      this.expression = this.activatedRoute.snapshot.paramMap.get('expression');
      this.validationName = this.activatedRoute.snapshot.paramMap.get('validationName');
      this.breadcrumb = new Breadcrumb('Expression Builder', '/expressionBuilder');
      this.title = `${this.schemaType.toUpperCase()} Expression Builder`;
    } else {
      this.schemaType = values.schemaType;
      this.expression = values.expression;
      this.validationName = values.validationName;
      this.breadcrumb = new Breadcrumb('Expression Builder', '/expressionBuilder');
      this.title = `${this.schemaType.toUpperCase()} Expression Builder`;
    }
    this.breadcrumbs = this.breadcrumbService.getBreadcrumbs();
    this.expressionBuilderService.setValuesFromExpressionBuilder(this.schemaType, this.expression, this.validationName);
    this.getPassFailOptions();
    this.initializeExpressionBuilderForm();
    this.getCurrentValidationSchemaByType();
    this.expressionBuilderService.validateExpressionFromCrossFieldEdit = false;
    this.expressionBuilderService.setRoutingHelper(false);
  }

  getCurrentValidationSchemaByType(): void {
    this.loading = true;
    this.schemaMaintenanceService.getCurrentValidationSchemaByType(this.schemaType)
      .subscribe(
        validationSchema => {
          this.validationSchema = validationSchema;
          this.fieldNames = this.getFieldNames();
          this.expressionBuilderService.setFieldName(this.fieldNames);
          this.loading = false;
        }
      );
  }

  getFieldNames(): any[] {
    const fields: Array<any> = [];
    const keys: Array<string> = this.getPropertiesKeys();
    keys.forEach(key => {
      const data = {};
      data['fieldNames'] = key;
      fields.push(data);
    });
    return fields;
  }

  getPropertiesKeys(): string[] {
    return _.keys(this.validationSchema.properties);
  }

  initializeExpressionBuilderForm() {
    if (!this.expressionBuilderService.getExpressionBuilderModel()) {
      this.model = this.visitor.validationStringToModel(this.expression);
    } else {
      this.model = this.expressionBuilderService.getExpressionBuilderModel();
    }
    this.expressionBuilderForm = this.formBuilder.group({
      expressionBuilder: this.setControls(this.model),
    });
    this.expressionBuilderForm.get('expressionBuilder').valueChanges.subscribe(x => {
      if ((x[0].expressions.findIndex((q) => Object.keys(q).length === 0)) !== -1) {
        (((this.expressionBuilderForm.get('expressionBuilder') as FormArray).at(0) as FormGroup).get('expressions') as FormArray).removeAt((x[0].expressions.findIndex((y) => (Object.keys(y).length === 0))));
      }
    });
  }

  initTest() {
    return this.formBuilder.group({
      name: new FormControl(''),
      andOrCondition: new FormControl('||'),
      expressions: this.formBuilder.array([
        this.initExpression()
      ]),
      trueAction: this.formBuilder.group({
        type: new FormControl(''),
        value: new FormControl('')
      }),
      falseAction: this.formBuilder.group({
        type: new FormControl(''),
        value: new FormControl('')
      })
    });
  }

  initExpression() {
    return this.formBuilder.group({
      expression: new FormControl('')
    });
  }

  get getExpressionBuilder() {
    return this.expressionBuilderForm.get('expressionBuilder') as FormArray;
  }

  addTest(): void {
    const control = <FormArray>this.expressionBuilderForm.controls['expressionBuilder'];
    control.push(this.initTest());
  }

  getPassFailOptions() {
    this.disableIfRdcAdmin = this.userService.getStoredUser().isRdcAdmin;
    this.passFailOptions = [
      { label: '', value: 'Select Result Condition', type: '' },
      { label: 'true', value: 'Pass Validation', type: 'VALIDATION_RESULT' },
      { label: 'false', value: 'Fail Validation', type: 'VALIDATION_RESULT' }
    ];
  }

  setControls(model) {
    let control = new FormArray([]);
    model.expressionBuilder.forEach(x => {
      (x.expressions.length === 1) ? (x.andOrCondition = '||') : (x.andOrCondition = x.andOrCondition);
      control.push(this.formBuilder.group({
        name: x.name,
        andOrCondition: x.andOrCondition,
        expressions: this.setProjects(x),
        trueAction: this.formBuilder.group({
          type: x.trueAction.type,
          value: x.trueAction.value
        }),
        falseAction: this.formBuilder.group({
          type: x.falseAction.type,
          value: x.falseAction.value
        }),
      }));
    });

    this.trueActionValue = control.at(0).get('trueAction').value;
    this.falseActionValue = control.at(0).get('falseAction').value;
    return control;
  }

  setProjects(x) {
    let arr = new FormArray([]);
    x.expressions.forEach(y => {
      if (y.andOrCondition) {
        arr.push(this.formBuilder.group({
          andOrCondition: y.andOrCondition,
          expressions: this.setProjects(y),
        }));

      } else {
        arr.push(this.formBuilder.group({
          expression: y.expression
        }));
      }
    });
    return arr;
  }

  setInnerProjects(y) {
    let arr = new FormArray([]);
    y.expressions.forEach(z => {
      arr.push(this.formBuilder.group({
        expression: z.expression
      }));
    });
    return arr;
  }

  setResultCondition(i: number, event, action) {
    let selectedValue = event.target.value;
    let valueForOtherOption = (String(selectedValue) === 'true') ? 'false' : 'true';
    switch (action) {
      case 'trueAction':
        ((this.expressionBuilderForm.get('expressionBuilder') as FormArray).at(i).get('trueAction'))
          .patchValue({
            type: `${this.passFailOptions.find(o => o.label === selectedValue).type}`,
            value: `${selectedValue}`
          });
        ((this.expressionBuilderForm.get('expressionBuilder') as FormArray).at(i).get('falseAction'))
          .setValue({
            type: `${this.passFailOptions.find(o => o.label === valueForOtherOption).type}`,
            value: `${valueForOtherOption}`
          });
        break;
      case 'falseAction':
        ((this.expressionBuilderForm.get('expressionBuilder') as FormArray).at(i).get('falseAction'))
          .patchValue({
            type: `${this.passFailOptions.find(o => o.label === selectedValue).type}`,
            value: `${selectedValue}`
          });
        ((this.expressionBuilderForm.get('expressionBuilder') as FormArray).at(i).get('trueAction'))
          .setValue({
            type: `${this.passFailOptions.find(o => o.label === valueForOtherOption).type}`,
            value: `${valueForOtherOption}`
          });
    }
    this.trueActionValue = (this.expressionBuilderForm.get('expressionBuilder') as FormArray).at(i).get('trueAction').value;
    this.falseActionValue = (this.expressionBuilderForm.get('expressionBuilder') as FormArray).at(i).get('falseAction').value;
  }

  setExpression() {
    if (this.validateThenElseSections()) {
      this.expressionBuilderService.validateExpressionFromEB = true;
      this.expressionBuilderService.colorIndex = 1;
      let builtExpression = this.expressionBuilderService.generateExpressionFromJson(this.expressionBuilderForm.value.expressionBuilder);
      this.visitor.validationStringToModel(builtExpression);
      const lastPage: Breadcrumb = this.breadcrumbs[this.breadcrumbs.length - 1];
      this.breadcrumbService.removeBreadcrumb(lastPage);
      this.router.navigate([lastPage.link, { expression: builtExpression, newCrossFieldValidation: false, saveFlag: true }]);
      this.expressionBuilderService.setRoutingHelper(true);
    }
  }

  validateThenElseSections(): boolean {
    this.messageService.clear();
    if ((!this.trueActionValue || !this.trueActionValue.value) && (!this.falseActionValue || !this.falseActionValue.value)) {
      this.messageService.add(new AlertMessage('Then and Else sections are required', 'danger'));
    } else if ((!this.trueActionValue || !this.trueActionValue.value)) {
      this.messageService.add(new AlertMessage('Then section is required', 'danger'));
    } else if ((!this.falseActionValue || !this.falseActionValue.value)) {
      this.messageService.add(new AlertMessage('Else section is required', 'danger'));
    } else {
      return true;
    }
  }

  testExpression(index) {
    let builtExpression = this.expressionBuilderService.generateExpressionFromJson(this.expressionBuilderForm.value.expressionBuilder);
    this.checkIncorrectFieldNames(builtExpression);
    this.validateExpression(builtExpression, index);
  }

  validateExpression(builtExpression, index) {
    if (this.validateThenElseSections()) {
      this.expressionBuilderService.validateExpressionFromEB = true;
      document.documentElement.scrollTop = 0;
      this.visitor.validationStringToModel(builtExpression);
      if (this.difference.length !== 0) {
        this.messageService.clear();
        document.documentElement.scrollTop = 0;
        this.messageService.add(new AlertMessage(`Cannot Test Expression. FieldName ${this.difference.toString()} is invalid.`, 'danger'));
      } else {
        this.messageService.clear();
        this.expressionBuilderService.setExpressionBuilderModel(this.expressionBuilderForm.value);
        this.breadcrumbService.addBreadcrumb(this.breadcrumb);
        this.router.navigate(['/testExpressionBuilder', {
          schemaType: this.schemaType,
          expressionIndex: index,
          validationName: this.validationName,
          builtExpression: builtExpression
        }]);
      }
    }
  }

  checkIncorrectFieldNames(builtExpression) {
    this.fieldNamesInExpression = [];
    this.fieldNames.forEach(x => {
      if (builtExpression.includes(x.fieldNames)) {
        this.fieldNamesInExpression.push(x.fieldNames);
      }
    });
    let string = builtExpression;
    let text1 = string.replace(/\s/g, "").split("data['");
    this.customFieldNamesInExpression = [];
    for (let i = 1; i < text1.length; i++) {
      this.customFieldNamesInExpression.push(text1[i].split("']")[0]);
    }
    let text2 = string.replace(/\s/g, "").split("data,'");
    for (let i = 1; i < text2.length; i++) {
      this.customFieldNamesInExpression.push(text2[i].split("')")[0]);
    }
    this.difference = [];
    this.difference = this.customFieldNamesInExpression.filter(a => !this.fieldNamesInExpression.some(b => a === b));
  }

  cancel() {
    this.messageService.clear();
    this.expressionBuilderService.colorIndex = 1;
    const lastPage: Breadcrumb = this.breadcrumbs[this.breadcrumbs.length - 1];
    this.breadcrumbService.removeBreadcrumb(lastPage);
    this.router.navigate([lastPage.link, { newCrossFieldValidation: false, saveFlag: true }]);
  }
}
