/*
 * Copyright 2018 National Association of Insurance Commissioners
 */

import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FilingValidationComponent} from './filing-validation.component';
import {ValidationEditComponent} from './validation-edit/validation-edit.component';


const filingValidationRoutes: Routes = [
  { path: 'filingValidation', component: FilingValidationComponent },
  { path: 'jsValidationEdit', component: ValidationEditComponent }
];

@NgModule({
  imports: [
    RouterModule.forChild(filingValidationRoutes)
  ],
  exports: [RouterModule]
})
export class FilingValidationRoutingModule { }
