import { Injectable } from '@angular/core';
import {HandleError, HttpErrorHandler} from '../../shared/http-error-handler.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SnowflakeService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('ExportService');
  }

  postSchemaForSnowflake(schemaType: string): Observable<string> {
    let url = `${environment.apiUrl}${schemaType}/snowflake`;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = { headers: headers };
    return this.httpClient.post<any>(url, '', options)
      .pipe(catchError(this.handleError('postSchemaForSnowflake', null)));
  }
}
