import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DatacallSettings } from '../model/datacall-setting';
import { HandleError, HttpErrorHandler } from '../../../shared/http-error-handler.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatacallSettingService {
  readonly handleError: HandleError;

  datacallSetting: any[] = [];
  datacall: any[];

  initialDatacall: DatacallSettings[];
  showUserAssignmentsLink: boolean;
  submissionSettingsForm: any[];
  onlineDataEntrySetting: boolean;
  copyToReportingDBSetting: boolean;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('DatacallSettingService');
  }

  getDatacallSettings(schema: string): Observable<any> {
    const url = `${environment.apiUrl}${schema}/datacallsettings `;
    const headers = new HttpHeaders({ 'Accept': 'application/json' });
    const options = { headers: headers };
    return this.httpClient.get(url, options)
      .pipe(catchError(this.handleError('getDatacallSettings', null)));
  }

  getDatacallSettingName(schema: string, settingName: string): Observable<any> {
    const url = `${environment.apiUrl}${schema}/datacallsettings/${settingName} `;
    const headers = new HttpHeaders({ 'Accept': 'application/json' });
    const options = { headers: headers };
    return this.httpClient.get(url, options)
      .pipe(catchError(this.handleError('getDatacallSettingName', null)));
  }

  putDatacallSettings(schema: string, datacallSetting: any): Observable<DatacallSettings> {
    const url = `${environment.apiUrl}${schema}/datacallsettings`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.httpClient.put(url, datacallSetting, options)
      .pipe(catchError(this.handleError('putDatacallSettings', null)));
  }

  createDatacallSetting(schema: string, datacall: DatacallSettings[]): Observable<DatacallSettings> {
    return this.putDatacallSettings(schema, datacall);
  }

  setDatacallSetting(datacall: DatacallSettings[]) {
    this.datacall = datacall;
  }

  getDatacallSetting(): DatacallSettings[] {
    return this.datacall;
  }

  setInitialDatacallSetting(datacall: DatacallSettings[]) {
    this.initialDatacall = datacall;
  }

  getInitialDatacallSetting(): DatacallSettings[] {
    return this.initialDatacall;
  }

  setSubmissionSettingForm(submissionSettingsForm: any[]) {
    this.submissionSettingsForm = submissionSettingsForm
  }

  getSubmissionSettingsForm(): any[] {
    return this.submissionSettingsForm;
  }

  setOnlineDataEntrySetting(settingValue: boolean) {
    this.onlineDataEntrySetting = settingValue;
  }

  getOnlineDataEntrySetting(): boolean {
    return this.onlineDataEntrySetting;
  }

  set iscopyToReportingDBSetting(settingValue: boolean) {
    this.copyToReportingDBSetting = settingValue;
  }

  get iscopyToReportingDBSetting(): boolean {
    return this.copyToReportingDBSetting;
  }
}
