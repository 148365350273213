import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SubmissionKey } from '../model/submission-key';
import { SubmissionKeys } from '../model/submission-keys';
import { SubmissionKeysService } from '../file-dashboard/service/submission-keys.service';
import { Breadcrumb } from '../model/breadcrumb';
import { FileSubmission } from '../model/file-submission';
import { User } from '../model/user';
import { UnCamelCasePipe } from '../pipes/un-camel-case.pipe';
import { BreadcrumbService } from '../service/breadcrumb.service';
import { UserService } from '../service/user.service';
import { RerunValidationsService } from './services/rerun-validations.service';
import * as _ from 'lodash';
import {MessageService} from '../messages/service/message.service';
import {AlertMessage} from '../messages/model/alert-message';
import { DateRendererComponent } from '../cell-renderer/date-renderer/date-renderer.component';
import { RerunValidationsRendererComponent } from './cell-renderer/rerun-validations-renderer/rerun-validations-renderer.component';
import { RunDiscrepancyRendererComponent } from './cell-renderer/run-discrepancy-renderer/run-discrepancy-renderer.component';

@Component({
  selector: 'app-rerun-validations',
  templateUrl: './rerun-validations.component.html'
})
export class RerunValidationsComponent implements OnInit, OnDestroy {
  title: string;
  messageText: string;
  error = false;
  breadcrumb = new Breadcrumb('Rerun Validations / Run Discrepancy', '/rerunValidations');
  user: User;
  submissionKeys: SubmissionKeys;
  submissionKeyList: SubmissionKey[];
  fileList: FileSubmission[];
  loading = false;
  scrollBarHorizontal = (window.innerWidth < 1200);
  subscriptions: Array<Subscription> = [];

  columnDefs = [];
  rowData = [];
  defaultColDef: any;
  context: any;
  frameworkComponents: any;
  gridApi: any;

  constructor(
    private userService: UserService,
    private router: Router,
    private rerunValidationsService: RerunValidationsService,
    private submissionKeysService: SubmissionKeysService,
    public breadcrumbService: BreadcrumbService,
    private unCamelCase: UnCamelCasePipe,
    private messageService: MessageService,
  ) {
    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 12000);
    };
  }

  ngOnInit() {
    this.context = { componentParent: this };
    this.frameworkComponents = {
      dateRenderer: DateRendererComponent,
      rerunValidationsRenderer: RerunValidationsRendererComponent,
      runDiscrepancyRenderer: RunDiscrepancyRendererComponent
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this.breadcrumbService.clearBreadcrumbs();
    this.user = this.userService.getStoredUser();
    this.title = `${this.user.currentSchema.toUpperCase()} Rerun Validations / Run Discrepancy`;
    if (this.rerunValidationsService.getStoredSubmissionKeys()) {
      this.submissionKeys = this.rerunValidationsService.getStoredSubmissionKeys();
      this.submissionKeyList = _.sortBy(this.submissionKeys.keys.slice(), ['name']).slice();
      this.getFileList();
    } else {
      this.getSubmissionKeysForUser();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.messageService.clear();
  }

  getSubmissionKeysForUser() {
    this.loading = true;
    const sub = this.submissionKeysService.getKeys(this.user)
      .subscribe(
        fileSubmissionKeys => {
          this.loading = false;
          this.error = false;
          this.submissionKeys = fileSubmissionKeys;
          this.submissionKeyList = _.sortBy(this.submissionKeys.keys.slice(), ['name']).slice();
        }
      );
    this.subscriptions.push(sub);
  }

  getFileList(): void {
    this.loading = true;
    this.rerunValidationsService.setStoredSubmissionKeys(this.submissionKeys);
    const sub = this.rerunValidationsService.getFileList(this.user.currentSchema, this.submissionKeys)
      .subscribe(
        fileList => {
          this.loading = false;
          this.error = false;
          this.fileList = fileList;

          if (this.fileList.length === 0) {
            this.messageText = 'No results found.';
            this.messageService.add(new AlertMessage(this.messageText, 'success'));
          } else {
            this.rowData = this.rerunValidationsService.getTableData(this.fileList);
            this.columnDefs = this.getColumnDef(this.rowData[0]);
          }
        }
      );
    this.subscriptions.push(sub);
  }

  getColumnDef(row: any): any[] {
    const columnsDef: any[] = [];
    if (row) {
      const keys = Object.keys(row);
      keys.forEach(key => {
        const headerName = this.unCamelCase.transform(key);
        if (key === 'action1') {
          columnsDef.push({headerName: '', field: key, cellRenderer: 'rerunValidationsRenderer', autoHeight: true});
        } else if (key === 'action2') {
          columnsDef.push({headerName: '', field: key, cellRenderer: 'runDiscrepancyRenderer', autoHeight: true});
        } else if (key === 'date') {
          columnsDef.push({headerName: headerName, field: key, cellRenderer: 'dateRenderer'});
        } else {
          columnsDef.push({headerName: headerName, field: key});
        }
      });
    };
    return columnsDef;
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;

    this.sizeColumnsToFit();
  }

  sizeColumnsToFit(): void {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  runValidations(fileId: string) {
    const sub = this.rerunValidationsService.rerunValidations(this.user.currentSchema, fileId)
      .subscribe(
        () => {
          this.messageText = 'Rerunning validations may take a few minutes. You will receive an email when complete.';
          this.messageService.add(new AlertMessage(this.messageText, 'success'));
          this.getFileList();
        }
      );
    this.subscriptions.push(sub);
  }

  runDiscrepancy(fileId: string) {
    const sub = this.rerunValidationsService.runDiscrepancy(this.user.currentSchema, fileId)
      .subscribe(
        () => {
          this.messageText = 'Running discrepancies may take a few minutes. You will receive an email when complete.';
          this.messageService.add(new AlertMessage(this.messageText, 'success'));
          this.getFileList();
        }
      );
    this.subscriptions.push(sub);
  }

  isStaticColumn(header: string): boolean {
    return RerunValidationsService.isStaticColumn(header);
  }

  getRowClass(row) {
    return {
      'rerun-warning': row.status === 'FILE_PENDING_ADMIN_REVIEW',
    };
  }

  getColumnName(name: string): string {
    return name === 'Action1' || name === 'Action2' ? ' ' : name;
  }

}
