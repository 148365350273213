import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilingSchemaComponent } from './filing-schema.component';
import {FilingSchemaRoutingModule} from './filing-schema-routing.module';
import {LoadingModule} from 'ngx-loading';
import {FilingSchemaService} from './service/filing-schema.service';
import {NgJsonEditorModule} from 'ang-jsoneditor';
import {MessagesModule} from '../messages/messages.module';

@NgModule({
  imports: [
    CommonModule,
    NgJsonEditorModule,
    MessagesModule,
    LoadingModule,
    FilingSchemaRoutingModule
  ],
  declarations: [FilingSchemaComponent],
  providers: [FilingSchemaService],
  exports: [FilingSchemaComponent]
})
export class FilingSchemaModule { }
