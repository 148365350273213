<ngx-loading [show]="loading"></ngx-loading>
<div class="wrapper">
    <div id="error-type">
        <div class="row page-title">
          <div class="col-12">
            <h1>{{title}}</h1>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <app-messages></app-messages>
          </div>
        </div>

        <!-- TODO: Switch to breadcrumb service -->
        <div *ngIf="backToErrors" class="row mb-3">
          <div class="col-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a [routerLink]="['/fileAdmin']">File Administration</a>
                </li>
                <li class="breadcrumb-item">
                  <a [routerLink]="['/fileAdminErrors', backToErrors]">{{originalFileName}}</a>
                </li>
                <li class="breadcrumb-item active">
                  {{errorType}}
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div>

          <div *ngIf="documentErrorList" class="row mb-3">
            <div class="col-12">
              <div *ngFor="let errorList of documentErrorList.documentsWithErrors" class="card mb-2">
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table">
                      <tr>
                        <th *ngFor="let key of getDocumentKeys(errorList.document)">{{key}}</th>
                      </tr>
                      <tr>
                        <td *ngFor="let value of getDocumentValues(errorList.document)">{{value}}</td>
                      </tr>
                    </table>
                  </div>
                  <ul class="list-group">
                    <li *ngFor="let error of errorList.errors" class="list-group-item">
                      {{error.errorMessage}}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="documentErrorList" class="row mb-3">
            <div class="col-12">
              <pagination
                [boundaryLinks]="documentErrorList.totalErrors > limit"
                [directionLinks]="documentErrorList.totalErrors > limit"
                [totalItems]="documentErrorList.totalErrors"
                [itemsPerPage]="limit"
                [(ngModel)]="currentPage"
                [maxSize]="maxSizeOfPageLinksInPager"
                (pageChanged)="page($event)">
              </pagination>
            </div>
          </div>
        </div>
      </div>
</div>

