<div id="constantValueHeader" class="modal-header">
    <h4 id="constantValueTitle" class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form name="constantValueForm" (ngSubmit)="ok()" #constantValueForm="ngForm">
    <div id="constantValueBody" class="modal-body" style="height: 250px;">
        <div class="form-group">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="name">Constant Value </span>
                </div>
                <input type="text" class="form-control" title="Constant Value" id="constantValueInput" [pattern]="regex" [(ngModel)]="enteredConstantValue"
                    [disabled]="!alphaNumericSelection && !numericSelection" name="constantValue" (keyup)="onChangeEnteredValue(enteredConstantValue)"/>
            </div>
            <div class="radio">
                <div>
                <label><input id="numeric" type="radio" title="Numeric" name="options" (change)="onChangeNumeric()"> Numeric</label>
            </div>
            <div>
                <label><input id="alphanumeric" type="radio" title="Alphanumeric" name="options" (change)="onChangeAlphanumeric()"> Alphanumeric</label>
            </div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button id="constantValueCancel" type="button" class="btn btn-primary float-right" (click)="bsModalRef.hide()"> Cancel </button>
        <button id="constantValueSubmit" type="submit" class="btn btn-primary" [disabled]="!constantValueForm.form.valid || !constantValueForm.form.dirty || disableOkButton"> Ok</button>
    </div>
</form>