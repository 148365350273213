import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoadingModule } from 'ngx-loading';
import { CrossFieldValidationAuditComponent } from './cross-field-validation-audit.component';
import {MessagesModule} from '../../messages/messages.module';
import { AgGridModule } from 'ag-grid-angular';
import { DateRendererComponent } from '../../cell-renderer/date-renderer/date-renderer.component';

@NgModule({
  imports: [
    CommonModule,
    AgGridModule.withComponents([DateRendererComponent]),
    FormsModule,
    RouterModule,
    MessagesModule,
    LoadingModule,
  ],
  declarations: [CrossFieldValidationAuditComponent],
  exports: [CrossFieldValidationAuditComponent]
})
export class CrossFieldValidationAuditModule { }
