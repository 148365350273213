import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject ,  Subscription } from 'rxjs';
import { Breadcrumb } from '../../model/breadcrumb';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { CrossFieldValidation } from '../model/cross-field-validation';
import { CrossFieldValidationMaintenanceService } from '../services/cross-field-validation-maintenance.service';
import {MessageService} from '../../messages/service/message.service';
import { UnCamelCasePipe } from '../../pipes/un-camel-case.pipe';
import { DateRendererComponent } from '../../cell-renderer/date-renderer/date-renderer.component';
import { DatacallSettingService } from '../../roleconfig/datacall-setting/services/datacall-setting.service';

@Component({
  selector: 'app-validation-audit',
  templateUrl: './cross-field-validation-audit.component.html'
})
export class CrossFieldValidationAuditComponent implements OnInit, OnDestroy {
  title: string;
  private _message = new Subject<string>();
  messageText: string;
  error = false;
  breadcrumb: Breadcrumb;
  breadcrumbs: Breadcrumb[];
  schemaType: string;
  crossFieldValidation: CrossFieldValidation;
  crossFieldValidationHistory: CrossFieldValidation[];
  loading = false;
  scrollBarHorizontal = (window.innerWidth < 1200);
  subscriptions: Array<Subscription> = [];

  columnDefs = [];
  rowData = [];
  defaultColDef: any;
  context: any;
  frameworkComponents: any;
  isOnlineDataEntry: boolean;

  constructor(
    private validationMaintenanceService: CrossFieldValidationMaintenanceService,
    private activatedRoute: ActivatedRoute,
    public breadcrumbService: BreadcrumbService,
    public messageService: MessageService,
    private unCamelCase: UnCamelCasePipe,
    private dataCallSettingsService: DatacallSettingService,
  ) {
    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 12000);
    };
  }

  ngOnInit() {
    this.context = { componentParent: this };
    this.frameworkComponents = {
      dateRenderer: DateRendererComponent,
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this._message.subscribe(message => this.messageText = message);
    this.breadcrumbs = this.breadcrumbService.getBreadcrumbs();
    this.schemaType = this.activatedRoute.snapshot.paramMap.get('schemaType');
    this.crossFieldValidation = this.validationMaintenanceService.getSelectedCrossFieldValidation();
    this.breadcrumb = new Breadcrumb(this.crossFieldValidation.name, '/validationAudit');
    this.title = `${this.schemaType.toUpperCase()} Cross Field Validation Audit`;
    this.isOnlineDataEntry = this.dataCallSettingsService.getOnlineDataEntrySetting();
    this.getCrossFieldValidationHistory();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.messageService.clear();
  }

  getCrossFieldValidationHistory(): void {
    this.loading = true;
    const sub = this.validationMaintenanceService.getCrossFieldValidationHistory(this.crossFieldValidation, this.schemaType)
      .subscribe(
        history => {
          this.loading = false;
          this.error = false;
          this.crossFieldValidationHistory = history;
          this.rowData = this.crossFieldValidationHistory;
          if (this.rowData && this.rowData.length > 0) {
            this.columnDefs = this.getColumnDef(this.rowData[0]);
          }
        }
      );
    this.subscriptions.push(sub);
  }

  getColumnDef(row: any): any[] {
    const columnsDef: any[] = [];
    const keys = Object.keys(row);
    keys.forEach(key => {
      const headerName = this.unCamelCase.transform(key);
      if (headerName === 'Date Created') {
        columnsDef.push({ headerName: headerName, field: key, cellRenderer: 'dateRenderer' });
      } else if (headerName === 'Validation Level') {
        if (this.isOnlineDataEntry) {
          columnsDef.push({ headerName: headerName, field: key });
        }
      } else if (headerName !== 'Id' && headerName !== 'Schema Reference') {
        columnsDef.push({ headerName: headerName, field: key });
      }
    });
    return columnsDef;
  }

  onGridReady(params: any): void {
    const gridApi = params.api;

    gridApi.sizeColumnsToFit();
  }

}
