import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[autoResize]'
})
export class AutoResizeDirective {

  constructor(private element: ElementRef) {}

  // On input, adjust the textarea height
  @HostListener('input', ['$event.target'])
  onInput(textArea: HTMLTextAreaElement): void {
    this.adjustHeight();
  }

  ngAfterContentChecked(): void {
    this.adjustHeight();
  }

  protected adjustHeight(): void {
    const textArea = this.element.nativeElement;
    textArea.style.height = 'auto';
    textArea.style.height = textArea.scrollHeight + 'px';
  }
}
