import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {FilingSearchComponent} from './filing-search.component';
import {OnlineDataEntryComponent} from '../online-data-entry.component';

const routes: Routes = [
  {path: 'filingSearch', component: FilingSearchComponent},
  {path: 'dataEntry/:id', component: OnlineDataEntryComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FilingSearchRoutingModule {
}
