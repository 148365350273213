import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Breadcrumb } from '../../model/breadcrumb';
import { BreadcrumbService } from '../../service/breadcrumb.service';
import { ExpressionBuilderService } from '../expression-builder/services/expression-builder.service';
import { TestExpressionBuilderService } from './services/test-expression-builder.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TestExpressionBuilderResponse, TestExpressionBuilderValues } from './model/test-expression-builder';
import { MessageService } from '../../messages/service/message.service';
import { CrossFieldValidationMaintenanceService } from '../services/cross-field-validation-maintenance.service';
import { CrossFieldValidation } from '../model/cross-field-validation';
import { ValidationSchema } from '../../file-validation-maintenance/model/validation-schema';
import { FileValidationMaintenanceService } from '../../file-validation-maintenance/services/file-validation-maintenance.service';
import {isNullOrUndefined} from 'util';

@Component({
  selector: 'app-test-expression-builder',
  templateUrl: './test-expression-builder.component.html',
  styleUrls: ['./test-expression-builder.component.css']
})
export class TestExpressionBuilderComponent implements OnInit {
  title: string;
  loading = false;
  breadcrumbs: Breadcrumb[];
  breadcrumb: Breadcrumb;
  schemaType: string;
  expressionIndex: any;
  validationName: string;
  builtExpression: string;
  fieldNames: any[];
  fieldNamesInExpression: any[] = [];
  customFieldNamesInExpression: any[] = [];
  enteredFieldNameValues: any;
  difference: any[];
  messageText: string = '';
  color: number = 0;
  modalRef: BsModalRef;
  crumb: any;
  testExpressionValues: TestExpressionBuilderValues;
  crossFieldValidation: CrossFieldValidation;
  validationSchema: ValidationSchema;
  schemaProperty: any[] = [];
  exception: string;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private activatedRoute: ActivatedRoute,
    private expressionBuilderService: ExpressionBuilderService,
    private modalService: BsModalService,
    private router: Router,
    private testExpressionBuilderService: TestExpressionBuilderService,
    private messageService: MessageService,
    private validationMaintenanceService: CrossFieldValidationMaintenanceService,
    private schemaMaintenanceService: FileValidationMaintenanceService
  ) { }

  ngOnInit() {
    this.messageService.clear();
    this.breadcrumbs = this.breadcrumbService.getBreadcrumbs();
    this.schemaType = this.activatedRoute.snapshot.paramMap.get('schemaType');
    this.expressionIndex = this.activatedRoute.snapshot.paramMap.get('expressionIndex');
    this.validationName = this.activatedRoute.snapshot.paramMap.get('validationName');
    this.builtExpression = this.activatedRoute.snapshot.paramMap.get('builtExpression');
    this.breadcrumb = new Breadcrumb('Test Expression Builder', '/testExpressionBuilder');
    this.title = `${this.schemaType.toUpperCase()} Test Expression`;
    this.findFieldNamesInExpression();
  }

  findFieldNamesInExpression() {
    this.expressionBuilderService.setValidationNotSavedFromTestExpressionPage(true);
    this.fieldNames = this.expressionBuilderService.getFieldName();
    this.fieldNames.forEach(x => {
      if (this.builtExpression.includes(x.fieldNames)) {
        this.fieldNamesInExpression.push(x.fieldNames);
      }
    });
    let string = this.builtExpression;
    let text1 = string.replace(/\s/g, "").split("data['");
    for (let i = 1; i < text1.length; i++) {
      this.customFieldNamesInExpression.push(text1[i].split("']")[0]);
    }
    let text2 = string.replace(/\s/g, "").split("data,'");
    for (let i = 1; i < text2.length; i++) {
      this.customFieldNamesInExpression.push(text2[i].split("')")[0]);
    }
    this.difference = this.customFieldNamesInExpression.filter(a => !this.fieldNamesInExpression.some(b => a === b));
  }

  getClassNamesForResultsPanel() {
    if (this.color === 1) {
      return ['green'];
    } else if (this.color === 2) {
      return ['red'];
    } else {
      return [''];
    }
  }

  enteredFieldNameValue(event) {
    this.enteredFieldNameValues = event.value;
  }

  testValues(template: TemplateRef<any>) {
    this.loading = true;
    this.crossFieldValidation = this.validationMaintenanceService.getSelectedCrossFieldValidation();
    this.crossFieldValidation.expression = this.builtExpression;
    this.testExpressionValues = this.getTestExpressionBuilderValues();
    this.testExpressionBuilderService.postTestExpression(this.testExpressionValues, this.schemaType)
      .subscribe(
        (res: TestExpressionBuilderResponse) => {
          if (res) {
            this.displayResultMessages(res, template);
          }
          this.loading = false;
        }
      );
  }

  displayResultMessages(res: TestExpressionBuilderResponse, template: TemplateRef<any>) {
    if (res.exception !== null) {
      this.exception = res.exception;
      this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    } else {
      if (res.passed) {
        this.color = 1;
        this.messageText = 'The validation will pass by returning true from the expression.';
      } else {
        this.color = 2;
        this.messageText = 'The validation will fail by returning false from the expression.';
      }
    }
  }

  routeToCrossFieldPage(crumb, template: TemplateRef<any>) {
    this.messageService.clear();
    this.crumb = crumb;
    if (this.crumb.link === '/validationEdit' || this.crumb.link === '/validationMaintenance') {
      this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    } else {
      this.expressionBuilderService.setValidationNotSavedFromTestExpressionPage(false);
      this.breadcrumbService.removeBreadcrumb(crumb);
      this.expressionBuilderService.setRoutingHelper(false);
      this.router.navigate([`${crumb.link}`]);
    }
  }

  routeToPage() {
    this.messageService.clear();
    this.expressionBuilderService.setValidationNotSavedFromTestExpressionPage(false);
    this.modalRef.hide();
    this.breadcrumbService.removeBreadcrumb(this.crumb);
    this.router.navigate([`${this.crumb.link}`]);
  }

  getTestExpressionBuilderValues(): TestExpressionBuilderValues {
    if (isNullOrUndefined(this.enteredFieldNameValues)) {
      this.enteredFieldNameValues = this.convertFieldNamesInExpressionToJson();
    }
    return new TestExpressionBuilderValues(this.crossFieldValidation, this.enteredFieldNameValues);
  }

  convertFieldNamesInExpressionToJson(): any {
    return JSON.parse('{' + this.fieldNamesInExpression.map(x => '"' + x.toString() + '":""').join(',') + '}');
  }
}
