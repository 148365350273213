import {DocumentWithError} from '../../file-dashboard/file-submission-error-type/model/document-with-error';
import {generateMockDocument} from './mock-documents';
import {generateMockErrors} from './mock-errors';

export function generateMockDocumentsWithErrors(numberOfDocumentsWithErrors: number, numberOfData: number, numberOfErrors: number): DocumentWithError[] {
  let documentsWithErrors: DocumentWithError[] = [];
  for (let i = 1; i <= numberOfDocumentsWithErrors; i++) {
    documentsWithErrors.push({document: generateMockDocument(numberOfData), errors: generateMockErrors(numberOfErrors)});
  }
  return documentsWithErrors;
}
