import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource, TooltipPosition } from '@angular/material';
import { faAd, faChevronRight, faExpand, faTrash } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertMessage } from '../../messages/model/alert-message';
import { MessageService } from '../../messages/service/message.service';
import { User } from '../../model/user';
import { UserService } from '../../service/user.service';
import { FilingBlank } from '../model/filingblank';
import { FormSection } from '../model/form-section';
import { TodoItemFlatNode } from '../model/ItemFlatNode';
import { NavigationItems } from '../model/Navigation';
import { FormBuilderService } from '../services/form-builder.service';

@Component({
  selector: 'app-filing-navigation',
  templateUrl: './filing-navigation.component.html',
  styleUrls: ['./filing-navigation.component.css']
})
export class FilingNavigationComponent implements OnInit {
  @Output() selectedFormSection = new EventEmitter<FormSection>();

  user: User;
  schema: string;
  title: string;
  loading: boolean = false;
  sidenavWidth = 15;
  ngStyle: string;
  faAdd = faAd;
  faChevronRight = faChevronRight;
  faExpand = faExpand;
  faTrash = faTrash
  navigation: NavigationItems;
  dataSo: FormSection[];
  tooltipPosition: TooltipPosition = 'above';
  editMode: boolean = false;
  activeNode: any;
  bsModalRef: BsModalRef;
  deleteNode: TodoItemFlatNode;
  disableIfRdcAdmin: boolean;
  isFocus: boolean = false;
  isNewNode: boolean = false;
  goToSectionNode: FormSection;

  constructor(
    private userService: UserService,
    public formBuilderService: FormBuilderService,
    private messageService: MessageService,
    private modalService: BsModalService
  ) {
  }

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    this.disableIfRdcAdmin = this.userService.getStoredUser().isRdcAdmin;
    this.schema = this.user.currentSchema.toLocaleUpperCase();
    this.title = `Set Navigation`;
    this.initial();
  }

  initial() {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren,
    );
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    this.formBuilderService.dataChange.subscribe(data => {
      this.refreshTree(data);
    });
  }

  refreshTree(_data: FormSection[]) {
    this.dataSource.data = [];
    this.dataSource.data = _data;
  }

  getClassNames(node): string[] {
    if (this.treeControl.isExpanded(node)) {
      return ['fa fa-chevron-down']
    } else {
      return ['fa fa-chevron-right']
    }
  }
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TodoItemFlatNode, FormSection>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<FormSection, TodoItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null;

  /** The new name's name */
  newItemName = '';

  treeControl: FlatTreeControl<TodoItemFlatNode>;

  treeFlattener: MatTreeFlattener<FormSection, TodoItemFlatNode>;

  dataSource: MatTreeFlatDataSource<FormSection, TodoItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

  /*   constructor(private _database: ChecklistDatabase) {

    } */

  getLevel = (node: TodoItemFlatNode) => node.level;

  isExpandable = (node: TodoItemFlatNode) => node.expandable;

  getChildren = (node: FormSection): FormSection[] => node.sections;

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.name === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: FormSection, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.name === node.name
      ? existingNode
      : new TodoItemFlatNode();
    flatNode.name = node.name;
    flatNode.level = level;
    flatNode.expandable = true; // edit this to true to make it always expandable
    if (node.sections) {
      flatNode.hasChild = (node.sections.length === 0) ? false : true;
    } else {
      flatNode.hasChild = false;
    }  // add this line. this property will help
    // us to hide the expand button in a node
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }


  getAllParents(node: FormSection): FormSection[] {
    const ancestors = this.getParents(this.dataSource.data, node.name);
    return ancestors;
  }

  getParents(array, name) {
    if (typeof array != "undefined") {
      for (let i = 0; i < array.length; i++) {
        if (array[i].name === name) {
          return [array[i]];
        }
        const a = this.getParents(array[i].sections, name);
        if (a !== null) {
          a.unshift(array[i]);
          return a;
        }
      }
    }
    return null;
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  addParentLevel() {
    this.activeNode = null;
    this.isFocus = true;
    this.isNewNode = true;
    this.formBuilderService.setChangeStatus(true);
    this.formBuilderService.isDisableAddEditButton.next(true);
    // this.formBuilderService.sideNavTitle.next('Set Page Navigation');
    this.formBuilderService.insertParentLevelItem();
  }

  /** Select the category so we can insert the new name. */
  addNewItem(node: TodoItemFlatNode) {
    this.formBuilderService.disableDependencyPage.next(true);
    this.activeNode = null;
    this.isFocus = true;
    this.isNewNode = true;
    this.formBuilderService.setChangeStatus(true);
    this.formBuilderService.isDisableAddEditButton.next(true);
    // this.formBuilderService.sideNavTitle.next('Set Page Navigation');
    const parentNode = this.flatNodeMap.get(node);
    this.formBuilderService.insertItem(parentNode!, '');
    this.treeControl.expand(node);
  }

  /** Save the node to database */
  saveNode(node: TodoItemFlatNode, itemValue: string, template: TemplateRef<any>) {
    this.isNewNode = false;
    this.formBuilderService.showErrorMessages.next(false);
    this.messageService.clear();
    let duplicateSection = false;
    const nestedNode = this.flatNodeMap.get(node);
    let treeControl = this.getAllParents(nestedNode);
    let validation = (itemValue.replace(/\s/g, "") === '') ? true : false;

      // check Duplicate section name or not
    if(treeControl.length> 1)
      duplicateSection = this.formBuilderService.isItemAlreadyInSection(itemValue, treeControl[treeControl.length - 2].sections);
    else {
      duplicateSection = this.formBuilderService.isItemAlreadyInSection(itemValue, this.dataSource.data);
    }
    if (validation || duplicateSection) {
      this.formBuilderService.showErrorMessages.next(true);
      let message = !validation? 'Duplicate section name':'Section Name cannot be empty';
      this.messageService.clear();
      this.messageService.add(new AlertMessage(message, 'danger'));
    } else {
      this.formBuilderService.updateItem(node, nestedNode!, itemValue, treeControl);
      node.isEditable = false;
      this.setDataSourceToNavigationModel(this.dataSource._data.value);
      this.goToSection(node , template);
    }
    this.formBuilderService.isDisableAddEditButton.next(false);
  }

  /** Delete Sections/SubSections */
  deleteItem(node: TodoItemFlatNode, template: TemplateRef<any>) {
    this.formBuilderService.disableDependencyPage.next(true);
    this.activeNode = node;
    this.deleteNode = node;
    this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
  }

  confirmDelete() {
    this.formBuilderService.setChangeStatus(true);
    let node = this.deleteNode;
    let nestedNode = this.flatNodeMap.get(node);
    this.formBuilderService.delete(nestedNode);
    this.setDataSourceToNavigationModel(this.dataSource._data.value);
    this.formBuilderService.isDisableAddEditButton.next(false);
    this.formBuilderService.setClickedName('');
    this.formBuilderService.setSelectedSection(nestedNode);
    this.selectedFormSection.emit(undefined);
    this.formBuilderService.isTitleVisible = true;
    this.bsModalRef.hide();
    this.messageService.clear();
  }

  setDataSourceToNavigationModel(dataSource: FormSection[]) {
    let filingBlank = new FilingBlank();
    filingBlank['sections'] = dataSource;
    filingBlank['questions'] = [];
    this.formBuilderService.setFilingBlank(filingBlank);
    this.formBuilderService.sideNavTitle.next('Page Navigation');
  }

  editItem(node: TodoItemFlatNode) {
    this.formBuilderService.disableDependencyPage.next(true);
    this.activeNode = node;
    this.isFocus = true;
    this.formBuilderService.setChangeStatus(true);
    this.formBuilderService.isDisableAddEditButton.next(true);
    node.isEditable = true;
    this.formBuilderService.sideNavTitle.next('Set Page Navigation');
  }

  goToSection(node: TodoItemFlatNode, template) {
    this.goToSectionNode = this.flatNodeMap.get(node); //'3c667cab-854c-46c3-90ad-91153d1cd926'
    this.activeNode = node;
    this.formBuilderService.setFilingBlank(this.formBuilderService.getFilingBlank());
    if(this.formBuilderService.isFormUpdated) {
    this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
    } else {
      this.navigateToClickedSection();
    }
  }

  navigateToClickedSection() {
    this.formBuilderService.isTitleVisible = false;
    let newSectionKey = this.formBuilderService.getNewSectionKey();
    if(newSectionKey.component === 'build-edit-form' && newSectionKey.newSectionKey === this.goToSectionNode.key) {
      this.goToSectionNode.questions = [];
    this.formBuilderService.disableDependencyPage.next(true);
    }
    this.clearSectionsIfNotSaved(newSectionKey.formSection);
    let nestedNode = this.goToSectionNode;
    let keyValue = nestedNode.name;
    this.formBuilderService.setClickedName(keyValue.toUpperCase());
    this.formBuilderService.setSelectedSection(nestedNode);
    this.selectedFormSection.emit(nestedNode);
    if (this.formBuilderService.isFormUpdated) { this.bsModalRef.hide() };
    this.formBuilderService.isFormUpdated = false;
  }

  clearSectionsIfNotSaved(formSection) {
    let newSectionKey = this.formBuilderService.getNewSectionKey();
    if(newSectionKey.component === 'build-edit-form') {
    formSection.forEach(x => {
      if(x.key === newSectionKey.newSectionKey) {
        x.questions = [];
      }
      if(x.sections.length>0) {
        this.clearSectionsIfNotSaved(x.sections);
      }
    });
  }
  }

  getClassNamesForDelete(node: TodoItemFlatNode): string {
    if (node.name === this.formBuilderService.initialSectionName.value) {
      return 'hidden-node';
    }
    return '';
  }

  cancelDelete() {
    this.formBuilderService.disableDependencyPage.next(false);
    this.bsModalRef.hide();
  }

  cancelSave() {
    this.formBuilderService.disableDependencyPage.next(true);
    this.bsModalRef.hide();
  }
}
