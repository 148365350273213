<div matTooltip="{{ isRemoveDependencyDisabled ? 'The field is used in Form Dependency and must be removed before deleting.' 
                      : (isRemoveDisabled ? 'The field is used in Form Builder and must be removed before deleting.' 
                      : '') }}"
     [matTooltipDisabled]="!(isRemoveDependencyDisabled || isRemoveDisabled)">
  <button id="btn:remove:{{params.value}}" 
          [disabled]="isRemoveDependencyDisabled || isRemoveDisabled" 
          class="btn btn-danger" 
          (click)="removeProperty(params.value, removePropertyModal)">
    <i class="fa fa-times"></i>
  </button>
</div>

<ng-template #removePropertyModal>
  <div class="modal-header">
    <h3 class="modal-title">Remove property</h3>
    <button id="close" type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Are you sure you wish to remove the {{deleteKey}} property? This cannot be undone. All subsequent field order numbers will be decremented after this field.
  </div>
  <div class="modal-footer">
    <button id="cancel" type="button" class="btn btn-link btn-sm" (click)="cancelRemove()">Cancel</button>
    <button id="remove" type="button" class="btn btn-primary" (click)="confirmRemove()">Remove</button>
  </div>
</ng-template>
