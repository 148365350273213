import {
    MOCK_DUPLICATE_RECORD, 
    MOCK_DURATION_ONE,
    MOCK_YEAR_MUST_EQUAL_ZERO,
    MOCK_DURATION_TWO,
    MOCK_DURATION_THREE,
    MOCK_DURATION_FOUR,
    MOCK_DURATION_FIVE,
    MOCK_DURATION_SIX,
    MOCK_DURATION_SEVEN,
    MOCK_DURATION_EIGHT
} from './mock-errors';
import {FileSubmissionErrorType} from '../file-dashboard/file-submission-errors/model/file-submission-error-type';

export const MOCK_DUPLICATE_ERRORS = new FileSubmissionErrorType('Duplicate Record', [MOCK_DUPLICATE_RECORD], '');
export const MOCK_ISSUE_DATE_YEAR = new FileSubmissionErrorType('Issue Date Year', [MOCK_YEAR_MUST_EQUAL_ZERO], '');
export const MOCK_DURATION = new FileSubmissionErrorType('Duration', [MOCK_DURATION_ONE], '');
export const MOCK_DURATION_1 = new FileSubmissionErrorType('Duration', [MOCK_DURATION_TWO], '');
export const MOCK_DURATION_2 = new FileSubmissionErrorType('Duration', [MOCK_DURATION_THREE], '');
export const MOCK_DURATION_3 = new FileSubmissionErrorType('Duration', [MOCK_DURATION_FOUR], '');
export const MOCK_DURATION_4 = new FileSubmissionErrorType('Duration', [MOCK_DURATION_FIVE], '');
export const MOCK_DURATION_5 = new FileSubmissionErrorType('Duration', [MOCK_DURATION_SIX], '');
export const MOCK_DURATION_6 = new FileSubmissionErrorType('Duration', [MOCK_DURATION_SEVEN], '');
export const MOCK_DURATION_7 = new FileSubmissionErrorType('Duration', [MOCK_DURATION_EIGHT], '');
export const MOCK_PBR_WAITING_FOR_USER_APPROVAL_ERRORS = [
    MOCK_DUPLICATE_ERRORS,
    MOCK_ISSUE_DATE_YEAR,
    MOCK_DURATION,
    MOCK_DURATION_1,
    MOCK_DURATION_2,
    MOCK_DURATION_3,
    MOCK_DURATION_4,
    MOCK_DURATION_5,
    MOCK_DURATION_6,
    MOCK_DURATION_7];
