import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';

import {OnlineDataEntryRoutingModule} from './online-data-entry-routing.module';
import {NcuiModule} from 'common-ui-components';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {FormSectionComponent} from './form-section/form-section.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OnlyDigitsDirective} from '../shared/only-digits.directive';
import {OnlineDataEntryComponent} from './online-data-entry.component';
import {QuestionControlService} from './dynamic-forms/question-control-service';
import {LoadingModule} from 'ngx-loading';
import {MessagesModule} from '../messages/messages.module';
import {SideNavigationComponent} from './side-navigation/side-navigation.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MinMaxDirective} from '../shared/min-max.directive';
import {FormSummaryComponent} from './form-summary/form-summary.component';
import {UserFilingComponent} from './user-filing-dasboard/user-filing.component';
import {AlertModule} from 'ngx-bootstrap/alert';
import { UploadAttachmentsComponent } from './upload-attachments/upload-attachments.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { AutoResizeDirective } from '../shared/app-auto-resize.directive';


@NgModule({
  declarations: [FormSectionComponent, FormSummaryComponent, UserFilingComponent,
    OnlineDataEntryComponent, OnlyDigitsDirective, SideNavigationComponent, MinMaxDirective, UploadAttachmentsComponent, AutoResizeDirective],
  imports: [
    CommonModule,
    OnlineDataEntryRoutingModule,
    NcuiModule,
    FontAwesomeModule,
    CollapseModule.forRoot(),
    ProgressbarModule.forRoot(),
    LoadingModule,
    FormsModule,
    ReactiveFormsModule,
    MessagesModule,
    MatTooltipModule,
    MatTreeModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    AlertModule
  ],
  providers: [QuestionControlService, DatePipe],
  entryComponents: [UploadAttachmentsComponent],
})
export class OnlineDataEntryModule {
}
