import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { HandleError, HttpErrorHandler } from '../../../shared/http-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class TestExpressionBuilderService {
  readonly handleError: HandleError;

  constructor(
    private httpErrorHandler: HttpErrorHandler,
    private httpClient: HttpClient,
  ) { 
    this.handleError = httpErrorHandler.createHandleError('TestExpressionBuilderService');
  }

  postTestExpression(fieldNames: any, schemaType: string): Observable<any> {
    const url = `${environment.apiUrl}${schemaType}/crossfieldvalidations/test`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };

    return this.httpClient.post<any>(url, fieldNames, options)
      .pipe(catchError(this.handleError('postTestExpression', null)));
  }
}
