<div id="validation-edit" class="wrapper">
  <div class="row page-title">
    <div class="col-12">
      <h1>{{title}}</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li *ngFor="let crumb of breadcrumbs" class="breadcrumb-item">
            <a [routerLink]="[crumb.link]" (click)="breadcrumbService.removeBreadcrumb(crumb)">{{crumb.label}}</a>
          </li>
          <li class="breadcrumb-item active">
            {{breadcrumb.label}}
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <app-messages></app-messages>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <form [formGroup]="validationForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label class="center-block" for="js-validation:name"><strong>Name:</strong></label>
          <input id="js-validation:name" class="form-control" formControlName="name" required/>
          <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
            <div *ngIf="name.errors['required']">
              Name is required
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="center-block" for="js-validation:description"><strong>Description:</strong></label>
          <input id="js-validation:description" class="form-control" formControlName="description" required/>
        </div>
        <div class="form-group">
          <label class="center-block" for="js-validation:schema-reference"><strong>Schema Reference:</strong></label>
          <input id="js-validation:schema-reference" class="form-control" formControlName="schemaReference" required/>
        </div>
        <div class="form-group">
          <label class="center-block" for="js-validation:validation-level"><strong>Validation Level:</strong></label>
          <input id="js-validation:validation-level" class="form-control" formControlName="validationLevel" required/>
        </div>
        <div class="form-group">
          <label class="center-block" for="js-validation:expression"><strong>Expression:</strong></label>
          <textarea id="js-validation:expression" class="form-control" formControlName="expression" required></textarea>
        </div>
        <div class="form-group">
          <label class="center-block" for="js-validation:error-message"><strong>Error Message:</strong></label>
          <textarea id="js-validation:error-message" class="form-control" formControlName="errorMessage" required></textarea>
        </div>
        <div class="inline-field mb-3">
          <label for="js-validation:active"><strong>Active</strong></label>
          <input id="js-validation:active" type="checkbox" formControlName="active"/>
          <label for="js-validation:public-viewable"><strong>Public Viewable</strong></label>
          <input id="js-validation:public-viewable" type="checkbox" formControlName="publicViewable"/>
        </div>

        <div class="mb-3">
          <button type="button" class="btn btn-link" (click)="onCancel()"><small>Cancel</small></button>
          <button type="submit" [disabled]="validationForm.pristine || validationForm.invalid" class="btn btn-primary">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>
