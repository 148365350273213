import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Subject } from 'rxjs';
import { User } from '../model/user';
import { UnCamelCasePipe } from '../pipes/un-camel-case.pipe';
import { UserService } from '../service/user.service';
import { FilingDate } from './model/filing-date';
import { FilingDatesService } from './services/filing-dates.service';
import * as _ from 'lodash';
import {MessageService} from '../messages/service/message.service';
import { AlertMessage } from '../messages/model/alert-message';
import { takeUntil } from 'rxjs/operators';
import { FilingDateRendererComponent } from './cell-renderer/filing-date-renderer/filing-date-renderer.component';

@Component({
  selector: 'app-filing-dates',
  templateUrl: './filing-dates.component.html'
})
export class FilingDatesComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  title: string;
  messageText: string;
  error = false;
  user: User;
  dataYear: number;
  dateStart: Date;
  dateEnd: Date;
  datesList: any[];
  loadingIndicator = true;
  loading = false;
  dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  scrollBarHorizontal = (window.innerWidth < 1200);

  columnDefs = [];
  rowData = [];
  defaultColDef: any;
  context: any;
  frameworkComponents: any;

  constructor(
    private userService: UserService,
    private filingDatesService: FilingDatesService,
    private unCamelCase: UnCamelCasePipe,
    private messageService: MessageService,
  ) {
    this.dpConfig.containerClass = 'theme-dark-blue';
    this.dpConfig.showWeekNumbers = false;
    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 12000);
    };
  }

  ngOnInit() {
    this.context = { componentParent: this };
    this.frameworkComponents = {
      dateRenderer: FilingDateRendererComponent
    };
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: true
    };
    this.user = this.userService.getStoredUser();
    this.title = `${this.user.currentSchema.toUpperCase()} Filing Dates`;
    this.getDates();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.messageService.clear();
  }

  getDates(): void {
    this.loading = true;
    this.filingDatesService.getDates(this.user.currentSchema)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        dates => {
          this.loading = false;
          this.error = false;
          this.datesList = _.values(dates);
          this.rowData = this.datesList;
          this.columnDefs = this.getColumnDef(this.rowData[0]);
        }
      );
  }

  getColumnDef(row: any): any[] {
    const dateColumns = ['Date Start', 'Date End', 'Date Last Modified'];
    const columnsDef: any[] = [];
    if (row) {
      const keys = Object.keys(row);
      keys.forEach(key => {
        const headerName = this.unCamelCase.transform(key);
        if (dateColumns.indexOf(headerName) >= 0) {
          columnsDef.push({headerName: headerName, field: key, cellRenderer: 'dateRenderer'});
        } else if (headerName == 'User Added') {
          columnsDef.push({headerName: 'Original User Added', field: key});
        } else if (headerName == 'Date Added') {
          columnsDef.push({headerName: 'Original Date Added', field: key, cellRenderer: 'dateRenderer'});
        } else if (headerName !== '_id') {
          columnsDef.push({headerName: headerName, field: key});
        }
      });
    }
    return columnsDef;
  }

  onGridReady(params: any): void {
    const gridApi = params.api;

    gridApi.sizeColumnsToFit();
  }

  create(): void {
    this.loading = true;
    const filingDate: FilingDate = new FilingDate();
    filingDate.dataYear = this.dataYear;
    filingDate.dateStart = this.dateStart;
    filingDate.dateEnd = this.dateEnd;
    this.filingDatesService.saveDate(this.user.currentSchema, filingDate)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => {
          this.loading = false;
          this.error = false;
          this.messageText = 'The filing date has been added';
          this.messageService.add(new AlertMessage(this.messageText, 'success'));
          this.getDates();
        }
      );
  }

  isCreateButtonDisabled(): boolean {
    return !this.isDateStart() || !this.isDateEnd();
  }

  private isDateStart(): boolean {
    return this.dateStart !== undefined && this.dateStart !== null;
  }

  private isDateEnd(): boolean {
    return this.dateEnd !== undefined && this.dateEnd !== null;
  }

}
