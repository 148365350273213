import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, HostListener, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { Message } from '../../model/message';
import { FileService } from '../service/file.service';
import { UserService } from '../../service/user.service';
import { User } from '../../model/user';
import * as _ from 'lodash';
import { MessageService } from '../../messages/service/message.service';
import { AlertMessage } from '../../messages/model/alert-message';
import { UploadURLResponse } from '../model/upload-url-response';
import { FileUpload } from '../model/file-upload';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

  @Output() isFileUploaded = new EventEmitter<boolean>();
  messages: Array<Message> = [];
  dragAreaClass = 'file-upload-drag-area';
  @Input() fileExt = 'CSV';
  @Input() maxFiles = 1;
  @Input() maxSize = 5; // 5MB
  user: User;
  files: any[];
  error = false;
  messageText: string;
  subscriptions: Array<Subscription> = [];
  isFileUploading: boolean;
  uploadingFileName: string;
  uploadPercentage: number;
  uploadingFileSize: any;
  fileWithoutFormData: File;

  constructor(
    private fileService: FileService,
    private userService: UserService,
    private messageService: MessageService
  ) {

  }

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    this.files = [];
  }

  onFileChange(event) {
    const files = event.target.files;
    this.fileWithoutFormData = files[0];
    this.error = false;
    this.isValidFiles(files);
    if (!this.error) {
      this.files.push(_.values(files));
      _.values(files).forEach((file) => {
        this.error = false;
        this.messageText = `${file.name} is ready for upload`;
        this.messageService.add(new AlertMessage(this.messageText, 'success'));
        this.uploadingFileName = file.name;
      });
    }
  }

  @HostListener('dragover', ['$event'])
  onDragOver(event) {
    this.dragAreaClass = 'file-upload-drop-area';
    event.preventDefault();
  }

  @HostListener('dragenter', ['$event'])
  onDragEnter(event) {
    this.dragAreaClass = 'file-upload-drop-area';
    event.preventDefault();
  }

  @HostListener('dragend', ['$event'])
  onDragEnd(event) {
    this.dragAreaClass = 'file-upload-drag-area';
    event.preventDefault();
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event) {
    this.dragAreaClass = 'file-upload-drag-area';
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  onDrop(event) {
    this.dragAreaClass = 'file-upload-drag-area';
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    this.fileWithoutFormData = files[0];
    this.error = false;
    this.isValidFiles(files);
    if (!this.error) {
      this.files.push(_.values(files));
      _.values(files).forEach((file) => {
        this.error = false;
        this.messageText = `${file.name} is ready for upload`;
        this.messageService.add(new AlertMessage(this.messageText, 'success'));
        this.uploadingFileName = file.name;
      });
    }
  }

  getPresignedURLFromS3() {
    this.fileService.getPreSignedUrlfromS3(this.user, this.uploadingFileName)
      .subscribe(
        res => {
          if (res) {
            this.uploadToS3(res);
          }
        }
      )
  }

  uploadToS3(res: UploadURLResponse) {
    if (this.files.length > 0) {
      const formData: FormData = new FormData();
      const fileNames: Array<string> = [];
      this.files.forEach((file, index) => {
        fileNames.push(this.files[index].name);
        const fileBlob = new Blob(this.files[index]);
        formData.append('file', fileBlob, this.files[index][0].name); // TODO: note that the service only supports single file uploads at this time
      });
      const sub = this.fileService.uploadtoS3(this.fileWithoutFormData, res.uploadURL)
        .subscribe(
          event => {
            this.uploadingFileName = fileNames[0];
            if (event.type === HttpEventType.UploadProgress) {
              this.isFileUploading = true;
              this.uploadPercentage = Math.round((100 * event.loaded / event.total));
              this.uploadingFileName = fileNames.length > 1 ? fileNames.join(', ') : fileNames[0];
              this.uploadingFileSize = event.total;
            } else if (event instanceof HttpResponse) {
              this.isFileUploading = false;
              this.cancel();
            } else {
              this.isFileUploading = false;
              this.cancel();
            }
            this.setFileUploadAndRefresh(res, event);
          }
        );
      this.subscriptions.push(sub);
    }
  }

  setFileUploadAndRefresh(res, event) {
    let fileUpload: FileUpload = new FileUpload();
    fileUpload.fileName = res.fileName;
    fileUpload.originalFileName = res.originalFileName;
    fileUpload.fileSize = this.uploadingFileSize;
    this.fileService.setFileUpload(fileUpload);
    if (event.status != undefined) {
      if (event.status === 201 || event.status === 200) {
        this.isFileUploaded.emit(true);
      }
    }
  }

  isValidFiles(files): boolean {
    if (((files.length + this.files.length) > this.maxFiles) || (files.length > this.maxFiles)) {
      this.messageText = `File upload limit is ${this.maxFiles} files`;
      this.messageService.add(new AlertMessage(this.messageText, 'danger'));
      return false;
    }
    return this.error;
  }

  private isValidFileExtension(files) {
    const extensions = this.fileExt.split(',').map(function (x) {
      return x.toLocaleUpperCase().trim();
    });
    _.values(files).forEach(file => {
      const ext = file.name.toUpperCase().split('.').pop() || file.name;
      if (extensions.indexOf(ext) === -1) {
        this.messageText = `Invalid extension for file ${file.name}. Accepted file formats: ${extensions}`;
        this.messageService.add(new AlertMessage(this.messageText, 'danger'));
      }
    });
  }

  private isValidFileSize(file) {
    const fileSizeInMB = file.size / (1024 * 1000);
    const size = Math.round(fileSizeInMB * 100) / 100;
    if (size > this.maxSize) {
      this.messageText = `${file.name} exceeds file size limit of ${this.maxSize}MB (${size}MB)`;
      this.messageService.add(new AlertMessage(this.messageText, 'danger'));
    }
  }

  cancel() {
    this.files = [];
    this.messages = [];
    this.error = false;
    this.dragAreaClass = 'file-upload-drag-area';
  }

  readyForUpload(): boolean {
    return this.files.length > 0;
  }

  removeMessage(index: number) {
    this.messages.splice(index, 1);
  }

  private alertId(index: number): string {
    return `fileUploadAlert${index + 1}`;
  }

}
