import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserNotAllowedComponent } from './user-not-allowed.component';
import { UserNotAllowedRoutingModule } from './user-not-allowed-routing.module';

@NgModule({
  imports: [
    CommonModule,
    UserNotAllowedRoutingModule
  ],
  declarations: [UserNotAllowedComponent]
})
export class UserNotAllowedModule { }
