export const MOCK_VALIDATION_SCHEMA = {
  "properties": {
      "string_length": {
          "name": "string_length",
          "type": "string",
          "required": false,
          "isSubmissionKey": false,
          "isDocumentKey": false,
          "isAssignmentsKey": false,
          "orderNumber": 1,
          "xlsLocation": null,
          "description": "Spaces not allowed in property name"
      },
      "string_listOf_values": {
          "name": "string_listOf_values",
          "type": "string",
          "required": false,
          "isSubmissionKey": false,
          "isDocumentKey": false,
          "isAssignmentsKey": false,
          "description": "string with a list of valid values validationg",
          "orderNumber": 3,
          "xlsLocation": null,
          "enum": [
              "house",
              "home",
              "casa",
              "dwelling",
              "maison",
              "extra",
              "word",
              "best",
              "krishna"
          ],
          "anyOf": [
              "df",
              "df"
          ]
      },
      "integer_min_max_value": {
          "name": "integer_min_max_value",
          "type": "integer",
          "required": false,
          "isSubmissionKey": false,
          "isDocumentKey": false,
          "isAssignmentsKey": false,
          "description": "integer field that must be between 10 and 20",
          "orderNumber": 4,
          "xlsLocation": null,
          "min": "10",
          "max": "20",
          "enum": [
              "yes",
              " no"
          ],
          "pattern": "fghgfh",
          "anyOf": [
              "vbnvbnv"
          ]
      },
      "string_pattern": {
          "name": "string_pattern",
          "type": "string",
          "required": false,
          "isSubmissionKey": false,
          "isDocumentKey": false,
          "isAssignmentsKey": false,
          "description": "string that cannot contain special characters according to regex/pattern and is between 1 and 10 characters",
          "orderNumber": 5,
          "xlsLocation": null,
          "pattern": "^[a-zA-Z0-9]{1,10}$",
          "enum": [
              "yes",
              " no"
          ]
      },
      "decimal_field": {
          "name": "decimal_field",
          "type": "decimal",
          "required": false,
          "isSubmissionKey": false,
          "isDocumentKey": false,
          "isAssignmentsKey": false,
          "description": "a decimal field",
          "orderNumber": 6,
          "xlsLocation": null,
          "max": "100"
      },
      "new_available_dec_field": {
          "name": "new_available_dec_field",
          "type": "decimal",
          "required": false,
          "isSubmissionKey": false,
          "isDocumentKey": false,
          "isAssignmentsKey": false,
          "description": "a non-required, non-validated decimal field.",
          "orderNumber": 7,
          "xlsLocation": null,
          "enum": [
              "NO",
              " YES"
          ]
      },
      "new_available_string": {
          "name": "new_available_string",
          "type": "string",
          "required": false,
          "isSubmissionKey": false,
          "isDocumentKey": false,
          "isAssignmentsKey": false,
          "description": "a string field for testing field selectionh",
          "orderNumber": 8,
          "xlsLocation": null,
          "enum": [
              "y",
              "n"
          ]
      },
      "cocode": {
          "name": "cocode",
          "type": "string",
          "required": true,
          "isSubmissionKey": true,
          "isDocumentKey": false,
          "isAssignmentsKey": true,
          "description": "cocode, assignment key",
          "orderNumber": 9,
          "xlsLocation": null,
          "enum": [
              "yes",
              " no"
          ]
      },
      "new_property_12254": {
          "name": "new_property_12254",
          "type": "string",
          "required": false,
          "isSubmissionKey": false,
          "isDocumentKey": false,
          "isAssignmentsKey": false,
          "description": "tesst",
          "orderNumber": 10,
          "xlsLocation": null,
          "anyOf": [
              "44"
          ],
          "enum": [
              "yes",
              " no"
          ]
      },
      "test33": {
          "name": "test33",
          "type": "string",
          "required": false,
          "isSubmissionKey": false,
          "isDocumentKey": false,
          "isAssignmentsKey": false,
          "description": "21",
          "orderNumber": 11,
          "xlsLocation": null,
          "enum": [
              "m",
              " n"
          ]
      },
      "New Enum": {
          "name": "New Enum",
          "type": "string",
          "required": false,
          "isSubmissionKey": false,
          "isDocumentKey": false,
          "isAssignmentsKey": false,
          "description": "New Enum Value",
          "orderNumber": 12,
          "xlsLocation": null,
          "enum": [
              "yes",
              " no"
          ]
      },
      "New Enum for Form Builder": {
          "name": "New Enum for Form Builder",
          "type": "string",
          "required": false,
          "isSubmissionKey": false,
          "isDocumentKey": false,
          "isAssignmentsKey": false,
          "description": "New Enum Value for Form Builder",
          "orderNumber": 13,
          "xlsLocation": null,
          "enum": [
              "yes",
              " no",
              " N/A"
          ]
      },
      "Enum 1": {
          "name": "Enum 1",
          "type": "string",
          "required": false,
          "isSubmissionKey": false,
          "isDocumentKey": false,
          "isAssignmentsKey": false,
          "description": "Enum 1",
          "orderNumber": 14,
          "xlsLocation": null,
          "enum": [
              "yes",
              " no"
          ]
      },
      "Enum 2": {
          "name": "Enum 2",
          "type": "string",
          "required": false,
          "isSubmissionKey": false,
          "isDocumentKey": false,
          "isAssignmentsKey": false,
          "description": "Enum 2",
          "orderNumber": 15,
          "xlsLocation": null,
          "enum": [
              "yes",
              " no"
          ]
      },
      "Enum 3": {
          "name": "Enum 3",
          "type": "string",
          "required": false,
          "isSubmissionKey": false,
          "isDocumentKey": false,
          "isAssignmentsKey": false,
          "description": "Enum 3",
          "orderNumber": 16,
          "xlsLocation": null,
          "enum": [
              "yes",
              " no"
          ]
      },
      "string_required": {
          "name": "string_required",
          "type": "string",
          "required": true,
          "isSubmissionKey": false,
          "isDocumentKey": false,
          "isAssignmentsKey": false,
          "description": "required string field",
          "orderNumber": 2,
          "xlsLocation": null
      },
      "eNum 1": {
          "name": "eNum 1",
          "type": "string",
          "required": false,
          "isSubmissionKey": false,
          "isDocumentKey": false,
          "isAssignmentsKey": false,
          "description": "Mix case",
          "orderNumber": 18,
          "xlsLocation": null,
          "enum": [
            "yes",
            " no"
        ]
      },
      "new_property_17": {
          "name": "new_property_17",
          "type": "string",
          "required": false,
          "isSubmissionKey": false,
          "isDocumentKey": false,
          "isAssignmentsKey": false,
          "description": "ll",
          "orderNumber": 17,
          "xlsLocation": null,
          "enum": [
              "yes",
              " no"
          ]
      }
  },
  "userCreated": "rdctest32",
  "dateCreated": "2024-02-27T19:20:57.162+00:00",
  "type": "KRISHNA_TEST_ODE",
  "id": "65de3619084b702ba1ff898e",
  "fileType": "CSV"
}
