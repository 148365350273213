import {Error} from '../file-admin/errors/model/error';

export const MOCK_PBR_ERROR_ONE = new Error('11111', new Date().getFullYear(), 'mock_error_type', 100);
export const MOCK_PBR_ERROR_TWO = new Error('22222', new Date().getFullYear(), 'mock_error_type', 200);
export const MOCK_PBR_ERROR_THREE = new Error('33333', new Date().getFullYear(), 'mock_error_type', 300);
export const MOCK_PBR_ERROR_FOUR = new Error('44444', new Date().getFullYear(), 'mock_error_type', 400);

export const MOCK_PBR_ALL_ERRORS = [
  MOCK_PBR_ERROR_ONE,
  MOCK_PBR_ERROR_TWO,
  MOCK_PBR_ERROR_THREE,
  MOCK_PBR_ERROR_FOUR
];
