export const NAV_LIST: any = {
    navigationItems: [
      { name: 'INTERROGATORIES', target: 'INTERROGATORIES', subNav: [] },
      { name: 'IN-EXCHANGE (INDIVIDUAL)', target: '', subNav: [
        { name: 'POLICY ADMINISTRATION', target: 'INEXCHANGEINDIVIDUALPOLICYADMINISTRATION', subNav: [] },
        { name: 'PRIOR AUTHORIZATIONS (PROSPECTIVE UTILIZATION REVIEW REQUESTS) EXCLUDING PHARMACY', target: 'INEXCHANGEINDIVIDUALPRIORAUTHORIZATIONSEXCLUDINGPHARMACY', subNav: []},
        { name: 'PRIOR AUTHORIZATIONS (PROSPECTIVE UTILIZATION REVIEW REQUESTS) - PHARMACY ONLY', target: 'INEXCHANGEINDIVIDUALPRIORAUTHORIZATIONSPHARMACYONLY', subNav: [] },
        { name: 'CLAIMS ADMINISTRATION (EXCLUDING PHARMACY)', target: 'INEXCHANGEINDIVIDUALCLAIMSADMINISTRATIONEXCLUDINGPHARMACY', subNav: [] },
        { name: 'CLAIMS ADMINISTRATION (PHARMACY ONLY)', target: 'INEXCHANGEINDIVIDUALCLAIMSADMINISTRATIONPHARMACYONLY', subNav: [] },
        { name: 'CONSUMER REQUESTED INTERNAL REVIEWS (GRIEVANCES - INCLUDING PHARMACY)', target: 'INEXCHANGEINDIVIDUALCONSUMERREQUESTEDINTERNALREVIEWSGRIEVANCESINCLUDINGPHARMACY', subNav: [] },
        { name: 'CONSUMER REQUESTED EXTERNAL REVIEWS (INCLUDING PHARMACY)', target: 'INEXCHANGEINDIVIDUALCONSUMERREQUESTEDEXTERNALREVIEWSINCLUDINGPHARMACY', subNav: [] },
        { name: 'Summary', target: '', subNav: [] }]
      }
    ]
  }