<div *ngIf="files.length === 0" draggable="true" ngClass="{{dragAreaClass}}">
  <div class="row">
    <div class="col-md-12 text-center">
      <a href="javascript:void(0)" (click)="file.click()">
        Click to browse
      </a>
      or drag & drop to upload your file
      <input type="file" #file [multiple]="(maxFiles > 1)" (change)="onFileChange($event)" style="display:none" />
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-12 text-center">
    <button id="btnFileUpload" class="btn btn-primary" (click)="getPresignedURLFromS3()" [disabled]="!readyForUpload()" type="button">Upload</button>
    <button id="btnFileCancel" class="btn btn-secondary" (click)="cancel()" [disabled]="!readyForUpload()" type="button">Cancel</button>
  </div>
</div>

<br/>

<div class="row" *ngIf="isFileUploading">
  <div class="col-12">
    <h4 class="text-center">Uploading {{uploadingFileName}}</h4>
    <p><progressbar type="info" [value]="uploadPercentage" [striped]="true" [animate]="true">{{uploadPercentage}}%</progressbar></p>
  </div>
</div>
