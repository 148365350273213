import { Component, OnDestroy, OnInit } from '@angular/core';
import { RoleconfigService } from './roleconfig.service';
import { UserService } from '../service/user.service';
import { Router } from '@angular/router';
import { MessageService } from '../messages/service/message.service';
import { User } from '../model/user';
import { Subscription } from 'rxjs';
import { Roleconfig } from './roleconfig';
import { AlertMessage } from '../messages/model/alert-message';
import { SchemaSelectionService } from '../service/schema-selection.service';
import { DatacallSettingService } from './datacall-setting/services/datacall-setting.service';
import { DatacallSettingEnum } from './datacall-setting/model/datacall-setting.enum';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-roleconfig',
  templateUrl: './roleconfig.component.html'
})
export class RoleconfigComponent implements OnInit, OnDestroy {
  datacallSetupConfigurationsForm: FormGroup;

  schema: string;
  userRole: string;
  adminRole: string;
  rejectSubmission: boolean;

  user: User;
  title: string;
  error: boolean;
  loading: boolean;
  subscriptions: Subscription[];
  data: Roleconfig[];
  private initialUserRole: string;
  private initialAdminRole: string;
  private initialRejectSubmission: any;
  datacallSetting: any;
  datacallSettings: any;
  updatedDatacallSetting: any;
  model: any = {};
  changedForm: any;
  initialForm: any;
  testDatacallSettings: boolean;
  settingValue: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private messageService: MessageService,
    private roleconfigService: RoleconfigService,
    private schemaSelectionService: SchemaSelectionService,
    private datacallSettingService: DatacallSettingService,
    private formBuilder: FormBuilder
  ) {
    this.error = false;
    this.loading = true;
    this.subscriptions = [];
    this.model = {
      userRole: '',
      adminRole: '',
      submissionSettings: []
    }
  }

  ngOnInit() {
    this.user = this.userService.getStoredUser();
    this.schema = this.user.currentSchema.toLocaleUpperCase();
    this.title = `${this.schema} Datacall Setup Configurations`;
    this.getRoleConfigs();
    this.messageService.clear();

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.messageService.clear();
  }

  getDatacallSettings(): void {
    this.loading = true;
    const subscription = this.datacallSettingService.getDatacallSettings(this.user.currentSchema).subscribe(
      res => {
        this.datacallSettings = res;
        this.testDatacallSettings = true;
        this.setDatacallSettings();
        this.loading = false;
      }
    );
    this.subscriptions.push(subscription);
  }

  setDatacallSettings(): void {
    const settings = [
      DatacallSettingEnum.rejectSubmission,
      DatacallSettingEnum.copyToReportingDB,
      DatacallSettingEnum.userAssignment,
      DatacallSettingEnum.notificationOnSubmission,
      DatacallSettingEnum.notificationOnSubmissionEmailAddress];
    this.datacallSettings = this.datacallSettings.filter(x => settings.includes(x.name));
    this.datacallSettings.forEach(y => {
      if (y.name !== DatacallSettingEnum.notificationOnSubmissionEmailAddress) {
        if (y.value === 'true') {
          y.value = true;
        } else {
          y.value = false;
        }
      } else {
        y.value = y.value;
      }
    }
    );
    this.datacallSettingService.setInitialDatacallSetting(this.datacallSettings);
  }

  getRoleConfigs(): void {
    this.loading = true;
    const subscription = this.roleconfigService.getRoleconfigs(this.user.currentSchema).subscribe(
      response => {
        this.error = false;
        this.data = response;
        this.setData();
        this.getDatacallSettings();
      }
    );
    this.subscriptions.push(subscription);
  }

  setData(): void {
    const maybeUserRole: Roleconfig = this.data.find(c => c.systemRole === 'user');
    this.initialUserRole = maybeUserRole ? maybeUserRole.role : '';
    this.userRole = this.initialUserRole;
    const maybeAdminRole: Roleconfig = this.data.find(c => c.systemRole === 'admindatacall');
    this.initialAdminRole = maybeAdminRole ? maybeAdminRole.role : '';
    this.adminRole = this.initialAdminRole;
    this.setFormData();
  }

  setFormData() {
    this.datacallSetupConfigurationsForm = this.formBuilder.group({
      userRole: this.formBuilder.group({
        role: this.userRole,
        systemRole: 'user'
      }),
      adminRole: this.formBuilder.group({
        role: this.adminRole,
        systemRole: 'admindatacall'
      }),
      submissionSettings: this.formBuilder.array([])
    });
    this.getValueChanges();
  }

  getValueChanges() {
    this.datacallSetupConfigurationsForm.valueChanges.subscribe(x => {
      this.changedForm = x;
    });
  }

  disableSave(): boolean {
    if (JSON.stringify(this.datacallSettingService.getSubmissionSettingsForm()) === JSON.stringify(this.changedForm)) {
      return true;
    } else {
      return false;
    }
  }

  reset(): void {
    this.initialForm = this.datacallSettingService.getSubmissionSettingsForm();
    this.datacallSetupConfigurationsForm.reset(this.initialForm);
  }

  public save() {
    this.initialForm = this.datacallSettingService.getSubmissionSettingsForm();
    this.messageService.clear();
    this.saveUserRole();
    this.saveAdminRole();
    this.saveDatacallSetting();
  }

  saveUserRole() {
    if (JSON.stringify(this.changedForm.userRole) !== JSON.stringify(this.initialForm.userRole)) {
      this.roleconfigService.putRoleconfig(this.schema, this.changedForm.userRole).subscribe(
        response => {
          const from = this.initialForm.userRole.role;
          this.initialForm = this.datacallSetupConfigurationsForm.getRawValue();
          this.datacallSettingService.setSubmissionSettingForm(this.initialForm);
          this.messageService.add(new AlertMessage(`Changed User Role from ${from} to ${this.changedForm.userRole.role}`, 'success'));
        },
        error => {
          this.messageService.add(new AlertMessage('Encountered error changing the role.', 'failure'));
        }
      );
    }
  }

  saveAdminRole() {
    if (JSON.stringify(this.changedForm.adminRole) !== JSON.stringify(this.initialForm.adminRole)) {
      this.roleconfigService.putRoleconfig(this.schema, this.changedForm.adminRole).subscribe(
        response => {
          const from = this.initialForm.adminRole.role;
          this.initialForm = this.datacallSetupConfigurationsForm.getRawValue();
          this.datacallSettingService.setSubmissionSettingForm(this.initialForm);
          this.messageService.add(new AlertMessage(`Changed Admin Role from ${from} to ${this.changedForm.adminRole.role}`, 'success'));
        },
        error => {
          this.messageService.add(new AlertMessage('Encountered error changing the role.', 'failure'));
        }
      );
    }
  }

  saveDatacallSetting() {
    let updated: any[] = this.changedForm.submissionSettings;
    let initial: any[] = this.initialForm.submissionSettings;
    let datacallSetting: any[];
    datacallSetting = updated.filter(this.compareArrayList(initial));
    if (datacallSetting.length === 0) {
    } else {
      this.datacallSettingService.createDatacallSetting(this.schema, datacallSetting).subscribe(
        response => {
          this.getDatacallSettingValue();
          this.initialForm = this.datacallSetupConfigurationsForm.getRawValue();
          this.datacallSettingService.setSubmissionSettingForm(this.initialForm);
          let submissionSettings = this.datacallSettingService.getSubmissionSettingsForm();
          this.setCopyToReportingDBSetting(submissionSettings);
          this.messageService.add(new AlertMessage(`Submission Setting Updated`, 'success'));
        },
        error => {
          this.messageService.add(new AlertMessage('Encountered error updating the Reject Submission Setting.', 'failure'));
        }
      )
    }

  }

  setCopyToReportingDBSetting(submissionSettings) {
    let settingName = DatacallSettingEnum.copyToReportingDB;
    submissionSettings.submissionSettings.forEach(element => {
      if(element.name === settingName) {
        this.settingValue = element.value;
      } 
    });
    this.datacallSettingService.iscopyToReportingDBSetting = this.settingValue;
  }

  getDatacallSettingValue(): void {
    let name = DatacallSettingEnum.userAssignment;
    this.datacallSettingService.getDatacallSettingName(this.user.currentSchema, name).subscribe(
      res => {
        if (res === true) {
          this.datacallSettingService.showUserAssignmentsLink = true;
        } else {
          this.datacallSettingService.showUserAssignmentsLink = false;
        }
        this.schemaSelectionService.selectedSchemaAfterDisclaimerAcceptance.next(this.user.currentSchema);
      }
    )
  }

  compareArrayList(array): any {
    return first => {
      return array.filter(second => {
        return second.name == first.name && second.value == first.value
      }).length == 0;
    }
  }

}
