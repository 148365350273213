/*
 * Copyright 2018 National Association of Insurance Commissioners
 */

export const MOCK_FILING_BLANK_LAYOUT = {
  sections: [
    {
      key: "New Section",
      name: "New Section",
      sectionNumber: 0,
      sections: [],
      questions: [
        {
          key: "1",
          text: "What is the best suburb of KC?",
          inputs: [
            {
              key: "Disabled",
              label: "Suburb",
              dataType: null,
              controlType: "",
              options: [],
              disabled: true,
              value: null,
              required: false,
              warnings: [],
              errors: [],
              minLength: null,
              maxLength: null,
              pattern: null,
              min: null,
              max: null,
              anyOf: null,
            },
          ],
        },
      ],
    },
  ],
  questions: [],
};

export const MOCK_FILING_BLANK_WITH_HTTP_HEADERS: string = '{"headers":{"normalizedNames":{},"lazyUpdate":null,"headers":{}},"status":200,"statusText":"OK","url":"/rdc/rest/test/filingblanklayout/generatedBlank","ok":true,"type":4,"body":{"sections":[{"key":"New Section","name":"New Section","sectionNumber":0,"sections":[],"questions":[{"key":"1","text":"What is the best suburb of KC?","inputs":[{"key":"Disabled","label":"Suburb","dataType":null,"controlType":"","options":[],"disabled":true,"value":null,"required":false,"warnings":[],"errors":[],"minLength":null,"maxLength":null,"pattern":null,"min":null,"max":null,"anyOf":null}]}]}],"questions":[]}}'
