import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FileAdminComponent} from './file-admin.component';
import {ErrorsComponent} from './errors/errors.component';
import {ErrorTypeComponent} from './error-type/error-type.component';

const fileAdminRoutes: Routes = [
  {path: 'fileAdmin', component: FileAdminComponent},
  {path: 'fileAdminErrors', component: ErrorsComponent},
  {path: 'fileAdminErrorType', component: ErrorTypeComponent}
];

@NgModule({
  imports: [
    RouterModule.forChild(fileAdminRoutes)
  ],
  exports: [RouterModule]
})
export class FileAdminRoutingModule { }
