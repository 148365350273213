<ngx-loading [show]="loading"></ngx-loading>
<div *ngIf="user" id="schema-selection" class="wrapper">

  <form [formGroup]="fromUser">

    <div class="row">
      <div class="col-12 page-title">
        <h1>Datacall Selection</h1>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-12">
        <app-messages></app-messages>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-12">
        <label id="datacall-group-label" class="col-2 font-weight-normal text-right">Select datacall group:</label>
        <select id="datacall-group" class="col-3 h-100 rounded" formControlName="DatacallGroup" (change)="onChangeDatacallGroup($event);">
          <option value="">--Select--</option>
          <option *ngFor="let datacallGroup of user.dataCallGroups" [value]="datacallGroup.groupName">{{datacallGroup.groupName | uppercase}}</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label id="datacall-schema-label" class="col-2 font-weight-normal text-right">Select datacall:</label>
        <select id="datacall-schema" class="col-3 mr-3 h-100 rounded" formControlName="DatacallSchema" (change)="goToDisclaimer($event);">
          <option value="">--Select--</option>
          <option *ngFor="let datacallSchema of datacallSchemas" [value]="datacallSchema.dataCallName">{{datacallSchema.dataCallName | uppercase}}</option>
        </select>
        <span *ngIf="user.isRdcAdmin">
          <span class="mr-3">or</span>
          <button id="create-datacall-button" type="button" class="btn btn-primary align-top" [disabled]="selectedDatacallGroup===''" (click)="openCreateDatacall(selectedDatacallGroup)">Create Datacall</button>
        </span>
      </div>
    </div>
  </form>

</div>
