import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoadingModule } from 'ngx-loading';
import { SubmissionLevelTolerancesService } from './services/submission-level-tolerances.service';
import { SubmissionLevelTolerancesRoutingModule } from './submission-level-tolerances-routing.module';
import { SubmissionLevelTolerancesComponent } from './submission-level-tolerances.component';
import { MessagesModule } from '../messages/messages.module';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AgGridModule } from 'ag-grid-angular';
import { DateRendererComponent } from '../cell-renderer/date-renderer/date-renderer.component';
import { NumberRendererComponent } from '../cell-renderer/number-renderer/number-renderer.component';

@NgModule({
  imports: [
    CommonModule,
    AgGridModule.withComponents([DateRendererComponent, NumberRendererComponent]),
    FormsModule,
    RouterModule,
    MessagesModule,
    LoadingModule,
    AlertModule.forRoot(),
    SubmissionLevelTolerancesRoutingModule,
  ],
  declarations: [SubmissionLevelTolerancesComponent],
  providers: [SubmissionLevelTolerancesService],
  exports: [SubmissionLevelTolerancesComponent]
})
export class SubmissionLevelTolerancesModule { }
