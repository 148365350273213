import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription, Subject} from 'rxjs';
import {Disclaimer} from '../model/disclaimer';
import {User} from '../model/user';
import {DisclaimerService} from './service/disclaimer.service';

import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../service/user.service';
import {MessageService} from '../messages/service/message.service';
import {SchemaSelectionService} from '../service/schema-selection.service';
import {DatacallSettingService} from '../roleconfig/datacall-setting/services/datacall-setting.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html'
})
export class DisclaimerComponent implements OnInit, OnDestroy {
  disclaimer: Disclaimer;
  private _message = new Subject<string>();
  loading: boolean = false;
  messageText: string;
  error: boolean = false;
  title: string;
  user: User;
  subscriptions: Array<Subscription> = [];
  userView: boolean;
  schema: string;

  constructor(
    private disclaimerService: DisclaimerService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private messageService: MessageService,
    private schemaSelectionService: SchemaSelectionService,
    private datacallSettingService: DatacallSettingService
  ) {
  }

  ngOnInit() {
    this.schema = this.route.snapshot.paramMap.get('schema');
    this.user = this.userService.getStoredUser();
    this.userView = this.userService.canViewFileDashboard(this.user);
    this.title = `${this.user.currentSchema.toUpperCase()} Legal Disclaimer`
    this.getDisclaimer(this.schema);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.messageService.clear();
  }

  getDisclaimer(schema: string) {
    this.loading = true;
    const sub = this.disclaimerService.getDisclaimer(schema)
      .subscribe(
        disclaimer => {
          this.disclaimer = disclaimer;
          if (!disclaimer.needsAcceptance) {
            this.error = false;
            this.schemaSelectionService.selectedSchemaAfterDisclaimerAcceptance.next(this.user.currentSchema);
            this.userService.setStoredUser(this.user);
            this.navigateToLandingOrOnlineDataEntry();
          } else {
            this.schemaSelectionService.selectedSchemaBeforeDisclaimerAcceptance.next(this.user.currentSchema);
          }
          this.loading = false;
        }
      );
    this.subscriptions.push(sub);
  }

  navigateToLandingOrOnlineDataEntry() {
    if (this.datacallSettingService.getOnlineDataEntrySetting() && this.userView) {
      this.router.navigate(['user-filing', this.schema]);
    } else {
      this.router.navigate(['landing']);
    }
  }

  agree() {
    const sub = this.disclaimerService.agree(this.user.currentSchema)
      .subscribe(
        () => {
          this.error = false;
          this.schemaSelectionService.selectedSchemaAfterDisclaimerAcceptance.next(this.user.currentSchema);
          this.userService.setStoredUser(this.user);
          this.disclaimer.needsAcceptance = false;
          this.navigateToLandingOrOnlineDataEntry();
        }
      );
    this.subscriptions.push(sub);
  }

}
