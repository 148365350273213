<ngx-loading [show]="loading"></ngx-loading>
<div class="wrapper">
  <div id="disclaimer">
    <div class="row">
      <div class="col-12 page-title">
        <h1>{{schema}} Filing Search</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <app-messages></app-messages>
      </div>
    </div>


    <div *ngIf="assignmentKeyFieldName" class="row mb-3">
      <div class="col-12">
        <ul class="list-inline">
          <li class="list-inline-item">
            <label hidden for="filingSearchInput">Filing Search Input</label>
            <input id="filingSearchInput" type="text"
                   class="form-control"
                   [(ngModel)]="searchTerm"/>
          </li>
          <li class="list-inline-item">
            <button id="btn:search" type="button" class="btn btn-primary" (click)="searchForFilings()"><i
              class="fa fa-search"></i> Search
            </button>
          </li>
        </ul>
        <ul id="keyOrUserSelection" class="list-inline">
          <li class="list-inline-item">
            <label><input id="assignment-key-input" type="radio" (change)="selectFilingSearchMethod($event)"
                          name="options"
                          [checked]="searchByAssignmentKey"> {{assignmentKeyFieldName}}
            </label>
          </li>
          <li class="list-inline-item">
            <label><input id="user-id" type="radio" name="options"
                          (change)="selectFilingSearchMethod($event)"> User Id</label>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-12 page-title">
      <h2>Filing Matrix</h2>
    </div>

    <div *ngIf="rowData.length > 0; else noData">
      <ag-grid-angular
        style="width: 100%; height: 400px;"
        #agGrid
        id="online-filings-table"
        class="ag-theme-balham"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [gridOptions]="gridOptions"
        (gridReady)="onGridReady($event)"
        [rowData]="rowData"
        [context]="context"
        [frameworkComponents]="frameworkComponents">
      </ag-grid-angular>
    </div>

    <ng-template #noData>
      <div class="d-flex justify-content-center align-items-center vh-25">
        <div class="text-center">
          <p *ngIf=!loading>No filings available/assigned</p>
        </div>
      </div>
    </ng-template>
  </div>
</div>

