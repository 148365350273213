import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import { CocodeModel } from '../model/cocode-model';
import {Credible} from '../model/credible';
import {FileSubmission} from '../../../model/file-submission';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {HandleError, HttpErrorHandler} from '../../../shared/http-error-handler.service';
import {catchError} from 'rxjs/operators';

@Injectable()
export class CredibleService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('CredibleService');
  }


  getCredibleData(fileSubmission: FileSubmission): Observable<Credible> {
    const params = new HttpParams().set('fileSubmissionId', fileSubmission.id);
    const options = {params: params};
    return this.httpClient.get<Credible>(`${environment.apiUrl}${fileSubmission.schemaType}/credibleData`, options)
      .pipe(catchError(this.handleError('getCredibleData', null)));

  }

  putCredibleData(schemaType: string, credibleData: Credible): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {headers: headers};
    return this.httpClient.put(`${environment.apiUrl}${schemaType}/credibleData`, JSON.stringify(credibleData), options)
      .pipe(catchError(this.handleError('putCredibleData', null)));

  }

  isAllChecked(cocodeModel: CocodeModel): boolean {
    let allChecked = 0;
    cocodeModel.planCodes.forEach(planCode => {
      allChecked = planCode.checked ? allChecked + 1 : allChecked;
    });
    return allChecked === cocodeModel.planCodes.length;
  }
}
