import { Directive, ElementRef, HostListener } from '@angular/core';
import { AbstractControl, NgControl, NG_VALIDATORS, Validator } from '@angular/forms';
import * as _ from 'lodash';

@Directive({
  selector: '[appNoSpace][formControlName],[appNoSpace][formControl],[appNoSpace][ngModel], input[appNoSpace]',
})
export class NoSpaceDirective {

  private regex = new RegExp(/^[A-Z_][A-Z0-9_]*$/);
  private maxLength = 40;

  constructor(private el: ElementRef, private ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = this.el.nativeElement;
    let currentValue = input.value.toUpperCase();

    // Prevent if input starts with a number and underscore
    while (/^[0-9_]/.test(currentValue)) {
      currentValue = currentValue.substring(1);
    }
    
    // Remove any invalid characters
    currentValue = currentValue.replace(/[^A-Z0-9_]/g, '');

    // No spaces allowed
    currentValue = currentValue.replace(/\s/g, '');

    // Trim to the maximum length
    if (currentValue.length > this.maxLength) {
      currentValue = currentValue.slice(0, this.maxLength);
    }

    // Update the value in the input element and model
    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.setValue(currentValue, { emitEvent: false });
    }
    input.value = currentValue;
  }

  @HostListener('paste', ['$event'])
  handlePaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    let pastedText = clipboardData ? clipboardData.getData('text').toUpperCase() : '';
    let currentValue = '';

    if (this.ngControl && this.ngControl.control) {
      currentValue = this.ngControl.control.value || '';
    }

    // Concatenate the pasted text
    currentValue = (currentValue + pastedText).toUpperCase();
    // Prevent if input starts with a number and underscore
    while (/^[0-9_]/.test(currentValue)) {
      currentValue = currentValue.substring(1);
    }
    
    // Remove any invalid characters
    currentValue = currentValue.replace(/[^A-Z0-9_]/g, '');

    // No spaces allowed
    currentValue = currentValue.replace(/\s/g, '');

    // Trim to the maximum length
    if (currentValue.length > this.maxLength) {
      currentValue = currentValue.slice(0, this.maxLength);
    }

    // Prevent the default paste action and update the value
    event.preventDefault();
    if (this.ngControl && this.ngControl.control) {
      this.ngControl.control.setValue(currentValue, { emitEvent: false });
    }
  }
}