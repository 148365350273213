import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SubmissionKeys} from '../../model/submission-keys';
import {User} from '../../model/user';
import {environment} from '../../../environments/environment';


import {HandleError, HttpErrorHandler} from '../../shared/http-error-handler.service';
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';

@Injectable()
export class SubmissionKeysService {
  readonly handleError: HandleError;

  constructor(
    private httpClient: HttpClient,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.handleError = httpErrorHandler.createHandleError('AggregationMaintenanceService');
  }

  getKeys(user: User): Observable<SubmissionKeys> {
    const url = `${environment.apiUrl}${user.currentSchema}/validationschema/current/properties?submissionKey=true`;
    return this.httpClient.get<SubmissionKeys>(url)
      .pipe(catchError(this.handleError('getKeys', null)));
  }

}
