import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription ,  Subject } from 'rxjs';
import { FileSubmission } from '../../model/file-submission';
import { NotesService } from './service/notes.service';
import { FileSubmissionStateModel } from '../update-status/model/file-submission-state-model';
import { FileSubmissionState } from '../../file-dashboard/model/file-submission-state.enum';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit, OnDestroy {
  fileSubmission: FileSubmission;
  title: string;
  private _message = new Subject<string>();
  messageText: string;
  error = false;
  scrollBarHorizontal = (window.innerWidth < 1200);
  subscriptions: Array<Subscription> = [];

  rows = [];
  columns = [];
  allColumns = [];
  loadingIndicator = true;

  constructor(private notesService: NotesService,
              public bsModalRef: BsModalRef,
              ) {
    window.onresize = () => {
      this.scrollBarHorizontal = (window.innerWidth < 12000);
    };
  }

  ngOnInit() {
    this._message.subscribe(message => this.messageText = message);
    this.title = `Submission Notes for ${this.fileSubmission.originalFileName}`;
    if (this.fileSubmission.notes && this.fileSubmission.notes.length > 0) {
      this.setData();
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  addNote() {
    const fileSubmissionStateData: FileSubmissionStateModel = {
      fileSubmissionId: this.fileSubmission.id,
      fileSubmissionState: FileSubmissionState.fileReceived,
      fileSubmissionComments: this.fileSubmission.comments
    };
    const sub = this.notesService.updateFileSubmissionNotes(this.fileSubmission.schemaType, fileSubmissionStateData)
      .subscribe(
        () => {
          this.error = false;
          this.messageText = 'Note added';
          this.bsModalRef.hide();
        },
        error => {
          this.error = true;
          this.messageText = <any>error;
          this._message.next(`${this.messageText}`);
        }
      );
    this.subscriptions.push(sub);
  }

  setData() {
    const data = this.getNoteData();
    this.columns = [];
    this.allColumns = [];
    this.rows = [];
    _.keys(data[0]).forEach((key, i) => {
      const column = {};
      column['name'] = key;
      column['index'] = i;
      this.columns.push(column);
      this.allColumns.push(column);
    });
    this.columns = [...this.columns];
    this.allColumns = [...this.allColumns];
    data.forEach(note => {
      this.rows.push(note);
    });
    this.rows = [...this.rows];
  }

  getNoteData(): Array<any> {
    const noteData: Array<any> = [];
    this.fileSubmission.notes.forEach(note => {
      const data = {};
      data['date'] = note.modified;
      data['user'] = note.updateBy;
      data['note'] = note.note;
      noteData.push(data);
    });
    return noteData;
  }

}
