import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipesModule } from '../../pipes/pipes.module';
import { SharedModule } from '../../shared/shared.module';
import { SchemaPropertyViewComponent } from './schema-property-view.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import {MessagesModule} from '../../messages/messages.module';
import { LoadingModule } from 'ngx-loading';
import { AlertModule } from 'ngx-bootstrap/alert';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    MessagesModule,
    TooltipModule.forRoot(),
    SharedModule,
    PipesModule,
    LoadingModule,
    AlertModule.forRoot()
  ],
  declarations: [SchemaPropertyViewComponent],
})
export class SchemaPropertyViewModule {
}
