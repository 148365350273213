import { ErrorHandler, Injectable } from '@angular/core';
import { ExpressionBuilderService } from '../cross-field-validation-maintenance/expression-builder/services/expression-builder.service';
import { AlertMessage } from '../messages/model/alert-message';
import { MessageService } from '../messages/service/message.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private messageService: MessageService,
    private expressionBuilderService: ExpressionBuilderService
  ) { }

  handleError(error) {
    let errorMessage: string = String(error);
    if (String(errorMessage).includes("SyntaxError:")) {
      if (this.expressionBuilderService.validateExpressionFromCrossFieldEdit) {
        this.messageService.add(new AlertMessage("The expression string is not currently supported by expression builder or expression is invalid. ", 'danger'));
      } else if (this.expressionBuilderService.validateExpressionFromEB) {
        this.messageService.add(new AlertMessage("Entered condition is invalid ", 'danger'));
      }
      console.error(error);
    } else if (String(errorMessage).includes("TypeError:")) {
      if (this.expressionBuilderService.validateExpressionFromCrossFieldEdit) {
        this.messageService.add(new AlertMessage("The expression string is not currently supported by expression builder or expression is invalid. ", 'danger'));
      }
      console.error(error);
    } else {
      console.error(error);
    }

    // this.messageService.add(new AlertMessage(error,"danger"));
  }
}