<div *ngIf="user" id="datacall-creation" class="wrapper">

  <form [formGroup]="datacallForm">

    <div>
      <div class="modal-header">
        <h3 class="modal-title">Add New Datacall</h3>
      </div>

      <div class="row mb-3">
        <alert *ngIf="messageText" class="col-12" type="warning">
          <span id="alert:message">{{messageText}}</span>
        </alert>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <label for="datacall-group" class="col-4 text-right">Datacall Group:</label>
            <span id="datacall-group">{{dataCallGroupFromParent | uppercase}}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-12 align-top">
            <label for="datacall-name" class="col-4 text-right">Datacall Name:</label>
            <input id="datacall-name" type="text" formControlName="datacallName" appDatacallNameValidator/>
            <div class="row" style="padding-left: 16rem !important;" *ngIf="datacallForm.get('datacallName').invalid && (datacallForm.get('datacallName').dirty || datacallForm.get('datacallName').touched)">
              <div *ngIf="datacallForm.get('datacallName').errors?.noANSIWords"><span class="font-italic small" style="color: #dc3545!important;">Schema property name '{{datacallForm.get('datacallName').value}}' is a reserved word which cannot be used.</span></div>
            </div>
          </div>
        </div>

        <div class="row" style="padding-left: 16rem !important;">
          <div class="col-4"></div>
          <span class="font-italic small">***Value must be unique, and can contain alphanumeric, or underscores, but cannot start with number or have special characters.</span>
        </div>

        <div class="row">
          <div class="col-12 align-top">
            <label for="datacall-display-name" class="col-4 text-right">Datacall Display Name:</label>
            <input id="datacall-display-name" type="text" formControlName="datacallDisplayName"/>
          </div>
        </div>

        <div class="row">
          <div class="col-12 align-top">
            <label for="online-data-entry" class="col-4 text-right">Online Data Entry:</label>
            <input id="online-data-entry" type="checkbox" formControlName="isOnlineDataEntry"/>
          </div>
        </div>

        <div class="row" *ngIf="!datacallForm.controls.isOnlineDataEntry.value">
          <div class="col-12">
            <label for="datacall-file-format" class="col-4 text-right">File Format for Datacall:</label>
            <select id="datacall-file-format" class="col-2 align-top" formControlName="fileFormatType">
              <option value="XLSX" selected>XLSX</option>
              <option value="CSV">CSV</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label for="datacall-userid" class="col-4 text-right">Responsible Party User ID:</label>
            <input id="datacall-userid" type="text" class="mr-2" size="40" formControlName="userId" pattern="[a-zA-Z\d.@_-]+$"/>
            <button id="datacall-userid-add-btn" class="btn btn-primary align-bottom" type="button" [disabled]="datacallForm.controls.userId.invalid || datacallForm.controls.userId.value ===''"
              (click)="addUserIdToList()">Add</button>
          </div>
        </div>

        <div class="row">
          <div class="col-12 pb-3">
            <label for="datacall-userid-list" class="col-4 text-right">Added User IDs:</label>
            <select id="datacall-userid-list" class="col-3 align-top mr-2" formControlName="userIdList" size="3" >
              <option *ngFor="let userId of userIdList" [value]="userId" (click)="setSelectedUserId($event)">{{userId}}</option>
            </select>
            <button id="datacall-userid-delete-btn" class="btn btn-primary align-bottom" type="button" (click)="deleteUserIdFromList()" [disabled]="selectedUserId === ''">Delete</button>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <label for="adminRoleField" class="col-4 text-right">Administration Group Role:</label>
            <input id="adminRoleField" formControlName="adminRole" type="text" class="col-3" pattern="[a-zA-Z0-9-_]*"/>
          </div>
        </div>

        <div class="row">
          <div class="col-12 pb-3">
            <label for="userRoleField" class="col-4 text-right">User Group Role:</label>
            <input id="userRoleField" formControlName="userRole"  type="text" class="col-3" pattern="[a-zA-Z0-9-_]*"/>
          </div>
        </div>

      </div>

      <div class="modal-footer">
        <button id="datacall-cancel-btn" class="btn btn-primary" type="button" (click)="bsModalRef.hide()">Cancel</button>
        <button id="datacall-save-btn" class="ml-2 btn btn-primary" type="submit" (click)="save()" [disabled]="datacallForm.invalid" >Save</button>
      </div>
    </div>

  </form>
</div>
