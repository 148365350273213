<div id="datafieldFileUploadHeader" class="modal-header">
    <h4 id="datafieldFileUploadTitle" class="modal-title">Upload File</h4>
    <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="row mb-1">
    <alert class="col-12" type="info" [dismissible]="false">
        <span id="alert:info">Uploading a file will erase and replace all the current field definitions.</span>
    </alert>
</div>

<div class="row mb-1">
    <alert *ngIf="messageText" class="col-12" type="{{alertType}}">
        <span id="alert:message">{{messageText}}</span>
    </alert>
</div>

<div class="row mb-1" *ngIf="errorResponse">
    <div class="col-12" >
        <ul class="list-style-type:square" style="overflow-y: scroll; overflow-x: hidden; height: 5rem;">
            <li *ngFor="let errori of fileUpload.errors">&bull;&nbsp;{{errori}}</li>
        </ul>
    </div>
</div>

<form name="datafieldFileUploadForm" (ngSubmit)="finishUpload()" #datafieldFileUploadForm="ngForm">
    <div id="datafieldFileUploadBody" class="modal-body">
        <div *ngIf="files.length === 0" draggable="true" ngClass="{{dragAreaClass}}">
            <div class="row">
                <div class="col-md-12 text-center">
                    <a href="javascript:void(0)" (click)="file.click()">
                        Click to browse
                    </a>
                    or drag & drop to upload your file
                    <input type="file" #file [multiple]="(maxFiles > 1)" (change)="onFileChange($event)"
                        style="display:none" />
                </div>
            </div>
        </div>
        <br />
        <div class="row" *ngIf="isFileUploading">
            <div class="col-12">
                <h4 class="text-center">Uploading {{uploadingFileName}}</h4>
                <p>
                    <progressbar type="info" [value]="uploadPercentage" [striped]="true" [animate]="true">
                        {{uploadPercentage}}%</progressbar>
                </p>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 text-center">
                <button id="btn:datafieldFileUpload" class="btn btn-primary" (click)="uploadFile()"
                    [disabled]="!readyForUpload()" type="button">Upload</button> &nbsp;
                <button id="btn:datafieldFileUpload-cancel" class="btn btn-secondary" (click)="cancel()"
                    [disabled]="!readyForUpload()" type="button">Cancel Upload</button>
            </div>
        </div>


    </div>

</form>