<ngx-loading [show]="loading"></ngx-loading>
<div id="rerun-validations" class="wrapper">
  <div class="row page-title">
    <div class="col-12">
      <h1>{{title}}</h1>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <app-messages></app-messages>
    </div>
  </div>

  <div *ngIf="submissionKeys" class="row mb-3">
    <div class="col-12">
      <ul class="list-inline">
        <li class="list-inline-item" *ngFor="let submissionKey of submissionKeyList">
          <label for="input:{{submissionKey.name}}">{{submissionKey.name | uppercase | noUnderscore}}</label>
          <input id="input:{{submissionKey.name}}" type="text" class="form-control" [(ngModel)]="submissionKey.values[0]"
                 required/>
        </li>
        <li class="list-inline-item">
          <button id="btn:search" type="button" class="btn btn-primary" (click)="getFileList()"><i
            class="fa fa-search"></i> Search
          </button>
        </li>
      </ul>
    </div>
  </div>


  <div *ngIf="fileList && fileList.length > 0" class="row mb-3">
    <div class="col-12">
      <ag-grid-angular
        style="width: 100%; height: 400px;"
        #agGrid
        id="rerunValidationsGrid"
        class="ag-theme-balham"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="rowData"
        [context]="context"
        [frameworkComponents]="frameworkComponents"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>
</div>
