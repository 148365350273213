import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
  selector: 'app-assignment-value-renderer',
  templateUrl: './assignment-value-renderer.component.html',
  styleUrls: ['./assignment-value-renderer.component.css']
})
export class AssignmentValueRenderer implements ICellRendererAngularComp {
  params: any;
  assignmentValue: any;

  agInit(params: any): void {
    this.params = params;
    this.assignmentValue = this.params.value;
  }

  refresh(): boolean {
    return false;
  }

  goToOnlineDataEntry(assignmentValue) {
    this.params.context.componentParent.goToOnlineDataEntry(assignmentValue);
  }

}
