import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { FileService } from '../../service/file.service';

@Component({
  selector: 'app-exceptions-renderer',
  templateUrl: './exceptions-renderer.component.html',
  styleUrls: ['./exceptions-renderer.component.css']
})
export class ExceptionsRendererComponent implements ICellRendererAngularComp {
  params: any;
  fileNameType: string;

  constructor(
    private fileService: FileService
  ) {}

  agInit(params: any): void {
    this.params = params;
  }

  goToErrors(data: any) {
    this.fileService.setFormFormatErrorTypeCounts(data.exceptions.formFormatErrorTypeCounts);
    this.fileService.setCrossFieldErrorTypeCounts(data.exceptions.crossFieldErrorTypeCounts);
    // Fetching the first file level exception type as we currently have only one File level Validation type (RECORDS_IN_FILE).
    this.fileService.setFileLevelExceptionTypes(data.exceptions.fileLevelExceptionTypes ? data.exceptions.fileLevelExceptionTypes[0] : null);
    this.params.context.componentParent.goToFileSubmissionErrors(data);
  }

  refresh(): boolean {
    return false;
  }

  isRejected(status: string): boolean {
    if (status !== 'SYSTEM_REJECTED_FILE') {
      const re = /rejected|rejects/i;
      return status.search(re) !== -1;
    }
  }

}
