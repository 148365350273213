import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { User } from '../model/user';
import { UserService } from '../service/user.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { MOCK_PBR_USER, MOCK_PBR_ADMIN, MOCK_PBR_ACTUARY, MOCK_ALL } from '../mock/mock-users';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserAssignmentService } from '../user-assignment/services/user-assignment.service';
import { DatacallSettingEnum } from '../roleconfig/datacall-setting/model/datacall-setting.enum';
import { DatacallSettingService } from '../roleconfig/datacall-setting/services/datacall-setting.service';
import { SessionService } from '../service/session.service';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html'
})
export class MainComponent implements OnInit, OnDestroy{
  user: User;
  loading = false;
  mock = environment.mock;
  one: any;
  mockUsers = ['PBR_USER', 'PBR_ADMIN', 'PBR_ACTUARY'];
  selectedMockUser = 'PBR_USER';
  showuserAssignmentsPrimaryLink: boolean = false;
  private unsubscribe: Subject<void> = new Subject();
  reservedWords: any[];

  constructor(
    private userService: UserService,
    private oauthService: OAuthService,
    private router: Router,
    private sessionService: SessionService,
    private userAssignmentService: UserAssignmentService,
    private dataCallSettingService: DatacallSettingService
  ) {
  }

  ngOnInit() {
    if (!this.mock) {
      this.loading = true;
      this.getUser();
    } else {
      this.user = MOCK_ALL;
      this.user.isAuthenticated = true;
      this.user.isRdcAdmin = false;
      this.user.currentSchema = null;
      this.user['name'] = 'MOCK';
      this.userService.setStoredUser(this.user);
      this.loading = false;
      this.navigateToNext();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getUser() {
    this.userService.getUser()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        user => {
          if (user) {
            this.user = user;
            this.user.isAuthenticated = true;
            this.user.currentSchema = null;
            this.user['name'] = this.userService.getStoredUserProfile().info.given_name;
            this.userService.setStoredUser(this.user);
            this.sessionService.configureSessionTimeout();
            this.loading = false;
            this.navigateToNext();
          }
        }
      );
  }

  private navigateToNext(): void {
    if (!this.user.schemas.length || this.user.schemas.length === 0) {
      this.router.navigate(['userNotAllowed']);
    } else if (!this.userService.hasMultiplePermittedSchemas(this.user)) {
      this.user.currentSchema = this.user.schemas[0];
      this.getUserAssignments();
      this.user.selectedDatacallGroup = this.user.dataCallGroups[0].groupName.toLocaleLowerCase();
      
      this.userService.setStoredUser(this.user);
      this.router.navigate(['disclaimer', this.user.currentSchema]);
    } else if (this.userService.hasMultiplePermittedSchemas(this.user)) {
      this.router.navigate(['schemaSelection']);
    } else {
    }
  }

  getEnvironmentName(): string {
    return environment.envName;
  }

  logout() {
    if (environment.mock) {
      this.router.navigate(['']);
    } else {
      this.oauthService.logOut();
    }
  }

  setMockUser() {
    if (this.selectedMockUser === 'PBR_USER') {
      this.user = MOCK_PBR_USER;
      this.user['name'] = 'PBR USER';
    } else if (this.selectedMockUser === 'PBR_ADMIN') {
      this.user = MOCK_PBR_ADMIN;
      this.user['name'] = 'PBR ADMIN';
    } else {
      this.user = MOCK_PBR_ACTUARY;
      this.user['name'] = 'PBR ACTUARY';
    }
    this.userService.setStoredUser(this.user);
    this.navigateToNext();
  }

// Enabling user assignments based on primary user and having user assignments
  getUserAssignments() {
    this.loading = true;
    let datcallSettingName = DatacallSettingEnum.userAssignment;
    this.dataCallSettingService.getDatacallSettingName(this.user.currentSchema, datcallSettingName)
      .subscribe(
        res => {
          if (!res) {
            this.setAssignmentPrimaryLink(false);
          }
          this.getOnlineDataEntryDatacallSetting();
          this.loading = false;
        }
      );
  }

  getAssignmentsForPrimaryUser() {
    this.userAssignmentService.getUserAssignmentsPrimaryUser(this.user.currentSchema, this.user.userId)
    .subscribe(
      answer => {
        if (answer.length !== 0) {
          this.setAssignmentPrimaryLink(true);
        } else {
          this.setAssignmentPrimaryLink(false);
        }
        this.loading = false;
      }
    );
  }

  getOnlineDataEntryDatacallSetting() {
    this.loading = true;
    let datacallSettingName = DatacallSettingEnum.isOnlineDataEntry;
    this.dataCallSettingService.getDatacallSettingName(this.user.currentSchema, datacallSettingName).subscribe(
      res => {
        this.dataCallSettingService.setOnlineDataEntrySetting(res);
        this.getAssignmentsForPrimaryUser();
      }
    )
  }
  
  setAssignmentPrimaryLink(isPrimaryUser): void {
     this.userAssignmentService.setShowuserAssignmentsPrimaryLink(isPrimaryUser);
      this.userAssignmentService.showuserAssignmentsPrimaryLink = isPrimaryUser;
    }
}


