import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { LoadingModule } from 'ngx-loading';
import { FilingDatesRoutingModule } from './filing-dates-routing.module';
import { FilingDatesComponent } from './filing-dates.component';
import { FilingDatesService } from './services/filing-dates.service';
import { MessagesModule } from '../messages/messages.module';
import { AgGridModule } from 'ag-grid-angular';
import { FilingDateRendererComponent } from './cell-renderer/filing-date-renderer/filing-date-renderer.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    AgGridModule.withComponents([FilingDateRendererComponent]),
    MessagesModule,
    BsDatepickerModule.forRoot(),
    TooltipModule.forRoot(),
    LoadingModule,
    FilingDatesRoutingModule,
  ],
  declarations: [FilingDatesComponent, FilingDateRendererComponent],
  providers: [
    FilingDatesService,
  ],
  exports: [FilingDatesComponent]
})
export class FilingDatesModule { }
